<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Item Wise Stock</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6 d-flex">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Excel</button>
                                    <!-- <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX2()">Export To Excel</button> -->
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-end mt-1">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-12 col-xl-3 bottom_margin">
                                            <!-- <div class="col-lg-2 col-xl-3 col-6"> -->
                                                <label for="first" class="form-label">Company Name<span
                                                        style="color: red;">&nbsp;*</span></label>
                                                <select class="form-select" name="txtCompany"
                                                    [(ngModel)]="txtCompany" required="" (change)="getAllCategory()">
                                                    <option>-:Select Company:-</option>
                                                    <option>All</option>
                                                    <option *ngFor="let company of allCompany" [ngValue]="company.companyId">
                                                        {{company.companyName}}</option>
                                                </select>
                                                <div class="invalid-feedback">
                                                    Please enter company.
                                                </div>
                                            <!-- </div> -->
                                        </div>
                                        <div class="col-12 col-xl-3 bottom_margin">
                                            <label for="country" class="form-label">Category<span
                                                style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" name="txtCategory"
                                                [(ngModel)]="txtCategory" required="">
                                                <option>-:Select Category:-</option>
                                                <option>All</option>
                                                <option *ngFor="let category of allCategory"
                                                    [value]="category.categoryId">{{category.categoryName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid country.
                                            </div>
                                        </div>
                                        <div class="col-5 col-xl-2" style="margin-top: 30px;">
                                            <button *ngIf="!isClick" class="btn btn-primary d-block" type="submit" (click)="exportAsXLSX2()">Export&nbsp;To&nbsp;Excel</button>
                                            <button *ngIf="isClick" class="btn btn-primary d-block" type="submit" disabled>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Please wait...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let iwstock of allItemStock | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{iwstock.itemname}}</td>
                                            <td>{{iwstock.quantity}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                (click)="getQtyBatchWiseByItemId(iwstock)" data-bs-toggle="modal"
                                                data-bs-target="#exampleModal">
                                                <i class="fas fa-history"></i>
                                            </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allItemStock < 1 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                        <!-- <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item active" aria-current="page">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Quantity Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Batch Number</th>
                                                        <th scope="col">Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let batchqty of resBatchWiseQty">
                                                        <td>{{batchqty.batchnumber}}</td>
                                                        <td>{{batchqty.totalqty}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="resBatchWiseQty < 1 ">
                                                        <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>