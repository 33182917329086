import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReceiptVoucherService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  createReceiptVoucher(txtVoucherDate: Date, ReceiptData: Array<any>, txtNarration: string, companyId: Number, txtChequeNo: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      receiptVoucherDetailDto: ReceiptData,
      narration: txtNarration,
      companyId: companyId,
      chequeNo: txtChequeNo,
    });
    return this.http.post('/api/create/receiptvoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllreceipt() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/receiptvoucher', { headers })
      .pipe(map(res => res.json()));
  }

  getAllReceiptByDate(stratdate:string,endDate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/receiptvoucher/date/'+stratdate +'/'+endDate, { headers })
      .pipe(map(res => res.json()));
  }

  //get All Receipt By Id
  getAllReceiptById(txtId: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/receiptvoucher/' + txtId, { headers: headers })
      .pipe(map(res => res.json()));

  }

  public downloadReceiptVoucher(vouchermasterid: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/receipt/voucher/` + vouchermasterid, { headers: header, responseType: 'blob' });
  }

  deleteVoucherRecord(vouchermasterId: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);

    return this.http.delete('/api/delete/voucher/' + vouchermasterId, { headers: headers })
      .pipe(map(res => res));
  }

  // deleteReceiptById(txtId: number) {
  //   var headers = new Headers();
  //   const token = localStorage.getItem('Token');
  //   headers.append('Authorization', token);
  //   headers.append('Content-Type', 'Application/Json');

  //   return this.http.delete('/api/delete/voucher/' + txtId, { headers: headers })
  //     .pipe(map(res => res));
  // }

  sendReceiptEmail(id: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/server/receipt/voucher/'+ id +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  receiptMailWithSendGrid(vouchermstId: string,email: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      vouchermasterid: vouchermstId,
      email: email
    });
    return this.http.post('/api/send/receipt', body, { headers })
      .pipe(map(res => res));
  }

  getB2BReceiptPaymentData(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/receiptpayment', { headers })
      .pipe(map(res => res.json()));
  }

  getB2BReceiptPaymentAcceptOrRejectData(vouchermstId: string,status: string,txtbank : string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      recieptId: vouchermstId,
      status: status,
      bankId : txtbank
    });
    return this.http.post('/api/create/receiptvoucher/company', body,{ headers: headers})
      .pipe(map(res => res.json()));

  }

  whatsappSale(saleReturnid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/receiptvoucher/whatsup/'+saleReturnid , { headers })
      .pipe(map(res => res.json()));
  }
}
