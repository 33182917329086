import { Component, OnInit } from '@angular/core';
import { RoleService } from "../service/role/role.service";
import { ToastrService } from "ngx-toastr";
import { MenuManagementService } from "../service/menu_management/menu-management.service";

@Component({
  selector: 'app-menu-management',
  templateUrl: './menu-management.component.html',
  styleUrls: ['./menu-management.component.css']
})
export class MenuManagementComponent implements OnInit {
  txtMenuId;
  txtmenuName;
  txtmenuAction;
  txtmenuDescription;
  txtparent;
  txtpriority;
  txtRole;

  allMenu;
  allRole;
  allMenuList;

  roleList = [];
  menuList = [];
  responsemenuList;
  dropdownList = [];
  selectedRoles = [];
  dropdownSettings = {};
  root: {};
  btnName = "Add";
  constructor(
    private roleservice: RoleService,
    private toastr: ToastrService,
    private menumanagementservice: MenuManagementService,
  ) { }

  ngOnInit(): void {
    this.getAllRole();
    this.getAllMenu();
    this.getAllMenuList();
    this.loadRoleList();
    this.loadMenuData();
    this.loadMenuList();
  }

  loadMenuData() {
    this.menumanagementservice.getAllMenuList().subscribe((data: any[]) => {
      this.root = data;
    }), (error => {
      this.toastr.error("Something went wrong");
      // console.log(error);
    });
  }
  loadMenuList() {
    this.menumanagementservice.getAllMenu().subscribe((data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        this.menuList.push(data[i]);
      }
    }), (error => {
      this.toastr.error("Something went wrong");
      // console.log(error);
    });
  }
  clearValue() {
    this.btnName = 'Add';
    this.txtmenuName = '';
    this.txtmenuAction = '';
    this.txtparent = null;
    this.txtmenuDescription = '';
    this.txtpriority = '';
    this.txtRole = '';
    this.txtMenuId = null;
    this.selectedRoles = [];
  }

  createMenures;
  createMenu() {
    this.roleList = [];
    // this.roleList.push(this.txtRole.roleid);

    if (this.txtmenuName == undefined || this.txtmenuName == null || this.txtmenuName == '') {
      return this.toastr.error('Menu name must be required');
    } else if (this.txtmenuAction == undefined || this.txtmenuAction == null || this.txtmenuAction == '') {
      return this.toastr.error('Menu action must be required');
    } else if (this.txtparent != null && (this.txtpriority == "-:Select Parent Menu:-")) {
      return this.toastr.error('Parent menu must be required');
    } else if (this.txtpriority == undefined || this.txtpriority == null || this.txtpriority == '') {
      return this.toastr.error('Priority must be required');
    } else {
      if (this.txtMenuId != null) {
        if (this.selectedRoles.length == 0 || this.selectedRoles == null || this.selectedRoles == undefined) {
          this.toastr.error('Pleace Select Role');
        } else {
          this.roleList = [];
          for (const role of this.selectedRoles) {
            this.roleList.push(role.roleid);
          }
          this.menumanagementservice.updateMenu(this.txtmenuName, this.txtmenuAction, this.txtpriority
            , this.txtmenuDescription, this.txtparent, this.roleList, this.txtMenuId)
            .subscribe(res => {
              this.createMenures = res;
              this.clearValue();
              this.getAllMenu();
              this.getAllMenuList();
              this.loadMenuData();
              this.loadMenuList();
              this.toastr.success('Menu detail updated successfully');
            }, error => {
              // this.toastr.error("Menu not updated");
              let message = JSON.parse(error._body).message;
              this.toastr.error(JSON.stringify(message));
            });
        }
      } else {
        if (this.selectedRoles.length == 0 || this.selectedRoles == null || this.selectedRoles == undefined) {
          this.toastr.error('Pleace Select Role');
        } else {
          this.roleList = [];
          for (const role of this.selectedRoles) {
            this.roleList.push(role.roleid);
          }
          this.menumanagementservice.createMenu(this.txtmenuName, this.txtmenuAction, this.txtmenuDescription, this.txtparent, this.txtpriority, this.roleList)
            .subscribe(res => {
              this.createMenures = res;
              console.log(this.createMenures);
              this.clearValue();
              this.getAllMenu();
              this.getAllMenuList();
              this.loadMenuData();
              this.loadMenuList();
              this.toastr.success('Menu save successfully');
            }, error => {
              // this.toastr.error('Menu not saved');
              let message = JSON.parse(error._body).message;
              this.toastr.error(JSON.stringify(message));
            })
        }
      }
    }
  }

  fillAllData(data) {
    // console.log(data);
    this.btnName = "Update";
    this.txtmenuName = data.name;
    this.txtmenuAction = data.action;
    this.txtmenuDescription = data.description;
    this.txtparent = data.parentmenuid;
    this.txtpriority = data.priority;
    this.selectedRoles = data.roles;
    this.txtMenuId = data.menuid;
  }
  getAllMenu() {
    this.menumanagementservice.getAllMenu().subscribe(res => {
      this.allMenu = res;
      console.log(this.allMenu);
    })
  }

  getAllMenuList() {
    this.menumanagementservice.getAllMenuList().subscribe(res => {
      this.allMenuList = res;
      console.log(this.allMenuList);
    })
  }

  getAllRole() {
    this.roleservice.getAllRole().subscribe(res => {
      this.allRole = res;
      console.log(this.allRole);
    })
  }

  loadRoleList() {
    this.roleservice.getAllRole().subscribe((data: any[]) => {
      this.dropdownList = data;
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'roleid',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true
      };
    }), (error => {
      this.toastr.error('Something went wrong');
      // console.log(error);
    });
  }

  toggleCollapse(e) {

    var curr = e.target;
    var parent = e.target.parentElement;

    if (parent.className.indexOf('collapse-menu') > -1) {
      parent.classList.remove('collapse-menu');
      parent.classList.add('expanded-menu');
      curr.classList.remove('fa-chevron-right');
      curr.classList.add('fa-chevron-down');

    } else {
      parent.classList.remove('expanded-menu');
      parent.classList.add('collapse-menu');
      curr.classList.add('fa-chevron-right');
      curr.classList.remove('fa-chevron-down');
    }
  }
}
