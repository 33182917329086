import { Component, OnInit } from '@angular/core';
import { GSTService } from "../service/GST/gst.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  styleUrls: ['./gst.component.css']
})
export class GSTComponent implements OnInit {
  resGst;
  resUpGst;
  txtGst;
  txtCGst;
  txtSGst;
  txtGstId;
  allGst;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private router: Router,
    private GSTService: GSTService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllGst();
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  getAllGst() {
    this.GSTService.getAllGst().subscribe(res => {
      this.allGst = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtGst = '';
    this.txtCGst = '';
    this.txtSGst = '';
    this.txtGstId = null;
  }

  createGst() {
    if (this.txtGst == null || this.txtGst == '' || this.txtGst == undefined) {
      this.toastr.error("GST is required");
    } else if (this.txtCGst == null || this.txtCGst == '' || this.txtCGst == undefined) {
      this.toastr.error("CGST is required");
    } else if (this.txtSGst == null || this.txtSGst == '' || this.txtSGst == undefined) {
      this.toastr.error("SGST is required");
    } else {
      if (this.txtGstId == null) {
        this.GSTService.createGst(this.txtGst, this.txtCGst, this.txtSGst)
          .subscribe(res => {
            this.resGst = res;
            console.log(this.resGst);
            this.clearData();
            this.getAllGst();
            this.toastr.success('GST save successfully');
          }, error => {
            // this.toastr.error('gst not saved');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.GSTService.updateGst(this.txtGstId, this.txtGst, this.txtCGst, this.txtSGst).subscribe(res => {
          this.resUpGst = res;
          this.clearData();
          this.getAllGst();
          this.toastr.success('GST updated successfully');
        }, error => {
          // this.toastr.error('gst not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateGst(gst) {
    this.btnName = 'Update';
    this.txtGstId = gst.id;
    this.txtGst = gst.gstName;
    this.txtCGst = gst.cgst;
    this.txtSGst = gst.sgst;
  }

  deleteid;
  deleteGst(gst) {
    this.deleteid = gst.id;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.GSTService.deleteGst(this.deleteid).subscribe(res => {
        this.resUpGst = res;
        this.clearData();
        this.getAllGst();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('GST deleted successfully');
      }, error => {
        this.toastr.error('GST not deleted');
      })
    }
  }
}
