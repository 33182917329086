import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CashToBankService } from '../service/cash-to-bank/cash-to-bank.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
declare var $: any;

@Component({
  selector: 'app-cash-to-bank-record',
  templateUrl: './cash-to-bank-record.component.html',
  styleUrls: ['./cash-to-bank-record.component.css']
})
export class CashToBankRecordComponent implements OnInit {
  allCashToBank;
  allPaymentDetail;
  allCashToBankById;
  txtTotalCredit = 0;
  txtTotalDebit = 0;
  txtTotalAmount = 0;
  search;
  page: number = 1;
  
  constructor(
    private cashtobankservice: CashToBankService,
    private router: Router,
    private toastr: ToastrService,
    private receiptvoucherservice: ReceiptVoucherService,
  ) { }

  ngOnInit(): void {
    this.getAllCashToBankVoucher();
  }

  MoveCashToBank() {
    this.router.navigate(['cashtobank']);
  }
  getAllCashToBankVoucher() {
    this.cashtobankservice.getAllCashToBankVoucher().subscribe(res => {
      this.allCashToBank = res;
      this.txtTotalAmount = 0;
      for (let cashToBankData of this.allCashToBank) {
        this.txtTotalAmount = this.txtTotalAmount + cashToBankData.debit;
      }
    })
  }

  cashToBankDetail(cashtobank) {
    this.getAllCashToBankById(cashtobank.voucherMasterId)
  }

  getAllCashToBankById(txtId) {
    this.cashtobankservice.getAllCashToBankById(txtId).subscribe(res => {
      this.allCashToBankById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let cashToBankData of this.allCashToBankById) {
        this.txtTotalDebit = this.txtTotalDebit + cashToBankData.debit;
        this.txtTotalCredit = this.txtTotalCredit + cashToBankData.credit;
      }
    })
  }

  // resBillPdf
  // openPdfResponse
  // paymentVoucherPdf(payment) {
  //   this.paymentvoucherservice.downloadPaymentVoucher(payment.voucherMasterId).subscribe((data) => {
  //     this.resBillPdf = data;

  //     var newBlob = new Blob([this.resBillPdf], { type: "application/pdf" });
  //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //       window.navigator.msSaveOrOpenBlob(newBlob);
  //       return;
  //     }

  //     const data1 = window.URL.createObjectURL(newBlob);
  //     var link = document.createElement('a');
  //     link.href = data1;
  //     link.download = "Sale_Bij_Nigam.pdf";
  //     // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  //     window.open(link.href, "", "width=800,height=600");
  //     this.openPdfResponse.saveAs(link.href);

  //     setTimeout(function () {
  //       // For Firefox it is necessary to delay revoking the ObjectURL
  //       window.URL.revokeObjectURL(data1);
  //       link.remove();
  //     }, 100);
  //     this.resBillPdf = '';
  //     this.openPdfResponse = '';
  //   })
  // }

  deleteCashToBankId;
  deleteCashToBank(cashtobank){
    this.deleteCashToBankId = cashtobank.voucherMasterId;
  }

  resCashToBankData;
  confirmDelete() {
    if (this.deleteCashToBankId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteCashToBankId)
        .subscribe(res => {
          this.resCashToBankData = res;
          this.getAllCashToBankVoucher();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('CashToBank data deleted successfully');
        }, error => {
          this.toastr.error('CashToBank data not deleted');
        })
    }
  }
}
