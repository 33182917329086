import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LoginService } from "../service/login/login.service";
import { ToastrService } from "ngx-toastr";
declare var $ : any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  txtusername;
  txtpass;
  responseToken;
  txtForgotUsername;
  constructor(
    private Router: Router,
    private LoginService: LoginService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  loginUser() {
    if (this.txtusername == null || this.txtusername == '' || this.txtusername == undefined) {
      this.toastr.error("username is required");
    } else if (this.txtpass == null || this.txtpass == '' || this.txtpass == undefined) {
      this.toastr.error("password is required");
    } else {
      this.LoginService.loginUser(this.txtusername, this.txtpass)
        .subscribe(res => {
          this.responseToken = res;
          // console.log(this.responseToken.token);
          localStorage.setItem('Token', this.responseToken.token);
          this.getLoginUserData();
          // this.toastr.success("login sussfully");
          // this.Router.navigate(["category"]);
        }, error => {
          this.toastr.error('Username and password did not match.');
        });
    }

  }

  loginUserData
  getLoginUserData() {
    this.LoginService.getLoginUserData().subscribe(res => {
      this.loginUserData = res;
      if(res.activeUsers == false){
        this.toastr.success("Login Successful");
        this.Router.navigate(['initialdetail']);
        } else {
        // this.toastr.success("Login Successful");
        // this.Router.navigate(['dashboard']);
        this.userRedirect();
        }
      // console.log(this.loginUserData);
    })
  }

  resRoleName;
  userRedirect() {
    this.LoginService.getUserRoleByUserId(this.loginUserData.userid).subscribe(res => {
      this.resRoleName = res;
      // console.log(this.resRoleName);
      if (this.loginUserData.userid != null) {
        if (this.resRoleName == null || this.resRoleName == undefined) {
          this.toastr.success('Login Successful');
          this.Router.navigate(['dashboard']);
          // this.LoginService.setLoggedIn(true);
        } else {
          if (this.resRoleName.roleName == "SUPER ADMIN") {
            this.toastr.success('Login Successful');
            this.Router.navigate(['dashboard']);
          } else if (this.resRoleName.roleName == "ADMIN") {
            this.toastr.success('Login Successful');
            this.Router.navigate(['sale']);
          } else if (this.resRoleName.roleName == "SALE ROLE") {
            this.toastr.success('Login Successful');
            this.Router.navigate(['sale']);
          } else {
            this.toastr.success('Login Successful');
            this.Router.navigate(['sale']);
          }
        }
      } else {
        this.toastr.error("User not found");
      }
    })
  }
  resForgetPass;
  forgetPassword() {
    if (this.txtForgotUsername == null || this.txtForgotUsername == '' || this.txtForgotUsername == undefined) {
      this.toastr.error("username is required");
    } else {
      this.LoginService.forgetPassword(this.txtForgotUsername)
        .subscribe(res => {
          this.resForgetPass = res;
          this.clearForgetPassword();
          $('#exampleModal').modal('hide');
          this.toastr.success("Password change successfully");
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
    }
  }

  clearForgetPassword(){
    this.txtForgotUsername = null;
  }
}
