import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CropsKrishiGyaanService {

  constructor(
    private http: Http,
  ) { }

  createCropsKrishiGyaan(txtCrops: string, txtCropsKrishiGyaanName: string, txtCropsKrishiGyaanGuj: string, txtCropsKrishiGyaanDes: string, txtCropsKrishiGyaanDesGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      cropsName: txtCrops,
      cropsKrishiGyaanName: txtCropsKrishiGyaanName,
      cropsKrishiGyaanNameGuj: txtCropsKrishiGyaanGuj,
      cropsKrishiGyaanDescription: txtCropsKrishiGyaanDes,
      cropsKrishiGyaanDescriptionGuj: txtCropsKrishiGyaanDesGuj,
    });
    return this.http.post('/api/app/create/cropsKrishiGyaan', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateCropsKrishiGyaan(txtCropsKrishiGyaanId: string, txtCrops: string, txtCropsKrishiGyaanName: string, txtCropsKrishiGyaanGuj: string, txtCropsKrishiGyaanDes: string, txtCropsKrishiGyaanDesGuj: string, txtImage: string, txtVideo: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: txtCropsKrishiGyaanId,
      cropsName: txtCrops,
      cropsKrishiGyaanName: txtCropsKrishiGyaanName,
      cropsKrishiGyaanNameGuj: txtCropsKrishiGyaanGuj,
      cropsKrishiGyaanDescription: txtCropsKrishiGyaanDes,
      cropsKrishiGyaanDescriptionGuj: txtCropsKrishiGyaanDesGuj,
      image: txtImage,
      video: txtVideo
    });
    return this.http.put('/api/app/update/cropsKrishiGyaan', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllCropsKrishiGyaan() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/cropsKrishiGyaan', { headers })
      .pipe(map(res => res.json()));
  }

  deleteCropsKrishiGyaan(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: deleteAppId,
    });
    return this.http.put('/api/app/delete/cropsKrishiGyaan', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/cropsKrishiGyaan/image', data, { headers: headers })
      .pipe(map(res => res));
  }

  uploadVideo(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/cropsKrishiGyaan/video', data, { headers: headers })
      .pipe(map(res => res));
  }
}
