import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(
    private http: Http,
  ) { }
  createSubCategory(txtCategory: string, txtSubCategory: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      subCategoryName : txtSubCategory,
      categoryId:txtCategory
    });
    return this.http.post('/api/create/subcategory', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateCategory(subcategoryid: string, txtSubCategory: string, txtCategory: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      subCategoryName : txtSubCategory,
      categoryId : txtCategory,
      subcategoryid: subcategoryid
    });
    return this.http.put('/api/update/subcategory', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllSubCategory(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/subcategory', { headers })
      .pipe(map(res => res.json()));

  }

  getAllItemBySubCategory(subcategoryid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/subcategory/item/' + subcategoryid , { headers })
      .pipe(map(res => res.json()));
  }

}
