import { Component, OnInit } from '@angular/core';
import { GroupService } from "../service/group/group.service";
import { LedgerService } from "../service/ledger/ledger.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.css']
})
export class LedgerComponent implements OnInit {

  resLedger = [];
  txtLedgerName;
  txtGroup;
  txtledgerid;
  allLedger;
  allgroup;
  resGroup;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(private groupService: GroupService,
    private ledgerService: LedgerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAllLedger();
    this.getAllGroup();
  }

  getAllGroup(){
    this.groupService.getAllGroup().subscribe(res => {
      this.allgroup = res;
    })
  }

  getAllLedger() {
    this.ledgerService.getAllLedger().subscribe(res => {
      this.allLedger = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtLedgerName = '';
    this.txtledgerid = null;
    this.txtGroup = '';
  }

  toUpperCaseLedger() {
    const temp = this.txtLedgerName;
    this.txtLedgerName = temp.toUpperCase();
    return this.txtLedgerName;
  }

  createLedger() {
    if (this.txtGroup == null || this.txtGroup == '' || this.txtGroup == undefined || this.txtGroup == "-:Select Group Name:-") {
      this.toastr.error("Group is required");
    } else if (this.txtLedgerName == undefined || this.txtLedgerName == null || this.txtLedgerName == '') {
      return this.toastr.error('Ledger name is required');
    } else {
      if (this.txtledgerid == null) {
        this.ledgerService.createLedger(this.txtLedgerName, this.txtGroup)
          .subscribe(res => {
            this.resGroup = res;
            this.clearData();
            this.getAllLedger();
            this.toastr.success('Ledger save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.ledgerService.updateLedger(this.txtledgerid, this.txtLedgerName, this.txtGroup).subscribe(res => {
          this.resGroup = res;
          this.clearData();
          this.getAllLedger();
          this.toastr.success('Ledger updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatesubcategory(ledger) {
    this.btnName = 'Update';
    this.txtledgerid = ledger.ledgerId;
    this.txtGroup = ledger.glAccountType.glaccountName;
    this.txtLedgerName = ledger.ledgerName;

  }

  deleteid;
  deleteData(ledger) {
    this.deleteid = ledger.ledgerId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.ledgerService.deleteLedger(this.deleteid).subscribe(res => {
        this.resGroup = res;
        this.clearData();
        this.getAllLedger();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Ledger deleted successfully');
      }, error => {
        this.toastr.error('Ledger not deleted');
      })
    }
  }

}
