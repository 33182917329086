import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PartyService } from '../service/party/party.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
declare var $: any;

@Component({
  selector: 'app-b2b-receipt-payment',
  templateUrl: './b2b-receipt-payment.component.html',
  styleUrls: ['./b2b-receipt-payment.component.css']
})
export class B2bReceiptPaymentComponent implements OnInit {
  reSearchData;
  search;
  page = 1;
  txtTotalDebit = 0;

  constructor(
    private toastr: ToastrService,
    private receiptvoucherservice: ReceiptVoucherService,
    private partyService: PartyService,
  ) { }

  ngOnInit(): void {
    this.getB2BReceiptPaymentData();
  }

  getB2BReceiptPaymentData() {
    this.receiptvoucherservice.getB2BReceiptPaymentData().subscribe(res => {
      this.reSearchData = res;
      this.txtTotalDebit = 0;
      for (let ii = 0; ii < this.reSearchData.length; ii++) {
        this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
      }
    }, error => {
      this.toastr.error("No data found");
    });
  }

  acceptId;
  selectedToCompanyId;
  getAccept(srch){
    this.acceptId = srch.recieptId;
    this.selectedToCompanyId = srch.toPartyId;
  }
  confirmAccept(){
    if (this.txtRadio == "Bank" && (this.txtselected == "" || this.txtselected == null || this.txtselected == undefined || this.txtselected == "-:Select Bank:-")) {
      this.toastr.error("Bank must be required")
    } else {
      if (this.txtRadio != "Bank") {
        this.txtselected = null;
      }
      this.receiptvoucherservice.getB2BReceiptPaymentAcceptOrRejectData(this.acceptId, "ACCEPT",this.txtselected).subscribe(res => {
        this.reSearchData = res;
        this.getB2BReceiptPaymentData();
        this.toastr.success("Payment accept successfully");
        $('#acceptModal').modal('hide');
      }, error => {
        // this.toastr.error("No data found");
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      });
    }
  }

  rejectId;
  getReject(srch){
    this.rejectId = srch.recieptId;
  }
  confirmReject(){
    if (this.txtRadio == "Bank" && (this.txtselected == "" || this.txtselected == null || this.txtselected == undefined || this.txtselected == "-:Select Bank:-")) {
      this.toastr.error("Bank must be required")
    } else {
      if (this.txtRadio != "Bank") {
        this.txtselected = null;
      }
      this.receiptvoucherservice.getB2BReceiptPaymentAcceptOrRejectData(this.rejectId, "REJECT",this.txtselected).subscribe(res => {
        this.reSearchData = res;
        this.getB2BReceiptPaymentData();
        this.toastr.success("Payment reject successfully");
        $('#rejectModal').modal('hide');
      }, error => {
        // this.toastr.error("No data found");
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      });
    }
  }

  txtRadio: any = "Cash";
  isDisabled: boolean = true;
  txtselected;
  onItemChange(value) {
    // console.log(value);
    this.txtRadio = value;
    if (value == "Bank") {
      // console.log(value);
      this.isDisabled = false;
      this.getAllBank();
    } else {
      this.isDisabled = true;
    }
  }

  allBank;
  getAllBank() {
    this.partyService.getAllBank(this.selectedToCompanyId).subscribe(res => {
      this.allBank = res;
      // console.log("all Bank", this.allBank);
    })
  }

  clearPaymentData() {
    var abc = document.getElementById('cashnote');
    var selele = abc as HTMLInputElement;
    selele.checked = true;
    this.isDisabled = true;
  }
}
