import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LiabilityVoucherService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }
  
  createLiabilityVoucher(txtVoucherDate: Date, ReceiptData: Array<any>, txtNarration: string, companyId: Number, txtChequeNo: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      receiptVoucherDetailDto: ReceiptData,
      narration: txtNarration,
      companyId: companyId,
      chequeNo: txtChequeNo,
    });
    return this.http.post('/api/create/liabilityvoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllLiability() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/liabilityvoucher', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Receipt By Id
  getAllLiabilityById(txtId: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/liabilityvoucher/' + txtId, { headers: headers })
      .pipe(map(res => res.json()));
  }

  // deleteVoucherRecord(vouchermasterId: number) {
  //   const headers = new Headers();
  //   var token = localStorage.getItem('Token');
  //   headers.append('Authorization', token);

  //   return this.http.delete('/api/delete/voucher/' + vouchermasterId, { headers: headers })
  //     .pipe(map(res => res));
  // }

}
