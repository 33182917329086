import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlanPriceService {

  constructor(
    private http: Http,
  ) { }

  createPlanPrice(txtplanname: string, txtplanduration: string, txtplanprice: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planName: txtplanname,
      planDays: txtplanduration,
      planPrice: txtplanprice
    });
    return this.http.post('/api/create/planprice', body, { headers })
      .pipe(map(res => res.json()));
  }

  updatePlanPrice(id: string,txtplanname: string, txtplanduration: string, txtplanprice: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpriceId: id,
      planName: txtplanname,
      planDays: txtplanduration,
      planPrice: txtplanprice
    });
    return this.http.put('/api/update/planprice', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllPriceDetail() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/api/getall/planprice', { headers })
      .pipe(map(res => res.json()));

  }

  deletPlanPrice(deleteid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpriceId: deleteid
    });
    return this.http.put('/api/delete/planprice', body, { headers })
      .pipe(map(res => res.json()));
  }
}
