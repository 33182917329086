import { Component, OnInit } from '@angular/core';
import { LocationService } from "../service/location/location.service";
import { ToastrService } from "ngx-toastr";
import { DetailAnalysisService } from "../service/detail-analysis/detail-analysis.service";
import { StockService } from "../service/stock/stock.service";
import { DatePipe } from '@angular/common';
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
import { ExcelService } from "../service/excel/excel.service";
import { ItemSearchStockDTO } from "../service/excel/ItemSearchStockDTO";
@Component({
  selector: 'app-item-wise-search-stock',
  templateUrl: './item-wise-search-stock.component.html',
  styleUrls: ['./item-wise-search-stock.component.css']
})
export class ItemWiseSearchStockComponent implements OnInit {

  txtFormDate;
  txtToDate;
  txtType;
  txtLocation;
  allLocation;
  reSearchData;
  resSelectedSubDetail;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  txtItemName;
  allItem;
  itemId;
  search1 = '';
  searchItemName = [];
  search;
  page = 1;
  
  ExcelData: Array<ItemSearchStockDTO> = [];
  constructor(
    private locationService: LocationService,
    private toastr: ToastrService,
    private detailAnalysisService: DetailAnalysisService,
    private itemsdetailservice: ItemsDetailService,
    private stockservice: StockService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.txtFormDate = new Date();
    this.txtToDate = new Date();
    this.getAllLocation();
    this.getAllItem();
  }

  getAllLocation() {
    this.locationService.getAllLocation().subscribe(res => {
      this.allLocation = res;
    })
  }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
  }

  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      console.log(this.allItem);
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.searchItemName);
    })
  }

  searchStock() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtItemName == "" || this.txtItemName == null || this.txtItemName == undefined) {
      this.toastr.error("Item name is required");
    } else if (this.txtType == "" || this.txtType == null || this.txtType == undefined || this.txtType == "-:Select Type:-") {
      this.toastr.error("Type is required");
    } else if (this.txtLocation == "" || this.txtLocation == null || this.txtLocation == undefined || this.txtLocation == "-:Select Location:-") {
      this.toastr.error("Location is required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      if (this.txtType == "Purchase") {
        this.stockservice.searchPurchaseStockItemWise(this.txtFormDate, this.txtToDate, this.txtLocation, this.search1).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          // for (let amount of this.reSearchData) {
          //   var sum = 0;
          //   sum = sum + Number(amount.netamount);

          //   this.totalNetAmount = this.totalNetAmount + sum;
          // }
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else if (this.txtType == "Sale") {
        this.stockservice.searchSaleStockItemWise(this.txtFormDate, this.txtToDate, this.txtLocation, this.search1).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          // for (let amount of this.reSearchData) {
          //   var sum = 0;
          //   sum = sum + Number(amount.netamount);

          //   this.totalNetAmount = this.totalNetAmount + sum;
          // }
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else if (this.txtType == "Sale Return") {
        this.stockservice.searchSaleReturnStockItemWise(this.txtFormDate, this.txtToDate, this.txtLocation,this.search1).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          // for (let amount of this.reSearchData) {
          //   var sum = 0;
          //   sum = sum + Number(amount.netamount);

          //   this.totalNetAmount = this.totalNetAmount + sum;
          // }
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else if (this.txtType == "Purchase Return") {
        this.stockservice.searchPurchaseReturnStockItemWise(this.txtFormDate, this.txtToDate, this.txtLocation,this.search1).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          // for (let amount of this.reSearchData) {
          //   var sum = 0;
          //   sum = sum + Number(amount.netamount);

          //   this.totalNetAmount = this.totalNetAmount + sum;
          // }
        }, error => {
          this.toastr.error("something went wrong");
        });
      }
    }
  }

  exportAsXLSX():void {
    this.ExcelData=[];
    for(let excel of this.reSearchData){
      let data = new ItemSearchStockDTO();
      data.date=this.datePipe.transform(excel.date, 'dd-MM-yyyy');
      data.number= excel.number;
      data.qty=excel.qty;
      data.rate=excel.rate;
      data.totalamount=excel.totalamount;
      this.ExcelData.push(data);
    }  
    this.excelservice.exportAsExcelFile(this.ExcelData, 'ItemSearchStock');
 }
}
