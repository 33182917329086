import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { GroupMasterPartyService } from '../service/group-master-party/group-master-party.service';
declare var $: any;

@Component({
  selector: 'app-group-master-party',
  templateUrl: './group-master-party.component.html',
  styleUrls: ['./group-master-party.component.css']
})
export class GroupMasterPartyComponent implements OnInit {
  resGroup;
  resUpGroup;
  resDelGroup;
  txtGroupName;
  txtGroupId;
  allGroup;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private groupMasterPartyService: GroupMasterPartyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllGroup();
  }
  getAllGroup() {
    this.groupMasterPartyService.getAllGroup().subscribe(res => {
      this.allGroup = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtGroupName = null;
    this.txtGroupId = null;
  }

  toUpperCaseGroup() {
    const temp = this.txtGroupName;
    this.txtGroupName = temp.toUpperCase();
    return this.txtGroupName;
  }

  createGroup() {
    if (this.txtGroupName == null || this.txtGroupName == '' || this.txtGroupName == undefined) {
      this.toastr.error("Group Name is required");
    } else {
      if (this.txtGroupId == null) {
        this.groupMasterPartyService.createGroup(this.txtGroupName)
          .subscribe(res => {
            this.resGroup = res;
            this.clearData();
            this.getAllGroup();
            this.toastr.success('Group save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.groupMasterPartyService.updateGroup(this.txtGroupId, this.txtGroupName).subscribe(res => {
          this.resUpGroup = res;
          this.clearData();
          this.getAllGroup();
          this.toastr.success('Group updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateGroup(group) {
    this.btnName = 'Update';
    this.txtGroupId = group.groupId;
    this.txtGroupName = group.groupName;
  }

  deleteid;
  deleteGroup(group) {
    this.deleteid = group.groupId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.groupMasterPartyService.deleteGroup(this.deleteid).subscribe(res => {
        this.resDelGroup = res;
        this.clearData();
        this.getAllGroup();
        $('#deleteModal').modal('hide');;
        this.toastr.success('Group deleted successfully');
      }, error => {
        this.toastr.error('Group not deleted');
      })
    }
  }
}
