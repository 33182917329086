import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { PaymentVoucherService } from "../service/payment_voucher/payment-voucher.service";
import { PartyService } from '../service/party/party.service';
import { SaleService } from '../service/sale/sale.service';
import { searchSale } from '../service/sale/searchSale';
import { SearchParty } from '../service/party/searchparty';
import { receiptVoucherDto } from '../service/receipt_voucher/receipt_voucher';
import { paymentVoucherDTO } from '../service/payment_voucher/paymentVoucherDTO';
import { LedgerService } from '../service/ledger/ledger.service';
import { SearchLedger } from '../service/ledger/searchledger';
import { ExpenseVoucherService } from '../service/expense_voucher/expense-voucher.service';
declare var $: any;

@Component({
  selector: 'app-gstr-voucher',
  templateUrl: './gstr-voucher.component.html',
  styleUrls: ['./gstr-voucher.component.css']
})
export class GstrVoucherComponent implements OnInit {
  txtgstrid;
  txtVoucherDate;
  txtselected;
  txtLedgerName;
  txtsaleCode;
  txtpandingAmount;
  txtnetAmount;
  txtcredit;
  txtcompany;
  optradio;
  txtRadio;
  // isDisabled: boolean = true;

  // allreceiptvoucher;
  btnName = 'Add';
  allGstrVoucher;
  txtNarration;

  public searchledger = '';
  searchLedgerName = [];
  public searchSale = '';
  searchsalecode = [];
  totalCredit = 0;
  allCompany;

  paymentData: Array<paymentVoucherDTO> = [];
  paymentIdLast: number = 0;
  datePipe = new DatePipe("en-US");

  constructor(
    private toastr: ToastrService,
    private partyService: PartyService,
    private saleservice: SaleService,
    private expensevoucherservice: ExpenseVoucherService,
    private ledgerService: LedgerService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.getAllLedger();
    // this.getAllPartyAccount();
    // this.getAllBank();
    this.getAllCompany();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party", this.allCompany);
    })
  }

  allBank;
  getAllBank() {
    if(this.txtcompany == null || this.txtcompany == "-:Select Company Name:-"){
      this.allBank = null;
    }else{
      this.partyService.getAllBank(this.txtcompany.partyId).subscribe(res => {
        this.allBank = res;
        // console.log("all Bank", this.allBank);
      })
    }
  }

  // checkBank(){
    // if(this.txtRadio == "Bank"){
      // this.getAllBank();
    // }
  // }

  // onItemChange(value) {
  //   console.log(value);
  //   this.txtRadio = value;
  //   if (value == "Bank") {
  //     console.log(value);
  //     this.isDisabled = false;
  //     if(this.txtcompany == null){
  //       this.toastr.error("Please Select Company");
  //     }else{
  //       this.getAllBank();
  //     }
      
  //   } else {
  //     this.isDisabled = true;
  //   }
  // }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  tempLedger;
  ledgerId;
  selectedLedger(ledger) {
    this.tempLedger = ledger;
    this.ledgerId = ledger.ledgerId;
    this.searchledger = ledger.ledgerName;
  }
  
  allLedger;
  getAllLedger() {
    this.ledgerService.getAllLedgerGST().subscribe(res => {
      this.allLedger = res;
      for (let item of this.allLedger) {
        var temp = new SearchLedger();
        temp.ledgerName = item.ledgerName;
        temp.ledgerId = item.ledgerId;
        // temp.cellNumber=item.cellNumber;
        this.searchLedgerName.push(temp)
      }
      // console.log("balance",this.allLedger);
    })
  }

  allPartyCash;
  getAllPartyAccount() {
    this.partyService.getAllPartyAccount().subscribe(res => {
      this.allPartyCash = res;
    })
  }

  tempSale;
  selectedSaleCode(sale) {
    this.tempSale = sale;
    // console.log(this.tempSale);
    this.searchSale = sale.saleCode;
    // this.getsaleById();
  }

  allsale;
  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
      for (let item of this.allsale) {
        var temp = new searchSale();
        temp.saleCode = item.saleCode;
        temp.saleid = item.saleid;
        this.searchsalecode.push(temp)
      }
      // console.log(this.allsale);
    })
  }

  clearGridValue() {
    this.txtLedgerName = "";
    this.txtcredit = "";
    this.searchledger = "";
    this.tempLedger = "";
    // this.txtNarration="";
    this.btnName = "Add";
    // this.txtgstrid = null;
    // this.txtrefId=null;
    // this.txtparticulars=null;
    // this.txtcompany="";
    // this.paymentData=null;
    // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    // this.txtVoucherDate = new Date();
  }
  clearValue() {
    this.txtLedgerName = "";
    this.txtcredit = "";
    this.searchledger = "";
    this.tempLedger = "";
    this.txtNarration="";
    this.btnName = "Add";
    this.txtgstrid = null;
    this.txtrefId=null;
    this.txtparticulars=null;
    this.txtcompany="";
    this.paymentData=null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txtselected = "";
  }

  addGstrVoucher() {
    if (this.txtgstrid == null) {
      if (this.tempLedger == undefined || this.tempLedger == null || this.tempLedger == '') {
        return this.toastr.error('Ledger name must be required');
      } else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
        return this.toastr.error('Amount must be required');
      }
      var paymentDetail = new paymentVoucherDTO();
      paymentDetail.id1 = this.paymentIdLast + 1;
      this.paymentIdLast = paymentDetail.id1;
      // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
      paymentDetail.partyId = 1064;
      paymentDetail.partyName = "GSTR";
      paymentDetail.debit = this.txtcredit;
      paymentDetail.amount = this.txtcredit;
      paymentDetail.ledgerId = this.tempLedger.ledgerId;
      paymentDetail.ledgerName = this.tempLedger.ledgerName;
      this.totalCredit = Number(this.totalCredit) + Number(paymentDetail.debit);
      this.paymentData.push(paymentDetail);
      console.log(this.paymentData);
      this.clearGridValue();

    } else {
      for (let Sale of this.paymentData) {
        if (Sale.id1 == this.txtgstrid) {
          // Sale.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
          Sale.partyId = 1064;
          Sale.partyName = "GSTR";
          Sale.ledgerName = this.tempLedger.ledgerName;
          Sale.ledgerId = this.tempLedger.ledgerId;
          Sale.debit = this.txtcredit;
          Sale.amount = this.txtcredit;
          this.getAmount();
          this.clearGridValue();
        }
      }
    }
    console.log(this.paymentData);
  }

  getAmount() {
    this.totalCredit = 0;
    for (let item of this.paymentData) {
      var sum = 0;
      sum = sum + Number(item.debit);
      this.totalCredit = this.totalCredit + sum;
    }
  }

  txtparticulars;
  txtrefId;
  paymentVoucherres;
  createGstrVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company must be required");
    } else if (this.txtselected == "" || this.txtselected == null || this.txtselected == undefined || this.txtselected == "-:Select Bank Name:-") {
      this.toastr.error("Bank must be required");
    } else if (this.paymentData.length == 0 || this.paymentData == []) {
      return this.toastr.error('GSTR detail must be required');
    } else {
      for (let paymentDetail of this.paymentData) {
        paymentDetail.refId = this.txtcompany.partyId;
        paymentDetail.particulars = this.txtcompany.partyName;
        // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
        // receiptDetail.numbers = receiptDetail.numbers;
        paymentDetail.amount = paymentDetail.debit;
        paymentDetail.ledgerId = paymentDetail.ledgerId;
        if (this.txtrefId == null || this.txtrefId == "") {
          this.txtrefId = paymentDetail.partyId;
          this.txtparticulars = "GSTR";
        } else {
          this.txtrefId = this.txtrefId + ',' + paymentDetail.partyId;
          this.txtparticulars = this.txtparticulars + ',' + paymentDetail.partyName;
        }
      }

      let paymentDetails = new paymentVoucherDTO();
      paymentDetails.partyId = this.txtcompany.partyId;
      paymentDetails.partyName = this.txtcompany.partyName;
      paymentDetails.refId = this.txtrefId;
      paymentDetails.particulars = this.txtparticulars;
      paymentDetails.voucherDate = this.txtVoucherDate;
      // paymentDetails.ledgerId = 6;
      // if(this.txtRadio == "Bank"){
        paymentDetails.ledgerId = 7;
        paymentDetails.bankId = this.txtselected.bankid;
      // }
      // paymentDetails.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
      this.paymentData.push(paymentDetails);
      this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
      this.expensevoucherservice.createGstrVoucher(this.txtVoucherDate, this.paymentData, this.txtNarration, this.txtcompany.partyId)
        .subscribe(res => {
          this.paymentVoucherres = res;
          console.log(this.paymentVoucherres);
          this.clearValue();
          this.paymentData = [];
          this.totalCredit = 0;
          this.toastr.success('Gstr voucher save successfully');
        }, error => {
          // this.toastr.error('Gstr voucher not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));

        })
    }
  }

  getAllGstrVoucher() {
    this.expensevoucherservice.getAllExpense().subscribe(res => {
      this.allGstrVoucher = res;
    })
  }

  updateGstrVoucher(gstr) {
    this.btnName = "Update";
    this.txtgstrid = gstr.id1;
    // this.txtVoucherDate = gstr.voucherDate;
    // this.txtLedgerName = gstr.ledgerName;
    // var abc = new SearchLedger();
    this.ledgerId = gstr.ledgerId;
    this.searchledger = gstr.ledgerName;
    // this.tempLedger = abc;
    this.txtcredit = gstr.debit;
  }

  i;
  deletePaymentData;
  deleteGstrVoucher(gstr) {
    this.deletePaymentData = gstr;

  }

  confirmDelete() {
    for (let abc of this.paymentData) {
      if (abc.id1 == this.deletePaymentData.id1) {
        this.i = this.deletePaymentData.id;
        let index = this.paymentData.indexOf(this.deletePaymentData);
        if (abc.id1 !== -1) {
          this.paymentData.splice(index, 1);
        }
      }
      $('#deleteModal').modal('hide');
      // $('.modal-backdrop').remove();
      this.getAmount();
    }
  }
}
