<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Purchase Return Record</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-lg-2 col-xl-2 col-6 bottom_margin">
                                    <!-- <label for="country" class="form-label">From <span
                                            style="color: red;">&nbsp;*</span></label> -->
                                    <input (click)="pickerFrom.open()" matInput [matDatepicker]="pickerFrom"
                                        class="form-control" [(ngModel)]="txtFormDate" name="txtFormDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerFrom" style="display: none;">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerFrom></mat-datepicker>
                                    <div class="invalid-feedback">
                                        Please enter a Form Date.
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xl-2 col-6 bottom_margin">
                                    <!-- <label for="country" class="form-label">To <span
                                            style="color: red;">&nbsp;*</span></label> -->
                                    <input (click)="pickerTO.open()" matInput [matDatepicker]="pickerTO" [min]="txtFormDate"
                                        class="form-control" [(ngModel)]="txtToDate" name="txtToDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerTO" style="display: none;">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerTO></mat-datepicker>
                                    <div class="invalid-feedback">
                                        Please enter a To Date.
                                    </div>
                                </div>
                                <div class="col-lg-1 col-xl-2 col-4 btn-container-end bottom_margin">
                                    <button class="btn btn-primary d-block" type="submit" (click)="getAllPurchaseReturnByDate()">Search</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Purchase Return Date</th>
                                            <th scope="col">Purchase Return Number</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col" class="text-center">Amount</th>
                                            <th scope="col" class="text-center">GST Amount</th>
                                            <th scope="col" class="text-center">Discount</th>
                                            <th scope="col" class="text-center">Net Amount</th>
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let purchaseReturn of allPurchaseReturn | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{purchaseReturn.purchasereturnDate | date :'dd-MMM-yyyy'}}</td>
                                            <td>{{purchaseReturn.purchasereturnNumber}}</td>
                                            <td>{{purchaseReturn.party.partyName}}</td>
                                            <td class="text-end">{{purchaseReturn.amount | appendIND}}</td>
                                            <td class="text-end">{{purchaseReturn.gstAmount | appendIND}}</td>
                                            <td class="text-end">{{purchaseReturn.discount | appendIND}}</td>
                                            <td class="text-end">{{purchaseReturn.netAmount - purchaseReturn.discount | appendIND}}</td>
                                            <!-- <td class="text-end">{{purchaseReturn.finalAmount | appendIND}}</td> -->
                                            <td class="text-end">
                                                <div class="card-close">
                                                    <div class="dropdown">
                                                      <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false" class="dropdown-toggle">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                      </button>
                                                      <div aria-labelledby="closeCard1" style="min-width: 120px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                            (click)="getPurchaseReturnDetail(purchaseReturn)" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-history"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
                                                            (click)="getPurchaseReturnPDFCategoryNoOfInvoiceData(purchaseReturn)" data-bs-toggle="modal"
                                                            data-bs-target="#pdfModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button *ngIf="userRole" type="button" class="btn btn-secondary btn-sm" style="margin-left: 5px; margin-bottom: 5px;"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                            (click)="deletePurchaseReturn(purchaseReturn)"data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allPurchaseReturn < 1 ">
                                            <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Purchase Return Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                              <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">SubCategory</th>
                                                        <th scope="col" >Quantity</th>
                                                        <th scope="col" class="text-center">Rate</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let purchaseReturndtl of allPurchaseReturnDetail">
                                                        <td>{{purchaseReturndtl.item.itemName}}</td>
                                                        <td>{{purchaseReturndtl.item.category.categoryName}}</td>
                                                        <td>{{purchaseReturndtl.item.subCategory.subCategoryName}}</td>
                                                        <td>{{purchaseReturndtl.quantity}}</td>
                                                        <td class="text-end">{{purchaseReturndtl.rate | appendIND}}</td>
                                    
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allPurchaseReturn < 1 ">
                                                        <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="pdfModal" tabindex="-1" aria-labelledby="pdfModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Download PDF</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- Do you want to download PDF ? -->
                <!-- <div class="row">
                    <div class="col-md-4 d-flex text-center align-items-center mb-2" *ngFor="let comp of allPDFCompany">
                        <a role="button" (click)="downloadPdfByCompany(comp,allPDFCompany.length)">{{comp.companyName}}</a>
                    </div>
                </div>
                <div class="text-center" *ngIf="allPDFCompany < 1 || allPDFCompany == null">
                    Company detail not found.
                </div> -->
                <ul *ngFor="let comp of allPDFCategory">
                    <li ><b>{{comp.companyName}}</b>
                      <ul *ngFor="let inv of comp.invoiceDetail">
                        <li role="button" (click)="downloadNewPDF(inv)">{{inv.invoicename}}</li>
                      </ul>
                    </li>
                </ul>
                <div class="text-center" *ngIf="allPDFCategory < 1 || allPDFCategory == null">
                    Invoice detail not found.
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="downloadPDF()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div> -->
        </div>
    </div>
</div>