import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CropsProductService {

  constructor(
    private http: Http,
  ) { }
  
  createCropsProduct(txtCropsName: string, txtCropsProduct: string, cropsId: string, productId: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      cropName: txtCropsName,
      productName: txtCropsProduct,
      cropsid: cropsId,
      productid: productId,
    });
    return this.http.post('/api/app/create/cropproduct', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateCropsProduct(txtCropsProductId: string, txtCropsName: string, txtCropsProduct: string, cropsId: string, productId: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: txtCropsProductId,
      cropName: txtCropsName,
      productName: txtCropsProduct,
      cropsid: cropsId,
      productid: productId,
    });
    return this.http.put('/api/app/update/cropproduct', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllCropsProduct() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/cropproduct', { headers })
      .pipe(map(res => res.json()));
  }

  deleteCropsProduct(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: deleteAppId,
    });
    return this.http.put('/api/app/delete/cropproduct', body, { headers })
      .pipe(map(res => res.json()));
  }

}
