<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Balance Sheet Report</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="company" class="form-label">Company Name</label>
                                            <select class="form-select" id="company" [(ngModel)]="txtCompany" name="txtCompany">
                                                <!-- <option>All</option> -->
                                                <option *ngFor="let company of allCompany" [value]="company.companyId">{{company.companyName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="searchStockValution()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Balance Sheet Report</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <!-- <div class="col-md-6">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Excel</button>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-lg-6 col-12 mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Liability</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>CAPITAL</td>
                                                <td class="text-end">{{txtProfitLoss | appendIND}}</td>
                                            </tr>
                                            <tr
                                                *ngFor="let liability of allbalancesheetliability | filter:search">
                                                <td>
                                                    <a href="javascript:void(0)" title="Details"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        (click)="getBalanceSheetDetail(liability)"
                                                        data-bs-toggle="modal" style="color: blue;"
                                                        data-bs-target="#exampleModal">{{liability.glaccountName}}
                                                    </a>
                                                </td>
                                                <td class="text-end">{{liability.amount | appendIND}}

                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th><b>Total</b></th>
                                                <th class="text-end"><b>{{totalLiabilityAmount + txtProfitLoss |
                                                        appendIND}}</b></th>
                                            </tr>
                                            <!-- <tr *ngIf="allItemStock < 1 ">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr> -->
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="col-md-6 col-lg-6 col-12 mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Asset</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let asset of allbalancesheetassets | filter:search ">
                                                <td><a href="javascript:void(0)" title="Details"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        (click)="getBalanceSheetDetail(asset)" data-bs-toggle="modal"
                                                        style="color: blue;"
                                                        data-bs-target="#exampleModal">{{asset.glaccountName}}
                                                    </a>

                                                </td>
                                                <td class="text-end">{{asset.amount | appendIND}}</td>
                                            </tr>
                                            <tr>
                                                <td>CLOSING STOCK</td>
                                                <td class="text-end">{{txtStock | appendIND}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th><b>Total</b></th>
                                                <th class="text-end"><b>{{totalAssetsAmount + txtStock | appendIND}}</b>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    (click)="clearHitoryData()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Ledger Name</th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><b>Opening Balance</b></td>
                                                        <td><b>{{totalOpeningBalance | appendIND}}</b></td>
                                                    </tr>
                                                    <tr *ngFor="let detail of allDetailData">
                                                    <td>
                                                        <a href="javascript:void(0)" title="Party Wise Details"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        (click)="getBalanceSheetDetailPartyWise(detail)" data-bs-toggle="modal"
                                                        style="color: blue;"
                                                        data-bs-target="#exampleModal1">{{detail.glaccountName}}</a>
                                                    </td>
                                                        <td>{{detail.amount | appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allDetailData < 1 ">
                                                        <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                        (click)="clearHitoryData()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Party Wise Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    (click)="clearHitoryDataPartyWise()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Party Name</th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of allDetailDataPartyWise | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                        <td>{{data.partyname}}</td>
                                                        <td>{{data.totalAmount | appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allDetailDataPartyWise < 1 ">
                                                        <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                        responsive="true"></pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                        (click)="clearHitoryDataPartyWise()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>