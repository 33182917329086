import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StockService } from '../service/stock/stock.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../service/excel/excel.service';

@Component({
  selector: 'app-product-wise-analysis',
  templateUrl: './product-wise-analysis.component.html',
  styleUrls: ['./product-wise-analysis.component.css']
})
export class ProductWiseAnalysisComponent implements OnInit {
  search;
  page: number = 1;
  config = {
    itemsPerPage: 10,
    currentPage: this.page,
    id: 'main'
  };
  datePipe = new DatePipe("en-US");
  isLoading: any = false;
  isClickSearch: any = false;
  historySearch: any;
  pageHistory: number = 1;
  txtProductName: any;
  productDetails: any;
  isType: any;
  isHistoryLoading: any = false;
  totalQuantity: any = 0;
  pageProductHistory: number = 1;
  isProductHistoryLoading: any = false;
  isClickDownload: any = false;

  constructor(
    private toastr: ToastrService,
    private stockService : StockService,
    private excelService : ExcelService,
  ) { }

  ngOnInit(): void {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "dd/MM/yyyy");
    this.txtFormDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "dd/MM/yyyy");
    this.txtToDate = new Date();
  }

  txtFormDate;
  txtToDate;
  allData;
  isEnable: any = false;
  SearchProductReportOverAll(){
    this.isClickSearch = true;
    this.isLoading = true;
    if (this.txtFormDate == null || this.txtFormDate == '' || this.txtFormDate == undefined) {
      this.isClickSearch = false;
      this.isLoading = false;
      this.toastr.error("From date is required");
    } else if (this.txtToDate == null || this.txtToDate == '' || this.txtToDate == undefined) {
      this.isClickSearch = false;
      this.isLoading = false;
      this.toastr.error("To date is required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      this.stockService.searchProductReportOverAll(this.txtFormDate, this.txtToDate).subscribe(res => {
        this.allData = res;
        this.isEnable = true;
        if(res){
          this.isClickSearch = false;
          this.isLoading = false;
        }
      }, error => {
        this.isEnable = false;
        this.isClickSearch = false;
        this.isLoading = false;
      })
    }
  }

  // getProductHistory(product : any,flage){
  //   this.isHistoryLoading = true;
  //   this.txtProductName = product.productname;
  //   this.isType = flage;
  //   if (this.txtFormDate == null || this.txtFormDate == '' || this.txtFormDate == undefined) {
  //     this.isHistoryLoading = false;
  //     this.toastr.error("From date is required");
  //   } else if (this.txtToDate == null || this.txtToDate == '' || this.txtToDate == undefined) {
  //     this.isHistoryLoading = false;
  //     this.toastr.error("To date is required");
  //   } else {
  //     this.totalQuantity = 0;
  //     this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
  //     this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
  //     this.stockService.searchProductReportHistory(flage,product.productid,this.txtFormDate, this.txtToDate).subscribe(res => { 
  //       this.productDetails = res;
  //       for (let data of res) {
  //         if(flage == 'PURCHASE' || flage == 'SALE'){
  //           this.totalQuantity = this.totalQuantity + data.quantity;
  //         } else if (flage == 'STOCK') {
  //           this.totalQuantity = this.totalQuantity + data.totalstock;
  //         }
  //       }
  //       if(res){
  //         this.isHistoryLoading = false;
  //       }
  //     }, error => {
  //       this.isHistoryLoading = false;
  //       let message = JSON.parse(error._body).message;
  //       this.toastr.error(JSON.stringify(message));
  //     })
  //   }
  // }

  productPurchaseOpeningStock: any = 0;
  previousdayDate: any;
  getMainProductHistory(product : any){
    this.isProductHistoryLoading = true;
    this.txtProductName = product.itemname;
    if (this.txtFormDate == null || this.txtFormDate == '' || this.txtFormDate == undefined) {
      this.isProductHistoryLoading = false;
      this.toastr.error("From date is required");
    } else if (this.txtToDate == null || this.txtToDate == '' || this.txtToDate == undefined) {
      this.isProductHistoryLoading = false;
      this.toastr.error("To date is required");
    } else {
      this.productPurchaseOpeningStock = 0;
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      this.previousdayDate = new Date(this.txtFormDate);
      this.previousdayDate.setDate(this.previousdayDate.getDate() - 1);
      this.previousdayDate = this.datePipe.transform(this.previousdayDate, 'yyyy-MM-dd');
      this.stockService.getProductPurchaseDtlOpeningStock(product.itemid,this.txtFormDate, this.previousdayDate).subscribe(res => { 
        this.productPurchaseOpeningStock = res.openingStock;
        if(res){
          // this.isProductHistoryLoading = false;
          this.getProductPurchaseDtl(product.itemid);
        }
      }, error => {
        // this.isProductHistoryLoading = false;
        this.getProductPurchaseDtl(product.itemid);
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  productPurchaseDetails: any;
  totalSaleQuantity: any = 0;
  totalPurchaseQuantity: any = 0;
  getProductPurchaseDtl(itemid : any){
    // this.isProductHistoryLoading = true;
    if (this.txtFormDate == null || this.txtFormDate == '' || this.txtFormDate == undefined) {
      this.isProductHistoryLoading = false;
      this.toastr.error("From date is required");
    } else if (this.txtToDate == null || this.txtToDate == '' || this.txtToDate == undefined) {
      this.isProductHistoryLoading = false;
      this.toastr.error("To date is required");
    } else {
      this.totalSaleQuantity = 0;
      this.totalPurchaseQuantity = 0;
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      this.stockService.getProductPurchaseDtl(itemid,this.txtFormDate, this.txtToDate).subscribe(res => { 
        this.productPurchaseDetails = res;
        // this.totalQuantity = this.productPurchaseOpeningStock;
        for (let data of res) {
          this.totalSaleQuantity = this.totalSaleQuantity + data.quantity;
          this.totalPurchaseQuantity = this.totalPurchaseQuantity + data.puquantity;
        }
        if(res){
          this.isProductHistoryLoading = false;
        }
      }, error => {
        this.isProductHistoryLoading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  exportAsXLSX(){
    if(this.allData.length == 0){
      // this.toastr.error("Please wait.");
      this.toastr.error("No Record Found.");
    } else {
      this.isClickDownload = true;
      var excelData = [];
      var txtFormDate = this.datePipe.transform(this.txtFormDate, 'dd-MMM-yyyy');
      var txtToDate = this.datePipe.transform(this.txtToDate, 'dd-MMM-yyyy');
      for (let excel of this.allData) {
        excelData.push({"Item Name":excel.itemname,"Subcategory Name":excel.subcategoryname,"Total Purchase Quantity":excel.totalPurchaseQuantity,"Total Purchase Avg Price(₹)":excel.totalPurchaseAvgPrice,
        "Total Credit Quantity":excel.totalCreditQuantity,"Total Credit Avg Price(₹)":excel.totalCreditAvgPrice,"Total Sale Quantity":excel.totalSaleQuantity,"Total Sale Avg Price(₹)":excel.totalSaleAvgPrice,
        "Total Debit Quantity": excel.totalDebitQuantity,"Total Debit Avg Price(₹)": excel.totalDebitAvgPrice});
      }
      this.excelService.exportAsExcelProductPurchaseReport('Product_Purchase_Report',excelData,txtFormDate,txtToDate);
      this.isClickDownload = false;
    }
  }

}
