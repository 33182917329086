import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CreditnoteService } from '../service/credit/creditnote.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
import { DatePipe } from '@angular/common';
declare var $: any

@Component({
  selector: 'app-credit-note-record',
  templateUrl: './credit-note-record.component.html',
  styleUrls: ['./credit-note-record.component.css']
})
export class CreditNoteRecordComponent implements OnInit {
  allPayment;
  allPaymentDetail;
  allPaymentById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;
  pageCreditNoteDtl: number = 1;
  public loading: boolean = false;
  datePipe = new DatePipe("en-US");

  constructor(
    private creditnoteService: CreditnoteService,
    private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getAllCreditNote();
  }

  Movecreditnote() {
    this.router.navigate(['creditnote']);
  }

  getAllCreditNote() {
    this.creditnoteService.getAllCreditNote().subscribe(res => {
      this.allPayment = res;
      // console.log(this.allPayment);

    })
  }

  paymentDetail(payment) {
    this.getCreditNoteById(payment.voucherMasterId)
    // this.txtDate = receipt.voucherDate;
    // console.log("Receipt Date",receipt);
  }

  getCreditNoteById(txtId) {
    this.creditnoteService.getCreditNoteById(txtId).subscribe(res => {
      this.allPaymentById = res;
      // console.log(this.allPaymentById);
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allPaymentById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }
  
  resBillPdf
  openPdfResponse
  creditNotePdf(credit) {
    this.creditnoteService.downloadCreditNote(credit.voucherMasterId).subscribe((data) => {
      this.resBillPdf = data;

      var newBlob = new Blob([this.resBillPdf], { type: "application/pdf" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Credit_Note.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponse.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
      this.resBillPdf = '';
      this.openPdfResponse = '';
    })
  }

  txtEmail;
  vouchermstId;
  getsendEmailData(credit) {
    // console.log(receipt);
    this.vouchermstId = credit.voucherMasterId;
  }
  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }
  creditNoteMailWithSendGrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.creditnoteService.creditNoteMailWithSendGrid(this.vouchermstId,this.txtEmail).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#emailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  sendMail() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("email address is required."); 
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.creditnoteService.sendCreditEmail(this.vouchermstId, this.txtEmail).subscribe(res => {
        // console.log(res);
        $("#emailModal").modal('hide');
        this.toastr.success("Email sent successfully");
      })
    }
  }

  deleteCreditId;
  deleteCredit(credit){
    this.deleteCreditId = credit.voucherMasterId;
  }

  resPaymentData;
  confirmDelete() {
    if (this.deleteCreditId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteCreditId)
        .subscribe(res => {
          this.resPaymentData = res;
          // console.log(this.resPaymentData);
          this.getAllCreditNote();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Credit notes deleted successfully');
        }, error => {
          this.toastr.error('Credit note not deleted');
        })
    }
  }

  creditDebitNoteDetails;
  creditDebitNoteDetail(detail){
    this.creditDebitNoteDetails = null;
    this.creditnoteService.creditDebitNoteDetailByVoucherId(detail.voucherMasterId).subscribe(res=>{
      this.creditDebitNoteDetails = res;
    },error=>{
      this.creditDebitNoteDetails = null;
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }
  allCreditNoteExcel: any;
  fileNameItem: any;
  excelCreditNote: any=[];
  getAllCreditNoteExcel() {
    this.creditnoteService.getAllCreditNoteExcel().subscribe(res => {
      this.allCreditNoteExcel = res;
      // console.log(this.allCreditNoteExcel);
      this.fileNameItem = "CreditNote";
      for (let dia of this.allCreditNoteExcel) {
      var voucherDate = this.datePipe.transform(dia.voucherDate, 'dd-MMM-yyyy');
      this.excelCreditNote.push({"Created Date": voucherDate,"Customer Name": dia.partyName,"Narration": dia.narration,"Final Amount(₹)": dia.debit,
      "Item Name": dia.itemName,"Quantity": dia.quantity,"Rate (₹)": dia.rate,"Amount (₹)": dia.totalAmount,"GST(%)": dia.gst,
      "GST Amount (₹)": dia.gstAmount,"Total Amount (₹)": dia.netAmount });
      }
      this.creditnoteService.exportExcelCreditDebitNote(this.fileNameItem,this.excelCreditNote);
    })
  }
}
