import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceMasterService } from '../service/invoice-master/invoice-master.service';
declare var $: any;

@Component({
  selector: 'app-invoice-master',
  templateUrl: './invoice-master.component.html',
  styleUrls: ['./invoice-master.component.css']
})
export class InvoiceMasterComponent implements OnInit {
  resInvoice;
  resUpInvoice;
  resDelInvoice;
  txtInvoiceName;
  txtInvoiceId;
  allInvoice;
  btnName = 'Save';
  search;
  page: number = 1;
  allInvoiceName = [];
  txtStaticInvoiceName;

  constructor(
    private invoiceMasterService: InvoiceMasterService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllInvoiceMaster();
    this.allInvoiceName = [];
    this.allInvoiceName = ["PESTICIDE","FERTILISER","SEEDS"];
  }

  getAllInvoiceMaster() {
    this.invoiceMasterService.getAllInvoiceMaster().subscribe(res => {
      this.allInvoice = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtInvoiceName = null;
    this.txtInvoiceId = null;
    this.txtStaticInvoiceName = null;
    this.allInvoiceName = ["PESTICIDE","FERTILISER","SEEDS"];
  }

  toUpperCaseBrand() {
    const temp = this.txtInvoiceName;
    this.txtInvoiceName = temp.toUpperCase();
    return this.txtInvoiceName;
  }

  createInvoiceMaster() {
    if (this.txtStaticInvoiceName == null || this.txtStaticInvoiceName == '-:Invoice Type:-' || this.txtStaticInvoiceName == undefined) {
      this.toastr.error("Invoice type is required");
    } else if (this.txtInvoiceName == null || this.txtInvoiceName.trim() == '' || this.txtInvoiceName == undefined) {
      this.toastr.error("Invoice name is required");
    } else {
      if (this.txtInvoiceId == null) {
        this.invoiceMasterService.createInvoiceMaster(this.txtStaticInvoiceName,this.txtInvoiceName)
          .subscribe(res => {
            this.resInvoice = res;
            this.clearData();
            this.getAllInvoiceMaster();
            this.toastr.success('Invoice save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.invoiceMasterService.updateInvoiceMaster(this.txtInvoiceId,this.txtStaticInvoiceName, this.txtInvoiceName).subscribe(res => {
          this.resUpInvoice = res;
          this.clearData();
          this.getAllInvoiceMaster();
          this.toastr.success('Invoice updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateInvoiceMaster(inv) {
    this.btnName = 'Update';
    this.txtInvoiceId = inv.invoiceId;
    this.txtInvoiceName = inv.invoiceName;
    this.txtStaticInvoiceName = inv.staticInvoiceName;
  }

  deleteid;
  deleteInvoiceMaster(inv) {
    this.deleteid = inv.invoiceId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.invoiceMasterService.deleteInvoiceMaster(this.deleteid).subscribe(res => {
        this.resDelInvoice = res;
        this.clearData();
        this.getAllInvoiceMaster();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Invoice deleted successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }
}
