<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Crop Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <!-- <div class="col-12">
                                            <label for="country" class="form-label">Product<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtProductApp"
                                                [(ngModel)]="txtProductApp" required="">
                                                <option>-:Select Product:-</option>
                                                <option *ngFor="let pdt of allProductApp"
                                                    [value]="pdt.productName">{{pdt.productName}}</option>

                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid product App.
                                            </div>
                                        </div> -->
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crop<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtCropsId"
                                                name="txtCropsId">
                                            <input type="text" class="form-control" id="ProductApp"
                                                placeholder="Enter Crop" name="txtCrops"
                                                [(ngModel)]="txtCrops" required="required" (focusout)="toUpperCaseCrops()" />
                                            <div class="invalid-feedback">
                                                Please enter crop name.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crop Gujarati<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="CropsGuj"
                                                placeholder="Enter Crop Gujarati" name="txtCropsGuj" [(ngModel)]="txtCropsGuj"
                                                required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Crop gujarati name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-12">
                                            <label for="first" class="form-label">Crops Description<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="CropsDes"
                                                placeholder="Enter Crops Description" name="txtCropsDes"
                                                [(ngModel)]="txtCropsDes" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Crops description.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crops Description Gujarati<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="ProductDesGuj"
                                                placeholder="Enter Crops Description Gujarati" name="txtCropsDesGuj" 
                                                [(ngModel)]="txtCropsDesGuj" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Crops description gujarati.
                                            </div>
                                        </div> -->
                                        <div class="row g-3">
                                            <div class="col-md-10 col-sm-8 col-8">
                                                <div class="form-group">
                                                    <!-- <label style="opacity:0;">Image</label> -->
                                                    <label for="formFile" class="form-label">Image</label>
                                                    <input class="form-control" type="file" id="formFile" name="txtImage"
                                                    [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                                <img [src]="imagePreview" height="50px" width="50px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createCrops()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Crop</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <!-- <th scope="col">Product</th> -->
                                                <th scope="col">Crop</th>
                                                <th scope="col">Crop Gujarati</th>
                                                <!-- <th scope="col">Crops Description</th>
                                                <th scope="col">Crops Description Gujarati</th> -->
                                                <th class="text-center">Image</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let crp of allCrops | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <!-- <td>{{crp.productApp.productName}}</td> -->
                                                <td>{{crp.cropsName}}</td>
                                                <td>{{crp.cropsNameGuj}}</td>
                                                <!-- <td>{{crp.cropsDescription}}</td>
                                                <td>{{crp.cropsDescriptionGuj}}</td> -->
                                                <td class="text-center"><img [src]="crp.image" height="50px" width="50px">
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateCrops(crp)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteDataCrops(crp)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allCrops < 1">
                                                <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>