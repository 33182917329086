<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Search Details</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <!-- <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">From <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtFormDate"
                                                name="txtFormDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Ledger <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="ledger" [(ngModel)]="txtLedger" name="txtLedger">
                                                <option *ngFor="let grp of allGroup" [value]="grp.glaccountId">{{grp.glaccountName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="searchAnalysis()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <div class="portlet-header">Search Details
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Loans And Advances (Assets)</th>
                                            <th scope="col" class="text-center">As On {{txtFormDate}}</th>
                                            <!-- <th scope="col">Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let srch of resAssetsData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{srch.partyName}}</td>
                                            <td class="text-end">{{srch.debit | appendIND}}</td>
                                            <!-- <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                    (click)="getSearchSubDetail(srch)" data-bs-toggle="modal"
                                                    data-bs-target="#detailModal">
                                                    <i class="fas fa-history"></i>
                                                </button>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="reSearchData.length == 0 ">
                                            <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="modal fade" id="detailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                        <div class="portlet">
                            <div class="portlet-content">
                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col" class="text-center">Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let srchdetail of resSelectedSubDetail">
                                                <td>{{srchdetail.itemname}}</td>
                                                <td>{{srchdetail.quantity}}</td>
                                                <td class="text-end">{{srchdetail.rate | appendIND}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="resSelectedSubDetail.length == 0 ">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>