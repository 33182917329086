import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceMasterService } from '../service/invoice-master/invoice-master.service';
import { CategoryService } from '../service/category/category.service';
declare var $: any;

@Component({
  selector: 'app-invoice-category',
  templateUrl: './invoice-category.component.html',
  styleUrls: ['./invoice-category.component.css']
})
export class InvoiceCategoryComponent implements OnInit {
  resInvoiceCat;
  resUpInvoiceCat;
  resDelInvoiceCat;
  txtInvoiceCatId;
  txtInvoice;
  allInvoice;
  txtCategorys = [];
  allCategory = [];
  dropdownSettingsCategory = {};
  allInvoiceCategory;
  btnName = 'Save';
  search;
  page: number = 1;
  txtCategory;
  isEnableCatUpdate = false;
  isEnableMultiCat = false;

  constructor(
    private invoiceMasterService: InvoiceMasterService,
    private toastr: ToastrService,
    private categoryservice: CategoryService,
  ) { }

  ngOnInit(): void {
    this.getAllInvoiceMaster();
    this.getAllCategory();
    this.getAllInvoiceCategory();
  }

  getAllInvoiceMaster() {
    this.invoiceMasterService.getAllInvoiceMaster().subscribe(res => {
      this.allInvoice = res;
    })
  }

  getAllCategory() {
    this.categoryservice.gatAllCategory().subscribe((data: any[]) => {
      this.allCategory = data;
      // this.dropdownList = data;
      this.dropdownSettingsCategory = {
        singleSelection: false,
        idField: 'categoryId',
        textField: 'categoryName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        itemsShowLimit: 3,
      };
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  getAllInvoiceCategory() {
    this.invoiceMasterService.getAllInvoiceCategory().subscribe(res => {
      this.allInvoiceCategory = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtInvoice = null;
    this.txtCategorys = [];
    this.txtInvoiceCatId = null;
    this.txtCategory = null;
    this.isEnableCatUpdate = false;
    this.isEnableMultiCat = false;
  }

  createInvoiceCategory() {
    if (this.txtInvoice == null || this.txtInvoice == '' || this.txtInvoice == undefined || this.txtInvoice == '-:Select Invoice:-') {
      this.toastr.error("Invoice name is required");
    } else if (this.isEnableMultiCat == false &&(this.txtCategorys == null || this.txtCategorys.length == 0 || this.txtCategorys == undefined)) {
        this.toastr.error("Invoice category is required");
    } else if (this.isEnableCatUpdate == true && (this.txtCategory == null || this.txtCategory == '-:Select Category:-'|| this.txtCategory == undefined)) {
      this.toastr.error("Invoice category is required");
    } else {
      if (this.txtInvoiceCatId == null) {
        this.invoiceMasterService.createInvoiceCategory(this.txtInvoice,this.txtCategorys)
          .subscribe(res => {
            this.resInvoiceCat = res;
            this.clearData();
            this.getAllInvoiceCategory();
            this.toastr.success('Invoice category save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.invoiceMasterService.updateInvoiceCategory(this.txtInvoiceCatId, this.txtInvoice,this.txtCategory).subscribe(res => {
          this.resUpInvoiceCat = res;
          this.clearData();
          this.getAllInvoiceCategory();
          this.toastr.success('Invoice category updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateInvoiceCategory(inv) {
    this.btnName = 'Update';
    this.isEnableCatUpdate = true;
    this.isEnableMultiCat = true;
    this.txtInvoiceCatId = inv.invoiceCategoryId;
    this.txtInvoice = inv.invoiceMaster.invoiceId;
    this.txtCategory = inv.category.categoryId;
    // this.txtCategorys = inv.categoryDTOs;
  }

  deleteid;
  deleteInvoiceCategory(inv) {
    this.deleteid = inv.invoiceCategoryId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.invoiceMasterService.deleteInvoiceCategory(this.deleteid).subscribe(res => {
        this.resDelInvoiceCat = res;
        this.clearData();
        this.getAllInvoiceCategory();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Invoice category deleted successfully');
      }, error => {
        // this.toastr.error('Invoice category not deleted');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

}
