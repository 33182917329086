<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>SubCategory Master</h2> -->

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Sub Category Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="country" class="form-label">Category<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtsubcategoryid"
                                                name="txtsubcategoryid">
                                            <select class="form-select" id="country" name="txtCategory"
                                                [(ngModel)]="txtCategory" required="">
                                                <option>-:Select Category:-</option>
                                                <option *ngFor="let category of allCategory"
                                                    [value]="category.categoryName">{{category.categoryName}}</option>

                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid country.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Sub Category<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="Category"
                                                placeholder="Enter Sub Category" name="txtSubCategory"
                                                [(ngModel)]="txtSubCategory" required="required" (focusout)="toUpperCaseSubCategory()"/>
                                            <div class="invalid-feedback">
                                                Please enter Sub Category name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createSubCategory()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Sub Category</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6 col-12">
                                        <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Category</th>
                                                <th scope="col">Sub Category</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let subCat of allSubCategory | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{subCat.category.categoryName}}</td>
                                                <td>{{subCat.subCategoryName}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updatesubcategory(subCat)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteData(subCat)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button> -->
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allSubCategory < 1">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                responsive="true"></pagination-controls>
                                            </li>
                                            <!-- <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">1</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">3</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>