import { stockDTO } from "./stockDTO";

export class transfer{
    id1: number;
   // manufactureDate: string;
    itemId: string;
    itemName: string;
    categoryName: string;
    subCategoryName: string;
    brandName: string;
    quantity: number;
    stockDTOs:Array<stockDTO>
}