<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Category Master</h2> -->

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Crops Product</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-lg-4 col-xl-12 col-12 position-relative">
                                            <label for="last" class="form-label">Crop Name<span style="color: red;" class="me-2">&nbsp;*</span></label>
                                            <input type="text" [value]="searchCrops" ngxTypeahead [taList]="searchCropsName"
                                                [taItemTpl]="myTemp" (taSelected)="selectedCrops($event)"
                                                id="ItemName" [(ngModel)]="txtCropsName" name="txtCropsName"
                                                placeholder="Enter Crop Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myTemp let-item>
                                                <div class="item-name">
                                                    {{item.result.cropsName}}
                                                </div>
                                            </ng-template>

                                            <div class="invalid-feedback">
                                                Please enter crops name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-12">
                                            <label for="first" class="form-label">Crops Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="cropsname"
                                                placeholder="Enter Crops Name" name="txtCropsName" [(ngModel)]="txtCropsName"
                                                required="required" />
                                            <div class="invalid-feedback">
                                                Please enter crops name.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-12 col-12 position-relative">
                                            <label for="last" class="form-label">Product Name<span style="color: red;" class="me-2">&nbsp;*</span></label>
                                            <input type="text" [value]="searchProduct" ngxTypeahead
                                                [taList]="searchProductName" [taItemTpl]="myProduct"
                                                (taSelected)="selectedProduct($event)" id="PartyName"
                                                [(ngModel)]="txtCropsProduct" name="txtCropsProduct"
                                                placeholder="Enter Crop Product"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus"/>
                                            <ng-template #myProduct let-item>
                                                <div class="item-name">
                                                    {{item.result.productName}}
                                                </div>
                                            </ng-template>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtCropsProductId"
                                                name="txtCropsProductId">
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-12">
                                            <label for="first" class="form-label">Product Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtCropsProductId"
                                                name="txtCropsProductId">
                                            <input type="text" class="form-control" id="cropsProduct"
                                                placeholder="Enter Crops Product" name="txtCropsProduct"
                                                [(ngModel)]="txtCropsProduct" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter product name.
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createCropsProduct()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Crops Product</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Crop Name</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let pdt of allCropsProduct | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{pdt.crops.cropsName}}</td>
                                                <td>{{pdt.productApp.productName}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateCropsProduct(pdt)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteCropsProduct(pdt)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allCropsProduct < 1">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>