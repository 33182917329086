import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SaleChallanService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  addSaleDetailChallan(txtsaleDate: string, txtsaleCode: string, txtCellNUmber: string, txtpartyName:string,txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, sale: Array<any>){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleDate: txtsaleDate,
      saleNumber: txtsaleCode,
      cellNumber: txtCellNUmber,
      partyName:txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      saleDetailDTOs: sale,
    });
    return this.http.post('/api/create/salechallan', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllsale(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/salechallan', { headers })
      .pipe(map(res => res.json()));
  }

  getSaleNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salenumber', { headers })
      .pipe(map(res => res.json()));
  }

  getSaleChallanNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salechallannumber', { headers })
      .pipe(map(res => res.json()));
  }

  addSaleDetailStockCheck(itemid:string,quantity:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      // itemName: itemname,
      itemid: itemid,
      quantity: quantity
    });

    return this.http.put('/api/get/stock/check', body,{ headers })
      .pipe(map(res => res.json()));
  }

  getStockByItemPerBatch(itemname:string,quantity:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemid : itemname,
      quantity: quantity
    });

    return this.http.put('/api/get/stock/check/byitem', body,{ headers })
      .pipe(map(res => res.json()));
  }

  saveSaleChallanToSale(txtsaleDate: string, txtsaleCode: string,txtchallanNumber:string , txtCellNUmber: string, txtpartyName:string,txtgstAmount: number, txtamount: number, txtDiscount: string, txtnetAmount: string, sale: Array<any>){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleDate: txtsaleDate,
      saleNumber: txtsaleCode,
      challanNumber: txtchallanNumber,
      cellNumber: txtCellNUmber,
      partyName:txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      saleDetailDTOs: sale,
      type:"CREDIT"
    });
    return this.http.post('/api/create/challan/sale', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  deleteSaleChallan(deleteSaleChallenId: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);

    return this.http.delete('/api/delete/salechallan/' + deleteSaleChallenId, { headers: headers })
      .pipe(map(res => res));
  }

  getChallanDetailByChallanId(challanid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salechallandetail/from/salechallan/' + challanid,{ headers })
      .pipe(map(res => res.json()));
  }
  updateSaleChallanDetail(txtsaledetailid: string,txtItemName: string,itemId: string,txtCategoryName: string,txtSubCategoryName: string,txtBrandName: string,txtquantity: string,txtrate: string,txtStockDTOs: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      // challandetailid: txtsaledetailid,
      saledetailid: txtsaledetailid,
      itemName: txtItemName,
      itemId: itemId,
      categoryName: txtCategoryName,
      subCategoryName: txtSubCategoryName,
      brandName: txtBrandName,
      quantity: txtquantity,
      rate: txtrate,
      stockDTOs: txtStockDTOs
    });
    return this.http.put('/api/update/salechallandetail', body, { headers })
      .pipe(map(res => res.json()));
  }

  getChallanPDFCategoryNoOfInvoiceData(challanid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/challan/pdf/category/noofinvoice/detail/' + challanid,{ headers })
      .pipe(map(res => res.text()));
  }
  // new sale challan PDF by category
  public downloadSaleChallanPesticideBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/salechallan/pesticide/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadSaleChallanFertiliserBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/salechallan/fertiliser/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadSaleChallanSeedsBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/salechallan/seeds/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

}
