import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(
    private http: Http,
  ) { }

  createUnit(txtUnit: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      unitName : txtUnit,
    });
    return this.http.post('/api/create/unit', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateUnit(txtUnitId: string, txtUnit: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      unitName : txtUnit,
      unitId : txtUnitId,
    });
    return this.http.put('/api/update/unit', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllUnit(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/unit', { headers })
      .pipe(map(res => res.json()));

  }
  deleteUnit(unitid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      unitId : unitid,
    });
    return this.http.put('/api/delete/unit', body, { headers })
      .pipe(map(res => res.json()));
  }

  importExcelUnitINIT(resUnit){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/unit', resUnit, { headers })
      .pipe(map(res => res));
  }
}
