import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GroupMasterPartyService {

  constructor(
    private http: Http,
  ) { }

  createGroup(txtGroupName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      groupName : txtGroupName,
    });
    return this.http.post('/api/create/group', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateGroup(txtGroupId: string, txtGroupName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      groupName : txtGroupName,
      groupId : txtGroupId,
    });
    return this.http.put('/api/update/group', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllGroup(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/Group', { headers })
      .pipe(map(res => res.json()));

  }
  deleteGroup(txtGroupId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      groupId : txtGroupId,
    });
    return this.http.put('/api/delete/Group', body, { headers })
      .pipe(map(res => res.json()));
  }

  getPartyWhatsaapMessage(txtGroupName: string, txtFormDate: string, txtToDate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/party/group/' + txtGroupName + '/' + txtFormDate + '/' + txtToDate , { headers })
      .pipe(map(res => res.json()));

  }
}
