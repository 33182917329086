export class paymentVoucherDTO {
    id1: number;
    voucherDate: Date;
    partyId: number;
    debit: number;
    status: string;
    partyName: string;
    // party:Party;
    refId: string;
    particulars: string;
    receivedAmount: number;
    netAmount: number;
    numbers: string;
    amount: number;
    ledgerId:number;
    ledgerName:number;
    bankId: number;
}