import { Component, OnInit } from '@angular/core';
import { ExcelService } from "../service/excel/excel.service";
import { DatePipe } from '@angular/common';
import { Gstr1PurchaseDto } from '../service/excel/Gstr1PurchaseDto';
import { ToastrService } from "ngx-toastr";
import { PurchaseService } from '../service/purchase/purchase.service';
import { GstrService } from '../service/gstr/gstr.service';
import { hsnDto } from '../service/excel/hsnDto';
import { masterDto } from '../service/excel/masterDto';
import { docsDto } from '../service/excel/docsDto';
import { cdnrDto } from '../service/excel/cdnrDto';
import { CompanyService } from '../service/company/company.service';
import { PurchasePlanService } from '../service/purchase_plan/purchase-plan.service';

@Component({
  selector: 'app-gstr1-purchase-detail',
  templateUrl: './gstr1-purchase-detail.component.html',
  styleUrls: ['./gstr1-purchase-detail.component.css']
})
export class Gstr1PurchaseDetailComponent implements OnInit {
  txtCompany;
  allCompany;
  txtType;

  selectMonth;
  selectYear
  datePipe = new DatePipe("en-US");
  reSearchData =[];
  ExcelData = [];
  partyName = [];
  partyLength;
  InvoiceNumber = [];
  invoiceLength;
  TotalAmount = 0;
  TotalTaxableValue = 0;
  TotalCess=0;
  fileName;

  b2csSearchData=[];
  b2cspartyName =[];
  b2csInvoiceNumber=[];
  b2csTotalAmount=0;
  b2csTotalTaxableValue=0;
  b2cspartyLength=0;
  b2csinvoiceLength=0;
  b2csExcelData=[];
  b2csTotalCess=0;

  HsnSearchData=[];
  ExcelDataHSN=[];
  TotalCesshsn=0;
  TotalValue=0;
  TotalTaxableValue1=0;
  TotalIntegratedTax=0;
  TotalCentralTax=0;
  TotalStateUTTax=0;
  NoofHSN=0;
  hsnCode=[];

  MasterSearchData = [];
  ExcelDataMaster = [];
  TotalCessmaster = 0;
  TotalTaxableValue2 = 0;

  DocsSearchData = [];
  ExcelDataDocs = [];
  TotalCancelldedocs = 0;
  TotalNumber = 0;

  cdnrSearchData = [];
  ExcelDataCdnr = [];
  ReceiverName = [] ;
  VoucherNumber = [];
  ReceiverLength;
  VoucherLength;
  TotalVoucherValue = 0;
  TotalTaxableValueCdnr = 0;
  TotalCessCdnr=0;
  TotalInvoiceAdvanceReceiptNumber = 0;

  constructor(
    private excelservice: ExcelService,
    private toastr: ToastrService,
    private purchaseservice: PurchaseService,
    private purchasePlanService: PurchasePlanService,
    private gstrservice: GstrService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    var d = new Date();
    this.selectMonth = d.getMonth();
    this.selectYear = d.getFullYear();
    this.getAllCompany();
  }
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      console.log("all Party",this.allCompany);
    })
  }

  getSave(){
    if (this.selectMonth == undefined || this.selectMonth == null || this.selectMonth == '') {
      return this.toastr.error('Month must be required');
    } else if (this.selectYear == undefined || this.selectYear == null || this.selectYear == '') {
      return this.toastr.error('Year must be required');
    } else if (this.txtCompany == "" || this.txtCompany == null || this.txtCompany == undefined || this.txtCompany == "-:Select Company Name:-") {
      this.toastr.error("Company must be required");
    } else if (this.txtType == "" || this.txtType == null || this.txtType == undefined || this.txtType == "-:Select Type:-") {
      this.toastr.error("Type must be required");
    } else {
      if(this.txtType == "GSTR1"){
        this.excelBhandarSaleDetail();
      } else if (this.txtType == "GSTR3B"){
        this.getGstr3BBhandar();
      } else {

      }
    }
  }

  b2csexcelNigamSaleData(){
    this.b2csSearchData = [];
    this.purchaseservice.searchB2CSDetail("1",this.selectMonth,this.selectYear).subscribe(res => {
      this.b2csSearchData = res;
      console.log(this.b2csSearchData);      
      this.HsnexcelDetail();
    },error =>{
      this.HsnexcelDetail();
    });
  }
  b2csexcelBhandarSaleData(){
    this.b2csSearchData = [];
    this.purchaseservice.searchB2CSDetail(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.b2csSearchData = res;//b2ba Tab Data
      console.log(this.b2csSearchData);
      this.HsnexcelbhandarDetail();
    },error =>{
      this.HsnexcelbhandarDetail();
    });
  }
  HsnexcelDetail() {
    this.HsnSearchData = [];
    // this.MasterSearchData = [];
    // this.DocsSearchData = [];
    this.purchaseservice.searchHSNDetail("1",this.selectMonth,this.selectYear).subscribe(res => {
      this.HsnSearchData = res;
      console.log(this.HsnSearchData);
      this.cdnrNigamExcelDetail();
    }, error => {
      this.cdnrNigamExcelDetail();
    });
  }
  HsnexcelbhandarDetail() {
    this.HsnSearchData = [];
    this.purchaseservice.searchHSNDetail(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.HsnSearchData = res;//hsn Tab Data
      console.log(this.HsnSearchData);
      this.masterexcelbhandarDetail();
    },error => {
      this.masterexcelbhandarDetail();
    });
  }
  excelNigamSaleDetail() {
    this.purchaseservice.searchGstrSaleNigamDetail("1",this.selectMonth,this.selectYear).subscribe(res => {
      this.reSearchData = res;
      console.log(this.reSearchData);
      this.b2csexcelNigamSaleData();
      // this.HsnexcelDetail();
      this.fileName = "BEEJ_NIGAM_GSTR1"
      // this.excelHeaderData();
    }, error => {
      // this.toastr.error("No Data Found");
      this.b2csexcelNigamSaleData();
      this.fileName = "BEEJ_NIGAM_GSTR1"
    });
  }
  excelBhandarSaleDetail() {
    this.purchaseservice.searchGstrSaleBhandarDetail(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.reSearchData = res;//b2b Tab Data
      console.log(this.reSearchData);
      this.b2csexcelBhandarSaleData();
      // this.HsnexcelbhandarDetail();
      this.fileName = "GSTR1_"+this.txtCompany.companyName;
      // this.excelHeaderData();
    }, error => {
      // this.toastr.error("No Data Found");
      this.b2csexcelBhandarSaleData();
      this.fileName = "GSTR1_"+this.txtCompany.companyName;
    });
  }
  masterexcelbhandarDetail() {
    this.MasterSearchData = [];
    this.purchaseservice.searchB2CSMasterDetail(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.MasterSearchData = res;//b2cs Tab Data
      console.log(this.MasterSearchData);
      this.docsexcelbhandarDetail();
    },error => {
      this.docsexcelbhandarDetail();
    });
  }
  NatureofDocumentData= ["Invoices for outward supply","Credit Note","Debit Note"];
  docsexcelbhandarDetail() {
    this.DocsSearchData = [];
    this.purchaseservice.searchDocsDetail(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.DocsSearchData = res;//docs Tab Data
      console.log(this.DocsSearchData);
      this.cdnrExcelBhandarDetail();
    },error => {
      this.cdnrExcelBhandarDetail();
    });
  }
  cdnrNigamExcelDetail() {
    this.cdnrSearchData = [];
    this.MasterSearchData = [];
    this.DocsSearchData = [];
    this.purchaseservice.searchCDNRDetail("1",this.selectMonth,this.selectYear).subscribe(res => {
      this.cdnrSearchData = res;
      console.log(this.cdnrSearchData);
      this.excelHeaderData();
    }, error => {
      this.excelHeaderData();
    });
  }
  cdnrExcelBhandarDetail() {
    this.cdnrSearchData = [];
    this.purchaseservice.searchCDNRDetail(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.cdnrSearchData = res;//cdnr Tab Data
      console.log(this.cdnrSearchData);
      this.excelHeaderData();
    },error => {
      this.excelHeaderData();
    });
  }

  excelHeaderData() {
    this.partyName = [];
    this.InvoiceNumber = [];
    this.TotalAmount = 0;
    this.TotalTaxableValue = 0;
    this.partyLength = 0;
    this.invoiceLength = 0;

    this.b2cspartyName = [];
    this.b2csInvoiceNumber = [];
    this.b2csTotalAmount = 0;
    this.b2csTotalTaxableValue = 0;
    this.b2cspartyLength = 0;
    this.b2csinvoiceLength = 0;

    this.hsnCode = [];
    this.TotalValue=0;
    this.TotalTaxableValue1=0;
    this.TotalIntegratedTax=0;
    this.TotalCentralTax=0;
    this.TotalStateUTTax=0;
    this.NoofHSN=0;

    this.TotalTaxableValue2=0;

    this.TotalNumber=0;

    this.ReceiverName = [];
    this.VoucherNumber = [];
    this.ReceiverLength = 0;
    this.VoucherLength = 0;
    this.TotalVoucherValue = 0;
    this.TotalTaxableValueCdnr = 0;
    this.TotalInvoiceAdvanceReceiptNumber = 0;
    for (let excel of this.reSearchData) {
      var amt = 0;
      amt = amt + Number(excel.netamount);
      this.TotalTaxableValue = this.TotalTaxableValue + amt;

      if (this.partyName.indexOf(excel.partyName) !== -1) {
      } else {
        this.partyName.push(excel.partyName);
        this.partyLength = this.partyName.length;
      }
      if (this.InvoiceNumber.indexOf(excel.invoicenumber) !== -1) {
      } else {
        this.InvoiceNumber.push(excel.invoicenumber);
        this.invoiceLength = this.InvoiceNumber.length;

        var Amt = 0;
        Amt = Amt + Number(excel.invoicevalue);
        this.TotalAmount = this.TotalAmount + Amt;
      }
    }

    for (let b2cs of this.b2csSearchData) {
      var amt = 0;
      amt = amt + Number(b2cs.netamount);
      this.b2csTotalTaxableValue = this.b2csTotalTaxableValue + amt;

      if (this.b2cspartyName.indexOf(b2cs.partyName) !== -1) {
      } else {
        this.b2cspartyName.push(b2cs.partyName);
        this.b2cspartyLength = this.b2cspartyName.length;
      }
      if (this.b2csInvoiceNumber.indexOf(b2cs.invoicenumber) !== -1) {
      } else {
        this.b2csInvoiceNumber.push(b2cs.invoicenumber);
        this.b2csinvoiceLength = this.b2csInvoiceNumber.length;

        var Amt = 0;
        Amt = Amt + Number(b2cs.invoicevalue);
        this.b2csTotalAmount = this.b2csTotalAmount + Amt;
      }
    }

    for (let excelhsn of this.HsnSearchData) {
      var Amt1 = 0;
      Amt1 = Amt1 + Number(excelhsn.totalvalue);
      this.TotalValue = this.TotalValue + Amt1;

      var amt1 = 0;
      amt1 = amt1 + Number(excelhsn.totaltaxablevalue);
      this.TotalTaxableValue1 = this.TotalTaxableValue1 + amt1;

      var int = 0;
      int = int + Number(excelhsn.totaligst);
      this.TotalIntegratedTax = this.TotalIntegratedTax + int;

      var cen = 0;
      cen = cen + Number(excelhsn.totalcgst);
      this.TotalCentralTax = this.TotalCentralTax + cen;

      var state = 0;
      state = state + Number(excelhsn.totalsgst);
      this.TotalStateUTTax = this.TotalStateUTTax + state;

      if (this.hsnCode.indexOf(excelhsn.hsncode) !== -1) {
      } else {
        this.hsnCode.push(excelhsn.hsncode);
        this.NoofHSN = this.hsnCode.length;
      }
    }

    for (let excelB2CSmst of this.MasterSearchData) {
      var amt1 = 0;
      amt1 = amt1 + Number(excelB2CSmst.total);
      this.TotalTaxableValue2 = this.TotalTaxableValue2 + amt1;
    }

    for (let exceldoc of this.DocsSearchData) {
      var numb = 0;
      numb = numb + Number(exceldoc.totalNumber);
      this.TotalNumber = this.TotalNumber + numb;
    }

    for (let excelcdnr of this.cdnrSearchData) {
      var Amt = 0;
      Amt = Amt + Number(excelcdnr.voucherValue);
      this.TotalVoucherValue = this.TotalVoucherValue + Amt;

      var amt = 0;
      amt = amt + Number(excelcdnr.taxableValue);
      this.TotalTaxableValueCdnr = this.TotalTaxableValueCdnr + amt;

      var int = 0;
      // int = int + Number(0.00);
      this.TotalInvoiceAdvanceReceiptNumber = this.TotalInvoiceAdvanceReceiptNumber + int;

      if (this.ReceiverName.indexOf(excelcdnr.partyName) !== -1) {
      } else {
        this.ReceiverName.push(excelcdnr.partyName);
        this.ReceiverLength = this.ReceiverName.length;
      }
      if (this.VoucherNumber.indexOf(excelcdnr.voucherNumber) !== -1) {
      } else {
        this.VoucherNumber.push(excelcdnr.voucherNumber);
        this.VoucherLength = this.VoucherNumber.length;
      }
    }

    this.exportAsXLSX();
  }
  
  exportAsXLSX(): void {
    this.ExcelData = [];
    this.b2csExcelData = [];
    this.TotalCess=0;
    this.ExcelDataHSN = [];
    this.TotalCesshsn=0;
    this.ExcelDataMaster = [];
    this.TotalCessmaster=0;
    this.ExcelDataDocs = [];
    this.TotalCancelldedocs = 0;
    this.ExcelDataCdnr = [];
    for (let excel of this.reSearchData) {
      let data = new Gstr1PurchaseDto();
      data.GSTINofRecipient = excel.gstnumber;
      data.ReceiverName = excel.partyName;
      data.InvoiceNumber = excel.invoicenumber;
      data.Invoicedate = this.datePipe.transform(excel.invoicedate, 'dd-MMM-yyyy');
      data.InvoiceValue = parseFloat(Number(excel.invoicevalue).toFixed(2));
      // data.PlaceOfSupply = "24-Gujarat";
      data.PlaceOfSupply = excel.statecode+"-"+excel.state;
      data.ReverseCharge = "N";
      data.ApplicableofTaxRate = "";
      data.InvoiceType = "Regular";
      data.ECommerceGSTIN = "";
      data.Rate = parseFloat(Number(excel.totalgstRate).toFixed(2));
      var tax = 0;
      // tax = Number(excel.totalCGSTAmount) + Number(excel.totalSGSTAmount)
      data.TaxableValue = Number(excel.netamount);
      data.CessAmount = 0;
      var totcess=0;
      var totcess= totcess+ data.CessAmount;
      this.TotalCess=this.TotalCess+totcess;
      this.ExcelData.push(data);
    }

    for (let b2cs of this.  b2csSearchData) {
      let data = new Gstr1PurchaseDto();
      data.GSTINofRecipient = b2cs.gstnumber;
      data.ReceiverName = b2cs.partyName;
      data.InvoiceNumber = b2cs.invoicenumber;
      data.Invoicedate = this.datePipe.transform(b2cs.invoicedate, 'dd-MMM-yyyy');
      data.InvoiceValue = parseFloat(Number(b2cs.invoicevalue).toFixed(2));
      data.PlaceOfSupply = "24-Gujarat";
      data.ReverseCharge = "N";
      data.ApplicableofTaxRate = "";
      data.InvoiceType = "Regular";
      data.ECommerceGSTIN = "";
      data.Rate = parseFloat(Number(b2cs.totalgstRate).toFixed(2));
      var tax = 0;
      // tax = Number(excel.totalCGSTAmount) + Number(excel.totalSGSTAmount)
      data.TaxableValue = Number(b2cs.netamount);
      data.CessAmount = 0;
      var totcess=0;
      var totcess= totcess+ data.CessAmount;
      this.b2csTotalCess=this.b2csTotalCess+totcess;
      this.b2csExcelData.push(data);
    }

    for (let hsn of this.HsnSearchData) {
      let datahsn = new hsnDto();
      datahsn.HSN = hsn.hsncode;
      datahsn.Description = "";
      datahsn.UQC = "PCS-P";
      datahsn.TotalQuantity = hsn.totalquantity;
      datahsn.TotalValue = parseFloat(Number(hsn.totalvalue).toFixed(2));
      datahsn.TaxableValue = parseFloat(Number(hsn.totaltaxablevalue).toFixed(2));
      datahsn.IntegratedTaxAmount = hsn.totaligst;
      datahsn.CentralTaxAmount = hsn.totalcgst;
      datahsn.StateUTTaxAmount = hsn.totalsgst;
      datahsn.CessAmount = 0;
      datahsn.rate=hsn.rate;
      var totcess=0;
      var totcess= totcess+ datahsn.CessAmount;
      this.TotalCesshsn=this.TotalCesshsn+totcess;
      this.ExcelDataHSN.push(datahsn);
    }

    for (let mstr of this.MasterSearchData) {
      let datamstr = new masterDto();
      datamstr.Type = "OE";
      datamstr.PlaceOfSupply = "24-Gujarat";
      datamstr.ApplicableOfTaxRate = "";
      datamstr.Rate = mstr.gst;
      datamstr.TaxableValue = parseFloat(Number(mstr.total).toFixed(2));
      datamstr.CessAmount = 0;
      var totcess=0;
      var totcess= totcess+ datamstr.CessAmount;
      this.TotalCessmaster=this.TotalCessmaster+totcess;
      datamstr.ECommerceGSTIN = "";
      this.ExcelDataMaster.push(datamstr);
    }

    for (let doc of this.DocsSearchData) {
      let datadoc = new docsDto();
      datadoc.NatureofDocument = "";
      datadoc.SrNoFrom = doc.from;
      datadoc.SrNoTo = doc.to;
      datadoc.TotalNumber = doc.totalNumber;
      datadoc.Cancellde = 0;
      var totcess=0;
      var totcess= totcess+ datadoc.Cancellde;
      this.TotalCancelldedocs=this.TotalCancelldedocs+totcess;
      this.ExcelDataDocs.push(datadoc);
    }

    for (let cdnr of this.cdnrSearchData) {
      let datacdnr = new cdnrDto();
      datacdnr.GSTINUINofRecipient = cdnr.gstNumber;
      datacdnr.ReceiverName = cdnr.partyName;
      datacdnr.NoteNumber = cdnr.voucherNumber;
      datacdnr.NoteDate = this.datePipe.transform(cdnr.voucherDate, 'dd-MMM-yyyy');
      datacdnr.NoteType = cdnr.documentType;
      // datacdnr.PlaceOfSupply = "24-Gujarat";
      datacdnr.PlaceOfSupply = cdnr.statecode+"-"+cdnr.state;
      datacdnr.ReverseCharge = "N";
      datacdnr.NoteSupplyType = "Regular";
      datacdnr.NoteValue = Number(cdnr.voucherValue);
      datacdnr.ApplicableofTaxRate = "";
      datacdnr.Rate = parseFloat(Number(cdnr.rate).toFixed(2));
      // var tax = 0;
      // tax = Number(excel.totalCGSTAmount) + Number(excel.totalSGSTAmount)
      datacdnr.TaxableValue = Number(cdnr.taxableValue);
      datacdnr.CessAmount = 0;
      var totcess=0;
      var totcess= totcess+ datacdnr.CessAmount;
      this.TotalCessCdnr=this.TotalCessCdnr+totcess;
      this.ExcelDataCdnr.push(datacdnr);
    }

    // this.ExcelData.push({"No. of Recipients": this.partyLength, "No. of Invoices": this.invoiceLength, "TotalInvoiceValue": this.TotalAmount, "TotalTaxableValue": this.TotalTaxableValue });
    // this.excelservice.exportAsExcelGSTR1(this.ExcelData, 'GSTR1Purchase');
    this.excelservice.exportExcel(this.fileName,this.ExcelData,this.partyLength,this.invoiceLength,this.TotalAmount,this.TotalTaxableValue,this.TotalCess,
      this.b2csExcelData,this.b2cspartyLength,this.b2csinvoiceLength,this.b2csTotalAmount,this.b2csTotalTaxableValue,this.b2csTotalCess,
      this.ExcelDataHSN,this.TotalValue,this.TotalTaxableValue1,this.TotalIntegratedTax,this.TotalCentralTax,this.TotalStateUTTax,this.NoofHSN,this.TotalCesshsn,
      this.ExcelDataMaster,this.NatureofDocumentData,this.TotalTaxableValue2,this.TotalCessmaster,
      this.ExcelDataDocs,this.TotalNumber,this.TotalCancelldedocs,
      this.ExcelDataCdnr,this.ReceiverLength,this.TotalInvoiceAdvanceReceiptNumber,this.VoucherLength,this.TotalVoucherValue,this.TotalTaxableValueCdnr,this.TotalCessCdnr);

  }

  gstr3bfileName;
  saleNetAmount=0;
  saleCGst=0;
  saleSGst=0;
  purchaseCGst=0;
  purchaseSGst=0;
  purchaseIGst=0;
  saleIGst=0;
  getGstr3BNigam(){
    this.gstrservice.getGstr3BData("1",this.selectMonth,this.selectYear).subscribe(res => {
      this.gstr3bfileName = "RADHASWAMI BIJ NIGAM";
      this.saleNetAmount = parseFloat(Number(res.saleNetAmount).toFixed(2));
      this.saleCGst = 0;
      this.saleSGst = 0;
      this.saleIGst = 0;
      this.purchaseCGst = 0;
      this.purchaseSGst = 0;
      this.purchaseIGst = 0;
      console.log(res);
      this.exportExcelGSTR3BNigam();
    })
  }
  getGstr3BBhandar(){
    this.gstrservice.getGstr3BData(this.txtCompany.companyId,this.selectMonth,this.selectYear).subscribe(res => {
      this.gstr3bfileName = "GSTR3B_"+this.txtCompany.companyName;
      this.saleNetAmount = parseFloat(Number(res.saleNetAmount).toFixed(2));
      this.saleCGst = parseFloat(Number(res.salecgst).toFixed(2));
      this.saleSGst = parseFloat(Number(res.salesgst).toFixed(2));
      this.saleIGst = parseFloat(Number(res.saleigst).toFixed(2));
      this.purchaseCGst = parseFloat(Number(res.purchasecgst).toFixed(2));
      this.purchaseSGst = parseFloat(Number(res.purchasesgst).toFixed(2));
      this.purchaseIGst = parseFloat(Number(res.purchaseigst).toFixed(2));
      console.log(res);
      this.exportExcelGSTR3BBhandar();
    })
  }

  monthname;
  getMonthName(){
    if(this.selectMonth == "0") {
      this.monthname = "January"
    } else if (this.selectMonth == "1") {
      this.monthname = "February"
    } else if (this.selectMonth == "2") {
      this.monthname = "March"
    } else if (this.selectMonth == "3") {
      this.monthname = "April"
    } else if (this.selectMonth == "4") {
      this.monthname = "May"
    } else if (this.selectMonth == "5") {
      this.monthname = "June"
    } else if (this.selectMonth == "6") {
      this.monthname = "July"
    } else if (this.selectMonth == "7") {
      this.monthname = "August"
    } else if (this.selectMonth == "8") {
      this.monthname = "September"
    } else if (this.selectMonth == "9") {
      this.monthname = "October"
    } else if (this.selectMonth == "10") {
      this.monthname = "November"
    } else if (this.selectMonth == "11") {
      this.monthname = "December"
    }
  }
  exportExcelGSTR3BNigam(){
    this.getMonthName();
    this.excelservice.exportExcelGSTR3B(this.monthname, this.gstr3bfileName,this.saleNetAmount,this.saleCGst,this.saleSGst,this.saleIGst,this.purchaseCGst,this.purchaseSGst,this.purchaseIGst,this.txtGstNumber,this.txtOwnerName);
  }
  exportExcelGSTR3BBhandar(){
    this.getMonthName();
    this.excelservice.exportExcelGSTR3B(this.monthname, this.gstr3bfileName,this.saleNetAmount,this.saleCGst,this.saleSGst,this.saleIGst,this.purchaseCGst,this.purchaseSGst,this.purchaseIGst,this.txtGstNumber,this.txtOwnerName);
  }

  txtGstNumber;
  txtOwnerName;
  resGSTNumber;
  getGSTNumber(Company) {
    this.txtGstNumber = Company.gstNumber;
    if(this.txtGstNumber.length == 15 && this.txtGstNumber != null){
      this.purchasePlanService.getGSTNumber(Company.gstNumber).subscribe(res => {
        this.resGSTNumber = res;
        // this.txtOrganizationName = res.organizationName;
        this.txtOwnerName = res.ownerName;
      })
    } else {
      // this.toastr.error('Please enter proper GST number.');
      // this.txtOrganizationName = null;
      // this.txtOwnerName = null;
    }
  }

}
