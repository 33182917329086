import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProductAppService {

  constructor(
    private http: Http,
  ) { }

  createProductApp(txtCategoryApp: string, txtBrandApp: string, txtProductApp: string, txtProductGuj: string, txtProductRate: string, txtProductDes: string, txtProductDesGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryName: txtCategoryApp,
      brandName: txtBrandApp,
      productName: txtProductApp,
      productNameGuj: txtProductGuj,
      price: txtProductRate,
      productDescription: txtProductDes,
      productDescriptionGuj: txtProductDesGuj,
    });
    return this.http.post('/api/app/create/product', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateProductApp(txtProductAppId: string, txtCategoryApp: string, txtBrandApp: string, txtProductApp: string, txtProductGuj: string, txtProductRate: string, txtProductDes: string, txtProductDesGuj: string, txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      productid: txtProductAppId,
      categoryName: txtCategoryApp,
      brandName: txtBrandApp,
      productName: txtProductApp,
      productNameGuj: txtProductGuj,
      price: txtProductRate,
      productDescription: txtProductDes,
      productDescriptionGuj: txtProductDesGuj,
      image: txtImage
    });
    return this.http.put('/api/app/update/product', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllProductApp() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/product', { headers })
      .pipe(map(res => res.json()));
  }

  deleteProductApp(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      productid: deleteAppId,
    });
    return this.http.put('/api/app/delete/product', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/product/image', data, { headers: headers })
      .pipe(map(res => res));
  }

}
