<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Extra Location Per Plan</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-lg-4 col-xl-12 col-12">
                                            <label for="country" class="form-label">Plan Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtPlanName"
                                                [(ngModel)]="txtPlanName" required="">
                                                <option>-:Select A Plan:-</option>
                                                <option *ngFor="let plan of allPlan"
                                                    [value]="plan.planName">{{plan.planName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid plan.
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-xl-12 col-12">
                                            <label for="first" class="form-label">Extra Location<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtExtraLocationId"
                                                name="txtExtraLocationId">
                                            <input type="text" class="form-control" id="Category"
                                                placeholder="Enter Extra Location" name="txtExtraLocation"
                                                [(ngModel)]="txtExtraLocation" required="required" (keypress)="numberOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter extra user.
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-xl-12 col-12">
                                            <label for="first" class="form-label">Amount<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="Category"
                                                placeholder="Enter Amount" name="txtAmount" [(ngModel)]="txtAmount"
                                                required="required" (keypress)="numberOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter amount.
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createExtraLocation()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Extra Location Per Plan</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Plan Name</th>
                                                <th scope="col">Extra Location</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let exloc of allExtraLocation | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{exloc.plan.planName}}</td>
                                                <td>{{exloc.planLocationLimit}}</td>
                                                <td>{{exloc.amount | appendIND}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateExtraLocation(exloc)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteExtraLocation(exloc)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allExtraLocation < 1">
                                                <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>