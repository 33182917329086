<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Payment Voucher Details</h2> -->
                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Journal Voucher</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Voucher Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtVoucherDate "
                                                name="txtVoucherDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Voucher Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-4 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtcompany"
                                                [(ngModel)]="txtcompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company">
                                                    {{company.partyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-4">
                                            <label for="country" class="form-label">Amount<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Amount"
                                                required="required" [(ngModel)]="txtAmount" name="txtAmount"
                                                (keypress)="numberandDotOnly($event)" />
                                            <div class="invalid-feedback">
                                                Please enter a debit.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-4 col-8">
                                            <label for="country" class="form-label">Remarks</label>
                                            <textarea class="form-control" rows="1" name="txtNarration"
                                                [(ngModel)]="txtNarration"></textarea>
                                        </div>
                                    <!-- </div> -->
                                    <!-- <br/>
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-6 col-xl-6 col-6">
                                            <label class="form-label">DEBIT</label>
                                        </div>
                                        <div class="col-lg-6 col-xl-6 col-6">
                                            <label class="form-label">CREDIT</label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row d-flex align-items-baseline g-3"> -->
                                        <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                            <label for="last" class="form-label">Party Debit Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Enter party Debit Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}-{{item.result.cellNumber}}{{item.result.address !='' && item.result.address !=null ? '-'+item.result.address:''}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Ledger Debit Name</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <select class="form-select" id="country" name="txtLedger"
                                                [(ngModel)]="txtLedger" required="">
                                                <option>-:Select Ledger:-</option>
                                                <option *ngFor="let ledger of allPartyLedgerName" [ngValue]="ledger.ledgerid">
                                                    {{ledger.partyledgerName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter ledger Name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                            <label for="last" class="form-label">Ledger Debit Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchledger" ngxTypeahead
                                                [taList]="searchLedgerName" [taItemTpl]="myLedger"
                                                (taSelected)="selectedLedger($event)" id="LedgerName"
                                                [(ngModel)]="txtLedgerName" name="txtLedgerName"
                                                placeholder="Enter Ledger Debit Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myLedger let-item>
                                                <div class="item-name">
                                                    {{item.result.ledgerName}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter ledger name.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                            <label for="last" class="form-label">Party Credit Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchPartyCredit" ngxTypeahead
                                                [taList]="searchPartyCreditName" [taItemTpl]="myPartyCredit"
                                                (taSelected)="selectedPartyCredit($event)" id="PartyName"
                                                [(ngModel)]="txtpartyCreditName" name="txtpartyCreditName"
                                                placeholder="Enter party Credit Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myPartyCredit let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}-{{item.result.cellNumber}}{{item.result.address !='' && item.result.address !=null ? '-'+item.result.address:''}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Ledger Credit Name</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <select class="form-select" id="country" name="txtLedgerCredit"
                                                [(ngModel)]="txtLedgerCredit" required="">
                                                <option>-:Select Ledger:-</option>
                                                <option *ngFor="let ledger of debitPartyLedgerName" [ngValue]="ledger.ledgerid">
                                                    {{ledger.partyledgerName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter ledger Name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                            <label for="last" class="form-label">Ledger Credit Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchledgerCredit" ngxTypeahead
                                                [taList]="searchLedgerCreditName" [taItemTpl]="myLedgerCredit"
                                                (taSelected)="selectedLedgerCredit($event)" id="LedgerName"
                                                [(ngModel)]="txtLedgerCreditName" name="txtLedgerCreditName"
                                                placeholder="Enter Ledger Credit Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myLedgerCredit let-item>
                                                <div class="item-name">
                                                    {{item.result.ledgerName}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter ledger name.
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="addPaymentVoucher()">
                                                {{btnName}}
                                            </button>
                                        </div> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Payment Voucher Details</div>
                        <div class="portlet-content">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Actions</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col" class="text-center">Debit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let payment of paymentData">
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Edit"
                                                    (click)="updatePayment(payment)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deletePayment(payment)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td>{{payment.partyName}}</td>
                                            <td class="text-end">{{payment.debit | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="paymentData.length == 0 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td class="text-end"><b>Total : </b></td>
                                            <td class="text-end"><b>{{totalCredit | appendIND}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearValue()">
                                Cancel
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="createJVVoucher()">
                                Save
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are You Sure Want To Delete This Record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div> -->