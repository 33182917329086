import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GstrService {

  constructor(private http: Http) { }

  getGstr3BData(companyid: string, month: string, year: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR3/bhandar/' + companyid + '/month/' + month + '/year/' + year, { headers })
      .pipe(map(res => res.json()));
  }
}
