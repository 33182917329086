import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ExpiryDetailService } from '../service/expiry_detail/expiry-detail.service';
import { ToastrService } from "ngx-toastr";
import { ExcelService } from '../service/excel/excel.service';

@Component({
  selector: 'app-expiry-detail',
  templateUrl: './expiry-detail.component.html',
  styleUrls: ['./expiry-detail.component.css']
})
export class ExpiryDetailComponent implements OnInit {
  search;
  pagePrevious: number = 1;
  page10: number = 1;
  page20: number = 1;
  page30: number = 1;
  searchPrevious;
  searchten;
  searchtwenty;
  searchthirty;
  datePipe = new DatePipe("en-US");
  txtDueDateConvert;
  txtEndDate;
  txtStartDate;
  txtquantity = 0;
  txtamount = 0;
  constructor(
    private expirydetailservice: ExpiryDetailService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    // this.getDueDate(10);
    this.searchPreviousStock();
  }

  allExpiryDateDetail;
  getDueDate(days) {
    this.txtStartDate = new Date();
    var ltime = this.txtStartDate.getTime() + days * 24 * 60 * 60 * 1000;
    this.txtEndDate = new Date(ltime);
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    console.log("this due date after", this.txtEndDate);
    this.expirydetailservice.getExpiryDate(this.txtStartDate, this.txtEndDate).subscribe(res => {
      this.allExpiryDateDetail = res;
      console.log(this.allExpiryDateDetail);
      this.txtamount = 0;
      this.txtquantity = 0;
  
      for (let item of res) {
        this.txtquantity = parseFloat(Number(this.txtquantity + item.quantity).toFixed(2));
        this.txtamount = parseFloat(Number(this.txtamount + item.stockTotalAmount).toFixed(2));
      }
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  resAllsubExpiryDateDetail;
  subExpiryDateDetail(evel){
    this.expirydetailservice.getsubExpiryDateDetail(evel.itemid,this.txtStartDate,this.txtEndDate).subscribe(res => {
      this.resAllsubExpiryDateDetail = res;
      console.log(this.resAllsubExpiryDateDetail);
    })
  }

  // previousdayDate;
  resAllExpiryDate;
  searchPreviousStock() {
    // this.txtStartDate = '2021-04-01';
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtStartDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtEndDate = new Date();
  //  this.txtToDate = new Date(this.txtToDate.setDate(this.txtToDate.getDate() - 1));
    this.txtEndDate.setDate(this.txtEndDate.getDate() - 1);
    console.log(this.txtEndDate);
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, 'yyyy-MM-dd');
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, 'yyyy-MM-dd');
    this.expirydetailservice.getExpiryDate(this.txtStartDate, this.txtEndDate).subscribe(res => {
    this.allExpiryDateDetail = res;
    console.log(res);
    this.txtamount = 0;
    this.txtquantity = 0;

    for (let item of res) {
      this.txtquantity = parseFloat(Number(this.txtquantity + item.quantity).toFixed(2));
      this.txtamount = parseFloat(Number(this.txtamount + item.stockTotalAmount).toFixed(2));
    }
    }, error => {
      this.toastr.error("No Data Found");
    });
 }

  ExalData = [];
  stockTotalAmount;
  excelExpiryStockData = [];
  exportAsXLSX():void {
    this.ExalData=[];
    // this.excelExpiryStockData.push(this.resAllExpiryDate);
    // this.excelExpiryStockData.push(this.allExpiryDateDetail);

    for(let exal of this.allExpiryDateDetail){
      this.stockTotalAmount = parseFloat(Number(exal.stockTotalAmount).toFixed(2));
      this.ExalData.push({
        "Item Name": exal.itemname,"Stock Quantity": exal.quantity,"Stock Total Amount (Rs.)": this.stockTotalAmount})
    }
    this.excelservice.exportAsExcelFile(this.ExalData,'ExpiryStock');
 }

}
