<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Location Master</h2> -->

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Location Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="first" class="form-label">Location<span style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtLocationId"
                                                name="txtLocationId">
                                            <input type="text" class="form-control" id="Location"
                                                placeholder="Enter Location" name="txtLocation" [disabled] = "disabled"
                                                [(ngModel)]="txtLocation" required="required" (focusout)="toUpperCaseLocation()"/>
                                            <div class="invalid-feedback">
                                                Please enter Location.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Address<span style="color: red;">&nbsp;</span></label>
                                            <!-- <input type="text" class="form-control" id="Location"
                                                placeholder="Enter Address" name="txtAddress"
                                                [(ngModel)]="txtAddress" required="required"/> -->
                                            <textarea class="form-control" rows="2" name="txtAddress"
                                            [(ngModel)]="txtAddress"></textarea>
                                            <div class="invalid-feedback">
                                                Please enter address.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label for="first" class="form-label">PES. LIC. No.<span style="color: red;">&nbsp;</span></label>
                                                    <input type="text" class="form-control" id="Location"
                                                        placeholder="Enter PES LIC Number" name="txtPESLICNumber"
                                                        [(ngModel)]="txtPESLICNumber" required="required"/>
                                                    <div class="invalid-feedback">
                                                        Please enter PES LIC number.
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <label for="first" class="form-label">FER. LIC. No.<span style="color: red;">&nbsp;</span></label>
                                                    <input type="text" class="form-control" id="Location"
                                                        placeholder="Enter FER LIC Number" name="txtFERLICNumber"
                                                        [(ngModel)]="txtFERLICNumber" required="required" />
                                                    <div class="invalid-feedback">
                                                        Please enter Location.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-6">
                                                    <label for="first" class="form-label">SEED. LIC. No.<span style="color: red;">&nbsp;</span></label>
                                                    <input type="text" class="form-control" id="Location"
                                                        placeholder="Enter SEED LIC Number" name="txtSEEDLICNumber"
                                                        [(ngModel)]="txtSEEDLICNumber" required="required"/>
                                                    <div class="invalid-feedback">
                                                        Please enter SEED LIC number.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createLocation()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Location</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" placeholder="Search" name="search"
                                                [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">Location</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">PES LIC. No.</th>
                                                <th scope="col">FER LIC. No.</th>
                                                <th scope="col">SEED. LIC. No.</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let location of allLocation | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <!-- <th scope="row">1</th> -->
                                                <td>{{location.locationName}}</td>
                                                <td>{{location.address}}</td>
                                                <td>{{location.peslicNumber}}</td>
                                                <td>{{location.ferlicNumber}}</td>
                                                <td>{{location.seedlicNumber}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateLocation(location)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteLocation(location)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button> -->
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allLocation < 1">
                                                <td colspan="5" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                responsive="true"></pagination-controls>
                                            </li>
                                            <!-- <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">1</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">3</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>