import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { TypeService } from '../service/type/type.service';
declare var $: any;

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit {
  resType;
  resUpType;
  txtType;
  txtTypeId;
  allType;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private typeService: TypeService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllType();
  }

  getAllType() {
    this.typeService.getAllType().subscribe(res => {
      this.allType = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtType = null;
    this.txtTypeId = null;
  }

  toUpperCaseType() {
    const temp = this.txtType;
    this.txtType = temp.toUpperCase();
    return this.txtType;
  }

  createType() {
    if (this.txtType == null || this.txtType == '' || this.txtType == undefined) {
      this.toastr.error("Type is required");
    } else {
      if (this.txtTypeId == null) {
        this.typeService.createType(this.txtType)
          .subscribe(res => {
            this.resType = res;
            this.clearData();
            this.getAllType();
            this.toastr.success('Type save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.typeService.updateType(this.txtTypeId, this.txtType).subscribe(res => {
          this.resUpType = res;
          this.clearData();
          this.getAllType();
          this.toastr.success('Type updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateType(type) {
    this.btnName = 'Update';
    this.txtTypeId = type.typeId;
    this.txtType = type.typeName;
  }

  // deleteid;
  // deleteUnit(type) {
  //   this.deleteid = type.typeId;
  // }

  // confirmDelete() {
  //   if (this.deleteid != null) {
  //     this.typeService.deleteType(this.deleteid).subscribe(res => {
  //       this.resUpType = res;
  //       this.clearData();
  //       this.getAllType();
  //       $('#deleteModal').modal('hide');
  //       this.toastr.success('Type deleted successfully');
  //     }, error => {
  //       this.toastr.error('Type not deleted');
  //     })
  //   }
  // }
}
