import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
import { ToastrService } from "ngx-toastr";
import { OrderHistoryService } from '../service/order-history/order-history.service';
declare var $: any;

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
  allOrderHistory;
  allOrderHistoryById;
  txtQuantity;
  txtTotalAmount;
  txtProductPrice;
  txtproductNameGuj;
  txtcategoryNameGuj;
  txtbrandNameGuj;
  search;
  page: number = 1;
  orderDetail = [];

  constructor(
    private orderHistoryService: OrderHistoryService,
    // private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllOrderHistory();
  }

  // MoveAssets() {
  //   this.router.navigate(['assetsvoucher']);
  // }

  getAllOrderHistory() {
    this.orderHistoryService.getAllOrderHistory().subscribe(res => {
      this.allOrderHistory = res;
      console.log(this.allOrderHistory);

    })
  }

  orderHistoryDetail(order) {
    this.getAllOrderHistoryById(order.ordermasterid)
    // this.txtDate = receipt.voucherDate;
    // console.log("Receipt Date",receipt);
  }

  getAllOrderHistoryById(txtId) {
    this.orderHistoryService.getAllOrderHistoryById(txtId).subscribe(res => {
      this.allOrderHistoryById = res;
      console.log(this.allOrderHistoryById);
      this.orderDetail = [];
      for (let orderDtl of this.allOrderHistoryById.orderDetail) {
      // this.orderDetail = [];
        this.txtQuantity = orderDtl.productqty;
        this.txtTotalAmount = orderDtl.totalAmount;
        this.txtProductPrice = orderDtl.productid.price;
        this.txtproductNameGuj = orderDtl.productid.productNameGuj;
        this.txtcategoryNameGuj = orderDtl.productid.category.categoryNameGuj;
        this.txtbrandNameGuj = orderDtl.productid.brandApp.brandNameGuj;
        this.orderDetail.push(orderDtl);
      }
    })
  }

  // deleteAssetsId;
  // deleteAssets(assets){
  //   this.deleteAssetsId = assets.voucherMasterId;
  // }

  // resAssetsData;
  // confirmDelete() {
  //   if (this.deleteAssetsId != null) {
  //     this.receiptvoucherservice.deleteVoucherRecord(this.deleteAssetsId)
  //       .subscribe(res => {
  //         this.resAssetsData = res;
  //         console.log(this.resAssetsData);
  //         this.getAllAssets();
  //         $('#deleteModal').modal('hide');
  //         // $('.modal-backdrop').remove();
  //         this.toastr.success('assets Data Delete Successfully');
  //       }, error => {
  //         this.toastr.error('assets Data Not Delete');
  //       })
  //   }
  // }

}
