import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user/user.service';

@Component({
  selector: 'app-user-inquiry',
  templateUrl: './user-inquiry.component.html',
  styleUrls: ['./user-inquiry.component.css']
})
export class UserInquiryComponent implements OnInit {

  allUserInquiry: any;
  search: any;
  page: number = 1;

  constructor(
    private userservice: UserService,
  ) { }

  ngOnInit(): void {
    this.getAllUserInquiry();
  }

  getAllUserInquiry() {
    this.userservice.getAllUserInquiry().subscribe(res => {
      this.allUserInquiry = res;
    })
  }
}
