<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>User Details</h2> -->
                <div class="row d-flex">
                    <div class="col-lg-8 col-md-8">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">User</div>
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">

                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <div class="row d-flex">
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="country" class="form-label">First Name<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                    <input type="hidden" class="form-control" [(ngModel)]="txtUserId"
                                                        name="txtUserId">
                                                    <input type="text" class="form-control" id="firstname"
                                                        placeholder="Enter first name" required="required"
                                                        [(ngModel)]="txtfirstName" name="txtfirstName" (focusout)="toUpperCaseFirst()"/>
                                                    <div class="invalid-feedback">
                                                        Please enter first name.
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="first" class="form-label">Last Name<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                    <input type="text" class="form-control" id="lastname"
                                                        placeholder="Enter last name" required="required"
                                                        [(ngModel)]="txtlastName" name="txtlastName" (focusout)="toUpperCaseLast()"/>
                                                    <div class="invalid-feedback">
                                                        Please enter last name.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <div class="row d-flex">
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="country" class="form-label">Contact No<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                    <input type="text" class="form-control" id="contactno"
                                                        placeholder="Enter Contact No" required="required"
                                                        maxlength="10" [(ngModel)]="txtContactNo" name="txtContactNo"
                                                        (keypress)="numberOnly($event)" />
                                                    <div class="invalid-feedback">
                                                        Please enter a contact no.
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="last" class="form-label">Email<span
                                                            style="color: red;">&nbsp;</span></label>
                                                    <input type="text" class="form-control" id="Email"
                                                        placeholder="Enter Email" required="required"
                                                        [(ngModel)]="txtEmail" name="txtEmail" />
                                                    <div class="invalid-feedback">
                                                        Please enter email.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <div class="row d-flex">
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="country" class="form-label">User Name<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                    <input type="text" class="form-control" id="username"
                                                        placeholder="Enter User Name" required="required"
                                                        [(ngModel)]="txtuserName" name="txtuserName" />
                                                    <div class="invalid-feedback">
                                                        Please enter a user name.
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xl-6 col-6" *ngIf="isDisablePassword">
                                                    <label for="country" class="form-label">Password<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                    <input type="password" class="form-control" id="password"
                                                        placeholder="Enter password" required="required"
                                                        [(ngModel)]="txtpassword" name="txtpassword" />
                                                    <div class="invalid-feedback">
                                                        Please enter a password.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <div class="row d-flex">
                                                <div class="col-lg-6 col-xl-6 col-6 position-relative">
                                                    <label for="country" class="form-label">Location<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                    <input type="text" [value]="searchLocation" ngxTypeahead
                                                        [taList]="searchLocationName" [taItemTpl]="myloc"
                                                        (taSelected)="selectedLocation($event)" id="Location"
                                                        [(ngModel)]="txtLocation" name="txtLocation"
                                                        placeholder="Enter Location"
                                                        class="ui-autocomplete-input form-control" autocomplete="off"
                                                        autofocus="autofocus" />
                                                    <ng-template #myloc let-item>
                                                        <div class="item-name">
                                                            {{item.result.locationName}}
                                                        </div>
                                                    </ng-template>
                                                    <!-- <input type="text" class="form-control" id="location" placeholder="Enter Location"
                                                required="required" [(ngModel)]="txtLocation" name="txtLocation"/> -->
                                                    <div class="invalid-feedback">
                                                        Please enter a location.
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="country" class="form-label">State</label>
                                                    <input type="text" class="form-control" id="state"
                                                        placeholder="Enter State" required="required"
                                                        [(ngModel)]="txtState" name="txtState" />
                                                    <div class="invalid-feedback">
                                                        Please enter a state.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <div class="row d-flex">
                                                <div class="col-lg-4 col-xl-4 col-4">
                                                    <label for="country" class="form-label">City</label>
                                                    <input type="text" class="form-control" id="city"
                                                        placeholder="Enter City" required="required"
                                                        [(ngModel)]="txtCity" name="txtCity" />
                                                    <div class="invalid-feedback">
                                                        Please enter a city.
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-xl-4 col-4">
                                                    <label for="country" class="form-label">Street</label>
                                                    <input type="text" class="form-control" id="street"
                                                        placeholder="Enter Street" required="required"
                                                        [(ngModel)]="txtStreet" name="txtStreet" />
                                                    <div class="invalid-feedback">
                                                        Please enter a street.
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-xl-4 col-4">
                                                    <label for="country" class="form-label">Area</label>
                                                    <input type="text" class="form-control" id="area"
                                                        placeholder="Enter Area" required="required"
                                                        [(ngModel)]="txtArea" name="txtArea" />
                                                    <div class="invalid-feedback">
                                                        Please enter a area.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <div class="row d-flex">
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <div class="assigned-roles">
                                                        <label for="country" class="form-label">Pincode<span
                                                                style="color: red;">&nbsp;*</span></label>
                                                        <input type="text" class="form-control" id="street"
                                                            placeholder="Enter Pincode" required="required"
                                                            maxlength="6" [(ngModel)]="txtPincode" name="txtPincode"
                                                            (keypress)="numberOnly($event)" />
                                                        <div class="invalid-feedback">
                                                            Please enter a pincode.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-xl-6 col-6">
                                                    <label for="country" class="form-label">Role<span
                                                            style="color: red;">&nbsp;*</span></label>
                                                        <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                            [data]="dropdownList" name="selectedRoles"
                                                            [(ngModel)]="selectedRoles">
                                                        </ng-multiselect-dropdown>
                                                            <!-- <select class="form-select" id="country" name="txtRole"
                                                        [(ngModel)]="txtRole" required="">
                                                        <option *ngFor="let role of allRole" [ngValue]="role">
                                                            {{role.name}}</option>
                                                    </select> -->
                                                    <!-- <ng-multiselect-dropdown [placeholder]="'Select'" [data]="dropdownList"
                                                name="selectedRoles" [(ngModel)]="selectedRoles"
                                                [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown> -->
                                                    <!-- <input type="text" class="form-control" id="role" placeholder="Enter role"
                                                required="required" [(ngModel)]="txtRole" name="txtRole" /> -->
                                                    <div class="invalid-feedback">
                                                        Please enter a role.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearValue()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createUser()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="portlet">
                            <div class="portlet-header">User Details</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                      <thead class="tblheadercolor">
                                            <tr>
                                                <th>User Name</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            <!-- <tr>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Password</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Street</th>
                                                <th scope="col">City</th>
                                                <th scope="col">Area</th>
                                                <th scope="col">Pincode</th>
                                                <th scope="col">User Name</th>
                                                <th scope="col">Contact No</th>
                                                <th scope="col">Action</th>

                                            </tr> -->
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let user of allUser | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td (click)="updateUser(user)">{{user.firstname}} {{user.lastname}}
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-secondary btn-sm" data-bs-placement="top" title="Delete"
                                                        (click)="deleteUser(user)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <!-- <tr *ngFor="let user of allUserDetail"> -->
                                            <!-- <td>{{user.firstname}}</td> -->
                                            <!-- <td>{{user.lastname}}</td> -->
                                            <!-- <td>{{user.email}}</td>
                                                <td>{{user.password}}</td>
                                                <td>{{user.location}}</td>
                                                <td>{{user.state}}</td>
                                                <td>{{user.street}}</td>
                                                <td>{{user.city}}</td>
                                                <td>{{user.area}}</td>
                                                <td>{{user.pincode}}</td>
                                                <td>{{user.username}}</td>
                                                <td>{{user.contactno}}</td> -->

                                            <!--  <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="updateUser(user)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteUser(user)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td> -->
                                            <!-- </tr> -->


                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allUser < 1">
                                                <td colspan="13" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                responsive="true"></pagination-controls>
                                            </li>
                                            <!-- <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">1</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">3</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>