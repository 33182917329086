import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class VenderStoryService {

  constructor(
    private http: Http,
  ) { }
  createVenderStory(txtTitleName: string, txtTitleNameGuj: string, txtTitleDes: string, txtTitleDesGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      titleName: txtTitleName,
      titleNameGuj: txtTitleNameGuj,
      titleDescription: txtTitleDes,
      titleDescriptionGuj: txtTitleDesGuj,
    });
    return this.http.post('/api/app/create/venderstory', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateVenderStory(txtCropsKrishiGyaanId: string, txtTitleName: string, txtTitleNameGuj: string, txtTitleDes: string, txtTitleDesGuj: string, txtImage: string, txtVideo: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      venderstoryId: txtCropsKrishiGyaanId,
      titleName: txtTitleName,
      titleNameGuj: txtTitleNameGuj,
      titleDescription: txtTitleDes,
      titleDescriptionGuj: txtTitleDesGuj,
      image: txtImage,
      video: txtVideo
    });
    return this.http.put('/api/app/update/venderstory', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllVenderStory() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/venderstory', { headers })
      .pipe(map(res => res.json()));
  }

  deleteVenderStory(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      venderstoryId: deleteAppId,
    });
    return this.http.put('/api/app/delete/venderstory', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/venderstory/image', data, { headers: headers })
      .pipe(map(res => res));
  }

  uploadVideo(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/venderstory/video', data, { headers: headers })
      .pipe(map(res => res));
  }
}
