<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Sale Details</h2> -->
                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Sale</div>
                                <div class="portlet-content">
                                    <label><b>Sale Master</b></label>
                                    <div class="col-md-8 d-flex">
                                        <div class="row">
                                            <div class="col-12 col-xl-6 bottom_margin">
                                            <input type="file" name="txtExcelBrand" [(ngModel)]="txtExcelBrand" 
                                            (change)="onExcelUploadSale($event)" accept=".xls,.xlsx"/>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                            <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                                (click)="exportExcelSaleINIT()">Export&nbsp;To&nbsp;Excel</button>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                            <button class="btn btn-primary d-block bottom_margin" type="submit"
                                                (click)="importandConvertExcelSale()">Import&nbsp;Excel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="mt-3"><b>Sale Detail</b></label>
                                    <div class="col-md-8 d-flex mb-3">
                                        <div class="row">
                                            <div class="col-12 col-xl-6 bottom_margin">
                                            <input type="file" name="txtExcelBrand" [(ngModel)]="txtExcelBrand" 
                                            (change)="onExcelUploadSaleDetail($event)" accept=".xls,.xlsx"/>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                            <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                                (click)="exportExcelSaleDetailINIT()">Export&nbsp;To&nbsp;Excel</button>
                                            </div>
                                            <div class="col-5 col-xl-3 mb-2">
                                            <button class="btn btn-primary d-block bottom_margin" type="submit"
                                                (click)="importandConvertExcelSaleDetail()">Import&nbsp;Excel</button>
                                            </div>
                                            <div class="col-md-6 col-xl-12">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Get Stock</button>
                                            </div>
                                        </div>
                                    </div>
                                    <label><b>Voucher Master</b></label>
                                    <div class="col-md-8 d-flex mb-3">
                                        <div class="row">
                                            <div class="col-12 col-xl-6 bottom_margin">
                                                <input type="file" name="txtVMExcel" [(ngModel)]="txtVMExcel" 
                                                (change)="onExcelUploadVMDetail($event)" accept=".xls,.xlsx"/>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                                <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                                    (click)="exportExcelVMDetailINIT()">Export&nbsp;To&nbsp;Excel</button>
                                            </div>
                                            <div class="col-5 col-xl-3 mb-2">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit"
                                                    (click)="importandConvertExcelVMDetail()">Import&nbsp;Excel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <label><b>Sale Return Master</b></label>
                                    <div class="col-md-8 d-flex mb-3">
                                        <div class="row">
                                            <div class="col-12 col-xl-6 bottom_margin">
                                                <input type="file" name="txtExcelSR" [(ngModel)]="txtExcelSR" 
                                                (change)="onExcelUploadSR($event)" accept=".xls,.xlsx"/>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                                <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                                    (click)="exportExcelSRINIT()">Export&nbsp;To&nbsp;Excel</button>
                                            </div>
                                            <div class="col-5 col-xl-3 mb-2">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit"
                                                    (click)="importandConvertExcelSR()">Import&nbsp;Excel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <label><b>Sale Return Detail</b></label>
                                    <div class="col-md-8 d-flex mb-3">
                                        <div class="row">
                                            <div class="col-12 col-xl-6 bottom_margin">
                                                <input type="file" name="txtExcelSRD" [(ngModel)]="txtExcelSRD" 
                                                (change)="onExcelUploadSRD($event)" accept=".xls,.xlsx"/>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                                <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                                    (click)="exportExcelSRDINIT()">Export&nbsp;To&nbsp;Excel</button>
                                            </div>
                                            <div class="col-5 col-xl-3 mb-2">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit"
                                                    (click)="importandConvertExcelSRD()">Import&nbsp;Excel</button>
                                            </div>
                                            <div class="col-md-6 col-xl-12">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsOpening()">Get Balance</button>
                                            </div>
                                        </div>
                                    </div>
                                    <label><b>List Of Opening Balance</b></label>
                                    <div class="col-md-8 d-flex mb-3">
                                        <div class="row">
                                            <div class="col-12 col-xl-6 bottom_margin">
                                                <input type="file" name="txtExcelSRD" [(ngModel)]="txtExcelSRD" 
                                                (change)="onExcelUploadOpening($event)" accept=".xls,.xlsx"/>
                                            </div>
                                            <div class="col-5 col-xl-3">
                                                <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                                    (click)="exportExcelSRDINIT()">Export&nbsp;To&nbsp;Excel</button>
                                            </div>
                                            <div class="col-5 col-xl-3 mb-2">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit"
                                                    (click)="importandConvertExcelOPP()">Import&nbsp;Excel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Sale Date<span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtsaleDate" name="txtsaleDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <!-- <input type="text" class="form-control" id="PurchaseDate"
                                                placeholder="Enter purchase Date" required="required"
                                                [(ngModel)]="txtPurchaseDate" name="txtPurchaseDate" /> -->
                                            <div class="invalid-feedback">
                                                Please enter a sale Date.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Sale Number</label>
                                            <input type="text" class="form-control" id="itemName"
                                                placeholder="Enter sale code" required="required"
                                                [(ngModel)]="txtsaleCode" name="txtsaleCode" disabled />
                                            <div class="invalid-feedback">
                                                Please enter sale code.
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-3">
                                            <label for="first" class="form-label">Mobile Number<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="itemName"
                                                placeholder="Enter Mobile Number" required="required"
                                                [(ngModel)]="txtMobileNumber" name="txtMobileNumber" maxlength="10"
                                                (focusout)="getPartyByCellNumber()" (keypress)="numberOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter Mobile Number.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-5 col-12 position-relative">
                                            <label for="last" class="form-label">Party Name<span style="color: red;" class="me-2">&nbsp;*</span>
                                                <span role="button" [routerLink]="['/party']"><i class="fas fa-plus-circle" title="Add Party"></i></span>
                                            </label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Enter party Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus"/>
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}-{{item.result.cellNumber}}{{item.result.address !='' && item.result.address !=null ? '-'+item.result.address:''}}
                                                </div>
                                            </ng-template>
                                            <!-- <input type="text" class="form-control" id="last"
                                                placeholder="Enter Party Name" required="required"
                                                [(ngModel)]="txtpartyName" name="txtpartyName" /> -->
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-md-6 col-12 credit-scor-box">
                                            <label for="country" class="form-label">Closing&nbsp;Balance&nbsp;:&nbsp;{{txtClosingBalance | appendIND}}</label><br/>
                                            <label for="country" class="form-label">IAGRO&nbsp;Credit&nbsp;Score&nbsp;:&nbsp; <span class=" diffcolor">{{iagroCreditScore }}</span></label><br/>
                                            <label for="country" class="form-label">My&nbsp;Credit&nbsp; Score&nbsp;:&nbsp; <span class=" diffcolorparty">{{partycreditScore }}</span></label>
                                        </div>
                                        <!-- <div class="col-3">
                                            <label for="country" class="form-label">Gst Amount</label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Enter Gst Amount" required="required"
                                                [(ngModel)]="txtgstAmount" name="txtgstAmount" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a gst amount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Amount</label>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Amount"
                                                required="required" [(ngModel)]="txtamount" name="txtamount" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a amount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Discount</label>
                                            <input type="text" class="form-control" id="last" (focusout)="calculateNetAmount()"
                                                placeholder="Enter Discount" required="required"
                                                [(ngModel)]="txtDiscount" name="txtDiscount" />
                                            <div class="invalid-feedback">
                                                Please enter a Discount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Net Amount</label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Enter Net Amount" required="required"
                                                [(ngModel)]="txtnetAmount" name="txtnetAmount" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a net Amount.
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <hr>
                                <form class="needs-validation" novalidate="">
                                    <div class="portlet-content">
                                        <div class="row d-flex align-items-baseline g-3">

                                            <div class="col-lg-4 col-xl-4 col-6 position-relative">
                                                <label for="last" class="form-label">Item Name<span style="color: red;" class="me-2">&nbsp;*</span>
                                                    <span role="button" [routerLink]="['/itemsdetail']"><i class="fas fa-plus-circle" title="Add Item"></i></span>
                                                </label>
                                                <input type="text" [value]="search1" ngxTypeahead
                                                    (focusout)="getItemById()" [taList]="searchItemName"
                                                    [taItemTpl]="myTemp" (taSelected)="selectedStatic($event)"
                                                    id="ItemName" [(ngModel)]="txtItemName" name="txtItemName"
                                                    placeholder="Enter Item Name"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp let-item>
                                                    <div class="item-name">
                                                        {{item.result.itemName}}
                                                    </div>
                                                </ng-template>

                                                <div class="invalid-feedback">
                                                    Please enter item name.
                                                </div>
                                            </div>
                                            <!-- <div class="col-2">
                                                <label for="first" class="form-label">Category Name</label>
                                                <input type="text" class="form-control" id="itemName"
                                                    placeholder="Enter Category Name" required="required"
                                                    [(ngModel)]="txtCategoryName" name="txtCategoryName" disabled />
                                                <div class="invalid-feedback">
                                                    Please enter txtCategory Name.
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <label for="country" class="form-label">SubCategory Name</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter SubCategory Name" required="required"
                                                    [(ngModel)]="txtSubCategoryName" name="txtSubCategoryName"
                                                    disabled />
                                                <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div> -->
                                            <div class="col-lg-3 col-xl-3 col-6">
                                                <label for="country" class="form-label">Brand Name</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Brand Name" required="required"
                                                    [(ngModel)]="txtBrandName" name="txtBrandName" disabled />
                                                <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-1 col-6">
                                                <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Quantity" required="required"
                                                    [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a quantity.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-2 col-6">
                                                <label for="country" class="form-label">Rate<span style="color: red;">&nbsp;*</span>&nbsp;&nbsp;Last Price : {{txtLastPrice | appendIND}}</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Rate" required="required"
                                                    [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a rate.
                                                </div>
                                            </div>
                                            <div class="col-auto ms-auto btn-container-end">
                                                <button type="button" class="btn btn-primary d-block me-2"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="getBatchNumber()" data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal">
                                                    STOCK
                                                </button>
                                                <button class="btn btn-primary d-block" type="submit"
                                                    (click)="addSaleDetailStockCheck()">
                                                    {{btnName}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Sale Details</div>
                        <div class="portlet-content">
                            <!-- <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" value="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div> -->

                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col">#</th> -->
                                            <th scope="col">Item Name</th>
                                            <!-- <th scope="col">category Name</th>
                                            <th scope="col">SubCategory Name</th> -->
                                            <th scope="col">Quantity</th>
                                            <th scope="col" class="text-center">Rate</th>
                                            <th scope="col" class="text-center">Total</th>
                                            <!-- <th scope="col">Manufacture Date</th>
                                            <th scope="col">Expiry Date</th> -->
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                            <!-- <th scope="col">Batch Number</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sale of saleData">
                                            <!-- <th scope="row">1</th> -->
                                            <td>{{sale.itemName}}</td>
                                            <!-- <td>{{sale.categoryName}}</td>
                                            <td>{{sale.subCategoryName}}</td> -->
                                            <td>{{sale.quantity}}</td>
                                            <td class="text-end">{{sale.rate | appendIND}}</td>
                                            <td class="text-end">{{sale.quantity * sale.rate | appendIND}}</td>
                                            <!-- <td>{{sale.manufactureDate | date:'dd/MM/yyyy'}}</td>
                                            <td>{{sale.expiryDate | date:'dd/MM/yyyy'}}</td> -->
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                style="margin-right:5px ;" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="updateSale(sale)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                style="margin-right:5px ;" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteSale(sale)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <!-- <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="getBatchNumber(sale)" data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal">
                                                    <i class="fas fa-history"></i>
                                                </button>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="saleData.length == 0 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-xl-8 col-md-6 col-12 mb-2">
                                        <div class="row g-3">
                                            <div class="col-lg-4 col-md-8 col-sm-8 col-9">
                                                <div class="form-group">
                                                    <!-- <label style="opacity:0;">Image</label> -->
                                                    <label for="formFile" class="form-label">Image</label>
                                                    <input class="form-control" type="file" id="formFile" name="txtImage"
                                                    [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                                <img [src]="imagePreview" height="50px" width="50px">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-xl-4 col-md-8 col-sm-4 col-12 mt-4">
                                            
                                            <label for="country" class="form-label">Narration</label>
                                            <textarea class="form-control" rows="2" name="txtNarration"
                                                [(ngModel)]="txtNarration"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="justify-content-end">
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5 col-6"
                                                    style="padding: 8px;">Amount:</label>
                                                <div class="col-sm-7 col-6">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Enter Amount" required="required"
                                                        [(ngModel)]="txtamount" name="txtamount" disabled />
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-end">
                                                <label for="inputPassword" class="col-md-5 col-6"
                                                    style="padding: 8px;">GST Amount:</label>
                                                <div class="col-sm-7 col-6">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Enter Gst Amount" required="required"
                                                        [(ngModel)]="txtgstAmount" name="txtgstAmount" disabled />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5 col-6"
                                                    style="padding: 8px;">Add. Charges:</label>
                                                <div class="col-sm-7 col-6">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        (focusout)="calculateAdd()" placeholder="Add Charges"
                                                        required="required" [(ngModel)]="txtAdd"
                                                        name="txtAdd" (keypress)="numberandDotOnly($event)"/>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5 col-6"
                                                    style="padding: 8px;">Discount:</label>
                                                <div class="col-sm-7 col-6">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        (focusout)="calculateNetAmount()" placeholder="Discount"
                                                        required="required" [(ngModel)]="txtDiscount"
                                                        name="txtDiscount" (keypress)="numberandDotOnly($event)"/>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5 col-6"
                                                    style="padding: 8px;">Net Amount:</label>
                                                <div class="col-sm-7 col-6">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Net Amount" required="required"
                                                        [(ngModel)]="txtnetAmount" name="txtnetAmount" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearGridData();clearValue()">
                                Cancel
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="createSaleDetail()">
                                Save
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Batch wise Stock Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="mt-3 table-responsive">
                            <table class="table table-hover table-striped">
                                <thead class="tblheadercolor">
                                    <tr>
                                        <th scope="col">Batch Number</th>
                                        <th scope="col">Available Qty</th>
                                        <th scope="col">Expiry Date</th>
                                        <th class="text-center" scope="col">Alloted Qty</th>
                                        <!-- <th class="text-center" scope="col">CREDIT (₹)</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let batch of batchwiseresponse;">
                                        <td>{{batch.batchNumber}}</td>
                                        <td>{{batch.quantity}}</td>
                                        <td>{{batch.expiryDate | date:'dd-MMM-yyyy'}}</td>
                                        <td>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Quantity" required="required"
                                                [(ngModel)]="batch.allotedquantity" name="txtrate" (keypress)="numberOnly($event)"/>
                                        </td>
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="pdfModal" tabindex="-1" aria-labelledby="pdfModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Download PDF</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- Do you want to download PDF ? -->
                <div class="row">
                    <div class="col-md-4 d-flex text-center align-items-center mb-2" *ngFor="let comp of allPDFCompany">
                        <a role="button" (click)="downloadPdfByCompany(comp,allPDFCompany.length)">{{comp.companyName}}</a>
                    </div>
                </div>
                <div class="text-center" *ngIf="allPDFCompany < 1 || allPDFCompany == null">
                    Company detail not found.
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="getcompnayOrganizationPDF()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div> -->
        </div>
    </div>
</div>