<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Menu</h2> -->
                <div class="row d-flex">
                    <div class="col-lg-8 col-md-8">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Menu Management</div>
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Menu Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtMenuId"
                                                name="txtMenuId">
                                            <input type="text" class="form-control" id="menuname"
                                                placeholder="Enter Menu Name" required="required"
                                                [(ngModel)]="txtmenuName" name="txtmenuName" />
                                            <div class="invalid-feedback">
                                                Please enter menu name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Menu Action<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="menuAction"
                                                placeholder="Enter Menu Action" required="required"
                                                [(ngModel)]="txtmenuAction" name="txtmenuAction" />
                                            <div class="invalid-feedback">
                                                Please enter menu action.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Menu Description</label>
                                            <input type="text" class="form-control" id="menuDescription"
                                                placeholder="Enter Menu Description" required="required"
                                                [(ngModel)]="txtmenuDescription" name="txtmenuDescription" />
                                            <div class="invalid-feedback">
                                                Please enter a menu description.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Select Parent</label>
                                            <select class="form-select" id="country" name="txtparent"
                                                [(ngModel)]="txtparent" required="">
                                                <option>-:Select Parent Menu:-</option>
                                                <option *ngFor="let parent of allMenu" value="{{parent.menuid}}">
                                                    {{parent.name}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter a parent.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Priority<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="priority"
                                                placeholder="Enter Priority" required="required"
                                                [(ngModel)]="txtpriority" name="txtpriority" />
                                            <div class="invalid-feedback">
                                                Please enter a priority.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-3 col-3 position-relative">
                                            <label for="country" class="form-label">Location<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchLocation" ngxTypeahead
                                                [taList]="searchLocationName" [taItemTpl]="myloc"
                                                (taSelected)="selectedLocation($event)" id="Location"
                                                [(ngModel)]="txtLocation" name="txtLocation"
                                                placeholder="Enter Location" class="ui-autocomplete-input form-control"
                                                autocomplete="off" autofocus="autofocus" />
                                            <ng-template #myloc let-item>
                                                <div class="item-name">
                                                    {{item.result.locationName}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter a location.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Role<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <!-- <select class="form-select" id="country" name="txtRole"
                                                [(ngModel)]="txtRole" required="">
                                                <option *ngFor="let role of allRole" [ngValue]="role">
                                                    {{role.name}}</option>
                                            </select> -->
                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                    [data]="dropdownList" name="selectedRoles"
                                                    [(ngModel)]="selectedRoles">
                                            </ng-multiselect-dropdown>
                                            <div class="invalid-feedback">
                                                Please enter a role.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearValue()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createMenu()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="portlet">
                            <div class="portlet-header">Menu Details</div>
                            <div class="portlet-content">

                                <div class="mt-3 table-responsive">
                                     <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <td scope="col">Menu Name</td>
                                            <!--<th scope="col">Menu Action</th>
                                            <th scope="col">Menu Description</th>
                                            <th scope="col">Select Parent</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Action</th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                         <!-- <tr *ngFor="let user of allMenu "> -->
                                           <!-- <td>{{user.name}}</td> -->
                                           <!-- <ul> -->
                                            <ul id="treeview">
                                                <ng-template #recursiveList let-list>
                                                    <li *ngFor="let item of list" class="expanded-menu">
                                                        <span class="fas fa-chevron-down toggle-menu"
                                                            style='font-size:11px;width: 18px;'
                                                            (click)="toggleCollapse($event)"
                                                            *ngIf="item.submenu.length > 0">&nbsp;&nbsp;</span>
                                                        <span class="fas fa-hand-point-right"
                                                            style='font-size:11px;width: 18px;'
                                                            *ngIf="item.submenu.length == 0">&nbsp;&nbsp;</span>
                                                        <a href="javascript:;" (click)="fillAllData(item)">{{item.name}}</a>
                                                        <ul *ngIf="item.submenu.length > 0">
                                                            <ng-container
                                                                *ngTemplateOutlet="recursiveList; context:{ $implicit: item.submenu }">
                                                            </ng-container>
                                                        </ul>
                                                    </li>
                                                </ng-template>
                                                <ng-container
                                                    *ngTemplateOutlet="recursiveList; context:{ $implicit: root }">
                                                </ng-container>
                                            </ul>
                                        <!-- </ul> -->
                                            <!--<td>{{user.action}}</td>
                                            <td>{{user.email}}</td>
                                            <td>{{user.password}}</td>
                                            <td>{{user.location}}</td>
                                            <td>{{user.state}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="updateUser(user)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteUser(user)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td> -->
                                        <!-- </tr> -->
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allMenu < 1">
                                            <td colspan="1" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>