<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Stock Adjustment</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">

                                        <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                            <label for="last" class="form-label">Item Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="search1" ngxTypeahead
                                                [taList]="allItem" [taItemTpl]="myTemp"
                                                (taSelected)="selectedStatic($event)" id="ItemName"
                                                [(ngModel)]="txtItemName" name="txtItemName"
                                                placeholder="Enter Item Name" class="ui-autocomplete-input form-control"
                                                autocomplete="off" autofocus="autofocus" />
                                            <ng-template #myTemp let-item>
                                                <div class="item-name">
                                                    {{item.result.itemName}}
                                                </div>
                                            </ng-template>

                                            <div class="invalid-feedback">
                                                Please enter item name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6 position-relative">
                                            <label for="last" class="form-label">Batch Number<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="txtBatchNumber" ngxTypeahead 
                                                [taList]="resStockAdjustmentBatchNo" [taItemTpl]="myBatchNo"
                                                (taSelected)="selectedBatchNo($event)" id="BatchNo"
                                                [(ngModel)]="txtBatchNumber" name="txtBatchNumber"
                                                placeholder="Enter Batch Number" class="ui-autocomplete-input form-control"
                                                autocomplete="off" autofocus="autofocus" (focusout)="toUpperCaseBatchNo()" />
                                            <ng-template #myBatchNo let-item>
                                                <div class="item-name">
                                                    {{item.result.batchnumber}}
                                                </div>
                                            </ng-template>

                                            <div class="invalid-feedback">
                                                Please enter item name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Batch Number<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtStockAdjustmentId"
                                                name="txtStockAdjustmentId">
                                            <input type="text" class="form-control" id="last" placeholder="Batch Number"
                                                required="required" [(ngModel)]="txtBatchNumber"
                                                name="txtBatchNumber" />
                                            <div class="invalid-feedback">
                                                Please enter a Batch Number.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-2 col-xl-1 col-6">
                                            <label for="country" class="form-label">Quantity<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Quantity" required="required"
                                                [(ngModel)]="txtquantity" name="txtquantity" (keypress) = "numberandMinusOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter a quantity.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">rate<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="rate" required="required"
                                                [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter a quantity.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Manufacture Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                class="form-control" [(ngModel)]="txtManufactureDate "
                                                name="txtManufactureDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <!-- <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Manufacture Date" required="required"
                                                    [(ngModel)]="txtManufactureDate" name="txtManufactureDate" /> -->
                                            <div class="invalid-feedback">
                                                Please enter a manufacture date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Expiry Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker1.open()" matInput [matDatepicker]="picker1" [min]="txtManufactureDate"
                                                class="form-control" [(ngModel)]="txtExpiryDate " name="txtExpiryDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker1" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                            <!-- <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Net Amount" required="required"
                                                    [(ngModel)]="txtExpiryDate" name="txtExpiryDate" /> -->
                                            <div class="invalid-feedback">
                                                Please enter a expiryDate.
                                            </div>
                                        </div>
                                        <!-- <div class="col-auto col-lg-1 btn-container-end">
                                                <button class="btn btn-primary" type="submit">
                                                    {{btnName}}
                                                </button>
                                            </div> -->
                                        <div class="portlet-footer">
                                            <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                            <div class="row">
                                                <div class="col-auto ms-auto">
                                                <button class="btn btn-secondary me-2" type="button"
                                                    (click)="clearValue()" (click)="clearValue()">
                                                    Cancel
                                                </button>
                                                <button class="btn btn-primary" type="submit"
                                                    (click)="createStockAdjustment()">
                                                    {{btnName}}
                                                </button>
                                                </div>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Stock Adjustment Details</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end mb-1">
                                <div class="col-md-8 d-flex">
                                    <div class="row">
                                        <div class="col-12 col-xl-6 bottom_margin">
                                        <input type="file" name="txtExcelStockAdjustment" [(ngModel)]="txtExcelStockAdjustment" 
                                        (change)="onExcelUploadStockAdjustment($event)" accept=".xls,.xlsx"/>
                                        </div>
                                        <div class="col-5 col-xl-3">
                                        <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                            (click)="exportExcelStockAdjustment()">Export&nbsp;To&nbsp;Excel</button>
                                        </div>
                                        <div class="col-5 col-xl-3">
                                        <button class="btn btn-primary d-block bottom_margin" type="submit"
                                            (click)="importandConvertExcelStockAdjustment()">Import&nbsp;Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group ">
                                        <!-- <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" /> -->
                                            <input type="text" [value]="txtItemNameSearch" ngxTypeahead
                                            [taList]="allItem" [taItemTpl]="myTempSearch"
                                            (taSelected)="selectedStaticItem($event)" id="ItemNameSearch"
                                            [(ngModel)]="txtItemNameSearch" name="txtItemNameSearch"
                                            placeholder="Enter Item Name" class="ui-autocomplete-input form-control"
                                            autocomplete="off" autofocus="autofocus" />
                                            <ng-template #myTempSearch let-item>
                                                <div class="item-name">
                                                    {{item.result.itemName}}
                                                </div>
                                            </ng-template>
                                        <span class="input-group-append">
                                            <!-- <button class="btn btn-primary" type="submit"
                                                    (click)="searchByItemName()">
                                                   Search
                                            </button> -->
                                            <button class="btn btn-outline-secondary border-0" type="button" (click)="searchByItemName()">
                                                <i class="fa fa-search"></i>
                                            </button>
                                            <button class="btn btn-outline-secondary border-0" type="button" (click)="searchBoxClear()">
                                                <i class="btn-close"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Batch Number</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Rate</th>
                                            <!-- <th scope="col">Taxable Value</th> -->
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Manufacture Date</th>
                                            <th scope="col">Expiry Date</th>
                                            <!-- <th scope="col" style="min-width: 100px;">Actions</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let stockadjust of allStockAdjustment | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{stockadjust.itemName}}</td>
                                            <td>{{stockadjust.batchNumber}}</td>
                                            <td>{{stockadjust.quantity}}</td>
                                            <td>{{stockadjust.rate}}</td>
                                            <!-- <td>{{stockadjust.taxableAmount | number : '1.2-2'}}</td> -->
                                            <td>{{stockadjust.totalAmount | number : '1.2-2'}}</td>
                                            <td>{{stockadjust.manufactureDate | date:'dd/MM/yyyy'}}</td>
                                            <td>{{stockadjust.expiryDate | date:'dd/MM/yyyy'}}</td>
                                            <!-- <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Edit"
                                                    (click)="updateStockAdjustment(stockadjust)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteStockAdjustment(stockadjust)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <!-- <tr *ngIf="allStockAdjustment.length == 0 "> -->
                                        <tr *ngIf="allStockAdjustment < 1 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>