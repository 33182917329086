import { stockDTO } from "../transfer/stockDTO";

export class sale{
    id1: number;
    manufactureDate: string;
    itemId: string;
    itemName: string;
    categoryName: string;
    subCategoryName: string;
    brandName: string;
    quantity: number;
    rate: number;
    expiryDate: string;
    cgst:number;
    sgst:number;
    cgstAmount:number;
    sgstAmount:number;
    totalAmount :number;
    netAmount :number;
    companyName: string;
    stockDTOs:Array<stockDTO>
}