import { Component, OnInit } from '@angular/core';
import { SaleService } from "../service/sale/sale.service";
import { SaleChallanService } from "../service/sale-challan/sale-challan.service";
import { DatePipe } from '@angular/common';
import { sale } from '../service/sale/sale';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../service/login/login.service';
declare var $: any;

@Component({
  selector: 'app-sale-challan-record',
  templateUrl: './sale-challan-record.component.html',
  styleUrls: ['./sale-challan-record.component.css']
})
export class SaleChallanRecordComponent implements OnInit {
  allsale;
  allSaleDetail;
  search;
  page: number = 1;
  txtrate;
  resSaleChallan;
  datePipe = new DatePipe("en-US");
  saleDetailres;
  saleNumber;
  txtsaleCode;
  txtsaleDate;
  txtMobileNumber;
  txtpartyName;
  txtgstAmount;
  txtamount;
  txtDiscount;
  txtnetAmount;
  saleData: Array<sale> = [];
  tempamount = 0;
  tempGST = 0;
  saleIdLast: number = 0;
  txtchallanNumber;
  loginUserId: any;
  isClick: any = false;

  constructor(
    private saleservice: SaleService,
    private salechallanservice: SaleChallanService,
    private toastr: ToastrService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.loginUserId = localStorage.getItem("loginUserId");
    this.getUserRoleByUserId();
    this.getAllsale();
    this.getLastSaleNumber();
  }
// Allow only Numbers and dot(.)
numberandDotOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keycode;
  if (charCode == 46) {
    return true;
  } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  } else {
    return true;
  }
}

  getAllsale() {
    this.salechallanservice.getAllsale().subscribe(res => {
      this.allsale = res;
      console.log(this.allsale);

    })
  }

  getLastSaleNumber() {
    this.salechallanservice.getSaleNumber().subscribe(res => {
      this.saleNumber = res;
      this.txtsaleCode = this.saleNumber;
    })
  }

  getSaleDetail(sale) {
    this.allSaleDetail = sale.salechallanDetails;
    this.resSaleChallan = sale;
    console.log(this.allSaleDetail);
    console.log(this.resSaleChallan);
    this.txtchallanNumber = this.resSaleChallan.challanNumber;
    this.txtMobileNumber = this.resSaleChallan.party.cellNumber;
    this.txtpartyName = this.resSaleChallan.party.partyName;
    this.txtgstAmount = this.resSaleChallan.gstAmount;
    this.txtamount = this.resSaleChallan.amount;
    this.txtDiscount = this.resSaleChallan.discount;
    this.txtnetAmount = this.resSaleChallan.netAmount;
    this.getChallanDetailByChallanId(sale.challanid);
  }

  getAmount() {
    this.txtamount = 0;
    this.txtgstAmount = 0;
    this.tempamount = 0;
    this.tempGST = 0;
    var temp = 0;
    var gstsum = 0;
    this.txtnetAmount = 0;

    for (let item of this.saleData) {
      // temp = item.quantity * item.rate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = this.tempamount * (item.cgst + item.sgst) / 100;
      // // gstsum = gstsum + this.tempGST;
      // this.txtgstAmount = this.tempGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;
      this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
      this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
      this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
    }
  }

  createSaleDetail() {
    // if (this.txtrate == undefined || this.txtrate == null || this.txtrate == "" || this.txtrate == 0) {
    //   return this.toastr.error('Rate must be required');
    // } else {
      this.saleData = [];
      for (let salchalan of this.allSaleDetail) {
        if (salchalan.rate == undefined || salchalan.rate == null || salchalan.rate == "" || salchalan.rate == 0) {
          return this.toastr.error('Rate must be required');
        }
        var saleDetail = new sale();
        if (this.saleData.length == 0) {
          saleDetail.id1 = this.saleIdLast;
        } else if (this.saleData.length != 0) {
          saleDetail.id1 = this.saleIdLast + 1;
        }
        this.saleIdLast = saleDetail.id1;
        saleDetail.itemName = salchalan.item.itemName;
        saleDetail.itemId = salchalan.item.itemid;
        saleDetail.categoryName = salchalan.item.category.categoryName;
        saleDetail.subCategoryName = salchalan.item.subCategory.subCategoryName;
        saleDetail.brandName = salchalan.item.brand.name;
        saleDetail.quantity = salchalan.quantity;
        saleDetail.rate = salchalan.rate;
        saleDetail.cgst = salchalan.item.gst.cgst;
        saleDetail.sgst = salchalan.item.gst.sgst;
        saleDetail.totalAmount = saleDetail.rate * saleDetail.quantity;
        //base price calculation  
        var totalpercentage = 100 + saleDetail.cgst + saleDetail.sgst
        var baseprice = 0;
        baseprice = parseFloat(Number(saleDetail.totalAmount * 100 / totalpercentage).toFixed(2));
        var totalgstamt = 0;
        totalgstamt = parseFloat(Number(baseprice * (saleDetail.cgst + saleDetail.cgst) / 100).toFixed(2));
        // saleDetail.cgstAmount = (saleDetail.rate *saleDetail.quantity) * (saleDetail.cgst) / 100;
        // saleDetail.sgstAmount = (saleDetail.rate * saleDetail.quantity) * (saleDetail.sgst) / 100;
        saleDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.netAmount = baseprice;
        saleDetail.manufactureDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        saleDetail.expiryDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        this.saleData.push(saleDetail);
      }
      // console.log(this.saleData);    
      this.getAmount();
      this.txtsaleDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.salechallanservice.saveSaleChallanToSale(this.txtsaleDate, this.txtsaleCode,this.txtchallanNumber, this.txtMobileNumber, this.txtpartyName, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.saleData)
      .subscribe(res => {
        this.saleDetailres = res;
        // this.txtrate = "";
        this.clearData();
        console.log(this.saleDetailres);
        this.getLastSaleNumber();
        this.toastr.success('Sale save successfully');
        this.getAllsale();
        // $('#exampleModal').modal('hide');
        $('#approvedModal').modal('hide');
        // $('.modal-backdrop').remove();
      }, error => {
        // this.toastr.error('Sale not saved');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    // }
  }

  deleteSaleChallenId;
  deleteSaleChallan(sale) {
    this.deleteSaleChallenId = sale.challanid;
  }

  resReceiptData;
  confirmDelete() {
    if (this.deleteSaleChallenId != null) {
      this.salechallanservice.deleteSaleChallan(this.deleteSaleChallenId).subscribe(res => {
        this.resReceiptData = res;
        console.log(this.resReceiptData);
        this.getAllsale();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Sale challan deleted successfully');
      }, error => {
        this.toastr.error('Sale challan not deleted');
      })
    }
  }

  clearData(){
    this.txtrate = null;
  }

  //Update Challan detail.
  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity;
  // txtrate;
  txtExpiryDate;
  btnName = 'Update';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];
  txtsaleid;
  itemId;

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getChallanDetailByChallanId(challanid){
    this.salechallanservice.getChallanDetailByChallanId(challanid).subscribe(res => {
      this.allSaleDetail = res;
    })
  }

  updateSale(sale){
    this.btnName = "Update";
    // this.txtsaleid = sale.saleid;
    this.txtsaleid = sale.challandetailid;
    this.txtItemName = sale.item.itemName;
    this.itemId = sale.item.itemid;
    this.search1 = sale.item.itemName;
    this.txtCategoryName = sale.item.category.categoryName;
    this.txtSubCategoryName = sale.item.subCategory.subCategoryName;
    this.txtBrandName = sale.item.brand.name;
    this.txtquantity = sale.quantity;
    this.txtrate = sale.rate;
    this.txtManufactureDate = sale.manufactureDate;
    this.txtExpiryDate = sale.expiryDate;
  }

  txtStockDTOs;
  stockQuantity: number = 0;
  resSaleDetail;
  updateSaleChallanDetail(){
    this.isClick = true;
    if (this.txtItemName == null || this.txtItemName == '' || this.txtItemName == undefined) {
      this.isClick = false;
      this.toastr.error("Item is required");
    } else if (this.txtBrandName == undefined || this.txtBrandName == null || this.txtBrandName == '') {
      this.isClick = false;
      return this.toastr.error('Brand name is required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == '' || this.txtquantity == 0) {
      this.isClick = false;
      return this.toastr.error('Quantity is required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == '' || this.txtrate == 0) {
      this.isClick = false;
      return this.toastr.error('Rate is required');
    } else if (this.batchwiseresponse == null) {
      this.isClick = false;
      return this.toastr.error('Please verify stock');
    } else {
      if (this.txtsaleid == null) {
        // this.saleservice.createSaleDetail(this.txtItemName,this.itemId,this.txtCategoryName,this.txtSubCategoryName, this.txtBrandName, this.txtquantity,this.txtrate)
        //   .subscribe(res => {
        //     this.resSaleDetail = res;
        //     this.getSaleDetailBySaleId(res.saleid);
        //     this.clearGridData();
        //     this.toastr.success('Sale detail save successfully');
        //   }, error => {
        //     let message = JSON.parse(error._body).message;
        //     this.toastr.error(JSON.stringify(message));
        //   })
      } else {
        if (this.batchwiseresponse == null) {
          this.getBatchNumber();
          this.txtStockDTOs = this.batchwiseresponse;
        } else {
          this.txtStockDTOs = this.batchwiseresponse;
        }
        this.stockQuantity = 0;
        for (let stockQuantityValue of this.batchwiseresponse) {
          this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
        }
        console.log("quantity",this.stockQuantity);
        if (this.stockQuantity > this.txtquantity) {
          this.isClick = false;
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is more than quantity.');
        }else if(this.stockQuantity < this.txtquantity){
          this.isClick = false;
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is less than quantity.');
        }

        this.salechallanservice.updateSaleChallanDetail(this.txtsaleid,this.txtItemName,this.itemId,this.txtCategoryName,this.txtSubCategoryName, this.txtBrandName, this.txtquantity,this.txtrate,this.txtStockDTOs).subscribe(res => {
          this.resSaleDetail = res;
          this.getChallanDetailByChallanId(res.saleid);
          this.clearGridData();
          this.toastr.success('Challan detail updated successfully');
        }, error => {
          this.isClick = false;
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  batchwiseresponse;
  totalStockQuantity = 0;
  getBatchNumber() {
    $('#exampleModal').modal('hide');
    $('#stockCheckModal').modal('show');
    $(document.body).addClass('static-modal-open');

    this.saleservice.getStockByItemPerBatch(this.itemId, this.txtquantity).subscribe(res => {
      this.batchwiseresponse = res;
      if (this.batchwiseresponse.length <= 0) {
        return this.toastr.error('Stock Not Found.');
      }
      console.log(this.batchwiseresponse);
      this.totalStockQuantity = 0;
      for (let batchWiseStock of this.batchwiseresponse) {
        if (batchWiseStock.allotedquantity == null) {
          batchWiseStock.allotedquantity = 0
        }
        var sum = 0;
        sum = sum + Number(batchWiseStock.quantity);
        this.totalStockQuantity = this.totalStockQuantity + sum;
      }
      console.log("batchWise Stock", this.batchwiseresponse);
      if(this.txtquantity > this.totalStockQuantity){
        this.toastr.error('Stock not available for item');
      } else {
        this.toastr.success('Stock available');
      }
      // this.addSaleDetail();
    }, error => {
      this.toastr.error('Stock not available for item');
    })
  }
  hideShowBarcodeModel() {
    $('#stockCheckModal').modal('hide');
    $('#exampleModal').modal('show');
  }
  hideShowHistoryModel() {
    $(document.body).removeClass('static-modal-open');
  }

  clearGridData() {
    this.txtItemName = null;
    this.txtManufactureDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = null;
    this.txtSubCategoryName = null;
    this.txtBrandName = null;
    this.txtquantity = null;
    this.txtrate = null;
    this.txtExpiryDate = new Date();
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = null;
    // this.btnName = "Add";
    this.btnName = "Update";
    this.txtsaleid = null;
    this.isClick = false;
    this.batchwiseresponse = null;
    this.txtStockDTOs = null;
  }
  
  userRoleName;
  userOrgName;
  getUserRoleByUserId() {
    this.loginService.getUserRoleByUserId(this.loginUserId).subscribe(res => {
      this.userRoleName = res.roleName;
      this.userOrgName = res.orgName;
      // console.log(this.userRoleName);
      // console.log(this.userOrgName);
    })
  }

  allPDFInvoice;
  challanNumber;
  totalNoOfInvoice = 0;
  getcompnayOrganizationPDF(sale) {
    this.challanNumber = sale.challanNumber;
    this.salechallanservice.getChallanPDFCategoryNoOfInvoiceData(sale.challanid).subscribe(res => {
      // console.log(res);
      // this.allPDFInvoice = res;
      this.allPDFInvoice = JSON.parse(res);
      for (let comp of this.allPDFInvoice) {
        this.totalNoOfInvoice = this.totalNoOfInvoice + comp.invoiceDetail.length;
      }
      // console.log("Total NoOfInvoice For Discount: "+this.totalNoOfInvoice);
    },error => {
      this.allPDFInvoice = null;
    })
  }

  // new sale challan report by category
  downloadNewPDF(company) {
    if("PESTICIDE" == company.staticinvoicename){
      this.downloadSalePesticideBill(company);
    } else if("FERTILISER" == company.staticinvoicename){
      this.downloadSaleFertiliserBill(company);
    } else if("SEEDS" == company.staticinvoicename){
      this.downloadSaleSeedsBill(company);
    }
  }
  openPdfResponsePesticide;
  resBillPdfPesticide;
  openPdfResponseFertiliser;
  resBillPdfFertiliser;
  openPdfResponseSeeds;
  resBillPdfSeeds;
  downloadSalePesticideBill(company) {
    this.salechallanservice.downloadSaleChallanPesticideBill(company.invoiceid,this.challanNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfPesticide = data;

      var newBlob1 = new Blob([this.resBillPdfPesticide], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "SaleChallan_PesticidesPDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponsePesticide.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfPesticide = '';
      this.openPdfResponsePesticide = '';
    })
  }
  downloadSaleFertiliserBill(company) {
    this.salechallanservice.downloadSaleChallanFertiliserBill(company.invoiceid,this.challanNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfFertiliser = data;

      var newBlob1 = new Blob([this.resBillPdfFertiliser], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "SaleChallan_SeedsPDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseFertiliser.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfFertiliser = '';
      this.openPdfResponseFertiliser = '';
    })
  }
  downloadSaleSeedsBill(company) {
    this.salechallanservice.downloadSaleChallanSeedsBill(company.invoiceid,this.challanNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfSeeds = data;

      var newBlob1 = new Blob([this.resBillPdfSeeds], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseSeeds.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfSeeds = '';
      this.openPdfResponseSeeds = '';
    })
  }

}
