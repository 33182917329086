import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ExpenseVoucherService {

  constructor(
    private http: Http,
  ) { }

  createExpenseVoucher(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId
    });
    return this.http.post('/api/create/expensevoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllExpense(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/expensevoucher', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Receipt By Id
  getAllExpensevoucherById(txtId:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/expensevoucher/'+txtId,{headers:headers})
    .pipe(map(res => res.json()));

  }

  createGstrVoucher(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId
    });
    return this.http.post('/api/create/gstrvoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }
  getAllGstr(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/gstrvoucher', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Receipt By Id
  getAllGstrVoucherById(gstrvoucherid:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/gstrvoucher/' + gstrvoucherid ,{headers:headers})
    .pipe(map(res => res.json()));

  }

}
