import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { PartyService } from '../service/party/party.service';
import { SaleService } from '../service/sale/sale.service';
import { searchSale } from '../service/sale/searchSale';
import { SearchParty } from '../service/party/searchparty';
import { paymentVoucherDTO } from '../service/payment_voucher/paymentVoucherDTO';
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';
declare var $: any;

@Component({
  selector: 'app-assets-voucher',
  templateUrl: './assets-voucher.component.html',
  styleUrls: ['./assets-voucher.component.css']
})
export class AssetsVoucherComponent implements OnInit {
  txtAssetsId;
  txtVoucherDate;
  txtselected;
  txtpartyName;
  txtsaleCode;
  txtpandingAmount;
  txtnetAmount;
  txtcredit;
  txtcompany;
  optradio;
  txtRadio;
  isDisabled: boolean = true;

  allreceiptvoucher;
  btnName = 'Add';
  allAssets;
  txtNarration;

  public searchParty = '';
  searchPartyName = [];
  public searchSale = '';
  searchsalecode = [];
  totalCredit = 0;
  allCompany;

  paymentData: Array<paymentVoucherDTO> = [];
  paymentIdLast: number = 0;
  datePipe = new DatePipe("en-US");
  txtChequeNo;
  txtAssLedger;
  allAssLibLedger;
  ledgerDisabled = false;
  
  constructor(
    private toastr: ToastrService,
    private partyService: PartyService,
    private saleservice: SaleService,
    // private paymentvoucherservice: PaymentVoucherService,
    private assetsVoucherService: AssetsVoucherService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.getAllParty();
    // this.getAllsale();
    // this.getAllPartyAccount();
    // this.getAllBank();
    this.getAllCompany();
    // this.getAllAssetsLedger();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party", this.allCompany);
    })
  }

  getAllAssetsLedger() {
    this.assetsVoucherService.getAllAssLibLedger("10").subscribe(res => {
      this.allAssLibLedger = res;
      // console.log("balance",this.allAssLibLedger);
    })
  }

  allBank;
  getAllBank() {
    this.partyService.getAllBank(this.txtcompany.partyId).subscribe(res => {
      this.allBank = res;
      // console.log("all Bank", this.allBank);
    })
  }

  checkBank(){
    if(this.txtRadio == "Bank"){
      if(this.txtcompany == null || this.txtcompany == "-:Select Company Name:-"){
        this.allBank = null;
      }else{
        this.getAllBank();
      }
    }
  }

  onItemChange(value) {
    // console.log(value);
    this.txtRadio = value;
    if (value == "Bank") {
      // console.log(value);
      this.isDisabled = false;
      if(this.txtcompany == null){
        this.toastr.error("Please select company");
      }else{
        this.getAllBank();
      }
      
    } else {
      this.isDisabled = true;
    }
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    // this.getLedgerByPartyId(party.partyId);
    this.getAllLedgerByPartyId(party.partyId);
    this.selectLedger(party.type);
  }
  allparty;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        temp.address = item.address;
        temp.type = item.type;
        this.searchPartyName.push(temp)
      }
      // console.log(this.allparty);
    })
  }

  partyLedger;
  getLedgerByPartyId(partyId) {
    this.partyService.getLedgerByPartyId(partyId).subscribe(res => {
      this.partyLedger = res.ledgerName;
    })
  }
  allPartyLedgerName;
  getAllLedgerByPartyId(partyId){
    this.partyService.getAllLedgerByPartyId(partyId).subscribe(res => {
      this.allPartyLedgerName = res;
    })
  }
  selectLedger(type){
    if(type == "COMPANY"){
      // this.txtLedger = "SUNDRY CREDITORS";
      // this.txtLedger = 21;
      this.txtAssLedger = 28;
      this.ledgerDisabled = true;
    } else if(type == "FARMER"){
      // this.txtLedger = "SUNDRY DEBITORS";
      // this.txtLedger = 20;
      this.txtAssLedger = 23;
      this.ledgerDisabled = true;
    } else {
      this.ledgerDisabled = false;
      this.txtAssLedger = null;
    }
  }

  
  allPartyCash;
  getAllPartyAccount() {
    this.partyService.getAllPartyAccount().subscribe(res => {
      this.allPartyCash = res;
    })
  }

  tempSale;
  selectedSaleCode(sale) {
    this.tempSale = sale;
    // console.log(this.tempSale);
    this.searchSale = sale.saleCode;
    // this.getsaleById();
  }

  allsale;
  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
      for (let item of this.allsale) {
        var temp = new searchSale();
        temp.saleCode = item.saleCode;
        temp.saleid = item.saleid;
        this.searchsalecode.push(temp)
      }
      // console.log(this.allsale);
    })
  }

  clearValue() {
    this.txtpartyName = "";
    this.txtcredit = "";
    this.searchParty = "";
    this.tempParty = "";
    this.txtNarration="";
    this.btnName = "Add";
    this.txtAssetsId = null;
    this.txtrefId=null;
    this.txtparticulars=null;
    this.txtcompany="";
    this.paymentData=null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txtChequeNo = '';
    this.txtAssLedger = '';
    this.partyLedger = null;
  }

  addAssetsVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      return this.toastr.error("Company name must be required")
    } else if (this.txtRadio == "Bank" && (this.txtselected == "" || this.txtselected == null || this.txtselected == undefined || this.txtselected == "-:Select Bank Name:-")) {
      return this.toastr.error("Bank name must be required")
    } else if (this.txtRadio == "Bank" && (this.txtChequeNo == "" || this.txtChequeNo == null || this.txtChequeNo == undefined)) {
      return this.toastr.error("Cheque number must be required")
    } else if (this.tempParty == undefined || this.tempParty == null || this.tempParty == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.txtAssLedger == undefined || this.txtAssLedger == null || this.txtAssLedger == "" || this.txtAssLedger == "-:Select Ledger Name:-") {
      return this.toastr.error('Ledger must be required');
    } else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
      return this.toastr.error('Amount must be required');
    } else {
      if (this.txtAssetsId == null) {
        var paymentDetail = new paymentVoucherDTO();
        paymentDetail.id1 = this.paymentIdLast + 1;
        this.paymentIdLast = paymentDetail.id1;
        // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
        paymentDetail.partyId = this.tempParty.partyId;
        paymentDetail.partyName = this.tempParty.partyName;
        paymentDetail.debit = this.txtcredit;
        paymentDetail.amount = this.txtcredit;
        paymentDetail.ledgerId = this.txtAssLedger;
        this.totalCredit = Number(this.totalCredit) + Number(paymentDetail.debit);
        this.paymentData.push(paymentDetail);
        // console.log(this.paymentData);
        // this.clearValue();
      } else {
        for (let Sale of this.paymentData) {
          if (Sale.id1 == this.txtAssetsId) {
            // Sale.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
            Sale.partyId = this.tempParty.partyId;
            Sale.partyName = this.tempParty.partyName;
            Sale.debit = this.txtcredit;
            Sale.amount = this.txtcredit;
            this.getAmount();
            this.clearValue();
          }
        }
      }
    }
    this.createAssetsVoucher();
    console.log(this.paymentData);
  }

  getAmount() {
    this.totalCredit = 0;
    for (let item of this.paymentData) {
      var sum = 0;
      sum = sum + Number(item.debit);
      this.totalCredit = this.totalCredit + sum;
    }
  }

  txtparticulars;
  txtrefId;
  paymentVoucherres;
  createAssetsVoucher() {
    for (let paymentDetail of this.paymentData) {
      paymentDetail.refId = this.txtcompany.partyId;
      paymentDetail.particulars = this.txtcompany.partyName;
      // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
      // receiptDetail.numbers = receiptDetail.numbers;
      paymentDetail.amount = paymentDetail.debit;
      // this.txtNarration = paymentDetail.narration;
    // paymentDetail.narration = this.txtNarration;
      paymentDetail.ledgerId = this.txtAssLedger;
      if (this.txtrefId == null || this.txtrefId == "") {
        this.txtrefId = paymentDetail.partyId;
        this.txtparticulars = paymentDetail.partyName;
      } else {
        this.txtrefId = this.txtrefId + ',' + paymentDetail.partyId;
        this.txtparticulars = this.txtparticulars + ',' + paymentDetail.partyName;
      }
    }

    let paymentDetails = new paymentVoucherDTO();
    paymentDetails.partyId = this.txtcompany.partyId;
    paymentDetails.partyName = this.txtcompany.partyName;
    paymentDetails.refId = this.txtrefId;
    paymentDetails.particulars = this.txtparticulars;
    paymentDetails.voucherDate = this.txtVoucherDate;
    // paymentDetails.narration = this.txtNarration;
      // this.txtNarration = paymentDetails.narration;
    paymentDetails.ledgerId = this.txtAssLedger;
    if(this.txtRadio == "Bank"){
      paymentDetails.ledgerId = this.txtAssLedger;
      paymentDetails.bankId = this.txtselected.bankid;
    }
    // paymentDetails.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.paymentData.push(paymentDetails);
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.assetsVoucherService.createAssetsVoucher(this.txtVoucherDate, this.paymentData, this.txtNarration, this.txtcompany.partyId,this.txtChequeNo)
      .subscribe(res => {
        this.paymentVoucherres = res;
        // console.log(this.paymentVoucherres);
        this.clearValue();
        this.paymentData = [];
        this.totalCredit = 0;
        this.toastr.success('Assets voucher save successfully');
      }, error => {
        // this.toastr.error('Assets voucher not saved');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));

      })
  }

  getAllAssets() {
    this.assetsVoucherService.getAllAssets().subscribe(res => {
      this.allAssets = res;
    })
  }

  // updatePayment(payment) {
  //   this.btnName = "Update";
  //   this.txtAssetsId = payment.id1;
  //   this.txtVoucherDate = payment.voucherDate;
  //   this.txtpartyName = payment.partyName;
  //   var abc = new SearchParty();
  //   abc.partyId = payment.partyId;
  //   abc.partyName = payment.partyName;
  //   this.tempParty = abc;
  //   this.txtcredit = payment.credit;
  // }

  // i;
  // deletePaymentData;
  // deletePayment(payment) {
  //   this.deletePaymentData = payment;

  // }

  // confirmDelete() {
  //   for (let abc of this.paymentData) {
  //     if (abc.id1 == this.deletePaymentData.id1) {
  //       this.i = this.deletePaymentData.id;
  //       let index = this.paymentData.indexOf(this.deletePaymentData);
  //       if (abc.id1 !== -1) {
  //         this.paymentData.splice(index, 1);
  //       }
  //     }
  //     $('#deleteModal').modal('hide');
  //     $('.modal-backdrop').remove();
  //     this.getAmount();
  //   }
  // }

}
