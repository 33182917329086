import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SmsIntegrationService {

  constructor(
    private http: Http,
  ) { }
  createSMS(txtSmsType: string,txtTemplateId: string,txtMessage: string, txtOrganizationName: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token", token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      type: txtSmsType,
      templateId: txtTemplateId,
      message: txtMessage,
      organizationName: txtOrganizationName
    });
    return this.http.post('/api/create/sms', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateSMS(txtSmsId: string, txtSmsType: string,txtTemplateId: string,txtMessage: string, txtOrganizationName: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      msgId: txtSmsId,
      type: txtSmsType,
      templateId: txtTemplateId,
      message: txtMessage,
      organizationName: txtOrganizationName
    });
    return this.http.put('/api/update/sms', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllSMS() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/sms', { headers })
      .pipe(map(res => res.json()));
  }

  deleteSMS(txtSmsId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      msgId: txtSmsId,
    });
    return this.http.put('/api/delete/sms', body, { headers })
      .pipe(map(res => res.json()));
  }
}
