import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { TermConditionService } from '../service/term-condition/term-condition.service';
declare var $: any;

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {
  resTermsCondition;
  resUpTermsCondition;
  resDelTermsCondition;
  txtTermsCondition;
  txtTermsConditionId;
  allTermsCondition;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private termConditionService: TermConditionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllTermsCondition();
  }

  getAllTermsCondition() {
    this.termConditionService.getAllTermsCondition().subscribe(res => {
      this.allTermsCondition = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtTermsCondition = '';
    this.txtTermsConditionId = null;
  }

  toUpperCaseTermsCondition() {
    const temp = this.txtTermsCondition;
    this.txtTermsCondition = temp.toUpperCase();
    return this.txtTermsCondition;
  }

  createTermsCondition() {
    if (this.txtTermsCondition == null || this.txtTermsCondition == '' || this.txtTermsCondition == undefined) {
      this.toastr.error("Terms Condition is required");
    } else {
      if (this.txtTermsConditionId == null) {
        this.termConditionService.createTermsCondition(this.txtTermsCondition)
          .subscribe(res => {
            this.resTermsCondition = res;
            this.clearData();
            this.getAllTermsCondition();
            this.toastr.success('Terms condition save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.termConditionService.updateTermsCondition(this.txtTermsConditionId, this.txtTermsCondition).subscribe(res => {
          this.resUpTermsCondition = res;
          this.clearData();
          this.getAllTermsCondition();
          this.toastr.success('Terms condition updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateTermsCondition(term) {
    this.btnName = 'Update';
    this.txtTermsConditionId = term.termconditionId;
    this.txtTermsCondition = term.termconditionName;
  }

  deleteid;
  deleteTermsCondition(term) {
    this.deleteid = term.termconditionId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.termConditionService.deleteTermsCondition(this.deleteid).subscribe(res => {
        this.resDelTermsCondition = res;
        this.clearData();
        this.getAllTermsCondition();
        $('#deleteModal').modal('hide');
        this.toastr.success('Terms condition deleted successfully');
      }, error => {
        this.toastr.error('Terms condition not deleted');
      })
    }
  }

}
