import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private http: Http,
  ) { }

  createPlan(txtPlanName: string,txtNoOfUser: string,txtNoOfLocation: string,txtNoOfOrganization: string,txtNoOfUserLimit: string,txtNoOfLocationLimit: string,txtNoOfOrganizationLimit: string,txtPlanPrice: string,txtPeriodTime: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token", token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planName: txtPlanName,
      numberOfUser: txtNoOfUser,
      numberOfLocation: txtNoOfLocation,
      numberOfOrganization: txtNoOfOrganization,
      userLimit: txtNoOfUserLimit,
      locationLimit: txtNoOfLocationLimit,
      organizationLimit: txtNoOfOrganizationLimit,
      price: txtPlanPrice,
      periodTime : txtPeriodTime,
    });
    return this.http.post('/api/create/plan', body, { headers })
      .pipe(map(res => res.json()));
  }

  updatePlan(txtPlanId: string, txtPlanName: string,txtNoOfUser: string,txtNoOfLocation: string,txtNoOfOrganization: string,txtNoOfUserLimit: string,txtNoOfLocationLimit: string,txtNoOfOrganizationLimit: string,txtPlanPrice: string,txtPeriodTime: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planId: txtPlanId,
      planName: txtPlanName,
      numberOfUser: txtNoOfUser,
      numberOfLocation: txtNoOfLocation,
      numberOfOrganization: txtNoOfOrganization,
      userLimit: txtNoOfUserLimit,
      locationLimit: txtNoOfLocationLimit,
      organizationLimit: txtNoOfOrganizationLimit,
      price: txtPlanPrice,
      periodTime : txtPeriodTime,
    });
    return this.http.put('/api/update/plan', body, { headers })
      .pipe(map(res => res.json()));
  }

  gatAllPlan(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/api/getall/plan', { headers })
      .pipe(map(res => res.json()));
  }

  deletePlan(txtPlanId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planId: txtPlanId,
    });
    return this.http.put('/api/delete/plan', body, { headers })
      .pipe(map(res => res.json()));
  }

}
