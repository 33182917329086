import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CropsProductService } from '../service/crops-product/crops-product.service';
import { CropsService } from '../service/crops/crops.service';
import { ProductAppService } from '../service/product-app/product-app.service';
declare var $: any;

@Component({
  selector: 'app-crops-product',
  templateUrl: './crops-product.component.html',
  styleUrls: ['./crops-product.component.css']
})
export class CropsProductComponent implements OnInit {
  txtCropsName;
  txtCropsProductId;
  txtCropsProduct;
  allCropsProduct;
  resCropsProduct;
  resCropsProductUp;
  resCropsProductDel;
  btnName = 'Save';
  search;
  page: number = 1;

  cropsId;
  productId;
  allCrops;
  allProductApp;
  searchCropsName = [];
  public searchCrops = '';
  searchProductName = [];
  public searchProduct = '';

  constructor(
    private cropsProductService: CropsProductService,
    private productAppService: ProductAppService,
    private cropsService: CropsService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllCrops();
    this.getAllProductApp();
    this.getAllCropsProduct();
  }

  getAllCropsProduct() {
    this.cropsProductService.getAllCropsProduct().subscribe(res => {
      this.allCropsProduct = res;
    })
  }

  getAllCrops() {
    this.cropsService.getAllCrops().subscribe(res => {
      this.allCrops = res;
      for (let crop of this.allCrops) {
        this.searchCropsName.push({"cropsName": crop.cropsName,"cropsId": crop.id});
      }
    })
  }
  tempCrops;
  selectedCrops(crops) {
    this.tempCrops = crops;
    this.cropsId = crops.cropsId;
    this.searchCrops = crops.cropsName;
  }

  getAllProductApp() {
    this.productAppService.getAllProductApp().subscribe(res => {
      this.allProductApp = res;
      for (let prd of this.allProductApp) {
        this.searchProductName.push({"productName": prd.productName,"productId": prd.productid});
      }
    })
  }
  tempProduct;
  selectedProduct(product) {
    this.tempProduct = product;
    this.searchProduct = product.productName;
    this.productId = product.productId;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCropsName = '';
    this.txtCropsProduct = '';
    this.txtCropsProductId = null;
    this.tempCrops = null;
    this.cropsId = null;
    this.searchCrops = null;
    this.tempProduct = null;
    this.searchProduct = null;
    this.productId = null;
  }

  createCropsProduct() {
    var pro = this.txtCropsProduct;
    var cro = this.txtCropsName;
    if (this.txtCropsName == null || this.txtCropsName == '' || this.txtCropsName == undefined || (this.txtCropsName = cro.trim()) == "" ) {
      this.toastr.error("Crops name is required");
    }else if (this.txtCropsProduct == null || this.txtCropsProduct == '' || this.txtCropsProduct == undefined || (this.txtCropsProduct = pro.trim()) == "") {
      this.toastr.error("Crops product name is required");
    } else {
      if (this.txtCropsProductId == null) {
        this.cropsProductService.createCropsProduct(this.txtCropsName, this.txtCropsProduct, this.cropsId, this.productId)
          .subscribe(res => {
            this.resCropsProduct = res;
            this.clearData();
            this.getAllCropsProduct();
            this.toastr.success('Crops product save successfully');
          }, error => {
            // this.toastr.error('Crops product not save');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.cropsProductService.updateCropsProduct(this.txtCropsProductId, this.txtCropsName, this.txtCropsProduct, this.cropsId, this.productId).subscribe(res => {
          this.resCropsProductUp = res;
          this.clearData();
          this.getAllCropsProduct();
          this.toastr.success('Crops Product updated successfully');
        }, error => {
          // this.toastr.error('Crops Product not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateCropsProduct(pdt) {
    this.btnName = 'Update';
    this.txtCropsProductId = pdt.id;
    this.txtCropsName = pdt.crops.cropsName;
    this.txtCropsProduct = pdt.productApp.productName;
    this.cropsId = pdt.crops.id;
    this.searchCrops = pdt.crops.cropsName;
    this.productId = pdt.productApp.productid;
    this.searchProduct = pdt.productApp.productName;
  }

  deleteCropProductId;
  deleteCropsProduct(pdt) {
    this.deleteCropProductId = pdt.id;
  }

  confirmDelete() {
    if (this.deleteCropProductId != null) {
      this.cropsProductService.deleteCropsProduct(this.deleteCropProductId)
        .subscribe(res => {
          this.resCropsProductDel = res;
          this.clearData();
          this.getAllCropsProduct();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Crops Product deleted successfully');
        }, error => {
          this.toastr.error('Crops Product not deleted');
        })
    }
  }

}
