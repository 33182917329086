import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentVoucherService } from "../service/payment_voucher/payment-voucher.service";
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { SaleService } from '../service/sale/sale.service';
declare var $: any;
@Component({
  selector: 'app-payment-v-record',
  templateUrl: './payment-v-record.component.html',
  styleUrls: ['./payment-v-record.component.css']
})
export class PaymentVRecordComponent implements OnInit {
  allPayment;
  allPaymentDetail;
  allPaymentById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;
  public loading: boolean = false;
  datePipe = new DatePipe("en-US");
  txtFormDate;
  txtToDate;
  
  constructor(
    private paymentvoucherservice: PaymentVoucherService,
    private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService,
    private saleService: SaleService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.getAllpayment();
    this.getAllPaymentByDate();
  }

  MovePayment() {
    this.router.navigate(['paymentvoucher']);
  }

  // getAllpayment() {
  //   this.paymentvoucherservice.getAllpayment().subscribe(res => {
  //     this.allPayment = res;
  //   })
  // }
  userRole;
  getAllPaymentByDate() {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.paymentvoucherservice.getAllPaymentByDate(this.txtFormDate,this.txtToDate).subscribe(res => {
      // this.allPayment = res;
      this.allPayment = res.paymentvoucher;
      this.userRole = res.userrole;
    })
  }

  paymentDetail(payment) {
    this.getReceiptVoucherById(payment.voucherMasterId)
    // this.txtDate = receipt.voucherDate;
  }

  getReceiptVoucherById(txtId) {
    this.receiptvoucherservice.getAllReceiptById(txtId).subscribe(res => {
      this.allPaymentById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allPaymentById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }

  whatsappsale;
  whatsappSale(res) {
    this.paymentvoucherservice.whatsappSale(res.voucherMasterId).subscribe(res => {
      this.whatsappsale = res;
      this.sendRevisedBillMSG(res);
    })
  }
  
  sendMSG;
  sendRevisedBillMSG(res) {
    this.saleService.sendRevisedBillMSG(res.mobileNumber,res.vrfmassage).subscribe(res => {
      this.sendMSG = res;
    })
  }

  resBillPdf
  openPdfResponse
  paymentVoucherPdf(payment) {
    this.paymentvoucherservice.downloadPaymentVoucher(payment.voucherMasterId).subscribe((data) => {
      this.resBillPdf = data;

      var newBlob = new Blob([this.resBillPdf], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Sale_Bij_Nigam.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponse.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
      this.resBillPdf = '';
      this.openPdfResponse = '';
    })
  }

  deletePaymentId;
  deletePayment(payment){
    this.deletePaymentId = payment.voucherMasterId;
  }

  resPaymentData;
  confirmDelete() {
    if (this.deletePaymentId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deletePaymentId)
        .subscribe(res => {
          this.resPaymentData = res;
          // this.getAllpayment();
          this.getAllPaymentByDate();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Payment data deleted successfully');
        }, error => {
          this.toastr.error('Payment data not deleted');
        })
    }
  }

  txtEmail;
  vouchermstId;
  getsendEmailData(payment) {
    // console.log(receipt);
    this.vouchermstId = payment.voucherMasterId;
    // if (payment.party.mailId != null) {
    //   this.txtEmail = payment.party.mailId;
    // }
  }
  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }

  paymentMailWithSendGrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.paymentvoucherservice.paymentMailWithSendGrid(this.vouchermstId,this.txtEmail).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#emailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  sendMail() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("email address is required."); 
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.paymentvoucherservice.sendPaymentEmail(this.vouchermstId, this.txtEmail).subscribe(res => {
        console.log(res);
        $("#emailModal").modal('hide');
        this.toastr.success("Email sent successfully");
      })
    }
  }
}
