import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../service/excel/excel.service';
import { PartyService } from '../service/party/party.service';
declare var $: any;

@Component({
  selector: 'app-party-type-wise',
  templateUrl: './party-type-wise.component.html',
  styleUrls: ['./party-type-wise.component.css']
})
export class PartyTypeWiseComponent implements OnInit {
  allPartyByType;
  search;
  page: number = 1;

  constructor(
    private PartyService: PartyService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.getAllParty();
  }

  allPartyForExcel = [];
  totalParty = 0;
  getAllParty() {
    this.searchPartyName = [];
    this.PartyService.getAllPartyTypeWiseReport().subscribe(res => {
      this.allPartyByType = res;
      this.allPartyForExcel = res;
      this.totalParty = res.length;
      for (let item of res) {
        this.searchPartyName.push({ "Address":item.address, "partyId": item.partyId, "party": item})
      }
    })
  }
  
 public searchParty = '';
 searchPartyName = [];
  tempPartyId;
  txtpartyName;
  selectedParty(party) {
    this.allPartyForExcel = [];
    this.searchParty = party.Address;
    this.tempPartyId = party.partyId;
    this.allPartyForExcel.push(party.party);
  }
  searchBoxClear(){
    this.allPartyForExcel = [];
    this.searchParty = null;
    // this.getAllParty();
  }

  ExalData=[];
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.allPartyForExcel){
      this.ExalData.push({ "Party Name":exal.partyName, "Address": exal.address,"Cell Number":exal.cellNumber, "Type": exal.type })
    }
    this.excelservice.exportAsExcelFile(this.ExalData, 'PartyTypeWise');
 }
 
}
