import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { CompanyService } from "../service/company/company.service";
import { InvoiceMasterService } from '../service/invoice-master/invoice-master.service';
declare var $: any;

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {
  resCompany;
  resUpCompany;
  txtCompany;
  allCompany;
  txtSignatureName;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  txtSignatureId;
  allSignature;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private invoiceMasterService: InvoiceMasterService,
    private companyService: CompanyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllCompany();
    this.getAllSignature();
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }
  getAllSignature() {
    this.invoiceMasterService.getAllSignature().subscribe(res => {
      this.allSignature = res;
    })
  }

  toUpperCaseSignatureName() {
    const temp = this.txtSignatureName;
    this.txtSignatureName = temp.toUpperCase();
    return this.txtSignatureName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCompany = '';
    this.txtSignatureName = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
    this.txtSignatureId = null;
  }

  createSignature() {
    if (this.txtCompany == null || this.txtCompany == '' || this.txtCompany == undefined) {
      this.toastr.error("Company is required");
    } else {
      if (this.txtSignatureId == null) {
        this.invoiceMasterService.createSignature(this.txtCompany,this.txtSignatureName,this.txtImage)
          .subscribe(res => {
            this.resCompany = res;
            this.uploadImage(res);
            // this.clearData();
            // this.getAllSignature();
            this.toastr.success('Signature save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.invoiceMasterService.updateSignature(this.txtSignatureId, this.txtCompany,this.txtSignatureName, this.txtImage).subscribe(res => {
          this.resUpCompany = res;
          this.uploadImage(res);
          // this.clearData();
          // this.getAllSignature();
          this.toastr.success('Signature updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateSignature(company) {
    this.btnName = 'Update';
    this.txtSignatureId = company.signatureId;
    this.txtCompany = company.companyId;
    this.txtSignatureName = company.signatureName;
    this.txtImage = company.image;
    this.imagePreview = company.image;
  }

  deleteid;
  deleteSignature(company) {
    this.deleteid = company.signatureId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.invoiceMasterService.deleteSignature(this.deleteid).subscribe(res => {
        this.resUpCompany = res;
        this.clearData();
        this.getAllSignature();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Signature deleted successfully');
      }, error => {
        this.toastr.error('Signature not deleted');
      })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage(sign) {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.imagePreview = "";
      this.getAllSignature();
      this.clearData();
      // this.uploadQR();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('sign', sign.signatureId);
      this.invoiceMasterService.uploadSignatureImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllSignature();
        this.clearData();
        // this.uploadQR();
        this.imagePreview = "";
      }, error => {
        this.imagePreview = "";
        this.clearData();
        this.getAllSignature();
        // this.uploadQR();
      })
    }
  }

}
