import { Component, OnInit } from '@angular/core';
import { ExcelService } from '../service/excel/excel.service';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '../service/category/category.service';
import { SubCategoryService } from '../service/sub_category/sub-category.service';
import { CompanyService } from '../service/company/company.service';
import { BrandService } from '../service/brand/brand.service';
import { UnitService } from '../service/unit/unit.service';
import { LocationService } from '../service/location/location.service';
import { GSTService } from '../service/GST/gst.service';
import { ItemsDetailService } from '../service/items-detail/items-detail.service';
import { Router } from '@angular/router';
import { LoginService } from '../service/login/login.service';

@Component({
  selector: 'app-initial-project-detail',
  templateUrl: './initial-project-detail.component.html',
  styleUrls: ['./initial-project-detail.component.css']
})
export class InitialProjectDetailComponent implements OnInit {
  // Company = false;
  // Category = false;
  // subCategory = false;
  // Brand = false;
  // Unit = false;
  // Location = false;
  // GST = false;
  // Item = false;
  // confirm = false;
  step = 0;

//Subcategory
  ExcelDatasubcat = [];
  fileName;
  txtExcelSubCategory;
  selectedExcel;
  updatePriceList;
  allCategory;
  catArray = [];

  //Category
  txtExcelCategory;
  ExcelDataCategory = [];
  fileNameCategory;
  selectedExcelCategory;
  categoryData;
  allCompany;
  comArray = [];

  //Company
  txtCompany;
  txtGstNo;
  resCompany;

  // Brand
  txtExcelBrand;
  ExcelDataBrand = [];
  fileNameBrand;
  selectedExcelBrand;
  brandData;

  // Unit
  txtExcelUnit;
  ExcelDataUnit = [];
  fileNameUnit;
  selectedExcelUnit;
  unitData;

  //Locatiion
  txtLocation;
  txtAddress;
  txtPESLICNumber;
  txtFERLICNumber;
  txtSEEDLICNumber;
  resLocation;

  // GST
  txtExcelGST;
  ExcelDataGST = [];
  fileNameGST;
  selectedExcelGST;
  gstData;

  //Item
  txtExcelItem;
  ExcelDataItem = [];
  fileNameItem;
  selectedExcelItem;
  itemData;
  allSubCategory;
  subCatArray = [];
  allBrand;
  brandArray = [];
  allUnit;
  unitArray = [];
  allGst;
  gstArray = [];

  constructor(
    private excelservice: ExcelService,
    private toastr: ToastrService,
    private categoryservice: CategoryService,
    private SubCategoryService: SubCategoryService,
    private companyService: CompanyService,
    private brandService: BrandService,
    private UnitService: UnitService,
    private LocationService: LocationService,
    private GSTService: GSTService,
    private itemsDetailService: ItemsDetailService,
    private Router: Router,
    private LoginService: LoginService,
    private itemsdetailservice: ItemsDetailService,
  ) { }

  ngOnInit(): void {
    this.getLoginUserData();
    this.loadScript('../assets/script/script.js');
    this.getAllCategory();
    this.getAllCompany();
    this.getAllSubCategory();
    this.getAllBrand();
    this.getAllUnit();
    this.getAllGst();
    document.getElementById("confirmblock").style.display = 'none';
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  // subCategory Excel
  getAllCategory() {
    this.catArray = [];
    this.categoryservice.gatAllCategory().subscribe(res => {
      this.allCategory = res;
      for(let cat of res){
        // this.catArray.push(cat.categoryName);
        this.catArray.push({"Category":cat.categoryName});
      }
    })
  }
  exportAsXLSX(): void {
    // this.getAllCategory();
    this.ExcelDatasubcat = [];
    this.fileName = "subcategory";
    // for (let sub of this.allSubCategory) {
    // this.ExcelDatasubcat.push({"Category": sub.category.categoryName, "SubCategory": sub.subCategoryName });
    // }
    this.excelservice.exportExcelSubcategoryINIT(this.fileName,this.ExcelDatasubcat,this.catArray);
  }
  onExcelUpload(event){
    this.selectedExcel = <File>event.target.files[0];
    // this.txtfilename = event.target.files[0].name;
  }
  importandConvertExcel() {
    if (this.selectedExcel) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcel);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var abc = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[abc]);
        this.updatePriceList = rowObject;
        // console.log(this.updatePriceList);
        var resCategory = [];
        for(let data of this.updatePriceList){
          resCategory.push({"categoryName":data.Category,"subCategoryName":data.SubCategory});
        }
        //save excel data inot server
        this.SubCategoryService.importExcelSubcategoryINIT(resCategory).subscribe(res => {
        // console.log(res);
          this.toastr.success("sub Category detail save successfully");
          this.selectedExcel = '';
          this.txtExcelSubCategory = '';
          // this.txtfilename = '';
          this.getAllSubCategory();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }
  
  // Category Excel
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      for(let com of res){
        this.comArray.push(com.companyName);
      }
    })
  }
  exportExcelCategoryINIT(): void {
    // this.getAllCompany();
    this.ExcelDataCategory = [];
    this.fileNameCategory = "category";
    this.excelservice.exportExcelCategoryINIT(this.fileNameCategory,this.ExcelDataCategory,this.comArray);
  }
  onExcelUploadCategory(event){
    this.selectedExcelCategory = <File>event.target.files[0];
  }
  importandConvertExcelCategory(){
    if (this.selectedExcelCategory) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelCategory);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var category = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[category]);
        this.categoryData = rowObject;
        var resCategory = [];
        for(let data of this.categoryData){
          resCategory.push({"categoryName":data.categoryName,"hsnCode":data.hsnCode,"companyName":data.companyName});
        }
        //save excel data inot server
        this.categoryservice.importExcelCategoryINIT(resCategory).subscribe(res => {
        // console.log(res);
          this.toastr.success("Category detail save successfully");
          this.selectedExcelCategory = '';
          this.txtExcelCategory = '';
          // this.txtfilename = '';
          this.getAllCategory();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  //Company
  toUpperCaseCompany() {
    const temp = this.txtCompany;
    this.txtCompany = temp.toUpperCase();
    return this.txtCompany;
  }
  clearDataCompany() {
    this.txtCompany = null;
    this.txtGstNo = null;
  }
  txtQRImage = null;
  createCompany() {
    if (this.txtCompany == null || this.txtCompany == '' || this.txtCompany == undefined) {
      this.toastr.error("Company is required");
    } else {
      this.companyService.createCompany(this.txtCompany,this.txtGstNo,this.txtQRImage)
        .subscribe(res => {
          this.resCompany = res;
          this.clearDataCompany();
          this.getAllCompany();
          this.toastr.success('Company save successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
    }
  }

  // Brand
  exportExcelBrandINIT(): void {
    this.ExcelDataBrand = [];
    this.fileNameBrand = "brand";
    this.excelservice.exportExcelBrandINIT(this.fileNameBrand,this.ExcelDataBrand);
  }
  onExcelUploadBrand(event){
    this.selectedExcelBrand = <File>event.target.files[0];
  }
  importandConvertExcelBrand(){
    if (this.selectedExcelBrand) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelBrand);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var brand = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[brand]);
        this.brandData = rowObject;
        var resBrand = [];
        for(let data of this.brandData){
          resBrand.push({"name":data.brandName});
        }
        //save excel data inot server
        this.brandService.importExcelBrandINIT(resBrand).subscribe(res => {
        // console.log(res);
          this.toastr.success("Brand detail save successfully");
          this.selectedExcelBrand = '';
          this.txtExcelBrand = '';
          // this.txtfilename = '';
          this.getAllBrand();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  // Unit
  exportExcelUnitINIT(): void {
    this.ExcelDataUnit = [];
    this.fileNameUnit = "unit";
    this.excelservice.exportExcelUnitINIT(this.fileNameUnit,this.ExcelDataUnit);
  }
  onExcelUploadUnit(event){
    this.selectedExcelUnit = <File>event.target.files[0];
  }
  importandConvertExcelUnit(){
    if (this.selectedExcelUnit) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelUnit);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var unit = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[unit]);
        this.unitData = rowObject;
        var resUnit = [];
        for(let data of this.unitData){
          resUnit.push({"unitName":data.unitName});
        }
        //save excel data inot server
        this.UnitService.importExcelUnitINIT(resUnit).subscribe(res => {
        // console.log(res);
          this.toastr.success("Unit detail save successfully");
          this.selectedExcelUnit = '';
          this.txtExcelUnit = '';
          // this.txtfilename = '';
          this.getAllUnit();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  //Location
  toUpperCaseLocation() {
    const temp = this.txtLocation;
    this.txtLocation = temp.toUpperCase();
    return this.txtLocation;
  }
  clearDataLocation() {
    this.txtLocation = null;
    this.txtAddress = null;
    this.txtFERLICNumber = null;
    this.txtPESLICNumber = null;
    this.txtSEEDLICNumber = null;
  }
  createLocation() {
    if (this.txtLocation == null || this.txtLocation == '' || this.txtLocation == undefined) {
      this.toastr.error("location is required");
    } else {
      this.LocationService.createLocation(this.txtLocation,this.txtAddress,this.txtPESLICNumber,this.txtFERLICNumber,this.txtSEEDLICNumber)
        .subscribe(res => {
          this.resLocation = res;
          this.clearDataLocation();
          // this.getAllLocation();
          this.toastr.success('location save successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
    }
  }

  // GST
  exportExcelGSTINIT(): void {
    this.ExcelDataGST = [];
    this.fileNameGST = "gst";
    this.excelservice.exportExcelGSTINIT(this.fileNameGST,this.ExcelDataGST);
  }
  onExcelUploadGST(event){
    this.selectedExcelGST = <File>event.target.files[0];
  }
  importandConvertExcelGST(){
    if (this.selectedExcelGST) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelGST);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var gst = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[gst]);
        this.gstData = rowObject;
        var resGST = [];
        for(let data of this.gstData){
          resGST.push({"gstName":data.gstName,"cgst":data.cgst,"sgst":data.sgst});
        }
        //save excel data inot server
        this.GSTService.importExcelGSTINIT(resGST).subscribe(res => {
        // console.log(res);
          this.toastr.success("GST detail save successfully");
          this.selectedExcelGST = '';
          this.txtExcelGST = '';
          // this.txtfilename = '';
          this.getAllGst();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  // Item Excel
  getAllSubCategory() {
    this.SubCategoryService.getAllSubCategory().subscribe(res => {
      this.allSubCategory = res;
      console.log(this.allSubCategory);
      for(let subcat of res){
        // this.subCatArray.push(subcat.subCategoryName);
        this.subCatArray.push({"SubCategory":subcat.subCategoryName});
      }
    })
  }
  getAllBrand() {
    this.brandService.getAllBrand().subscribe(res => {
      this.allBrand = res;
      for(let brd of res){
        // this.brandArray.push(brd.name);
        this.brandArray.push({"Brand":brd.name});
      }
    })
  }
  getAllUnit() {
    this.UnitService.getAllUnit().subscribe(res => {
      this.allUnit = res;
      for(let unit of res){
        // this.unitArray.push(unit.unitName);
        this.unitArray.push({"Unit":unit.unitName});
      }
    })
  }
  getAllGst() {
    this.GSTService.getAllGst().subscribe(res => {
      this.allGst = res;
      for(let gst of res){
        // this.gstArray.push(gst.gstName);
        this.gstArray.push({"GST":gst.gstName});
      }
    })
  }
  exportExcelItemINIT(): void {
    // this.getAllCategory();
    // this.getAllSubCategory();
    // this.getAllBrand();
    // this.getAllUnit();
    // this.getAllGst();
    this.ExcelDataItem = [];
    this.fileNameItem = "Item";
    // for (let sub of this.allSubCategory) {
    // this.ExcelDatasubcat.push({"Category": sub.category.categoryName, "SubCategory": sub.subCategoryName });
    // }
    this.excelservice.exportExcelItemINIT(this.fileNameItem,this.ExcelDataItem,this.catArray,this.subCatArray,this.brandArray,this.unitArray,this.gstArray);
  }
  onExcelUploadItem(event){
    this.selectedExcelItem = <File>event.target.files[0];
    // this.txtfilename = event.target.files[0].name;
  }
  importandConvertExcelItem() {
    if (this.selectedExcelItem) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelItem);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var abc = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[abc]);
        this.itemData = rowObject;
        var resCategory = [];
        for(let data of this.itemData){
          resCategory.push({"itemName":data["Item Name"],"categoryName":data["Category Name"],"subCategoryName":data["SubCategory Name"],
          "brandName":data["Brand Name"],"unitName":data["Unit Name"],"gstName":data["GST Name"],"hsnCode":data["HSN Code"]});
        }
        //save excel data inot server
        this.itemsDetailService.importExcelItemINIT(resCategory).subscribe(res => {
        // console.log(res);
          this.toastr.success("Item detail save successfully");
          this.selectedExcelItem = '';
          this.txtExcelItem = '';
          // this.txtfilename = '';
          this.getAllItem();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  allItem;
  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
    })
  }

  allWizardDataCheck;
  getAllWizardDataCheck() {
    this.allWizardDataCheck = false;
    this.categoryservice.getAllWizardDataCheck().subscribe(res => {
      this.allWizardDataCheck = res;
      if(this.allWizardDataCheck == "true"){
        document.getElementById("confirmblock").style.display = 'block';
        document.getElementById("itemblock").style.display = 'none';
        setTimeout(() => {
          this.Router.navigate(['dashboard']);
      }, 5000);
      } else {
        this.toastr.error("Please fill all the detail of wizard form");
      }
    })
  }

  next(){
    // this.step++;
    if(this.step==0){
    return  this.step = 1;
  //         this.Company = true;
  //         if (this.allCompany.length != 0) 
  //         { 
  //           this.step++
  //           return  
  //         }
  //         console.log('Company',this.step);
    }
    if(this.step==1){
    return  this.step = 7;
  //       this.Category = true;
  //       if (this.allCategory.length != 0) 
  //       { 
  //         this.step++;
  //         return 
  //       }
  //       console.log('Category',this.step);
    }
  //   if(this.step==2){
  //       this.subCategory = true;
  //       if (this.allSubCategory.length != 0) 
  //       { 
  //         this.step++;
  //         return 
  //       }
  //       console.log('subCategory',this.step);
  //   }
  //   if(this.step==3){
  //       this.Brand = true;
  //       if (this.allBrand.length != 0) 
  //       { 
  //         this.step++;
  //         return 
  //       }
  //       console.log('Brand',this.step);
  //   }
  //   if(this.step==4){
  //       this.Unit = true;
  //       if (this.allUnit.length != 0) 
  //       { 
  //         this.step++;
  //         return 
  //       }
  //       console.log('Unit',this.step);
  //   }
  //   if(this.step==5){
  //       this.Location = true;
  //       if (this.allSubCategory.length != 0) 
  //       { 
  //         this.step++;
  //         return 
  //       }
  //       console.log('Location',this.step);
  //   }
  //   if(this.step==6){
  //       this.GST = true;
  //       if (this.allGst.length != 0) 
  //       { 
  //         this.step++;
  //         return 
  //       }
  //       console.log('GST',this.step);
  //   }
  //   if(this.step==7){
  //       this.Item = true;
  //       if (this.allSubCategory.length != 0) 
  //       { 
  //         // this.step++;
  //         this.getAllWizardDataCheck();
  //         return 
  //       }
  //       console.log('Item',this.step);
  //   }
  }
  getLoginUserData() {
    this.LoginService.getLoginUserData().subscribe(res => {
      this.step = res.wizardindex;
    })
  }
}
