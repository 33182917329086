<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Bank Master</h2> -->

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Bank Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="first" class="form-label">Bank<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtBankId"
                                                name="txtBankId">
                                            <input type="text" class="form-control" id="Brand" placeholder="Enter Bank"
                                                name="txtBank" [(ngModel)]="txtBank" required="required" (focusout)="toUpperCaseBank()" />
                                            <div class="invalid-feedback">
                                                Please enter Brand name.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="country" class="form-label">Company<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtCompany"
                                                [(ngModel)]="txtCompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company">
                                                    {{company.companyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid country.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Opening Balance</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <input type="text" class="form-control" id="Pincode"
                                                placeholder="Enter Opening Balance" name="txtopeningBalance"
                                                [(ngModel)]="txtopeningBalance" required="required" (keypress)="numberandDotOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter Opening Balance.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-1 col-6">
                                            <label for="first" class="form-check-label">UPI</label>
                                            <br>
                                            <div class="form-check form-check">
                                                <label><input class="form-check-input" type="radio" name="optRadio" value=True id="cashnote"
                                                    (change)="onItemChange($event.target.value)" checked>&nbsp;Yes</label>
                                            </div>
                                            <div class="form-check form-check">
                                                <label><input class="form-check-input" type="radio" name="optRadio" value=False
                                                    (change)="onItemChange($event.target.value)">&nbsp;No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createBrand()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Bank</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                      <thead class="tblheadercolor">
                                            <tr>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">Bank</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Opening Balance</th>
                                                <th scope="col">UPI</th>
                                                <!-- <th scope="col">Actions</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let bank of allBank | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <!-- <th scope="row">1</th> -->
                                                <td>{{bank.bankName}}</td>
                                                <td>{{bank.companyName}}</td>
                                                <td class="text-end">{{bank.openingBalance | appendIND}}</td>
                                                <!-- <td>{{bank.upi}}</td> -->
                                                <td>
                                                    <mat-slide-toggle [(ngModel)]="bank.upi" (change)="upiOnOffButton(bank)" [color]="color"></mat-slide-toggle>
                                                </td>
                                                <!-- <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateBrand(brand)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteBrand(brand)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td> -->
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allBank < 1">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                responsive="true"></pagination-controls>
                                            </li>
                                            <!-- <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">1</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">3</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Are You Sure Want To Delete This Record ?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>