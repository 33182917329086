import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DetailAnalysisService {

  constructor(
    private http: Http,
  ) { }

  getPurchaseAnalysis(formdate: string, todate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchase/analysis/location/' + location + '/date/' + formdate + '/' + todate, { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseReturnAnalysis(formdate: string, todate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasereturn/analysis/location/' + location + '/date/' + formdate + '/' + todate, { headers })
      .pipe(map(res => res.json()));
  }

  getSaleAnalysis(formdate: string, todate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sale/analysis/location/' + location + '/date/' + formdate + '/' + todate, { headers })
      .pipe(map(res => res.json()));
  }

  getSaleReturnAnalysis(formdate: string, todate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturn/analysis/location/' + location + '/date/' + formdate + '/' + todate, { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseAnalysisDetail(location: string, purchaseid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchase/analysisdetail/location/' + location + '/purchaseid/' + purchaseid, { headers })
      .pipe(map(res => res.json()));
  }
  getSaleAnalysisDetail(location: string, saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sale/analysisdetail/location/' + location + '/saleid/' + saleid, { headers })
      .pipe(map(res => res.json()));
  }
  getSaleReturnAnalysisDetail(location: string, returnid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturn/analysisdetail/location/' + location + '/salereturnid/' + returnid, { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseReturnAnalysisDetail(location: string, returnid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasereturn/analysisdetail/location/' + location + '/purchasereturnid/' + returnid, { headers })
      .pipe(map(res => res.json()));
  }
}
