import { Component, OnInit } from '@angular/core';
import { UserService } from "../service/user/user.service";
import { LocationService } from "../service/location/location.service";
import { SearchLocation } from '../service/location/searchLocation';
import { ToastrService } from "ngx-toastr";
import { User } from '../service/user/user';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RoleService } from "../service/role/role.service";
declare var $: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  txtUserId;
  txtfirstName;
  txtlastName;
  txtEmail;
  txtpassword;
  txtLocation;
  txtState;
  txtStreet;
  txtCity;
  txtArea;
  txtPincode;
  txtuserName;
  txtContactNo;
  txtRole;
  roleList = [];
  allUser;
  allRole;
  createUserres;
  btnName = 'Add';
  updateUserres;
  search;
  page: number = 1;

  public searchLocation = '';
  searchLocationName = [];
  isDisablePassword: boolean = true;
  selectedRoles = [];
  dropdownSettings = {};
  dropdownList = [];

  constructor(
    private userservice: UserService,
    private LocationService: LocationService,
    private toastr: ToastrService,
    private roleservice: RoleService,
  ) { }

  ngOnInit(): void {
    this.getAllLocation();
    this.getAllUser();
    this.getAllRole();
    this.loadRoleList();
  }

  toUpperCaseFirst(){
    const temp = this.txtfirstName;
    this.txtfirstName = temp.toUpperCase();
    return this.txtfirstName;
  }

  toUpperCaseLast(){
    const temp = this.txtlastName;
    this.txtlastName = temp.toUpperCase();
    return this.txtlastName;
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  templocation;
  selectedLocation(location) {
    this.templocation = location;
    this.searchLocation = location.locationName;
    this.txtLocation = location.locationName;
  }

  passwordDisable() {
    if (this.txtUserId == null || this.txtUserId == "") {
      this.isDisablePassword = true;
    } else {
      this.isDisablePassword = false;
    }
  }

  allLocation;
  getAllLocation() {
    this.LocationService.getAllLocation().subscribe(res => {
      this.allLocation = res;
      for (let loc of this.allLocation) {
        var temp = new SearchLocation();
        temp.locationName = loc.locationName;
        temp.id = loc.id;
        this.searchLocationName.push(temp)
      }
      console.log(this.allLocation);
    })
  }

  clearValue() {
    this.btnName = "Add";
    this.txtUserId = null;
    this.txtfirstName = '';
    this.txtlastName = '';
    this.txtEmail = '';
    this.txtpassword = '';
    this.txtLocation = '';
    this.txtState = '';
    this.txtStreet = '';
    this.txtCity = '';
    this.txtArea = '';
    this.txtPincode = '';
    this.txtuserName = '';
    this.txtContactNo = '';
    this.txtRole = '';
    this.roleList = [];
    this.passwordDisable();
    this.selectedRoles = null;
    this.templocation = '';
  }

  createUser() {
    this.roleList = [];
    for (let role of this.selectedRoles) {
      this.roleList.push(role.roleid);
      console.log(this.roleList);
    }
    if (this.txtfirstName == undefined || this.txtfirstName == null || this.txtfirstName == '') {
      return this.toastr.error('First name must be required');
    } else if (this.txtlastName == undefined || this.txtlastName == null || this.txtlastName == '') {
      return this.toastr.error('Last name must be required');
    } else if (this.txtContactNo == undefined || this.txtContactNo == null || this.txtContactNo == '') {
      return this.toastr.error('Contact Number must be required');
    } else if (this.txtContactNo.length > 10 || this.txtContactNo.length < 10) {
      return this.toastr.error("Contact Number must be 10 digit");
      // } else if (this.txtEmail == undefined || this.txtEmail == null || this.txtEmail == '') {
      //   return this.toastr.error('email must be required');
    } else if (this.txtuserName == undefined || this.txtuserName == null || this.txtuserName == '') {
      return this.toastr.error('User name must be required');
    } else if (this.txtLocation == undefined || this.txtLocation == null || this.txtLocation == '') {
      return this.toastr.error('Location must be required');
      // } else if (this.txtState == undefined || this.txtState == null || this.txtState == '') {
      //   return this.toastr.error('state must be required');
      // } else if (this.txtStreet == undefined || this.txtStreet == null || this.txtStreet == '') {
      //   return this.toastr.error('street must be required');
      // } else if (this.txtCity == undefined || this.txtCity == null || this.txtCity == '') {
      //   return this.toastr.error('city must be required');
      // } else if (this.txtArea == undefined || this.txtArea == null || this.txtArea == '') {
      //   return this.toastr.error('area must be required');
    } else if (this.txtPincode == undefined || this.txtPincode == null || this.txtPincode == '') {
      return this.toastr.error('Pincode must be required');
    } else if (this.txtPincode.length > 6 || this.txtPincode.length < 6) {
      return this.toastr.error("Pincode must be 6 digit");
    } else if (this.selectedRoles == undefined || this.selectedRoles == null || this.selectedRoles.length == 0) {
      return this.toastr.error('Role must be required');
    } else {
      if (this.txtUserId == null) {
        if (this.txtpassword == undefined || this.txtpassword == null || this.txtpassword == '') {
          return this.toastr.error('Password must be required');
        } else {
          this.txtLocation = this.templocation;
          this.userservice.createUser(this.txtfirstName, this.txtlastName, this.txtEmail, this.txtpassword, this.txtLocation.locationName, this.txtState, this.txtStreet, this.txtCity, this.txtArea, this.txtPincode, this.txtuserName, this.txtContactNo, this.roleList)
            .subscribe(res => {
              this.createUserres = res;
              console.log(this.createUserres);
              this.clearValue();
              this.getAllUser();
              this.toastr.success('User save successfully');
            }, error => {
              // this.toastr.error('user not saved');
              let message = JSON.parse(error._body).message;
              this.toastr.error(JSON.stringify(message));
            })
        }
      } else {
        if (this.templocation == undefined || this.templocation == null || this.templocation == '') {
          this.txtLocation = this.txtLocation;
        } else {
          this.txtLocation = this.templocation.locationName;
        }
        this.userservice.updateUser(this.txtUserId, this.txtfirstName, this.txtlastName, this.txtEmail, this.txtLocation, this.txtState, this.txtStreet, this.txtCity, this.txtArea, this.txtPincode, this.txtuserName, this.txtContactNo, this.roleList)
          .subscribe(res => {
            this.updateUserres = res;
            console.log(this.updateUserres);
            this.clearValue();
            this.getAllUser();
            this.isDisablePassword = true;
            this.btnName = "Add"
            this.toastr.success('User updated successfully');
          }, error => {
            // this.toastr.error('user not update');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      }
    }
  }

  getAllUser() {
    this.userservice.getAllUser().subscribe(res => {
      this.allUser = res;
    })
  }

  // allUserDetail;
  // getAllUserDetail(user) {
  //   this.userservice.getAllUser().subscribe(res => {
  //     this.allUserDetail = res;

  //     // this.allUserDetail= user.userid;
  //     // this.txtUserId=this.allUserDetail.userid;

  //     // user.userid=this.txtUserId;
  //     // this.allUserDetail=this.txtUserId;
  //     console.log(this.allUserDetail);
  //   })
  // }

  getAllRole() {
    this.roleservice.getAllRole().subscribe(res => {
      this.allRole = res;
      console.log(this.allRole);
    })
  }

  loadRoleList() {
    this.roleservice.getAllRole().subscribe((data: any[]) => {
      this.dropdownList = data;
      // console.log("Role List", this.dropdownList);
      this.dropdownSettings = {
        singleSelection: true,
        idField: 'roleid',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      // console.log(this.dropdownList)
    }), (error => {
      this.toastr.error("Something went wrong");
      // console.log(error);
    })
  }

  updateUser(user) {
    console.log(user);
    this.btnName = "Update";
    this.txtUserId = user.userid;
    this.txtfirstName = user.firstname;
    this.txtlastName = user.lastname;
    this.txtEmail = user.email;
    this.txtpassword = user;
    this.txtLocation = user.location;
    this.txtState = user.state;
    this.txtStreet = user.street;
    this.txtCity = user.city;
    this.txtArea = user.area;
    this.txtPincode = user.pincode;
    this.txtuserName = user.username;
    this.txtContactNo = user.contactno;
    this.txtRole = user.roles;
    this.selectedRoles = user.roles;
    this.passwordDisable();
  }

  deleteid;
  deleteUser(user) {
    this.deleteid = user.userid;
    this.txtRole = user.roles[0].name;
  }

  resDelUser;
  confirmDelete() {
    if (this.deleteid != null) {
      this.userservice.deleteUser(this.deleteid, this.txtRole).subscribe(res => {
        this.resDelUser = res;
        this.clearValue();
        this.getAllUser();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('User deleted successfully');
      }, error => {
        this.toastr.error('User not deleted');
      })
    }
  }

}
