<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Payment Voucher Details</h2> -->
                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Credit Note</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Voucher Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtVoucherDate "
                                                name="txtVoucherDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Voucher Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-4 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtcompany"
                                                [(ngModel)]="txtcompany" (focusout)="checkBank()" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company">
                                                    {{company.partyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Type<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="type" name="txttype"
                                                [(ngModel)]="txttype" required="">
                                                <option>-:Select Type:-</option>
                                                <option value="Sale">Sale</option>
                                                <option value="Purchase">Purchase</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter type.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-check-label"></label>
                                            <br>
                                            <div class="form-check form-check-inline">
                                                <label><input class="form-check-input" type="radio" name="optradio" value="Cash" id="cashnote"
                                                        (change)="onItemChange($event.target.value)" checked>&nbsp;Cash</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label><input class="form-check-input" type="radio" name="optradio" value="Bank"
                                                        (change)="onItemChange($event.target.value)">&nbsp;Bank</label>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-3">
                                            <label for="first" class="form-label"></label>
                                            <div class="radio-inline">
                                                <label><input type="radio" name="optradio" value="Cash" id="cashnote"
                                                        (change)="onItemChange($event.target.value)" checked>&nbsp;Cash</label>
                                            </div>
                                            <div class="radio-inline">
                                                <label><input type="radio" name="optradio" value="Bank"
                                                        (change)="onItemChange($event.target.value)">&nbsp;Bank</label>
                                            </div>
                                        </div> -->
                                        <!-- <div *ngIf="isDisabled == false" class="col-3">
                                            <label for="first" class="form-label">Bank<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtpaymenteid"
                                                name="txtpaymenteid">
                                            <select class="form-select" id="country" name="txtselected"
                                                [(ngModel)]="txtselected" required="">
                                                <option *ngFor="let bank of allBank" [ngValue]="bank">
                                                    {{bank.bankName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter Cash.
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row d-flex align-items-baseline g-3 mt-2">
                                        <div class="col-lg-3 col-xl-4 col-8 position-relative">
                                            <label for="last" class="form-label">Party Name<span
                                                    style="color: red;">&nbsp;*</span>&nbsp;&nbsp;&nbsp;<b>Ledger : {{partyLedger}}</b></label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Enter party Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}-{{item.result.cellNumber}}{{item.result.address !='' && item.result.address !=null ? '-'+item.result.address:''}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-4">
                                            <label for="country" class="form-label">Amount<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last" placeholder="Amount"
                                                required="required" [(ngModel)]="txtcredit" name="txtcredit"
                                                (keypress)="numberandDotOnly($event)" (focusout)="calculateTotalAmount()"/>
                                            <div class="invalid-feedback">
                                                Please enter a debit.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-1 col-6">
                                            <label for="first" class="form-label">GST<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="gst" name="txtgst"
                                                [(ngModel)]="txtgst" required="" (focusout)="calculateTotalAmount()">
                                                <option>-:Select GST:-</option>
                                                <option value="0">0</option>
                                                <option value="5">5</option>
                                                <option value="12">12</option>
                                                <option value="18">18</option>
                                                <option value="28">28</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter GST.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Total Amount<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last" placeholder=""
                                                required="required" [(ngModel)]="txttotalAmount" name="txttotalAmount"
                                                disabled />
                                            <div class="invalid-feedback">
                                                Please enter a TotalAmount.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-8">
                                            <label for="country" class="form-label">Narration</label>
                                            <textarea class="form-control" rows="1" name="txtNarration"
                                                [(ngModel)]="txtNarration"></textarea>
                                        </div>
                                        <!-- <div class="col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="addPaymentVoucher()">
                                                {{btnName}}
                                            </button>
                                        </div> -->
                                    </div>
                                    <hr>
                                    <div class="row d-flex align-items-baseline g-3 mt-2">
                                        <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                            <label for="last" class="form-label">Item Name<span style="color: red;" class="me-2">&nbsp;*</span>
                                            <span role="button" [routerLink]="['/itemsdetail']"><i class="fas fa-plus-circle" title="Add Item"></i></span>
                                            </label>
                                            <input type="text" [value]="searchItem" ngxTypeahead
                                                [taList]="searchItemName"
                                                [taItemTpl]="myTemp" (taSelected)="selectedItem($event)"
                                                id="ItemName" [(ngModel)]="txtItemName" name="txtItemName"
                                                placeholder="Enter Item Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myTemp let-item>
                                                <div class="item-name">
                                                    {{item.result.itemName}}
                                                </div>
                                            </ng-template>

                                            <div class="invalid-feedback">
                                                Please enter item name.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-1 col-md-3 col-6">
                                            <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Quantity" required="required" (focusout)="calculateItemQtyRate()"
                                                [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter a quantity.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-1 col-md-3 col-6">
                                            <label for="country" class="form-label">Rate<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Rate" required="required" (focusout)="calculateItemQtyRate()"
                                                [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter a rate.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-md-3 col-4">
                                            <label for="country" class="form-label">Amount<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last" placeholder="Amount"
                                                required="required" [(ngModel)]="txtAmount" name="txtAmount"
                                                (keypress)="numberandDotOnly($event)" (focusout)="calculateItemTotalAmount()" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a debit.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-1 col-md-3 col-6">
                                            <label for="first" class="form-label">GST<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="gst" name="txtItemGST"
                                                [(ngModel)]="txtItemGST" required="" (focusout)="calculateItemTotalAmount()">
                                                <option>-:Select GST:-</option>
                                                <option value="0">0</option>
                                                <option value="5">5</option>
                                                <option value="12">12</option>
                                                <option value="18">18</option>
                                                <option value="28">28</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter GST.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-md-3 col-6">
                                            <label for="country" class="form-label">Total Amount<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last" placeholder=""
                                                required="required" [(ngModel)]="txtTotalItemAmount" name="txtTotalItemAmount"
                                                disabled />
                                            <div class="invalid-feedback">
                                                Please enter a TotalAmount.
                                            </div>
                                        </div>
                                        <div class="col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="addItemDetail()">{{btnArrayName}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Payment Voucher Details</div>
                        <div class="portlet-content">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Actions</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col" class="text-center">Debit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let payment of paymentData">
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Edit"
                                                    (click)="updateCreditNote(payment)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteCreditNote(payment)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td>{{payment.partyName}}</td>
                                            <td class="text-end">{{payment.debit | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="paymentData.length == 0 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td class="text-end"><b>Total : </b></td>
                                            <td class="text-end"><b>{{totalCredit | appendIND}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <!-- <div class="portlet-header">Payment Voucher Details</div> -->
                        <div class="portlet-content">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Actions</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col" class="text-center">Quantity</th>
                                            <th scope="col" class="text-center">Rate</th>
                                            <th scope="col" class="text-center">Amount</th>
                                            <th scope="col" class="text-center">GST</th>
                                            <th scope="col" class="text-center">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cdn of CreditDebitNoteData">
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Edit"
                                                    (click)="updateCreditNoteItem(cdn)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteCreditNoteItem(cdn)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteItemModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                            <td>{{cdn.itemName}}</td>
                                            <td class="text-center">{{cdn.quantity}}</td>
                                            <td class="text-end">{{cdn.rate | appendIND}}</td>
                                            <td class="text-end">{{cdn.totalAmount | appendIND}}</td>
                                            <td class="text-end">{{cdn.cgst + cdn.sgst}}</td>
                                            <td class="text-end">{{cdn.netAmount | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="CreditDebitNoteData.length == 0 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto" *ngIf="!isClick">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearValue()">Cancel</button>
                            <button class="btn btn-primary" type="submit" (click)="addCreditNote();">Save</button>
                        </div>
                        <div class="col-auto ms-auto" *ngIf="isClick">
                            <button class="btn btn-primary" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please wait...
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteItemModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmItemDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
