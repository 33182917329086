import { Component, OnInit } from '@angular/core';
import { MenuManagementService } from '../service/menu_management/menu-management.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  allMenuList
  constructor(private menumanagementservice: MenuManagementService) { }

  ngOnInit(): void {
    // this.getAllMenuList();
    this.loadUserMenu();
  }

  loadUserMenu() {
    this.menumanagementservice.getMenuRoleWise().subscribe((data: any[]) => {
      this.allMenuList = data;
      // console.log("menu master",this.menu);
      // this.menu = this.menu[0];
      // console.log(this.menu);
    }), (error => {
      alert(error);
    })
  }
  getAllMenuList() {
    this.menumanagementservice.getAllMenuList().subscribe(res => {
      this.allMenuList = res;
      console.log(this.allMenuList);
    })
  }
}
