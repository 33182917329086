<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Brand Master</h2> -->
                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Invoice Category</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-lg-12 col-xl-12 col-12">
                                            <label for="country" class="form-label">Invoice<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" name="txtInvoice"
                                                [(ngModel)]="txtInvoice" required="">
                                                <option>-:Select Invoice:-</option>
                                                <option *ngFor="let inv of allInvoice" [ngValue]="inv.invoiceId">
                                                    {{inv.invoiceName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter a invoice.
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12"*ngIf="!isEnableMultiCat">
                                            <label for="country" class="form-label">Category<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettingsCategory" [placeholder]="'Category'"
                                                [data]="allCategory" name="txtCategorys" [(ngModel)]="txtCategorys">
                                            </ng-multiselect-dropdown>
                                            <div class="invalid-feedback">
                                                Please enter a category.
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-xl-12 col-12"*ngIf="isEnableCatUpdate">
                                            <label for="country" class="form-label">Category<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtCategory"
                                                [(ngModel)]="txtCategory" required="">
                                                <option>-:Select Category:-</option>
                                                <option *ngFor="let cat of allCategory"
                                                    [value]="cat.categoryId">{{cat.categoryName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid category.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createInvoiceCategory()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <!-- <div class="portlet-header">Invoice</div> -->
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                                id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Invoice</th>
                                                <th scope="col">Category</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let inv of allInvoiceCategory | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{inv.invoiceMaster.invoiceName}}</td>
                                                <td>{{inv.category.categoryName}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateInvoiceCategory(inv)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteInvoiceCategory(inv)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allInvoice < 1">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)" 
                                                responsive="true"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>