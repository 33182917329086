import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from '../service/plan/plan.service';
import { PlanLocationService } from '../service/plan_location/plan-location.service';
declare var $: any;

@Component({
  selector: 'app-plan-location',
  templateUrl: './plan-location.component.html',
  styleUrls: ['./plan-location.component.css']
})
export class PlanLocationComponent implements OnInit {
  resExtraLocation;
  resUpExtraLocation;
  resDelExtraLocation;
  txtExtraLocationId;
  txtPlanName;
  txtExtraLocation;
  txtAmount;
  allExtraLocation;
  allPlan;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private toastr: ToastrService, 
    private planLocationService: PlanLocationService,
    private planService: PlanService,
  ) { }

  ngOnInit(): void {
    this.getAllPlan();
    this.getAllExtraUser();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllPlan() {
    this.planService.gatAllPlan().subscribe(res => {
      this.allPlan = res;
    })
  }

  getAllExtraUser() {
    this.planLocationService.getAllExtraUser().subscribe(res => {
      this.allExtraLocation = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtPlanName = null;
    this.txtExtraLocationId = null;
    this.txtExtraLocation = null;
    this.txtAmount = null;
  }
  createExtraLocation() {
    if (this.txtPlanName == null || this.txtPlanName == '' || this.txtPlanName == undefined || this.txtPlanName == "-:Select A Plan:-") {
      this.toastr.error("Plan name is required");
    } else if (this.txtExtraLocation == null || this.txtExtraLocation == '' || this.txtExtraLocation == undefined || this.txtExtraLocation == 0) {
      this.toastr.error("Extra location is required");
    } else if (this.txtAmount == null || this.txtAmount == '' || this.txtAmount == undefined || this.txtAmount == 0) {
      this.toastr.error("Amount is required");
    } else {
      if (this.txtExtraLocationId == null) {
        this.planLocationService.createExtraLocation(this.txtPlanName, this.txtExtraLocation, this.txtAmount)
          .subscribe(res => {
            this.resExtraLocation = res;
            this.clearData();
            this.getAllExtraUser();
            this.toastr.success('Extra location save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.planLocationService.updateExtraLocation(this.txtExtraLocationId, this.txtPlanName, this.txtExtraLocation, this.txtAmount).subscribe(res => {
          this.resUpExtraLocation = res;
          this.clearData();
          this.getAllExtraUser();
          this.toastr.success('Extra location updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateExtraLocation(exloc) {
    this.btnName = 'Update';
    this.txtExtraLocationId = exloc.planlocationId;
    this.txtPlanName = exloc.plan.planName;
    this.txtExtraLocation = exloc.planLocationLimit;
    this.txtAmount = exloc.amount;
  }

  deleteid;
  deleteExtraLocation(exloc) {
    this.deleteid = exloc.planlocationId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.planLocationService.deleteExtraLocation(this.deleteid)
        .subscribe(res => {
          this.resDelExtraLocation = res;
          this.clearData();
          this.getAllExtraUser();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Extra location deleted successfully');
        }, error => {
          // this.toastr.error('Extra location not deleted');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
    }
  }

}
