import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BrandAppService } from '../service/brand-app/brand-app.service';
import { CategoryAppService } from '../service/category-app/category-app.service';
import { ProductAppService } from '../service/product-app/product-app.service';
declare var $: any;

@Component({
  selector: 'app-product-app',
  templateUrl: './product-app.component.html',
  styleUrls: ['./product-app.component.css']
})
export class ProductAppComponent implements OnInit {
  txtCategoryApp;
  allCategoryApp;
  txtProductAppId;
  txtProductApp;
  txtProductGuj;
  txtProductDes;
  txtProductDesGuj;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  resProductApp;
  resProductAppUp;
  resProductDel;
  btnName = 'Save';
  search;
  page: number = 1;
  allProductApp;
  txtProductRate;
  txtBrandApp;
  allBrandApp;

  constructor(
    private productAppService: ProductAppService,
    private categoryAppService: CategoryAppService,
    private brandAppService: BrandAppService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllCategoryApp();
    this.getAllProductApp();
    this.getAllBrandApp();
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  getAllCategoryApp() {
    this.categoryAppService.gatAllCategoryApp().subscribe(res => {
      this.allCategoryApp = res;
    })
  }

  getAllProductApp() {
    this.productAppService.getAllProductApp().subscribe(res => {
      this.allProductApp = res;
    })
  }

  getAllBrandApp() {
    this.brandAppService.getAllBrandApp().subscribe(res => {
      this.allBrandApp = res;
    })
  }
  toUpperCaseProductApp() {
    const temp = this.txtProductApp;
    this.txtProductApp = temp.toUpperCase();
    return this.txtProductApp;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCategoryApp = '';
    this.txtBrandApp = '';
    this.txtProductApp = '';
    this.txtProductGuj = '';
    this.txtProductDes = '';
    this.txtProductDesGuj = '';
    this.txtProductAppId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
    this.txtProductRate = "";
  }

  createProductApp() {
    var cat = this.txtCategoryApp;
    var brd = this.txtBrandApp;
    var pro = this.txtProductApp;
    var proguj = this.txtProductGuj;
    var prorat = this.txtProductRate;
    var prodes = this.txtProductDes;
    var prddesguj = this.txtProductDesGuj;
    if (this.txtCategoryApp == null || this.txtCategoryApp == '' || this.txtCategoryApp == undefined || this.txtCategoryApp == "-:Select Category App:-" || (this.txtCategoryApp = cat.trim()) == "" ) {
      this.toastr.error("Application category is required");
    } else if (this.txtBrandApp == null || this.txtBrandApp == '' || this.txtBrandApp == undefined || this.txtBrandApp == "-:Select Brand App:-" || (this.txtBrandApp = brd.trim()) == "" ) {
      this.toastr.error("Application brand is required");
    } else if (this.txtProductApp == null || this.txtProductApp == '' || this.txtProductApp == undefined || (this.txtProductApp = pro.trim()) == "" ) {
      this.toastr.error("Product name is required");
    } else if (this.txtProductGuj == null || this.txtProductGuj == '' || this.txtProductGuj == undefined || (this.txtProductGuj = proguj.trim()) == "" ) {
      this.toastr.error("Gujarati product name is required");
    // } else if (this.txtProductRate == null || this.txtProductRate == '' || this.txtProductRate == undefined || this.txtProductRate == 0) {
    //   this.toastr.error("Product rate is required");
    // } else if (this.txtProductDes == null || this.txtProductDes == '' || this.txtProductDes == undefined || (this.txtProductDes = prodes.trim()) == "" ) {
    //   this.toastr.error("Product description is required");
    // } else if (this.txtProductDesGuj == null || this.txtProductDesGuj == '' || this.txtProductDesGuj == undefined || (this.txtProductDesGuj = prddesguj.trim()) == "" ) {
    //   this.toastr.error("Gujarati product description is required");
    // } else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("Product image is required");
    } else {
      if (this.txtProductAppId == null) {
        this.productAppService.createProductApp(this.txtCategoryApp, this.txtBrandApp, this.txtProductApp, this.txtProductGuj, this.txtProductRate, this.txtProductDes, this.txtProductDesGuj)
          .subscribe(res => {
            this.resProductApp = res;
            console.log(this.resProductApp);
            this.uploadImage();
            this.clearData();
            this.getAllProductApp();
            this.toastr.success('Application product save successfully');
          }, error => {
            // this.toastr.error('Application Product not saved');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.productAppService.updateProductApp(this.txtProductAppId, this.txtCategoryApp, this.txtBrandApp, this.txtProductApp, this.txtProductGuj, this.txtProductRate, this.txtProductDes, this.txtProductDesGuj, this.txtImage).subscribe(res => {
          this.resProductAppUp = res;
          this.uploadImage();
          this.clearData();
          this.getAllProductApp();
          this.toastr.success('Application product updated successfully');
        }, error => {
          // this.toastr.error('Application Product not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateProductApp(pdt) {
    this.btnName = 'Update';
    this.txtProductAppId = pdt.productid;
    this.txtCategoryApp = pdt.category.categoryName;
    this.txtBrandApp = pdt.brandApp.name;
    this.txtProductApp = pdt.productName;
    this.txtProductGuj = pdt.productNameGuj;
    // this.txtProductRate = pdt.price;
    // this.txtProductDes = pdt.productDescription;
    // this.txtProductDesGuj = pdt.productDescriptionGuj;
    this.txtImage = pdt.image;
    this.imagePreview = pdt.image;
  }

  deleteAppId;
  deleteDataProduct(pdt) {
    this.deleteAppId = pdt.productid;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.productAppService.deleteProductApp(this.deleteAppId)
        .subscribe(res => {
          this.resProductDel = res;
          console.log(this.resProductDel);
          this.clearData();
          this.getAllProductApp();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Application product deleted successfully');
        }, error => {
          this.toastr.error('Application product not deleted');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      // this.imagePreview = "";
      // this.getAllProductApp();
      // this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('cat', this.txtProductApp);
      this.productAppService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllProductApp();
        // this.clearData();
        this.imagePreview = "";
      }, error => {
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllProductApp();
      })
    }
  }

}
