import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { PartyService } from '../service/party/party.service';
import { SearchParty } from '../service/party/searchparty';
import { paymentVoucherDTO } from '../service/payment_voucher/paymentVoucherDTO';
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';

@Component({
  selector: 'app-opening-balance-assets',
  templateUrl: './opening-balance-assets.component.html',
  styleUrls: ['./opening-balance-assets.component.css']
})
export class OpeningBalanceAssetsComponent implements OnInit {
  txtAssetsId;
  txtVoucherDate;
  txtcompany;
  allCompany;
  txtpartyName;
  public searchParty = '';
  searchPartyName = [];
  txtAssLedger;
  allAssLibLedger;
  txtcredit;
  txtNarration;

  paymentData: Array<paymentVoucherDTO> = [];
  paymentIdLast: number = 0;
  datePipe = new DatePipe("en-US");
  txtChequeNo;

  constructor(
    private toastr: ToastrService,
    private partyService: PartyService,
    private assetsVoucherService: AssetsVoucherService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.getAllParty();
    this.getAllCompany();
    this.getAllAssetsLedger();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party", this.allCompany);
    })
  }

  getAllAssetsLedger() {
    this.assetsVoucherService.getAllAssLibLedger("10").subscribe(res => {
      this.allAssLibLedger = res;
      // console.log("balance",this.allAssLibLedger);
    })
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
  }
  allparty;
  getAllParty() {
    this.partyService.getAllPartyStatus("COMPANY").subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        temp.address = item.address;
        this.searchPartyName.push(temp)
      }
    })
  }

  clearValue() {
    this.txtpartyName = "";
    this.txtcredit = "";
    this.searchParty = "";
    this.tempParty = "";
    this.txtNarration="";
    this.txtAssetsId = null;
    this.txtrefId=null;
    this.txtparticulars=null;
    this.txtcompany="";
    this.paymentData=null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txtChequeNo = null;
    this.txtAssLedger = '';
  }

  addAssetsVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      return this.toastr.error("Company name must be required")
    } else if (this.tempParty == undefined || this.tempParty == null || this.tempParty == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.txtAssLedger == undefined || this.txtAssLedger == null || this.txtAssLedger == "" || this.txtAssLedger == "-:Select Ledger Name:-") {
      return this.toastr.error('Ledger must be required');
    } else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
      return this.toastr.error('Opening Balance must be required');
    } else {
      if (this.txtAssetsId == null) {
        var paymentDetail = new paymentVoucherDTO();
        paymentDetail.id1 = this.paymentIdLast + 1;
        this.paymentIdLast = paymentDetail.id1;
        // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
        paymentDetail.partyId = this.tempParty.partyId;
        paymentDetail.partyName = this.tempParty.partyName;
        paymentDetail.debit = this.txtcredit;
        paymentDetail.amount = this.txtcredit;
        paymentDetail.ledgerId = this.txtAssLedger;
        this.paymentData.push(paymentDetail);
        // console.log(this.paymentData);
        // this.clearValue();
      }
    }
    this.createAssetsVoucher();
    // console.log(this.paymentData);
  }

  txtparticulars;
  txtrefId;
  paymentVoucherres;
  createAssetsVoucher() {
    for (let paymentDetail of this.paymentData) {
      paymentDetail.refId = this.txtcompany.partyId;
      paymentDetail.particulars = this.txtcompany.partyName;
      // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
      paymentDetail.amount = paymentDetail.debit;
      paymentDetail.ledgerId = this.txtAssLedger;
      if (this.txtrefId == null || this.txtrefId == "") {
        this.txtrefId = paymentDetail.partyId;
        this.txtparticulars = paymentDetail.partyName;
      } else {
        this.txtrefId = this.txtrefId + ',' + paymentDetail.partyId;
        this.txtparticulars = this.txtparticulars + ',' + paymentDetail.partyName;
      }
    }

    let paymentDetails = new paymentVoucherDTO();
    paymentDetails.partyId = this.txtcompany.partyId;
    paymentDetails.partyName = this.txtcompany.partyName;
    paymentDetails.refId = this.txtrefId;
    paymentDetails.particulars = this.txtparticulars;
    paymentDetails.voucherDate = this.txtVoucherDate;
    paymentDetails.ledgerId = this.txtAssLedger;
    this.paymentData.push(paymentDetails);
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.assetsVoucherService.createAssetsVoucher(this.txtVoucherDate, this.paymentData, this.txtNarration, this.txtcompany.partyId,this.txtChequeNo)
      .subscribe(res => {
        this.paymentVoucherres = res;
        // console.log(this.paymentVoucherres);
        this.clearValue();
        this.paymentData = [];
        this.toastr.success('Assets voucher save successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
  }

}
