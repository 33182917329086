import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CompanyService } from '../service/company/company.service';
import { StockService } from "../service/stock/stock.service";
import { ToastrService } from "ngx-toastr";
import { ExcelService } from '../service/excel/excel.service';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.css']
})
export class PurchaseReportComponent implements OnInit {

  // txtFormDate = '2021-04-01';
  txtFormDate;
  txtToDate;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  search;
  page = 1;
  allCompany;
  reSearchPurchaseData;
  txtcompany;
  totalPurchaseAmount:number;
  totalExemptedNetAmount:number;
  
  totalFiveNetAmount:number;
  fiveCgstAmount:number;
  fiveSgstAmount:number;
  fiveIgstAmount:number;

  totalTwelveNetAmount:number;
  twelveCgstAmount:number;
  twelveSgstAmount:number;
  twelveIgstAmount:number;

  totalEightgNetAmount:number;
  eightCgstAmount:number;
  eightSgstAmount:number;
  eightIgstAmount:number;

  totalTwentyFourNetAmount:number;
  tewntyeightCgstAmount:number;
  tewntyeightSgstAmount:number;
  tewntyeightIgstAmount:number;

  gstAmount:number;
  netAmount:number;
  totalDiscount:number;

  constructor(private companyService: CompanyService,private toastr: ToastrService,
    private stockservice: StockService,
    private excelservice: ExcelService,
    ) { }

  ngOnInit(): void {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtFormDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtToDate = new Date();
    this.getAllCompany();
  }

  txtCompanyId;
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      this.txtCompanyId = res.companyId;
    })
  }

  searchPurchaseReport() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
      // } else if (this.txtLedger == "" || this.txtLedger == null || this.txtLedger == undefined) {
      //   this.toastr.error("ledger is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name is required");
    } else {
      // this.txtLedger=this.allParty.partyId;
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      
      this.stockservice.getPurchaseReport( this.txtFormDate, this.txtToDate,this.txtcompany).subscribe(res => {
        this.reSearchPurchaseData = res;
        this.totalPurchaseAmount =0;
        this.totalExemptedNetAmount = 0;

        this.totalFiveNetAmount = 0;
        this.fiveCgstAmount=0;
        this.fiveSgstAmount=0;
        this.fiveIgstAmount=0;

        this.totalTwelveNetAmount = 0;
        this.twelveCgstAmount=0;
        this.twelveSgstAmount=0;
        this.twelveIgstAmount=0;

        this.totalEightgNetAmount = 0;
        this.eightCgstAmount=0;
        this.eightSgstAmount=0;
        this.eightIgstAmount=0;

        this.totalTwentyFourNetAmount = 0;
        this.tewntyeightCgstAmount=0;
        this.tewntyeightSgstAmount=0;
        this.tewntyeightIgstAmount=0;

        this.gstAmount=0;
        this.totalDiscount=0;
        this.netAmount=0;
        for(let purchase of this.reSearchPurchaseData){
          this.totalPurchaseAmount = this.totalPurchaseAmount + purchase.purchaseamount;
          this.totalExemptedNetAmount = this.totalExemptedNetAmount + purchase.exemptedNetAmount;

          this.totalFiveNetAmount = this.totalFiveNetAmount + purchase.fiveNetAmount;
          this.fiveCgstAmount = this.fiveCgstAmount + purchase.fiveCgst;
          this.fiveSgstAmount = this.fiveSgstAmount + purchase.fiveSgst;
          this.fiveIgstAmount = this.fiveIgstAmount + purchase.fiveIgst;

          this.totalTwelveNetAmount = this.totalTwelveNetAmount + purchase.twelveNetAmount;
          this.twelveCgstAmount = this.twelveCgstAmount + purchase.twelveCgst;
          this.twelveSgstAmount = this.twelveSgstAmount + purchase.twelveSgst;
          this.twelveIgstAmount = this.twelveIgstAmount + purchase.twelveIgst;

          this.totalEightgNetAmount = this.totalEightgNetAmount + purchase.eighteenNetAmount;
          this.eightCgstAmount = this.eightCgstAmount + purchase.eighteenCgst;
          this.eightSgstAmount = this.eightSgstAmount + purchase.eighteenSgst;
          this.eightIgstAmount = this.eightIgstAmount + purchase.eighteenIgst;

          this.totalTwentyFourNetAmount = this.totalTwentyFourNetAmount + purchase.tewntyeightNetAmount;
          this.tewntyeightCgstAmount = this.tewntyeightCgstAmount + purchase.tewntyeightCgst;
          this.tewntyeightSgstAmount = this.tewntyeightSgstAmount + purchase.tewntyeightSgst;
          this.tewntyeightIgstAmount = this.tewntyeightIgstAmount + purchase.tewntyeightIgst;

          this.gstAmount = this.gstAmount + purchase.totalcgst + purchase.totalsgst + purchase.totaligst;
          this.totalDiscount = this.totalDiscount + purchase.salediscount;
          this.netAmount = this.netAmount + purchase.netamount;
        }
      }, error => {
        // this.toastr.error("");
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
      });
    }
  }

  ExalData = [];
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.reSearchPurchaseData){
      var gst = exal.totalcgst + exal.totalsgst + exal.totaligst;
      var exaldate = this.datePipe.transform(exal.date, 'dd-MM-yyyy');

      var exemptedNetAmount : number = 0;
      // var exemptedper : number = 0;

      // var fiveNetAmount : number = 0;
      // var fiveCgst : number = 0;
      // var fiveSgst : number = 0;
      // var fiveIgst : number = 0;

      // var twelveNetAmount : number = 0;
      // var twelveper : number = 0;

      // var eightgNetAmount : number = 0;
      // var eighteenper : number = 0;

      // var twentyFourNetAmount : number = 0;
      // var tewntyeightper : number = 0;

      if(exal.exempted != null){
        exemptedNetAmount = exal.exemptedNetAmount;
        // exemptedper = exal.exempted;
      }
      // if(exal.five != null){
        var fiveNetAmount = null != exal.fiveNetAmount ? exal.fiveNetAmount : 0;
        var fiveCgst = null != exal.fiveCgst ? exal.fiveCgst : 0;
        var fiveSgst = null != exal.fiveSgst ? exal.fiveSgst : 0;
        var fiveIgst = null != exal.fiveIgst ? exal.fiveIgst : 0;
      // }
      // if(exal.twelve != null){
        var twelveNetAmount = null != exal.twelveNetAmount ? exal.twelveNetAmount : 0;
        var twelveCgst = null != exal.twelveCgst ? exal.twelveCgst : 0;
        var twelveSgst = null != exal.twelveSgst ? exal.twelveSgst : 0;
        var twelveIgst = null != exal.twelveIgst ? exal.twelveIgst : 0;
      // }
      // if(exal.eighteen != null){
        var eightgNetAmount = null != exal.eighteenNetAmount ? exal.eighteenNetAmount : 0;
        var eighteenCgst = null != exal.eighteenCgst ? exal.eighteenCgst : 0;
        var eighteenSgst = null != exal.eighteenSgst ? exal.eighteenSgst : 0;
        var eighteenIgst = null != exal.eighteenIgst ? exal.eighteenIgst : 0;
      // }
      // if(exal.tewntyeight != null){
        var twentyFourNetAmount = null != exal.tewntyeightNetAmount ? exal.tewntyeightNetAmount : 0;
        var tewntyeightCgst = null != exal.tewntyeightCgst ? exal.tewntyeightCgst : 0;
        var tewntyeightSgst = null != exal.tewntyeightSgst ? exal.tewntyeightSgst : 0;
        var tewntyeightIgst = null != exal.tewntyeightIgst ? exal.tewntyeightIgst : 0;
      // }

      this.ExalData.push({
        "Purchase Date": exaldate, "Name": exal.name,"GST Number":exal.gstnumber, "Purchase Number": exal.purchasenumber, "Amount": exal.purchaseamount,
        "GST 0% Amt":exemptedNetAmount,
        "GST 5% Amt":fiveNetAmount,"CGST 2.5%":fiveCgst,"SGST 2.5%":fiveSgst,"IGST 5%":fiveIgst,
        "GST 12% Amt":twelveNetAmount,"CGST 6%":twelveCgst,"SGST 6%":twelveSgst,"IGST 12%":twelveIgst,
        "GST 18% Amt":eightgNetAmount,"CGST 9%":eighteenCgst,"SGST 9%":eighteenSgst,"IGST 18%":eighteenIgst,
        "GST 28% Amt":twentyFourNetAmount,"CGST 14%":tewntyeightCgst,"SGST 14%":tewntyeightSgst,"IGST 28%":tewntyeightIgst,
        "GST Amount": gst, "Discount": exal.salediscount,"Net Amount": exal.netamount
      })
    }
    this.excelservice.exportPurchaseReportExcelFile( 'PurchaseReport',this.ExalData,this.totalPurchaseAmount,
      this.totalExemptedNetAmount,
      this.totalFiveNetAmount,this.fiveCgstAmount,this.fiveSgstAmount,this.fiveIgstAmount,
      this.totalTwelveNetAmount,this.twelveCgstAmount,this.twelveSgstAmount,this.twelveIgstAmount,
      this.totalEightgNetAmount,this.eightCgstAmount,this.eightSgstAmount,this.eightIgstAmount,
      this.totalTwentyFourNetAmount,this.tewntyeightCgstAmount,this.tewntyeightSgstAmount,this.tewntyeightIgstAmount,
      this.gstAmount,this.netAmount,this.totalDiscount);
 }

  resPurchasePdf;
  openPdfResponsePurchase;
  downloadPDF() {
    this.stockservice.downloadPurchaseReport(this.txtFormDate, this.txtToDate, this.txtcompany).subscribe((data) => {
      this.resPurchasePdf = data;

      var newBlob = new Blob([this.resPurchasePdf], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      const purchaseData = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = purchaseData;
      link.download = "purchaseReport.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponsePurchase.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(purchaseData);
        link.remove();
      }, 100);
      this.resPurchasePdf = '';
      this.openPdfResponsePurchase = '';
    })
  }

}
