import { Component, OnInit } from '@angular/core';
import { SaleReturnService } from "../service/sale-return/sale-return.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
//import { Purchase } from "../service/purchase/purchase";
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
import { saleReturn } from '../service/sale_return/sale-returm';
declare var $: any;

@Component({
  selector: 'app-sale-return',
  templateUrl: './sale-return.component.html',
  styleUrls: ['./sale-return.component.css']
})
export class SaleReturnComponent implements OnInit {
  itemId;
  txtsaleReturnDate;
  txtsaleReturnid;
  txtsaleReturnCode;
  txtpartyName;
  txtgstAmount = 0;
  txtamount = 0;
  txtDiscount = 0;
  txtnetAmount = 0;

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity;
  txtrate;
  txtExpiryDate;
  saleReturnNumber;
  getParty;
  txtsaleid;

  // txtItemName;
  allsaleReturn;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];
  txtMobileNumber;
  saleitemid;
  salepartyid;
  searchBatchNumber = [];
  public searchBatch = '';
  txtBatchNumber;

  saleReturnData: Array<saleReturn> = [];
  saleReturnIdLast: number = 0;
  txtCGST: number;
  txtSGST: number;
  txtTotalGST: number;
  exitGST=0;
  datePipe = new DatePipe("en-US");
  txtNarration;
  public loading: boolean = false;

  constructor(
    private SaleReturnService: SaleReturnService,
    private itemsdetailservice: ItemsDetailService,
    private toastr: ToastrService,
    private partyService: PartyService,
  ) { }

  ngOnInit(): void {
    this.txtsaleReturnDate = this.datePipe.transform(this.txtsaleReturnDate, "dd/MM/yyyy");
    this.txtsaleReturnDate = new Date();
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    // this.txtExpiryDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    // this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllParty();
    // this.getSaleReturnNumber();
    // this.getAllBatch();
  }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //SaleReturn Number
  getSaleReturnNumber() {
    this.SaleReturnService.getSaleReturnNumber().subscribe(res => {
      this.saleReturnNumber = res;
      this.txtsaleReturnCode = this.saleReturnNumber;
    })
  }

  // getPartyByCellNumber() {
  //   this.partyService.getPartyByCellNumber(this.txtMobileNumber).subscribe(res => {
  //     this.getParty = res;
  //     this.txtpartyName = this.getParty.partyName;
  //     // this.txtpartyId =  this.getParty.partyId;
  //     this.salepartyid = this.getParty.partyId;
  //   })
  // }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
    this.getItemById();
    this.getBatchNumberByPartyANdItem();
  }
  searchSalenumber;
  selectedStaticSaleNumber(result) {
    this.searchSalenumber = result.salenumber;
    this.getQTYNRateBySaleNoAndItem();
    this.getQTYBySaleNoAndItem();
  }
  tempParty;
  txtpartyId;
  selectedParty(party) {
    this.tempParty = party;
    this.searchParty = party.partyName;
    this.salepartyid = party.partyId;
    this.txtpartyId =  party.partyId;
  }
  allparty;
  getAllParty() {
    this.partyService.getAllParty().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        this.searchPartyName.push(temp)
      }
      // console.log(this.allparty);
    })
  }
  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.allItem);

    })
  }

  // selectedBatch(batch) {
  //   this.searchBatch = batch.batchNumber;
  // }
  // allBatch;
  // getAllBatch() {
  //   this.itemsdetailservice.getAllItem().subscribe(res => {
  //     this.allBatch = res;
  //     // for (let item of this.allBatch) {
  //     //   var temp = this.allBatch;
  //     //   temp = item.batchNumber;
  //     //   this.searchBatchNumber.push(temp)
  //     // }
  //     console.log(this.allBatch);
  //   })
  // }

  clearGridData() {
    this.txtItemName = "";
    this.txtManufactureDate = "";
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = "";
    this.txtSubCategoryName = "";
    this.txtBrandName = "";
    this.txtquantity = "";
    this.txtrate = "";
    this.txtExpiryDate = "";
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = "";
    this.btnName = "Add";
    this.txtsaleReturnid = null;
    this.txtBatchNumber = "";
    this.txtsaleid = "";
    this.qtyLabel = false;
    this.txtCGST = 0;
    this.txtSGST = 0;
  }

  clearValue() {
    this.txtsaleReturnDate = new Date();
    // this.txtsaleReturnDate = this.datePipe.transform(this.txtsaleReturnDate, "dd/MM/yyyy");
    this.txtsaleReturnCode = '';
    this.txtMobileNumber = '';
    this.txtnetAmount = 0;
    this.txtamount = 0;
    this.txtDiscount = 0;
    this.txtgstAmount = 0;
    this.txtpartyName = '';
    this.searchParty = '';
    this.saleReturnData = [];
    this.txtMobileNumber = '';
    this.tempamount = 0;
    this.exitGST=0;
    this.txtNarration = '';
    this.getSaleReturnNumber();
  }

  resItemById;
  getItemById() {
    this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
      this.resItemById = res;
      console.log(this.resItemById);
      this.txtCategoryName = this.resItemById.category.categoryName;
      this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
      this.txtBrandName = this.resItemById.brand.name;
      this.txtCGST = this.resItemById.gst.cgst;
      this.txtSGST = this.resItemById.gst.sgst;
      this.saleitemid = this.resItemById.itemid;
      this.getSaleDataByPartyAndItemId();
    });
  }

  resSaleData;
  getSaleDataByPartyAndItemId() {
    this.itemsdetailservice.getSaleDataByPartyAndItemId(this.saleitemid, this.salepartyid).subscribe(res => {
      this.resSaleData = res;
      console.log(this.resSaleData);

    })
  }

  allSale;
  getAllSaleItemNameSaleId() {
    console.log(this.txtsaleid);
    this.itemsdetailservice.getAllSaleItemNameSaleId(this.search1, this.txtsaleid).subscribe(res => {
      this.allSale = res;
      console.log(this.allSale);
    })
    this.getSaleItemPriceByItemIdAndSaleId();
  }

  allSaleBatch;
  getSaleDatesByBatchNo() {
    this.itemsdetailservice.getSaleDatesByBatchNo(this.txtBatchNumber, this.txtsaleid).subscribe(res => {
      this.allSaleBatch = res;
      this.txtManufactureDate = this.datePipe.transform(this.allSaleBatch.manufactureDate, "yyyy-MM-dd");
      this.txtExpiryDate = this.datePipe.transform(this.allSaleBatch.expiryDate, "yyyy-MM-dd");
      console.log(this.allSaleBatch);
    })
  }

  addSaleReturnDetail() {
      if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
        return this.toastr.error('Item name must be required');
      } else if (this.txtBatchNumber == undefined || this.txtBatchNumber == null || this.txtBatchNumber == 0 || this.txtBatchNumber == "-:Select Batch Number:-") {
        return this.toastr.error('Batch number must be required');
      } else if (this.txtsaleid == undefined || this.txtsaleid == null || this.txtsaleid == 0) {
        return this.toastr.error('Sale number must be required');
      } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
        return this.toastr.error('Quantity must be required');
      } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
        return this.toastr.error('Rate must be required');
        // } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
        //   return this.toastr.error(' Manufacture Date must be required');
        // } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
        //   return this.toastr.error(' Expiry Date must be required');
      } else if (Number(this.txtquantity) > Number(this.availableSaleNoQty)) {
        return this.toastr.error("Sale Return quantity is greater than sale quantity")
      } else {
        if (this.txtsaleReturnid == null) {
          // this.txtItemName = this.tempitem;
          var saleReturnDetail = new saleReturn();
          
          if (this.saleReturnData.length == 0) {
            saleReturnDetail.id1 = this.saleReturnIdLast;
          } else if (this.saleReturnData.length != 0) {
            saleReturnDetail.id1 = this.saleReturnIdLast + 1;
          }
          this.saleReturnIdLast = saleReturnDetail.id1;
          saleReturnDetail.itemName = this.search1; //this for selected itemName
          saleReturnDetail.itemId = this.itemId;
          saleReturnDetail.saleid = this.txtsaleid;
          saleReturnDetail.salenumber = this.txtsaleid;
          saleReturnDetail.batchNumber = this.txtBatchNumber;
          saleReturnDetail.categoryName = this.txtCategoryName;
          saleReturnDetail.subCategoryName = this.txtSubCategoryName;
          saleReturnDetail.brandName = this.txtBrandName;
          saleReturnDetail.quantity = this.txtquantity;
          saleReturnDetail.rate = this.txtrate;
          saleReturnDetail.cgst = this.txtCGST;
          saleReturnDetail.sgst = this.txtSGST;
          saleReturnDetail.totalAmount = this.txtrate * this.txtquantity;
          //base price calculation
          var totalpercentage = 100 + this.txtCGST + this.txtSGST
          var baseprice = 0;
          baseprice = parseFloat(Number(saleReturnDetail.totalAmount * 100 / totalpercentage).toFixed(2));
          var totalgstamt = 0;
          totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
          // saleReturnDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
          // saleReturnDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
          saleReturnDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
          saleReturnDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
          saleReturnDetail.netAmount = baseprice;
          // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
          saleReturnDetail.manufactureDate = this.txtManufactureDate;
          // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
          saleReturnDetail.expiryDate = this.txtExpiryDate;
          this.saleReturnData.push(saleReturnDetail);

          //code for calculate netamount and gstamount;
          // var temp = 0;
          // var gstsum = 0;
          // temp = this.txtquantity * this.txtrate;
          // this.tempamount = this.tempamount + temp;

          // this.tempGST = temp * (this.txtCGST + this.txtSGST) / 100;
          // gstsum = gstsum + this.tempGST;
          // this.exitGST=this.exitGST+gstsum;
          // this.txtgstAmount = this.exitGST;

          // this.txtamount = this.tempamount - this.txtgstAmount;
          // this.txtnetAmount = this.tempamount;

          this.qtyLabel = false;
          this.getAmount();
          this.calculateNetAmount()
          this.clearGridData();

        } else {
          // if (this.txtquantity == 0 ||this.txtquantity == null || this.txtquantity == undefined) {
          //   return this.toastr.error("quantity is required")
          // } else if (Number(this.txtquantity) > Number(this.saleNoQty)) {
          //   return this.toastr.error("Return quantity is greater then sale quantity")
          // } else {
            for (let sale of this.saleReturnData) {
              if (sale.id1 == this.txtsaleReturnid) {
                this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
                sale.manufactureDate = this.txtManufactureDate;
                sale.itemName = this.search1;
                sale.itemId = this.itemId;
                sale.saleid = this.txtsaleid;
                sale.salenumber = this.txtsaleid;
                sale.batchNumber = this.txtBatchNumber;
                sale.categoryName = this.txtCategoryName;
                sale.subCategoryName = this.txtSubCategoryName;
                sale.brandName = this.txtBrandName;
                sale.quantity = this.txtquantity;
                sale.rate = this.txtrate;
                sale.cgst = this.txtCGST;
                sale.sgst = this.txtSGST;
                sale.totalAmount = this.txtrate * this.txtquantity;
                //base price calculation
                var totalpercentage = 100 + this.txtCGST + this.txtSGST
                var baseprice = 0;
                baseprice = parseFloat(Number(sale.totalAmount * 100 / totalpercentage).toFixed(2));
                var totalgstamt = 0;
                totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
                // sale.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
                // sale.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
                sale.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
                sale.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
                sale.netAmount = baseprice;
                this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
                sale.expiryDate = this.txtExpiryDate;
                this.qtyLabel = false;
                this.getAmount();
                this.calculateNetAmount();
                this.clearGridData();
              }
            }
          // }
        }
      }
    console.log(this.saleReturnData);
  }

  calculateNetAmount() {
    if (this.txtDiscount == 0 || this.txtDiscount == undefined || this.txtDiscount == null) {
      this.txtDiscount = 0;
      this.txtnetAmount = this.tempNetAmount;
    } else {
      this.txtnetAmount = this.tempNetAmount - this.txtDiscount;
    }
  }

  tempamount = 0;
  tempGST = 0;
  tempNetAmount = 0;
  getAmount() {
    this.txtamount = 0;
    this.txtgstAmount = 0;
    this.tempamount = 0;
    this.tempGST = 0;
    var temp = 0;
    var gstsum = 0;
    this.txtnetAmount = 0;
    this.exitGST=0;

    for (let item of this.saleReturnData) {
      // temp = item.quantity * item.rate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = temp * (item.cgst + item.sgst) / 100;
      // // gstsum = gstsum + this.tempGST;
      // this.exitGST=this.exitGST+this.tempGST;
      // this.txtgstAmount = this.exitGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;
      this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
      this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
      this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
      this.tempNetAmount = this.txtnetAmount;
    }
  }

  saleDetailres;
  createSaleReturnDetail() {
    if (this.txtsaleReturnDate == undefined || this.txtsaleReturnDate == null || this.txtsaleReturnDate == '') {
      return this.toastr.error('SaleReturn Date must be required');
    // } else if (this.txtsaleReturnCode == undefined || this.txtsaleReturnCode == null || this.txtsaleReturnCode == '') {
      // return this.toastr.error(' saleReturn Code must be required');
    // } else if (this.txtMobileNumber == null || this.txtMobileNumber == '' || this.txtMobileNumber == undefined) {
    //   this.toastr.error("Mobile number is required");
    // } else if (this.txtMobileNumber.length > 10 || this.txtMobileNumber.length < 10) {
    //   this.toastr.error("Mobile number must be 10 digit");
    } else if (this.txtpartyName == undefined || this.txtpartyName == null || this.txtpartyName == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.saleReturnData.length == 0 || this.saleReturnData == []) {
      return this.toastr.error('SaleReturn detail must be required');
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.txtpartyName = this.tempParty;
      this.txtsaleReturnDate = this.datePipe.transform(this.txtsaleReturnDate, 'yyyy-MM-dd');
      this.SaleReturnService.addSaleReturnDetail(this.txtsaleReturnDate, this.txtsaleReturnCode, this.txtMobileNumber, this.txtpartyId, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.saleReturnData, this.txtNarration)
        .subscribe(res => {
          this.saleDetailres = res;
          console.log(this.saleDetailres);
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
          }
          this.clearValue();
          this.toastr.success('Sale Return save successfully');
        }, error => {
          // this.toastr.error('Sale Return not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
        })
    }
  }

  getAllsaleReturn() {
    this.SaleReturnService.getAllsaleReturn()
      .subscribe(res => {
        this.allsaleReturn = res;
      })
  }

  updateSaleReturn(salereturn) {
    this.btnName = "Update";
    this.txtsaleReturnid = salereturn.id1;
    this.txtItemName = salereturn.itemName;
    this.itemId = salereturn.itemId;
    this.txtsaleid = salereturn.saleid;
    this.txtBatchNumber = salereturn.batchNumber;
    this.search1 = salereturn.itemName;
    this.txtCategoryName = salereturn.categoryName;
    this.txtSubCategoryName = salereturn.subCategoryName;
    this.txtBrandName = salereturn.brandName;
    this.txtquantity = salereturn.quantity;
    this.txtrate = salereturn.rate;
    this.txtCGST = salereturn.cgst;
    this.txtSGST = salereturn.sgst;
    // this.txtManufactureDate = this.datePipe.transform(salereturn.manufactureDate, 'dd-MM-yyyy');
    // this.txtExpiryDate = this.datePipe.transform(salereturn.expiryDate, 'dd-MM-yyyy');
    this.txtManufactureDate = salereturn.manufactureDate;
    this.txtExpiryDate = salereturn.expiryDate;
    this.getQTYNRateBySaleNoAndItem();
    this.getQTYBySaleNoAndItem();
  }

  i;
  deletePurchaseReturnData;
  deleteSaleReturn(salereturn) {
    this.deletePurchaseReturnData = salereturn;
  }

  confirmDelete(){
    for (let saleData of this.saleReturnData) {
      if (saleData.id1 == this.deletePurchaseReturnData.id1) {
        this.i = this.deletePurchaseReturnData.saleId;
        let index = this.saleReturnData.indexOf(this.deletePurchaseReturnData);
        if (saleData.id1 !== -1) {
          this.saleReturnData.splice(index, 1);
          this.getAmount();
          this.calculateNetAmount();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
        }
      }
    }
  }

  getSaleItemPriceByItemIdAndSaleId() {
    // console.log(this.resItemById);
    // console.log(this.txtsaleid);
    this.SaleReturnService.getSaleItemPriceByItemIdAndSaleId(this.resItemById.itemid,this.txtsaleid).subscribe(res=>{
      console.log(res);
      this.txtrate = res.rate;
    })
  }

  resReturnBatchNos;
  resReturnSaleNos;
  resReturnQtyRate;
  getBatchNumberByPartyANdItem() {
    this.SaleReturnService.getBatchNumberByPartyANdItem(this.salepartyid,this.itemId).subscribe(res=>{
      this.resReturnBatchNos = res;
      console.log(this.resReturnBatchNos);
    })
  }
  
  getSaleNoByPartyItemAndBatchNo() {
    this.SaleReturnService.getSaleNoByPartyItemAndBatchNo(this.salepartyid,this.itemId,this.txtBatchNumber).subscribe(res=>{
      this.resReturnSaleNos = res;
      console.log(this.resReturnSaleNos);
      this.setExpiryAndManuFactureDate();
    })
  }
  
  saleNoQty
  qtyLabel = false
  getQTYNRateBySaleNoAndItem() {
    this.txtsaleid = this.searchSalenumber;
    this.SaleReturnService.getQTYNRateBySaleNoAndItem(this.txtsaleid,this.itemId).subscribe(res=>{
      this.resReturnQtyRate = res;
      console.log(this.resReturnQtyRate);
      this.qtyLabel = true;
      this.txtrate = parseFloat(Number(this.resReturnQtyRate.rate).toFixed(2));
      this.saleNoQty = this.resReturnQtyRate.quantity;
    })
  }
  setExpiryAndManuFactureDate() {
    for (let batchno  of this.resReturnBatchNos){
      if (this.txtBatchNumber == batchno.batchNumber) {
        // this.txtManufactureDate = this.datePipe.transform(batchno.manufactureDate, "yyyy-MM-dd");
        // this.txtExpiryDate = this.datePipe.transform(batchno.expiryDate, "yyyy-MM-dd");
        this.txtManufactureDate = batchno.manufactureDate;
        this.txtExpiryDate = batchno.expiryDate;
      }
    }
  }

  availableSaleNoQty;
  getQTYBySaleNoAndItem() {
    this.SaleReturnService.getQTYBySaleNoAndItem(this.searchSalenumber,this.itemId).subscribe(res=>{
      this.availableSaleNoQty = parseFloat(Number(res).toFixed(0));
    })
  }
}
