import { Component, OnInit } from '@angular/core';
import { CategoryService } from "../service/category/category.service";
import { ToastrService } from "ngx-toastr";
import { GstewaybillLoginService } from '../service/gstewaybill-login/gstewaybill-login.service';
declare var $: any;

@Component({
  selector: 'app-gstewaybill-login',
  templateUrl: './gstewaybill-login.component.html',
  styleUrls: ['./gstewaybill-login.component.css']
})
export class GstewaybillLoginComponent implements OnInit {

  resGSTEWayBillLogin;
  resUPGSTEWayBillLogin;
  resDELGSTEWayBillLogin;
  txtGSTEWayBillUserName;
  txtGSTEWayBillPassword;
  txtGSTEWayBillId;
  allGSTEWayBillLogin;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private GstewaybillLoginService: GstewaybillLoginService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllGSTEWayBillLogin();
  }

  getAllGSTEWayBillLogin() {
    this.GstewaybillLoginService.getAllGSTEWayBillLogin().subscribe(res => {
      this.allGSTEWayBillLogin = res;
    })
  }

  toUpperCaseGSTEWayBillUserName() {
    const temp = this.txtGSTEWayBillUserName;
    this.txtGSTEWayBillUserName = temp.toUpperCase();
    return this.txtGSTEWayBillUserName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtGSTEWayBillUserName = null;
    this.txtGSTEWayBillPassword = null;
    this.txtGSTEWayBillId = null;
  }
  createGSTEWayBillLogin() {
    if (this.txtGSTEWayBillUserName == null || this.txtGSTEWayBillUserName == '' || this.txtGSTEWayBillUserName == undefined) {
      this.toastr.error("GSTEWayBill UserName is required");
    } else if (this.txtGSTEWayBillPassword == undefined || this.txtGSTEWayBillPassword == null || this.txtGSTEWayBillPassword == '') {
      return this.toastr.error('GSTEWayBill Password is required');
    } else {
      if (this.txtGSTEWayBillId == null) {
        this.GstewaybillLoginService.createGSTEWayBillLogin(this.txtGSTEWayBillUserName, this.txtGSTEWayBillPassword)
          .subscribe(res => {
            this.resGSTEWayBillLogin = res;
            this.clearData();
            this.getAllGSTEWayBillLogin();
            this.toastr.success('GSTEWayBill save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.GstewaybillLoginService.updateGSTEWayBillLogin(this.txtGSTEWayBillId, this.txtGSTEWayBillUserName, this.txtGSTEWayBillPassword).subscribe(res => {
          this.resUPGSTEWayBillLogin = res;
          this.clearData();
          this.getAllGSTEWayBillLogin();
          this.toastr.success('GSTEWayBill updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateGSTEWayBill(ewaybill) {
    this.btnName = 'Update';
    this.txtGSTEWayBillId = ewaybill.gstEWayBillLoginId;
    this.txtGSTEWayBillUserName = ewaybill.gstEWayBillUserName;
    this.txtGSTEWayBillPassword = ewaybill.gstEWayBillPassword;
  }

  deleteid;
  deleteGSTEWayBill(ewaybill) {
    this.deleteid = ewaybill.gstEWayBillLoginId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.GstewaybillLoginService.deleteGSTEWayBillLogin(this.deleteid)
        .subscribe(res => {
          this.resDELGSTEWayBillLogin = res;
          this.clearData();
          this.getAllGSTEWayBillLogin();
          $('#deleteModal').modal('hide');
          this.toastr.success('GSTEWayBill deleted successfully');
        }, error => {
          this.toastr.error('GSTEWayBill not deleted');
        })
    }
  }
}
