import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { ExcelService } from "../service/excel/excel.service";
import { searchdto } from "../service/excel/searchdto";
import { PartyService } from '../service/party/party.service';
import { LoginService } from '../service/login/login.service';

@Component({
  selector: 'app-party-farmer-list',
  templateUrl: './party-farmer-list.component.html',
  styleUrls: ['./party-farmer-list.component.css']
})
export class PartyFarmerListComponent implements OnInit {

  txtType: any = 'FARMER';
  txtOrganization;
  allOrganization;
  allFarmerList: any = [];
  isEnable = false;
  datePipe = new DatePipe("en-US");
  // totalNetAmount = 0;
  page = 1;
  search;

  ExalData: any = [];
  loginUserId: any;

  constructor(
    private toastr: ToastrService,
    private partyService: PartyService,
    private excelservice: ExcelService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.loginUserId = localStorage.getItem("loginUserId");
    this.getUserRoleByUserId();
    this.getAllOrganization();
    this.getAllFarmerListByTypeAndOrgId();
  }

  userRoleName;
  userOrgName;
  getUserRoleByUserId() {
    this.loginService.getUserRoleByUserId(this.loginUserId).subscribe(res => {
      this.userRoleName = res.roleName;
      this.userOrgName = res.orgName;
      // console.log(this.userRoleName);
      // console.log(this.userOrgName);
    })
  }

  getAllOrganization() {
    this.partyService.getAllOrganization().subscribe(res => {
      this.allOrganization = res;
    })
  }

  getAllFarmerListByTypeAndOrgId() {
    // if (this.txtType == "" || this.txtType == null || this.txtType == undefined || this.txtType == "-:Select Type:-") {
    //   this.toastr.error("Type is required");
    // } else 
    if (this.userOrgName == 'IAgro Solution' && this.userRoleName == 'SUPER ADMIN' && (this.txtOrganization == "" || this.txtOrganization == null || this.txtOrganization == undefined || this.txtOrganization == "-:Select Entity:-")) {
      this.toastr.error("Entity is required");
    } else {
      // if (this.txtOrganization == "" || this.txtOrganization == null || this.txtOrganization == undefined || this.txtOrganization == "-:Select Entity:-") {
      //   this.txtOrganization = null;
      // }
      this.partyService.getAllFarmerListByTypeAndOrgId(this.txtType, this.txtOrganization).subscribe(res => {
        this.allFarmerList = res;
          this.isEnable = true;
        // this.totalNetAmount = 0;
        // for (let amount of this.reSearchData) {
        //   var sum = 0;
        //   sum = sum + Number(amount.netamount);
        //   this.totalNetAmount = this.totalNetAmount + sum;
        // }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }
  }

  exportAsXLSX():void {
    this.ExalData=[];
    for(let excel of this.allFarmerList){
      this.ExalData.push({"Entity Name": excel.orgname,"party Name": excel.partyname,"Mobile No.":excel.cellnumber, "address": excel.address, "GST Number": excel.gstnumber, "Location": excel.location
      , "State": excel.state, "State Code": excel.statecode});
    }
    this.excelservice.exportExcelPartyTypeFarmerData('Farmer Data',this.ExalData);
 }

}
