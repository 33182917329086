<nav class="side-nav navbar-expand-lg">
    <div class="d-flex align-items-center justify-content-between">
        <a [routerLink]="['/dashboard']" class="logo" style="padding: 0.25rem !important;">
            <img src="assets/images/loginLogo.png" alt="" class="img-fluid" style="height: 60px !important; margin-left: 65px !important;"/>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>
    </div>
    <div class="side-nav__devider"></div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="side-menu">
            <!-- <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a [routerLink]="['/dashboard']"><span class="menu-icon"><i class="fas fa-home"></i></span>
                    <span class="menu-text">Home</span></a>
            </li> -->
            <li class="menu-item" *ngFor="let dynamicmenu of allMenuList;let i = index" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" [attr.data-bs-target]="'#menu'+i" role="button" aria-expanded="false"
                    aria-controls="setup-menu"><span class="menu-icon"><i class="{{dynamicmenu.menuiconclass}}"></i></span>
                    <span class="menu-text">{{dynamicmenu.name}}</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" [attr.id]="'menu'+i" data-bs-parent="#navbarSupportedContent">
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" *ngFor="let subm of dynamicmenu.submenu">
                        <a [routerLink]="[subm.action]"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">{{subm.name}}</span></a>
                    </li>
                </ul>
            </li>
            <!-- <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#setup-menu" role="button" aria-expanded="false"
                    aria-controls="setup-menu"><span class="menu-icon"><i class="fas fa-cog"></i></span>
                    <span class="menu-text">App Setup</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="setup-menu" data-bs-parent="#navbarSupportedContent">
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/category']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Category</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/subCategory']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Sub Category</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/itemsdetail']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Item</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/brand']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Brand</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/unit']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Unit</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/gst']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">GST</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/location']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Location</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/party']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Party</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/company']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Company</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/balancesheet']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">BalanceSheet</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/group']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Group</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/ledger']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Ledger</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#SetUp" aria-expanded="false"
                    aria-controls="SetUp"><span class="menu-icon"><i class="fas fa-book"></i></span>
                    <span class="menu-text">Set Up</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="SetUp" data-bs-parent="#navbarSupportedContent">
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/bank']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Bank</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/role']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Role</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/user']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">User</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/menumanagement']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Menu Management</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#Purchase" aria-expanded="false"
                    aria-controls="Purchase"><span class="menu-icon"><i class="fas fa-shopping-cart"></i></span>
                    <span class="menu-text">Purchase</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="Purchase" data-bs-parent="#navbarSupportedContent">
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/purchase']"><span class="menu-icon"><i
                                    class="fas fa-shopping-cart"></i></span>
                            <span class="menu-text">Credit Purchase</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/cashpurchase']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Cash Purchase</span></a>
                    </li> 
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/purchaseRecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Purchase Record</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/purchasereturn']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Purchase Return</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/purchasereturnrecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Purchase Return Record</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#Sales" aria-expanded="false"
                    aria-controls="Sales"><span class="menu-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="menu-text">Sales</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="Sales" data-bs-parent="#navbarSupportedContent">
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/sale']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Credit Sales</span></a>
                    </li>
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/cashsale']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Cash Sales</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/saleRecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Sale Record</span></a>
                    </li>
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/salechallan']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Sale Challan</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/salechallanrecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Challan Record</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#SaleReturn" aria-expanded="false"
                    aria-controls="SaleReturn"><span class="menu-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="menu-text">Sale Return</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="SaleReturn" data-bs-parent="#navbarSupportedContent">
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/salereturn']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Create Sale Return</span></a>
                    </li>
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/cashsalereturn']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Cash Sale Return</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/saleReturnRecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Sale Return Record</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#Transfer" aria-expanded="false"
                    aria-controls="Transfer"><span class="menu-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="menu-text">Transfer</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="Transfer" data-bs-parent="#navbarSupportedContent">
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/transfer']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Create Transfer</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/transferRecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Transfer Record</span></a>
                    </li>
                </ul>
            </li>

            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#Account" aria-expanded="false"
                    aria-controls="Account"><span class="menu-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="menu-text">Account</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="Account" data-bs-parent="#navbarSupportedContent">
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/receiptvrecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Receipt Voucher</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/paymentvrecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Payment Voucher</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/ledgerdetail']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Ledger Detail</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/creditnoterecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Credit Note</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/debitnoterecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Debit Note</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/expensevrecord']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Expense Voucher</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#Stock" aria-expanded="false"
                    aria-controls="Stock"><span class="menu-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="menu-text">Stock</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="Stock" data-bs-parent="#navbarSupportedContent">
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/itemwisestock']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Item Wise Stock</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/itemlocationstock']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Location Stock Detail</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/analysis']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Search Data</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/itemwisesearchstock']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Item Wise Search Stock</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/companywisesearchstock']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Company Wise Search Stock</span></a>
                    </li>
                    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/summarywisesearchstock']"><span class="menu-icon"><i
                                    class="fas fa-chevron-circle-right"></i></span>
                            <span class="menu-text">Summary Wise Search Stock</span></a>
                    </li>
                </ul>
            </li>
            <li class="menu-item " [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                <a data-bs-toggle="collapse" class="collapsed" role="button" data-bs-target="#GSTR" aria-expanded="false"
                    aria-controls="Transfer"><span class="menu-icon"><i class="fas fa-file-invoice-dollar"></i></span>
                    <span class="menu-text">GSTR</span>
                    <span class="chevron-arrow ms-auto"></span></a>
                <ul class="sub-menu collapse" id="GSTR" data-bs-parent="#navbarSupportedContent">
                    <li class="menu-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
                        <a [routerLink]="['/gstr1purchase']"><span class="menu-icon"><i
                                    class="fas fa-file-invoice-dollar"></i></span>
                            <span class="menu-text">Gstr1</span></a>
                    </li>
                </ul>
            </li>  -->
        </ul>
    </div>
</nav>