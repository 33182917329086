import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PurchasePlanWorklistService {

  constructor(
    private http: Http,
    private httpClient: HttpClient,
  ) { }
  searchStatusWiseData(status :string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/status/plan/' + status , { headers })
      .pipe(map(res => res.json()));
  }

  movePendingToReceive(txtPurchaseId: string, txtBankName: string, txtChequeNo: string, optradio: string, txtNarration: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpurchaseId: txtPurchaseId,
      bankName: txtBankName,
      chequeNo: txtChequeNo,
      paymentMode: optradio,
      narration: txtNarration,
    });
    return this.http.put('/api/update/payment/detail', body, { headers })
      .pipe(map(res => res.json()));
  }

  confirmAccept(txtPurchaseId: string, status: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpurchaseId: txtPurchaseId,
      status: status,
    });
    return this.http.put('/api/update/planpurchase/status', body, { headers })
      .pipe(map(res => res.json()));
  }
  confirmReject(txtPurchaseId: string, status: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpurchaseId: txtPurchaseId,
      status: status,
    });
    return this.http.put('/api/update/planpurchase/status', body, { headers })
      .pipe(map(res => res.json()));
  }
  setActiveUserDetail(txtPurchaseId: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
    });
    return this.http.put('/api/active/planpurchase/' + txtPurchaseId , body,{ headers })
      .pipe(map(res => res.json()));
  }
  setDeactiveUserDetail(txtPurchaseId: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    const body = JSON.stringify({
    });
    return this.http.put('/api/deactive/planpurchase/' + txtPurchaseId , body, { headers })
      .pipe(map(res => res.json()));
  }

  downloadPlanPurchaseBill(planpurchaseId){
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/planpurchase/bill/` + planpurchaseId, { headers: header, responseType: 'blob' });
  }

  planPurchaseMailWithSendGrid(emailplanPurchaseId: string, txtEmail: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpurchaseId: emailplanPurchaseId,
      email: txtEmail
    });
    return this.http.post('/api/send/planpurchase', body, { headers })
      .pipe(map(res => res));
  }
}
