import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ItemsDetailComponent } from './items-detail/items-detail.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { LoginComponent } from './login/login.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { BrandComponent } from './brand/brand.component';
import { UnitComponent } from './unit/unit.component';
import { GSTComponent } from './gst/gst.component';
import { LocationComponent } from './location/location.component';
import { PartyComponent } from './party/party.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field'; //angular material modules
import { MatInputModule } from '@angular/material/input';
import { HttpModule } from "@angular/http";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SaleComponent } from './sale/sale.component';
import { SaleReturnComponent } from './sale-return/sale-return.component';
import { TransferComponent } from './transfer/transfer.component';
import { SaleRecordComponent } from './sale-record/sale-record.component';
import { SaleReturnRecordComponent } from './sale-return-record/sale-return-record.component';
import { PurchaseRecordComponent } from './purchase-record/purchase-record.component';
import { TransferRecordComponent } from './transfer-record/transfer-record.component';
import { RoleComponent } from './role/role.component';
import { UserComponent } from './user/user.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReceiptVoucherComponent } from './receipt-voucher/receipt-voucher.component';
import { PaymentVoucherComponent } from './payment-voucher/payment-voucher.component';
import { ReceiptVRecordComponent } from './receipt-v-record/receipt-v-record.component';
import { PaymentVRecordComponent } from './payment-v-record/payment-v-record.component';
import { ItemWiseStockComponent } from './item-wise-stock/item-wise-stock.component';
import { CashSaleComponent } from './cash-sale/cash-sale.component';
import { CashSaleReturnComponent } from './cash-sale-return/cash-sale-return.component';
import { MenuManagementComponent } from './menu-management/menu-management.component';
import { NgxPaginationModule } from "ngx-pagination";
import { SaleChallanComponent } from './sale-challan/sale-challan.component';
import { SaleChallanRecordComponent } from './sale-challan-record/sale-challan-record.component';
import { CompanyComponent } from './company/company.component';
import { BalancesheetComponent } from './balancesheet/balancesheet.component';
import { GroupComponent } from './group/group.component';
import { LedgerComponent } from './ledger/ledger.component';
import { appendINDPipe } from './pipes/IndianCurrency';
import { PurchaseReturnComponent } from './purchase-return/purchase-return.component';
import { PurchaseReturnRecordComponent } from './purchase-return-record/purchase-return-record.component';
import { BankComponent } from './bank/bank.component';
import { DetailAnalysisComponent } from './detail-analysis/detail-analysis.component';
import { ItemStockDetailsComponent } from './item-stock-details/item-stock-details.component';
import { ItemWiseSearchStockComponent } from './item-wise-search-stock/item-wise-search-stock.component';
import { CompanyWiseSearchStockComponent } from './company-wise-search-stock/company-wise-search-stock.component';
import { SummaryWiseSearchStockComponent } from './summary-wise-search-stock/summary-wise-search-stock.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CashPurchaseComponent } from './cash-purchase/cash-purchase.component';
import { LedgerDetailComponent } from './ledger-detail/ledger-detail.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { CreditNoteRecordComponent } from './credit-note-record/credit-note-record.component';
import { DebitNoteRecordComponent } from './debit-note-record/debit-note-record.component';
import { Gstr1PurchaseDetailComponent } from './gstr1-purchase-detail/gstr1-purchase-detail.component';
import { ExpenseVoucherComponent } from './expense-voucher/expense-voucher.component';
import { ExpenseVRecordComponent } from './expense-v-record/expense-v-record.component';
import { StockAdjustmentComponent } from './stock-adjustment/stock-adjustment.component';
import { BankBookComponent } from './bank-book/bank-book.component';
import { CashBookComponent } from './cash-book/cash-book.component';
import { StockvalutionComponent } from './stockvalution/stockvalution.component';
import { CashComponent } from './cash/cash.component';
import { CashToBankComponent } from './cash-to-bank/cash-to-bank.component';
import { CashToBankRecordComponent } from './cash-to-bank-record/cash-to-bank-record.component';
import { ExpiryDetailComponent } from './expiry-detail/expiry-detail.component';
import { PartyClosingReportComponent } from './party-closing-report/party-closing-report.component';
import { BankToBankComponent } from './bank-to-bank/bank-to-bank.component';
import { BankToBankRecordComponent } from './bank-to-bank-record/bank-to-bank-record.component';
import { GstrVoucherComponent } from './gstr-voucher/gstr-voucher.component';
import { GstrVoucherRecordComponent } from './gstr-voucher-record/gstr-voucher-record.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { CategoryAppComponent } from './category-app/category-app.component';
import { ProductAppComponent } from './product-app/product-app.component';
import { CropsComponent } from './crops/crops.component';
import { CropsIssueComponent } from './crops-issue/crops-issue.component';
import { CropsKrishiGyaanComponent } from './crops-krishi-gyaan/crops-krishi-gyaan.component';
import { PurchaseReportComponent } from './purchase-report/purchase-report.component';
import { AssetsVoucherComponent } from './assets-voucher/assets-voucher.component';
import { AssetsVRecordComponent } from './assets-v-record/assets-v-record.component';
import { LiabilityVoucherComponent } from './liability-voucher/liability-voucher.component';
import { LiabilityVRecordComponent } from './liability-v-record/liability-v-record.component';
import { AssetsLiabilitySearchComponent } from './assets-liability-search/assets-liability-search.component';
import { CropsProductComponent } from './crops-product/crops-product.component';
import { BannerDetailsComponent } from './banner-details/banner-details.component';
import { BrandAppComponent } from './brand-app/brand-app.component';
import { UserSuccessStoryComponent } from './user-success-story/user-success-story.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { SaleReportComponent } from './sale-report/sale-report.component';
import { ExpenseLedgerComponent } from './expense-ledger/expense-ledger.component';
import { SubcategoryReportComponent } from './subcategory-report/subcategory-report.component';
import { JvComponent } from './jv/jv.component';
import { JvrecordComponent } from './jvrecord/jvrecord.component';
import { SaleReturnReportComponent } from './sale-return-report/sale-return-report.component';
import { PurchaseReturnReportComponent } from './purchase-return-report/purchase-return-report.component';
import { PlanComponent } from './plan/plan.component';
import { PlanUserComponent } from './plan-user/plan-user.component';
import { PlanLocationComponent } from './plan-location/plan-location.component';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { SmsIntegrationComponent } from './sms-integration/sms-integration.component';
import { PurchasePlanWorklistComponent } from './purchase-plan-worklist/purchase-plan-worklist.component';
import { PlanPriceDetailComponent } from './plan-price-detail/plan-price-detail.component';
import { InitialProjectDetailComponent } from './initial-project-detail/initial-project-detail.component';
import { GeneralInformationComponent } from "./general-information/general-information.component";
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PlanExpiryDetailComponent } from './plan-expiry-detail/plan-expiry-detail.component';
import { TypeComponent } from './type/type.component';
import { OpeningBalanceAssetsComponent } from './opening-balance-assets/opening-balance-assets.component';
import { OpeningBalanceLiabilityComponent } from './opening-balance-liability/opening-balance-liability.component';
import { PartyTypeWiseComponent } from './party-type-wise/party-type-wise.component';
import { B2bReceiptPaymentComponent } from './b2b-receipt-payment/b2b-receipt-payment.component';
import { GstewaybillLoginComponent } from './gstewaybill-login/gstewaybill-login.component';
import { GroupMasterPartyComponent } from './group-master-party/group-master-party.component';
import { WhatsappMessageComponent } from './whatsapp-message/whatsapp-message.component';
import { UserInquiryComponent } from './user-inquiry/user-inquiry.component';
import { GroupWiseLedgerComponent } from './group-wise-ledger/group-wise-ledger.component';
import { VenderUserListComponent } from './vender-user-list/vender-user-list.component';
import { SubcategoryItemReportComponent } from './subcategory-item-report/subcategory-item-report.component';
import { PurchaseDemoComponent } from './purchase-demo/purchase-demo.component';
import { VenderStoryComponent } from './vender-story/vender-story.component'; 
import { SaleDemoComponent } from './sale-demo/sale-demo.component';
import { ThanksDetailComponent } from './thanks-detail/thanks-detail.component';
import { AdvertisementMsgComponent } from './advertisement-msg/advertisement-msg.component';
import { TrialBalanceComponent } from './trial-balance/trial-balance.component';
import { BalancesheetReportComponent } from './balancesheet-report/balancesheet-report.component';
import { PartyFarmerListComponent } from './party-farmer-list/party-farmer-list.component';
import { ProductWiseAnalysisComponent } from './product-wise-analysis/product-wise-analysis.component';
import { MediaMarketingComponent } from './media-marketing/media-marketing.component';
import { InvoiceMasterComponent } from './invoice-master/invoice-master.component';
import { InvoiceCategoryComponent } from './invoice-category/invoice-category.component';
import { LicenseNumberComponent } from './license-number/license-number.component';
import { SignatureComponent } from './signature/signature.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ItemsDetailComponent,
    TopHeaderComponent,
    LoginComponent,
    CategoryComponent,
    SubCategoryComponent,
    BrandComponent,
    UnitComponent,
    GSTComponent,
    LocationComponent,
    PartyComponent,
    PurchaseComponent,
    SaleComponent,
    SaleReturnComponent,
    TransferComponent,
    SaleRecordComponent,
    SaleReturnRecordComponent,
    PurchaseRecordComponent,
    TransferRecordComponent,
    RoleComponent,
    UserComponent,
    ReceiptVoucherComponent,
    PaymentVoucherComponent,
    ReceiptVRecordComponent,
    PaymentVRecordComponent,
    ItemWiseStockComponent,
    CashSaleComponent,
    CashSaleReturnComponent,
    MenuManagementComponent,
    SaleChallanComponent,
    SaleChallanRecordComponent,
    CompanyComponent,
    BalancesheetComponent,
    GroupComponent,
    LedgerComponent,
    appendINDPipe,
    PurchaseReturnComponent,
    PurchaseReturnRecordComponent,
    BankComponent,
    DetailAnalysisComponent,
    ItemStockDetailsComponent,
    ItemWiseSearchStockComponent,
    CompanyWiseSearchStockComponent,
    SummaryWiseSearchStockComponent,
    DashboardComponent,
    CashPurchaseComponent,
    LedgerDetailComponent,
    CreditNoteComponent,
    DebitNoteComponent,
    CreditNoteRecordComponent,
    DebitNoteRecordComponent,
    Gstr1PurchaseDetailComponent,
    ExpenseVoucherComponent,
    ExpenseVRecordComponent,
    StockAdjustmentComponent,
    BankBookComponent,
    CashBookComponent,
    StockvalutionComponent,
    CashComponent,
    CashToBankComponent,
    CashToBankRecordComponent,
    ExpiryDetailComponent,
    PartyClosingReportComponent,
    BankToBankComponent,
    BankToBankRecordComponent,
    GstrVoucherComponent,
    GstrVoucherRecordComponent,
    ProfitLossComponent,
    PurchaseReportComponent,
    CategoryAppComponent,
    ProductAppComponent,
    CropsComponent,
    CropsIssueComponent,
    CropsKrishiGyaanComponent,
    AssetsVoucherComponent,
    AssetsVRecordComponent,
    LiabilityVoucherComponent,
    LiabilityVRecordComponent,
    AssetsLiabilitySearchComponent,
    CropsProductComponent,
    BannerDetailsComponent,
    BrandAppComponent,
    UserSuccessStoryComponent,
    OrderHistoryComponent,
    SaleReportComponent,
    ExpenseLedgerComponent,
    SubcategoryReportComponent,
    JvComponent,
    JvrecordComponent,
    SaleReturnReportComponent,
    PurchaseReturnReportComponent,
    PlanComponent,
    PlanUserComponent,
    PlanLocationComponent,
    PurchasePlanComponent,
    SmsIntegrationComponent,
    PurchasePlanWorklistComponent,
    PlanPriceDetailComponent,
    InitialProjectDetailComponent,
    GeneralInformationComponent,
    TermConditionComponent,
    PlanExpiryDetailComponent,
    TypeComponent,
    OpeningBalanceAssetsComponent,
    OpeningBalanceLiabilityComponent,
    PartyTypeWiseComponent,
    B2bReceiptPaymentComponent,
    GstewaybillLoginComponent,
    GroupMasterPartyComponent,
    WhatsappMessageComponent,
    UserInquiryComponent,
    GroupWiseLedgerComponent,
    VenderUserListComponent,
    SubcategoryItemReportComponent,
    PurchaseDemoComponent,
    VenderStoryComponent,
    SaleDemoComponent,
    ThanksDetailComponent,
    AdvertisementMsgComponent,
    TrialBalanceComponent,
    BalancesheetReportComponent,
    PartyFarmerListComponent,
    ProductWiseAnalysisComponent,
    MediaMarketingComponent,
    InvoiceMasterComponent,
    InvoiceCategoryComponent,
    LicenseNumberComponent,
    SignatureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    Ng2SearchPipeModule,
    HttpModule,
    ToastrModule.forRoot({timeOut:3000,positionClass:'toast-bottom-left',closeButton:true}),
    BrowserAnimationsModule,
    NgxTypeaheadModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    MatSlideToggleModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
