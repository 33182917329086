import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: Http) { }

  createGroup(txtbalanceSheetName: string, txtglaccountName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      glaccountName : txtglaccountName,
      balanceSheetName:txtbalanceSheetName
    });
    return this.http.post('/api/create/gl', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateGroup(glaccountId: string, txtglaccountName: string,txtbalanceSheetName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      glaccountName : txtglaccountName,
      balanceSheetName : txtbalanceSheetName,
      glaccountId: glaccountId
    });
    return this.http.put('/api/update/gl', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllGroup(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/gl', { headers })
      .pipe(map(res => res.json()));

  }

  deletGroup(glaccountId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      glaccountId: glaccountId
    });
    return this.http.put('/api/delete/gl', body, { headers })
      .pipe(map(res => res.json()));
  }

}
