<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Product Wise Analysis Report</h2>
                <!-- <div class="row d-flex"> -->
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">From <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtFormDate"
                                                name="txtFormDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">To <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                class="form-control" [(ngModel)]="txtToDate"
                                                name="txtToDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Type <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtType" name="txtType">
                                                <option>-:Select Type:-</option>
                                                <option>Purchase</option>
                                                <option>Purchase Return</option>
                                                <option>Sale</option>
                                                <option>Sale Return</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Location <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtLocation" name="txtLocation">
                                                <option>-:Select Location:-</option>
                                                <option value="ALL">ALL</option>
                                                <option *ngFor="let location of allLocation" [value]="location.locationName">{{location.locationName}}</option>
                                            </select>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-2 col-12 btn-container-end">
                                            <button *ngIf="!isClickSearch" class="btn btn-primary d-block" type="button"
                                                (click)="SearchProductReportOverAll()">Search
                                            </button>
                                            <button *ngIf="isClickSearch" class="btn btn-primary d-block" type="button" disabled>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Please wait...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                <!-- </div> -->
                <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <!-- <div class="portlet-header">Search Details
                            <span style="float: right;"><b>Total : {{totalNetAmount | appendIND}}</b></span>
                        </div> -->
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-xl-8 col-lg-6 col-md-6 col-12">
                                    <button *ngIf="!isClickDownload && !isLoading" class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                    <button *ngIf="isClickDownload" class="btn btn-primary d-block bottom_margin" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Please wait...
                                    </button>
                                </div>
                                <div *ngIf="!isLoading" class="col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" *ngIf="isLoading">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!isLoading" class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Subcategory Name</th>
                                            <th scope="col" class="text-center">Total Purchase Quantity</th>
                                            <th scope="col" class="text-center">Total Purchase Avg Price(₹)</th>
                                            <th scope="col" class="text-center">Total Credit Quantity</th>
                                            <th scope="col" class="text-center">Total Credit Avg Price(₹)</th>
                                            <th scope="col" class="text-center">Total Sale Quantity</th>
                                            <th scope="col" class="text-center">Total Sale Avg Price(₹)</th>
                                            <th scope="col" class="text-center">Total Debit Quantity</th>
                                            <th scope="col" class="text-center">Total Debit Avg Price(₹)</th>
                                            <th scope="col" class="text-center">Profit/Loss(₹)</th>
                                            <!-- <th scope="col">Total Stock</th>
                                            <th scope="col">Total Stock Avg Price(₹)</th>
                                            <th scope="col" class="text-center">Total Stock Avg Amount(₹)</th> -->
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let srch of allData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{srch.itemname}}</td>
                                            <td>{{srch.subcategoryname}}</td>
                                            <td class="text-center">{{srch.totalPurchaseQuantity | number:'1.0-0'}}</td>
                                            <td class="text-end">{{srch.totalPurchaseAvgPrice | number:'1.2-2'}}</td>
                                            <td class="text-center">{{srch.totalCreditQuantity | number:'1.0-0'}}</td>
                                            <td class="text-end">{{srch.totalCreditAvgPrice | number:'1.2-2'}}</td>
                                            <td class="text-center">{{srch.totalSaleQuantity | number:'1.0-0'}}</td>
                                            <td class="text-end">{{srch.totalSaleAvgPrice| number:'1.2-2'}}</td>
                                            <td class="text-center">{{srch.totalDebitQuantity | number:'1.0-0'}}</td>
                                            <td class="text-end">{{srch.totalDebitAvgPrice | number:'1.2-2'}}</td>
                                            <!-- <td class="text-end">{{((srch.totalSaleQuantity*srch.totalSaleAvgPrice)+(srch.totalDebitQuantity*srch.totalDebitAvgPrice))-((srch.totalPurchaseQuantity*srch.totalPurchaseAvgPrice)+(srch.totalCreditQuantity*srch.totalCreditAvgPrice)) | number:'1.2-2'}}</td> -->
                                            <td class="text-end">{{((srch.totalSaleAvgPrice)+(srch.totalDebitAvgPrice))-((srch.totalPurchaseAvgPrice)+(srch.totalCreditAvgPrice)) | number:'1.2-2'}}</td>
                                            <!-- <td>{{srch.totalstock | number:'1.3-3'}}</td>
                                            <td>{{srch.totalStockAvgPrice| number:'1.2-2'}}</td>
                                            <td class="text-end">{{srch.totalStockAvgAmount| appendIND}}</td> -->
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                    (click)="getMainProductHistory(srch)" data-bs-toggle="modal"
                                                    data-bs-target="#detailModal">
                                                    <i class="fas fa-history"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allData.length == 0 ">
                                            <td colspan="12" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div *ngIf="!isLoading" class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="portlet-footer">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button class="btn btn-secondary me-md-2" type="button">
                            Cancel
                        </button>
                        <button class="btn btn-primary" type="submit" (click)="createPurchaseDetail()">
                            Save
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="detailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detail-({{txtProductName}})</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <div class="portlet-content">
                            <div class="text-center" *ngIf="isProductHistoryLoading">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!isProductHistoryLoading" class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Type</th>
                                            <th scope="col" class="text-center">Inward Quantity</th>
                                            <th scope="col" class="text-center">Outward Quantity</th>
                                            <th scope="col" class="text-center">Net Amount(₹)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td colspan="2" class="text-right"><b>Opening Balance</b></td>
                                            <td class="text-center"><b>{{productPurchaseOpeningStock | number:'1.3-3'}}</b></td>
                                            <td></td>
                                            <td></td>
                                        </tr> -->
                                        <tr *ngFor="let srchdetail of productPurchaseDetails | paginate: { itemsPerPage: 10, currentPage: pageProductHistory, id: 'productHistory'}">
                                            <td>{{srchdetail.datee | date:'dd-MMM-yyy'}}</td>
                                            <td>{{srchdetail.type}}</td>
                                            <td class="text-center">{{srchdetail.puquantity | number : '1.0-0'}}</td>
                                            <td class="text-center">{{srchdetail.quantity | number : '1.0-0'}}</td>
                                            <!-- <td class="text-end">{{pu.netamount | number:'1.2-2'}}</td> -->
                                            <td class="text-end">{{srchdetail.netamount | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="2" class="text-end"><b>Total : </b></td>
                                            <td class="text-center"><b>{{totalPurchaseQuantity | number:'1.0-0'}}</b></td>
                                            <td class="text-center"><b>{{totalSaleQuantity | number:'1.0-0'}}</b></td>
                                            <td></td>
                                        </tr>
                                        <tr *ngIf="productPurchaseDetails < 1 || productPurchaseDetails == null">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div *ngIf="!isProductHistoryLoading" class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="pageProductHistory = $event" (pageBoundsCorrection)="pageProductHistory=($event)"
                                            responsive="true" id="productHistory"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>