<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Crops Krishi Gyaan Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="cropsissue" class="form-label">Crops<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="cropsissue" name="txtCrops"
                                                [(ngModel)]="txtCrops" required="">
                                                <option>-:Select Crops:-</option>
                                                <option *ngFor="let crpisu of allCrops"
                                                    [value]="crpisu.cropsName">{{crpisu.cropsName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid crops name.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crops Krishi Gyaan<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtCropsKrishiGyaanId"
                                                name="txtCropsKrishiGyaanId">
                                            <input type="text" class="form-control" id="ProductApp"
                                                placeholder="Enter Crops Krishi Gyaan" name="txtCropsKrishiGyaanName"
                                                [(ngModel)]="txtCropsKrishiGyaanName" required="required" (focusout)="toUpperCaseCropsKrishiGyaan()" />
                                            <div class="invalid-feedback">
                                                Please enter crops krishi gyaan name.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crops Krishi Gyaan Gujarati<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="CropsGuj"
                                                placeholder="Enter Crops Krishi Gyaan Gujarati" name="txtCropsKrishiGyaanGuj" [(ngModel)]="txtCropsKrishiGyaanGuj"
                                                required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Crops krishi gyaan gujarati name.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crops Krishi Gyaan Description<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="CropsDes"
                                                placeholder="Enter Crops Krishi Gyaan Description" name="txtCropsKrishiGyaanDes"
                                                [(ngModel)]="txtCropsKrishiGyaanDes" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Crops krishi gyaan description.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Crops Krishi Gyaan Description Gujarati<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="ProductDesGuj"
                                                placeholder="Enter Crops Krishi Gyaan Description Gujarati" name="txtCropsKrishiGyaanDesGuj" 
                                                [(ngModel)]="txtCropsKrishiGyaanDesGuj" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Crops krishi gyaan description gujarati.
                                            </div>
                                        </div>
                                        <div class="row g-2">
                                            <div class="col-md-12 col-sm-8 col-12">
                                                <div class="form-group">
                                                    <!-- <label style="opacity:0;">Image</label> -->
                                                    <label for="formFile" class="form-label">Image</label>
                                                    <input class="form-control" type="file" id="formFile" name="txtImage" multiple
                                                    [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;" *ngFor='let url of imagePreview;let index = index'>
                                                <img [src]="url" height="30px" width="30px">
                                                <a class="text-danger" style="margin-left: 10px; margin-right: 10px;" title="Delete" (click)="removeSelectedFile(index)"><i class="fas fa-trash-alt" aria-hidden="true"></i></a>
                                            </div>
                                        </div>

                                        <div class="row g-2">
                                            <div class="col-md-12 col-sm-8 col-12">
                                                <div class="form-group">
                                                    <label class="form-label" for="customFile4">Video</label>
                                                    <!-- <div class="custom-file"> -->
                                                        <input class="form-control" type="file" multiple #myInput
                                                            name="txtVideo" [(ngModel)]="txtVideo"
                                                            (change)="onVideoUpload($event)"
                                                            id="customFile4" accept="video/*">
                                                        <label style="opacity:0;"> Video</label>
                                                        <!-- <div class="d-flex justify-content-start">
                                                            <video autoplay loop height="30px" width="30px" [src]="videoPreview" [muted]="'muted'"></video>
                                                            <a class="text-danger d-flex align-items-center ml-2" *ngIf="videoPreview != null" title="Delete" (click)="removeSelectedVideo()"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                                        </div> -->
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 0px;" *ngFor='let url of videoPreview;let index = index'>
                                                <!-- <div class="d-flex justify-content-start"> -->
                                                    <video autoplay loop height="50px" width="50px" [src]="url" [muted]="'muted'"></video>
                                                    <a class="text-danger" style="margin-left: 10px; margin-right: 10px;" *ngIf="url != null" title="Delete" (click)="removeSelectedVideo(index)"><i class="fas fa-trash-alt" aria-hidden="true"></i></a>
                                                <!-- </div> -->
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                        <div class="row">
                                            <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createCropsKrishiGyaan()">
                                                {{btnName}}
                                            </button>
                                            </div>
                                        </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Crops Krishi Gyaan</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Crops</th>
                                                <th scope="col">Crops Krishi Gyaan</th>
                                                <th scope="col">Crops Krishi Gyaan Gujarati</th>
                                                <th scope="col">Crops Krishi Gyaan Description</th>
                                                <th scope="col">Crops Krishi Gyaan Description Gujarati</th>
                                                <th class="text-center">Image</th>
                                                <th class="text-center">Video</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let ckg of allCropsKrishiGyaan | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{ckg.crops.cropsName}}</td>
                                                <td>{{ckg.cropsKrishiGyaanName}}</td>
                                                <td>{{ckg.cropsKrishiGyaanNameGuj}}</td>
                                                <td>{{ckg.cropsKrishiGyaanDescription}}</td>
                                                <td>{{ckg.cropsKrishiGyaanDescriptionGuj}}</td>
                                                <!-- <td class="text-center"><img [src]="ckg.image" height="50px" width="50px">
                                                </td>
                                                <td class="text-center">
                                                    <video autoplay loop height="50px" width="50px" [src]="ckg.video" [muted]="'muted'"></video>
                                                </td> -->
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Image"
                                                        (click)="getCropsKrishiGyaanImages(ckg,'Image')" data-bs-toggle="modal"
                                                        data-bs-target="#imageModal">Images
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Image"
                                                        (click)="getCropsKrishiGyaanVideo(ckg,'Video')" data-bs-toggle="modal"
                                                        data-bs-target="#videoModal">Video
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateCropsKrishiGyaan(ckg)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteDataCropsKrishiGyaan(ckg)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allCropsKrishiGyaan < 1">
                                                <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="imageModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Images</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="row d-flex g-3 mt-2">
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-3" *ngFor="let ckg of allCropsKrishiGyaanImages"
                            style="margin: 10px;">
                                <img [src]="ckg.imageURL" height="80px" width="80px">
                                <a class="text-danger d-flex align-items-center ms-2 mt-2" role="button" *ngIf="ckg.imageURL != null" title="Delete" (click)="deleteImagesAndVideos(ckg)"
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-toggle="modal" data-bs-target="#deleteImageVideoModal">
                                    <i class="fas fa-trash-alt" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div class="text-center" *ngIf="allCropsKrishiGyaanImages < 1" style="margin-bottom: 10px;">
                                <div class="text-center"><b>No Image Found</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="videoModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Videos</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="row d-flex g-3 mt-2">
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-3" *ngFor="let ckg of allCropsKrishiGyaanVideo;let index = index"
                            style="margin: 10px;">
                                <video autoplay loop height="80px" width="80px" [src]="ckg.videoURL" [muted]="'muted'"></video>
                                <a class="text-danger d-flex align-items-center ms-2" role="button" *ngIf="ckg.videoURL != null" title="Delete" (click)="deleteImagesAndVideos(ckg)"
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-toggle="modal" data-bs-target="#deleteImageVideoModal">
                                    <i class="fas fa-trash-alt" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div class="text-center" *ngIf="allCropsKrishiGyaanVideo < 1" style="margin-bottom: 10px;">
                                <div class="text-center"><b>No Video Found</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteImageVideoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete document</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this document ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmImageDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>