import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor( private http: Http,) { }

  createCompany(txtCompany: string, txtGSTNumber: string, txtQRImage: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      companyName : txtCompany,
      gstNumber : txtGSTNumber,
      qr : txtQRImage,
    });
    return this.http.post('/api/create/company', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateCompany(txcompanyId: string, txtcompanyName: string, txtGSTNumber: string, txtImage: string, txtQRImage: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      companyName : txtcompanyName,
      gstNumber : txtGSTNumber,
      companyId : txcompanyId,
      image: txtImage,
      qr : txtQRImage
    });
    return this.http.put('/api/update/company', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllCompany(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/company', { headers })
      .pipe(map(res => res.json()));

  }

  getCashOpeningBalance(txtCompanyId:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/get/cash/'+txtCompanyId, { headers })
      .pipe(map(res => res.json()));

  }

  deleteCompany(txcompanyId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      companyId : txcompanyId,
    });
    return this.http.put('/api/delete/company', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/company/image', data, { headers: headers })
      .pipe(map(res => res));
  }
  uploadQRCode(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/company/qrcode', data, { headers: headers })
      .pipe(map(res => res));
  }
}
