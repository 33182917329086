import { Component, OnInit } from '@angular/core';
import { PurchaseService } from "../service/purchase/purchase.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
import { Purchase } from "../service/purchase/purchase";
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
declare var $: any;

@Component({
  selector: 'app-purchase-demo',
  templateUrl: './purchase-demo.component.html',
  styleUrls: ['./purchase-demo.component.css']
})
export class PurchaseDemoComponent implements OnInit {

  itemId;
  txtPurchaseDate;
  txtpurchaseid;
  txtpurchaseCode;
  txtpartyName;
  txtDueDays;
  txtDueDate;
  txtDueDateConvert;
  txtgstAmount = 0;
  txtamount = 0;
  txtDiscount = 0;
  txtnetAmount = 0;

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBatchNumber;
  txtquantity;
  txtrate;
  txtExpiryDate;
  purchaseNumber;
  txtType;

  allPurchase;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];

  PurchaseData: Array<Purchase> = [];
  PurchaseIdLast: number = 0;
  txtCGST: number;
  txtSGST: number;
  txtTotalGST: number;
  existGST = 0;
  datePipe = new DatePipe("en-US");
  txtPartyInvoiceNo;
  txtNarration;
  public loading: boolean = false;

  constructor(
    private itemsdetailservice: ItemsDetailService,
    private purchaseservice: PurchaseService,
    private toastr: ToastrService,
    private partyService: PartyService,

  ) { }

  ngOnInit(): void {
    this.txtPurchaseDate = this.datePipe.transform(this.txtPurchaseDate, "dd/MM/yyyy");
    this.txtPurchaseDate = new Date();
    this.txtDueDate = new Date();
    this.txtDueDate = this.datePipe.transform(this.txtDueDate, "dd/MM/yyyy");
    this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.txtExpiryDate = new Date();
    this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllParty();
    // this.getPurchaseNumber();

  }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getDueDate() {
    var ltime = this.txtPurchaseDate.getTime() + this.txtDueDays * 24 * 60 * 60 * 1000;
    this.txtDueDate = new Date(ltime);
    console.log("this due date",this.txtDueDate);
    this.txtDueDateConvert = this.txtDueDate;
    this.txtDueDate = this.datePipe.transform(this.txtDueDate, "dd-MM-yyyy");
    console.log("this due date after",this.txtDueDate);
  }
  //for search data


  getPurchaseNumber() {
    this.purchaseservice.getPurchaseNumber().subscribe(res => {
      this.purchaseNumber = res;
      this.txtpurchaseCode = this.purchaseNumber;
    })
  }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
    this.getItemById();
  }
  tempParty;
  selectedParty(party) {
    this.tempParty = party;

    this.searchParty = party.partyName;
  }
  allparty;
  getAllParty() {
    this.partyService.getAllPartyStatus("COMPANY").subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        this.searchPartyName.push(temp)
      }
      console.log(this.allparty);
    })
  }
  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.allItem);

    })
  }

  clearGridData() {
    this.txtItemName = "";
    this.txtManufactureDate = new Date();
    // this.txtManufactureDate =this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = "";
    this.txtSubCategoryName = "";
    this.txtBatchNumber = "";
    this.txtquantity = "";
    this.txtrate = "";
    this.txtExpiryDate = new Date();
    // this.txtExpiryDate =this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = "";
    this.btnName = "Add";
    this.txtpurchaseid = null;
    this.txtCGST = 0;
    this.txtSGST = 0;
  }

  clearValue() {
    this.txtPurchaseDate = new Date();
    // this.txtPurchaseDate = this.datePipe.transform(this.txtPurchaseDate, "dd/MM/yyyy");
    this.txtpurchaseCode = '';
    this.txtDueDays = '';
    this.txtDueDate = new Date();
    this.txtDueDate = this.datePipe.transform(this.txtDueDate, "dd/MM/yyyy");
    this.txtnetAmount = 0;
    this.txtamount = 0;
    this.txtDiscount = 0;
    this.txtgstAmount = 0;
    this.txtpartyName = '';
    this.searchParty = '';
    this.PurchaseData = [];
    this.tempamount = 0;
    this.existGST = 0;
    this.txtPartyInvoiceNo = '';
    this.txtNarration = '';
    this.txtType = null;
    // this.getPurchaseNumber();
  }

  resItemById;
  getItemById() {
    this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
      this.resItemById = res;
      console.log(this.resItemById);
      this.txtCategoryName = this.resItemById.category.categoryName;
      this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
      this.txtCGST = this.resItemById.gst.cgst;
      this.txtSGST = this.resItemById.gst.sgst;
    });
  }

  addPurchaseDetail() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtBatchNumber == undefined || this.txtBatchNumber == null || this.txtBatchNumber == 0) {
      return this.toastr.error('Batch number must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate must be required');
    } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      return this.toastr.error('Manufacture date must be required');
    } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      return this.toastr.error('Expiry date must be required');
    } else {
      if (this.txtpurchaseid == null) {
        // this.txtItemName = this.tempitem;
        var purchaseDetail = new Purchase();
        if (this.PurchaseData.length == 0) {
          purchaseDetail.id1 = this.PurchaseIdLast;
        } else if (this.PurchaseData.length != 0) {
          purchaseDetail.id1 = this.PurchaseIdLast + 1;
        }
        this.PurchaseIdLast = purchaseDetail.id1;
        purchaseDetail.itemName = this.search1; //this for selected itemName
        purchaseDetail.itemId = this.itemId;
        purchaseDetail.categoryName = this.txtCategoryName;
        purchaseDetail.subCategoryName = this.txtSubCategoryName;
        purchaseDetail.batchNumber = this.txtBatchNumber;
        purchaseDetail.quantity = this.txtquantity;
        purchaseDetail.rate = this.txtrate;
        purchaseDetail.cgst = this.txtCGST;
        purchaseDetail.sgst = this.txtSGST;
        purchaseDetail.totalAmount = this.txtrate * this.txtquantity;
        //base price calculation
        var totalpercentage = 100 + this.txtCGST + this.txtSGST
        var baseprice = 0;
        baseprice = parseFloat(Number(purchaseDetail.totalAmount * 100 / totalpercentage).toFixed(2));
        var totalgstamt = 0;
        totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
        // purchaseDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
        // purchaseDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
        purchaseDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        purchaseDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        purchaseDetail.netAmount = baseprice;

        this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
        purchaseDetail.manufactureDate = this.txtManufactureDate;
        this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
        purchaseDetail.expiryDate = this.txtExpiryDate;
        this.PurchaseData.push(purchaseDetail);

        //code for calculate netamount and gstamount;
        // var temp = 0;
        // var gstsum = 0;
        // temp = purchaseDetail.totalAmount;
        // this.tempamount = this.tempamount + temp;

        // this.tempGST = temp * (this.txtCGST + this.txtSGST) / 100;
        // gstsum = gstsum + this.tempGST;
        // this.existGST = this.existGST + gstsum;
        // this.txtgstAmount = this.existGST;

        // this.txtamount = this.tempamount - this.txtgstAmount;
        // this.txtnetAmount = this.tempamount;
        this.getAmount();
        this.calculateNetAmount()
        this.clearGridData();

      } else {
        for (let Purchase of this.PurchaseData) {
          if (Purchase.id1 == this.txtpurchaseid) {
            this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
            Purchase.manufactureDate = this.txtManufactureDate;
            Purchase.itemName = this.search1;
            Purchase.itemId = this.itemId;
            Purchase.categoryName = this.txtCategoryName;
            Purchase.subCategoryName = this.txtSubCategoryName;
            Purchase.batchNumber = this.txtBatchNumber;
            Purchase.quantity = this.txtquantity;
            Purchase.rate = this.txtrate;
            Purchase.cgst = this.txtCGST;
            Purchase.sgst = this.txtSGST;
            Purchase.totalAmount = this.txtrate * this.txtquantity;
            //base price calculation
            var totalpercentage = 100 + this.txtCGST + this.txtSGST
            var baseprice = 0;
            baseprice = parseFloat(Number(Purchase.totalAmount * 100 / totalpercentage).toFixed(2));
            var totalgstamt = 0;
            totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
            // Purchase.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
            // Purchase.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
            Purchase.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            Purchase.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            Purchase.netAmount = baseprice;

            this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
            Purchase.expiryDate = this.txtExpiryDate;
            this.getAmount();
            this.calculateNetAmount();
            this.clearGridData();
          }
        }
      }
    }
    console.log(this.PurchaseData);
  }

  calculateNetAmount() {
    if (this.txtDiscount == 0 || this.txtDiscount == undefined || this.txtDiscount == null) {
      this.txtDiscount = 0;
      this.txtnetAmount = this.tempNetAmount;
    } else {
      this.txtnetAmount = this.tempNetAmount - this.txtDiscount;
    }
  }

  tempamount = 0;
  tempGST = 0;
  tempNetAmount = 0;
  getAmount() {
    this.txtamount = 0;
    this.txtgstAmount = 0;
    this.tempamount = 0;
    this.tempGST = 0;
    this.existGST = 0;
    var temp = 0;
    var gstsum = 0;
    this.txtnetAmount = 0;

    for (let item of this.PurchaseData) {
      // temp = item.quantity * item.rate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = temp * (item.cgst + item.sgst) / 100;
      // // gstsum = gstsum + this.tempGST;
      // this.existGST = this.existGST + this.tempGST;
      // this.txtgstAmount = this.existGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;

      this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
      this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
      this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
      this.tempNetAmount = this.txtnetAmount;
    }

  }

  purchaseDetailres;
  createPurchaseDetail() {
    if (this.txtPurchaseDate == undefined || this.txtPurchaseDate == null || this.txtPurchaseDate == '') {
      return this.toastr.error('Purchase date must be required');
      } else if (this.txtpurchaseCode == undefined || this.txtpurchaseCode == null || this.txtpurchaseCode == '') {
        return this.toastr.error(' purchase number must be required');
    } else if (this.txtType == undefined || this.txtType == null || this.txtType == '' || this.txtType == "-:Select Type:-") {
      return this.toastr.error('Type must be required');
    } else if (this.txtpartyName == undefined || this.txtpartyName == null || this.txtpartyName == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.txtPartyInvoiceNo == undefined || this.txtPartyInvoiceNo == null || this.txtPartyInvoiceNo == '') {
      return this.toastr.error('Party invoice number must be required');
    } else if (this.txtDueDays == undefined || this.txtDueDays == null || this.txtDueDays == '') {
      return this.toastr.error('Due days must be required');
    } else if (this.PurchaseData.length == 0 || this.PurchaseData == []) {
      return this.toastr.error('Purchase detail must be required');
    } else {
      // this.txtpartyName = this.tempParty;
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.txtPurchaseDate = this.datePipe.transform(this.txtPurchaseDate, 'yyyy-MM-dd');
      this.txtDueDate = this.datePipe.transform(this.txtDueDateConvert, 'yyyy-MM-dd');
      // console.log("this due date after after",this.txtDueDate);
      this.purchaseservice.addDemoPurchaseDetail(this.txtPurchaseDate, this.txtpurchaseCode, this.tempParty.partyId, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.PurchaseData, this.txtDueDays, this.txtDueDate,this.txtPartyInvoiceNo, this.txtNarration,this.txtType)
        .subscribe(res => {
          this.purchaseDetailres = res;
          // console.log(this.purchaseDetailres);
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
          }
          this.clearValue();
          // this.getPurchaseNumber();
          this.toastr.success('Purchase save successfully');
        }, error => {
          // this.toastr.error('Purchase not save');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
        })
    }
  }

  getAllPurchase() {
    this.purchaseservice.getAllPurchase().subscribe(res => {
      this.allPurchase = res;
    })
  }

  updatePurchase(purchase) {
    this.btnName = "Update";
    this.txtpurchaseid = purchase.id1;
    this.txtItemName = purchase.itemName;
    this.itemId = purchase.itemId;
    this.search1 = purchase.itemName;
    this.txtCategoryName = purchase.categoryName;
    this.txtSubCategoryName = purchase.subCategoryName;
    this.txtquantity = purchase.quantity;
    this.txtrate = purchase.rate;
    this.txtCGST = purchase.cgst;
    this.txtSGST = purchase.sgst;
    this.txtManufactureDate = purchase.manufactureDate;
    this.txtExpiryDate = purchase.expiryDate;
    this.txtBatchNumber = purchase.batchNumber;
  }

  i;
  deletepurchasedata;
  deletePurchase(purchase) {
    this.deletepurchasedata = purchase;
  }

  confirmDelete() {
    for (let PurchaseData of this.PurchaseData) {
      if (PurchaseData.id1 == this.deletepurchasedata.id1) {
        this.i = this.deletepurchasedata.purchaseId;
        let index = this.PurchaseData.indexOf(this.deletepurchasedata);
        if (PurchaseData.id1 !== -1) {
          this.PurchaseData.splice(index, 1);
          this.getAmount();
          this.calculateNetAmount();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
        }
      }
    }
  }

}
