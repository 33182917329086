import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PartyService {

  constructor(
    private http: Http,
  ) { }

  createParty(txtParty: string, txtPartyCode: string, txtAddress: string, txtCity: string, txtState: string, txtPincode: string, txtCellNumber: string, txtMailId: string,txtGstNumber:string,txtType:string,txtAccountGroup:string,companyData: Array<any>, txtChequeNo: string, txtbankName: string, txtGroupName: string, txtStateCode: string, txtLedger: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyName : txtParty,
      partyCode : txtPartyCode,
      address : txtAddress,
      city : txtCity,
      state : txtState,
      pincode : txtPincode,
      cellNumber : txtCellNumber,
      mailId : txtMailId,
      gstNumber : txtGstNumber,
      type : txtType,
      accountGroup: txtAccountGroup,
      companyDTOs:companyData,
      cheqNo:txtChequeNo,
      bankName:txtbankName,
      groupName:txtGroupName,
      stateCode:txtStateCode,
      ledgerId:txtLedger,
    });
    return this.http.post('/api/create/party', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateParty(txtPartyId: string, txtParty: string, txtPartyCode: string, txtAddress: string, txtCity: string, txtState: string, txtPincode: string, txtCellNumber: string, txtMailId: string,txtGstNumber:string,txtType:string,txtAccountGroup:string,txtopeningbalance:number, txtChequeNo: string, txtbankName: string, companyData: Array<any>, txtGroupName: string, txtStateCode: string, txtLedger: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyId : txtPartyId,
      partyName : txtParty,
      partyCode : txtPartyCode,
      address : txtAddress,
      city : txtCity,
      state : txtState,
      pincode : txtPincode,
      cellNumber : txtCellNumber,
      mailId : txtMailId,
      gstNumber : txtGstNumber,
      type : txtType,
      accountGroup: txtAccountGroup,
      cheqNo:txtChequeNo,
      bankName:txtbankName,
      companyDTOs:companyData,
      groupName:txtGroupName,
      stateCode:txtStateCode,
      ledgerId:txtLedger,
      // openingBalance:txtopeningbalance
    });
    return this.http.put('/api/update/party', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateOpeningParty(txtPartyId: string,companyOpeningData: Array<any>){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyId : txtPartyId,
      companyDTOs:companyOpeningData,
      // openingBalance:txtopeningbalance
    });
    return this.http.put('/api/update/openingbalance/party', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllParty(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/party', { headers })
      .pipe(map(res => res.json()));

  }
  getAllPartyByStatus(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/party/statuswise', { headers })
      .pipe(map(res => res.json()));
  }

  getAllPartyStatus(txtStatus: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/get/party/status/'+txtStatus, { headers })
      .pipe(map(res => res.json()));

  }

  deleteParty(txtPartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyId : txtPartyId,
    });
    return this.http.put('/api/delete/party', body, { headers })
      .pipe(map(res => res.json()));
  }

  getPartyByCellNumber(txtCellNumber: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/cellnumber/'+txtCellNumber ,  { headers })
      .pipe(map(res => res.json()));
  }

  getAllPartyAccount(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/accountgroup?accountgroup=CASH,BANK',{headers:headers})
    .pipe(map(res => res.json()));

  }

  getAllPartyType(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/accountgroup?accountgroup=COMPANY,FARMER',{headers:headers})
    .pipe(map(res => res.json()));

  }

  getAllPartyTypeWiseReport(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/accountgroup?accountgroup=FARMER',{headers:headers})
    .pipe(map(res => res.json()));

  }
  getAllPartyByCompanyOrg(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/org/company',{headers:headers})
    .pipe(map(res => res.json()));

  }

  getAllBank(txtParty:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/bank/company/'+ txtParty,{headers:headers})
    .pipe(map(res => res.json()));
  }

  importExcelParty(resParty){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.post('/api/create/list/party/demo/farmer', resParty, { headers })
      .pipe(map(res => res));
  }

  getPartyByOpeningBlance(txtPartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/opningbalance/party/'+txtPartyId ,  { headers })
      .pipe(map(res => res.json()));
  }

  getAllPartyByAllType(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/without/companyorg/party',{headers:headers})
    .pipe(map(res => res.json()));
  }
  
  getAllState(){
    const headers = new Headers();
    // var token = localStorage.getItem('Token');
    // headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/state',{headers:headers})
    .pipe(map(res => res.json()));
  }

  getLedgerByPartyId(txtPartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getledger/party/'+txtPartyId , { headers }).pipe(map(res => res.json()));
  }

  getAllLedgerByPartyId(txtPartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/multiledger/'+txtPartyId , { headers }).pipe(map(res => res.json()));
  }

  getdebitLedgerByPartyId(txtPartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/multiledger/'+txtPartyId , { headers }).pipe(map(res => res.json()));
  }

  createPartyLedger(partyLedgerId: string, txtAssLibLedger: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyId : partyLedgerId,
      ledgerId : txtAssLibLedger,
    });
    return this.http.post('/api/create/partyledger', body , { headers }).pipe(map(res => res.json()));
  }

  getPartyOpeningBalanceByMonth(txtStartDate: string, previousdayDate: string, txtPartyId: string, txtcompany: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/partyopeningbalance/date/' + txtStartDate + '/' + previousdayDate + '/party/' + txtPartyId + '/company/' + txtcompany, { headers })
    .pipe(map(res => res.json()));
  }
  getAllPartyOpeningBalanceByMonth(txtStartDate: string, previousdayDate: string, txtPartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/allpartyopeningbalance/date/' + txtStartDate + '/' + previousdayDate + '/party/' + txtPartyId , { headers })
    .pipe(map(res => res.json()));
  }

  getAllFarmerListByTypeAndOrgId(type: string,organizationid: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/party/type/'+type+'/organizationid/'+organizationid , { headers }).pipe(map(res => res.json()));
  }
  getAllOrganization(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/organization' , { headers }).pipe(map(res => res.json()));
  }
}
