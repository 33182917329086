<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Receipt Voucher Details</h2> -->
                <div class="row d-flex align-items-baseline ">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Opening Balance Liability Voucher</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Voucher Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtVoucherDate "
                                                name="txtVoucherDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Voucher Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-4 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtLiabilityId"
                                                name="txtLiabilityId">
                                            <select class="form-select" id="company" name="txtcompany"
                                                [(ngModel)]="txtcompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company">
                                                    {{company.partyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-flex align-items-baseline g-3 mt-2" >
                                        <div class="col-lg-3 col-xl-4 col-8 position-relative">
                                            <label for="last" class="form-label">Party Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}-{{item.result.cellNumber}}{{item.result.address !='' && item.result.address !=null ? '-'+item.result.address:''}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-4">
                                            <label for="first" class="form-label">Ledger Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="ledger" name="txtLibLedger"
                                                [(ngModel)]="txtLibLedger" required="">
                                                <option>-:Select Ledger Name:-</option>
                                                <option *ngFor="let ledger of allAssLibLedger" [ngValue]="ledger.ledgerId">
                                                    {{ledger.ledgerName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter ledger.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-4">
                                            <label for="country" class="form-label">Opening Balance<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="last" placeholder="Opening Balance"
                                                required="required" [(ngModel)]="txtcredit" name="txtcredit"
                                                (keypress)="numberandDotOnly($event)" />
                                            <div class="invalid-feedback">
                                                Please enter a credit.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-4 col-8">
                                            <label for="country" class="form-label">Narration</label>
                                            <textarea class="form-control" rows="1" name="txtNarration"
                                                [(ngModel)]="txtNarration"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearValue()">
                                Cancel
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="addLiabilityVoucher();">
                                Save
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>