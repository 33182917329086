import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class CreditnoteService {

  constructor(private http: Http,private httpClient: HttpClient) { }

  createCreditNote(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number, txttype:string, txtgst:string, txttotalAmount:number,amount:number,CreditDebitNoteData: Array<any>){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId,
      type: txttype,
      gst: txtgst,
      totalamount: txttotalAmount,
      amount:amount,
      creditDebitNoteDetailDTOs : CreditDebitNoteData
    });
    return this.http.post('/api/create/creditnote', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllCreditNote(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/creditnote', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Credit note By Id
  getCreditNoteById(txtId:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/creditnote/'+txtId,{headers:headers})
    .pipe(map(res => res.json()));

  }

  public downloadCreditNote(vouchermasterid: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/credit/note/` + vouchermasterid, { headers: header, responseType: 'blob' });
  }

  sendCreditEmail(id: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/server/credit/note/'+ id +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  creditNoteMailWithSendGrid(vouchermstId: string,email: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      vouchermasterid: vouchermstId,
      email: email
    });
    return this.http.post('/api/send/creditnote', body, { headers })
      .pipe(map(res => res));
  }

  //get All Credit Debit note detail By Voucher Id
  creditDebitNoteDetailByVoucherId(voucherid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/creditdebitnote/detail/voucherid/'+voucherid,{headers:headers})
    .pipe(map(res => res.json()));
  }
  //get All Credit note detail excel
  getAllCreditNoteExcel(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/excel/creditnote/detail',{headers:headers})
    .pipe(map(res => res.json()));
  }

  exportExcelCreditDebitNote(filename, excelData) {
    //Title, Data
    const title = filename;
    const allPassedData: any[] = excelData;
    const data = excelData;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);
    
    //Dynamic Header.
    let tempHeader = []
    for (let x1 of Object.keys(allPassedData[0])) {
      tempHeader.push(x1)
    }
    // let header = ["Seives","CVD","HPHT","Natural","Monzonite"]
    let headerRow = worksheet.addRow(tempHeader)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        // fgColor: { argb: '0070C0' },
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    //Dynamic width.
    for(let i= 1; i<= tempHeader.length; i++){
      worksheet.getColumn(i).width = 15;
      worksheet.getColumn(i).alignment = { vertical:'middle', horizontal: 'center', wrapText: true};
      worksheet.getColumn(2).width = 25;
      worksheet.getColumn(3).width = 25;
      worksheet.getColumn(5).width = 25;
    }

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      var currentMonth = new Date().getMonth()+1;
      fs.saveAs(blob, title +'-'+new Date().getDate()+'-'+currentMonth+'-'+new Date().getFullYear()+' '+new Date().getHours()+'.'+new Date().getMinutes()+'.'+new Date().getSeconds()+ EXCEL_EXTENSION);
    })

  }

}
