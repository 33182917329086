import { Component, OnInit } from '@angular/core';
import { RoleService } from "../service/role/role.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  resRole;
  resUpRole;
  resdeleterole;
  txtroleName;
  txtroleId;
  txtDescription;
  allRole;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private roleService: RoleService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllRole();
  }

  getAllRole() {
    this.roleService.getAllRole().subscribe(res => {
      this.allRole = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtroleName = '';
    this.txtDescription = '';
    this.txtroleId = null;
  }

  toUpperCaseRole(){
    const temp = this.txtroleName;
    this.txtroleName = temp.toUpperCase();
    return this.txtroleName;
  }

  createRole() {
    if (this.txtroleName == null || this.txtroleName == '' || this.txtroleName == undefined) {
      this.toastr.error("Role name is required");
      // } else if (this.txtDescription == null || this.txtDescription == '' || this.txtDescription == undefined) {
      //   this.toastr.error("description is required");
    } else {
      if (this.txtroleId == null) {
        this.roleService.createRole(this.txtroleName, this.txtDescription)
          .subscribe(res => {
            this.resRole = res;
            console.log(this.resRole);
            this.clearData();
            this.getAllRole();
            this.toastr.success('Role save successfully');
          }, error => {
            // this.toastr.error('Role not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.roleService.updateRole(this.txtroleId, this.txtroleName, this.txtDescription).subscribe(res => {
          this.resUpRole = res;
          this.clearData();
          this.getAllRole();
          this.toastr.success('Role updated successfully');
        }, error => {
          // this.toastr.error('Role not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateRole(role) {
    this.btnName = 'Update';
    this.txtroleId = role.roleid;
    this.txtroleName = role.name;
    this.txtDescription = role.description;
  }

  deleteid;
  deleteRole(role) {
    this.deleteid = role.roleid;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.roleService.deleteRole(this.deleteid)
        .subscribe(res => {
          this.resdeleterole = res;
          console.log(this.resdeleterole);
          this.clearData();
          this.getAllRole();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Role deleted Successfully');
        }, error => {
          this.toastr.error('Role not deleted');
        })
    }
  }
}
