<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Party Type Report
                            <span style="float: right;">Total Party : {{totalParty | appendIND}}</span>
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6 bottom_margin">
                                    <button class="btn btn-primary d-block" type="submit"
                                        (click)="exportAsXLSX()">Export To Excel</button>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <div class="col-lg-10 col-xl-10 col-8 position-relative">
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="search" name="search"
                                                placeholder="Search Address"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus"/>
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.Address}}
                                                </div>
                                            </ng-template>
                                        </div>
                                        <!-- <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input"/> -->
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                            <button class="btn btn-outline-secondary border-0" type="button" (click)="searchBoxClear()">
                                                <i class="btn-close"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Party Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Cell Number</th>
                                            <th scope="col">Type</th>
                                            <!-- <th scope="col">Opening Balance(Rs.)</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dtl of allPartyByType | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{dtl.partyName}}</td>
                                            <td>{{dtl.address}}</td>
                                            <td>{{dtl.cellNumber}}</td>
                                            <td>{{dtl.type}}</td>
                                            <!-- <td>{{dtl.openingBalance | appendIND}}</td> -->
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allPartyByType < 1 ">
                                            <td colspan="5" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>