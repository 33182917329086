import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class StockAdjustmentService {

  constructor(
    private http: Http,
  ) { }
  createStockAdjustment(txtItemName: string,txtBatchNumber: string,txtquantity: string,txtRate:number,txtManufactureDate:string,txtExpiryDate:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemName: txtItemName,
      batchNumber: txtBatchNumber,
      quantity: txtquantity,
      rate:txtRate,
      manufactureDate: txtManufactureDate,
      expiryDate: txtExpiryDate,
    });
    return this.http.post('/api/create/stock/adjustment', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  updateStockAdjustment(txtStockAdjustmentId: string, txtItemName: string,txtBatchNumber: string,txtquantity: string,txtManufactureDate:string,txtExpiryDate:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      subCategoryName : txtStockAdjustmentId,
      categoryName : txtItemName,
      subcategoryid: txtBatchNumber,
      subcategoryids: txtquantity,
      subcategoryidd: txtManufactureDate,
      subcategoryidx: txtExpiryDate,
    });
    return this.http.put('/api/update/subcategory', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllStockAdjustment() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/stock/adjustment', { headers })
      .pipe(map(res => res.json()));
  }

  deleteStockAdjustment(subcategoryid: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      subcategoryid: subcategoryid
    });
    return this.http.put('/api/delete/subcategory', body, { headers })
      .pipe(map(res => res.json()));
  }

  getStockAdjustmentByItemId(itemid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/stock/adjustment/batchno/byitem/' + itemid, { headers })
      .pipe(map(res => res.json()));
  }

  itemAndBatchNumberValue(itemid:number,batchNumber:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/stock/adjustment/batchno/byitem/' + itemid +'/batchnumber/'+batchNumber, { headers })
      .pipe(map(res => res.json()));
  }

  getStockAdjustMentDataByItemName(txtItemName:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemName : txtItemName
    });

    return this.http.put('/api/getall/stock/adjustment/itemname', body,{ headers })
      .pipe(map(res => res.json()));
  }

  importExcelStockAdjustment(resStockAdjustment){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.post('/api/create/list/stock', resStockAdjustment, { headers })
      .pipe(map(res => res));
  }
}
