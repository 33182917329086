<div class="body-container login-container">
<div class="container d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column justify-content-between">
        <div class="card mt-3 p-5">
            <div class="logo p-1 pb-3 mb-4">
                <img src="assets/images/loginLogo.png" alt="" class="img-fluid" style="height: 70px !important; margin-left: 5rem !important;"/>
            </div>
        </div>
        <div class="card two bg-white px-5 py-4 mb-3">
            <div class="form-group mb-3">
                <label class="form-control-placeholder" for="username">Username<span style="color: red;">&nbsp;*</span></label>
                <input type="text" class="form-control" id="username" required [(ngModel)]="txtusername" name="txtusername"/>
            </div>
            <div class="form-group mb-3">
                <label class="form-control-placeholder" for="pass">Password<span style="color: red;">&nbsp;*</span></label>
                <input type="password" class="form-control" id="pass" required [(ngModel)]="txtpass" name="txtpass"/>
            </div>
            <!-- <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" value="" id="remember" />
                <label class="form-check-label" for="remember"> Remember me </label>
            </div> -->
            <button type="button" class="btn btn-primary btn-block mt-1 mb-3" (click)="loginUser()">
                <span>Login<i class="fas fa-long-arrow-alt-right ms-2"></i></span>
            </button>
            <!-- <a (click)="loginUser()" class="btn btn-primary btn-block mt-1 mb-3">
                <span>Login <i class="fas fa-long-arrow-alt-right ms-2"></i></span>
            </a> -->

            <div class="form-group text-center mb-2">
                <a class="pwd-forget" href="javascript:void(0)" data-bs-toggle="modal"
                    data-bs-target="#exampleModal">Forgot Password</a>
            </div>
            <!-- <div class="text-center">
                Not registered yet? <a href="register.html">Sign up</a>
            </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Forgot password?</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearForgetPassword();"></button>
            </div>
            <div class="modal-body">
                <p>You can reset your password here.</p>
                <div class="mb-3">
                    <label class="form-control-placeholder" for="username">Username<span style="color: red;">&nbsp;*</span></label>
                    <input type="text" class="form-control" id="username" required [(ngModel)]="txtForgotUsername" name="txtForgotUsername"
                    placeholder="Enter User Name" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearForgetPassword();">
                    Close
                </button>
                <button type="button" class="btn btn-primary" (click)="forgetPassword();">
                    Send my password
                </button>
            </div>
        </div>
    </div>
</div>
</div>