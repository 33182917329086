<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Company Master</h2> -->

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Company Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="first" class="form-label">Company Name<span style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtCompanyId"
                                                name="txtCompanyId">
                                            <input type="text" class="form-control" id="Brand" placeholder="Enter Company"
                                                name="txtCompany" [(ngModel)]="txtCompany" required="required" (focusout)="toUpperCaseCompany()"/>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">GST Number<span style="color: red;">&nbsp;</span></label>
                                            <input type="text" class="form-control" id="gst" placeholder="Enter GST Number" maxlength="15"
                                                name="txtGSTNumber" [(ngModel)]="txtGSTNumber" required="required"/>
                                            <div class="invalid-feedback">
                                                Please enter GST Number.
                                            </div>
                                        </div>
                                        <div class="row g-3" *ngIf="isEnable">
                                            <div class="col-md-10 col-sm-8 col-8">
                                                <div class="form-group">
                                                    <!-- <label style="opacity:0;">Image</label> -->
                                                    <label for="formFile" class="form-label">Image</label>
                                                    <input class="form-control" type="file" id="formFile" name="txtImage"
                                                    [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                                <img [src]="imagePreview" height="50px" width="50px">
                                            </div>
                                        </div>
                                        <div class="row g-3 mb-3">
                                            <div class="col-md-10 col-sm-8 col-8">
                                                    <div class="form-group">
                                                        <label for="QRImage" class="form-label">QR Code</label>
                                                        <input type="file" class="form-control" id="QRImage"
                                                            name="txtQRImage" [(ngModel)]="txtQRImage" accept="image/*"
                                                            (change)="onQRUpload($event)">
                                                    </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                                <img [src]="QRPreview" height="50px" width="50px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createCompany()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Company</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                                id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Company Name</th>
                                                <th scope="col">GST Number</th>
                                                <th class="text-center">Image</th>
                                                <th class="text-center">QR Code</th>
                                                <th scope="col" style="min-width: 90px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let company of allCompany | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{company.companyName}}</td>
                                                <td>{{company.gstNumber}}</td>
                                                <td class="text-center"><img [src]="company.image" height="50px" width="50px">
                                                <td class="text-center"><img [src]="company.qr" height="50px" width="50px">
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateCompany(company)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteCompany(company)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button> -->
                                                    <!-- <button type="button" class="btn btn-primary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Image"
                                                         data-bs-toggle="modal" (click)="updateCompany(company)"
                                                        data-bs-target="#UploadImageModal">
                                                        <i class="fas fa-image fa-lg"></i>
                                                    </button> -->
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allCompany < 1">
                                                <td colspan="5" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                responsive="true"></pagination-controls>
                                            </li>
                                            <!-- <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li class="page-item active" aria-current="page">
                                                <a class="page-link" href="#">1</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">2</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">3</a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="UploadImageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload Image</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearData()"></button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <div class="portlet">
                        <form class="needs-validation" novalidate="">
                            <div class="portlet-content">
                                <div class="row g-3">
                                    <div class="col-md-10 col-sm-8 col-8">
                                        <div class="form-group">
                                            <label for="formFile" class="form-label">Image</label>
                                            <input class="form-control" type="file" id="formFile" name="txtImage"
                                            [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                        <img [src]="imagePreview" height="50px" width="50px">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="createCompany()">{{btnName}}</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearData()">Cancel</button>
            </div>
        </div>
    </div>
</div> -->