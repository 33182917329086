import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GeneralInformationService } from '../service/general-information/general-information.service';
import { PartyService } from '../service/party/party.service';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.css']
})
export class GeneralInformationComponent implements OnInit {

  btnName ='save';
  txtEmail;
  txtBankName;
  txtAccountNumber;
  txtIfscCode;
  txtBranchName;
  txtTermsNcondition;
  txtGeneralId;
  allGeneral;
  resGeneral;
  search;
  page: number = 1;
  isGeneralData = false;
  txtCompany;
  allCompany;

  constructor(
    private toastr : ToastrService,
    private generalinformationService : GeneralInformationService,
    private partyService: PartyService,
  ) { }
  ngOnInit(): void {
    this.getAllCompany();
    this.getAllGeneralInformation();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
    })
  }
  
  getAllGeneralInformation() {
    this.generalinformationService.getAllGeneralInformation().subscribe(res => {
      this.allGeneral = res;
      // if(res == null || res == [] || res.length == 0) {
      //   this.isGeneralData = false;
      // } else {
      //   this.isGeneralData = true;
      // }
      if(res.length != this.allCompany.length) {
        this.isGeneralData = false;
      } else {
        this.isGeneralData = true;
      }
    },error =>{
      this.isGeneralData = false;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.isGeneralData = true;
    this.txtEmail = null;
    this.txtBankName = null;
    this.txtAccountNumber = null;
    this.txtIfscCode = null;
    this.txtBranchName = null;
    this.txtTermsNcondition = null;
    this.txtCompany = null;
    this.txtGeneralId = null;
  }

  createGeneralInformation() {
    if (this.txtCompany == "" || this.txtCompany == null || this.txtCompany == undefined || this.txtCompany == '-:Select Company Name:-') {
      this.toastr.error("Company name must be required");
    } else if (this.txtEmail == null || this.txtEmail == '' || this.txtEmail == undefined || this.txtEmail.trim() =='') {
      this.toastr.error("email is required");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");  
    } else if (this.txtBankName == null || this.txtBankName == '' || this.txtBankName == undefined || this.txtBankName.trim() =='') {
      this.toastr.error("bank name is required");
    } else if (this.txtAccountNumber == null || this.txtAccountNumber == '' || this.txtAccountNumber == undefined || this.txtBranchName.trim() =='') {
      this.toastr.error("account number is required");
    } else if (this.txtIfscCode == null || this.txtIfscCode == '' || this.txtIfscCode == undefined || this.txtIfscCode.trim() =='') {
      this.toastr.error("ifsc code is required");
    } else if (this.txtBranchName == null || this.txtBranchName == '' || this.txtBranchName == undefined || this.txtBranchName.trim() =='') {
      this.toastr.error("branch name is required");
    // } else if (this.txtTermsNcondition == null || this.txtTermsNcondition == '' || this.txtTermsNcondition == undefined || this.txtTermsNcondition.trim() =='') {
    //   this.toastr.error("terms & condition is required");
    } else {
      if (this.txtGeneralId == null) {
        this.generalinformationService.createGeneralInformation(this.txtCompany, this.txtEmail, this.txtBankName, this.txtAccountNumber, this.txtIfscCode, this.txtBranchName, this.txtTermsNcondition)
          .subscribe(res => {
            this.resGeneral = res;
            console.log(this.resGeneral);
            this.clearData();
            this.getAllGeneralInformation();
            this.toastr.success('detail save successfully');
          }, error => {
            // this.toastr.error('detail not saved');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));

          })
      } else {
        this.generalinformationService.updateGeneralInformation(this.txtGeneralId, this.txtCompany, this.txtEmail, this.txtBankName, this.txtAccountNumber, this.txtIfscCode, this.txtBranchName, this.txtTermsNcondition).subscribe(res => {
          this.resGeneral = res;
          this.clearData();
          this.getAllGeneralInformation();
          this.toastr.success('detail updated successfully');
        }, error => {
          // this.toastr.error('detail not update');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updategeneral(general) {
    this.btnName = 'Update';
    this.isGeneralData = false;
    this.txtGeneralId = general.id;
    this.txtCompany = general.companyId;
    this.txtEmail = general.email;
    this.txtBankName = general.bankName;
    this.txtAccountNumber = general.accountNumber;
    this.txtIfscCode = general.ifscCode;
    this.txtBranchName = general.branch;
    this.txtTermsNcondition = general.termsNcondition;
  }
}
