import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { PlanService } from '../service/plan/plan.service';
declare var $: any;

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {
  txtPlanId;
  txtPlanName;
  txtNoOfUser;
  txtNoOfLocation;
  txtNoOfOrganization;
  txtNoOfUserLimit;
  txtNoOfLocationLimit;
  txtNoOfOrganizationLimit;
  txtPeriodTime;
  txtPlanPrice;
  allPlan;
  resPlan;
  resUpPlan;
  resDelPlan;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private planService: PlanService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllPlan();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  toUpperPlan() {
    const temp = this.txtPlanName;
    this.txtPlanName = temp.toUpperCase();
    return this.txtPlanName;
  }

  getAllPlan() {
    this.planService.gatAllPlan().subscribe(res => {
      this.allPlan = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtPlanName = null;
    this.txtNoOfUser = null;
    this.txtNoOfLocation = null;
    this.txtNoOfOrganization = null;
    this.txtNoOfUserLimit = null;
    this.txtNoOfLocationLimit = null;
    this.txtNoOfOrganizationLimit = null;
    this.txtPlanId = null;
    this.txtPlanPrice = null;
    this.txtPeriodTime = null;
  }

  createPlan() {
    var planname = this.txtPlanName;
    if (this.txtPlanName == null || this.txtPlanName == '' || this.txtPlanName == undefined || (this.txtPlanName = planname.trim()) == "") {
      this.toastr.error("Plan name is required");
    } else if (this.txtNoOfUser == null || this.txtNoOfUser == '' || this.txtNoOfUser == undefined || this.txtNoOfUser == 0) {
      this.toastr.error("Number of user is required");
    } else if (this.txtNoOfLocation == null || this.txtNoOfLocation == '' || this.txtNoOfLocation == undefined || this.txtNoOfLocation == 0) {
      this.toastr.error("Number of location is required");
    } else if (this.txtNoOfOrganization == null || this.txtNoOfOrganization == '' || this.txtNoOfOrganization == undefined || this.txtNoOfOrganization == 0) {
      this.toastr.error("Number of organization is required");
    // } else if (this.txtNoOfUserLimit == null || this.txtNoOfUserLimit == '' || this.txtNoOfUserLimit == undefined) {
    //   this.toastr.error("Number of user limit is required");
    // } else if (this.txtNoOfLocationLimit == null || this.txtNoOfLocationLimit == '' || this.txtNoOfLocationLimit == undefined) {
    //   this.toastr.error("Number of location limit is required");
    // } else if (this.txtNoOfOrganizationLimit == null || this.txtNoOfOrganizationLimit == '' || this.txtNoOfOrganizationLimit == undefined) {
    //   this.toastr.error("Number of organization limit is required");
    // } else if (this.txtPeriodTime == null || this.txtPeriodTime == '' || this.txtPeriodTime == undefined || this.txtPeriodTime == 0) {
    //   this.toastr.error("Period time is required");
    // } else if (this.txtPlanPrice == null || this.txtPlanPrice == '' || this.txtPlanPrice == undefined || this.txtPlanPrice == 0) {
    //   this.toastr.error("Plan price is required");
    } else if (this.txtNoOfLocation > this.txtNoOfUser) {
      this.toastr.error("No of location is more than no of user");
    } else {
      if(this.txtNoOfLocationLimit == null || this.txtNoOfLocationLimit == undefined || this.txtNoOfLocationLimit == '') {
        this.txtNoOfLocationLimit = 0;
      }
      if(this.txtNoOfUserLimit == null ||this.txtNoOfUserLimit == undefined || this.txtNoOfUserLimit == '') {
        this.txtNoOfUserLimit = 0;
      }
      if (this.txtPlanId == null) {
        this.planService.createPlan(this.txtPlanName, this.txtNoOfUser, this.txtNoOfLocation, this.txtNoOfOrganization, this.txtNoOfUserLimit, this.txtNoOfLocationLimit, this.txtNoOfOrganizationLimit,this.txtPlanPrice, this.txtPeriodTime)
          .subscribe(res => {
            this.resPlan = res;
            this.clearData();
            this.getAllPlan();
            this.toastr.success('Plan save successfully');
          }, error => {
            // this.toastr.error('Plan not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));

          })
      } else {
        this.planService.updatePlan(this.txtPlanId, this.txtPlanName, this.txtNoOfUser, this.txtNoOfLocation, this.txtNoOfOrganization, this.txtNoOfUserLimit, this.txtNoOfLocationLimit, this.txtNoOfOrganizationLimit,this.txtPlanPrice, this.txtPeriodTime).subscribe(res => {
          this.resUpPlan = res;
          this.clearData();
          this.getAllPlan();
          this.toastr.success('Plan updated successfully');
        }, error => {
          // this.toastr.error('Plan not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatePlan(plan) {
    this.btnName = 'Update';
    this.txtPlanId = plan.planId;
    this.txtPlanName = plan.planName;
    this.txtNoOfUser = plan.numberOfUser;
    this.txtNoOfLocation = plan.numberOfLocation;
    this.txtNoOfOrganization = plan.numberOfOrganization;
    this.txtNoOfUserLimit = plan.userLimit;
    this.txtNoOfLocationLimit = plan.locationLimit;
    this.txtNoOfOrganizationLimit = plan.organizationLimit;
    this.txtPlanPrice = plan.price;
    this.txtPeriodTime = plan.periodTime;
  }

  deleteid;
  deletePlan(plan) {
    this.deleteid = plan.planId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.planService.deletePlan(this.deleteid).subscribe(res => {
        this.resDelPlan = res;
        this.clearData();
        this.getAllPlan();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Plan deleted successfully');
      }, error => {
        // this.toastr.error('Plan not deleted');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }
}
