import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  constructor(
    private http: Http,
  ) { }

  getAllOrderHistory(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/user/orderhistory', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Assets By Id
  getAllOrderHistoryById(txtId: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/get/orderbyid/' + txtId, { headers: headers })
      .pipe(map(res => res.json()));
  }

}
