import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BalancesheetService } from '../service/balancesheet/balancesheet.service';
import { CompanyService } from '../service/company/company.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-trial-balance',
  templateUrl: './trial-balance.component.html',
  styleUrls: ['./trial-balance.component.css']
})
export class TrialBalanceComponent implements OnInit {

  datePipe = new DatePipe("en-US");
  txtStartDate;
  txtEndDate;

  constructor(
    private balancesheetservice: BalancesheetService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this.txtEndDate = new Date();
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    this.txtStartDate = new Date();
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    // this.getAllTrialBalance();
    this.getAllCompany();
  }

  trialbalance: any;
  alltrialbalance: any;
  totalAmount: any;
  search: any;
  page: number = 1;
  page1: number = 1;
  allCompany;
  txtCompany;
  config = {
    itemsPerPage: 10,
    currentPage: this.page,
  };

  config1 = {
    itemsPerPage: 10,
    currentPage: this.page1,
  };

  searchData() {
    if (this.search == null || this.search == undefined || this.search == "" || this.search.trim() == "") {
      this.trialbalance = this.alltrialbalance;
    } else {
      let term = this.search;
      this.trialbalance = this.alltrialbalance.filter(function (tag) {
        return (tag.productname).toLowerCase().includes(term.toLowerCase());
      });
    }
  }

  totalDebitAmount = 0;
  totalCreditAmount = 0;
  getAllTrialBalance() {
    if (this.txtStartDate == "" || this.txtStartDate == null || this.txtStartDate == undefined) {
      this.toastr.error("Start date is required");
    } else if (this.txtEndDate == "" || this.txtEndDate == null || this.txtEndDate == undefined) {
      this.toastr.error("End date is required");
    } else if(this.txtCompany == null || this.txtCompany == "" || this.txtCompany == undefined){
      this.toastr.error("Company is required")
    } else {
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
      this.totalDebitAmount = 0;
      this.totalCreditAmount = 0;
      this.balancesheetservice.getAllTrialBalance(this.txtStartDate,this.txtEndDate,this.txtCompany).subscribe(res => {
        this.alltrialbalance = res;
        this.trialbalance = res;
        for (let t of res) {
          this.totalDebitAmount = this.totalDebitAmount + t.debit;
          this.totalCreditAmount = this.totalCreditAmount + t.credit;
        }
      })
    }
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      // console.log(this.allCompany);
    })
  }

  allDetailData;
  groupName;
  getTrialBalanceDetail(detail) {
    // console.log(detail);
    this.allDetailData = [];
    this.groupName = detail.groupName;
    if (this.txtStartDate == "" || this.txtStartDate == null || this.txtStartDate == undefined) {
      this.toastr.error("Start date is required");
    } else if (this.txtEndDate == "" || this.txtEndDate == null || this.txtEndDate == undefined) {
      this.toastr.error("End date is required");
    } else {
      this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
      this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
      this.balancesheetservice.getTrialBalanceDetail(this.txtStartDate,this.txtEndDate,detail.glaAccountId,this.txtCompany).subscribe(res => {
        this.allDetailData = res;
      })
    } 
  }

}
