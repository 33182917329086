<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="portlet">
                            <div class="portlet-header">Purchase Plan Detail</div>
                            <div class="portlet-content">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="Pending-tab" data-bs-toggle="tab"
                                            data-bs-target="#Pending" type="button" role="tab" aria-controls="Pending"
                                            aria-selected="true" (click)="searchStatusWiseData('PENDING')">Pending</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="Received-tab" data-bs-toggle="tab"
                                            data-bs-target="#Received" type="button" role="tab"
                                            aria-controls="Received" aria-selected="false" (click)="searchStatusWiseData('RECEIVED')">Received</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="deactiveted-tab" data-bs-toggle="tab"
                                            data-bs-target="#deactiveted" type="button" role="tab" aria-controls="deactiveted"
                                            aria-selected="true" (click)="searchStatusWiseData('DEACTIVE')">Deactiveted</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="onlinePending-tab" data-bs-toggle="tab"
                                            data-bs-target="#onlinePending" type="button" role="tab" aria-controls="onlinePending"
                                            aria-selected="true" (click)="searchStatusWiseData('ONLINEPENDING')">Online Pending</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="PaymentFailed-tab" data-bs-toggle="tab"
                                            data-bs-target="#PaymentFailed" type="button" role="tab"
                                            aria-controls="PaymentFailed" aria-selected="false" (click)="searchStatusWiseData('PAYMENTFAILED')">Payment Failed</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Pending" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchPending" [(ngModel)]="searchPending"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Purchase #</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Owner</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <!-- <th scope="col">Contact Person</th> -->
                                                        <!-- <th scope="col">Contact Person Mobile</th> -->
                                                        <!-- <th scope="col">Address</th>
                                                        <th scope="col">Email</th> -->
                                                        <th scope="col">GST Number</th>
                                                        <th scope="col">Company Name</th>
                                                        <!-- <th scope="col">Extra User</th> -->
                                                        <!-- <th scope="col">Extra Location</th> -->
                                                        <!-- <th scope="col">Bank Name</th> -->
                                                        <!-- <th scope="col">Cheque No.</th> -->
                                                        <th class="text-center" scope="col">Final Amount</th>
                                                        <!-- <th scope="col">Active Date</th> -->
                                                        <!-- <th scope="col">End Date</th> -->
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let plan of allStatusWiseData | filter:searchPending | paginate: { itemsPerPage: 10, currentPage: pagePending }">
                                                        <td class="text-success me-2" data-bs-toggle="modal" role="button" title="History"
                                                        (click)="getSubPurchaseDetail(plan)" data-bs-target="#PurchasedetailModal" aria-hidden="true">
                                                        {{plan.planPurchaseNumber}}</td>
                                                        <td>{{plan.plan.planName}}</td>
                                                        <td>{{plan.ownerName}}</td>
                                                        <td>{{plan.ownerMobileNumber}}</td>
                                                        <!-- <td>{{plan.contactPerson}}</td> -->
                                                        <!-- <td>{{plan.contactPersonMobile}}</td> -->
                                                        <!-- <td>{{plan.address}}</td>
                                                        <td>{{plan.email}}</td> -->
                                                        <td>{{plan.gstNumber}}</td>
                                                        <td>{{plan.organizationName}}</td>
                                                        <!-- <td>{{plan.extraUser != null ? (plan.extraUser.planUserLimit +' User - Rs.'+ plan.extraUser.amount): ''}}</td> -->
                                                        <!-- <td>{{plan.extraLocation != null ? (plan.extraLocation.planLocationLimit +' User - Rs.'+ plan.extraLocation.amount) : ''}}</td> -->
                                                        <!-- <td>{{plan.bankName}}</td> -->
                                                        <!-- <td>{{plan.chequeNo}}</td> -->
                                                        <td class="text-end">{{plan.finalAmount | appendIND}}</td>
                                                        <!-- <td>{{plan.activeDate}}</td> -->
                                                        <!-- <td>{{plan.endDate}}</td> -->
                                                        <td class="text-center">
                                                            <!-- <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button> -->
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Payment"
                                                                 data-bs-toggle="modal" data-bs-target="#pendingToReceiveModal" (click)="getPurchaseDetail(plan)">
                                                                Payment
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allStatusWiseData < 1">
                                                        <td colspan="11" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="pagePending = $event"
                                                            (pageBoundsCorrection)="pagePending=($event)">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="Received" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchReceived"
                                                        [(ngModel)]="searchReceived" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Purchase #</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Owner</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <!-- <th scope="col">Contact Person</th> -->
                                                        <!-- <th scope="col">Contact Person Mobile</th> -->
                                                        <!-- <th scope="col">Address</th>
                                                        <th scope="col">Email</th> -->
                                                        <th scope="col">GST Number</th>
                                                        <th scope="col">Company Name</th>
                                                        <!-- <th scope="col">Extra User</th> -->
                                                        <!-- <th scope="col">Extra Location</th> -->
                                                        <!-- <th scope="col">Bank Name</th> -->
                                                        <!-- <th scope="col">Cheque No.</th> -->
                                                        <th class="text-center" scope="col">Final Amount</th>
                                                        <!-- <th scope="col">Active Date</th> -->
                                                        <!-- <th scope="col">End Date</th> -->
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Active Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col" style="min-width: 150px;">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let plan of allStatusWiseData | filter:searchReceived | paginate: { itemsPerPage: 10, currentPage: pageReceived }">
                                                        <td class="text-success me-2" data-bs-toggle="modal" role="button" title="History"
                                                        (click)="getSubPurchaseDetail(plan)" data-bs-target="#PurchasedetailModal" aria-hidden="true">
                                                        {{plan.planPurchaseNumber}}</td>
                                                        <td>{{plan.plan.planName}}</td>
                                                        <td>{{plan.ownerName}}</td>
                                                        <td>{{plan.ownerMobileNumber}}</td>
                                                        <!-- <td>{{plan.contactPerson}}</td> -->
                                                        <!-- <td>{{plan.contactPersonMobile}}</td> -->
                                                        <!-- <td>{{plan.address}}</td>
                                                        <td>{{plan.email}}</td> -->
                                                        <td>{{plan.gstNumber}}</td>
                                                        <td>{{plan.organizationName}}</td>
                                                        <!-- <td>{{plan.extraUser != null ? (plan.extraUser.planUserLimit +' User - Rs.'+ plan.extraUser.amount): ''}}</td> -->
                                                        <!-- <td>{{plan.extraLocation != null ? (plan.extraLocation.planLocationLimit +' User - Rs.'+ plan.extraLocation.amount) : ''}}</td> -->
                                                        <!-- <td>{{plan.bankName}}</td> -->
                                                        <!-- <td>{{plan.chequeNo}}</td> -->
                                                        <td class="text-end">{{plan.finalAmount | appendIND}}</td>
                                                        <!-- <td>{{plan.activeDate}}</td> -->
                                                        <!-- <td>{{plan.endDate}}</td> -->
                                                        <td>{{plan.type}}</td>
                                                        <td><span *ngIf="plan.activeUser == true">{{plan.activeDate | date:'dd-MMM-yyyy'}}</span></td>
                                                        <td><span *ngIf="plan.activeUser == true">{{plan.endDate | date:'dd-MMM-yyyy'}}</span></td>
                                                        <td class="text-start">
                                                            <!-- <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button> -->
                                                            <mat-slide-toggle [(ngModel)]="plan.activeUser" (change)="checkDisableUser(plan)" [color]="color"></mat-slide-toggle>
                                                            <button *ngIf="plan.activeUser == true" type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
                                                                (click)="getPlanPurchsePDF(plan)" style="margin-left: 5px; margin-bottom: 5px;">
                                                                <i class="fas fa-print"></i>
                                                            </button>
                                                            <button *ngIf="plan.activeUser == true" type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                                                (click)="getsendEmailData(plan)" data-bs-toggle="modal"
                                                                data-bs-target="#emailModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                                <i class="fas fa-envelope"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allStatusWiseData < 1">
                                                        <td colspan="11" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="pageReceived = $event"
                                                            (pageBoundsCorrection)="pageReceived=($event)">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="deactiveted" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchdeactiveted" [(ngModel)]="searchdeactiveted"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Purchase #</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Owner</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <!-- <th scope="col">Contact Person</th> -->
                                                        <!-- <th scope="col">Contact Person Mobile</th> -->
                                                        <!-- <th scope="col">Address</th>
                                                        <th scope="col">Email</th> -->
                                                        <th scope="col">GST Number</th>
                                                        <th scope="col">Company Name</th>
                                                        <!-- <th scope="col">Extra User</th> -->
                                                        <!-- <th scope="col">Extra Location</th> -->
                                                        <!-- <th scope="col">Bank Name</th> -->
                                                        <!-- <th scope="col">Cheque No.</th> -->
                                                        <th class="text-center"  scope="col">Final Amount</th>
                                                        <!-- <th scope="col">Active Date</th> -->
                                                        <!-- <th scope="col">End Date</th> -->
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let plan of allStatusWiseData | filter:searchdeactiveted | paginate: { itemsPerPage: 10, currentPage: pagedeactiveted }">
                                                        <td class="text-success me-2" data-bs-toggle="modal" role="button" title="History"
                                                        (click)="getSubPurchaseDetail(plan)" data-bs-target="#PurchasedetailModal" aria-hidden="true">
                                                        {{plan.planPurchaseNumber}}</td>
                                                        <td>{{plan.plan.planName}}</td>
                                                        <td>{{plan.ownerName}}</td>
                                                        <td>{{plan.ownerMobileNumber}}</td>
                                                        <!-- <td>{{plan.contactPerson}}</td> -->
                                                        <!-- <td>{{plan.contactPersonMobile}}</td> -->
                                                        <!-- <td>{{plan.address}}</td>
                                                        <td>{{plan.email}}</td> -->
                                                        <td>{{plan.gstNumber}}</td>
                                                        <td>{{plan.organizationName}}</td>
                                                        <!-- <td>{{plan.extraUser != null ? (plan.extraUser.planUserLimit +' User - Rs.'+ plan.extraUser.amount): ''}}</td> -->
                                                        <!-- <td>{{plan.extraLocation != null ? (plan.extraLocation.planLocationLimit +' User - Rs.'+ plan.extraLocation.amount) : ''}}</td> -->
                                                        <!-- <td>{{plan.bankName}}</td> -->
                                                        <!-- <td>{{plan.chequeNo}}</td> -->
                                                        <td class="text-end">{{plan.finalAmount | appendIND}}</td>
                                                        <!-- <td>{{plan.activeDate}}</td> -->
                                                        <!-- <td>{{plan.endDate}}</td> -->
                                                        <td class="text-center">
                                                            <!-- <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button> -->
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Payment"
                                                                 data-bs-toggle="modal" data-bs-target="#pendingToReceiveModal" (click)="getPurchaseDetail(plan)">
                                                                Payment
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allStatusWiseData < 1">
                                                        <td colspan="11" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="pagedeactiveted = $event"
                                                            (pageBoundsCorrection)="pagedeactiveted=($event)">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="onlinePending" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchOnlinePending" [(ngModel)]="searchOnlinePending"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Purchase #</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Owner</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <!-- <th scope="col">Contact Person</th> -->
                                                        <!-- <th scope="col">Contact Person Mobile</th> -->
                                                        <!-- <th scope="col">Address</th>
                                                        <th scope="col">Email</th> -->
                                                        <th scope="col">GST Number</th>
                                                        <th scope="col">Company Name</th>
                                                        <!-- <th scope="col">Extra User</th> -->
                                                        <!-- <th scope="col">Extra Location</th> -->
                                                        <!-- <th scope="col">Bank Name</th> -->
                                                        <!-- <th scope="col">Cheque No.</th> -->
                                                        <th class="text-center"  scope="col">Final Amount</th>
                                                        <!-- <th scope="col">Active Date</th> -->
                                                        <!-- <th scope="col">End Date</th> -->
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let plan of allStatusWiseData | filter:searchOnlinePending | paginate: { itemsPerPage: 10, currentPage: pageOnlinePending }">
                                                        <td class="text-success me-2" data-bs-toggle="modal" role="button" title="History"
                                                            (click)="getSubPurchaseDetail(plan)" data-bs-target="#PurchasedetailModal" aria-hidden="true">
                                                            {{plan.planPurchaseNumber}}</td>
                                                        <td>{{plan.plan.planName}}</td>
                                                        <td>{{plan.ownerName}}</td>
                                                        <td>{{plan.ownerMobileNumber}}</td>
                                                        <!-- <td>{{plan.contactPerson}}</td> -->
                                                        <!-- <td>{{plan.contactPersonMobile}}</td> -->
                                                        <!-- <td>{{plan.address}}</td>
                                                        <td>{{plan.email}}</td> -->
                                                        <td>{{plan.gstNumber}}</td>
                                                        <td>{{plan.organizationName}}</td>
                                                        <!-- <td>{{plan.extraUser != null ? (plan.extraUser.planUserLimit +' User - Rs.'+ plan.extraUser.amount): ''}}</td> -->
                                                        <!-- <td>{{plan.extraLocation != null ? (plan.extraLocation.planLocationLimit +' User - Rs.'+ plan.extraLocation.amount) : ''}}</td> -->
                                                        <!-- <td>{{plan.bankName}}</td> -->
                                                        <!-- <td>{{plan.chequeNo}}</td> -->
                                                        <td class="text-end">{{plan.finalAmount | appendIND}}</td>
                                                        <!-- <td>{{plan.activeDate}}</td> -->
                                                        <!-- <td>{{plan.endDate}}</td> -->
                                                        <td class="text-center">
                                                            <a class="text-success me-2" title="Received" role="button" data-bs-toggle="modal" data-bs-target="#acceptModal"
                                                            (click)="getAcceptRejectDetail(plan)"><i class="fas fa-check-square" style='font-size:20px;color:green' aria-hidden="true"></i></a>
                                                            <a class="text-success me-2" title="Failed" role="button" data-bs-toggle="modal" data-bs-target="#rejectModal"
                                                            (click)="getAcceptRejectDetail(plan)"><i class="fas fa-window-close" style='font-size:20px;color:red' aria-hidden="true"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allStatusWiseData < 1">
                                                        <td colspan="11" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="pageOnlinePending = $event"
                                                            (pageBoundsCorrection)="pageOnlinePending=($event)">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="PaymentFailed" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchPaymentFailed"
                                                        [(ngModel)]="searchPaymentFailed" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Purchase #</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Owner</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <!-- <th scope="col">Contact Person</th> -->
                                                        <!-- <th scope="col">Contact Person Mobile</th> -->
                                                        <!-- <th scope="col">Address</th>
                                                        <th scope="col">Email</th> -->
                                                        <th scope="col">GST Number</th>
                                                        <th scope="col">Company Name</th>
                                                        <!-- <th scope="col">Extra User</th> -->
                                                        <!-- <th scope="col">Extra Location</th> -->
                                                        <!-- <th scope="col">Bank Name</th> -->
                                                        <!-- <th scope="col">Cheque No.</th> -->
                                                        <th class="text-center"  scope="col">Final Amount</th>
                                                        <!-- <th scope="col">Active Date</th> -->
                                                        <!-- <th scope="col">End Date</th> -->
                                                        <!-- <th scope="col">Actions</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let plan of allStatusWiseData | filter:searchPaymentFailed | paginate: { itemsPerPage: 10, currentPage: pagePaymentFailed }">
                                                        <td class="text-success me-2" data-bs-toggle="modal" role="button" title="History"
                                                        (click)="getSubPurchaseDetail(plan)" data-bs-target="#PurchasedetailModal" aria-hidden="true">
                                                        {{plan.planPurchaseNumber}}</td>
                                                        <td>{{plan.plan.planName}}</td>
                                                        <td>{{plan.ownerName}}</td>
                                                        <td>{{plan.ownerMobileNumber}}</td>
                                                        <!-- <td>{{plan.contactPerson}}</td> -->
                                                        <!-- <td>{{plan.contactPersonMobile}}</td> -->
                                                        <!-- <td>{{plan.address}}</td>
                                                        <td>{{plan.email}}</td> -->
                                                        <td>{{plan.gstNumber}}</td>
                                                        <td>{{plan.organizationName}}</td>
                                                        <!-- <td>{{plan.extraUser != null ? (plan.extraUser.planUserLimit +' User - Rs.'+ plan.extraUser.amount): ''}}</td> -->
                                                        <!-- <td>{{plan.extraLocation != null ? (plan.extraLocation.planLocationLimit +' User - Rs.'+ plan.extraLocation.amount) : ''}}</td> -->
                                                        <!-- <td>{{plan.bankName}}</td> -->
                                                        <!-- <td>{{plan.chequeNo}}</td> -->
                                                        <td class="text-end">{{plan.finalAmount | appendIND}}</td>
                                                        <!-- <td>{{plan.activeDate}}</td> -->
                                                        <!-- <td>{{plan.endDate}}</td> -->
                                                        <!-- <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td> -->
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allStatusWiseData < 1">
                                                        <td colspan="11" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="pagePaymentFailed = $event"
                                                            (pageBoundsCorrection)="pagePaymentFailed=($event)">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createCategory()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="PurchasedetailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-content">
                            <div class="row mt-2 mb-1">
                                <div class="col-lg-4 col-sm-4 col-4">
                                    <a>Plan Amount : <span class="text-right">{{planAmount | appendIND}}</span></a>
                                </div>
                                <div class="col-lg-4 col-sm-4 col-4">
                                    <a>Extra Location Amount : <span>{{extraLocationAmount | appendIND}}</span></a>
                                </div>
                                <div class="col-lg-4 col-sm-4 col-4">
                                    <a>Extra User Amount : <span>{{extraUserAmount | appendIND}}</span></a>
                                </div>
                                <!-- <div class="col-lg-6 col-sm-6 col-6">
                                    <a>Amount : <span class="text-right">{{totalAmount}}</span></a><br/>
                                    <a>GST : <span>{{gstAmount}}</span></a><br/>
                                    <a>Discount : <span>{{discount}}</span></a><br/>
                                    <a>Net Amount : <span>{{netAmount}}</span></a>
                                </div> -->
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Location</th>
                                            <th scope="col">User</th>
                                            <th scope="col">Plan Duration (Year)</th>
                                            <th scope="col">Contact Person</th>
                                            <th scope="col">Contact Person Mobile</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Bank Name</th>
                                            <th scope="col">Cheque No.</th>
                                            <th scope="col">Narration</th>
                                            <!-- <th scope="col">Active Date</th> -->
                                            <!-- <th scope="col">End Date</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr *ngFor="let detail of resAllsubExpiryDateDetail"> -->
                                            <td class="text-center">{{totalLocation}}</td>
                                            <td class="text-center">{{totalUser}}</td>
                                            <td class="text-center">{{planDays}}</td>
                                            <!-- <td>{{extraplanLocationLimit !=null ?extraplanLocationLimit +' User - Rs.':'' }}{{extraLocationAmount}}</td>
                                            <td>{{extraplanUserLimit != null ? extraplanUserLimit +' User - Rs.' :''}}{{extraUserAmount}}</td>
                                            <td>{{planDays}} Days - Rs.{{planPrice}}</td> -->
                                            <td>{{contactPerson}}</td>
                                            <td>{{contactPersonMobile}}</td>
                                            <td>{{address}}</td>
                                            <td>{{email}}</td>
                                            <td>{{bankName}}</td>
                                            <td>{{chequeNo}}</td>
                                            <td>{{narration}}</td>
                                            <!-- <td>{{detail.expirydate | date :'dd-MMM-yyyy'}}</td> -->
                                        <!-- </tr> -->
                                    </tbody>
                                    <tfoot>
                                        <!-- <tr *ngIf="reSearchSubLedgerData.length == 0 "> -->
                                        <!-- <tr *ngIf="resAllsubExpiryDateDetail < 1 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr> -->
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="pendingToReceiveModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Purchase Plan Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearData()"></button>
        </div>
        <div class="modal-body">
          <!-- <div class="col-lg-12 col-md-12"> -->
            <form class="needs-validation" novalidate="">
                <!-- <div class="portlet-header">Purchase Plan</div> -->
                <div class="portlet-content">
                  <div class="row g-3">
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="first" class="form-check-label"></label>
                      <br>
                      <div class="form-check form-check-inline">
                        <label><input class="form-check-input" type="radio" name="optradio" value="Cash" id="cashnote"
                            (change)="onItemChange($event.target.value)" checked>&nbsp;Cash</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label><input class="form-check-input" type="radio" name="optradio" value="Bank"
                            (change)="onItemChange($event.target.value)">&nbsp;Bank</label>
                      </div>
                    </div>
                    <!-- <div *ngIf="isDisabled == false" class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Bank Name<span style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="txtBankName" name="txtBankName"
                        placeholder="Enter Bank Name">
                      <div class="invalid-feedback">
                        Please enter Bank Name.
                      </div>
                    </div>
                    <div *ngIf="isDisabled == false" class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Cheque No.<span style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="txtChequeNo" name="txtChequeNo">
                      <div class="invalid-feedback">
                        Please enter Cheque Number.
                      </div>
                    </div> -->
                    <div class="col-lg-3 col-xl-3 col-6">
                        <label for="first" class="form-label">Narration</label>
                          <textarea class="form-control" rows="1" name="txtNarration"
                          [(ngModel)]="txtNarration"></textarea>
                        <div class="invalid-feedback">
                          Please enter Address.
                        </div>
                    </div>
                    <!-- <div class="col-auto col-lg-1 btn-container-end">
                      <button class="btn btn-secondary me-2" type="button">
                        Cancel
                      </button>
                      <button class="btn btn-primary" type="submit" (click)="movePendingToReceive()">
                        Save
                      </button>
                    </div> -->
                  </div>
                </div>
            </form>
          <!-- </div> -->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearData()">Close</button>
              <button class="btn btn-primary" type="submit" (click)="movePendingToReceive()">
                Save
              </button>
          </div>
        </div>
      </div>
    </div>
</div>
<!-- Accept Reject Model-->
<div class="modal fade" id="acceptModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Accept Reject Modal</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are You Sure You Received This Payment ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmAccept()">Yes</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
</div>
<!-- model colse-->
<!-- Reject Model-->
<div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Reject Modal</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are You Sure Reject This Payment ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmReject()">Yes</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
</div>
<!-- model colse-->
<div class="modal fade" id="emailModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearEmail()"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-6 col-xl-6 col-6">
                    <label for="country" class="form-label">Email Address</label>
                    <input type="text" class="form-control" id="last"
                        placeholder="Enter Email Address" required="required"
                        [(ngModel)]="txtEmail" name="txtEmail" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="planPurchaseMailWithSendGrid()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearEmail()">Cancel</button>
            </div>
        </div>
    </div>
</div>  