import { Component, OnInit } from '@angular/core';
import { SubCategoryService } from "../service/sub_category/sub-category.service";
import { CategoryService } from "../service/category/category.service";
import { ToastrService } from "ngx-toastr";
import { ExcelService } from '../service/excel/excel.service';
declare var $: any;

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {
  resCategory = [];
  txtSubCategory;
  txtCategory;
  txtsubcategoryid;
  allSubCategory;
  allCategory;
  btnName = 'Save';
  search;
  page: number = 1;
  ExcelDatasubcat = [];
  fileName;

  constructor(
    private SubCategoryService: SubCategoryService,
    private categoryservice: CategoryService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.getAllSubCategory();
    this.getAllCategory();
  }

  toUpperCaseSubCategory() {
    const temp = this.txtSubCategory;
    this.txtSubCategory = temp.toUpperCase();
    return this.txtSubCategory;
  }

  getAllSubCategory() {
    this.SubCategoryService.getAllSubCategory().subscribe(res => {
      this.allSubCategory = res;
      
    })
  }
  getAllCategory() {
    this.categoryservice.gatAllCategory().subscribe(res => {
      this.allCategory = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtSubCategory = '';
    this.txtsubcategoryid = null;
    this.txtCategory = '';
  }

  createSubCategory() {
    if (this.txtCategory == null || this.txtCategory == '' || this.txtCategory == undefined || this.txtCategory == "-:Select Category:-") {
      this.toastr.error("Category is required");
    } else if (this.txtSubCategory == undefined || this.txtSubCategory == null || this.txtSubCategory == '') {
      return this.toastr.error('SubCategory is required');
    } else {
      if (this.txtsubcategoryid == null) {
        this.SubCategoryService.createSubCategory(this.txtCategory, this.txtSubCategory)
          .subscribe(res => {
            this.resCategory = res;
            this.clearData();
            this.getAllSubCategory();
            this.toastr.success('Subcategory save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));

          })
      } else {
        this.SubCategoryService.updateCategory(this.txtsubcategoryid, this.txtSubCategory, this.txtCategory).subscribe(res => {
          this.resCategory = res;
          this.clearData();
          this.getAllSubCategory();
          this.toastr.success('Subcategory updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatesubcategory(subCat) {
    this.btnName = 'Update';
    this.txtsubcategoryid = subCat.subcategoryid;
    this.txtCategory = subCat.category.categoryName;
    this.txtSubCategory = subCat.subCategoryName;

  }

  deleteid;
  deleteData(subCat) {
    this.deleteid = subCat.subcategoryid;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.SubCategoryService.deletSubCategory(this.deleteid).subscribe(res => {
        this.resCategory = res;
        this.clearData();
        this.getAllSubCategory();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Subcategory deleted successfully');
      }, error => {
        this.toastr.error('Subcategory not deleted');
      })
    }
  }

  exportAsXLSX(): void {
    this.ExcelDatasubcat = [];
    this.fileName = "subcategory";
    for (let sub of this.allSubCategory) {
    this.ExcelDatasubcat.push({"Category": sub.category.categoryName, "SubCategory": sub.subCategoryName });
    }
    this.excelservice.exportExcelSubcategory(this.fileName,this.ExcelDatasubcat);
  }
}