<!-- <style>
  .bs-example{
      margin: 20px;        
  }
  /* Style to change separator  */
  .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
  }
</style> -->
<div class="top-bar">
  <div class="me-auto">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <!-- <li class="breadcrumb-item"><a href="#">Application</a></li>
        <li class="breadcrumb-item"><a href="#">App Setup</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          Sub Category 1
        </li> -->
        <!-- <li class="breadcrumb-item"><a href="#">Application</a></li>
        <li class="breadcrumb-item"><a href="#">App Setup</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          Sub Category 1
        </li> -->

        <!-- <li class="breadcrumb-item"><a>Purchase</a></li>
        <li class="breadcrumb-item " ><a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/purchase']">Create Purchase</a></li>
        <li class="breadcrumb-item " ><a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}" [routerLink]="['/purchaseRecord']">Purchase Record</a></li> -->

      </ol>
    </nav>
  </div>
  <div class="ms-auto user-bar">
    <ul class="list-inline d-flex align-items-center">
      
      <li *ngIf="tempuserRoleName == 'SUPER ADMIN' && orgQrCode != null" class="list-inline-item">
        <div class="dropdown">
          <a class="user-profile-img d-flex justify-content-center" [href]="orgQrCode" title="Download QR Code" style="height: 15px;" role="button" >
            <i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </div>
      </li>
      <li class="list-inline-item" style="margin-left: 1rem;">
        <div class="dropdown">
          <a class="user-profile-img dropdown-toggle" href="#" role="button" id="dropdownAddMenu"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="assets/images/plus-bluecolor.svg" class="img-fluid" alt="" />
          </a>

          <div class="dropdown-menu" style="min-width: 12rem!important;" aria-labelledby="dropdownAddMenu">
            <!-- <div class="px-3 py-1">
              <p class="mb-0">
                <strong>{{firstName}} {{lastName}}</strong>
              </p>
              <p class="mb-0">
                <small><i class="fas fa-map-marker-alt"></i> {{location}}</small>
              </p>
            </div>
            <div class="dropdown-divider"></div> -->
            <ul class="list-unstyled">
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/sale']"><i class="fas fa-hand-holding-usd"></i> Credit Sale</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/cashsale']"><i class="fas fa-hand-holding-usd"></i> Cash Sale</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/purchase']"><i class="fas fa-shopping-cart"></i> Credit Purchase</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/cashpurchase']"><i class="fas fa-shopping-cart"></i> Cash Purchase</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/receiptvoucher']"><i class="fas fa-user-alt"></i> Receipt Voucher</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/paymentvoucher']"><i class="fas fa-user-alt"></i> Payment Voucher</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/creditnote']"><i class="fas fa-user-alt"></i> Credit Note</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/debitnote']"><i class="fas fa-user-alt"></i> Debit Note</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" [routerLink]="['/ledgerdetail']"><i class="fas fa-user-alt"></i> Ledger Detail</a>
              </li>
            </ul>
          </div>
        </div>
      </li>

      <li class="list-inline-item" style="margin-left: 1rem;">
        <div class="dropdown">
          <a href="#" class="notification dropdown-toggle" href="#" role="button" id="NotificationDropdown"
            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true"><i class="fas fa-bell" style="font-size:24px !important;"></i>
            <span class="badge bg-red badge-corner">
              {{allPlanPurchaseNotification == 0 ? (purchaseLength + TransferLength) : 1 + purchaseLength + TransferLength}}
            </span>
          </a>
          <div class="dropdown-menu" style="min-width: 12rem!important;" aria-labelledby="NotificationDropdown">
            <div class="p-1" *ngIf="TransferLength == 0 && allPlanPurchaseNotification == 0 && purchaseLength == 0">
                There are no notification.
            </div>
            <div *ngIf="allPlanPurchaseNotification != '0'" class="px-3 py-1">
              <p class="mb-0">
                <strong>Your Plan Is Expiry In <p class="mb-0 blink_me"><small style="color: red;">{{allPlanPurchaseNotification}} Days</small></p></strong>
              </p>
              <!-- <p class="mb-0 blink_me" style="color: red;">
                <small>{{allPlanPurchaseNotification}} Days</small>
              </p> -->
              <div class="dropdown-divider"></div>
            </div>
            <div *ngIf="enableTransfer == true" class="px-3 py-1">
              <p class="mb-0">
                <strong>TRANSFER</strong>
              </p>
              <p class="mb-0" *ngFor="let transfer of allTransferNotification">
                <small [routerLink]="['/transferRecord']" role="button"><i class="fas fa-map-marker-alt"></i> {{transfer.fromlocation}} - {{transfer.quantity}}</small>
              </p>
              <div class="dropdown-divider"></div>
            </div>
           
            <div *ngIf="purchaseLength != 0" class="px-3 py-1">
              <p class="mb-0">
                <strong>PURCHASE</strong>
              </p>
              <p class="mb-0" *ngFor="let purchase of allPurchaseNotification">
                <small [routerLink]="['/purchaseRecord']" role="button">{{purchase.purchaseNumber}} - {{purchase.netAmount}}</small>
              </p>
            </div>
          </div>
        </div>
      </li>

      <li class="list-inline-item" style="margin-left: 1rem;">
        <div class="dropdown">
          <a class="user-profile-img dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="assets/images/user-profile.jpg" class="img-fluid" alt="" />
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <div class="px-3 py-1">
              <p class="mb-0">
                <strong>{{firstName}} {{lastName}}</strong>
              </p>
              <p class="mb-0">
                <small><i class="fas fa-map-marker-alt"></i> {{location}}</small>
              </p>
            </div>
            <div class="dropdown-divider"></div>
            <ul class="list-unstyled">
              <!-- <li>
                <a class="dropdown-item" href="#"><i class="fas fa-user"></i> Profile</a>
              </li> -->
              <li>
                <a class="dropdown-item" role="button" data-bs-toggle="modal" data-bs-target="#ChangepwdModal"><i
                    class="fas fa-key"></i> Reset Password</a>
              </li>
              <li>
                <a class="dropdown-item" role="button" (click)="logoutuser()"><i class="fas fa-power-off"></i> Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>

  </div>
</div>
<div class="modal fade" id="ChangepwdModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Change Password</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-xl-12 col-12">
            <label for="first" class="form-label">New Password<span style="color: red;">&nbsp;*</span></label>
            <input type="password" class="form-control" id="name" placeholder="Enter New Password" name="txtNewPassword"
              [(ngModel)]="txtNewPassword" required="required" />
            <div class="invalid-feedback">
              Please enter New Password.
            </div>
          </div>
          <div class="col-lg-12 col-xl-12 col-12 mt-2">
            <label for="first" class="form-label">Confirm Password<span style="color: red;">&nbsp;*</span></label>
            <input type="password" class="form-control" id="name" placeholder="Enter Confirm Password"
              name="txtConfirmPassword" [(ngModel)]="txtConfirmPassword" required="required" />
            <div class="invalid-feedback">
              Please enter Confirm Password.
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" id="button" aria-hidden="true"
            (click)="changePassword()">Change</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>