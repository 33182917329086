import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PurchaseService } from "../service/purchase/purchase.service";
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-purchase-record',
  templateUrl: './purchase-record.component.html',
  styleUrls: ['./purchase-record.component.css']
})
export class PurchaseRecordComponent implements OnInit {
  allPurchaseDetail;
  allPurchase;
  search;
  page: number = 1;
  txtFormDate;
  txtToDate;
  datePipe = new DatePipe("en-US");
  
  constructor(
    private purchaseservice: PurchaseService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.getAllPurchase();
    this.getAllPurchaseByDate();
  }

getAllPurchase() {
  this.purchaseservice.getAllPurchase().subscribe(res => {
    this.allPurchase = res;
    // console.log(this.allPurchase);
  })
}

userRole;
getAllPurchaseByDate() {
  this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
  this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
  this.purchaseservice.getAllPurchaseByLocationAndDate(this.txtFormDate,this.txtToDate).subscribe(res => {
    this.allPurchase = res.purchases;
    this.userRole = res.userrole;
  })
}

  getPurchaseDetail(purchase){
    this.allPurchaseDetail=purchase.purchaseDetails;
    // console.log(this.allPurchaseDetail);
  }

  purchaseDeleteId;
  resDelPurchase;
  deletePurchase(purchase){
    this.purchaseDeleteId = purchase.purchaseid;
  }

  confirmDelete(){
    if (this.purchaseDeleteId != null) {
      this.purchaseservice.deletePurchase(this.purchaseDeleteId).subscribe(res => {
        this.resDelPurchase = res;
        this.getAllPurchaseByDate();
        $('#deleteModal').modal('hide');
        this.toastr.success('Purchase deleted successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }
}
