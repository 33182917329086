<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="portlet">
                            <!-- <form class="needs-validation" novalidate=""> -->
                            <div class="portlet-header">Plan Expiry Detail</div>
                            <div class="portlet-content">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="tenDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#tenDays" type="button" role="tab" aria-controls="tenDays"
                                            aria-selected="true" (click)="getDueDate(10)">10 Days</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="twentyDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#twentyDays" type="button" role="tab"
                                            aria-controls="twentyDays" aria-selected="false" (click)="getDueDate(20)">20
                                            Days</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="thirtyDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#thirtyDays" type="button" role="tab"
                                            aria-controls="thirtyDays" aria-selected="false" (click)="getDueDate(30)">30
                                            Days</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="fourtyfiveDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#fourtyfiveDays" type="button" role="tab"
                                            aria-controls="fourtyfiveDays" aria-selected="false" (click)="getDueDate(45)">45
                                            Days</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="tenDays" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchten" [(ngModel)]="searchten"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Owner Name</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Final Amount</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Active Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let planexp of allPlanExpiryDate | filter:searchten | paginate: { itemsPerPage: 10, currentPage: page10 }">
                                                        <td>{{planexp.ownerName}}</td>
                                                        <td>{{planexp.ownerMobileNumber}}</td>
                                                        <td>{{planexp.plan.planName}}</td>
                                                        <td>{{planexp.finalAmount | appendIND}}</td>
                                                        <td>{{planexp.organizationName}}</td>
                                                        <td>{{planexp.activeDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td>{{planexp.endDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(planexp)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allPlanExpiryDate < 1">
                                                        <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page10 = $event"
                                                            (pageBoundsCorrection)="page10=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="twentyDays" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchtwenty"
                                                        [(ngModel)]="searchtwenty" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Owner Name</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Final Amount</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Active Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let planexp of allPlanExpiryDate | filter:searchtwenty | paginate: { itemsPerPage: 10, currentPage: page20 }">
                                                        <td>{{planexp.ownerName}}</td>
                                                        <td>{{planexp.ownerMobileNumber}}</td>
                                                        <td>{{planexp.plan.planName}}</td>
                                                        <td>{{planexp.finalAmount | appendIND}}</td>
                                                        <td>{{planexp.organizationName}}</td>
                                                        <td>{{planexp.activeDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td>{{planexp.endDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(planexp)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allPlanExpiryDate < 1">
                                                        <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page20 = $event"
                                                            (pageBoundsCorrection)="page20=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="thirtyDays" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchthirty"
                                                        [(ngModel)]="searchthirty" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Owner Name</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Final Amount</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Active Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let planexp of allPlanExpiryDate | filter:searchthirty | paginate: { itemsPerPage: 10, currentPage: page30 }">
                                                        <td>{{planexp.ownerName}}</td>
                                                        <td>{{planexp.ownerMobileNumber}}</td>
                                                        <td>{{planexp.plan.planName}}</td>
                                                        <td>{{planexp.finalAmount | appendIND}}</td>
                                                        <td>{{planexp.organizationName}}</td>
                                                        <td>{{planexp.activeDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td>{{planexp.endDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(planexp)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allPlanExpiryDate < 1">
                                                        <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page30 = $event"
                                                            (pageBoundsCorrection)="page30=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="fourtyfiveDays" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-4">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchfourtyfive"
                                                        [(ngModel)]="searchfourtyfive" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Owner Name</th>
                                                        <th scope="col">Owner Mobile</th>
                                                        <th scope="col">Plan</th>
                                                        <th scope="col">Final Amount</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Active Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let planexp of allPlanExpiryDate | filter:searchfourtyfive | paginate: { itemsPerPage: 10, currentPage: page45 }">
                                                        <td>{{planexp.ownerName}}</td>
                                                        <td>{{planexp.ownerMobileNumber}}</td>
                                                        <td>{{planexp.plan.planName}}</td>
                                                        <td>{{planexp.finalAmount | appendIND}}</td>
                                                        <td>{{planexp.organizationName}}</td>
                                                        <td>{{planexp.activeDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td>{{planexp.endDate | date:'dd-MMM-yyyy'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(planexp)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allPlanExpiryDate < 1">
                                                        <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page45 = $event"
                                                            (pageBoundsCorrection)="page45=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="expiryDetailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-content">
                            <div class="row mt-2 mb-1">
                                <div class="col-lg-4 col-sm-4 col-4">
                                    <a>Plan Amount : <span class="text-right">{{planAmount | appendIND}}</span></a>
                                </div>
                                <div class="col-lg-4 col-sm-4 col-4">
                                    <a>Extra Location Amount : <span>{{extraLocationAmount | appendIND}}</span></a>
                                </div>
                                <div class="col-lg-4 col-sm-4 col-4">
                                    <a>Extra User Amount : <span>{{extraUserAmount | appendIND}}</span></a>
                                </div>
                                <!-- <div class="col-lg-6 col-sm-6 col-6">
                                    <a>Amount : <span class="text-right">{{totalAmount}}</span></a><br/>
                                    <a>GST : <span>{{gstAmount}}</span></a><br/>
                                    <a>Discount : <span>{{discount}}</span></a><br/>
                                    <a>Net Amount : <span>{{netAmount}}</span></a>
                                </div> -->
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Location</th>
                                            <th scope="col">User</th>
                                            <th scope="col">Plan Duration (Year)</th>
                                            <th scope="col">Contact Person</th>
                                            <th scope="col">Contact Person Mobile</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Bank Name</th>
                                            <th scope="col">Cheque No.</th>
                                            <th scope="col">Narration</th>
                                            <!-- <th scope="col">Active Date</th> -->
                                            <!-- <th scope="col">End Date</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr *ngFor="let detail of resAllsubExpiryDateDetail"> -->
                                            <td class="text-center">{{totalLocation}}</td>
                                            <td class="text-center">{{totalUser}}</td>
                                            <td class="text-center">{{planDays}}</td>
                                            <!-- <td>{{extraplanLocationLimit !=null ?extraplanLocationLimit +' User - Rs.':'' }}{{extraLocationAmount}}</td>
                                            <td>{{extraplanUserLimit != null ? extraplanUserLimit +' User - Rs.' :''}}{{extraUserAmount}}</td>
                                            <td>{{planDays}} Days - Rs.{{planPrice}}</td> -->
                                            <td>{{contactPerson}}</td>
                                            <td>{{contactPersonMobile}}</td>
                                            <td>{{address}}</td>
                                            <td>{{email}}</td>
                                            <td>{{bankName}}</td>
                                            <td>{{chequeNo}}</td>
                                            <td>{{narration}}</td>
                                            <!-- <td>{{detail.expirydate | date :'dd-MMM-yyyy'}}</td> -->
                                        <!-- </tr> -->
                                    </tbody>
                                    <tfoot>
                                        <!-- <tr *ngIf="reSearchSubLedgerData.length == 0 "> -->
                                        <!-- <tr *ngIf="resAllsubExpiryDateDetail < 1 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr> -->
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>