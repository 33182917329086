import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CategoryAppService {

  constructor(
    private http: Http,
  ) { }

  createCategoryApp(txtCategoryApp: string,txtCategoryAppGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryName: txtCategoryApp,
      categoryNameGuj: txtCategoryAppGuj
    });
    return this.http.post('/api/app/create/categoryapp', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateCategoryApp(txtCategoryAppId: string, txtCategoryApp: string,txtCategoryAppGuj: string, txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryName: txtCategoryApp,
      categoryId: txtCategoryAppId,
      categoryNameGuj: txtCategoryAppGuj,
      image: txtImage
    });
    return this.http.put('/api/app/update/categoryapp', body, { headers })
      .pipe(map(res => res.json()));
  }

  gatAllCategoryApp() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/categoryapp', { headers })
      .pipe(map(res => res.json()));
  }

  deleteCategoryApp(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryId: deleteAppId,
    });
    return this.http.put('/api/app/delete/categoryapp', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/categoryapp/image', data, { headers: headers })
      .pipe(map(res => res));
  }
}
