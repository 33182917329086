import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AgroGlobalConst } from '../agro-global';

@Injectable({
  providedIn: 'root'
})
export class GeneralInformationService {

  constructor(
    private http : Http,
  ) { }

  createGeneralInformation(txtCompany: string,txtEmail: string, txtBankName: string, txtAccountNumber: string, txtIfscCode: string, txtBranchName: string,txtTermsNcondition: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      companyId : txtCompany,
      email : txtEmail,
      bankName : txtBankName,
      accountNumber : txtAccountNumber,
      ifscCode : txtIfscCode,
      branch : txtBranchName,
      termsNcondition : txtTermsNcondition,
    });
    return this.http.post('/api/create/gnrlinfo', body, { headers })
    .pipe(map(res => res.json()));
  }

  getAllGeneralInformation(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/gnrlinfo', { headers })
      .pipe(map(res => res.json()));

  }

  updateGeneralInformation(txtGeneralId: string,txtCompany: string, txtEmail: string, txtBankName: string, txtAccountNumber: string, txtIfscCode: string, txtBranchName: string, txtTermsNcondition: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id : txtGeneralId,
      companyId : txtCompany,
      email : txtEmail,
      bankName : txtBankName,
      accountNumber : txtAccountNumber,
      ifscCode : txtIfscCode,
      branch : txtBranchName,
      termsNcondition : txtTermsNcondition,
    });
    return this.http.put('/api/update/gnrlinfo', body, { headers })
      .pipe(map(res => res.json()));
  }

  createAdvertisement(txtAdvertisement: string, txtImage: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      text : txtAdvertisement,
      image : txtImage,
    });
    return this.http.post('/api/create/advertisement', body, { headers })
    .pipe(map(res => res.json()));
  }

  getAllAdvertisement(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/advertisement', { headers })
      .pipe(map(res => res.json()));

  }

  updateAdvertisement(txtAdvertisementId: string, txtAdvertisement: string, txtImage: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      advertisementid : txtAdvertisementId,
      text : txtAdvertisement,
      image : txtImage,
    });
    return this.http.put('/api/update/advertisement', body, { headers })
      .pipe(map(res => res.json()));
  }
  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/advertisement/image', data, { headers: headers })
      .pipe(map(res => res));
  }

  whatsappAdvertisement(txtAdvertisementId:string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/advertisement/whatsup/'+txtAdvertisementId , { headers })
      .pipe(map(res => res.json()));
  }
  sendAdvertisementMsgAndMedia(mobileNumber: string,verifymsg: string,url: string) {
    return this.http.get('https://chhoteraja.com/api/send-media.php?number=91'+mobileNumber+'&msg='+verifymsg+'&media='+url+'&apikey='+AgroGlobalConst.apikey+'&instance='+AgroGlobalConst.instantkey)
    .pipe(map(res => res));
  }
}
