import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LedgerService } from '../service/ledger/ledger.service';
import { BalancesheetService } from '../service/balancesheet/balancesheet.service';
import { CompanyService } from '../service/company/company.service';
declare var $: any;

@Component({
  selector: 'app-group-wise-ledger',
  templateUrl: './group-wise-ledger.component.html',
  styleUrls: ['./group-wise-ledger.component.css']
})
export class GroupWiseLedgerComponent implements OnInit {
  allBalance;
  txtFormDate;
  txtToDate;
  txtBalanceName;
  resGroupWiseLedgerData;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  txtTotalDebit = 0;
  txtTotalCredit = 0;
  txtClosingBalance = 0;
  page = 1;
  search;
  txtcompany;
  allCompany;

  constructor(
    private balancesheetService: BalancesheetService,
    private ledgerService: LedgerService,
    private toastr: ToastrService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.txtFormDate = new Date();
    this.txtToDate = new Date();
    this.getAllBalance();
    this.getAllCompany();
  }

  getAllBalance() {
    this.balancesheetService.getAllBalance().subscribe(res => {
      this.allBalance = res;
    })
  }
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  GetGroupWiseLedger() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company Name is required");
    } else if (this.txtBalanceName == "" || this.txtBalanceName == null || this.txtBalanceName == undefined || this.txtBalanceName == "-:Select BalanceSheet:-") {
      this.toastr.error("Balance Sheet is required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
        this.ledgerService.GetGroupWiseLedger(this.txtFormDate, this.txtToDate, this.txtBalanceName, this.txtcompany).subscribe(res => {
          this.resGroupWiseLedgerData = res;
          this.isEnable = true;
          this.txtTotalDebit = 0;
          this.txtTotalCredit = 0;
          for (let ii = 0; ii < this.resGroupWiseLedgerData.length; ii++) {
            this.txtTotalDebit = this.txtTotalDebit + this.resGroupWiseLedgerData[ii].debit;
            this.txtTotalCredit = this.txtTotalCredit + this.resGroupWiseLedgerData[ii].credit;
          }
          this.txtClosingBalance = this.txtTotalDebit - this.txtTotalCredit;
        }, error => {
          // this.toastr.error("something went wrong");
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
    }
  }
  
  resBalancesheetPdf;
  openPdfResponseBalancesheet;
  downloadPDF() {
    this.ledgerService.downloadGroupWiseLedgerPDF(this.txtcompany).subscribe((data) => {
      this.resBalancesheetPdf = data;
 
      var newBlob = new Blob([this.resBalancesheetPdf], {type: "application/pdf"});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
 
      const Balancesheetdata = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = Balancesheetdata;
      link.download = "BalancesheetReport.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseBalancesheet.saveAs(link.href);
 
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(Balancesheetdata);
        link.remove();
      }, 100);
      this.resBalancesheetPdf = '';
      this.openPdfResponseBalancesheet = '';
    })
  }
 
}
