import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BrandAppService {

  constructor(
    private http: Http,
  ) { }
  
  createBrandApp(txtBrandAppName: string, txtBrandGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name: txtBrandAppName,
      brandNameGuj: txtBrandGuj,
    });
    return this.http.post('/api/app/create/brandapp', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateBrandApp(txtBrandAppId: string, txtBrandAppName: string, txtBrandGuj: string, txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      brandappid: txtBrandAppId,
      name: txtBrandAppName,
      brandNameGuj: txtBrandGuj,
      image: txtImage,
    });
    return this.http.put('/api/app/update/brandapp', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllBrandApp() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/brandapp', { headers })
      .pipe(map(res => res.json()));
  }

  deleteBrandApp(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      brandappid: deleteAppId,
    });
    return this.http.put('/api/app/delete/brandapp', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/brandapp/image', data, { headers: headers })
      .pipe(map(res => res));
  }

}
