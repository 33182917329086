import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CategoryService } from "../service/category/category.service";
import { ToastrService } from "ngx-toastr";
import { CompanyService } from "../service/company/company.service";
declare var $: any;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  resCategory;
  txtCategory;
  txtcategoryid;
  allCategory;
  btnName = 'Save';
  search;
  allCompany;
  txthsncode;
  txtCompanyName;
  page: number = 1;
  // resToken = [];

  constructor(
    private categoryservice: CategoryService,
    private toastr: ToastrService, private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.getAllCategory();
    this.getAllCompany();
  }

  getAllCategory() {
    this.categoryservice.gatAllCategory().subscribe(res => {
      this.allCategory = res;
    })
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  toUpperCaseCategory() {
    const temp = this.txtCategory;
    this.txtCategory = temp.toUpperCase();
    return this.txtCategory;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCategory = '';
    this.txtcategoryid = null;
    this.txthsncode = '';
    this.txtCompanyName = '';
  }
  createCategory() {
    // var category = this.txtCategory;
    // this.resToken.push(category);
    // this.txtCategory = '';
    // console.log(this.resToken);
    if (this.txtCategory == null || this.txtCategory == '' || this.txtCategory == undefined) {
      this.toastr.error("Category is required");
    } else if (this.txthsncode == undefined || this.txthsncode == null || this.txthsncode == '') {
      return this.toastr.error('HSN code is required');
    } else if (this.txtCompanyName == undefined || this.txtCompanyName == null || this.txtCompanyName == '' || this.txtCompanyName == "-:Select Company Name:-") {
      return this.toastr.error('Company is required');
    } else {
      if (this.txtcategoryid == null) {
        this.categoryservice.createCategory(this.txtCategory, this.txthsncode, this.txtCompanyName)
          .subscribe(res => {
            this.resCategory = res;
            console.log(this.resCategory);
            this.clearData();
            this.getAllCategory();
            this.toastr.success('Category save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.categoryservice.updateCategory(this.txtcategoryid, this.txtCategory, this.txthsncode, this.txtCompanyName).subscribe(res => {
          this.resCategory = res;
          this.clearData();
          this.getAllCategory();
          this.toastr.success('Category updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatecategory(cat) {
    this.btnName = 'Update';
    this.txtcategoryid = cat.categoryId;
    this.txtCategory = cat.categoryName;
    this.txthsncode = cat.hsnCode;
    this.txtCompanyName = cat.company.companyName;
  }

  deleteid;
  deleteData(cat) {
    this.deleteid = cat.categoryId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.categoryservice.deleteCategory(this.deleteid)
        .subscribe(res => {
          this.resCategory = res;
          console.log(this.resCategory);
          this.clearData();
          this.getAllCategory();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Category deleted successfully');
        }, error => {
          this.toastr.error('Category not deleted');
        })
    }
  }
}
