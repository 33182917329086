<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Purchase Return Details</h2> -->
                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Purchase Return</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Purchase Return Date<span style="color: red;">&nbsp;*</span></label>
                                            <!-- <input type="text" placeholder="Datepicker"
class="form-control result-data receive-date with-icon" bsDatepicker [(ngModel)]="txtPurchaseDate"
name="txtPurchaseDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"> -->
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtPurchaseReturnDate "
                                                name="txtPurchaseReturnDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Purchase Return Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Return Number</label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtpurchaseReturnid"
                                                name="txtpurchaseReturnid">
                                            <input type="text" disabled class="form-control" id="itemName"
                                                placeholder="Enter purchase Return code" required="required"
                                                [(ngModel)]="txtpurchaseReturnCode" name="txtpurchaseReturnCode" />
                                            <div class="invalid-feedback">
                                                Please enter purchase Return code.
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-xl-5 col-12 position-relative">
                                            <label for="last" class="form-label">Party Name<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Enter party Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-1">
                                            <label for="first" class="form-label">Due Days<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="itemName" placeholder="Due Days"
                                                required="required" [(ngModel)]="txtDueDays" (focusout)="getDueDate()"
                                                name="txtDueDays" />
                                            <div class="invalid-feedback">
                                                Due Days
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <label for="first" class="form-label">Due Date</label>
                                            <input type="text" disabled class="form-control" id="itemName"
                                                required="required" [(ngModel)]="txtDueDate" name="txtDueDate" />
                                            <div class="invalid-feedback">
                                                Due Date.
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-3">
                                            <label for="country" class="form-label">Gst Amount</label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Enter Gst Amount" required="required"
                                                [(ngModel)]="txtgstAmount" name="txtgstAmount" disabled />
                                            <div class="invalid-feedback">
                                                Please enter a gst amount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Amount</label>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Amount"
                                                required="required" [(ngModel)]="txtamount" name="txtamount" disabled />
                                            <div class="invalid-feedback">
                                                Please enter a amount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Discount</label>
                                            <input type="text" class="form-control" id="last"
                                                (focusout)="calculateNetAmount()" placeholder="Enter Discount"
                                                required="required" [(ngModel)]="txtDiscount" name="txtDiscount" />
                                            <div class="invalid-feedback">
                                                Please enter a Discount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Net Amount</label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Enter Net Amount" required="required"
                                                [(ngModel)]="txtnetAmount" name="txtnetAmount" disabled />
                                            <div class="invalid-feedback">
                                                Please enter a net Amount.
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <hr>
                                <form class="needs-validation" novalidate="">
                                    <div class="portlet-content">
                                        <div class="row d-flex align-items-baseline g-3">

                                            <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                                <label for="last" class="form-label">Item Name<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" [value]="search1" ngxTypeahead
                                                    [taList]="searchItemName" [taItemTpl]="myTemp" (taSelected)="selectedStatic($event)"
                                                    id="ItemName" [(ngModel)]="txtItemName" name="txtItemName"
                                                    placeholder="Enter Item Name"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp let-item>
                                                    <div class="item-name">
                                                        {{item.result.itemName}}
                                                    </div>
                                                </ng-template>

                                                <div class="invalid-feedback">
                                                    Please enter item name.
                                                </div>
                                            </div>
                                            <!-- <div class="col-2">
                                                <label for="first" class="form-label">Category Name</label>
                                                <input type="text" class="form-control" id="itemName"
                                                    placeholder="Enter Category Name" required="required"
                                                    [(ngModel)]="txtCategoryName" name="txtCategoryName" disabled />
                                                <div class="invalid-feedback">
                                                    Please enter txtCategory Name.
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <label for="country" class="form-label">SubCategory Name</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter SubCategory Name" required="required"
                                                    [(ngModel)]="txtSubCategoryName" name="txtSubCategoryName"
                                                    disabled />
                                                <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div> -->
                                            <div class="col-lg-3 col-xl-2 col-6 position-relative">
                                                <label for="country" class="form-label">Batch Number<span style="color: red;">&nbsp;*</span></label>
                                                <!-- <input type="text" class="form-control" id="last"
                                                    placeholder="Batch Number" required="required"
                                                    [(ngModel)]="txtBatchNumber" name="txtBatchNumber" /> -->
                                                <input type="text" [value]="searchbatchno" ngxTypeahead
                                                     [taList]="searchBatchNumbers" [taItemTpl]="myTemp1" (taSelected)="selectedBatchno($event)"
                                                    id="batchnumber" [(ngModel)]="txtBatchNumber" name="txtBatchNumber"
                                                    placeholder="Enter Batch Number"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp1 let-item>
                                                    <div class="item-name">
                                                        {{item.result.batchnumber}}
                                                    </div>
                                                </ng-template>
                                                    <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-2 col-6 position-relative">
                                                <label for="first" class="form-label">Purchase Number<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" [value]="searchPurchasenumber" ngxTypeahead
                                                    [taList]="resReturnPurchaseNos" [taItemTpl]="myTemp2" (taSelected)="selectedStaticPurchaseNumber($event)"
                                                    id="salenumber" [(ngModel)]="txtPurchaseNo" name="txtPurchaseNo"
                                                    placeholder="Enter Purchase Number" (focusout)="getQTYNRateByPurchaseNoAndItem()"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp2 let-item>
                                                    <div class="item-name">
                                                        {{item.result.purchasenumber}}
                                                    </div>
                                                </ng-template>
                                                <div class="invalid-feedback">
                                                    Please select purchase number.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-1 col-6">
                                                <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Quantity" required="required"
                                                    [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a quantity.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-1 col-6">
                                                <label for="country" class="form-label">Rate<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Rate" required="required"
                                                    [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a rate.
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-2 col-6">
                                                <label for="country" class="form-label">Manufacture Date<span style="color: red;">&nbsp;*</span></label>
                                                <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                    class="form-control" [(ngModel)]="txtManufactureDate "
                                                    name="txtManufactureDate" disabled>
                                                <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                                <!-- <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Manufacture Date" required="required"
                                                    [(ngModel)]="txtManufactureDate" name="txtManufactureDate" /> -->
                                                <div class="invalid-feedback">
                                                    Please enter a manufacture date.
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-2 col-6">
                                                <label for="country" class="form-label">Expiry Date<span style="color: red;">&nbsp;*</span></label>
                                                <input (click)="picker1.open()" matInput [matDatepicker]="picker1"
                                                    class="form-control" [(ngModel)]="txtExpiryDate "
                                                    name="txtExpiryDate" disabled>
                                                <mat-datepicker-toggle matSuffix [for]="picker1" style="display: none;">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                                <!-- <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Net Amount" required="required"
                                                    [(ngModel)]="txtExpiryDate" name="txtExpiryDate" /> -->
                                                <div class="invalid-feedback">
                                                    Please enter a expiryDate.
                                                </div>
                                            </div>
                                            <div class="col-auto col-1 btn-container-end">
                                              <div class="row">  
                                                <label *ngIf="qtyLabel">Quantity:&nbsp;{{availablePurchaseNoQty}}</label>
                                                <button class="btn btn-primary d-block" type="submit"
                                                    (click)="addPurchaseReturnDetail()">
                                                    {{btnName}}
                                                </button>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Purchase Return Details</div>
                        <div class="portlet-content">
                            <!-- <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" value="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div> -->

                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col">#</th> -->
                                            <th scope="col">Item Name</th>
                                            <!-- <th scope="col">category Name</th>
                                            <th scope="col">SubCategory Name</th> -->
                                            <th scope="col">Batch Number</th>
                                            <th scope="col">Purchase Number</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col" class="text-center">Rate</th>
                                            <th scope="col" class="text-center">Total</th>
                                            <th scope="col">Manufacture Date</th>
                                            <th scope="col">Expiry Date</th>
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let purchaseReturn of PurchaseReturnData">
                                            <!-- <th scope="row">1</th> -->
                                            <td>{{purchaseReturn.itemName}}</td>
                                            <!-- <td>{{purchaseReturn.categoryName}}</td>
                                            <td>{{purchaseReturn.subCategoryName}}</td> -->
                                            <td>{{purchaseReturn.batchNumber}}</td>
                                            <td>{{purchaseReturn.purchasenumber}}</td>
                                            <td>{{purchaseReturn.quantity}}</td>
                                            <td class="text-end">{{purchaseReturn.rate | appendIND}}</td>
                                            <td class="text-end">{{purchaseReturn.quantity * purchaseReturn.rate | appendIND}}</td>
                                            <td>{{purchaseReturn.manufactureDate | date:'dd/MM/yyyy'}}</td>
                                            <td>{{purchaseReturn.expiryDate | date:'dd/MM/yyyy'}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                style="margin-right:5px ;" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="updatePurchaseReturn(purchaseReturn)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deletePurchaseReturn(purchaseReturn)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="PurchaseReturnData.length == 0 ">
                                            <td colspan="10" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="col-md-12">
                                <div class="row ">
                                    <div class="col-xl-8 col-md-6 col-6">
                                        <div class="col-xl-4 col-lg-4 col-md-8 col-sm-4 col-12 margin_top">
                                            <label for="country" class="form-label">Narration</label>
                                            <textarea class="form-control" rows="2" name="txtNarration"
                                                [(ngModel)]="txtNarration"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-6">
                                        <div class="justify-content-end">
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">Amount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Enter Amount" required="required"
                                                        [(ngModel)]="txtamount" name="txtamount" disabled />
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-end">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">GST Amount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Enter Gst Amount" required="required"
                                                        [(ngModel)]="txtgstAmount" name="txtgstAmount" disabled />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">Discount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        (focusout)="calculateNetAmount()" placeholder="Discount"
                                                        required="required" [(ngModel)]="txtDiscount"
                                                        name="txtDiscount" (keypress)="numberandDotOnly($event)"/>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">Net Amount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Net Amount" required="required"
                                                        [(ngModel)]="txtnetAmount" name="txtnetAmount" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item active" aria-current="page">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearGridData()" (click)="clearValue()">
                                Cancel
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="createPurchaseReturnDetail()">
                                Save
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>