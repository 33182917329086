import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { GroupService } from '../service/group/group.service';
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-assets-liability-search',
  templateUrl: './assets-liability-search.component.html',
  styleUrls: ['./assets-liability-search.component.css']
})
export class AssetsLiabilitySearchComponent implements OnInit {

  txtFormDate;
  reSearchData;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  page = 1;
  search;
  allGroup;
  txtLedger;

  constructor(
    private groupService: GroupService,
    private toastr: ToastrService,
    private assetsVoucherService: AssetsVoucherService,
  ) { }

  ngOnInit(): void {
    this.txtFormDate = new Date();
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'dd-MM-yyyy');
    this.getAllGroup();
  }

  getAllGroup() {
    this.groupService.getAllGroup().subscribe(res => {
      this.allGroup = res;
      console.log("balance", this.allGroup);
    })
  }
  resAssetsData = [];
  resAssets;
  searchAnalysis() {
    this.resAssetsData = [];
    if (this.txtLedger == "" || this.txtLedger == null || this.txtLedger == undefined) {
      this.toastr.error("ledger is required");
    } else {
      this.assetsVoucherService.getAssetsSearch(this.txtLedger).subscribe(res => {
        this.reSearchData = res;
        console.log(this.reSearchData);
        // this.resAssetsData = [];
        for (let amount of this.reSearchData) {
          // this.resAssets = amount.voucherDetails;
          for (let data of amount.voucherDetails) {
            this.resAssetsData.push(data);
          }
        }
        console.log(this.resAssetsData);
        this.isEnable = true;

      }, error => {
        this.toastr.error("something went wrong");
      });
    }
  }

}
