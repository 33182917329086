import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: Http,
  ) { }

  createCategory(txtCategory: string,txthsncode: string,txtCompanyName: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token", token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryName: txtCategory,
      hsnCode: txthsncode,
      companyName: txtCompanyName
    });
    return this.http.post('/api/create/category', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateCategory(categoryid: string, txtCategory: string,txthsncode: string,txtCompanyName: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryName: txtCategory,
      categoryId: categoryid,
      hsnCode: txthsncode,
      companyName: txtCompanyName
    });
    return this.http.put('/api/update/category', body, { headers })
      .pipe(map(res => res.json()));
  }

  gatAllCategory() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/api/getall/category', { headers })
      .pipe(map(res => res.json()));

  }

  deleteCategory(txtCategoryId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      categoryId: txtCategoryId,
    });
    return this.http.put('/api/delete/category', body, { headers })
      .pipe(map(res => res.json()));
  }

  importExcelCategoryINIT(resCategory){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   companyName : resCategory,
    // });
    return this.http.post('/api/create/list/category', resCategory, { headers })
      .pipe(map(res => res));
  }

  getAllWizardDataCheck() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/dataofappsetup', { headers })
      .pipe(map(res => res.text()));
  }
  
  getAllCategoryByCompany(txtCompanyId: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/category/company/'+txtCompanyId, { headers })
      .pipe(map(res => res.json()));
  }
}
