<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header d-flex align-items-center">BankToBank Voucher Record
                            <button type="button" class="btn btn-secondary ms-auto" (click)="MoveBankToBank()">Add
                                BankToBank</button>
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Action</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">From Bank</th>
                                            <th scope="col">To Bank</th>
                                            <th scope="col">Narration</th>
                                            <th scope="col">Amount (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let banktobank of allBankToBank | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>
                                                <!-- <button type="button" class="btn btn-primary btn-sm" style="margin-left: 5px;"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="cashToBankDetail(cashtobank)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    <i class="fas fa-history"></i>
                                                </button> -->
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    style="margin-left:5px ;" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete"
                                                    (click)="deleteBankToBank(banktobank)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>

                                            </td>
                                            <td>{{banktobank.voucherDate | date :'dd-MMM-yyyy'}}</td>
                                            <td>{{banktobank.frombank}}</td>
                                            <td>{{banktobank.tobank}}</td>
                                            <td>{{banktobank.narration}}</td>
                                            <td class="text-end">{{banktobank.debit | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><b>Current Total</b></td>
                                            <td class="text-end"><b>{{txtTotalAmount | appendIND}}</b></td>
                                        </tr>
                                    </tfoot>
                                    <tfoot>
                                        <tr *ngIf="allBankToBank < 1 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">BankToBank Voucher Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Particulars</th>
                                                        <th class="text-center" scope="col">DEBIT (₹)</th>
                                                        <th class="text-center" scope="col">CREDIT (₹)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let banktobankById of allBankToBankById;">
                                                        <td>{{banktobankById.partyName}}</td>
                                                        <td class="text-end">{{banktobankById.debit | appendIND}}</td>
                                                        <td class="text-end">{{banktobankById.credit | appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td><b>Total</b></td>
                                                        <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                                        <td class="text-end"><b>{{txtTotalCredit | appendIND}}</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                Are you sure want to delete this record ?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>