import { Component, OnInit } from '@angular/core';
import { StockService } from "../service/stock/stock.service";
import { ExcelService } from "../service/excel/excel.service";
// import { SummarySearchStockDTO } from '../service/excel/SummarySearchStockDTO';
@Component({
  selector: 'app-item-stock-details',
  templateUrl: './item-stock-details.component.html',
  styleUrls: ['./item-stock-details.component.css']
})
export class ItemStockDetailsComponent implements OnInit {

  allItemStock;
  resLocationStock;
  page: number = 1;
  search;

  // ExalData: Array<SummarySearchStockDTO> = [];
  constructor(
    private stockService: StockService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.getAllItemStock();
  }

  getAllItemStock() {
    this.stockService.getAllItemStock().subscribe(res => {
      this.allItemStock = res;
    })
  }

  getItemLocationStock(stock) {
    this.stockService.getItemStockByLocationAndItemName(stock.itemid).subscribe(res => {
      this.resLocationStock = res;
    })
  }
  ExalData=[];
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.allItemStock){
      this.ExalData.push({ "itemname":exal.itemname, "quantity": exal.quantity })
    }
    this.excelservice.exportAsExcelFile(this.ExalData, 'LocationWiseStock');
 }
}
