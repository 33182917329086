<!-- MultiStep Form -->
<div class="container-fluid" id="grad1">
    <div class="row justify-content-center mt-0">
        <div class="col-11 col-sm-9 col-md-7 col-lg-9 text-center p-0 mt-3" style="margin-bottom: 6rem !important;">
            <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                <!-- <h2><strong>Sign Up Your User Account</strong></h2>
                <p>Fill all form field to go to next step</p> -->
                <!-- <h2><strong>Sign Up Your User Account</strong></h2> -->
                <h2>Note:- Please do not change the header of excel file.</h2>
                <p>&nbsp;</p>
                <div class="row">
                    <div class="col-md-12 mx-0">
                        <form id="msform">
                            <!-- progressbar -->
                            <ul id="progressbar">
                                <li class="active" [ngClass]="step>=0 ? 'active' : 'inactive'" id="Company"><strong>Company</strong></li>
                                <li id="Location" [ngClass]="step>=1 ? 'active' : 'inactive'"><strong>Location</strong></li>
                                <!-- <li id="Category" [ngClass]="step>=2 ? 'active' : 'inactive'"><strong>Category</strong></li>
                                <li id="subCategory" [ngClass]="step>=3 ? 'active ' : 'inactive'"><strong>sub Category</strong></li>
                                <li id="Brand" [ngClass]="step>=4 ? 'active' : 'inactive'"><strong>Brand</strong></li>
                                <li id="Unit" [ngClass]="step>=5 ? 'active' : 'inactive'"><strong>Unit</strong></li>
                                <li id="GST" [ngClass]="step>=6 ? 'active' : 'inactive'"><strong>GST</strong></li> -->
                                <li id="Item" [ngClass]="step>=7 ? 'active' : 'inactive'"><strong>Item</strong></li>
                                <li id="confirm" style="display: none;"><strong>Confirm</strong></li>
                            </ul> 
                            <!-- fieldsets -->
                            <!-- Company fieldsets -->
                            <fieldset *ngIf="step==0">
                                <div class="form-card">
                                    <!-- <h2 class="fs-title">Company Information</h2>  -->
                                    <div class="row">
                                        <div class="col-lg-5 col-md-4">
                                            <div class="row g-3">
                                                <h2 class="fs-title">Company</h2> 
                                                <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 me-2">
                                                    <input type="text" name="txtCompany" [(ngModel)]="txtCompany" placeholder="Enter Company Name"
                                                    (focusout)="toUpperCaseCompany()" />
                                                </div> 
                                                <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <input type="text" name="txtGstNo" [(ngModel)]="txtGstNo" maxlength="15" placeholder="Enter GST Number" />
                                                </div> 
                                            </div>
                                            <div class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <button class="btn btn-primary me-2" type="submit" (click)="clearDataCompany()">Cancel</button>
                                                <button class="btn btn-primary" type="button" (click)="createCompany()">Save</button>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-8">
                                            <div class="portlet">
                                                <div class="portlet-content">
                                                    <div class="mt-3 table-responsive">
                                                        <table class="table table-hover table-striped table-bordered">
                                                            <thead class="tblheadercolor">
                                                                <tr>
                                                                    <th scope="col">Company Name</th>
                                                                    <th scope="col">GST Number</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let company of allCompany">
                                                                    <td>{{company.companyName}}</td>
                                                                    <td>{{company.gstNumber}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr *ngIf="allCompany < 1">
                                                                    <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset>
                            <!-- Location fieldsets -->
                            <fieldset *ngIf="step==1">
                                <div class="form-card">
                                    <h2 class="fs-title">Location</h2> 
                                    <div class="row g-3">
                                        <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12 me-2">
                                            <input type="text" name="txtLocation" [(ngModel)]="txtLocation" placeholder="Enter Location Name"
                                            (focusout)="toUpperCaseLocation()"/>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12 me-2">
                                            <textarea type="text" rows="1" name="txtAddress" [(ngModel)]="txtAddress" placeholder="Enter Address"></textarea>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-md-5 col-sm-12 col-12 me-2">
                                            <input type="text" name="txtPESLICNumber" [(ngModel)]="txtPESLICNumber" placeholder="PES LIC Number"/>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-md-5 col-sm-12 col-12 me-2">
                                            <input type="text" name="txtFERLICNumber" [(ngModel)]="txtFERLICNumber" placeholder="FER LIC Number"/>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-md-5 col-sm-12 col-12">
                                            <input type="text" name="txtSEEDLICNumber" [(ngModel)]="txtSEEDLICNumber" placeholder="SEED LIC Number"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="clearDataLocation()">Cancel</button>
                                        <button class="btn btn-primary" type="button" (click)="createLocation()">Save</button>
                                    </div>
                                </div> 
                                <!-- <input type="button" name="previous" class="previous action-button-previous" value="Previous" />  -->
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset>
                            <!-- Category fieldsets -->
                            <!-- <fieldset *ngIf="step==2">
                                <div class="form-card">
                                    <h2 class="fs-title">Category</h2> 
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                    <input type="file" name="txtExcelCategory" [(ngModel)]="txtExcelCategory" 
                                    placeholder="Enter Category Name" (change)="onExcelUploadCategory($event)" accept=".xls,.xlsx"/> 
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="exportExcelCategoryINIT()">Export To Excel</button>
                                        <button class="btn btn-primary" type="button" (click)="importandConvertExcelCategory()">Import Excel</button>
                                    </div>
                                </div> 
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset> -->
                            <!-- SubCategory fieldsets -->
                            <!-- <fieldset *ngIf="step==3">
                                <div class="form-card">
                                    <h2 class="fs-title">Sub Category</h2> 
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <input type="file" name="txtExcelSubCategory" [(ngModel)]="txtExcelSubCategory" (change)="onExcelUpload($event)"
                                        placeholder="Excel Sub Category" accept=".xls,.xlsx"/>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                        <button class="btn btn-primary" type="button" (click)="importandConvertExcel()">Import Excel</button>
                                    </div>
                                </div> 
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset> -->
                            <!-- Brand fieldsets -->
                            <!-- <fieldset *ngIf="step==4">
                                <div class="form-card">
                                    <h2 class="fs-title">Brand</h2> 
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <input type="file" name="txtExcelBrand" [(ngModel)]="txtExcelBrand" 
                                        placeholder="Enter Brand Name" (change)="onExcelUploadBrand($event)" accept=".xls,.xlsx"/> 
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="exportExcelBrandINIT()">Export To Excel</button>
                                        <button class="btn btn-primary" type="button" (click)="importandConvertExcelBrand()">Import Excel</button>
                                    </div>
                                </div> 
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset> -->
                            <!-- Unit fieldsets -->
                            <!-- <fieldset *ngIf="step==5">
                                <div class="form-card">
                                    <h2 class="fs-title">Unit</h2> 
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <input type="file" name="txtExcelUnit" [(ngModel)]="txtExcelUnit" 
                                        placeholder="Enter Unit Name" (change)="onExcelUploadUnit($event)" accept=".xls,.xlsx"/> 
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="exportExcelUnitINIT()">Export To Excel</button>
                                        <button class="btn btn-primary" type="button" (click)="importandConvertExcelUnit()">Import Excel</button>
                                    </div>
                                </div> 
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset> -->
                            <!-- GST fieldsets -->
                            <!-- <fieldset *ngIf="step==6">
                                <div class="form-card">
                                    <h2 class="fs-title">GST</h2> 
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <input type="file" name="txtExcelGST" [(ngModel)]="txtExcelGST" 
                                        placeholder="Enter GST Name" (change)="onExcelUploadGST($event)" accept=".xls,.xlsx"/> 
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="exportExcelGSTINIT()">Export To Excel</button>
                                        <button class="btn btn-primary" type="button" (click)="importandConvertExcelGST()">Import Excel</button>
                                    </div>
                                </div> 
                                <input type="button" name="next" class="next action-button" value="Next Step" (click)="next()"/>
                            </fieldset> -->
                            <!-- Item fieldsets -->
                            <fieldset id="itemblock" *ngIf="step==7">
                                <div class="form-card">
                                    <h2 class="fs-title">Item</h2> 
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <input type="file" name="txtExcelItem" [(ngModel)]="txtExcelItem" 
                                        placeholder="Enter Item Name" (change)="onExcelUploadItem($event)" accept=".xls,.xlsx"/> 
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <button class="btn btn-primary me-2" type="submit" (click)="exportExcelItemINIT()">Export To Excel</button>
                                        <button class="btn btn-primary" type="button" (click)="importandConvertExcelItem()">Import Excel</button>
                                    </div>
                                </div> 
                                <!-- <button class="btn btn-primary me-2" type="submit" (click)="getAllWizardDataCheck()">Confirm</button> -->
                                <!-- <input type="button" name="previous" class="previous action-button-previous" value="Previous" />  -->
                                <input type="button" name="next" class="next action-button" value="Confirm" (click)="getAllWizardDataCheck()" />
                            </fieldset>

                            <!-- <fieldset>
                                <div class="form-card">
                                    <h2 class="fs-title">Payment Information</h2>
                                    <div class="radio-group">
                                        <div class='radio' data-value="credit"><img src="https://i.imgur.com/XzOzVHZ.jpg" width="200px" height="100px"></div>
                                        <div class='radio' data-value="paypal"><img src="https://i.imgur.com/jXjwZlj.jpg" width="200px" height="100px"></div> <br>
                                    </div> <label class="pay">Card Holder Name*</label> <input type="text" name="holdername" placeholder="" />
                                    <div class="row">
                                        <div class="col-9"> <label class="pay">Card Number*</label> <input type="text" name="cardno" placeholder="" /> </div>
                                        <div class="col-3"> <label class="pay">CVC*</label> <input type="password" name="cvcpwd" placeholder="***" /> </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3"> <label class="pay">Expiry Date*</label> </div>
                                        <div class="col-9"> <select class="list-dt" id="month" name="expmonth">
                                                <option selected>Month</option>
                                                <option>January</option>
                                                <option>February</option>
                                                <option>March</option>
                                                <option>April</option>
                                                <option>May</option>
                                                <option>June</option>
                                                <option>July</option>
                                                <option>August</option>
                                                <option>September</option>
                                                <option>October</option>
                                                <option>November</option>
                                                <option>December</option>
                                            </select> <select class="list-dt" id="year" name="expyear">
                                                <option selected>Year</option>
                                            </select> </div>
                                    </div>
                                </div> 
                                <input type="button" name="previous" class="previous action-button-previous" value="Previous" /> 
                                <input type="button" name="make_payment" class="next action-button" value="Confirm" />
                            </fieldset> -->
                            <fieldset id="confirmblock" style="display: none;">
                                <div class="form-card">
                                    <h2 class="fs-title text-center">Success !</h2> <br><br>
                                    <div class="row justify-content-center">
                                        <div class="col-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" class="fit-image"> </div>
                                    </div> <br><br>
                                    <div class="row justify-content-center">
                                        <div class="col-7 text-center">
                                            <h5>You Have Successfully Set Up</h5>
                                        </div>
                                    </div>
                                </div>
                            </fieldset> 
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>