import { Component, OnInit } from '@angular/core';
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SubCategoryService } from "../service/sub_category/sub-category.service";
import { CategoryService } from "../service/category/category.service";
import { UnitService } from "../service/unit/unit.service";
import { BrandService } from "../service/brand/brand.service";
import { GSTService } from "../service/GST/gst.service";
import { ToastrService } from "ngx-toastr";
import { from } from 'rxjs';
import { ExcelService } from '../service/excel/excel.service';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-items-detail',
  templateUrl: './items-detail.component.html',
  styleUrls: ['./items-detail.component.css']
})
export class ItemsDetailComponent implements OnInit {

  resItem;
  resUpItem;
  txtitemNameid;
  txtitemName;
  txtitemCode;
  txtbrand;
  txtcategory;
  txtsubCategory;
  txtunit;
  txtgst;

  allBrand;
  allCategory;
  allSubCategory;
  allUnit;
  allGst;
  allItem;
  btnName = 'Save';
  search;
  page: number = 1;
  txtHSNCode;
  allSubCategoryByCategory;

  constructor(
    private SubCategoryService: SubCategoryService,
    private categoryservice: CategoryService,
    private itemsdetailservice: ItemsDetailService,
    private unitservice: UnitService,
    private brandservice: BrandService,
    private gstservice: GSTService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.getAllSubCategory();
    this.getAllCategory();
    this.getAllUnit();
    this.getAllBrand();
    this.getAllGst();
    this.getAllItem();
  }

  toUpperItem() {
    const temp = this.txtitemName;
    this.txtitemName = temp.toUpperCase();
    return this.txtitemName;
  }


  getAllSubCategory() {
    this.SubCategoryService.getAllSubCategory().subscribe(res => {
      this.allSubCategory = res;
      for(let subcat of res){
        // this.subCatArray.push(subcat.subCategoryName);
        this.subCatArray.push({"SubCategory":subcat.subCategoryName});
      }
    })
  }
  getAllCategory() {
    this.categoryservice.gatAllCategory().subscribe(res => {
      this.allCategory = res;
      for(let cat of res){
        // this.catArray.push(cat.categoryName);
        this.catArray.push({"Category":cat.categoryName});
      }
    })
  }
  getAllUnit() {
    this.unitservice.getAllUnit().subscribe(res => {
      this.allUnit = res;
      for(let unit of res){
        // this.unitArray.push(unit.unitName);
        this.unitArray.push({"Unit":unit.unitName});
      }
    })
  }
  getAllBrand() {
    this.brandservice.getAllBrand().subscribe(res => {
      this.allBrand = res;
      for(let brd of res){
        // this.brandArray.push(brd.name);
        this.brandArray.push({"Brand":brd.name});
      }
    })
  }
  getAllGst() {
    this.gstservice.getAllGst().subscribe(res => {
      this.allGst = res;
      for(let gst of res){
        // this.gstArray.push(gst.gstName);
        this.gstArray.push({"GST":gst.gstName});
      }
    })
  }

  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
    })
  }

  getSubCategoryByCategory(category) {
    if (this.txtcategory == null || this.txtcategory == '' || this.txtcategory == undefined || this.txtcategory == "-:Select Category Name:-") 
    {
      this.toastr.error("Category is required");
      this.allSubCategoryByCategory = null;
    } else {
      this.SubCategoryService.getSubCategoryByCategory(category).subscribe(res => {
        this.allSubCategoryByCategory = res;
      })
    }
  }

  clearData() {
    this.btnName = 'Save';
    this.txtitemName = '';
    this.txtitemCode = '';
    this.txtbrand = '';
    this.txtcategory = '';
    this.txtsubCategory = '';
    this.txtunit = '';
    this.txtgst = '';
    this.txtitemNameid = null;
    this.txtHSNCode = "";
  }

  createItemsDetail() {
    if (this.txtitemName == null || this.txtitemName == '' || this.txtitemName == undefined) {
      this.toastr.error("Item name is required");
    } else if (this.txtbrand == null || this.txtbrand == '' || this.txtbrand == undefined || this.txtbrand == "-:Select Brand Name:-") {
      this.toastr.error("Brand is required");
    } else if (this.txtcategory == null || this.txtcategory == '' || this.txtcategory == undefined || this.txtcategory == "-:Select Category Name:-") {
      this.toastr.error("Category is required");
    } else if (this.txtsubCategory == null || this.txtsubCategory == '' || this.txtsubCategory == undefined || this.txtsubCategory == "-:Select Sub Category Name:-") {
      this.toastr.error("Subcategory is required");
    } else if (this.txtunit == null || this.txtunit == '' || this.txtunit == undefined || this.txtunit == "-:Select Unit Name:-") {
      this.toastr.error("Unit is required");
    } else if (this.txtgst == null || this.txtgst == '' || this.txtgst == undefined || this.txtgst == "-:Select GST:-") {
      this.toastr.error("GST is required");
    } else if (this.txtHSNCode == null || this.txtHSNCode == '' || this.txtHSNCode == undefined) {
      this.toastr.error("HSN code is required");
    } else {
      if (this.txtitemNameid == null) {
        this.itemsdetailservice.createItemsDetail(this.txtitemName, this.txtitemCode, this.txtbrand, this.txtcategory, this.txtsubCategory, this.txtunit, this.txtgst,this.txtHSNCode)
          .subscribe(res => {
            this.resItem = res;
            this.clearData();
            this.getAllItem();
            this.toastr.success('Item save successfully');
          }, error => {
            // this.toastr.error('Item not saved');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.itemsdetailservice.updateitem(this.txtitemNameid, this.txtitemName, this.txtitemCode, this.txtbrand, this.txtcategory, this.txtsubCategory, this.txtunit, this.txtgst,this.txtHSNCode).subscribe(res => {
          this.resUpItem = res;
          this.clearData();
          this.getAllItem();
          this.toastr.success('Item updated successfully');
        }, error => {
          // this.toastr.error('Item not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateitem(item) {
    this.btnName = 'Update';
    this.txtitemNameid = item.itemid;
    this.txtitemName = item.itemName;
    this.txtitemCode = item.itemCode;
    this.txtbrand = item.brand.name;
    this.txtcategory = item.category.categoryName;
    this.txtsubCategory = item.subCategory.subCategoryName;
    this.txtunit = item.unit.unitName;
    this.txtgst = item.gst.gstName;
    this.txtHSNCode = item.hsnCode;
  }

  deleteid;
  deleteitem(item) {
    this.deleteid = item.itemid;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.itemsdetailservice.deleteItem(this.deleteid).subscribe(res => {
        this.resUpItem = res;
        this.clearData();
        this.getAllItem();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Item deleted successfully');
      }, error => {
        this.toastr.error('Item not deleted');
      })
    }
  }

  //Item
  txtExcelItem;
  ExcelDataItem = [];
  fileNameItem;
  selectedExcelItem;
  itemData;
  catArray = [];
  subCatArray = [];
  brandArray = [];
  unitArray = [];
  gstArray = [];
  exportExcelItemINIT(): void {
    this.ExcelDataItem = [];
    this.fileNameItem = "Item";
    this.excelservice.exportExcelItemINIT(this.fileNameItem,this.ExcelDataItem,this.catArray,this.subCatArray,this.brandArray,this.unitArray,this.gstArray);
  }
  onExcelUploadItem(event){
    this.selectedExcelItem = <File>event.target.files[0];
    // this.txtfilename = event.target.files[0].name;
  }
  importandConvertExcelItem() {
    if (this.selectedExcelItem) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelItem);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var abc = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[abc]);
        this.itemData = rowObject;
        var resCategory = [];
        for(let data of this.itemData){
          resCategory.push({"itemName":data["Item Name"],"categoryName":data["Category Name"],"subCategoryName":data["SubCategory Name"],
          "brandName":data["Brand Name"],"unitName":data["Unit Name"],"gstName":data["GST Name"],"hsnCode":data["HSN Code"]});
        }
        //save excel data inot server
        this.itemsdetailservice.importExcelItemINIT(resCategory).subscribe(res => {
        // console.log(res);
          this.toastr.success("Item detail save successfully");
          this.selectedExcelItem = '';
          this.txtExcelItem = '';
          // this.txtfilename = '';
          this.getAllItem();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.txtExcelItem = '';
        });
      }
    } else {
      this.toastr.error("Please select file");
    }
  }
}
