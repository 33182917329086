<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>StockValution</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Location <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtLocation" name="txtLocation">
                                                <option>-:Select Location:-</option>
                                                <option>All</option>
                                                <option *ngFor="let location of allLocation" [value]="location.locationName">{{location.locationName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="company" class="form-label">Company Name</label>
                                            <select class="form-select" id="company" [(ngModel)]="txtCompany" name="txtCompany">
                                                <option></option>
                                                <option *ngFor="let company of allCompany" [value]="company.companyName">{{company.companyName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="searchStockValution()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">StockValution Details</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col" class="text-center">Stock Quantity</th>
                                            <th scope="col">Stock Total Amount (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let stkvlu of reStockvalutionData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">   
                                            <td>{{stkvlu.item.itemName}}</td>
                                            <td>{{stkvlu.item.category.company.companyName}}</td>
                                            <td class="text-center">{{stkvlu.stockquantity}}</td>
                                            <td class="text-end">{{stkvlu.stockTotalAmount | number : '1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td><b>Total</b></td>
                                            <td class="text-center"><b>{{totalstockquantity}}</b></td>
                                            <td class="text-end"><b>{{totalstockTotalAmount | number : '1.2-2'}}</b></td>
                                        </tr>
                                    </tfoot>
                                    <tfoot>
                                        <tr *ngIf="reStockvalutionData < 1">
                                            <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>