import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PurchasePlanWorklistService } from '../service/purchase-plan-worklist/purchase-plan-worklist.service';
import {ThemePalette} from '@angular/material/core';
declare var $: any;

@Component({
  selector: 'app-purchase-plan-worklist',
  templateUrl: './purchase-plan-worklist.component.html',
  styleUrls: ['./purchase-plan-worklist.component.css']
})
export class PurchasePlanWorklistComponent implements OnInit {
  pagePending: number = 1;
  pagedeactiveted: number = 1;
  pageOnlinePending: number = 1;
  pageReceived: number = 1;
  pagePaymentFailed: number = 1;
  searchPending;
  searchdeactiveted;
  searchOnlinePending;
  searchReceived;
  searchPaymentFailed;
  allStatusWiseData;
  
  optradio = "Cash";
  txtBankName;
  txtChequeNo;
  txtNarration;
  isDisabled: boolean = true;
  // color: ThemePalette = 'warn';
  color: ThemePalette = 'primary';
  // color: ThemePalette = 'accent';
  public loading: boolean = false;

  constructor(
    private purchasePlanWorklistService: PurchasePlanWorklistService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.searchStatusWiseData('PENDING');
  }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }

  searchStatusWiseData(status) {
    // this.txtStartDate = new Date();
    // var ltime = this.txtStartDate.getTime() + days * 24 * 60 * 60 * 1000;
    // this.txtEndDate = new Date(ltime);
    // this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    // this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    // console.log("this due date after", this.txtEndDate);
    this.purchasePlanWorklistService.searchStatusWiseData(status).subscribe(res => {
      this.allStatusWiseData = res;
      console.log(this.allStatusWiseData);
    }, error => {

    })
  }

  txtPurchaseId;
  getPurchaseDetail(plan){
  console.log(plan);
  this.txtPurchaseId = plan.planpurchaseId;
  }
  resPurchasePlan;
  movePendingToReceive() {
    // var bankname = this.txtBankName;
    // var chequeNo = this.txtChequeNo;
    // if (this.optradio == "Bank" && (this.txtBankName == null || this.txtBankName == '' || this.txtBankName == undefined || (this.txtBankName = bankname.trim()) == "")) {
    //   this.toastr.error("Bank name is required");
    // } else if (this.optradio == "Bank" && (this.txtChequeNo == null || this.txtChequeNo == '' || this.txtChequeNo == undefined || (this.txtChequeNo = chequeNo.trim()) == "")) {
    //   this.toastr.error("Cheque number is required");
    // } else {
      this.purchasePlanWorklistService.movePendingToReceive(this.txtPurchaseId, this.txtBankName, this.txtChequeNo, this.optradio, this.txtNarration)
        .subscribe(res => {
          this.resPurchasePlan = res;
          this.toastr.success('Payment detail save successfully');
          this.searchStatusWiseData("PENDING");
          $('#pendingToReceiveModal').modal('hide');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
    // }
  }

  onItemChange(value) {
    console.log(value);
    this.optradio = value;
    if (value == "Bank") {
      console.log(value);
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
  clearData(){
    this.optradio = "Cash";
    this.txtBankName = null;
    this.txtChequeNo = null;
    this.txtNarration = null;
  }

  getAcceptRejectDetail(plan){
    this.txtPurchaseId = plan.planpurchaseId;
  }
  resAccept;
  resReject;
  confirmAccept(){
    this.purchasePlanWorklistService.confirmAccept(this.txtPurchaseId, 'RECEIVED')
    .subscribe(res => {
      this.resAccept = res;
      this.toastr.success('Accept detail save successfully');
      this.searchStatusWiseData("ONLINEPENDING");
      $('#acceptModal').modal('hide');
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }
  confirmReject(){
    this.purchasePlanWorklistService.confirmReject(this.txtPurchaseId, 'PAYMENTFAILED')
    .subscribe(res => {
      this.resReject = res;
      this.toastr.success('Reject detail save successfully');
      $('#rejectModal').modal('hide');
      this.searchStatusWiseData("ONLINEPENDING");
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  resuserActive;
  resuserDeactive;
  checkDisableUser(plan) {
    if (plan.activeUser == true) {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.purchasePlanWorklistService.setActiveUserDetail(plan.planpurchaseId).subscribe(res => {
        this.resuserActive = res;
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
        }
        this.toastr.success('User active successfully');
        this.searchStatusWiseData("RECEIVED");
      }, error => {
        // this.toastr.error('User not active');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
        plan.activeUser = false;
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        this.searchStatusWiseData("RECEIVED");
      });
    } else {
      this.purchasePlanWorklistService.setDeactiveUserDetail(plan.planpurchaseId).subscribe(res => {
        this.resuserDeactive = res;
        this.toastr.success('User deactive successfully');
        this.searchStatusWiseData("RECEIVED");
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
        plan.activeUser = true;
        this.searchStatusWiseData("RECEIVED");
      });
    }
  }

  contactPerson;
  contactPersonMobile;
  address;
  email;
  chequeNo;
  bankName;
  extraLocationAmount;
  extraplanLocationLimit;
  extraUserAmount;
  extraplanUserLimit;
  planDays;
  planPrice;
  planAmount;
  planNumberOfLocation;
  planNumberOfUser;
  totalLocation;
  totalUser;
  narration;
  getSubPurchaseDetail(plan){
    if (plan.planAmount != null){
      this.planAmount = plan.planAmount;
    } else {
      this.planAmount = 0;
    }
    if (plan.extraLocation != null || plan.extraLocation != undefined){
      this.extraLocationAmount = plan.extraLocation.amount;
      this.totalLocation = parseFloat(Number(Number(plan.plan.numberOfLocation) + Number(plan.extraLocation.planLocationLimit)).toFixed(2));
    } else {
      this.extraLocationAmount = 0;
      this.totalLocation = parseFloat(Number(plan.plan.numberOfLocation).toFixed(2));
    }
    if (plan.extraUser != null || plan.extraUser != undefined){
      this.extraUserAmount = plan.extraUser.amount;
      this.totalUser = parseFloat(Number(Number(plan.plan.numberOfUser) + Number(plan.extraUser.planUserLimit)).toFixed(2));
    } else {
      this.extraUserAmount = 0;
      this.totalUser = parseFloat(Number(plan.plan.numberOfUser).toFixed(2));
    }
    // this.planNumberOfLocation = plan.plan.numberOfLocation;
    // this.planNumberOfUser = plan.plan.numberOfUser;
    // this.extraplanLocationLimit = plan.extraLocation.planLocationLimit;
    // this.extraplanUserLimit = plan.extraUser.planUserLimit;
    if(plan.extraLocation != null) {
      this.extraLocationAmount = plan.extraLocation.amount;
      this.extraplanLocationLimit = plan.extraLocation.planLocationLimit;
    }
    if(plan.extraUser != null) {
      this.extraUserAmount = plan.extraUser.amount;
      this.extraplanUserLimit = plan.extraUser.planUserLimit;
    }
    this.planDays = plan.planpriceId.planDays;
    this.planPrice = plan.planpriceId.planPrice;
    this.contactPerson = plan.contactPerson;
    this.contactPersonMobile = plan.contactPersonMobile;
    this.address = plan.address;
    this.email = plan.email;
    this.chequeNo = plan.chequeNo;
    this.bankName = plan.bankName;
    this.narration = plan.narration;
  }

  resBillPdfPlanPurchase;
  openPdfResponsePlanPurchase;
  getPlanPurchsePDF(plan){
    this.purchasePlanWorklistService.downloadPlanPurchaseBill(plan.planpurchaseId).subscribe((data) => {
      this.resBillPdfPlanPurchase = data;

      var newBlob1 = new Blob([this.resBillPdfPlanPurchase], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_Bij_Bhandar.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponsePlanPurchase.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfPlanPurchase = '';
      this.openPdfResponsePlanPurchase = '';
    })
  }

  txtEmail;
  emailplanPurchaseId;
  getsendEmailData(plan) {
    this.emailplanPurchaseId = plan.planpurchaseId;
    if (plan.email != null) {
      this.txtEmail = plan.email;
    }
  }

  clearEmail(){
    this.txtEmail = null;
  }

  planPurchaseMailWithSendGrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.purchasePlanWorklistService.planPurchaseMailWithSendGrid(this.emailplanPurchaseId,this.txtEmail).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#emailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }
}
