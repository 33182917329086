import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: Http,
  ) { }

  createLocation(txtLocation: string, txtAddress: string, txtPESLICNumber: string, txtFERLICNumber: string, txtSEEDLICNumber: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      locationName : txtLocation,
      address : txtAddress,
      peslicNumber : txtPESLICNumber,
      ferlicNumber : txtFERLICNumber,
      seedlicNumber : txtSEEDLICNumber,
    });
    return this.http.post('/api/create/location', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateLocation(txtLocationId: string, txtLocation: string, txtAddress: string, txtPESLICNumber: string, txtFERLICNumber: string, txtSEEDLICNumber: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      locationName : txtLocation,
      id : txtLocationId,
      address : txtAddress,
      peslicNumber : txtPESLICNumber,
      ferlicNumber : txtFERLICNumber,
      seedlicNumber : txtSEEDLICNumber,

    });
    return this.http.put('/api/update/location', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllLocation(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/location', { headers })
      .pipe(map(res => res.json()));

  }
  getAllWithoutCurrentLocation(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/location/withoutcurrentlocation', { headers })
      .pipe(map(res => res.json()));
  }

  deleteLocation(txtLocationId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id : txtLocationId
    });
    return this.http.put('/api/delete/location', body, { headers })
      .pipe(map(res => res.json()));
  }

}
