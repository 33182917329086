import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { ExpenseLedgerService } from '../service/expense-ledger/expense-ledger.service';
import { SearchExpenseLedger } from '../service/expense-ledger/searcExpenseLedger';
declare var $: any;

@Component({
  selector: 'app-expense-ledger',
  templateUrl: './expense-ledger.component.html',
  styleUrls: ['./expense-ledger.component.css']
})
export class ExpenseLedgerComponent implements OnInit {
  // txtFormDate = '2021-04-01';
  txtFormDate;
  txtToDate;
  reSearchData;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  search;
  page = 1;
  txtTotalDebit = 0;
  txtLedgerName;
  public searchLedger = '';
  searchLedgerName = [];

  constructor(
    private toastr: ToastrService,
    private expenseLedgerService: ExpenseLedgerService,
  ) { }

  ngOnInit(): void {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtFormDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtToDate = new Date();
    this.getAllExpenseLedger();
  }

  tempLedger;
  selectedLedger(ledger) {
    this.tempLedger = ledger.ledgerId;
    this.searchLedger = ledger.ledgerName;
    console.log(ledger);
  }

  allExpenseLedger;
  getAllExpenseLedger() {
    this.expenseLedgerService.getAllExpenseLedger().subscribe(res => {
      this.allExpenseLedger = res;
      for (let item of this.allExpenseLedger) {
        var temp = new SearchExpenseLedger();
        temp.ledgerName = item.ledgerName;
        temp.ledgerId = item.ledgerId;
        this.searchLedgerName.push(temp)
      }
      console.log(this.allExpenseLedger);
    })
  }

  searchExpenseLedger() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      this.reSearchData = "";
      if (this.searchLedger == "" || this.searchLedger == null || this.searchLedger == undefined) {
        this.expenseLedgerService.getAllLedgerDetail(this.txtFormDate, this.txtToDate).subscribe(res => {
          this.reSearchData = res;
          this.searchLedger = "";
          console.log(this.reSearchData);
          this.isEnable = true;
          this.txtTotalDebit = 0;
          for (let ii = 0; ii < this.reSearchData.length; ii++) {
            this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
          }
        }, error => {
          this.searchLedger = "";
          this.toastr.error("something went wrong");
        });
      } else {
        this.expenseLedgerService.getLedgerDetail(this.tempLedger, this.txtFormDate, this.txtToDate).subscribe(res => {
          this.reSearchData = res;
          this.searchLedger = "";
          console.log(this.reSearchData);
          this.isEnable = true;
          this.txtTotalDebit = 0;
          for (let ii = 0; ii < this.reSearchData.length; ii++) {
            this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
          }
        }, error => {
          this.searchLedger = "";
          this.toastr.error("something went wrong");
        });
      }
    }
  }

}