export class cdnrDto{
    GSTINUINofRecipient: string;
    ReceiverName: string;
    NoteNumber: string;
    NoteDate: string;
    NoteType: string;
    PlaceOfSupply: string;
    ReverseCharge: string;
    NoteSupplyType: string;
    NoteValue: number;
    ApplicableofTaxRate: string;
    Rate: number;
    TaxableValue: number;
    CessAmount: number;
}