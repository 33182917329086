import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }


  getAllItemStockLocationWise() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/api/getAll/itemby/stock', { headers })
      .pipe(map(res => res.json()));

  }

  getAllItemStock() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/stock/location/allitem', { headers })
      .pipe(map(res => res.json()));

  }

  getAllItemStockExcel(txtCompany: Array<any>,txtCategory: Array<any>) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/excel/stockby/item/batchno/company/'+txtCompany+'/category/'+txtCategory, { headers })
      .pipe(map(res => res.json()));
  }
  
  getItemStockByLocationAndItemName(itemname: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   itemName : itemname,
    // });
    return this.http.get('/api/get/stock/location/item/' + itemname, { headers })
      .pipe(map(res => res.json()));
  }

  searchPurchaseStockItemWise(startdate: string, enddate: string, location: string, item: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/purchase/stock/location/' + location + '/item/' + item + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchPurchaseStockCompanyWise(startdate: string, enddate: string, location: string, company: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/purchase/stock/location/' + location + '/company/' + company + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchPurchaseStockSummaryWise(startdate: string, enddate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/summary/purchase/stock/location/' + location + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  searchSaleStockItemWise(startdate: string, enddate: string, location: string, item: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/sale/stock/location/' + location + '/item/' + item + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchSaleStockCompanyWise(startdate: string, enddate: string, location: string, company: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/sale/stock/location/' + location + '/company/' + company + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchSaleStockSummaryWise(startdate: string, enddate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/summary/sale/stock/location/' + location + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  searchSaleReturnStockItemWise(startdate: string, enddate: string, location: string, item: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/salereturn/stock/location/' + location + '/item/' + item + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchSaleReturnStockCompanyWise(startdate: string, enddate: string, location: string, company: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/salereturn/stock/location/' + location + '/company/' + company + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchSaleReturnStockSummaryWise(startdate: string, enddate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/summary/salereturn/stock/location/' + location + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  searchPurchaseReturnStockItemWise(startdate: string, enddate: string, location: string, item: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/purchasereturn/stock/location/' + location + '/item/' + item + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchPurchaseReturnStockCompanyWise(startdate: string, enddate: string, location: string,company: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/purchasereturn/stock/location/' + location +  '/company/' + company +'/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  searchPurchaseReturnStockSummaryWise(startdate: string, enddate: string, location: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/summary/purchasereturn/stock/location/' + location + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  //PurchaseBYMonth
  getSumPurchaseCaratByMonthAndCrtYear(txtPurchaseYear){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sumof/purchase/'+ txtPurchaseYear, { headers })
      .pipe(map(res => res.json()));

  }

  //top10SaleItemname
  getTop3SalePacketName(year){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/top10/sale/itemname/mapyear/' + year, { headers })
      .pipe(map(res => res.json()));
  }

  getTop3SaleItemNameByLocation(location: string, txtYear: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/top10/sale/itemname/map/'+location + '/' + txtYear, { headers })
      .pipe(map(res => res.json()));
  }

  //Sale Overall Data 
  getSumSaleByYear(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sumof/sale', { headers })
      .pipe(map(res => res.json()));
  }

  getSumSaleByYearType(txtCreditCashYear: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sumof/sale/type/' + txtCreditCashYear, { headers })
      .pipe(map(res => res.json()));
  }

  getSumSaleByYearTypeByLocation(location: string, txtCreditCashYear: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sumof/sale/type/'+location + '/' + txtCreditCashYear, { headers })
      .pipe(map(res => res.json()));
  }

  getSumAccount(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sumof/account', { headers })
      .pipe(map(res => res.json()));
  }
  getLedgerDetail(partyid:string,startdate:string,enddate:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledger/detail/'+partyid+'/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }

  getQtyBatchWiseByItemId(itemid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/qtyby/batchno/item/' + itemid, { headers })
      .pipe(map(res => res.json()));
  }

  getAllNigamDailyReport(startdate: string, enddate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/nigam/daily/report/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }
  getAllBhandarDailyReport(startdate: string, enddate: string, companyId){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/Bhandar/daily/report/date/'+startdate+'/'+enddate + '/' +companyId, { headers })
      .pipe(map(res => res.json()));
  }

  getsearchStockValution(location: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/stockvalution/' +location, { headers })
      .pipe(map(res => res.json()));
  }
  getsearchStockValutionAll(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/stockvalution', { headers })
      .pipe(map(res => res.json()));
  }
  
  getsearchStockValutionAllByDate(startdate,enddate){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/stockvalution/date/'+startdate+'/'+enddate , { headers })
      .pipe(map(res => res.json()));
  }

  getNigamDailySummaryData(startdate,enddate,companyid,location,type){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/nigam/daily/summary/company/' +companyid+ '/location/' +location+ '/type/' +type+ '/date/' +startdate+ '/' +enddate , { headers })
      .pipe(map(res => res.json()));
  }
  getBhandarDailySummaryData(startdate,enddate,companyid,location,type){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/bhandar/daily/summary/company/' +companyid+ '/location/' +location+ '/type/' +type+ '/date/' +startdate+ '/' +enddate , { headers })
      .pipe(map(res => res.json()));
  }
  getCollectionData(startdate,enddate,companyid,location){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/collection/summary/company/' +companyid+ '/location/' +location+ '/date/' +startdate+ '/' +enddate, { headers })
      .pipe(map(res => res.json()));
  }
  getExpenseData(startdate,enddate,companyid,location){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/expense/summary/company/' +companyid+ '/location/' +location+ '/date/' +startdate+ '/' +enddate, { headers })
      .pipe(map(res => res.json()));
  }
  getBankBookData(startdate,enddate){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/bankbook/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseLedgerDetail(purchaseid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/purchase/' +purchaseid, { headers })
      .pipe(map(res => res.json()));
  }
  getPurchaseReturnLedgerDetail(purchasereturnid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/purchasereturn/' +purchasereturnid, { headers })
      .pipe(map(res => res.json()));
  }
  getSaleLedgerDetail(saleid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/sale/' +saleid, { headers })
      .pipe(map(res => res.json()));
  }
  getSaleReturnLedgerDetail(salereturnid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/salereturn/' +salereturnid, { headers })
      .pipe(map(res => res.json()));
  }
  getSaleChallanLedgerDetail(saleid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/salechallan/' +saleid, { headers })
      .pipe(map(res => res.json()));
  }

  getPaymentVoucherLedgerDetail(paymentvoucherid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/paymentvoucher/' +paymentvoucherid, { headers })
      .pipe(map(res => res.json()));
  }
  getReceiptVoucherLedgerDetail(receiptvoucherid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/receiptvoucher/' +receiptvoucherid, { headers })
      .pipe(map(res => res.json()));
  }
  getCreditVoucherLedgerDetail(creditnoteid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/creditnote/' +creditnoteid, { headers })
      .pipe(map(res => res.json()));
  }
  getDebitVoucherLedgerDetail(debitnote){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/get/debitnote/' +debitnote, { headers })
      .pipe(map(res => res.json()));
  }

  getLedgerDetailPartyAndCompanyId(partyid:string,companyid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/' + partyid + '/company/' + companyid , { headers })
      .pipe(map(res => res.json()));
  }
  getLedgerDetailCompany(ledgerid:string,startdate:string,enddate:string,companyid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledger/detail/' +ledgerid+ '/date/' +startdate+ '/' +enddate+ '/company/' +companyid , { headers })
      .pipe(map(res => res.json()));
  }

  getAllPartyClosingReport(startdate: string, enddate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledger/closing/location/date/' + startdate + '/' + enddate , { headers })
      .pipe(map(res => res.json()));
  }
  
  getAllPartyClosingReportByCompany(startdate: string, enddate: string, txtCompany : string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledger/closing/location/date/'+ txtCompany + '/' + startdate + '/' + enddate , { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseReport(startdate: string, enddate: string,companyId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/purchase/report/company/'+companyId +'/date/' + startdate + '/' + enddate , { headers })
      .pipe(map(res => res.json()));
  }
  public downloadPurchaseReport(startdate, enddate, companyid): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/server/purchasedetail/search/bill/' + startdate + '/'  + enddate +'/' + companyid , { headers: header, responseType: 'blob' });
  }

  getSaleReport(startdate: string, enddate: string,companyId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/sale/report/company/'+companyId +'/date/' + startdate + '/' + enddate , { headers })
      .pipe(map(res => res.json()));
  }
  public downloadSaleReport(startdate, enddate, companyid): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/server/saledetail/search/bill/' + startdate + '/'  + enddate +'/' + companyid , { headers: header, responseType: 'blob' });
  }

  getAllProfitAndLoss(startdate: string, enddate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/profitlose/' + startdate + '/' + enddate , { headers })
      .pipe(map(res => res.json()));
  }
  getAllProfitAndLossCompany(txtcompany: string, startdate: string, enddate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/profitlose/company/' + txtcompany + '/'+ startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  public downloadProfitLossPDF(companyid: string, startdate, enddate,txtStock : number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/server/profitlose/company/' + companyid + '/' + startdate + '/' + enddate + '/' + txtStock, { headers: header, responseType: 'blob' });
  }

  public downloadLedgerDetailBill(ledgerid: string, startdate, enddate): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/ledger/server/report/' + ledgerid + '/date/' + startdate + '/' + enddate , { headers: header, responseType: 'blob' });
  }
  public downloadLedgerCompanyDetailBill(ledgerid: string, startdate, enddate, companyid): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/ledger/server/report/' + ledgerid + '/company/' + companyid + '/date/' + startdate + '/' + enddate , { headers: header, responseType: 'blob' });
  }

  sendMailAllLedgerData(ledgerid: string, startdate: string, enddate:string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/ledger/server/report/' + ledgerid +'/email/' + email + '/date/' + startdate + '/' + enddate , { headers })
    .pipe(map(res => res));
  }

  sendMailCompanyLedgerData(ledgerid: string,companyid: string, startdate: string, enddate:string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/ledger/server/report/' + ledgerid +'/company/'+companyid+'/email/' + email + '/date/' + startdate + '/' + enddate , { headers })
    .pipe(map(res => res));
  }

  sendLedgerMessage(partyId: string, PartyClosingBalance: number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/send/openingbalance/' + partyId + '/' + PartyClosingBalance , { headers })
      .pipe(map(res => res));
  }

  allLedgerMailWithSendGrid(partyid: string, startdate: string, enddate:string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyid: partyid,
      startdate: startdate,
      enddate: enddate,
      email: email
    });
    return this.http.post('/api/send/ledger', body, { headers })
    .pipe(map(res => res));
  }

  companyLedgerMailWithSendGrid(partyid: string,companyid: string, startdate: string, enddate:string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      partyid: partyid,
      companyid: companyid,
      startdate: startdate,
      enddate: enddate,
      email: email
    });
    return this.http.post('/api/send/ledger/companywise', body , { headers })
    .pipe(map(res => res));
  }

   //Purchase Return Report
   getPurchaseReturnReport(startdate: string, enddate: string, companyId: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/purchasereturn/report/company/' + companyId + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  public downloadPurchaseReturnReport(startdate, enddate, companyid): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/server/purchasereturndetail/search/bill/' + startdate + '/'  + enddate +'/' + companyid , { headers: header, responseType: 'blob' });
  }

  //Sale ReturnReport
  getSaleReturnReport(startdate: string, enddate: string, companyId: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/salereturn/report/company/' + companyId + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }
  public downloadSaleReturnReport(startdate, enddate, companyid): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/server/salereturndetail/search/bill/' + startdate + '/'  + enddate +'/' + companyid , { headers: header, responseType: 'blob' });
  }

  //Vender List
  getAllVenderList() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/vender/user', { headers })
      .pipe(map(res => res.json()));
  }

  getAllItemStockDemo() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/api/create/list/stock/demo', { headers })
      .pipe(map(res => res.json()));

  }

  getAllItemStockBalance() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/get/party/opening/companywise', { headers })
      .pipe(map(res => res.json()));
  }

  searchProductReportOverAll(startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/report/product/overall/sale/purchase/stock/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }

  getProductPurchaseDtlOpeningStock(productid: string,startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // return this.http.get('/api/search/report/product/history/openingstock/date/'+startdate+'/'+enddate+'/'+productid, { headers })
    return this.http.get('/api/search/report/product/history/date/'+startdate+'/'+enddate+'/'+productid, { headers })
      .pipe(map(res => res.json()));
  }
  getProductPurchaseDtl(productid: string,startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/search/report/product/history/date/'+startdate+'/'+enddate+'/'+productid, { headers })
      .pipe(map(res => res.json()));
  }

  getAllPurchaseReportCompanyWise(startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchase/report/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }
  getAllSaleReportCompanyWise(startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sale/report/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }
  getAllPaymentCreditReportCompanyWise(startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/payment/credit/report/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }
  getAllReceiptDebitReportCompanyWise(startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/receipt/debit/report/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }

  getDetailOfYouWillPayOrGet(startdate: string, enddate: string,type: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/youwill/getpay/dtl/'+type+'/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }

} 
