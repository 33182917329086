import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { PlanService } from '../service/plan/plan.service';
import { PurchasePlanService } from '../service/purchase_plan/purchase-plan.service';
import { DatePipe } from '@angular/common';
import { PartyService } from '../service/party/party.service';
declare var $: any;

@Component({
  selector: 'app-purchase-plan',
  templateUrl: './purchase-plan.component.html',
  styleUrls: ['./purchase-plan.component.css']
})
export class PurchasePlanComponent implements OnInit {
  txtPurchasePlanId;
  txtPlanName;
  txtOwnerName;
  txtOwnerMobileNumber;
  txtContactPerson;
  txtContactPersonMobileNumber;
  txtAddress;
  txtMailId;
  txtGstNumber;
  txtOrganizationName;
  txtExtraUser;
  txtExtraLocation;
  optradio;
  // optradio = "Cash";
  txtBankName;
  txtChequeNo;
  isDisabled: boolean = true;
  txtPlanAmount = 0;
  txtFinalAmount = 0;
  // txtActiveDate;
  // txtEndDate;
  allPlan;
  allPurchasePlan;
  allAdditionalPlan;
  resPurchasePlan;
  resUpPurchasePlan;
  resDelPurchasePlan;
  btnName = 'Save';
  search;
  page: number = 1;
  datePipe = new DatePipe("en-US");
  txtPlanDays;
  txtState;
  txtStateCode;
  allState;
  txtLedgerView: any;
  txtMarketingView: any;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;

  constructor(
    private planService: PlanService,
    private purchasePlanService: PurchasePlanService,
    private PartyService: PartyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.txtActiveDate = this.datePipe.transform(this.txtActiveDate, "dd/MM/yyyy");
    // this.txtActiveDate = new Date();
    // this.txtEndDate = this.datePipe.transform(this.txtEndDate, "dd/MM/yyyy");
    // this.txtEndDate = new Date();
    this.getAllPlan();
    this.getAllPurchasePlan();
    this.getAllState();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  toUpperPlan() {
    const temp = this.txtPlanName;
    this.txtPlanName = temp.toUpperCase();
    return this.txtPlanName;
  }
  toUpperCompanyName() {
    const temp = this.txtOrganizationName;
    this.txtOrganizationName = temp.toUpperCase();
    return this.txtOrganizationName;
  }
  getAllState() {
    this.PartyService.getAllState().subscribe(res => {
      this.allState = res;
    })
  }
  stateCode(){
    this.txtStateCode = this.txtState.stateCode;
  }

  getAllPlan() {
    this.planService.gatAllPlan().subscribe(res => {
      this.allPlan = res;
    })
  }

  resExtraUser;
  resExtraLocation;
  extraPlanLocationLimit;
  extraPlanLocationAmount;
  extraPlanUserLimit;
  extraPlanUserAmount;
  getAllPurchasePlan() {
    this.purchasePlanService.getAllPurchasePlan().subscribe(res => {
      this.allPurchasePlan = res;
    })
  }

  contactPerson;
  contactPersonMobile;
  address;
  state;
  statecode;
  email;
  gstNumber;
  organizationName;
  planAmount;
  totalLocation;
  totalUser;
  extraLocationAmountData;
  extraUserAmountData;
  txtLedgerDisplay;
  txtMarketinfDisplay;
  txtImageDisplay;

  getPurchaseDetail(plan){
    if (plan.planAmount != null){
      this.planAmount = plan.planAmount;
    } else {
      this.planAmount = 0;
    }
    if (plan.extraLocation != null || plan.extraLocation != undefined){
      this.extraLocationAmountData = plan.extraLocation.amount;
      this.totalLocation = parseFloat(Number(Number(plan.plan.numberOfLocation) + Number(plan.extraLocation.planLocationLimit)).toFixed(2));
    } else {
      this.extraLocationAmountData = 0;
      this.totalLocation = parseFloat(Number(plan.plan.numberOfLocation).toFixed(2));
    }
    if (plan.extraUser != null || plan.extraUser != undefined){
      this.extraUserAmountData = plan.extraUser.amount;
      this.totalUser = parseFloat(Number(Number(plan.plan.numberOfUser) + Number(plan.extraUser.planUserLimit)).toFixed(2));
    } else {
      this.extraUserAmountData = 0;
      this.totalUser = parseFloat(Number(plan.plan.numberOfUser).toFixed(2));
    }
    this.contactPerson = plan.contactPerson;
    this.contactPersonMobile = plan.contactPersonMobile;
    this.address = plan.address;
    this.email = plan.email;
    this.gstNumber = plan.gstNumber;
    this.organizationName = plan.organizationName;
    this.state = plan.state;
    this.statecode = plan.stateCode;
    this.txtLedgerDisplay = plan.ledger;
    this.txtMarketinfDisplay = plan.marketing;
    this.txtImageDisplay = plan.image;
  }

  allLocationData = [];
  allUserData = [];
  extraLocation;
  extraLocationAmount;
  extraLocationId;
  extraUser;
  extraUserAmount;
  extraUserId;
  allPriceList;
  getAllAdditionalPlan() {
    this.purchasePlanService.getAllAdditionalPlan(this.txtPlanName.planId).subscribe(res => {
      this.allAdditionalPlan = res;
      this.allLocationData = res.locationresponse;
      this.allUserData = res.userresponse;
      this.allPriceList = res.planprice;
      // for(let loc of this.allLocationData){
      //   this.extraLocation = loc.planLocationLimit;
      //   this.extraLocationAmount = loc.amount;
      //   this.extraLocationId = loc.planlocationId;
      // }
      // for(let usr of this.allUserData){
      //   this.extraUser = usr.planUserLimit;
      //   this.extraUserAmount = usr.amount;
      //   this.extraUserId = usr.planuserId;
      // }
      this.txtPlanDays = res.planprice.find(pp=>{
        return pp.planpriceId === this.txtPlanDays.planpriceId;
      });
      if(res.locationresponse !=[] || res.locationresponse.length != 0) {
        this.txtExtraLocation = res.locationresponse.find(lp=>{
          return lp.planlocationId === this.txtExtraLocation.planlocationId;
        });
      }
      if(res.userresponse !=[] || res.userresponse.length != 0) {
        this.txtExtraUser = res.userresponse.find(up=>{
          return up.planuserId === this.txtExtraUser.planuserId;
        });
      }
    })
  }

  toUpperOwnerName(){
    const temp = this.txtOwnerName;
    this.txtOwnerName = temp.toUpperCase();
    return this.txtOwnerName;
  }
  toUpperContactPerson(){
    const temp = this.txtContactPerson;
    this.txtContactPerson = temp.toUpperCase();
    return this.txtContactPerson;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtPlanName = null;
    this.txtOwnerName = null;
    this.txtOwnerMobileNumber = null;
    this.txtContactPerson = null;
    this.txtContactPersonMobileNumber = null;
    this.txtExtraUser = null;
    this.txtExtraLocation = null;
    this.txtPlanAmount = 0;
    this.txtFinalAmount = 0;
    this.txtBankName = null;
    this.txtChequeNo = null;
    this.extraLocationId = null;
    this.extraUserId = null;
    this.txtAddress = null;
    this.txtMailId = null;
    this.txtGstNumber = null;
    this.txtOrganizationName = null;
    this.optradio = '';
    // this.optradio = 'Cash';
    // this.txtActiveDate = new Date();
    // this.txtEndDate = new Date();
    this.txtPurchasePlanId = null;
    this.txtPlanDays = null;
    this.txtStateCode = null;
    this.txtState = null;
    this.txtLedgerView = null;
    this.txtMarketingView = null;
    this.txtImage = null;
    this.imagePreview = null;
    this.selectedImage = null;
  }

  createPurchasePlan() {
    var ownername = this.txtOwnerName;
    var orgname = this.txtOrganizationName;
    var bankname = this.txtBankName;
    var chequeNo = this.txtChequeNo;
    if (this.txtPlanName == null || this.txtPlanName == '' || this.txtPlanName == undefined || this.txtPlanName == "-:Select A Plan:-") {
      this.toastr.error("Plan name is required");
    } else if (this.txtPlanDays == null || this.txtPlanDays == undefined || this.txtPlanDays == '' || this.txtPlanDays == "-:Select A Plan Duration:-") {
      this.toastr.error("plan duration is required");
    } else if (this.txtOrganizationName == null || this.txtOrganizationName == undefined || (this.txtOrganizationName = orgname.trim()) == "") {
      this.toastr.error("Company name is required");
    } else if (this.txtOwnerName == null || this.txtOwnerName == '' || this.txtOwnerName == undefined || (this.txtOwnerName = ownername.trim()) == "") {
      this.toastr.error("Owner name is required");
    } else if (this.txtOwnerMobileNumber == null || this.txtOwnerMobileNumber == '' || this.txtOwnerMobileNumber == undefined) {
      this.toastr.error("Owner mobile Number is required");
    } else if (this.txtOwnerMobileNumber.length > 10 || this.txtOwnerMobileNumber.length < 10) {
      this.toastr.error("Owner mobile number must be 10 digit");
    } else if (this.txtState == null || this.txtState == undefined || this.txtState == '' || this.txtState == "-:Select State:-") {
      this.toastr.error("State is required");
    } else if (this.txtLedgerView == null || this.txtLedgerView == undefined || this.txtLedgerView == '' || this.txtLedgerView == "-:Select Ledger:-") {
      this.toastr.error("Ledger is required");
    } else if (this.txtMarketingView == null || this.txtMarketingView == undefined || this.txtMarketingView == '' || this.txtMarketingView == "-:Select Marketing:-") {
      this.toastr.error("Marketing is required");
    // } else if (this.optradio == "Bank" && (this.txtBankName == null || this.txtBankName == '' || this.txtBankName == undefined || (this.txtBankName = bankname.trim()) == "")) {
    //   this.toastr.error("Bank name is required");
    // } else if (this.optradio == "Bank" && (this.txtChequeNo == null || this.txtChequeNo == '' || this.txtChequeNo == undefined || (this.txtChequeNo = chequeNo.trim()) == "")) {
    //   this.toastr.error("Cheque number is required");
    // } else if(this.txtGstNumber == null || (this.txtGstNumber.length < 15 || this.txtGstNumber.length > 15)){
    //   this.toastr.error('Please enter proper GST number.');
    } else {
      var tempextrauser;
      var tempextralocation;
      if(this.txtExtraUser == null || this.txtExtraUser == '' || this.txtExtraUser == undefined || this.txtExtraUser == "-:Select A User:-"){
        tempextrauser = null;
      } else {
        tempextrauser = this.txtExtraUser.planuserId;
      }
      if(this.txtExtraLocation == null || this.txtExtraLocation == '' || this.txtExtraLocation == undefined || this.txtExtraLocation == "-:Select A Location:-" ){
        tempextralocation = null;
      } else {
        tempextralocation = this.txtExtraLocation.planlocationId;
      }
      // this.txtActiveDate = this.datePipe.transform(this.txtActiveDate, "dd/MM/yyyy");
      // this.txtEndDate = this.datePipe.transform(this.txtEndDate, "dd/MM/yyyy");
      if (this.txtPurchasePlanId == null) {
        // this.txtPlanAmount = this.txtPlanName.price;
        this.purchasePlanService.createPurchasePlan(this.txtPlanName.planName, this.txtOwnerName, this.txtOwnerMobileNumber, this.txtContactPerson, this.txtContactPersonMobileNumber,this.txtAddress,this.txtMailId,this.txtGstNumber,this.txtOrganizationName, tempextrauser, tempextralocation, this.txtBankName, this.txtChequeNo, this.txtFinalAmount, this.optradio
          ,this.txtPlanDays.planpriceId,this.txtState.stateName,this.txtStateCode,this.txtLedgerView,this.txtMarketingView)
          .subscribe(res => {
            this.resPurchasePlan = res;
            this.uploadImage(res);
            // this.clearData();
            // this.getAllPurchasePlan();
            this.toastr.success('Purchase Plan save successfully');
          }, error => {
            // this.toastr.error('Plan not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));

          })
      } else {
        this.purchasePlanService.updatePurchasePlan(this.txtPurchasePlanId, this.txtPlanName.planName, this.txtOwnerName, this.txtOwnerMobileNumber, this.txtContactPerson, this.txtContactPersonMobileNumber,this.txtAddress,this.txtMailId,this.txtGstNumber,this.txtOrganizationName, tempextrauser, tempextralocation, this.txtBankName, this.txtChequeNo, this.txtFinalAmount, this.optradio
          ,this.txtPlanDays.planpriceId,this.txtState.stateName,this.txtStateCode,this.txtLedgerView,this.txtMarketingView,this.txtImage).subscribe(res => {
          this.resUpPurchasePlan = res;
          this.uploadImage(res);
          // this.clearData();
          // this.getAllPurchasePlan();
          this.toastr.success('Purchase Plan updated successfully');
        }, error => {
          // this.toastr.error('Plan not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  getAmount() {
    this.txtPlanAmount = this.txtPlanDays.planPrice;
    // this.txtFinalAmount = this.txtPlanName.price +  this.extraUserAmount + this.extraLocationAmount;
    if((this.txtExtraUser == null || this.txtExtraUser == '' || this.txtExtraUser == undefined || this.txtExtraUser == "-:Select A User:-") &&
    (this.txtExtraLocation == null || this.txtExtraLocation == '' || this.txtExtraLocation == undefined || this.txtExtraLocation == "-:Select A Location:-")){
      this.txtFinalAmount = this.txtPlanDays.planPrice;
    }else if((this.txtExtraUser == null || this.txtExtraUser == '' || this.txtExtraUser == undefined || this.txtExtraUser == "-:Select A User:-")){
      this.txtFinalAmount = this.txtPlanDays.planPrice + this.extraLocationAmount;
    } else if((this.txtExtraLocation == null || this.txtExtraLocation == '' || this.txtExtraLocation == undefined || this.txtExtraLocation == "-:Select A Location:-")) {
      this.txtFinalAmount = this.txtPlanDays.planPrice + this.extraUserAmount;
    } else {
      this.txtFinalAmount = this.txtPlanDays.planPrice + this.extraUserAmount + this.extraLocationAmount;
    }
  }
changeExtraUser
  () {
    this.extraUserAmount = this.txtExtraUser.amount;
    this.getAmount();
  }
  changeExtraLocation() {
    this.extraLocationAmount = this.txtExtraLocation.amount;
    this.getAmount();
  }

  updatePurchasePlan(plan) {
    this.btnName = 'Update';
    this.txtPlanName = this.allPlan.find((abc: any) => {
      return abc.planId === plan.plan.planId
    });
    // this.txtPlanName = plan.plan;
    this.txtPurchasePlanId = plan.planpurchaseId;
    this.txtPlanDays = plan.planpriceId;
    if (plan.extraLocation != null){
      this.txtExtraLocation = plan.extraLocation;
      this.extraLocationAmount = plan.extraLocation.amount
    } else {
      this.extraLocationAmount = 0;
    }
    if (plan.extraUser != null){
      this.txtExtraUser = plan.extraUser;
      this.extraUserAmount = plan.extraUser.amount
    } else {
      this.extraUserAmount = 0;
    }
    this.getAllAdditionalPlan();
    this.txtOwnerName = plan.ownerName;
    this.txtOwnerMobileNumber = plan.ownerMobileNumber;
    this.txtContactPerson = plan.contactPerson;
    this.txtContactPersonMobileNumber = plan.contactPersonMobile;
    this.txtAddress = plan.address;
    this.txtGstNumber = plan.gstNumber;
    this.txtOrganizationName = plan.organizationName;
    this.txtMailId = plan.email;
    this.txtLedgerView = plan.ledger;
    this.txtMarketingView = plan.marketing;
    this.txtImage = plan.image;
    this.imagePreview = plan.image;
    // this.txtActiveDate = plan.activeDate;
    // this.txtEndDate = plan.endDate;
    var booleanValue = this.allState.filter(function (stat){
      return stat.stateName == plan.state
    })
    this.txtStateCode = booleanValue[0].stateCode;
    this.txtState = booleanValue[0];
    this.getAmount();
  }

  deleteid;
  deletePurchasePlan(plan) {
    this.deleteid = plan.planpurchaseId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.purchasePlanService.deletePurchasePlan(this.deleteid).subscribe(res => {
        this.resDelPurchasePlan = res;
        this.clearData();
        this.getAllPurchasePlan();
        $('#deleteModal').modal('hide');
        this.toastr.success('Purchase Plan deleted successfully');
      }, error => {
        // this.toastr.error('Plan not deleted');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  onItemChange(value) {
    console.log(value);
    this.optradio = value;
    if (value == "Bank") {
      console.log(value);
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  resGSTNumber;
  getGSTNumber() {
    if(this.txtGstNumber.length == 15 && this.txtGstNumber != null){
      this.purchasePlanService.getGSTNumber(this.txtGstNumber).subscribe(res => {
        this.resGSTNumber = res;
        this.txtOrganizationName = res.organizationName;
        this.txtOwnerName = res.ownerName;
      })
    } else {
      // this.toastr.error('Please enter proper GST number.');
      // this.txtOrganizationName = null;
      // this.txtOwnerName = null;
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage(res) {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.imagePreview = "";
      this.getAllPurchasePlan();
      this.clearData();
      // this.uploadQR();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('planpurchaseid', res.planpurchaseId);
      this.purchasePlanService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllPurchasePlan();
        this.clearData();
        // this.uploadQR();
        this.imagePreview = "";
      }, error => {
        this.imagePreview = "";
        this.clearData();
        this.getAllPurchasePlan();
        // this.uploadQR();
      })
    }
  }

}
