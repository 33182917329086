import { Component, OnInit } from '@angular/core';
import { StockService } from '../service/stock/stock.service';

@Component({
  selector: 'app-vender-user-list',
  templateUrl: './vender-user-list.component.html',
  styleUrls: ['./vender-user-list.component.css']
})
export class VenderUserListComponent implements OnInit {
  allVenderList;
  page: number = 1;
  search;

  // ExalData: Array<SummarySearchStockDTO> = [];
  constructor(
    private stockService: StockService,
  ) { }

  ngOnInit(): void {
    this.getAllVenderList();
  }

  getAllVenderList() {
    this.stockService.getAllVenderList().subscribe(res => {
      this.allVenderList = res;
    })
  }

}
