import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PaymentVoucherService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  createPaymentVoucher(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number,txtchequeNo: number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId,
      chequeNo: txtchequeNo,
    });
    return this.http.post('/api/create/paymentvoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllpayment(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/paymentvoucher', { headers })
      .pipe(map(res => res.json()));
  }

  getAllPaymentByDate(stratdate:string,endDate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/paymentvoucher/date/'+stratdate +'/'+endDate, { headers })
      .pipe(map(res => res.json()));
  }

  public downloadPaymentVoucher(vouchermasterid: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/payment/voucher/` + vouchermasterid, { headers: header, responseType: 'blob' });
  }

  sendPaymentEmail(id: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/server/payment/voucher/'+ id +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  paymentMailWithSendGrid(vouchermstId: string,email: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      vouchermasterid: vouchermstId,
      email: email
    });
    return this.http.post('/api/send/payment', body, { headers })
      .pipe(map(res => res));
  }

  whatsappSale(saleReturnid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/paymentvoucher/whatsup/'+saleReturnid , { headers })
      .pipe(map(res => res.json()));
  }
}
