import { Component, OnInit } from '@angular/core';
import { ReceiptVoucherService } from "../service/receipt_voucher/receipt-voucher.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LiabilityVoucherService } from '../service/liability-voucher/liability-voucher.service';
declare var $: any;

@Component({
  selector: 'app-liability-v-record',
  templateUrl: './liability-v-record.component.html',
  styleUrls: ['./liability-v-record.component.css']
})
export class LiabilityVRecordComponent implements OnInit {
  allLiability;
  // allReceiptDetail;
  allLiabilityById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;

  constructor(
    private receiptvoucherservice: ReceiptVoucherService,
    private liabilityVoucherService: LiabilityVoucherService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllLiability();
  }

  MoveLiability() {
    this.router.navigate(['liabilityvoucher']);
  }

  getAllLiability() {
    this.liabilityVoucherService.getAllLiability().subscribe(res => {
      this.allLiability = res;
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  liabilityDetail(liability) {
    this.getLiabilityVoucherById(liability.voucherMasterId)
    // this.txtDate = receipt.voucherDate;
  }

  getLiabilityVoucherById(txtId) {
    this.liabilityVoucherService.getAllLiabilityById(txtId).subscribe(res => {
      this.allLiabilityById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allLiabilityById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }

  deleteLiabilityId;
  deleteLiability(liability) {
    this.deleteLiabilityId = liability.voucherMasterId;
  }

  resReceiptData;
  confirmDelete() {
    if (this.deleteLiabilityId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteLiabilityId).subscribe(res => {
        this.resReceiptData = res;
        this.getAllLiability();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Liability data deleted successfully');
      }, error => {
        this.toastr.error('Liability data not deleted');
      })
    }
  }

}
