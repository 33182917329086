import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(
    private http: Http,
  ) { }
  
  createBrand(txtBrand: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name : txtBrand,
    });
    return this.http.post('/api/create/brand', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateBrand(txtBrandId: string, txtBrand: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name : txtBrand,
      id : txtBrandId,
    });
    return this.http.put('/api/update/brand', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllBrand(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/brand', { headers })
      .pipe(map(res => res.json()));

  }

  deleteBrand(id:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id : id,
    });
    return this.http.put('/api/delete/brand', body, { headers })
      .pipe(map(res => res.json()));  
  }

  importExcelBrandINIT(resBrand){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   name : resCategory,
    // });
    return this.http.post('/api/create/list/brand', resBrand, { headers })
      .pipe(map(res => res));
  }
}
