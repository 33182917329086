import { Component, OnInit } from '@angular/core';
import { SaleReturnService } from "../service/sale-return/sale-return.service";
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../service/company/company.service';
import { DatePipe } from '@angular/common';
import { SaleService } from '../service/sale/sale.service';
declare var $: any;

@Component({
  selector: 'app-sale-return-record',
  templateUrl: './sale-return-record.component.html',
  styleUrls: ['./sale-return-record.component.css']
})
export class SaleReturnRecordComponent implements OnInit {
  allsaleReturn;
  allSaleReturnDetail;
  search;
  page: number = 1;

  openPdfResponseNigam;
  resBillPdfNigama;
  openPdfResponseBhandar;
  resBillPdfBhandar;
  txtFormDate;
  txtToDate;
  datePipe = new DatePipe("en-US");
  public loading: boolean = false;

  constructor(
    private SaleReturnService: SaleReturnService,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private saleService: SaleService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.getAllsaleReturn();
    this.getAllsaleReturnByDate();
    this.getAllCompany();
  }

getAllsaleReturn() {
    this.SaleReturnService.getAllsaleReturn()
      .subscribe(res => {
      this.allsaleReturn = res;
      // console.log(this.allsaleReturn);
    })
  }

  whatsappsale;
  whatsappSaleReturn(res) {
    this.SaleReturnService.whatsappSaleReturn(res.salereturnid).subscribe(res => {
      this.whatsappsale = res;
      this.sendRevisedBillMSG(res);
    })
  }
  
  sendMSG;
  sendRevisedBillMSG(res) {
    this.saleService.sendRevisedBillMSG(res.mobileNumber,res.vrfmassage).subscribe(res => {
      this.sendMSG = res;
    })
  }
  
  userRole;
  getAllsaleReturnByDate() {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.SaleReturnService.getAllsaleReturnByDate(this.txtFormDate,this.txtToDate).subscribe(res => {
      this.allsaleReturn = res.salereturn;
      this.userRole = res.userrole;
    })
  }

  getSaleReturnDetail(saleReturn) {
    this.allSaleReturnDetail = saleReturn.saleReturnDetails;
    // console.log(this.allSaleReturnDetail);
  }

  allCompany = [];
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  bijNigamId
  bijBhandarId
  saleReturnNumber;
  pdfSaleReturnId;
  tempPdfCompany;
  allPDFCompany;
  getcompnayOrganizationPDF(saleReturn) {
    this.saleReturnNumber = saleReturn.salereturnNumber;
    this.pdfSaleReturnId = saleReturn.salereturnid;
    this.SaleReturnService.getCompanyOganizationPDFData(saleReturn.salereturnid).subscribe(res => {
      console.log(res);
      this.allPDFCompany = res;
      // for (let data of res) {
        // if (data.companyName == "RADHASWAMI BIJ NIGAM") {
        //   this.bijNigamId = data.companyId
        // } else if (data.companyName == "RADHASWAMI BIJ BHANDAR") {
        //   this.bijBhandarId = data.companyId
        // } else {
        //   this.toastr.error("something went wrong");
        // }
        // for(let comp of this.allCompany) {
        //   if (data.companyName == comp.companyName) {
        //     this.tempPdfCompany = data.companyName;
        //     this.saleReturnBhandarPDF();
        //     break;
        //   } else {
        //     this.tempPdfCompany = null;
        //   }
        // }
      // }
    },error=>{
      this.allPDFCompany = null;
    })
  }

  // downloadPDF() {
  //   if ((this.bijNigamId != undefined || this.bijNigamId != null) && (this.bijBhandarId != undefined || this.bijBhandarId != null)) {
  //     this.saleReturnNigamPDF();
  //     this.saleReturnBhandarPDF();
  //   } else if (this.bijNigamId != undefined || this.bijNigamId != null) {
  //     this.saleReturnNigamPDF();
  //   } else if (this.bijBhandarId != null || this.bijBhandarId != undefined) {
  //     this.saleReturnBhandarPDF();
  //   } else {
  //     this.toastr.error("something went wrong.");
  //   }
  //   this.bijBhandarId = null;
  //   this.bijNigamId = null;
  // }

  // saleReturnNigamPDF() {
  //   this.SaleReturnService.downloadSaleReturnNigamBill(this.saleReturnNumber).subscribe((data) => {
  //     this.resBillPdfNigama = data;

  //     var newBlob = new Blob([this.resBillPdfNigama], { type: "application/pdf" });
  //     if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
  //       (window.navigator as any).msSaveOrOpenBlob(newBlob);
  //       return;
  //     }

  //     const data1 = window.URL.createObjectURL(newBlob);
  //     var link = document.createElement('a');
  //     link.href = data1;
  //     link.download = "SaleReturn_Bij_Nigam.pdf";
  //     // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  //     window.open(link.href, "", "width=800,height=600");
  //     this.openPdfResponseNigam.saveAs(link.href);

  //     setTimeout(function () {
  //       // For Firefox it is necessary to delay revoking the ObjectURL
  //       window.URL.revokeObjectURL(data1);
  //       link.remove();
  //     }, 100);
  //     this.resBillPdfNigama = '';
  //     this.openPdfResponseNigam = '';
  //   })
  // }

  // saleReturnBhandarPDF() {
  //   this.SaleReturnService.downloadSaleReturnBhandarBill(this.saleReturnNumber,this.tempPdfCompany).subscribe((data) => {
  //     this.resBillPdfBhandar = data;

  //     var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
  //     if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
  //       (window.navigator as any).msSaveOrOpenBlob(newBlob1);
  //       return;
  //     }

  //     const data2 = window.URL.createObjectURL(newBlob1);
  //     var link2 = document.createElement('a');
  //     link2.href = data2;
  //     link2.download = "SaleReturn_Bij_Bhandar.pdf";
  //     // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  //     window.open(link2.href, "", "width=800,height=600");
  //     this.openPdfResponseBhandar.saveAs(link2.href);

  //     setTimeout(function () {
  //       // For Firefox it is necessary to delay revoking the ObjectURL
  //       window.URL.revokeObjectURL(data2);
  //       link2.remove();
  //     }, 100);
  //     this.resBillPdfBhandar = '';
  //     this.openPdfResponseBhandar = '';
  //   })
  // }

  downloadPdfByCompany(company,noofCompany) {
    this.SaleReturnService.downloadSaleReturnBhandarBill(this.saleReturnNumber,company.companyName,noofCompany).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "SaleReturn_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  txtEmail;
  emailsaleReturnno;
  getsendEmailData(saleReturn) {
    console.log(saleReturn);
    // this.getcompnayOrganizationPDF(saleReturn);
    this.emailsaleReturnno = saleReturn.salereturnNumber;
    if (saleReturn.party.mailId != null) {
      this.txtEmail = saleReturn.party.mailId;
    }
  }
  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }
  saleReturnMailWithSendGrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.SaleReturnService.saleReturnMailWithSendGrid(this.emailsaleReturnno,this.txtEmail).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#emailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  ReturnNigamEmail() {
    this.SaleReturnService.sendReturnNigamEmail(this.emailsaleReturnno, this.txtEmail).subscribe(res => {
      console.log(res);
      $("#emailModal").modal('hide');
      this.toastr.success("Email sent successfully");
    })
  }
  ReturnBhandarEmail() {
    this.SaleReturnService.sendReturnBhandarEmail(this.emailsaleReturnno, this.txtEmail).subscribe(res => {
      console.log(res);
      $("#emailModal").modal('hide');
      this.toastr.success("Email sent successfully");
    })
  }
  sendMail() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("email address is required."); 
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      if ((this.bijNigamId != undefined || this.bijNigamId != null) && (this.bijBhandarId != undefined || this.bijBhandarId != null)) {
        this.ReturnNigamEmail();
        this.ReturnBhandarEmail();
      } else if (this.bijNigamId != undefined || this.bijNigamId != null) {
        this.ReturnNigamEmail();
      } else if (this.bijBhandarId != null || this.bijBhandarId != undefined) {
        this.ReturnBhandarEmail();
      } else {
        this.toastr.error("something went wrong.");
      }
      this.bijBhandarId = null;
      this.bijNigamId = null;
    }
  }

  saleReturnDeleteId;
  resDelSaleReturn;
  deleteSaleReturn(saleReturn){
    this.saleReturnDeleteId = saleReturn.salereturnid;
  }
  confirmDelete(){
    if (this.saleReturnDeleteId != null) {
      this.SaleReturnService.deleteSaleReturn(this.saleReturnDeleteId).subscribe(res => {
        this.resDelSaleReturn = res;
        this.getAllsaleReturn();
        $('#deleteModal').modal('hide');
        this.toastr.success('Sale return deleted successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  // new sale return report by category
  allPDFCategory;
  totalNoOfInvoice = 0;
  getSaleReturnPDFCategoryNoOfInvoiceData(sale) {
    this.saleReturnNumber = sale.salereturnNumber;
    this.SaleReturnService.getSaleReturnPDFCategoryNoOfInvoiceData(sale.salereturnid).subscribe(res => {
      // console.log(res);
      this.allPDFCategory = JSON.parse(res);
      // console.log(JSON.parse(res));
      // this.allPDFCategory = res;
      this.totalNoOfInvoice = 0;
      for (let comp of this.allPDFCategory) {
        this.totalNoOfInvoice = this.totalNoOfInvoice + comp.invoiceDetail.length;
      }
      // console.log("Total NoOfInvoice For Discount: "+this.totalNoOfInvoice);
    },error => {
      this.allPDFCategory = null;
    })
  }
  
  downloadNewPDF(company) {
    if("PESTICIDE" == company.staticinvoicename){
      this.downloadSaleReturnPesticideBill(company);
    } else if("FERTILISER" == company.staticinvoicename){
      this.downloadSaleReturnFertiliserBill(company);
    } else if("SEEDS" == company.staticinvoicename){
      this.downloadSaleReturnSeedsBill(company);
    }
  }
  openPdfResponsePesticide;
  resBillPdfPesticide;
  openPdfResponseFertiliser;
  resBillPdfFertiliser;
  openPdfResponseSeeds;
  resBillPdfSeeds;
  downloadSaleReturnPesticideBill(company) {
    this.SaleReturnService.downloadSaleReturnPesticideBill(company.invoiceid,this.saleReturnNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfPesticide = data;

      var newBlob1 = new Blob([this.resBillPdfPesticide], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponsePesticide.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfPesticide = '';
      this.openPdfResponsePesticide = '';
    })
  }
  downloadSaleReturnFertiliserBill(company) {
    this.SaleReturnService.downloadSaleReturnFertiliserBill(company.invoiceid,this.saleReturnNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfFertiliser = data;

      var newBlob1 = new Blob([this.resBillPdfFertiliser], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseFertiliser.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfFertiliser = '';
      this.openPdfResponseFertiliser = '';
    })
  }
  downloadSaleReturnSeedsBill(company) {
    this.SaleReturnService.downloadSaleReturnSeedsBill(company.invoiceid,this.saleReturnNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfSeeds = data;

      var newBlob1 = new Blob([this.resBillPdfSeeds], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseSeeds.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfSeeds = '';
      this.openPdfResponseSeeds = '';
    })
  }

}
