import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BalancesheetService } from '../service/balancesheet/balancesheet.service';
import { CompanyService } from '../service/company/company.service';
import { StockService } from '../service/stock/stock.service';
declare var $: any;

@Component({
  selector: 'app-balancesheet-report',
  templateUrl: './balancesheet-report.component.html',
  styleUrls: ['./balancesheet-report.component.css']
})
export class BalancesheetReportComponent implements OnInit {

  constructor(
    private stockservice: StockService,
    private toastr: ToastrService,
    private balancesheetService: BalancesheetService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.txtEndDate = new Date();
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear() - 1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtStartDate = (curr_year + "-" + '04' + "-" + '01');
    this.getAllCompany();
    // this.getAllBalanceSheetAssetsReport();
    // this.getAllBalanceSheetLiabilityReport();
    // this.searchStockValution();
  }

  allProfitLossData;
  datePipe = new DatePipe("en-US");
  txtStartDate;
  txtEndDate;
  txtSale = 0;
  txtStock = 0;
  txtPurchase = 0;
  txtExpense = 0;
  txtIncome = 0;
  totalA = 0;
  totalB = 0;
  txtProfitLoss = 0;
  getAllProfitAndLoss() {
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    if (this.txtCompany == "All") {
      this.stockservice.getAllProfitAndLoss('2021-04-01', this.txtEndDate).subscribe(res => {
        this.allProfitLossData = res;
        console.log(this.allProfitLossData);
        this.txtSale = this.allProfitLossData.sale;
        this.txtPurchase = this.allProfitLossData.purchase;
        this.txtExpense = this.allProfitLossData.expense;
        this.txtIncome = this.allProfitLossData.income;
        this.totalA = this.txtSale + this.txtStock + this.txtIncome;
        this.totalB = this.txtExpense + this.txtPurchase;
        this.txtProfitLoss = this.totalA - this.totalB;
      })
    } else {
      this.stockservice.getAllProfitAndLossCompany(this.txtCompany, '2021-04-01', this.txtEndDate).subscribe(res => {
        this.allProfitLossData = res;
        console.log(this.allProfitLossData);
        this.txtSale = this.allProfitLossData.sale;
        this.txtPurchase = this.allProfitLossData.purchase;
        this.txtExpense = this.allProfitLossData.expense;
        this.txtIncome = this.allProfitLossData.income;
        this.totalA = this.txtSale + this.txtStock + this.txtIncome;
        this.totalB = this.txtExpense + this.txtPurchase;
        this.txtProfitLoss = this.totalA - this.totalB;
      })
    }
  }

  reStockvalutionData;
  reStockvalutionCompanyData = [];
  finalLiabilityAmount = 0;
  finalAssetsAmount = 0;
  searchStockValution() {
    this.stockservice.getsearchStockValutionAll().subscribe(res => {
      this.reStockvalutionData = res;
      this.reStockvalutionCompanyData = this.reStockvalutionData;
      if ( this.txtCompany == "All") {
        this.getTotal();
        this.getAllProfitAndLoss();
        this.getAllBalanceSheetAssetsReport();
        this.getAllBalanceSheetLiabilityReport();
      } else {
        this.reStockvalutionData = [];
        for (let company of this.reStockvalutionCompanyData) {
          if (this.txtCompany == company.item.category.company.companyId) {
            this.reStockvalutionData.push(company);
          }
        }
          this.getTotal();
          this.getAllProfitAndLoss();  
          this.getAllBalanceSheetAssetsReport();
          this.getAllBalanceSheetLiabilityReport();
      }
    }, error => {
      this.toastr.error("something went wrong");
    });
  }

  totalstockTotalAmount;
  getTotal() {
    this.totalstockTotalAmount = 0;
    for (let stkval of this.reStockvalutionData) {
      var sum = 0;
      sum = sum + Number(stkval.stockTotalAmount);
      this.totalstockTotalAmount = this.totalstockTotalAmount + sum;
      this.txtStock = this.totalstockTotalAmount;
    }
  }

  allbalancesheetassets: any;
  allbalancesheetliability: any;
  totalAssetsAmount: number = 0;
  totalLiabilityAmount: number = 0;
  search: any;
  page: number = 1;
  getAllBalanceSheetAssetsReport() {
    this.totalAssetsAmount = 0;
    this.balancesheetService.getAllBalanceSheetAssetsReport(this.txtCompany).subscribe(res => {
      this.allbalancesheetassets = res;
      for (let a of res) {
        this.totalAssetsAmount = this.totalAssetsAmount + a.amount;
      }
    })
  }

  getAllBalanceSheetLiabilityReport() {
    this.totalLiabilityAmount = 0;
    this.balancesheetService.getAllBalanceSheetLiabilityReport(this.txtCompany).subscribe(res1 => {
      this.allbalancesheetliability = res1;
      for (let l of res1) {
        this.totalLiabilityAmount = this.totalLiabilityAmount + l.amount;
      }
    })
  }

  allDetailData;
  totalOpeningBalance;
  getBalanceSheetDetail(detail) {
    console.log(detail);
    this.allDetailData = [];
    this.totalOpeningBalance = 0;
    this.balancesheetService.getBalanceSheetDetail(detail.glaccountId,this.txtCompany).subscribe(res => {
      this.allDetailData = res.assets;
      this.totalOpeningBalance = res.credit;
    })
  }

  allDetailDataPartyWise;
  getBalanceSheetDetailPartyWise(data) {
    this.allDetailDataPartyWise = [];
    $('#exampleModal').modal('hide');
    this.balancesheetService.getBalanceSheetDetailPartyWise(data.glaccountId,this.txtCompany).subscribe(res => {
      this.allDetailDataPartyWise = res;
    })
  }

  clearHitoryData() {
    this.allDetailData = [];
    this.totalOpeningBalance = 0;
  }

  clearHitoryDataPartyWise() {
    this.allDetailDataPartyWise = [];
    $('#exampleModal').modal('show');
  }

  allCompany;
  txtCompany;
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      // console.log(this.allCompany);
    })
  }

}
