import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../service/excel/excel.service';
import { ExpenseLedgerService } from '../service/expense-ledger/expense-ledger.service';
import { SubCategoryService } from '../sub-category/sub-category.service';

@Component({
  selector: 'app-subcategory-item-report',
  templateUrl: './subcategory-item-report.component.html',
  styleUrls: ['./subcategory-item-report.component.css']
})
export class SubcategoryItemReportComponent implements OnInit {
  reSearchData;
  search;
  page = 1;
  txtTotalDebit = 0;
  txtSubCategory;
  txtType;
  allSubCategory;
  isEnable = false;

  constructor(
    private toastr: ToastrService,
    private expenseLedgerService: ExpenseLedgerService,
    private SubCategoryService: SubCategoryService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    // this.searchSubCategoryData();
    this.getAllSubCategory();
  }

  getAllSubCategory() {
    this.SubCategoryService.getAllSubCategory().subscribe(res => {
      this.allSubCategory = res;
    })
  }

  searchSubCategoryItemData() {
    if (this.txtSubCategory == null || this.txtSubCategory == "" || this.txtSubCategory == undefined || this.txtSubCategory == "-:Select SubCategory:-") {
      this.toastr.error("Please select a subcategory");
    } else if (this.txtType == null || this.txtType == "" || this.txtType == undefined || this.txtType == "-:Select Type:-") {
      this.toastr.error("Please select a type");
    } else {
      this.expenseLedgerService.searchSubCategoryItemData(this.txtSubCategory,this.txtType).subscribe(res => {
        this.reSearchData = res;
        this.isEnable = true;
        // this.txtTotalDebit = 0;
        // for (let ii = 0; ii < this.reSearchData.length; ii++) {
        //   this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
        // }
      }, error => {
        this.toastr.error("something went wrong");
      })
    }
  }

  ExalData = [];
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.reSearchData){
      this.ExalData.push({
        "Item Name": exal.itemName,"Quantity": exal.quantity,"Total Amount (Rs.)": exal.finalAmount})
    }
    this.excelservice.exportAsExcelFile(this.ExalData,'SubcategoryWiseItem');
 }

}
