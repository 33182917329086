import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceMasterService } from '../service/invoice-master/invoice-master.service';
import { CategoryService } from '../service/category/category.service';
import { CompanyService } from '../service/company/company.service';
declare var $: any;

@Component({
  selector: 'app-license-number',
  templateUrl: './license-number.component.html',
  styleUrls: ['./license-number.component.css']
})
export class LicenseNumberComponent implements OnInit {
  resInvoiceCat;
  resUpInvoiceCat;
  resDelInvoiceCat;
  
  txtLicenseId;
  txtInvoice;
  allInvoice;
  txtLicenseNumber;
  txtLicenseName;
  txtCategorys = [];
  allCategory = [];
  dropdownSettingsCategory = {};
  allLicense;
  btnName = 'Save';
  search;
  page: number = 1;
  txtCategory;
  isEnableCatUpdate = false;
  isEnableMultiCat = false;
  txtCompany;
  allCompany;

  constructor(
    private invoiceMasterService: InvoiceMasterService,
    private toastr: ToastrService,
    private categoryservice: CategoryService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.getAllInvoiceMaster();
    // this.getAllCategory();
    this.getAllLicenseNumber();
    this.getAllCompany();
  }

  getAllInvoiceMaster() {
    this.invoiceMasterService.getAllInvoiceMaster().subscribe(res => {
      this.allInvoice = res;
    })
  }
  
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  getAllCategory() {
    this.categoryservice.gatAllCategory().subscribe((data: any[]) => {
      this.allCategory = data;
      // this.dropdownList = data;
      this.dropdownSettingsCategory = {
        singleSelection: false,
        idField: 'categoryId',
        textField: 'categoryName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        itemsShowLimit: 3,
      };
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  getAllLicenseNumber() {
    this.invoiceMasterService.getAllLicenseNumber().subscribe(res => {
      this.allLicense = res;
    })
  }
  toUpperCaseLicenseNumber() {
    const temp = this.txtLicenseNumber;
    this.txtLicenseNumber = temp.toUpperCase();
    return this.txtLicenseNumber;
  }
  toUpperCaseLicenseName() {
    const temp = this.txtLicenseName;
    this.txtLicenseName = temp.toUpperCase();
    return this.txtLicenseName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtInvoice = null;
    this.txtLicenseNumber = null;
    this.txtLicenseName = null;
    this.txtCategorys = [];
    this.txtLicenseId = null;
    this.txtCategory = null;
    this.isEnableCatUpdate = false;
    this.isEnableMultiCat = false;
    this.txtCompany = null;
  }

  createLicense() {
    if (this.txtLicenseNumber == null || this.txtLicenseNumber.trim() == '' || this.txtLicenseNumber == undefined) {
      this.toastr.error("License number is required");
    } else if (this.txtLicenseName == null || this.txtLicenseName.trim() == '' || this.txtLicenseName == undefined) {
        this.toastr.error("License name is required");
    // } else if (this.isEnableMultiCat == false &&(this.txtCategorys == null || this.txtCategorys.length == 0 || this.txtCategorys == undefined)) {
    //     this.toastr.error("category is required");
    // } else if (this.isEnableCatUpdate == true && (this.txtCategory == null || this.txtCategory == '-:Select Category:-'|| this.txtCategory == undefined)) {
    //   this.toastr.error("category is required");
    } else if ((this.txtCompany == null || this.txtCompany == '-:Select Company:-' || this.txtCompany == undefined)) {
      this.toastr.error("Company is required");
    } else if ((this.txtInvoice == null || this.txtInvoice == '-:Select Invoice:-' || this.txtInvoice == undefined)) {
      this.toastr.error("Invoice number is required");
    } else {
      if (this.txtLicenseId == null) {
        this.invoiceMasterService.createLicenseNumber(this.txtLicenseNumber,this.txtLicenseName,this.txtCategorys,this.txtCompany,this.txtInvoice)
          .subscribe(res => {
            this.resInvoiceCat = res;
            this.clearData();
            this.getAllLicenseNumber();
            this.toastr.success('License number save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.invoiceMasterService.updateLicenseNumber(this.txtLicenseId, this.txtLicenseNumber,this.txtLicenseName,this.txtCategory,this.txtCompany,this.txtInvoice).subscribe(res => {
          this.resUpInvoiceCat = res;
          this.clearData();
          this.getAllLicenseNumber();
          this.toastr.success('License number updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateLicense(inv) {
    this.btnName = 'Update';
    this.isEnableCatUpdate = true;
    this.isEnableMultiCat = true;
    this.txtLicenseId = inv.licenseId;
    if(inv.invoiceMaster != null) {
      this.txtInvoice = inv.invoiceMaster.invoiceId;
    } else {
      this.txtInvoice = null;
    }
    this.txtLicenseNumber = inv.licenseNumber;
    this.txtLicenseName = inv.licenseName;
    // this.txtCategory = inv.category.categoryId;
    // this.txtCategorys = inv.categoryDTOs;
    if(inv.company != null) {
      this.txtCompany = inv.company.companyId;
    } else {
      this.txtCompany = null;
    }
  }

  deleteid;
  deleteLicense(inv) {
    this.deleteid = inv.licenseId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.invoiceMasterService.deleteLicenseNumber(this.deleteid).subscribe(res => {
        this.resDelInvoiceCat = res;
        this.clearData();
        this.getAllLicenseNumber();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('License number deleted successfully');
      }, error => {
        // this.toastr.error('License number not deleted');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

}
