import { Component, OnInit } from '@angular/core';
import { PartyService } from "../service/party/party.service";
import { BankService } from "../service/bank/bank.service";
import { ToastrService } from "ngx-toastr";
import { CompanyService } from '../service/company/company.service';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {

  allCompany;
  resBank;
  resUpBank;
  txtBank;
  txtCompany;
  txtBankId;
  allBank;
  txtopeningBalance;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private partyService: PartyService,
    private bankService:BankService,
    private toastr: ToastrService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.getAllCompany();
    this.getAllBank();
  }
  
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  getAllBank() {
    this.bankService.getAllBank().subscribe(res => {
      this.allBank = res;
    })
  }

  toUpperCaseBank() {
    const temp = this.txtBank;
    this.txtBank = temp.toUpperCase();
    return this.txtBank;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtBank = '';
    this.txtCompany = '';
    this.txtopeningBalance = '';
    this.txtBankId = null;
    this.optRadio = "True";
  }
  createBrand() {
    if (this.txtBank == null || this.txtBank == '' || this.txtBank == undefined) {
      this.toastr.error("Bank name is required");
    } else if (this.txtCompany == null || this.txtCompany == '' || this.txtCompany == undefined || this.txtCompany == "-:Select Company Name:-") {
      this.toastr.error("Company is required");
    } else if (this.txtopeningBalance == null || this.txtopeningBalance == '' || this.txtopeningBalance == undefined) {
      this.toastr.error("Opening balance is required");
    } else {
      if (this.txtBankId == null) {
        this.bankService.createBank(this.txtBank,this.txtCompany.companyId,this.txtCompany.companyName,this.txtopeningBalance,this.optRadio)
          .subscribe(res => {
            this.resBank = res;
            console.log(this.resBank);
            this.clearData();
            this.getAllBank();
            this.toastr.success('Bank save successfully');
          }, error => {
            // this.toastr.error('Bank not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } 
    }
  }

  optRadio="True";
  onItemChange(value) {
    // console.log(value);
    this.optRadio = value;
    if (value == "False") {
      this.optRadio = "False";
      console.log("Bank Value",this.optRadio);
    }else{
      this.optRadio = "True";
      console.log("Cash Value",this.optRadio)
    }
  }

  // color: ThemePalette = 'warn';
  color: ThemePalette = 'primary';
  // color: ThemePalette = 'accent';
  resuserActive;
  upiOnOffButton(bank) {
    this.bankService.setBankUpiUpdate(bank.companyId,bank.bankid, bank.upi).subscribe(res => {
      this.resuserActive = res;
      this.toastr.success('Bank detail update successfully');
      this.getAllBank();
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
      this.getAllBank();
    });
  }

}
