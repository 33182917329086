<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="portlet">
                            <!-- <form class="needs-validation" novalidate=""> -->
                            <div class="portlet-header">Expiry Detail</div>
                            <div class="portlet-content">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="Previous-tab" data-bs-toggle="tab"
                                            data-bs-target="#Previous" type="button" role="tab" aria-controls="Previous"
                                            aria-selected="true" (click)="searchPreviousStock()">Previous Stock</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="tenDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#tenDays" type="button" role="tab" aria-controls="tenDays"
                                            aria-selected="true" (click)="getDueDate(10)">10 Days</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="twentyDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#twentyDays" type="button" role="tab"
                                            aria-controls="twentyDays" aria-selected="false" (click)="getDueDate(20)">20
                                            Days</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="thirtyDays-tab" data-bs-toggle="tab"
                                            data-bs-target="#thirtyDays" type="button" role="tab"
                                            aria-controls="thirtyDays" aria-selected="false" (click)="getDueDate(30)">30
                                            Days</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Previous" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-6">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchPrevious" [(ngModel)]="searchPrevious"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th class="text-center" scope="col">Quantity</th>
                                                        <th class="text-center" scope="col">Stock Total Amount (Rs.)</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let evel of allExpiryDateDetail | filter:searchPrevious | paginate: { itemsPerPage: 10, currentPage: pagePrevious }">
                                                        <td>{{evel.itemname}}</td>
                                                        <td class="text-center">{{evel.quantity}}</td>
                                                        <td class="text-center">{{evel.stockTotalAmount | number : '1.2-2'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >Total</td>
                                                        <td class="text-center">{{txtquantity}}</td>
                                                        <td class="text-center">{{txtamount | number : '1.2-2'}}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allExpiryDateDetail < 1">
                                                        <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="pagePrevious = $event"
                                                            (pageBoundsCorrection)="pagePrevious=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="tenDays" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-6">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchten" [(ngModel)]="searchten"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th class="text-center" scope="col">Quantity</th>
                                                        <th class="text-center" scope="col">Stock Total Amount (Rs.)</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let evel of allExpiryDateDetail | filter:searchten | paginate: { itemsPerPage: 10, currentPage: page10 }">
                                                        <td>{{evel.itemname}}</td>
                                                        <td class="text-center">{{evel.quantity}}</td>
                                                        <td class="text-center">{{evel.stockTotalAmount | number : '1.2-2'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >Total</td>
                                                        <td class="text-center">{{txtquantity}}</td>
                                                        <td class="text-center">{{txtamount | number : '1.2-2'}}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allExpiryDateDetail < 1">
                                                        <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page10 = $event"
                                                            (pageBoundsCorrection)="page10=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="twentyDays" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-6">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchtwenty"
                                                        [(ngModel)]="searchtwenty" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th class="text-center" scope="col">Quantity</th>
                                                        <th class="text-center" scope="col">Stock Total Amount (Rs.)</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let evel of allExpiryDateDetail | filter:searchtwenty | paginate: { itemsPerPage: 10, currentPage: page20 }">
                                                        <td>{{evel.itemname}}</td>
                                                        <td class="text-center">{{evel.quantity}}</td>
                                                        <td class="text-center">{{evel.stockTotalAmount | number : '1.2-2'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >Total</td>
                                                        <td class="text-center">{{txtquantity}}</td>
                                                        <td class="text-center">{{txtamount | number : '1.2-2'}}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allExpiryDateDetail < 1">
                                                        <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page20 = $event"
                                                            (pageBoundsCorrection)="page20=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="thirtyDays" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <div class="row d-flex justify-content-end" style="margin-top: 5px;">
                                            <div class="col-md-6">
                                                <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="searchthirty"
                                                        [(ngModel)]="searchthirty" id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th class="text-center" scope="col">Quantity</th>
                                                        <th class="text-center" scope="col">Stock Total Amount (Rs.)</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let evel of allExpiryDateDetail | filter:searchthirty | paginate: { itemsPerPage: 10, currentPage: page30 }">
                                                        <td>{{evel.itemname}}</td>
                                                        <td class="text-center">{{evel.quantity}}</td>
                                                        <td class="text-center">{{evel.stockTotalAmount | number : '1.2-2'}}</td>
                                                        <td class="text-center">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="History" data-bs-toggle="modal"
                                                                data-bs-target="#expiryDetailModal" (click)="subExpiryDateDetail(evel)">
                                                                <i class="fas fa-history"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >Total</td>
                                                        <td class="text-center">{{txtquantity}}</td>
                                                        <td class="text-center">{{txtamount | number : '1.2-2'}}</td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allExpiryDateDetail < 1">
                                                        <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page30 = $event"
                                                            (pageBoundsCorrection)="page30=($event)" responsive="true">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createCategory()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div> -->
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="expiryDetailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-content">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Location</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col" class="text-center">Quantity</th>
                                            <th scope="col">Batch Number</th>
                                            <th scope="col">Expiry Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let detail of resAllsubExpiryDateDetail">
                                            <td>{{detail.location}}</td>
                                            <td>{{detail.itemname}}</td>
                                            <td class="text-center">{{detail.quantity}}</td>
                                            <td>{{detail.batchnumber}}</td>
                                            <td>{{detail.expirydate | date :'dd-MMM-yyyy'}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <!-- <tr *ngIf="reSearchSubLedgerData.length == 0 "> -->
                                        <tr *ngIf="resAllsubExpiryDateDetail < 1 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>