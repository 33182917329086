<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Sale Challan Record</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Challan Date</th>
                                            <th scope="col">Challan Number</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">GST Amount</th>
                                            <th scope="col">Net Amount</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let sale of allsale | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{sale.createdon | date :'dd-MMM-yyyy'}}</td>
                                            <td>{{sale.challanNumber}}</td>
                                            <td>{{sale.party.partyName}}</td>
                                            <td class="text-end">{{sale.amount | appendIND}}</td>
                                            <td class="text-end">{{sale.gstAmount | appendIND}}</td>
                                            <td class="text-end">{{sale.netAmount | appendIND}}</td>
                                            <!-- <td>{{sale.netAmount}}</td> -->
                                            <td class="text-end">
                                                <div class="card-close">
                                                    <div class="dropdown">
                                                      <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false" class="dropdown-toggle">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                      </button>
                                                      <div aria-labelledby="closeCard1" style="min-width: 120px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                            (click)="getSaleDetail(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-history"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
                                                            (click)="getcompnayOrganizationPDF(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#pdfModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-secondary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Approve"
                                                            (click)="getSaleDetail(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#approvedModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fa fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-secondary btn-sm" style="margin-left: 7px; margin-bottom: 5px;"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                            (click)="deleteSaleChallan(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allsale < 1 ">
                                            <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                        <!-- <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item active" aria-current="page">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Sale Challan Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="getAllsale();clearGridData();hideShowHistoryModel();"></button>
                            </div>
                            <div class="modal-body">
                                
                                <div class="row d-flex align-items-baseline">
                                    <div class="col-lg-12 col-md-12 mb-4">
                                        <div class="portlet">
                                            <div class="portlet-header">Sale Challan</div>
                                            <form class="needs-validation" novalidate="">
                                                <div class="portlet-content">
                                                    <div class="row d-flex align-items-baseline g-3">
            
                                                        <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                                            <label for="last" class="form-label">Item Name<span style="color: red;" class="me-2">&nbsp;*</span>
                                                            </label>
                                                            <input type="text" class="form-control" id="ItemName"
                                                                placeholder="Enter Item Name" required="required"
                                                                [(ngModel)]="txtItemName" name="txtItemName" disabled />
                                                            <div class="invalid-feedback">
                                                                Please enter item name.
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 col-xl-3 col-6">
                                                            <label for="country" class="form-label">Brand Name</label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Enter Brand Name" required="required"
                                                                [(ngModel)]="txtBrandName" name="txtBrandName" disabled />
                                                            <div class="invalid-feedback">
                                                                Please enter a SubCategory Name.
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-xl-1 col-6">
                                                            <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Quantity" required="required"
                                                                [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                                            <div class="invalid-feedback">
                                                                Please enter a quantity.
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-xl-2 col-6">
                                                            <label for="country" class="form-label">Rate<span style="color: red;">&nbsp;*</span></label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Rate" required="required"
                                                                [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                                            <div class="invalid-feedback">
                                                                Please enter a rate.
                                                            </div>
                                                        </div>
                                                        <div class="col-auto ms-auto btn-container-end" *ngIf="!isClick">
                                                            <button type="button" class="btn btn-primary d-block me-2"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                                (click)="getBatchNumber()" data-bs-toggle="modal"
                                                                data-bs-target="#stockCheckModal">
                                                                STOCK
                                                            </button>
                                                            <button class="btn btn-secondary me-2" type="button" (click)="clearGridData();">Cancel</button>
                                                            <button class="btn btn-primary d-block" type="submit" (click)="updateSaleChallanDetail()">{{btnName}}</button>
                                                        </div>
                                                        <div class="col-auto ms-auto btn-container-end" *ngIf="isClick">
                                                            <button class="btn btn-primary" type="button" disabled>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                Please wait...
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <!-- <div class="row d-flex justify-content-end">
                                            <div class="col-md-6">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="search" [(ngModel)]="search"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                              <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Action</th>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">SubCategory</th>
                                                        <th scope="col">Brand</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Rate</th>
                                                        <!-- <th scope="col">Rate<span style="color: red;">&nbsp;*</span></th> -->
                                                        <!-- <th scope="col">Manufacture Date</th>
                                                    <th scope="col">Expiry Date</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let saledtl of allSaleDetail">
                                                        <td>
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                            (click)="updateSale(saledtl)">
                                                            <i class="fas fa-pencil-alt"></i></button>
                                                        </td>
                                                        <td>{{saledtl.item.itemName}}</td>
                                                        <td>{{saledtl.item.category.categoryName}}</td>
                                                        <td>{{saledtl.item.subCategory.subCategoryName}}</td>
                                                        <td>{{saledtl.item.brand.name}}</td>
                                                        <td>{{saledtl.quantity}}</td>
                                                        <td class="text-end">{{saledtl.rate | appendIND}}</td>
                                                        <!-- <td>
                                                            <div class="col">
                                                                <input type="text" class="form-control" id="last"
                                                                    placeholder="Enter Rate" required="required"
                                                                    [(ngModel)]="saledtl.rate" name="txtrate"
                                                                    (keypress)="numberandDotOnly($event)" />
                                                                <div class="invalid-feedback">
                                                                    Please enter a rate.
                                                                </div>
                                                            </div>
                                                        </td> -->
                                                        <!-- <td>{{saledtl.party.partyName}}</td>
                                                    <td>{{saledtl.party.partyName}}</td> -->
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allsale < 1 ">
                                                        <td colspan="6" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal" (click)="getAllsale();clearGridData();hideShowHistoryModel();">Close</button>
                                        <!-- <button class="btn btn-primary" type="submit" (click)="createSaleDetail()" >
                                            Save
                                        </button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="approvedModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Approve Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure you want to approve this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="createSaleDetail()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="pdfModal" tabindex="-1" aria-labelledby="pdfModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Download PDF</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- Do you want to download PDF ? -->
                <!-- <div class="row">
                    <div class="col-md-4 d-flex text-center align-items-center mb-2" *ngFor="let comp of allPDFInvoice">
                        <a role="button" (click)="downloadNewPDF(comp,allPDFInvoice.length)">{{comp.invoicename}}</a>
                    </div>
                </div> -->
                <ul *ngFor="let comp of allPDFInvoice">
                    <li ><b>{{comp.companyName}}</b>
                      <ul *ngFor="let inv of comp.invoiceDetail">
                        <li role="button" (click)="downloadNewPDF(inv)">{{inv.invoicename}}</li>
                      </ul>
                    </li>
                </ul>
                <div class="text-center" *ngIf="allPDFInvoice < 1 || allPDFInvoice == null">
                    Invoice detail not found.
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="downloadPDF()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div> -->
        </div>
    </div>
</div>
<!-- Stock Check Model -->
<div class="modal fade" id="stockCheckModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" 
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Batch wise Stock Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="hideShowBarcodeModel()"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="mt-3 table-responsive">
                            <table class="table table-hover table-striped">
                                <thead class="tblheadercolor">
                                    <tr>
                                        <th scope="col">Batch Number</th>
                                        <th scope="col">Available Qty</th>
                                        <th scope="col">Expiry Date</th>
                                        <th class="text-center" scope="col">Alloted Qty</th>
                                        <!-- <th class="text-center" scope="col">CREDIT (₹)</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let batch of batchwiseresponse;">
                                        <td>{{batch.batchNumber}}</td>
                                        <td>{{batch.quantity}}</td>
                                        <td>{{batch.expiryDate | date:'dd-MMM-yyyy'}}</td>
                                        <td>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Quantity" required="required"
                                                [(ngModel)]="batch.allotedquantity" name="txtrate" (keypress)="numberOnly($event)"/>
                                        </td>
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="hideShowBarcodeModel()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>