import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { PartyService } from '../service/party/party.service';
import { SearchParty } from '../service/party/searchparty';
import { LedgerService } from '../service/ledger/ledger.service';
import { SearchLedger } from '../service/ledger/searchledger';
import { JvService } from '../service/jv/jv.service';
declare var $: any;

@Component({
  selector: 'app-jv',
  templateUrl: './jv.component.html',
  styleUrls: ['./jv.component.css']
})
export class JvComponent implements OnInit {
  txtpaymentid;
  txtVoucherDate;
  txtcompany;
  txtAmount;
  txtNarration;
  txtLedgerName;
  txtpartyName;
  txtLedgerCreditName;
  txtpartyCreditName;
  allCompany;
  txtLedger;
  txtLedgerCredit;
  datePipe = new DatePipe("en-US");

  public searchledger = '';
  searchLedgerName = [];
  public searchledgerCredit = '';
  searchLedgerCreditName = [];
  public searchParty = '';
  searchPartyName = [];
  public searchPartyCredit = '';
  searchPartyCreditName = [];

  constructor(
    private toastr: ToastrService,
    private partyService: PartyService,
    private JvService: JvService,
    private ledgerService: LedgerService,
  ) { }

  ngOnInit(): void {
    // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "yyyy/MM/dd");
    this.txtVoucherDate = new Date();
    this.getAllParty();
    this.getAllPartyCredit();
    // this.getAllLedger();
    // this.getAllLedgerCredit();
    this.getAllCompany();
    // this.getAllJV();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party", this.allCompany);
    })
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    if (party.address != null && party.address != '') {
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    } else {
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    this.getAllLedgerByPartyId(party.partyId);
    // this.selectLedger(party.type);
  }
  allparty;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber = item.cellNumber;
        temp.address = item.address;
        this.searchPartyName.push(temp)
      }
      // console.log(this.allparty);
    })
  }

  tempPartyCredit;
  selectedPartyCredit(partyCredit) {
    this.tempPartyCredit = partyCredit;
    if (partyCredit.address != null && partyCredit.address != '') {
      this.searchPartyCredit = partyCredit.partyName + "-" + partyCredit.cellNumber + "-" + partyCredit.address;
    } else {
      this.searchPartyCredit = partyCredit.partyName + "-" + partyCredit.cellNumber;
    }
    this.getdebitLedgerByPartyId(partyCredit.partyId);
  }
  allpartyCredit;
  getAllPartyCredit() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allpartyCredit = res;
      for (let item of this.allpartyCredit) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber = item.cellNumber;
        temp.address = item.address;
        this.searchPartyCreditName.push(temp)
      }
      // console.log(this.allpartyCredit);
    })
  }

  tempLedger;
  selectedLedger(ledger) {
    this.tempLedger = ledger;
    this.searchledger = ledger.ledgerName;
  }
  allLedger;
  getAllLedger() {
    this.ledgerService.getAllLedger().subscribe(res => {
      this.allLedger = res;
      for (let item of this.allLedger) {
        var temp = new SearchLedger();
        temp.ledgerName = item.ledgerName;
        temp.ledgerId = item.ledgerId;
        this.searchLedgerName.push(temp)
      }
      // console.log("balance", this.allLedger);
    })
  }

  tempLedgerCredit;
  selectedLedgerCredit(ledgerCredit) {
    this.tempLedgerCredit = ledgerCredit;
    this.searchledgerCredit = ledgerCredit.ledgerName;
  }
  allLedgerCredit;
  getAllLedgerCredit() {
    this.ledgerService.getAllLedger().subscribe(res => {
      this.allLedgerCredit = res;
      for (let item of this.allLedgerCredit) {
        var temp = new SearchLedger();
        temp.ledgerName = item.ledgerName;
        temp.ledgerId = item.ledgerId;
        this.searchLedgerCreditName.push(temp)
      }
      // console.log("balance", this.allLedgerCredit);
    })
  }

  clearValue() {
    this.txtVoucherDate = new Date();
    this.txtcompany = "";
    this.txtAmount = "";
    this.txtNarration = "";
    this.txtpaymentid = null;
    // this.txtLedgerName = "";
    // this.searchledger = "";
    // this.tempLedger = "";
    this.txtpartyName = "";
    this.searchParty = "";
    this.tempParty = "";
    // this.txtLedgerCreditName = "";
    // this.searchledgerCredit = "";
    // this.tempLedgerCredit = "";
    this.txtpartyCreditName = "";
    this.searchPartyCredit = "";
    this.tempPartyCredit = "";
    this.txtLedger = null;
    this.txtLedgerCredit = null;
    this.allPartyLedgerName = null;
    this.debitPartyLedgerName = null;
  }

  resJVVoucher;
  createJVVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      return this.toastr.error("Company name must be required");
    } else if (this.txtAmount == "" || this.txtAmount == null || this.txtAmount == undefined) {
      return this.toastr.error("Amount must be required");
    // } else if (this.txtNarration == "" || this.txtNarration == null || this.txtNarration == undefined) {
    //   return this.toastr.error("Narration must be required");
    // } else if (this.tempLedger == "" || this.tempLedger == null || this.tempLedger == undefined) {
    //   return this.toastr.error("Ledger name must be required");
    } else if (this.tempParty == "" || this.tempParty == null || this.tempParty == undefined) {
      return this.toastr.error("Party name must be required");
    } else if (this.txtLedger == undefined || this.txtLedger == null || this.txtLedger == '' || this.txtLedger == '-:Select Ledger:-') {
      return this.toastr.error('Ledger name is required');
    // } else if (this.tempLedgerCredit == "" || this.tempLedgerCredit == null || this.tempLedgerCredit == undefined) {
    //   return this.toastr.error("Ledger credit name must be required");
    } else if (this.tempPartyCredit == "" || this.tempPartyCredit == null || this.tempPartyCredit == undefined) {
      return this.toastr.error("Party credit name must be required");
    } else if (this.txtLedgerCredit == undefined || this.txtLedgerCredit == null || this.txtLedgerCredit == '' || this.txtLedgerCredit == '-:Select Ledger:-') {
      return this.toastr.error('Ledger name is required');
    } else {
      // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy/MM/dd');
      this.JvService.createJVVoucher(this.txtVoucherDate, this.txtcompany.partyId, this.txtAmount, this.txtNarration, this.tempParty.partyId, this.tempPartyCredit.partyId,this.txtLedger,this.txtLedgerCredit)
        .subscribe(res => {
          this.resJVVoucher = res;
          // console.log(this.resJVVoucher);
          this.clearValue();
          this.toastr.success('JV voucher save successfully');
        }, error => {
          // this.toastr.error('JV voucher not save');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
    }
  }

  allPartyLedgerName;
  getAllLedgerByPartyId(partyId){
    this.partyService.getAllLedgerByPartyId(partyId).subscribe(res => {
      this.allPartyLedgerName = res;
    })
  }

  debitPartyLedgerName;
  getdebitLedgerByPartyId(partyId){
    this.partyService.getdebitLedgerByPartyId(partyId).subscribe(res => {
      this.debitPartyLedgerName = res;
    })
  }

  // allJV;
  // getAllJV() {
  //   this.JvService.getAllJV().subscribe(res => {
  //     this.allJV = res;
  //   })
  // }

}
