import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MenuManagementService {

  constructor(
    private http: Http,
  ) { }

  createMenu(txtmenuName: string, txtmenuAction: string, txtmenuDescription: string, txtparent: string, txtpriority: string, txtRole: Array<any>) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name: txtmenuName,
      action: txtmenuAction,
      description: txtmenuDescription,
      priority: txtpriority,
      parentmenuid: txtparent,
      roleid: txtRole,
    });
    return this.http.post('/api/menu/add', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  // update menu
  updateMenu(txtMenuName: string, txtMenuAction: string, txtPriorty: string,
    txtMenuDescription: string, txtSelectParent: number, txtRoleList: Array<any>, txtMenuId: number) {
    const headers = new Headers();
    const token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name: txtMenuName,
      action: txtMenuAction,
      description: txtMenuDescription,
      priority: txtPriorty,
      parentmenuid: txtSelectParent,
      roleid: txtRoleList,
      menuid: txtMenuId,
    });
    return this.http.put('/api/menu/update', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllMenu() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/menu/get/list', { headers })
      .pipe(map(res => res.json()));
  }
  
  getAllMenuList() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/menu/get', { headers })
      .pipe(map(res => res.json()));
  }
  //get All Menu
  getMenuRoleWise() {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('api/menu/get/rolewise', { headers: headers })
      .pipe(map(res => res.json()));
  }
}
