export class Purchase{
    id1: number;
    manufactureDate: string;
    itemId: string;
    itemName: string;
    categoryName: string;
    subCategoryName: string;
    quantity: number;
    rate: number;
    expiryDate: string;
    cgst:number;
    cgstAmount:number;
    sgst:number;
    sgstAmount:number;
    batchNumber :string;
    totalAmount :number;
    netAmount :number;
    partyId: string;
    type: string;
}