<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content"><h2>Sub Category Details</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">From <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtFormDate"
                                                name="txtFormDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">To <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                class="form-control" [(ngModel)]="txtToDate"
                                                name="txtToDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-xl-4 col-12">
                                            <label for="country" class="form-label">Sub Category <span style="color: red;">&nbsp;*</span></label>
                                            <!-- <select class="form-select" id="country" [(ngModel)]="txtSubCategory" name="txtSubCategory" 
                                            (focusout)="getAllItemBySubCategory(txtSubCategory)">
                                                <option>-:Select SubCategory:-</option>
                                                <option *ngFor="let sub of allSubCategory" [value]="sub.subcategoryid">{{sub.subCategoryName}}</option>
                                            </select> -->
                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                    [data]="dropdownList" name="txtSubCategory"
                                                    [(ngModel)]="txtSubCategory" (onSelect)="getAllItemBySubCategory($event)">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-lg-4 col-xl-4 col-12">
                                            <label for="country" class="form-label">Item <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtItem" name="txtItem">
                                                <option>-:Select Item:-</option>
                                                <option *ngFor="let item of allItemBySubCategory" [value]="item.itemid">{{item.itemName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-6 col-xl-6 col-8 btn-container-end">
                                            <button class="btn btn-primary d-block me-2" type="submit" title="Print" (click)="getSubCategoryPDF('ALL')">
                                                All Location PDF
                                            </button>
                                            <button class="btn btn-primary d-block" type="submit" title="Print" (click)="getSubCategoryPDF('null')">
                                                Your Location PDF
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Sub Category Details
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Sub Category</th>
                                            <th scope="col">Amount (Rs.)</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let srch of reSearchData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{srch.ledgername}}</td>
                                            <td class="text-end">{{srch.debit | appendIND}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
                                                    (click)="getSubCategoryPDF(srch)" style="margin-left: 5px; margin-bottom: 5px;">
                                                    <i class="fas fa-print"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td class="text-end"><b>Current Total</b></td>
                                            <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                    <tfoot>
                                        <tr *ngIf="reSearchData.length == 0 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event"
                                                (pageBoundsCorrection)="page=($event)" responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>