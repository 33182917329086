import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TransferService } from '../service/transfer/transfer.service';
import { UserService } from "../service/user/user.service";
import { LoginService } from '../service/login/login.service';
declare var $: any;
@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css']
})
export class TopHeaderComponent implements OnInit {

  user;
  firstName;
  lastName;
  location;
  txtNewPassword;
  txtConfirmPassword;
  changePasswordRes;

  constructor(
    private userservice: UserService,
    private toastr: ToastrService,
    private router: Router,
    private TransferService: TransferService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.getLoginuser();
    this.getAllTransferNotification();
    this.getAllPlanPurchaseNotification();
    this.getAllPurchaseNotification();
  }

  getLoginuser() {
    this.userservice.getLoginUser().subscribe(res => {
      this.user = res;
      this.firstName = this.user.firstname;
      this.lastName = this.user.lastname;
      this.location = this.user.location;
      localStorage.setItem("loginUserId", res.userid)
      this.getUserRoleByUserId(res.userid);
    })
  }

  tempuserRoleName;
  orgQrCode = null;
  getUserRoleByUserId(userid) {
    this.loginService.getUserRoleByUserId(userid).subscribe(res => {
      this.tempuserRoleName = res.roleName;
      if(res.roleName == "SUPER ADMIN") {
        this.getOrganizationDetailById(res.organizationId);
      } else {
        this.orgQrCode = null;
      }
    })
  }

  getOrganizationDetailById(orgid) {
    this.loginService.getOrganizationDetailById(orgid).subscribe(res => {
      this.orgQrCode = res.qrImage;
    }, error => {
      this.orgQrCode = null;
    })
  }

  logoutuser() {
    localStorage.removeItem("Token");  
    localStorage.removeItem("loginUserId");
    this.router.navigate(['']);    
  }

  changePassword() {
    if (this.txtNewPassword == "" || this.txtNewPassword == null || this.txtNewPassword == undefined) {
      return this.toastr.error("Please Enter New Password.");
    } else if (this.txtConfirmPassword == "" || this.txtConfirmPassword == null || this.txtConfirmPassword == undefined) {
      return this.toastr.error("Please Enter Confirm Password.");
    } else if (this.txtNewPassword != this.txtConfirmPassword) {
      return this.toastr.error("Password Not Matched.");
    } else {
      // console.log(this.txtNewPassword);
      // console.log(this.txtConfirmPassword);
      this.userservice.changePassword(this.txtNewPassword).subscribe(res => {
        this.changePasswordRes = res;
        // console.log(this.changePasswordRes);
        localStorage.removeItem("Token");
        this.toastr.success("Password change successfully");
        $('#ChangepwdModal').modal('hide')
        // $('.modal-backdrop').remove();
        this.router.navigate(['']);
      })
    }

  }

  allTransferNotification;
  TransferLength;
  enableTransfer = true;
  getAllTransferNotification() {
    this.TransferService.getAllTransferNotification().subscribe(res => {
      this.allTransferNotification = res;
      this.TransferLength= res.length;
      if(res.length == 1){
        if(res[0].quantity == 0){
          this.enableTransfer = false;
          this.TransferLength = 0;
        } else {
          this.enableTransfer = true;
        }
      } else if(res == [] || res.length == 0){
        this.enableTransfer = false;
        this.TransferLength = 0;
      }
    })
  }
  allPlanPurchaseNotification;
  getAllPlanPurchaseNotification() {
    this.TransferService.getAllPlanPurchaseNotification().subscribe(res => {
      this.allPlanPurchaseNotification = res;
    })
  }
  allPurchaseNotification;
  purchaseLength = 0;
  getAllPurchaseNotification() {
    this.TransferService.getAllPurchaseNotification().subscribe(res => {
      this.allPurchaseNotification = res;
      this.purchaseLength= res.length;
    })
  }
}
