import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CropsService {

  constructor(
    private http: Http,
  ) { }

  createCrops(txtProductApp: string, txtCrops: string, txtCropsGuj: string, txtCropsDes: string, txtCropsDesGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      productName: txtProductApp,
      cropsName: txtCrops,
      cropsNameGuj: txtCropsGuj,
      cropsDescription: txtCropsDes,
      cropsDescriptionGuj: txtCropsDesGuj,
    });
    return this.http.post('/api/app/create/crops', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateCrops(txtProductAppId: string, txtProductApp: string, txtCrops: string, txtCropsGuj: string, txtCropsDes: string, txtCropsDesGuj: string, txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: txtProductAppId,
      productName: txtProductApp,
      cropsName: txtCrops,
      cropsNameGuj: txtCropsGuj,
      cropsDescription: txtCropsDes,
      cropsDescriptionGuj: txtCropsDesGuj,
      image: txtImage
    });
    return this.http.put('/api/app/update/crops', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllCrops() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/crops', { headers })
      .pipe(map(res => res.json()));
  }

  deleteCrops(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: deleteAppId,
    });
    return this.http.put('/api/app/delete/crops', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/crops/image', data, { headers: headers })
      .pipe(map(res => res));
  }

}
