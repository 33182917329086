import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SaleService } from "../service/sale/sale.service";
import { DatePipe } from '@angular/common';
import { CompanyService } from '../service/company/company.service';
import { GeneralInformationService } from '../service/general-information/general-information.service';
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
import { ItemsDetailService } from '../service/items-detail/items-detail.service';

declare var $: any;
@Component({
  selector: 'app-sale-record',
  templateUrl: './sale-record.component.html',
  styleUrls: ['./sale-record.component.css']
})
export class SaleRecordComponent implements OnInit {
  allsale;
  allSaleDetail;
  search;
  page: number = 1;
  openPdfResponseNigam;
  resBillPdfNigama;
  openPdfResponseBhandar;
  resBillPdfBhandar;

  txtToGstIn;
  txtToTradeName;
  txtToAddress1;
  txtToAddress2;
  txtToPlace;
  txtToPincode;
  txtOtherValue = 0;
  txtTotalValue = 0;
  txtCgstValue = 0;
  txtSgstValue = 0;
  txtIgstValue = 0;
  txtCessValue = 0;
  txtCessNonAdvolValue = 0;
  txttotInvValue = 0;

  txtTransporterName;
  txtTransDocNo;
  txtTransMode = "R";
  txtTransDistance;
  txtTransDocDate;
  txtVehicleNo;
  txtVehicleType;
  txtSaleId;
  datePipe = new DatePipe("en-US");
  allCompany = [];
  public loading: boolean = false;
  txtFormDate;
  txtToDate;

  constructor(
    private saleservice: SaleService,
    private toastr: ToastrService,
    private companyService: CompanyService,
    private generalinformationService : GeneralInformationService,
    private itemsdetailservice: ItemsDetailService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.txtTransDocDate = this.datePipe.transform(this.txtTransDocDate, "yyyy/MM/dd");
    this.txtTransDocDate = new Date();
    // this.getAllsale();
    this.getAllsaleByDate();
    this.getAllCompany();
  }

  getallEWayBillDetailServer;
  openPdfResponseEWayBill;
  getEWayBillDetailServer(sale){
    this.saleservice.getEWayBillDetailServer(sale.saleid).subscribe(res => {
      this.getallEWayBillDetailServer = res;
      // this.getEWayBillPDFServer(res);
      var newBlob1 = new Blob([res], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "EWAY_Bill.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseEWayBill.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.getallEWayBillDetailServer = '';
      this.openPdfResponseEWayBill = '';
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }
  
  toUpperCaseVehicleNo() {
    const temp = this.txtVehicleNo;
    this.txtVehicleNo = temp.toUpperCase();
    return this.txtVehicleNo;
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }
  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
      // console.log(this.allsale);
    })
  }

  whatsappsale;
  whatsappSale(res) {
    this.saleservice.whatsappSale(res.saleid).subscribe(res => {
      this.whatsappsale = res;
      this.sendRevisedBillMSG(res);
    })
  }
  
  sendMSG;
  deleteMultiMedia = [];
  counter = 0;
  sendRevisedBillMSG(data) {
    this.deleteMultiMedia = [];
    this.counter = 0;
    this.saleservice.sendRevisedBillMSG(data.mobileNumber,data.vrfmassage).subscribe((res:any) => {
      this.sendMSG = res;
      this.deleteMultiMedia = res.multipdf;
    })
    for(let msg of data.multipdf){
      this.generalinformationService.sendAdvertisementMsgAndMedia(data.mobileNumber,"",msg).subscribe(res => {
        // this.sendMSG = res;
      })
      // this.counter++;
      // console.log(this.counter);
      // if(this.counter == (data.multipdf).length){
      //   this.saleservice.deleteMultiMedia(data.multipdf).subscribe(res1 => {
      //     // this.sendMSG = res;
      //   })
      // }
    }
  }

  userRole;
  getAllsaleByDate() {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.saleservice.getAllsaleByDate(this.txtFormDate,this.txtToDate).subscribe(res => {
      this.allsale = res.sales;
      this.userRole = res.userrole;
    })
  }

  getSaleDetail(sale) {
    this.allSaleDetail = sale.saleDetails;
    // console.log(this.allSaleDetail);
  }

  bijNigamId
  bijBhandarId
  saleNumber;
  pdfSaleId;
  tempPdfCompany;
  allPDFCompany;
  getcompnayOrganizationPDF(sale) {
    this.saleNumber = sale.saleNumber;
    this.pdfSaleId = sale.saleid;
    // this.saleservice.getCompanyOganizationPDFData(sale.saleid).subscribe(res => {
    this.saleservice.getPDFCategoryNoOfInvoiceData(sale.saleid).subscribe(res => {
      // console.log(res);
      this.allPDFCompany = res;
      // for (let data of res) {
      //   for(let comp of this.allCompany) {
      //     if (data.companyName == comp.companyName) {
      //       this.tempPdfCompany = data.companyName;
      //       this.saleBhandarPDF(res.length);
      //       break;
      //     // } else if (data.companyName == "RADHASWAMI BIJ BHANDAR") {
      //     //   this.bijBhandarId = data.companyId
      //     } else {
      //       this.tempPdfCompany = null;
      //       // this.toastr.error("something went wrong");
      //     }
      //   }
      // }
    },error => {
      this.allPDFCompany = null;
    })
  }

  allPDFCategory;
  totalNoOfInvoice = 0;
  getPDFCategoryNoOfInvoiceData(sale) {
    this.saleNumber = sale.saleNumber;
    this.saleservice.getPDFCategoryNoOfInvoiceData(sale.saleid).subscribe(res => {
      // console.log(res);
      this.allPDFCategory = JSON.parse(res);
      // console.log(JSON.parse(res));
      // this.allPDFCategory = res;
      this.totalNoOfInvoice = 0;
      for (let comp of this.allPDFCategory) {
        this.totalNoOfInvoice = this.totalNoOfInvoice + comp.invoiceDetail.length;
      }
      // console.log("Total NoOfInvoice For Discount: "+this.totalNoOfInvoice);
    },error => {
      this.allPDFCategory = null;
    })
  }

  downloadPDF() {
    if ((this.bijNigamId != undefined || this.bijNigamId != null) && (this.bijBhandarId != undefined || this.bijBhandarId != null)) {
      this.saleNigamPDF();
      // this.saleBhandarPDF();
    } else if (this.bijNigamId != undefined || this.bijNigamId != null) {
      this.saleNigamPDF();
    } else if (this.bijBhandarId != null || this.bijBhandarId != undefined) {
      // this.saleBhandarPDF();
    } else {
      this.toastr.error("something went wrong.");
    }
    this.bijBhandarId = null;
    this.bijNigamId = null;
  }

  saleNigamPDF() {
    this.saleservice.downloadSaleNigamBill(this.saleNumber).subscribe((data) => {
      this.resBillPdfNigama = data;

      var newBlob = new Blob([this.resBillPdfNigama], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Sale_Bij_Nigam.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseNigam.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
      this.resBillPdfNigama = '';
      this.openPdfResponseNigam = '';
    })
  }

  downloadPdfByCompany(company,noofCompany) {
    this.saleservice.downloadSaleBhandarBill(this.saleNumber,company.companyName,noofCompany).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }
  // new sale report by category
  downloadNewPDF(company) {
    if("PESTICIDE" == company.staticinvoicename){
      this.downloadSalePesticideBill(company);
    } else if("FERTILISER" == company.staticinvoicename){
      this.downloadSaleFertiliserBill(company);
    } else if("SEEDS" == company.staticinvoicename){
      this.downloadSaleSeedsBill(company);
    }
  }
  openPdfResponsePesticide;
  resBillPdfPesticide;
  openPdfResponseFertiliser;
  resBillPdfFertiliser;
  openPdfResponseSeeds;
  resBillPdfSeeds;
  downloadSalePesticideBill(company) {
    this.saleservice.downloadSalePesticideBill(company.invoiceid,this.saleNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfPesticide = data;

      var newBlob1 = new Blob([this.resBillPdfPesticide], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponsePesticide.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfPesticide = '';
      this.openPdfResponsePesticide = '';
    })
  }
  downloadSaleFertiliserBill(company) {
    this.saleservice.downloadSaleFertiliserBill(company.invoiceid,this.saleNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfFertiliser = data;

      var newBlob1 = new Blob([this.resBillPdfFertiliser], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseFertiliser.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfFertiliser = '';
      this.openPdfResponseFertiliser = '';
    })
  }
  downloadSaleSeedsBill(company) {
    this.saleservice.downloadSaleSeedsBill(company.invoiceid,this.saleNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfSeeds = data;

      var newBlob1 = new Blob([this.resBillPdfSeeds], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseSeeds.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfSeeds = '';
      this.openPdfResponseSeeds = '';
    })
  }

  saleBhandarPDF(NoOfCompanyLength) {
    this.saleservice.downloadSaleBhandarBill(this.saleNumber,this.tempPdfCompany,NoOfCompanyLength).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_Bij_Bhandar.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  txtEmail;
  emailsaleno;
  getsendEmailData(sale) {
    console.log(sale);
    // this.getcompnayOrganizationPDF(sale);
    this.emailsaleno = sale.saleNumber;
    if (sale.party.mailId != null) {
      this.txtEmail = sale.party.mailId;
    }
  }

  nigamEmail() {
    this.saleservice.sendNigamEmail(this.emailsaleno, this.txtEmail).subscribe(res => {
      console.log(res);
      $("#emailModal").modal('hide');
      this.toastr.success("Email sent successfully");
    })
  }

  bhandarEmail() {
    this.saleservice.sendBhandarEmail(this.emailsaleno, this.txtEmail).subscribe(res => {
      console.log(res);
      $("#emailModal").modal('hide');
      this.toastr.success("Email sent successfully");
    })
  }

  sendMail() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      if ((this.bijNigamId != undefined || this.bijNigamId != null) && (this.bijBhandarId != undefined || this.bijBhandarId != null)) {
        this.nigamEmail();
        this.bhandarEmail();
      } else if (this.bijNigamId != undefined || this.bijNigamId != null) {
        this.nigamEmail();
      } else if (this.bijBhandarId != null || this.bijBhandarId != undefined) {
        this.bhandarEmail();
      } else {
        this.toastr.error("something went wrong.");
      }
      this.bijBhandarId = null;
      this.bijNigamId = null;
    }
  }

  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }

  sendMailUsingSendgrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.saleservice.sendMailWithSendGrid(this.emailsaleno,this.txtEmail).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#emailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  clearValue(){
    this.txtToGstIn = "";
    this.txtTransDocDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtToTradeName = "";
    this.txtToAddress1 = "";
    this.txtToAddress2 = "";
    this.txtToPlace = "";
    this.txtToPincode = "";
    this.txtOtherValue = 0;
    this.txtTotalValue = 0;
    this.txtCgstValue = 0;
    this.txtSgstValue = 0;
    this.txtIgstValue = 0;
    this.txtCessValue = 0;
    this.txtCessNonAdvolValue = 0;
    this.txttotInvValue = 0;
    this.txtTransporterName = "";
    this.txtTransDocNo = "";
    this.txtTransMode = "";
    this.txtTransDistance = "";
    this.txtVehicleNo = "";
    this.txtVehicleType = "";
  }

  getEWayBillDetail(sale){
    this.txtToGstIn = sale.party.gstNumber;
    this.txtToTradeName = sale.party.partyName;
    this.txtToAddress1 = sale.party.address;
    this.txtToPincode = sale.party.pincode;
    // for(let a of this.allsale){
      this.txtTotalValue = sale.amount;
      this.txtCgstValue = parseFloat(Number(sale.gstAmount / 2).toFixed(2));
      this.txtSgstValue = parseFloat(Number(sale.gstAmount / 2).toFixed(2));
      this.txttotInvValue = sale.netAmount;
      this.txtSaleId = sale.saleid;
    // }
  }
  resEWayBill;
  createEwayBill() {
    // this.getEWayBillDetail();
    // for(let ebill of this.allsale){
    //   this.txtTotalValue = ebill.amount;
    //   this.txtCgstValue = ebill.gstAmount;
    //   this.txtSgstValue = ebill.gstAmount;
    //   this.txttotInvValue = ebill.netAmount;
    //   this.txtSaleId = ebill.saleid;
    // }
    if (this.txtToGstIn == undefined || this.txtToGstIn == null || this.txtToGstIn == '') {
      return this.toastr.error('To gstIn must be required');
    } else if (this.txtToTradeName == undefined || this.txtToTradeName == null || this.txtToTradeName == '') {
      return this.toastr.error('To trade name must be required');
    } else if (this.txtToAddress1 == undefined || this.txtToAddress1 == null || this.txtToAddress1 == '') {
    return this.toastr.error('To address1 must be required');
    // } else if (this.txtToAddress2 == undefined || this.txtToAddress2 == null || this.txtToAddress2 == '') {
    // return this.toastr.error(' to address2 must be required');
    } else if (this.txtToPlace == undefined || this.txtToPlace == null || this.txtToPlace == '') {
    return this.toastr.error('To place must be required');
    } else if (this.txtToPincode == undefined || this.txtToPincode == null || this.txtToPincode == '') {
    return this.toastr.error('To pincode must be required');
    } else if (this.txtTransporterName == undefined || this.txtTransporterName == null || this.txtTransporterName == '') {
    return this.toastr.error('Transporter name must be required');
    } else if (this.txtTransDocNo == undefined || this.txtTransDocNo == null || this.txtTransDocNo == '') {
    return this.toastr.error('Transporter DocNo must be required');
    } else if (this.txtTransMode == undefined || this.txtTransMode == null || this.txtTransMode == '') {
    return this.toastr.error('Transporter mode must be required');
    // } else if (this.txtTransDistance == undefined || this.txtTransDistance == null || this.txtTransDistance == '') {
    // return this.toastr.error(' transporter distance must be required');
    } else if (this.txtTransDocDate == undefined || this.txtTransDocDate == null || this.txtTransDocDate == '') {
    return this.toastr.error('Transporter DocDate must be required');
    } else if (this.txtVehicleNo == undefined || this.txtVehicleNo == null || this.txtVehicleNo == '') {
    return this.toastr.error('Vehicle No must be required');
    } else if (this.txtVehicleType == undefined || this.txtVehicleType == null || this.txtVehicleType == '') {
    return this.toastr.error('Vehicle type must be required');
    }
    this.saleservice.createEwayBill(this.txtToGstIn, this.txtToTradeName, this.txtToAddress1, this.txtToAddress2,
       this.txtToPlace, this.txtToPincode, this.txtOtherValue, this.txtTotalValue, this.txtCgstValue, this.txtSgstValue,
       this.txtIgstValue, this.txtCessValue, this.txtCessNonAdvolValue, this.txttotInvValue, this.txtTransporterName,
       this.txtTransDocNo, this.txtTransMode, this.txtTransDocDate, this.txtVehicleNo, this.txtVehicleType, this.txtSaleId)
      .subscribe(res => {
        this.resEWayBill = res;
        console.log(this.resEWayBill);
        this.clearValue();
        this.getAllsale();
        this.toastr.success('E-Way Bill save successfully');
      }, error => {
        // this.toastr.error('E-Way Bill not save');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
  }

  saleDeleteId;
  resDelSale;
  deleteSale(sale){
    this.saleDeleteId = sale.saleid;
  }
  confirmDelete(){
    if (this.saleDeleteId != null) {
      this.saleservice.deleteSale(this.saleDeleteId).subscribe(res => {
        this.resDelSale = res;
        this.getAllsaleByDate();
        $('#deleteModal').modal('hide');
        this.toastr.success('Sale deleted successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  largeviewImage;
  imgLargeview(data: any) {
    this.largeviewImage = data.image
  }

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity;
  txtrate;
  txtExpiryDate;
  // btnName = 'Add';
  btnName = 'Update';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];
  txtsaleid;

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getSaleDetailBySaleId(sale){
    this.saleservice.getSaleDetailBySaleId(sale.saleid).subscribe(res => {
      this.allSaleDetail = res;
    })
  }

  itemId;
  // getAllItem() {
  //   this.itemsdetailservice.getAllItem().subscribe(res => {
  //     this.allItem = res;
  //     for (let item of this.allItem) {
  //       var temp = new SearchItemDtos();
  //       temp.itemName = item.itemName;
  //       temp.itemId = item.itemid;
  //       this.searchItemName.push(temp)
  //     }
  //   })
  // }
  // selectedStatic(result) {
  //   this.itemId = result.itemId;
  //   this.search1 = result.itemName;
  //   this.getItemById();
  // }

  // resItemById;
  // getItemById() {
  //   this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
  //     this.resItemById = res;
  //     this.txtCategoryName = this.resItemById.category.categoryName;
  //     this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
  //     this.txtBrandName = this.resItemById.brand.name;
  //   });
  // }

  updateSale(sale){
    this.btnName = "Update";
    // this.txtsaleid = sale.saleid;
    this.txtsaleid = sale.saledetailid;
    this.txtItemName = sale.item.itemName;
    this.itemId = sale.item.itemid;
    this.search1 = sale.item.itemName;
    this.txtCategoryName = sale.item.category.categoryName;
    this.txtSubCategoryName = sale.item.subCategory.subCategoryName;
    this.txtBrandName = sale.item.brand.name;
    this.txtquantity = sale.quantity;
    this.txtrate = sale.rate;
    this.txtManufactureDate = sale.manufactureDate;
    this.txtExpiryDate = sale.expiryDate;
  }

  txtStockDTOs;
  stockQuantity: number = 0;
  isClick: any = false;
  resSaleDetail;
  addUpdateSaleDetail(){
    this.isClick = true;
    if (this.txtItemName == null || this.txtItemName == '' || this.txtItemName == undefined) {
      this.isClick = false;
      this.toastr.error("Item is required");
    } else if (this.txtBrandName == undefined || this.txtBrandName == null || this.txtBrandName == '') {
      this.isClick = false;
      return this.toastr.error('Brand name is required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == '' || this.txtquantity == 0) {
      this.isClick = false;
      return this.toastr.error('Quantity is required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == '' || this.txtrate == 0) {
      this.isClick = false;
      return this.toastr.error('Rate is required');
    } else if (this.batchwiseresponse == null) {
      this.isClick = false;
      return this.toastr.error('Please verify stock');
    } else {
      if (this.txtsaleid == null) {
        // this.saleservice.createSaleDetail(this.txtItemName,this.itemId,this.txtCategoryName,this.txtSubCategoryName, this.txtBrandName, this.txtquantity,this.txtrate)
        //   .subscribe(res => {
        //     this.resSaleDetail = res;
        //     this.getSaleDetailBySaleId(res.saleid);
        //     this.clearGridData();
        //     this.toastr.success('Sale detail save successfully');
        //   }, error => {
        //     let message = JSON.parse(error._body).message;
        //     this.toastr.error(JSON.stringify(message));
        //   })
      } else {
        if (this.batchwiseresponse == null) {
          this.getBatchNumber();
          this.txtStockDTOs = this.batchwiseresponse;
        } else {
          this.txtStockDTOs = this.batchwiseresponse;
        }
        this.stockQuantity = 0;
        for (let stockQuantityValue of this.batchwiseresponse) {
          this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
        }
        console.log("quantity",this.stockQuantity);
        if (this.stockQuantity > this.txtquantity) {
          this.isClick = false;
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is more than quantity.');
        }else if(this.stockQuantity < this.txtquantity){
          this.isClick = false;
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is less than quantity.');
        }
        
        this.saleservice.updateSaleDetail(this.txtsaleid,this.txtItemName,this.itemId,this.txtCategoryName,this.txtSubCategoryName, this.txtBrandName, this.txtquantity,this.txtrate,this.txtStockDTOs).subscribe(res => {
          this.resSaleDetail = res;
          this.getSaleDetailBySaleId(res);
          this.clearGridData();
          this.toastr.success('Sale detail updated successfully');
        }, error => {
          this.isClick = false;
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  clearGridData() {
    this.txtItemName = null;
    this.txtManufactureDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = null;
    this.txtSubCategoryName = null;
    this.txtBrandName = null;
    this.txtquantity = null;
    this.txtrate = null;
    this.txtExpiryDate = new Date();
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = null;
    // this.btnName = "Add";
    this.btnName = "Update";
    this.txtsaleid = null;
    this.isClick = false;
    this.batchwiseresponse = null;
    this.txtStockDTOs = null;
  }
  
  batchwiseresponse;
  totalStockQuantity = 0;
  getBatchNumber() {
    $('#exampleModal').modal('hide');
    $('#stockCheckModal').modal('show');
    $(document.body).addClass('static-modal-open');

    this.saleservice.getStockByItemPerBatch(this.itemId, this.txtquantity).subscribe(res => {
      this.batchwiseresponse = res;
      if (this.batchwiseresponse.length <= 0) {
        return this.toastr.error('Stock Not Found.');
      }
      console.log(this.batchwiseresponse);
      this.totalStockQuantity = 0;
      for (let batchWiseStock of this.batchwiseresponse) {
        if (batchWiseStock.allotedquantity == null) {
          batchWiseStock.allotedquantity = 0
        }
        var sum = 0;
        sum = sum + Number(batchWiseStock.quantity);
        this.totalStockQuantity = this.totalStockQuantity + sum;
      }
      console.log("batchWise Stock", this.batchwiseresponse);
      if(this.txtquantity > this.totalStockQuantity){
        this.toastr.error('Stock not available for item');
      } else {
        this.toastr.success('Stock available');
      }
      // this.addSaleDetail();
    }, error => {
      this.toastr.error('Stock not available for item');
    })
  }
  hideShowBarcodeModel() {
    $('#stockCheckModal').modal('hide');
    $('#exampleModal').modal('show');
  }
  hideShowHistoryModel() {
    $(document.body).removeClass('static-modal-open');
  }

}