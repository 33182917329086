<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Party Closing Report</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-8 bottom_margin">
                                    <div class="row">
                                    <!-- <div class="col-12 col-xl-3 col-lg-3 bottom_margin">
                                        <button class="btn btn-primary" type="submit"
                                            (click)="exportAsXLSX()">Export To Excel</button>
                                    </div> -->
                                    <div class="col-lg-3 col-xl-3 col-6" > 
                                        <!-- <label for="first" class="form-label">Group</label> -->
                                        <select class="form-select" id="country" name="txtGroupName"
                                            [(ngModel)]="txtGroupName" required="">
                                            <option>-:Select Group:-</option>
                                            <option *ngFor="let group of allGroup" [ngValue]="group.groupName">
                                                {{group.groupName}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-xl-3 col-lg-3">
                                        <button class="btn btn-primary" type="submit" (click)="getPartyWhatsaapMessage()">
                                            Send
                                        </button>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" (focusout)="calculateNetAmount()"/>
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex mt-1">
                                <!-- <div class="col-md-12"> -->
                                    <div class="row">
                                        <div class="col-12 col-xl-3 bottom_margin">
                                            <!-- <div class="col-lg-2 col-xl-3 col-6"> -->
                                                <label for="first" class="form-label">Company Name<span style="color: red;">&nbsp;*</span></label>
                                                <select class="form-select" name="txtCompany" [(ngModel)]="txtCompany" required="">
                                                    <option>All</option>
                                                    <option *ngFor="let company of allCompany" [ngValue]="company.companyId">{{company.companyName}}</option>
                                                </select>
                                                <div class="invalid-feedback">
                                                    Please enter company.
                                                </div>
                                            <!-- </div> -->
                                        </div>
                                        <div class="col-12 col-xl-6" style="margin-top: 30px;">
                                            <button class="btn btn-primary me-2" type="submit" (click)="getAllPartyClosingReport()">Search</button>
                                            <button class="btn btn-primary" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                        </div>
                                    </div>
                                <!-- </div> -->
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Party Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Opening Balance (Rs.)</th>
                                            <th scope="col">Debit (Rs.)</th>
                                            <th scope="col">Credit (Rs.)</th>
                                            <th scope="col">Closing Balance (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dtl of partyReportData | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{dtl.partyName}}</td>
                                            <td>{{dtl.address}}</td>
                                            <td class="text-end">{{dtl.openingBalance | appendIND}}</td>
                                            <td class="text-end">{{dtl.debit | appendIND}}</td>
                                            <td class="text-end">{{dtl.credit | appendIND}}</td>
                                            <td class="text-end">{{dtl.closingBalance | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allPartyClosingData < 1 ">
                                            <td colspan="5" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>