import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MediaMarketingService {

  constructor(
    private http: Http,
  ) { }

  createMediaMarketing(txtDescription: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      description: txtDescription,
    });
    return this.http.post('/api/create/mrkt/media', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateMediaMarketing(ttxmasterId: string,txtDescription: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      masterid: ttxmasterId,
      description: txtDescription,
    });
    return this.http.put('/api/update/mrkt/media', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadMedia(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/mrkt/media', data, { headers: headers })
      .pipe(map(res => res));
  }

  getMediaMarketing() {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/mrkt/media', { headers })
      .pipe(map(res => res.json()));
  }
  
  deleteMediaMarketing(txtmasterId: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      masterid: txtmasterId,
    });

    return this.http.put('/api/dlt/mrkt/media', body, { headers })
      .pipe(map(res => res.json()));
  }

  onoffMediaMarketingView(txtmasterId: string,txtvwType: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      masterid: txtmasterId,
      vwType: txtvwType,
    });

    return this.http.put('/api/onoff/mrkt/media', body, { headers })
      .pipe(map(res => res.json()));
  }
}
