import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CompanyService } from '../service/company/company.service';
import { StockService } from "../service/stock/stock.service";
import { ToastrService } from "ngx-toastr";
import { ExcelService } from '../service/excel/excel.service';

@Component({
  selector: 'app-sale-report',
  templateUrl: './sale-report.component.html',
  styleUrls: ['./sale-report.component.css']
})
export class SaleReportComponent implements OnInit {

  // txtFormDate = '2021-04-01';
  txtFormDate;
  txtToDate;
  datePipe = new DatePipe("en-US");
  // totalNetAmount = 0;
  search;
  page = 1;
  allCompany;
  reSearchSaleData;
  txtcompany;
  totalSaleAmount: number;
  totalExemptedNetAmount:number;
  
  totalFiveNetAmount:number;
  fiveCgstAmount:number;
  fiveSgstAmount:number;
  fiveIgstAmount:number;

  totalTwelveNetAmount:number;
  twelveCgstAmount:number;
  twelveSgstAmount:number;
  twelveIgstAmount:number;

  totalEightgNetAmount:number;
  eightCgstAmount:number;
  eightSgstAmount:number;
  eightIgstAmount:number;

  totalTwentyEightNetAmount:number;
  tewntyeightCgstAmount:number;
  tewntyeightSgstAmount:number;
  tewntyeightIgstAmount:number;

  totalGstAmount: number;
  totalNetAmount: number;
  totalDiscount: number;
  totalAddCharge: number;
  // enableNigam = false;
  
  constructor(
    private companyService: CompanyService,
    private toastr: ToastrService,
    private stockservice: StockService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtFormDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtToDate = new Date();
    this.getAllCompany();
  }

  txtCompanyId;
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      this.txtCompanyId = res.companyId;
    })
  }

  searchSaleReport() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name is required");
    } else {
      // if(this.txtcompany == 1 ){
      //   this.enableNigam = true;
      // }else{
      //   this.enableNigam = false;
      // }
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');

      this.stockservice.getSaleReport(this.txtFormDate, this.txtToDate, this.txtcompany).subscribe(res => {
        this.reSearchSaleData = res;
        this.totalSaleAmount = 0;
        this.totalExemptedNetAmount = 0;
        
        this.totalFiveNetAmount = 0;
        this.fiveCgstAmount=0;
        this.fiveSgstAmount=0;
        this.fiveIgstAmount=0;

        this.totalTwelveNetAmount = 0;
        this.twelveCgstAmount=0;
        this.twelveSgstAmount=0;
        this.twelveIgstAmount=0;

        this.totalEightgNetAmount = 0;
        this.eightCgstAmount=0;
        this.eightSgstAmount=0;
        this.eightIgstAmount=0;

        this.totalTwentyEightNetAmount = 0;
        this.tewntyeightCgstAmount=0;
        this.tewntyeightSgstAmount=0;
        this.tewntyeightIgstAmount=0;

        this.totalGstAmount = 0;
        this.totalNetAmount = 0;
        this.totalDiscount = 0;
        this.totalAddCharge = 0;
        for (let sale of this.reSearchSaleData) {
            this.totalSaleAmount = parseFloat(Number(this.totalSaleAmount + (sale.purchaseamount)).toFixed(2));
            this.totalExemptedNetAmount = this.totalExemptedNetAmount + sale.exemptedNetAmount;

            this.totalFiveNetAmount = this.totalFiveNetAmount + sale.fiveNetAmount;
            this.fiveCgstAmount = this.fiveCgstAmount + sale.fiveCgst;
            this.fiveSgstAmount = this.fiveSgstAmount + sale.fiveSgst;
            this.fiveIgstAmount = this.fiveIgstAmount + sale.fiveIgst;
  
            this.totalTwelveNetAmount = this.totalTwelveNetAmount + sale.twelveNetAmount;
            this.twelveCgstAmount = this.twelveCgstAmount + sale.twelveCgst;
            this.twelveSgstAmount = this.twelveSgstAmount + sale.twelveSgst;
            this.twelveIgstAmount = this.twelveIgstAmount + sale.twelveIgst;
  
            this.totalEightgNetAmount = this.totalEightgNetAmount + sale.eighteenNetAmount;
            this.eightCgstAmount = this.eightCgstAmount + sale.eighteenCgst;
            this.eightSgstAmount = this.eightSgstAmount + sale.eighteenSgst;
            this.eightIgstAmount = this.eightIgstAmount + sale.eighteenIgst;
  
            this.totalTwentyEightNetAmount = this.totalTwentyEightNetAmount + sale.tewntyeightNetAmount;
            this.tewntyeightCgstAmount = this.tewntyeightCgstAmount + sale.tewntyeightCgst;
            this.tewntyeightSgstAmount = this.tewntyeightSgstAmount + sale.tewntyeightSgst;
            this.tewntyeightIgstAmount = this.tewntyeightIgstAmount + sale.tewntyeightIgst;
  
            this.totalGstAmount = parseFloat(Number(this.totalGstAmount + (sale.totalcgst + sale.totalsgst + sale.totaligst)).toFixed(2));
            this.totalNetAmount = parseFloat(Number(this.totalNetAmount + (sale.netamount)).toFixed(2));
            this.totalDiscount = parseFloat(Number(this.totalDiscount + (sale.salediscount)).toFixed(2));
            this.totalAddCharge = parseFloat(Number(this.totalAddCharge + (sale.additionalCharge)).toFixed(2));
          
          // if (this.txtcompany != 1) {
          //   this.totalSaleAmount = parseFloat(Number(this.totalSaleAmount + (sale.credit - sale.gstamount)).toFixed(2));
          //   this.totalGstAmount = this.totalGstAmount + sale.gstamount;
          //   // this.totalNetAmount = this.totalNetAmount + sale.netamount;
          //   this.totalNetAmount = parseFloat(Number(this.totalNetAmount + (sale.credit)).toFixed(2));
          // } else {
          //   this.totalSaleAmount = parseFloat(Number(this.totalSaleAmount + (sale.credit - sale.gstamount)).toFixed(2));
          //   this.totalGstAmount = 0 ;
          //   this.totalNetAmount = parseFloat(Number(this.totalNetAmount + (sale.credit)).toFixed(2));;
          // }
        }
      }, error => {
        // this.toastr.error("");
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
      });
    }
  }

  ExalData = [];
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.reSearchSaleData){
      var gst = exal.totalcgst + exal.totalsgst + exal.totaligst;
      var exaldate = this.datePipe.transform(exal.date, 'dd-MM-yyyy');
      var exemptedNetAmount : number = 0;
      // var exemptedper : number = 0;

      // var fiveNetAmount : number = 0;
      // var fiveper : number = 0;

      // var twelveNetAmount : number = 0;
      // var twelveper : number = 0;

      // var eightgNetAmount : number = 0;
      // var eighteenper : number = 0;

      // var twentyEightNetAmount : number = 0;
      // var tewntyeightper : number = 0;

      if(exal.exempted != null){
        exemptedNetAmount = exal.exemptedNetAmount;
        // exemptedper = exal.exempted;
      }
      // if(exal.five != null){
        var fiveNetAmount = null != exal.fiveNetAmount ? exal.fiveNetAmount : 0;
        var fiveCgst = null != exal.fiveCgst ? exal.fiveCgst : 0;
        var fiveSgst = null != exal.fiveSgst ? exal.fiveSgst : 0;
        var fiveIgst = null != exal.fiveIgst ? exal.fiveIgst : 0;
      // }
      // if(exal.twelve != null){
        var twelveNetAmount = null != exal.twelveNetAmount ? exal.twelveNetAmount : 0;
        var twelveCgst = null != exal.twelveCgst ? exal.twelveCgst : 0;
        var twelveSgst = null != exal.twelveSgst ? exal.twelveSgst : 0;
        var twelveIgst = null != exal.twelveIgst ? exal.twelveIgst : 0;
      // }
      // if(exal.eighteen != null){
        var eightgNetAmount = null != exal.eighteenNetAmount ? exal.eighteenNetAmount : 0;
        var eighteenCgst = null != exal.eighteenCgst ? exal.eighteenCgst : 0;
        var eighteenSgst = null != exal.eighteenSgst ? exal.eighteenSgst : 0;
        var eighteenIgst = null != exal.eighteenIgst ? exal.eighteenIgst : 0;
      // }
      // if(exal.tewntyeight != null){
        var twentyEightNetAmount = null != exal.tewntyeightNetAmount ? exal.tewntyeightNetAmount : 0;
        var tewntyeightCgst = null != exal.tewntyeightCgst ? exal.tewntyeightCgst : 0;
        var tewntyeightSgst = null != exal.tewntyeightSgst ? exal.tewntyeightSgst : 0;
        var tewntyeightIgst = null != exal.tewntyeightIgst ? exal.tewntyeightIgst : 0;
      // }

      this.ExalData.push({
        "Sale Date": exaldate, "Name": exal.name,"GST Number":exal.gstnumber, "Sale Number": exal.purchasenumber,"Amount": exal.purchaseamount,
        "GST 0% Amt":exemptedNetAmount,
        "GST 5% Amt":fiveNetAmount,"CGST 2.5%":fiveCgst,"SGST 2.5%":fiveSgst,"IGST 5%":fiveIgst,
        "GST 12% Amt":twelveNetAmount,"CGST 6%":twelveCgst,"SGST 6%":twelveSgst,"IGST 12%":twelveIgst,
        "GST 18% Amt":eightgNetAmount,"CGST 9%":eighteenCgst,"SGST 9%":eighteenSgst,"IGST 18%":eighteenIgst,
        "GST 28% Amt":twentyEightNetAmount,"CGST 14%":tewntyeightCgst,"SGST 14%":tewntyeightSgst,"IGST 28%":tewntyeightIgst,
        "GST Amount": gst, "Discount": exal.salediscount, "Additional Charge": exal.additionalCharge,"Net Amount": exal.netamount
      })
    }
    this.excelservice.exportSaleReportExcelFile( 'SaleReport',this.ExalData,this.totalSaleAmount,
      this.totalExemptedNetAmount,
      this.totalFiveNetAmount,this.fiveCgstAmount,this.fiveSgstAmount,this.fiveIgstAmount,
      this.totalTwelveNetAmount,this.twelveCgstAmount,this.twelveSgstAmount,this.twelveIgstAmount,
      this.totalEightgNetAmount,this.eightCgstAmount,this.eightSgstAmount,this.eightIgstAmount,
      this.totalTwentyEightNetAmount,this.tewntyeightCgstAmount,this.tewntyeightSgstAmount,this.tewntyeightIgstAmount,
      this.totalGstAmount,this.totalNetAmount,this.totalAddCharge,this.totalDiscount);
 }

  resSaleCompanyDetailPdf;
  openPdfResponseSaleCompany;
  downloadPDF() {
    this.stockservice.downloadSaleReport(this.txtFormDate, this.txtToDate, this.txtcompany).subscribe((data) => {
      this.resSaleCompanyDetailPdf = data;

      var newBlob = new Blob([this.resSaleCompanyDetailPdf], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      const saleData = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = saleData;
      link.download = "saleReport.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseSaleCompany.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(saleData);
        link.remove();
      }, 100);
      this.resSaleCompanyDetailPdf = '';
      this.openPdfResponseSaleCompany = '';
    })
  }

}
