import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PurchaseReturnService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  addPurchaseReturnDetail(txtPurchaseReturnDate: string, txtpurchaseReturnCode: string, txtpartyName: string, txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, PurchaseReturn: Array<any>, txtDueDays: String, txtDueDate: String, txtNarration: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      purchaseDate: txtPurchaseReturnDate,
      purchaseNumber: txtpurchaseReturnCode,
      partyId: txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      purchaseDetailDTOs: PurchaseReturn,
      dueDays: txtDueDays,
      dueDate: txtDueDate,
      narration: txtNarration
    });
    return this.http.post('/api/create/purchasereturn', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllPurchaseReturn() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/purchasereturn', { headers })
      .pipe(map(res => res.json()));
  }

  getAllPurchaseReturnByDate(startDate: string, enddate:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasereturn/date/'+startDate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }
  
  getPurchaseReturnNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasereturnnumber', { headers })
      .pipe(map(res => res.json()));
  }

  getBatchNumberByItemId(itemid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/batchnumberby/item/' + itemid, { headers })
      .pipe(map(res => res.json()));
  }

  getQtyByBatchNoAndItemId(itemid: string,batchno:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/item/qtyby/' + itemid + '/batchnumber/' + batchno, { headers })
      .pipe(map(res => res.json()));
  }

  getBatchNumberByPartyANdItem(partyid: string, itemid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/find/purchase/batchno/party/' + partyid + '/item/' + itemid , { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseDetailByPartyItemAndBatchNo(partyid: string, itemid: string,batchno:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchaseno/party/' + partyid + '/item/' + itemid + '/batchnumber/' + batchno, { headers })
      .pipe(map(res => res.json()));
  }

  getQTYNRateByPurchaseNoAndItem(saleno: string,itemid:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/qtyrate/purchaseno/'+ saleno +'/item/' + itemid, { headers })
      .pipe(map(res => res.json()));
  }

  deletePurchaseReturn(purchaseReturnDeleteId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      // purchasereturnid: purchaseReturnDeleteId,
    });
    return this.http.put('/api/delete/purchasereturn/'+ purchaseReturnDeleteId, body, { headers })
      .pipe(map(res => res.json()));
  }

  getQTYByPurchaseNoAndItem(Purchasenumber: string,itemid:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/purchasereturn/chk/stock/'+ Purchasenumber +'/' + itemid, { headers })
      .pipe(map(res => res.json()));
  }

  public downloadPurchaseReturnBhandarBill(billnumber: string, companyname: string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    // return this.httpClient.get(`/api/generate/server/purchasereturn/bhandar/bill/` + billnumber + `/` + companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
    return this.httpClient.get(`/api/generate/purchasereturn/bhandar/bill/` + billnumber + `/` + companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

  getCompanyOganizationPDFData(purchasereturnid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasereturn/company/organization/detail/' + purchasereturnid,{ headers })
      .pipe(map(res => res.json()));
  }

  // new purchase retuen PDF by category
  getPurchaseReturnPDFCategoryNoOfInvoiceData(saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasereturn/pdf/category/noofinvoice/detail/' + saleid,{ headers })
      .pipe(map(res => res.text()));
  }
  
  public downloadPurchaseReturnPesticideBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/purchasereturn/pesticide/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadPurchaseReturnFertiliserBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/purchasereturn/fertiliser/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadPurchaseReturnSeedsBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/purchasereturn/seeds/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

}
