import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CashService {

  constructor(private http: Http) { }

  createCash(txtCash: string,txtCompanyId:string,txtCompany:String,txtopeningBalance: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      cashName : txtCash,
      cashId : 6,
      companyId : txtCompanyId,
      companyName : txtCompany,
      openingBalance: txtopeningBalance,
    });
    return this.http.post('/api/create/cash', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllCash(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/cash', { headers })
      .pipe(map(res => res.json()));
  }

}
