import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import * as CanvasJS from './canvasjs.min.js';
import { StockService } from "../service/stock/stock.service";
import { SaleWiseAmountDTO } from '../service/sale/SaleWiseAmountDTO.js';
import { LocationService } from "../service/location/location.service";
import { Chart } from 'chart.js';
import { DailyReportDTO } from '../service/stock/dailyReport.js';
import { DatePipe } from '@angular/common';
import { CompanyService } from '../service/company/company.service.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  map: google.maps.Map;

  allStock;
  contentString;
  location = [];
  netAmount = [];
  netAmountDays = [];
  allLocation;
  txtlocation ="All";
  txtSaleBytype="All";
  txtStartDate;
  txtEndDate;
  datePipe = new DatePipe("en-US");
  allYouWillGet = [];
  allYouWillPay = [];
  youwillGetFinancialyear;
  youwillPayFinancialyear;


  constructor(private stockservice: StockService,private LocationService: LocationService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.getAllCompany();
    this.txtYear = new Date().getFullYear().toString();
    this.txtPurchaseYear = new Date().getFullYear().toString();
    this.txtCreditCashYear = new Date().getFullYear().toString();
    this.txtStartDate =  new Date();
    this.txtStartDate =  this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate =  new Date();
    this.txtEndDate =  this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    this.purchaseBarChart();
    this.top10SaleItemName();
    this.getAllLocation();
    this.getSumSaleByYearType();
    this.topAccount();
    // this.loadNigamDailyChart();
    // this.loadBhandarDailyChart();
    this.getAllBankBookData();

    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    var next_year = curr_year+1;
    this.txtPurchaseFinancialYear = (curr_year+"-"+next_year);
    this.txtSaleFinancialYear = (curr_year+"-"+next_year);
    this.txtPaymentCreditFinancialYear = (curr_year+"-"+next_year);
    this.txtReceiptDebitFinancialYear = (curr_year+"-"+next_year);
    this.youwillGetFinancialyear = (curr_year+"-"+next_year);
    this.youwillPayFinancialyear = (curr_year+"-"+next_year);
    this.getAllPurchaseReportCompanyWise();
    this.getAllSaleReportCompanyWise();
    this.getAllPaymentCreditReportCompanyWise();
    this.getAllReceiptDebitReportCompanyWise();
    // this.getAllYouWillGetDetail();
    // this.getAllYouWillPayDetail();

    var surat = { lat: 21.207179, lng: 72.838935 };
    var kamrej = { lat: 21.2695, lng: 72.9577 };
    var kim = { lat: 21.4006, lng: 72.9269 };
    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 10,
      center: surat
    });
    var legend = document.getElementById('legend');
    var div = document.createElement('div');
    div.innerHTML = '<h6 class="inner-dtl" style="font-size:0.8rem;">' + '<img style="width:14px;" src=' + 'assets/images/triangle.png' + '>' + " : Net Amount" + '</h6> ' +
      '<h6 class="inner-dtl" style="font-size:0.8rem;letter-spacing: 0.5px;">' + '<img style="width:14px;" src=' + 'assets/images/dot.png' + '>' + " : Net Amount Days " + '</h6>';
      
    legend.appendChild(div);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(legend);
    this.stockservice.getSumSaleByYear().subscribe((res: SaleWiseAmountDTO[]) => {
      this.allStock = res;
      console.log(this.allStock);
      for (var i = 0; i < this.allStock.length; i++) {
        this.location = this.allStock[i].location
        this.netAmount = this.allStock[i].netAmount;
        this.netAmountDays = this.allStock[i].netAmountDays;
        // console.log("Stock",this.stock);
        // console.log("Approval",this.approval);
        // console.log("Sale",this.sale);
        if (this.allStock[i].location == "SURAT") {
          this.contentString = '<h3 id="firstHeading" class="firstHeading" style="font-size:1rem;font-weight:700;">' + this.location + '</h3>' +
            '<h6 id="firstHeading" class="firstHeading" style="font-size:0.8rem;letter-spacing: 0.5px;"><img style="width:14px;" src=' + 'assets/images/triangle.png' + '> : ' + this.netAmount + '</h6>' +
            '<h6 id="firstHeading" class="firstHeading" style="font-size:0.8rem;letter-spacing: 0.5px;"><img style="width:14px;" src=' + 'assets/images/dot.png' + '> : ' + this.netAmountDays + '</h6>' ;
          var infowindow = new google.maps.InfoWindow({
            content: this.contentString,
            maxWidth: 200
          });
          var markersurat = new google.maps.Marker({
            position: surat,
            map: map,
            title: this.location + ' (Stock Details)'
          });
          markersurat.addListener('click', function () {
            infowindow.open(map, markersurat);
          });
        }
        // markersurat.setAnimation(google.maps.Animation.DROP);
      }
    });
    this.stockservice.getSumSaleByYear().subscribe((res: SaleWiseAmountDTO[]) => {
      this.allStock = res;
      console.log(this.allStock);
      for (var i = 0; i < this.allStock.length; i++) {
        this.location = this.allStock[i].location
        this.netAmount = this.allStock[i].netAmount;
        this.netAmountDays = this.allStock[i].netAmountDays;
        // console.log("Stock",this.stock);
        // console.log("Approval",this.approval);
        // console.log("Sale",this.sale);
        if (this.allStock[i].location == "KAMREJ") {
          this.contentString = '<h3 id="firstHeading" class="firstHeading" style="font-size:1rem;font-weight:700;">' + this.location + '</h3>' +
            '<h6 id="firstHeading" class="firstHeading" style="font-size:0.8rem;letter-spacing: 0.5px;"><img style="width:14px;" src=' + 'assets/images/triangle.png' + '> : ' + this.netAmount + '</h6>' +
            '<h6 id="firstHeading" class="firstHeading" style="font-size:0.8rem;letter-spacing: 0.5px;"><img style="width:14px;" src=' + 'assets/images/dot.png' + '> : ' + this.netAmountDays + '</h6>' ;
          var infowindow = new google.maps.InfoWindow({
            content: this.contentString,
            maxWidth: 200
          });
          var markerkamrej = new google.maps.Marker({
            position: kamrej,
            map: map,
            title: this.location + ' (Stock Details)'
          });
          markerkamrej.addListener('click', function () {
            infowindow.open(map, markerkamrej);
          });
        }
        // markersurat.setAnimation(google.maps.Animation.DROP);
      }
    });
    this.stockservice.getSumSaleByYear().subscribe((res: SaleWiseAmountDTO[]) => {
      this.allStock = res;
      console.log(this.allStock);
      for (var i = 0; i < this.allStock.length; i++) {
        this.location = this.allStock[i].location
        this.netAmount = this.allStock[i].netAmount;
        this.netAmountDays = this.allStock[i].netAmountDays;
        // console.log("Stock",this.stock);
        // console.log("Approval",this.approval);
        // console.log("Sale",this.sale);
        if (this.allStock[i].location == "KIM") {
          this.contentString = '<h3 id="firstHeading" class="firstHeading" style="font-size:1rem;font-weight:700;">' + this.location + '</h3>' +
            '<h6 id="firstHeading" class="firstHeading" style="font-size:0.8rem;letter-spacing: 0.5px;"><img style="width:14px;" src=' + 'assets/images/triangle.png' + '> : ' + this.netAmount + '</h6>' +
            '<h6 id="firstHeading" class="firstHeading" style="font-size:0.8rem;letter-spacing: 0.5px;"><img style="width:14px;" src=' + 'assets/images/dot.png' + '> : ' + this.netAmountDays + '</h6>' ;
          var infowindow = new google.maps.InfoWindow({
            content: this.contentString,
            maxWidth: 200
          });
          var markerkamrej = new google.maps.Marker({
            position: kim,
            map: map,
            title: this.location + ' (Stock Details)'
          });
          markerkamrej.addListener('click', function () {
            infowindow.open(map, markerkamrej);
          });
        }
        // markersurat.setAnimation(google.maps.Animation.DROP);
      }
    });
  }
  companyName = [];
  selectedwallet = 0;
  allCompany;
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      this.selectedChange(res[0],0);
      for(let com of res){
        this.companyName.push({"companyName": com.companyName, "companyId": com.companyId});
      }
    })
  }

  companyReportName;
  companyReportId;
  selectedChange(event,i){
    this.selectedwallet = i;
    this.companyReportName = event.companyName;
    this.companyReportId = event.companyId;
    this.stockservice.getAllBhandarDailyReport(this.txtStartDate,this.txtEndDate, this.companyReportId).subscribe((res: DailyReportDTO[]) => {
      this.allBhandarDailyReport = res;
    })
  }

  txtPurchaseYear;
  purchaseBarChartEnable = true;
  purchaseBarChart(){
    this.purchaseBarChartEnable = true;
    this.stockservice.getSumPurchaseCaratByMonthAndCrtYear(this.txtPurchaseYear).subscribe(res => {
      var dataPoints = [];
      if(res.length != 0){
        for (var i = 0; i < res.length; i++) {
          // var month = res[i].month;
          // var carat = res[i].totalCarat;
          dataPoints.push({ label: res[i].month, y: res[i].netAmount })
          // console.log(month);
          // console.log(carat);
          var chart = new CanvasJS.Chart("barchart", {
            animationEnabled: true,
            // exportEnabled: true,
            // theme: "light2",
            title: {
              // text: "Monthly Purchase Data"
            },
            axisY: {
              valueFormatString: "#,##,###.#",
              title: "Total Purchase",
              titleFontSize: 16
            },
            axisX: {
              title: "Month",
              titleFontSize: 16
            },
            data: [{
              type: "bar",
              bevelEnabled: true,
              indexLabel: "{y}",
              dataPoints: dataPoints,
              valueFormatString: "#,##,###.#",
            }]
          });
          chart.render();
        }
      } else {
        this.purchaseBarChartEnable = false;
      }
    })
  }

  //Top 3 Saleing Packet Name
  txtYear;
  topSaleItemName = true;
  top10SaleItemName() {
    this.topSaleItemName = true;
    this.stockservice.getTop3SalePacketName(this.txtYear).subscribe(res => {
      var dataPoints = [];
      if(res.length != 0){
        for (var i = 0; i < res.length; i++) {
          dataPoints.push({ label: res[i].itemname, y: res[i].quantity })

          var chart = new CanvasJS.Chart("topsalechart", {
            animationEnabled: true,
            // exportEnabled: true,
            // theme: "light2",
            // title: {
            //   text: "Monthly Purchase Data"
            // },
            axisY: {
              title: "Total Sale",
              titleFontSize: 16
            },
            axisX: {
              title: "Item Name",
              titleFontSize: 16
            },
            data: [{
              type: "column",
              bevelEnabled: true,
              indexLabel: "{y}",
              dataPoints: dataPoints
            }]
          });
          chart.render();
        }
      } else {
        this.topSaleItemName = false;
      }
    })
  }

  top10SaleByItemName() {
    this.topSaleItemName = true;
    this.stockservice.getTop3SaleItemNameByLocation(this.txtlocation,this.txtYear).subscribe(res => {
      var dataPoints = [];
      if(res.length != 0){
        for (var i = 0; i < res.length; i++) {
          dataPoints.push({ label: res[i].itemname, y: res[i].quantity })

          var chart = new CanvasJS.Chart("topsalechart", {
            animationEnabled: true,
            // exportEnabled: true,
            // theme: "light2",
            // title: {
            //   text: "Monthly Purchase Data"
            // },
            axisY: {
              title: "Total Sale",
              titleFontSize: 16
            },
            axisX: {
              title: "Item Name",
              titleFontSize: 16
            },
            data: [{
              type: "column",
              bevelEnabled: true,
              indexLabel: "{y}",
              dataPoints: dataPoints
            }]
          });
          chart.render();
        }
      } else {
        this.topSaleItemName = false;
      }
    })
  }

  getAllLocation() {
    this.LocationService.getAllLocation().subscribe(res => {
      this.allLocation = res;
    })
  }

  getLocation(){
    console.log(this.txtlocation);
    if(this.txtlocation == "All"){
      this.top10SaleItemName();
    }else{
      // var location = this.txtlocation.locationname
      this.top10SaleByItemName();
    }
  }

  getSaleByType(){
    console.log(this.txtlocation);
    if(this.txtSaleBytype == "All"){
      this.getSumSaleByYearType();
    }else{
      // var location = this.txtlocation.locationname
      this.getSumSaleByYearTypeByLocation();
    }
  }

  credit  = 0;
  cash = 0;
  txtCreditCashYear;
  getCreditCashEnable = true;
  //crdit vs cash
  getSumSaleByYearType() {
    this.getCreditCashEnable = true;
    this.stockservice.getSumSaleByYearType(this.txtCreditCashYear).subscribe(res => {
      if(res.credit != 0 || res.cash != 0){
        this.credit = res.credit;
        this.cash = res.cash;
        var chart = new CanvasJS.Chart("piechart", {
          theme: "light2",
          animationEnabled: true,
          title: {
            // text: "All Sale Details"
          },
          data: [{
            type: "pie",
            startAngle: 90,
            yValueFormatString: "##0.00\"\"",
            indexLabel: "{label} {y}",
            click: explodePie,
            dataPoints: [
              { y: this.cash, label: "CASH" },
              { y: this.credit, label: "CREDIT" },
            ]
          }]
        });
        chart.render();
        //for animation
        function explodePie(e) {
          for (var i = 0; i < e.dataSeries.dataPoints.length; i++) {
            if (i !== e.dataPointIndex)
              e.dataSeries.dataPoints[i].exploded = false;
          }
        }
      }else {
        this.getCreditCashEnable = false;
      }
    })
  };

  // credit;
  // cash;
  //crdit vs cash
  getSumSaleByYearTypeByLocation() {
    this.getCreditCashEnable = true;
    this.stockservice.getSumSaleByYearTypeByLocation(this.txtSaleBytype,this.txtCreditCashYear).subscribe(res => {
      if(res.credit != 0 || res.cash != 0){
        this.credit = res.credit;
        this.cash = res.cash;
        var chart = new CanvasJS.Chart("piechart", {
          theme: "light2",
          animationEnabled: true,
          title: {
            // text: "All Sale Details"
          },
          data: [{
            type: "pie",
            startAngle: 90,
            yValueFormatString: "##0.00\"\"",
            indexLabel: "{label} {y}",
            click: explodePie,
            dataPoints: [
              { y: this.cash, label: "CASH" },
              { y: this.credit, label: "CREDIT" },
            ]
          }]
        });
        chart.render();
        //for animation
        function explodePie(e) {
          for (var i = 0; i < e.dataSeries.dataPoints.length; i++) {
            if (i !== e.dataPointIndex)
              e.dataSeries.dataPoints[i].exploded = false;
          }
        }
      }else {
        this.getCreditCashEnable = false;
      }
    })
  };

  //Account
  topAccount() {
    var dataPoints = [];
    this.stockservice.getSumAccount().subscribe(res => {
      for (var i = 0; i < res.length; i++) {
        dataPoints.push({ label: res[i].name, y: res[i].amount })

        var chart = new CanvasJS.Chart("topaccount", {
          animationEnabled: true,
          // exportEnabled: true,
          // theme: "light2",
          // title: {
          //   text: "Monthly Purchase Data"
          // },
          axisY: {
            title: "Total Sale",
            titleFontSize: 16
          },
          axisX: {
            title: "Item Name",
            titleFontSize: 16
          },
          data: [{
            type: "column",
            bevelEnabled: true,
            indexLabel: "{y}",
            dataPoints: dataPoints
          }]
        });
        chart.render();
      }
    })
  }

  cashSale = [];
  locationchart = [];
  creditSale = [];
  collection = [];
  expense = [];
  barChart;
  allNigamDailyReport;
  loadNigamDailyChart() {
    // code for Bar chart
    this.stockservice.getAllNigamDailyReport(this.txtStartDate,this.txtEndDate).subscribe((res: DailyReportDTO[]) => {
      this.allNigamDailyReport = res;
      console.log(this.allNigamDailyReport);
      // console.log(res);

      res.forEach(y => {
        this.locationchart.push(y.location);
        this.cashSale.push(y.cashSale);
        this.creditSale.push(y.creditSale);
        this.collection.push(y.collection);
        this.expense.push(y.expense);

      })
      this.barChart = new Chart('chartnigam', {
        type: 'bar',
        data: {
          labels: this.locationchart,
          datasets: [{
            label: 'Total Cash Sale',
            data: this.cashSale,
            backgroundColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }, {
            label: 'Total Credit Sale',
            data: this.creditSale,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: '#777',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }, {
            label: 'Total Collection',
            data: this.collection,
            backgroundColor: 'rgba(75, 192, 192, 1)',
            borderColor: '#777',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }, {
            label: 'Total Expense',
            data: this.expense,
            backgroundColor: 'rgba(153, 102, 255, 1)',
            borderColor: '#777',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }]
        },
        options: {
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Daily Report',
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: '#000'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Location',
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: '#000'
              }
            }]
          }
        }
      });
    });
  }
  locationchartBhadar = [];
  cashSaleBhadar = [];
  creditSaleBhadar = [];
  collectionBhadar = [];
  expenseBhadar = [];
  barChartbhandar;
  allBhandarDailyReport;
  loadBhandarDailyChart() {
    // code for Bar chart
    this.stockservice.getAllBhandarDailyReport(this.txtStartDate,this.txtEndDate,this.companyReportId).subscribe((res: DailyReportDTO[]) => {
      this.allBhandarDailyReport = res;
      console.log(this.allBhandarDailyReport);
      // console.log(res);

      res.forEach(y => {
        this.locationchartBhadar.push(y.location);
        this.cashSaleBhadar.push(y.cashSale);
        this.creditSaleBhadar.push(y.creditSale);
        this.collectionBhadar.push(y.collection);
        this.expenseBhadar.push(y.expense);

      })
      this.barChartbhandar = new Chart('chartbhandar', {
        type: 'bar',
        data: {
          labels: this.locationchartBhadar,
          datasets: [{
            label: 'Total Cash Sale',
            data: this.cashSaleBhadar,
            backgroundColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }, {
            label: 'Total Credit Sale',
            data: this.creditSaleBhadar,
            backgroundColor: 'rgba(54, 162, 235, 1)',
            borderColor: '#777',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }, {
            label: 'Total Collection',
            data: this.collectionBhadar,
            backgroundColor: 'rgba(75, 192, 192, 1)',
            borderColor: '#777',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }, {
            label: 'Total Expense',
            data: this.expenseBhadar,
            backgroundColor: 'rgba(153, 102, 255, 1)',
            borderColor: '#777',
            borderWidth: 1,
            hoverBorderWidth: 1,
            hoverBorderColor: "#000"
          }]
        },
        options: {
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Daily Report',
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: '#000'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Location',
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: '#000'
              }
            }]
          }
        }
      });
    });
  }

  allNigamDailySummaryData = [];
  allBhandarDailySummaryData;
  allCollectionData = [];
  allExpenseData;
  getNigamDailySummaryData(NDRstock,type){
    this.allNigamDailySummaryData = [];
    this.stockservice.getNigamDailySummaryData(this.txtStartDate, this.txtEndDate, "1", NDRstock.location, type).subscribe(res => {
      this.allNigamDailySummaryData = res;
      console.log(this.allNigamDailySummaryData);
    });
  }
  getBhandarDailySummaryData(BDRstock,type){
    this.allNigamDailySummaryData = [];
    this.stockservice.getBhandarDailySummaryData(this.txtStartDate, this.txtEndDate, this.companyReportId, BDRstock.location, type).subscribe(res => {
      this.allNigamDailySummaryData = res;
      console.log(this.allNigamDailySummaryData);
    });
  }
  getCollectionData(NDRstock){
    this.allCollectionData = [];
    this.stockservice.getCollectionData(this.txtStartDate,this.txtEndDate,"1",NDRstock.location).subscribe(res => {
      this.allCollectionData = res;
      console.log(this.allCollectionData);
    });
  }
  expenseSummary = [];
  paymentSummary = [];
  getExpenseData(NDRstock){
    this.allExpenseData = "";
    this.expenseSummary = [];
    this. paymentSummary = [];
    this.stockservice.getExpenseData(this.txtStartDate,this.txtEndDate,"1",NDRstock.location).subscribe(res => {
      this.allExpenseData = res;
      this.expenseSummary= this.allExpenseData.expense;
      this.paymentSummary= this.allExpenseData.payment;
      console.log(this.allExpenseData);
    });
  }
  getCollectionBhandarData(BDRstock){
    this.allCollectionData = [];
    this.stockservice.getCollectionData(this.txtStartDate,this.txtEndDate,this.companyReportId,BDRstock.location).subscribe(res => {
      this.allCollectionData = res;
      console.log(this.allCollectionData);
    });
  }
  getExpenseBhandarData(BDRstock){
    this.allExpenseData = "";
    this.expenseSummary = [];
    this. paymentSummary = [];
    this.stockservice.getExpenseData(this.txtStartDate,this.txtEndDate,this.companyReportId,BDRstock.location).subscribe(res => {
      this.allExpenseData = res;
      this.expenseSummary= this.allExpenseData.expense;
      this.paymentSummary= this.allExpenseData.payment;
      console.log(this.allExpenseData);
    });
  }

  allBankBookData;
  txtStartBankBookData;
  getAllBankBookData(){
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtStartBankBookData = (curr_year + "-" + '04' + "-" + '01');
    this.stockservice.getBankBookData(this.txtStartBankBookData,this.txtEndDate).subscribe(res => {
      this.allBankBookData = res;
      console.log(this.allBankBookData);
    });
  }

  allPurchaseData;
  txtStartPurchaseDate;
  txtEndPurchaseDate;
  txtPurchaseFinancialYear;
  getAllPurchaseReportCompanyWise(){
    this.txtStartPurchaseDate = (this.txtPurchaseFinancialYear.split("-")[0] + "-" + '04' + "-" + '01');
    this.txtEndPurchaseDate = (this.txtPurchaseFinancialYear.split("-")[1] + "-" + '03' + "-" + '31');
    this.stockservice.getAllPurchaseReportCompanyWise(this.txtStartPurchaseDate,this.txtEndPurchaseDate).subscribe(res => {
      this.allPurchaseData = res;
    });
  }
  allSaleData;
  txtStartSaleDate;
  txtEndSaleDate;
  txtSaleFinancialYear;
  getAllSaleReportCompanyWise(){
    this.txtStartSaleDate = (this.txtSaleFinancialYear.split("-")[0] + "-" + '04' + "-" + '01');
    this.txtEndSaleDate = (this.txtSaleFinancialYear.split("-")[1] + "-" + '03' + "-" + '31');
    this.stockservice.getAllSaleReportCompanyWise(this.txtStartSaleDate,this.txtEndSaleDate).subscribe(res => {
      this.allSaleData = res;
    });
  }
  allPaymentCreditData;
  txtStartPaymentCreditDate;
  txtEndPaymentCreditDate;
  txtPaymentCreditFinancialYear;
  getAllPaymentCreditReportCompanyWise(){
    this.txtStartPaymentCreditDate = (this.txtPaymentCreditFinancialYear.split("-")[0] + "-" + '04' + "-" + '01');
    this.txtEndPaymentCreditDate = (this.txtPaymentCreditFinancialYear.split("-")[1] + "-" + '03' + "-" + '31');
    this.stockservice.getAllPaymentCreditReportCompanyWise(this.txtStartPaymentCreditDate,this.txtEndPaymentCreditDate).subscribe(res => {
      this.allPaymentCreditData = res;
    });
  }
  allReceiptDebitData;
  txtStartReceiptDebitDate;
  txtEndReceiptDebitDate;
  txtReceiptDebitFinancialYear;
  getAllReceiptDebitReportCompanyWise(){
    this.txtStartReceiptDebitDate = (this.txtReceiptDebitFinancialYear.split("-")[0] + "-" + '04' + "-" + '01');
    this.txtEndReceiptDebitDate = (this.txtReceiptDebitFinancialYear.split("-")[1] + "-" + '03' + "-" + '31');
    this.stockservice.getAllReceiptDebitReportCompanyWise(this.txtStartReceiptDebitDate,this.txtEndReceiptDebitDate).subscribe(res => {
      this.allReceiptDebitData = res;
    });
  }

  getAllYouWillGetDetail() {
    this.allYouWillGet = [];
    var start = (this.youwillGetFinancialyear.split("-")[0] + "-" + '04' + "-" + '01');
    var end = (this.youwillGetFinancialyear.split("-")[1] + "-" + '03' + "-" + '31');
    this.stockservice.getDetailOfYouWillPayOrGet(start,end,"WillGet").subscribe(res => {
      this.allYouWillGet = res;
    });
  }

  getAllYouWillPayDetail() {
    this.allYouWillPay = [];
    var start = (this.youwillPayFinancialyear.split("-")[0] + "-" + '04' + "-" + '01');
    var end = (this.youwillPayFinancialyear.split("-")[1] + "-" + '03' + "-" + '31');
    this.stockservice.getDetailOfYouWillPayOrGet(start,end,"WillPay").subscribe(res => {
      this.allYouWillPay = res;
    });
  }
}
