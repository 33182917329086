<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Profit&Loss</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-2 col-xl-2 col-md-3 col-6 bottom_margin">
                                            <label for="country" class="form-label">Start Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="pickerFrom.open()" matInput [matDatepicker]="pickerFrom"
                                                class="form-control" [(ngModel)]="txtStartDate" name="txtStartDate">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFrom" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pickerFrom></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-md-3 col-6 bottom_margin">
                                            <label for="country" class="form-label">End Date <span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="pickerTO.open()" matInput [matDatepicker]="pickerTO"
                                                class="form-control" [(ngModel)]="txtEndDate" name="txtEndDate">
                                            <mat-datepicker-toggle matSuffix [for]="pickerTO" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pickerTO></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="company" class="form-label">Company Name</label>
                                            <select class="form-select" id="company" [(ngModel)]="txtCompany" name="txtCompany">
                                                <option>All</option>
                                                <option *ngFor="let company of allCompany" [value]="company.companyId">{{company.companyName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="searchStockValution()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Profit & Loss</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-12 d-flex">
                                    <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                        (click)="exportAsXLSX()" style="margin-bottom: 10px;">Export To Excel</button>
                                    <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                        (click)="downloadProfitLossPDF()" style="margin-bottom: 10px;">Download PDF</button>
                                </div>
                                <!-- <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Particulars</th>
                                            <!-- <th scope="col">01-04-2021 To {{txtEndDate}}</th> -->
                                             <th scope="col" class="text-end">Amount (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td style="font-weight: 900;">(A)Income</td><td></td></tr>
                                        <tr><td>Income</td>
                                            <td class="text-end">{{txtIncome | appendIND}}</td>
                                        </tr>
                                        <tr>
                                            <td>Sale</td>
                                            <td class="text-end">{{txtSale | appendIND}}</td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Return</td>
                                            <td class="text-end">{{txtPurchaseReturn | appendIND}}</td>
                                        </tr>
                                        <tr>
                                            <td>Stock</td>
                                            <td class="text-end">{{txtStock | appendIND}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Credit Note</td>
                                            <td class="text-end">{{txtCreditNote | appendIND}}</td>
                                        </tr> -->
                                        <tr><td style="font-weight: 900;">Total(A)</td><td style="font-weight: 900;" class="text-end">{{totalA | appendIND}}</td></tr>
                                        <tr><td style="font-weight: 900;">(b)Expenditure</td><td></td></tr>
                                        <tr>
                                            <td>Expense</td>
                                            <td class="text-end">{{txtExpense | appendIND}}</td>
                                        </tr>
                                        <tr>
                                            <td>Purchase</td>
                                            <td class="text-end">{{txtPurchase | appendIND}}</td>
                                        </tr>
                                        <tr>
                                            <td>Sale Return</td>
                                            <td class="text-end">{{txtSaleReturn | appendIND}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Debit Note</td>
                                            <td class="text-end">{{txtDebitNote | appendIND}}</td>
                                        </tr> -->
                                        <tr><td style="font-weight: 900;">Total(B)</td><td style="font-weight: 900;" class="text-end">{{totalB | appendIND}}</td></tr>
                                        <tr><td style="font-weight: 900;">ProfitLoss</td><td style="font-weight: 900;" class="text-end">{{txtProfitLoss | appendIND}}</td></tr>
                                    </tbody>
                                    <tfoot>
                                        <!-- <tr *ngIf="allProfitLossData.length == 0 ">
                                            <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                        </tr> -->
                                    </tfoot>
                                </table>
                            </div>
                            <!-- <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>