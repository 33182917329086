import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(
    private http: Http,
    private httpClient: HttpClient,
  ) { }

  addTransferDetailSave(txttransferDate: string, txttransferCode: string, txtlocation: string, transferData: Array<any>){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      transferDate: txttransferDate,
      transferNumber: txttransferCode,
      tolocation: txtlocation,
      transferDetailDTOs: transferData,
    });
    return this.http.post('/api/create/transfer', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAlltransfer(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/transfer', { headers })
      .pipe(map(res => res.json()));
  }

  getAlltransferByDate(startDate: string,enddate: string ){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/transfer/date/'+startDate+'/'+ enddate, { headers })
      .pipe(map(res => res.json()));
  }
  
  getTransferNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/transfernumber', { headers })
      .pipe(map(res => res.json()));
  }

  //Notification API
  getAllTransferNotification(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/nortification/transfer', { headers })
      .pipe(map(res => res.json()));
  }
  getAllPlanPurchaseNotification(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/notification/planpurchase', { headers })
      .pipe(map(res => res.json()));
  }
  getAllPurchaseNotification(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/send/purchase/notification', { headers })
      .pipe(map(res => res.json()));
  }

  downloadTransferBill(transferId :string){
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/transfer/bill/` + transferId, { headers: header, responseType: 'blob' });
  }

  whatsappSale(saleReturnid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/transfer/whatsup/'+saleReturnid , { headers })
      .pipe(map(res => res.json()));
  }

  getTransferPDFCategoryNoOfInvoiceData(transferId: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/transfer/pdf/category/noofinvoice/detail/' + transferId,{ headers })
      .pipe(map(res => res.text()));
  }
  // new sale challan PDF by category
  public downloadTransferCategoryWiseBill(invoiceid:string,billnumber: string,companyname:string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/transfer/categorywise/invoice/`+invoiceid+`/`+ billnumber+`/`+companyname, { headers: header, responseType: 'blob' });
  }
}
