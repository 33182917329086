<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Group Wise Ledger Details</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">From <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtFormDate"
                                                name="txtFormDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">To <span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                class="form-control" [(ngModel)]="txtToDate"
                                                name="txtToDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-12">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtcompany"
                                                [(ngModel)]="txtcompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company.companyId">
                                                    {{company.companyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-12">
                                            <label for="country" class="form-label">Balance<span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtBalanceName" [(ngModel)]="txtBalanceName" required="">
                                                <option>-:Select BalanceSheet:-</option>
                                                <option *ngFor="let balance of allBalance"
                                                    [value]="balance.balancesheetId">{{balance.balancesheetName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-3 btn-container-end">
                                            <button class="btn btn-primary me-2 d-block" type="submit"
                                                (click)="GetGroupWiseLedger()">
                                                Search
                                            </button>
                                            <button class="btn btn-primary d-block me-2" type="submit"
                                                (click)="downloadPDF()">Download PDF</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <div class="portlet-header">Group Wise Ledger Details
                            <!-- <span style="float: right;">Total : {{totalNetAmount | appendIND}}</span> -->
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <!-- <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Excel</button> -->
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Group Name</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col" class="text-center">Debit (Rs.)</th>
                                            <th scope="col" class="text-center">Credit (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let grpled of resGroupWiseLedgerData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{grpled.groupName}}</td>
                                            <td>{{grpled.particulars}}</td>
                                            <td class="text-end">{{grpled.debit | appendIND}}</td>
                                            <td class="text-end">{{grpled.credit | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td><b>Current Total</b></td>
                                            <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                            <td class="text-end"><b>{{txtTotalCredit | appendIND}}</b></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><b>Closing Balance</b></td>
                                            <td class="text-end"><b>{{txtClosingBalance | appendIND}}</b></td>
                                            <td></td>
                                            <!-- <td class="text-end">{{txtDebitClosingBalance | appendIND}}</td> -->
                                            <!-- <td class="text-end"><b>{{txtCreditClosingBalance | appendIND}}</b></td> -->
                                        </tr>
                                    </tfoot>
                                    <tfoot>
                                        <tr *ngIf="resGroupWiseLedgerData.length == 0 ">
                                            <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>