import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PlanPriceService } from '../service/plan-price/plan-price.service';
import { PlanService } from '../service/plan/plan.service';
declare var $: any;
@Component({
  selector: 'app-plan-price-detail',
  templateUrl: './plan-price-detail.component.html',
  styleUrls: ['./plan-price-detail.component.css']
})
export class PlanPriceDetailComponent implements OnInit {

  btnName = "Save";
  txtPriceid;
  txtPlan;
  txtPlanDuration;
  txtPrice;
  resPriceDetail;
  allPriceData;
  allPlan;
  search;
  page: number = 1;

  constructor(
    private toastr: ToastrService,
    private priceService: PlanPriceService,
    private planService: PlanService
  ) { }

  ngOnInit(): void {
    this.getAllPlan();
    this.getAllPlanPrice();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearData() {
    this.btnName = "Save";
    this.txtPriceid = null;
    this.txtPlan = null;
    this.txtPlanDuration = null;
    this.txtPrice = null;
  }

  getAllPlan() {
    this.planService.gatAllPlan().subscribe(res => {
      this.allPlan = res;
    })
  }

  getAllPlanPrice() {
    this.priceService.getAllPriceDetail().subscribe(res => {
      this.allPriceData = res;
    })
  }

  createPlanPriceDetail() {
    if (this.txtPlan == null || this.txtPlan == "-:Select Plan Name:-" || this.txtPlan == undefined) {
      this.toastr.error("Plan name is required");
    } else if (this.txtPlanDuration == null || this.txtPlanDuration == '-:Select Plan Duration:-' || this.txtPlanDuration == undefined) {
      this.toastr.error("Plan duration is required");
    } else if (this.txtPrice == 0 || this.txtPrice == null || this.txtPrice == undefined) {
      this.toastr.error("Price is required");
    } else {
      if (this.txtPriceid == null) {
        this.priceService.createPlanPrice(this.txtPlan, this.txtPlanDuration, this.txtPrice).subscribe(res => {
          this.resPriceDetail = res;
          this.clearData();
          this.getAllPlanPrice();
          this.toastr.success("price detail save successfully");
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      } else {
        this.priceService.updatePlanPrice(this.txtPriceid, this.txtPlan, this.txtPlanDuration, this.txtPrice).subscribe(res => {
          this.resPriceDetail = res;
          this.clearData();
          this.getAllPlanPrice();
          this.toastr.success("price detail updated successfully");
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatePlanPrice(pp) {
    this.btnName = "Update";
    this.txtPriceid = pp.planpriceId;
    this.txtPlan = pp.plan.planName;
    this.txtPlanDuration = pp.planDays;
    this.txtPrice = pp.planPrice;
  }

  deleteid;
  deleteData(pp) {
    this.deleteid = pp.planpriceId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.priceService.deletPlanPrice(this.deleteid).subscribe(res => {
        this.clearData();
        this.getAllPlanPrice();
        $('#deleteModal').modal('hide');
        $('.modal-backdrop').remove();
        this.toastr.success('Price deleted successfully');
      }, error => {
        this.toastr.error('Price not deleted');
      })
    }
  }
}
