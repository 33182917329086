import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PurchasePlanService {

  constructor(
    private http: Http,
  ) { }
  createPurchasePlan(txtPlanName: string,txtOwnerName: string,txtOwnerMobileNumber: string,txtContactPerson: string,txtContactPersonMobileNumber: string,txtAddress: string,txtMailId:string,txtGstNumber:string,txtOrganizationName: string, txtExtraUser: string, txtExtraLocation: string, txtBankName: string, txtChequeNo: string, txtFinalAmount: number, optradio: string, txtPlanDays :string,txtState: string, txtStateCode: string,txtLedgerView: string,txtMarketingView: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token", token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planName: txtPlanName,
      ownerName: txtOwnerName,
      ownerMobileNumber: txtOwnerMobileNumber,
      contactPerson: txtContactPerson,
      contactPersonMobile: txtContactPersonMobileNumber,
      address: txtAddress,
      email : txtMailId,
      gstNumber : txtGstNumber,
      organizationName : txtOrganizationName,
      type: "Offline",
      // activeDate: txtActiveDate,
      // endDate: txtEndDate,
      extraUser: txtExtraUser,
      extraLocation: txtExtraLocation,
      bankName: txtBankName,
      chequeNo: txtChequeNo,
      finalAmount: txtFinalAmount,
      paymentMode: optradio,
      planpriceId : txtPlanDays,
      state : txtState,
      stateCode:txtStateCode,
      ledger: txtLedgerView,
      marketing:txtMarketingView
    });
    return this.http.post('/api/create/planpurchase', body, { headers })
      .pipe(map(res => res.json()));
  }

  updatePurchasePlan(txtPlanId: string, txtPlanName: string,txtOwnerName: string,txtOwnerMobileNumber: string,txtContactPerson: string,txtContactPersonMobileNumber: string,txtAddress: string,txtMailId:string,txtGstNumber:string, txtOrganizationName: string, txtExtraUser: string, txtExtraLocation: string, txtBankName: string, txtChequeNo: string, txtFinalAmount: number, optradio: string,txtPlanDays:string,txtState: string, txtStateCode: string,txtLedgerView: string,txtMarketingView: string,txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpurchaseId: txtPlanId,
      planName: txtPlanName,
      ownerName: txtOwnerName,
      ownerMobileNumber: txtOwnerMobileNumber,
      contactPerson: txtContactPerson,
      contactPersonMobile: txtContactPersonMobileNumber,
      address : txtAddress,
      email : txtMailId,
      gstNumber : txtGstNumber,
      organizationName : txtOrganizationName,
      // activeDate: txtActiveDate,
      // endDate: txtEndDate,
      extraUser: txtExtraUser,
      extraLocation: txtExtraLocation,
      bankName: txtBankName,
      chequeNo: txtChequeNo,
      finalAmount: txtFinalAmount,
      paymentMode: optradio,
      paymentRecived : true,
      planpriceId : txtPlanDays,
      type: "Offline",
      state : txtState,
      stateCode:txtStateCode,
      ledger: txtLedgerView,
      marketing:txtMarketingView,
      image: txtImage
    });
    return this.http.put('/api/update/planpurchase', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllPurchasePlan(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/planpurchase', { headers })
      .pipe(map(res => res.json()));
  }

  deletePurchasePlan(txtPlanId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      planpurchaseId: txtPlanId,
    });
    return this.http.put('/api/delete/planpurchase', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllAdditionalPlan(planid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/additionalplandata/' + planid, { headers })
      .pipe(map(res => res.json()));
  }

  getGSTNumber(txtGstNumber: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      gstNumber: txtGstNumber,
    });
    return this.http.post('/api/get/planpurchase/gstnumber', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/planpurchase/company/image', data, { headers: headers })
      .pipe(map(res => res));
  }

}
