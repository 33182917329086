import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MediaMarketingService } from '../service/media-marketing/media-marketing.service';
import { ThemePalette } from '@angular/material/core';
declare var $: any;

@Component({
  selector: 'app-media-marketing',
  templateUrl: './media-marketing.component.html',
  styleUrls: ['./media-marketing.component.css']
})
export class MediaMarketingComponent implements OnInit {

  btnName = "Save";
  txtMstId;
  txtDescription;
  txtMediaType = "Image";
  search;
  page: number = 1;
  allMediaDetail = [];
  txtImage;
  selectedFile = [];
  myfiles = [];
  isUpdate = false;
  resSavedDetail;
  color: ThemePalette = 'primary';
  constructor(
    private toastr: ToastrService,
    private mediaMarketingService: MediaMarketingService
  ) { }

  ngOnInit(): void {
    this.getAllMediaMaster();
  }

  clearData() {
    this.btnName = "Save";
    this.txtMstId = null;
    this.txtDescription = null;
    this.txtMediaType = "Image";
    this.txtImage = null;
    this.selectedFile = [];
    this.myfiles = [];
    this.isUpdate = false;
  }

  getAllMediaMaster() {
    this.mediaMarketingService.getMediaMarketing().subscribe(res => {
      this.allMediaDetail = res;
    }, error => {
      this.allMediaDetail = [];
    })
  }

  createMediaMarketing() {
    if (this.txtDescription == null || this.txtDescription == undefined || this.txtDescription.trim() == '') {
      this.toastr.error("Description is required");
    } else if (this.isUpdate == false && (this.txtMediaType == null || this.txtMediaType == undefined)) {
      this.toastr.error("Media Type is required");
    } else if (this.isUpdate == false && (this.selectedFile.length == 0 || this.myfiles.length == 0)) {
      this.toastr.error("Media detail is required");
    } else {
      if (this.txtMstId == null) {
        this.mediaMarketingService.createMediaMarketing(this.txtDescription).subscribe(res => {
          this.resSavedDetail = res;
          this.uploadMedia();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      } else {
        this.mediaMarketingService.updateMediaMarketing(this.txtMstId, this.txtDescription).subscribe(res => {
          this.clearData();
          this.toastr.success("Detail updated successfully");
          this.getAllMediaMaster();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateMediaMaster(detail) {
    this.btnName = "Update";
    this.isUpdate = true;
    this.txtMstId = detail.masterid;
    this.txtDescription = detail.description;
  }

  onImageUpload(event) {
    this.myfiles = [];
    this.selectedFile = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.myfiles.push(event.target.files[i])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // console.log(event.target.result);
        this.selectedFile.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
    }
    // console.log(this.myfiles)
  }

  uploadMedia() {
    if (this.myfiles == undefined || this.myfiles == null || this.myfiles.length == 0) {
      this.toastr.error("please select media detail");
    } else {
      const data1 = new FormData();
      for (let i = 0; i < this.myfiles.length; i++) {
        data1.append('file', this.myfiles[i], this.myfiles[i].name);
        data1.append('mstid', this.resSavedDetail.masterid);
        data1.append('mediatype', this.txtMediaType);
      }
      this.mediaMarketingService.uploadMedia(data1).subscribe(res => {
        this.clearData();
        this.toastr.success("Detail saved successfully");
        this.getAllMediaMaster();
      }, error => {
        this.toastr.error("Media not uploaded")
      })
    }
  }

  removeSelectedFile(index) {
    this.selectedFile.splice(index, 1);
    this.myfiles.splice(index, 1);
    if ((this.selectedFile.length == 0 && this.myfiles.length == 0) || (this.selectedFile.length == 0 && this.myfiles.length == 0)) {
      // this.clearData();
      this.txtImage = '';
    }
  }

  deleteDetail;
  deleteData(detail) {
    this.deleteDetail = detail;
  }

  confirmDelete() {
    this.mediaMarketingService.deleteMediaMarketing(this.deleteDetail.masterid).subscribe(res => {
      this.clearData();
      this.toastr.success("Detail deleted successfully");
      this.getAllMediaMaster();
      $('#deleteModal').modal('hide');
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  checkEnableDisableMarketing(dtl) {
    if (dtl.isview == true) {
      this.mediaMarketingService.onoffMediaMarketingView(dtl.masterid, "Enable").subscribe(res => {
        this.toastr.success('Media detail enable successfully');
        this.getAllMediaMaster();
      }, error => {
        // this.toastr.error('User not active');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
        dtl.isview = false;
      });
    } else {
      this.mediaMarketingService.onoffMediaMarketingView(dtl.masterid, "Disable").subscribe(res => {
        this.toastr.success('Media detail disable successfully');
        this.getAllMediaMaster();
      }, error => {
        // this.toastr.error('User not active');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
        dtl.isview = false;
      });
    }
  }

  allSubDetail = [];
  mediaUpdateMstDtl;
  viewMediaDetail(dtl) {
    this.mediaUpdateMstDtl = dtl;
    this.allSubDetail = dtl.marketingDetails;
  }

  dltMediaId;
  deleteImagesAndVideos(dtl) {
    this.dltMediaId = dtl.
      $('#viewMediaDetail').modal('hide');
    $('#deleteImageVideoModal').modal('show');
  }

  confirmImageDelete() {

  }
}
