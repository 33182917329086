import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PurchaseReturnService } from "../service/purchase-return/purchase-return.service";
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-purchase-return-record',
  templateUrl: './purchase-return-record.component.html',
  styleUrls: ['./purchase-return-record.component.css']
})
export class PurchaseReturnRecordComponent implements OnInit {
  allPurchaseReturnDetail;
  allPurchaseReturn;
  search;
  page: number = 1;
  txtFormDate;
  txtToDate;
  datePipe = new DatePipe("en-US");

  constructor(
    private purchasereturnservice: PurchaseReturnService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.getAllPurchaseReturn();
    this.getAllPurchaseReturnByDate();
  }

  getAllPurchaseReturn() {
    this.purchasereturnservice.getAllPurchaseReturn().subscribe(res => {
      this.allPurchaseReturn = res;
      // console.log(this.allPurchaseReturn);
    })
  }
  
  userRole;
  getAllPurchaseReturnByDate() {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.purchasereturnservice.getAllPurchaseReturnByDate(this.txtFormDate,this.txtToDate).subscribe(res => {
      this.allPurchaseReturn = res.purchasereturn;
      this.userRole = res.userrole;
    })
  }

  getPurchaseReturnDetail(purchaseReturn) {
    this.allPurchaseReturnDetail = purchaseReturn.purchaseReturnDetails;
    // console.log(this.allPurchaseReturnDetail);
  }

  purchaseReturnDeleteId;
  resDelPurchaseReturn;
  deletePurchaseReturn(purchaseReturn){
    this.purchaseReturnDeleteId = purchaseReturn.purchasereturnid;
  }
  confirmDelete(){
    if (this.purchaseReturnDeleteId != null) {
      this.purchasereturnservice.deletePurchaseReturn(this.purchaseReturnDeleteId).subscribe(res => {
        this.resDelPurchaseReturn = res;
        this.getAllPurchaseReturnByDate();
        $('#deleteModal').modal('hide');
        this.toastr.success('Purchase return deleted successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  purchasereturnNumber;
  pdfSaleReturnId;
  allPDFCompany;
  getcompnayOrganizationPDF(purchaseReturn) {
    this.purchasereturnNumber = purchaseReturn.purchasereturnNumber;
    this.pdfSaleReturnId = purchaseReturn.purchasereturnid;
    this.purchasereturnservice.getCompanyOganizationPDFData(purchaseReturn.purchasereturnid).subscribe(res => {
      console.log(res);
      this.allPDFCompany = res;
    },error=>{
      this.allPDFCompany = null;
    })
  }

  openPdfResponseBhandar;
  resBillPdfBhandar;
  downloadPdfByCompany(company,noofCompany) {
    this.purchasereturnservice.downloadPurchaseReturnBhandarBill(this.purchasereturnNumber,company.companyName,noofCompany).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "PurchaseReturn_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  // new purchase return report by category
  allPDFCategory;
  totalNoOfInvoice = 0;
  getPurchaseReturnPDFCategoryNoOfInvoiceData(sale) {
    this.purchasereturnNumber = sale.purchasereturnNumber;
    this.purchasereturnservice.getPurchaseReturnPDFCategoryNoOfInvoiceData(sale.purchasereturnid).subscribe(res => {
      // console.log(res);
      this.allPDFCategory = JSON.parse(res);
      // console.log(JSON.parse(res));
      // this.allPDFCategory = res;
      this.totalNoOfInvoice = 0;
      for (let comp of this.allPDFCategory) {
        this.totalNoOfInvoice = this.totalNoOfInvoice + comp.invoiceDetail.length;
      }
      // console.log("Total NoOfInvoice For Discount: "+this.totalNoOfInvoice);
    },error => {
      this.allPDFCategory = null;
    })
  }
  
  downloadNewPDF(company) {
    if("PESTICIDE" == company.staticinvoicename){
      this.downloadPurchaseReturnPesticideBill(company);
    } else if("FERTILISER" == company.staticinvoicename){
      this.downloadPurchaseReturnFertiliserBill(company);
    } else if("SEEDS" == company.staticinvoicename){
      this.downloadPurchaseReturnSeedsBill(company);
    }
  }
  openPdfResponsePesticide;
  resBillPdfPesticide;
  openPdfResponseFertiliser;
  resBillPdfFertiliser;
  openPdfResponseSeeds;
  resBillPdfSeeds;
  downloadPurchaseReturnPesticideBill(company) {
    this.purchasereturnservice.downloadPurchaseReturnPesticideBill(company.invoiceid,this.purchasereturnNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfPesticide = data;

      var newBlob1 = new Blob([this.resBillPdfPesticide], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponsePesticide.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfPesticide = '';
      this.openPdfResponsePesticide = '';
    })
  }
  downloadPurchaseReturnFertiliserBill(company) {
    this.purchasereturnservice.downloadPurchaseReturnFertiliserBill(company.invoiceid,this.purchasereturnNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfFertiliser = data;

      var newBlob1 = new Blob([this.resBillPdfFertiliser], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseFertiliser.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfFertiliser = '';
      this.openPdfResponseFertiliser = '';
    })
  }
  downloadPurchaseReturnSeedsBill(company) {
    this.purchasereturnservice.downloadPurchaseReturnSeedsBill(company.invoiceid,this.purchasereturnNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfSeeds = data;

      var newBlob1 = new Blob([this.resBillPdfSeeds], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseSeeds.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfSeeds = '';
      this.openPdfResponseSeeds = '';
    })
  }

}
