<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Item Master</h2> -->

                <div class="row">
                    <div class="col-lg-12 col-md-12 mb-4" *ngIf="!isAdvertisementData">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Advertisement</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-lg-3 col-xl-3 col-md-3 col-12">
                                            <label for="condition" class="form-label">Advertisement Message<span
                                                style="color: red;">&nbsp;</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtAdvertisementId"
                                            name="txtAdvertisementId">
                                            <textarea class="form-control" rows="2" name="txtAdvertisement"
                                                [(ngModel)]="txtAdvertisement"></textarea>
                                            <div class="invalid-feedback">
                                                Please enter valid advertisement.
                                            </div>
                                        </div>
                                        <!-- <div class="row g-3 mb-3"> -->
                                            <div class="col-lg-3 col-xl-3 col-md-3 col-sm-8 col-9">
                                                <div class="form-group">
                                                    <label for="formFile" class="form-label">Image<span
                                                        style="color: red;">&nbsp;</span></label>
                                                    <input type="file" class="form-control" id="formFile"
                                                        name="txtImage" [(ngModel)]="txtImage" accept="image/*"
                                                        (change)="onImageUpload($event)">
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-2 col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                                <img [src]="imagePreview" height="50px" width="50px">
                                            </div>
                                        <!-- </div> -->
                                        <div class="col-auto col-lg-1 btn-container-end">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createAdvertisement()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <div class="portlet-header">Advertisement Details</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-4">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" placeholder="Search" name="search"
                                                [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Text</th>
                                                <th scope="col">Image</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let adv of allAdvertisement | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{adv.text}}</td>
                                                <!-- <td>{{adv.bankName}}</td> -->
                                                <td class="text-center"><img [src]="adv.image" height="50px" width="50px">
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                        (click)="updateAdvertisement(adv)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary btn-sm" title="Whatsapp Message"
                                                        (click)="whatsappAdvertisement(adv)">
                                                        <i class="fab fa-whatsapp"></i>
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-secondary btn-sm me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deletegeneral(general)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allAdvertisement < 1">
                                                <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event"
                                                    (pageBoundsCorrection)="page=($event)" responsive="true">
                                                </pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are You Sure Want To Delete This Record ?
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button> -->
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>