<div class="body-container">
  <div class="content-holder">
    <app-navbar></app-navbar>
    <div class="content">
      <app-top-header></app-top-header>
      <div class="main-content">
        <!-- <h2>Item Master</h2> -->

        <div class="row">
          <div class="col-lg-12 col-md-12 mb-4">
            <div class="portlet">
              <form class="needs-validation" novalidate="">
                <div class="portlet-header">Item Master</div>
                <div class="portlet-content">
                  <div class="row g-3">
                    <div class="col-lg-5 col-xl-4 col-12">
                      <label for="first" class="form-label">Item Name<span style="color: red;">&nbsp;*</span></label>
                      <input type="hidden" class="form-control" [(ngModel)]="txtitemNameid" name="txtitemNameid">
                      <input type="text" class="form-control" id="itemName" placeholder="Enter item name"
                        required="required" [(ngModel)]="txtitemName" name="txtitemName" (focusout)="toUpperItem()"/>
                      <div class="invalid-feedback">
                        Please enter item name.
                      </div>
                    </div>
                    <!-- <div class="col-3">
                      <label for="last" class="form-label">Item Code<span style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" id="last" placeholder="Enter item code"
                        required="required" [(ngModel)]="txtitemCode" name="txtitemCode" />
                      <div class="invalid-feedback">
                        Please enter item code.
                      </div>
                    </div> -->
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="country" class="form-label">Brand<span style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtbrand" [(ngModel)]="txtbrand" required="">
                        <option>-:Select Brand Name:-</option>
                        <option *ngFor="let brand of allBrand" [value]="brand.name">{{brand.name}}</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid brand Name.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="country" class="form-label">Category<span style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtcategory"
                        (focusout)="getSubCategoryByCategory(txtcategory)" [(ngModel)]="txtcategory" required="">
                        <option>-:Select Category Name:-</option>
                        <option *ngFor="let category of allCategory" [value]="category.categoryName">
                          {{category.categoryName}}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid category name.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-4 col-6">
                      <label for="country" class="form-label">Sub Category<span
                          style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtsubCategory" [(ngModel)]="txtsubCategory"
                        required="">
                        <option>-:Select Sub Category Name:-</option>
                        <option *ngFor="let subcategory of allSubCategoryByCategory" [value]="subcategory.subCategoryName">
                          {{subcategory.subCategoryName}}</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid subCategory name.
                      </div>
                    </div>
                    <div class="col-lg-2 col-xl-2 col-6">
                      <label for="country" class="form-label">Unit<span style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtunit" [(ngModel)]="txtunit" required="">
                        <option>-:Select Unit Name:-</option>
                        <option *ngFor="let unit of allUnit" [value]="unit.unitName">{{unit.unitName}}</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid unit name.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="country" class="form-label">GST<span style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtgst" [(ngModel)]="txtgst" required="">
                        <option>-:Select GST:-</option>
                        <option *ngFor="let gst of allGst" [value]="gst.gstName">{{gst.gstName}}</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid gst name.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="country" class="form-label">HSN Code<span style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" id="itemName" placeholder="Enter HSN Code"
                        required="required" [(ngModel)]="txtHSNCode" name="txtHSNCode"/>
                      <div class="invalid-feedback">
                        Please enter valid HSN Code.
                      </div>
                    </div>
                    <div class="col-auto col-lg-1 btn-container-end">
                      <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                        Cancel
                      </button>
                      <button class="btn btn-primary" type="submit" (click)="createItemsDetail()">
                        {{btnName}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-4">
            <div class="portlet">
              <div class="portlet-header">Item Details</div>
              <div class="portlet-content">
                <div class="row d-flex justify-content-end">
                  <div class="col-md-8 d-flex">
                    <div class="row">
                    <div class="col-12 col-xl-6 bottom_margin">
                    <input type="file" name="txtExcelItem" [(ngModel)]="txtExcelItem" 
                     (change)="onExcelUploadItem($event)" accept=".xls,.xlsx"/>
                    </div>
                    <div class="col-5 col-xl-3">
                    <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                        (click)="exportExcelItemINIT()">Export&nbsp;To&nbsp;Excel</button>
                    </div>
                    <div class="col-5 col-xl-3">
                    <button class="btn btn-primary d-block bottom_margin" type="submit"
                        (click)="importandConvertExcelItem()">Import&nbsp;Excel</button>
                    </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-group search-box">
                      <input class="form-control border-0" placeholder="Search" name="search" [(ngModel)]="search"
                        id="example-search-input" />
                      <span class="input-group-append">
                        <button class="btn btn-outline-secondary border-0" type="button">
                          <i class="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mt-3 table-responsive">
                  <table class="table table-hover table-striped table-bordered">
                    <thead class="tblheadercolor">
                      <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col">Item Name</th>
                        <!-- <th scope="col">Item Code</th> -->
                        <th scope="col">Brand</th>
                        <th scope="col">Category</th>
                        <th scope="col">Sub Category</th>
                        <th scope="col">Unit</th>
                        <th scope="col">GST</th>
                        <th scope="col">HSN Code</th>
                        <th scope="col" style="min-width: 100px;">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let item of allItem | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                        <!-- <th scope="row">1</th> -->
                        <td>{{item.itemName}}</td>
                        <!-- <td>{{item.itemCode}}</td> -->
                        <td>{{item.brand.name}}</td>
                        <td>{{item.category.categoryName}}</td>
                        <td>{{item.subCategory.subCategoryName}}</td>
                        <td>{{item.unit.unitName}}</td>
                        <td>{{item.gst.gstName}}</td>
                        <td>{{item.hsnCode}}</td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Edit" (click)="updateitem(item)">
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                          <button type="button" class="btn btn-secondary btn-sm me-2" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Delete" (click)="deleteitem(item)" data-bs-toggle="modal"
                            data-bs-target="#deleteModal">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr *ngIf="allItem < 1">
                        <td colspan="8" class="text-center"><b>No Record Found</b></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="mt-3 d-flex justify-content-end">
                  <nav aria-label="Table pagination">
                    <ul class="pagination">
                      <li>
                        <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)" responsive="true">
                        </pagination-controls>
                      </li>
                      <!-- <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">2</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li> -->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure want to delete this record ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>