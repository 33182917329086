import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JvService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }
  
  createJVVoucher(txtVoucherDate: string, txtcompany: Number,txtAmount: string,txtNarration:string,txtpartyName: string, txtpartyCreditName: string,txtLedger : string,txtLedgerCredit : string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      date: txtVoucherDate,
      companyId: txtcompany,
      amount: txtAmount,
      remarks: txtNarration,
      // ledgerdebit: txtLedgerName,
      ledgerdebitparty: txtpartyName,
      // ledgercredit: txtLedgerCreditName,
      ledgercreditparty: txtpartyCreditName,
      ledgerdebit : txtLedger,
      ledgercredit : txtLedgerCredit,
    });
    return this.http.post('/api/create/journalvoucher', body, { headers: headers })
      .pipe(map(res => res));
  }

  getAllJV(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/journalvoucher', { headers })
      .pipe(map(res => res.json()));
  }

  getAllJVById(txtId){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/journalvoucher/' + txtId , { headers })
      .pipe(map(res => res.json()));
  }
}
