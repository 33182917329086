import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ExpenseLedgerService {

  constructor(
    private http: Http,
    private httpClient: HttpClient,
  ) { }

  // getAllExpenseLedger(groupid: string) {
  //   const headers = new Headers();
  //   var token = localStorage.getItem('Token');
  //   headers.append('Authorization', token);
  //   headers.append('Content-Type', 'Application/Json');

  //   return this.http.get('/api/getall/ledger/assets/' + groupid, { headers })
  //     .pipe(map(res => res.json()));
  // }

  getAllExpenseLedger() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/ledger/expence', { headers })
      .pipe(map(res => res.json()));
  }

  getLedgerDetail(ledgerid: string, startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/expensesvoucher/startdate/' + startdate + '/enddate/' + enddate + '/ledger/' + ledgerid, { headers })
      .pipe(map(res => res.json()));
  }

  getAllLedgerDetail(startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/expensesvoucher/startdate/' + startdate + '/enddate/' + enddate , { headers })
      .pipe(map(res => res.json()));
  }

  searchSubCategoryData() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/subcategoryreport', { headers })
      .pipe(map(res => res.json()));
  }

  downloadSubCategoryPDF(subcategoryid :string,txtFormDate: string,txtToDate: string,location: string){
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/subcategory/item/report/` + subcategoryid + '/date/' + txtFormDate + '/' + txtToDate + '/location/' + location, { headers: header, responseType: 'blob' });
  }

  searchSubCategoryItemData(txtSubCategory: string, txtType: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      subcategoryId: txtSubCategory,
      type: txtType,
    });

    return this.http.put('/api/create/subcategory/list/itemwise', body, { headers: headers })
      .pipe(map(res => res.json()));
  }
}
