import { Component, OnInit } from '@angular/core';
import { PartyService } from '../service/party/party.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LedgerSearchStockDTO } from '../service/excel/LedgerSearchStockDTO';
import { ExcelService } from '../service/excel/excel.service';
import { BankBookService } from '../service/bank-book/bank-book.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';

@Component({
  selector: 'app-bank-book',
  templateUrl: './bank-book.component.html',
  styleUrls: ['./bank-book.component.css']
})
export class BankBookComponent implements OnInit {

  allBank;
  allCompany;
  txtFormDate;
  txtToDate;
  // txtLedger;
  allParty;
  // partyId;
  reSearchData;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  search;
  page = 1;
  txtTotalCredit = 0;
  txtTotalDebit = 0;
  txtTotalBalance;
  bankOpeningBlance;

  receiptData = [];
  txtCreditOpeningBalance = 0;
  txtDebitOpeningBalance = 0;
  txtCreditClosingBalance = 0;
  txtDebitClosingBalance = 0;
  txtTotalDifferent = 0;
  cashLedgerId = "7";
  txtcompany;
  txtselectedBank;
  constructor(
    private partyService: PartyService,
    private bankBookService: BankBookService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
    private receiptvoucherservice: ReceiptVoucherService
  ) { }

  ngOnInit(): void {
    this.txtFormDate = new Date();
    this.txtToDate = new Date();
    this.getAllCompany();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
    })
  }

  getAllBank() {
    this.partyService.getAllBank(this.txtcompany).subscribe(res => {
      this.allBank = res;
    })
  }

  // bankOpeningBalance(){
  //   this.bankBookService.getBankOpeningBalance(this.txtselectedBank.bankid).subscribe(res => {
  //     this.bankOpeningBlance = res;
  //     this.txtDebitOpeningBalance = this.bankOpeningBlance.openingBalance;
  //   })
  // }

  bankOpeningBlanceByMonth;
  previousdayDate;
  txtStartDate;
  getBankOpeningBalanceByMonth(){
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name required");
    } else if (this.txtselectedBank == "" || this.txtselectedBank == null || this.txtselectedBank == undefined || this.txtselectedBank == "-:Select Bank Name:-") {
      this.toastr.error("Bank name required");
    } else {
      this.txtStartDate = "2021-04-01";
      // var startDate = new Date();
      // var curr_year = startDate.getFullYear();
      // var curr_month = startDate.getMonth() + 1;
      // if (curr_month < 4) {
      //   curr_year = startDate.getFullYear()-1;
      // } else {
      //   curr_year = startDate.getFullYear();
      // }
      // this.txtStartDate = (curr_year + "-" + '04' + "-" + '01');
      this.previousdayDate = new Date(this.txtFormDate);
      // this.txtToDate = new Date(this.txtToDate.setDate(this.txtToDate.getDate() - 1));
      this.previousdayDate.setDate(this.previousdayDate.getDate() - 1);
      //  console.log(this.txtStartDate);
      console.log(this.previousdayDate);
      // this.txtStartDate = this.datePipe.transform(this.txtStartDate, 'yyyy-MM-dd');
      this.previousdayDate = this.datePipe.transform(this.previousdayDate, 'yyyy-MM-dd');
      // if(this.txtStartDate>this.previousdayDate){
      //   console.log('---txtStartDate is greater----');
      //   this.txtStartDate = new Date(this.txtStartDate);
      //   this.previousdayDate = new Date(this.previousdayDate);
      //   console.log('startDate local' +this.txtStartDate);
      //   var year;
      //   if (this.previousdayDate.getMonth() < 4) {
      //     year = this.previousdayDate.getFullYear()-1;
      //   } else {
      //     year = this.previousdayDate.getFullYear();
      //   }
      //   this.txtStartDate.setFullYear(year);
      //   // this.txtStartDate.setFullYear(this.txtStartDate.getFullYear() - 1);
      //   // this.txtStartDate.setDate(this.txtStartDate.getDate() - 365);
      //   console.log('startDate year change' +this.txtStartDate);
      //   this.txtStartDate = this.datePipe.transform(this.txtStartDate, 'yyyy-MM-dd');
      //   this.previousdayDate = this.datePipe.transform(this.previousdayDate, 'yyyy-MM-dd');
      // }else{
      //   console.log('---previousdayDate is greater-----');
      //   this.txtStartDate;
      // }
      this.bankBookService.getBankOpeningBalanceByMonth(this.txtStartDate,this.previousdayDate,this.txtselectedBank.bankid).subscribe(res => {
        this.bankOpeningBlanceByMonth = res;
        this.txtDebitOpeningBalance = res.closingBalance;
        this.searchBankBookDetailByStartEndDate();
      })
    }
  }

  txtVoucherTotalCredit = 0;
  txtVoucherTotalDebit = 0;
  allReceiptById;
  getvoucherDetail(receipt) {
    this.getVoucherById(receipt.vouchermasterid)
    // this.txtDate = receipt.voucherDate;
    // console.log("Receipt Date",receipt);
  }
  getVoucherById(txtId) {
    this.receiptvoucherservice.getAllReceiptById(txtId).subscribe(res => {
      this.allReceiptById = res;
      // console.log(this.allReceiptById);
      this.txtVoucherTotalCredit = 0;
      this.txtVoucherTotalDebit = 0;
      for (let receiptData of this.allReceiptById) {
        this.txtVoucherTotalDebit = this.txtVoucherTotalDebit + receiptData.debit;
        this.txtVoucherTotalCredit = this.txtVoucherTotalCredit + receiptData.credit;
      }
    })
  }

  reSearchDataLength;
  searchBankBookDetailByStartEndDate() {
    this.totalNetAmount = 0;
    this.txtTotalCredit = 0;
    this.txtTotalDebit = 0;
    this.txtTotalBalance = 0;
    this.txtCreditClosingBalance = 0;
    this.txtDebitClosingBalance = 0;
    this.txtTotalDifferent = 0;
    this.receiptData = [];
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name required");
    } else if (this.txtselectedBank == "" || this.txtselectedBank == null || this.txtselectedBank == undefined || this.txtselectedBank == "-:Select Bank Name:-") {
      this.toastr.error("Bank name required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      // this.bankOpeningBalance();
      // this.getBankOpeningBalanceByMonth();
      this.bankBookService.getBankBookDetail(this.cashLedgerId, this.txtcompany, this.txtselectedBank.bankid, this.txtFormDate, this.txtToDate).subscribe(res => {
        this.reSearchData = res;
        this.reSearchDataLength = res.length;
        // console.log(res);

        // this.isEnable = true;
        if (this.reSearchData.length == 0) {
          this.receiptData = [];
          this.txtCreditClosingBalance = this.txtCreditOpeningBalance;
          this.txtDebitClosingBalance = this.txtDebitOpeningBalance;
          this.reSearchData.push("totalbalance", "0")
        } else {
          this.receiptData = [];        
          this.txtTotalBalance = this.txtDebitOpeningBalance;
          for (let ii = 0; ii < this.reSearchData.length; ii++) {
            this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
            this.txtTotalCredit = this.txtTotalCredit + this.reSearchData[ii].credit;
            if (this.reSearchData[ii].debit != 0 && this.reSearchData[ii].credit == 0) {
              this.txtTotalBalance = this.txtTotalBalance + this.reSearchData[ii].debit;
              // console.log("particular" + ii);
              this.receiptData.push({
                "voucherDate": this.reSearchData[ii].voucherdate, "detail": this.reSearchData[ii].detail, "debit": this.reSearchData[ii].debit
                , "credit": this.reSearchData[ii].credit, "txtTotalBalance": this.txtTotalBalance,"vouchermasterid":this.reSearchData[ii].vouchermasterid,"customername": this.reSearchData[ii].customername
              })

            } else if (this.reSearchData[ii].credit != 0 && this.reSearchData[ii].debit == 0) {
              this.txtTotalBalance = this.txtTotalBalance - this.reSearchData[ii].credit;
              this.receiptData.push({
                "voucherDate": this.reSearchData[ii].voucherdate, "detail": this.reSearchData[ii].detail, "debit": this.reSearchData[ii].debit
                , "credit": this.reSearchData[ii].credit, "txtTotalBalance": this.txtTotalBalance,"vouchermasterid":this.reSearchData[ii].vouchermasterid,"customername": this.reSearchData[ii].customername
              })
            }
          }
          this.txtTotalDifferent = Math.abs(this.txtTotalDebit - this.txtTotalCredit);
          // console.log("diff",this.txtTotalDifferent);
          // console.log("total debit",this.txtTotalDebit);
          // console.log("total credit",this.txtTotalCredit);
          if (this.txtTotalDebit > this.txtTotalCredit) {
            if (this.txtTotalDifferent < this.txtCreditOpeningBalance) {
              this.txtCreditClosingBalance = this.txtCreditOpeningBalance - this.txtTotalDifferent - this.txtDebitOpeningBalance;
            } else {
              this.txtDebitClosingBalance = this.txtTotalDifferent + this.txtDebitOpeningBalance - this.txtCreditOpeningBalance;
            }
          } else {
            if (this.txtTotalDifferent < this.txtDebitOpeningBalance) {
              this.txtDebitClosingBalance = this.txtDebitOpeningBalance - this.txtTotalDifferent - this.txtCreditOpeningBalance;
            } else {
              this.txtCreditClosingBalance = this.txtTotalDifferent + this.txtCreditOpeningBalance - this.txtDebitOpeningBalance;
            }
          }
        }
        if(this.receiptData.length == 0 || this.receiptData == null || this.receiptData == undefined){
          this.toastr.error("No data found.");
          this.isEnable = false;
        } else {
          this.isEnable = true;
        }
        // console.log(this.receiptData);
        // console.log(this.reSearchData);
      }, error => {
         this.isEnable = true;
        this.txtCreditClosingBalance = this.txtCreditOpeningBalance;
        this.txtDebitClosingBalance = this.txtDebitOpeningBalance;
        // let message = JSON.parse(error._body).message;
        // this.toastr.error(JSON.stringify(message));
      });
    }
  }

  ExcelData = [];
  TotalAmount = 0;
  TotalTaxableValue = 0;
  TotalCess = 0;
  exportAsXLSX(): void {
    this.ExcelData = [];
    this.TotalCess = 0;
    for (let excel of this.receiptData) {
      let data = new LedgerSearchStockDTO();
      data.date = this.datePipe.transform(excel.voucherDate, 'dd-MM-yyyy');
      data.particulars = excel.detail;
      data.partyName = excel.customername;
      data.debit = excel.debit;
      data.credit = excel.credit;
      data.balance = excel.txtTotalBalance;
      this.ExcelData.push(data);
    }
    this.excelservice.exportExcelCashBankBook("BankBook", this.ExcelData, this.txtDebitOpeningBalance, this.txtCreditOpeningBalance, this.txtTotalDebit, this.txtTotalCredit, this.txtDebitClosingBalance, this.txtCreditClosingBalance);
  }
}
