import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InvoiceMasterService {

  constructor(
    private http: Http,
  ) { }
  
  createInvoiceMaster(txtStaticInvoiceName: string,txtInvoiceName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      staticInvoiceName : txtStaticInvoiceName,
      invoiceName : txtInvoiceName,
    });
    return this.http.post('/api/create/invoicemaster', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateInvoiceMaster(txtInvoiceId: string,txtStaticInvoiceName: string,txtInvoiceName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      invoiceId : txtInvoiceId,
      staticInvoiceName : txtStaticInvoiceName,
      invoiceName : txtInvoiceName,
    });
    return this.http.put('/api/update/invoicemaster', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllInvoiceMaster(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/invoicemaster', { headers })
      .pipe(map(res => res.json()));
  }
  deleteInvoiceMaster(txtInvoiceId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      invoiceId : txtInvoiceId,
    });
    return this.http.put('/api/delete/invoicemaster', body, { headers })
      .pipe(map(res => res.json()));  
  }

// invoicemaster category api
  createInvoiceCategory(txtInvoice: string,txtCategorys: Array<any>){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      invoiceId : txtInvoice,
      categoryDTOs : txtCategorys,
    });
    return this.http.post('/api/create/invoice/category', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateInvoiceCategory(txtInvoiceCatId: string, txtInvoiceId: string,txtCategory: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      invoiceCategoryId : txtInvoiceCatId,
      invoiceId : txtInvoiceId,
      // categoryDTOs : txtCategorys,
      categoryId : txtCategory,
    });
    return this.http.put('/api/update/invoice/category', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllInvoiceCategory(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/invoice/category', { headers })
      .pipe(map(res => res.json()));
  }
  deleteInvoiceCategory(txtInvoiceCatId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      invoiceCategoryId : txtInvoiceCatId,
    });
    return this.http.put('/api/delete/invoice/category', body, { headers })
      .pipe(map(res => res.json()));  
  }

  // License Number api
  createLicenseNumber(txtLicenseNumber: string,txtLicenseName: string,txtCategorys: Array<any>,txtCompany: string,txtInvoice: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      licenseNumber : txtLicenseNumber,
      licenseName : txtLicenseName,
      categoryDTOs : txtCategorys,
      invoiceId : txtInvoice,
      companyId : txtCompany,
    });
    return this.http.post('/api/create/license/number', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateLicenseNumber(txtLicenseId: string,txtLicenseNumber: string,txtLicenseName: string,txtCategory: string,txtCompany: string,txtInvoice : string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      licenseId : txtLicenseId,
      licenseNumber : txtLicenseNumber,
      licenseName : txtLicenseName,
      // categoryDTOs : txtCategorys,
      categoryId : txtCategory,
      companyId : txtCompany,
      invoiceId : txtInvoice,
    });
    return this.http.put('/api/update/license/number', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllLicenseNumber(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/license/number', { headers })
      .pipe(map(res => res.json()));
  }
  deleteLicenseNumber(txtLicenseId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      licenseId : txtLicenseId,
    });
    return this.http.put('/api/delete/license/number', body, { headers })
      .pipe(map(res => res.json()));  
  }

  // Signature api
  createSignature(txtCompany: string,txtSignatureName: string,txtImage: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      companyId : txtCompany,
      signatureName : txtSignatureName,
      image : txtImage,
    });
    return this.http.post('/api/create/signature', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateSignature(txtSignatureId: string,txtCompany: string,txtSignatureName: string,txtImage: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      signatureId : txtSignatureId,
      companyId : txtCompany,
      signatureName : txtSignatureName,
      image : txtImage,
    });
    return this.http.put('/api/update/signature', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllSignature(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/signature', { headers })
      .pipe(map(res => res.json()));
  }
  deleteSignature(txtSignatureId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      signatureId : txtSignatureId,
    });
    return this.http.put('/api/delete/signature', body, { headers })
      .pipe(map(res => res.json()));  
  }
  uploadSignatureImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/signature/image', data, { headers: headers })
      .pipe(map(res => res));
  }
}
