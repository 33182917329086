<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Sale Record</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-lg-2 col-xl-2 col-6 bottom_margin">
                                    <!-- <label for="country" class="form-label">From <span
                                            style="color: red;">&nbsp;*</span></label> -->
                                    <input (click)="pickerFrom.open()" matInput [matDatepicker]="pickerFrom"
                                        class="form-control" [(ngModel)]="txtFormDate" name="txtFormDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerFrom" style="display: none;">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerFrom></mat-datepicker>
                                    <div class="invalid-feedback">
                                        Please enter a Form Date.
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xl-2 col-6 bottom_margin">
                                    <!-- <label for="country" class="form-label">To <span
                                            style="color: red;">&nbsp;*</span></label> -->
                                    <input (click)="pickerTO.open()" matInput [matDatepicker]="pickerTO" [min]="txtFormDate"
                                        class="form-control" [(ngModel)]="txtToDate" name="txtToDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerTO" style="display: none;">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerTO></mat-datepicker>
                                    <div class="invalid-feedback">
                                        Please enter a To Date.
                                    </div>
                                </div>
                                <div class="col-lg-1 col-xl-2 col-4 btn-container-end bottom_margin">
                                    <button class="btn btn-primary d-block" type="submit" (click)="getAllsaleByDate()">Search</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Sale Date</th>
                                            <th scope="col">Sale Number</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">GST Amount</th>
                                            <!-- <th scope="col">Net Amount</th> -->
                                            <th scope="col">Discount</th>
                                            <th scope="col">Add. Charge</th>
                                            <th scope="col">Net Amount</th>
                                            <th scope="col">Type</th>
                                            <th class="text-center">Image</th>
                                            <!-- <th scope="col" style="min-width: 130px;">Actions</th> -->
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let sale of allsale | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{sale.saleDate | date :'dd-MMM-yyyy'}}</td>
                                            <td>{{sale.saleNumber}}</td>
                                            <td>{{sale.party.partyName}}</td>
                                            <td class="text-end">{{sale.amount | appendIND}}</td>
                                            <td class="text-end">{{sale.gstAmount | appendIND}}</td>
                                            <!-- <td class="text-end">{{sale.netAmount | appendIND}}</td> -->
                                            <td class="text-end">{{sale.discount | appendIND}}</td>
                                            <td class="text-end">{{sale.additionalcharges | appendIND}}</td>
                                            <td class="text-end">{{sale.netAmount + sale.additionalcharges - sale.discount | appendIND}}</td>
                                            <!-- <td class="text-end">{{sale.finalAmount | appendIND}}</td> -->
                                            <td>{{sale.type}}</td>
                                            <td class="text-center"><img *ngIf="sale.image != null" role="button" [src]="sale.image" height="50px" width="50px" data-bs-toggle="modal" data-bs-target="#imageModal"
                                                (click)="imgLargeview(sale)">
                                            <td class="text-end">
                                                <div class="card-close">
                                                    <div class="dropdown">
                                                      <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false" class="dropdown-toggle">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                      </button>
                                                      <div aria-labelledby="closeCard1" style="min-width: 120px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                                        <!-- <a href="#" class="dropdown-item remove">
                                                          <i class="fas fa-times"></i>Close
                                                        </a>
                                                        <a href="#" class="dropdown-item edit">
                                                          <i class="fas fa-cog"></i>Edit
                                                        </a> -->
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                            (click)="getSaleDetailBySaleId(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-history"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
                                                            (click)="getPDFCategoryNoOfInvoiceData(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#pdfModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                                            (click)="getsendEmailData(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#emailModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-envelope"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Send Bill"
                                                            data-bs-toggle="modal" data-bs-target="#eWayBillModal" style="margin-left: 7px; margin-bottom: 5px;"
                                                            (click)="getEWayBillDetail(sale);">
                                                            <i class="fas fa-file-invoice"></i>
                                                        </button>
                                                        <button *ngIf="userRole" type="button" class="btn btn-secondary btn-sm" style="margin-left: 7px; margin-bottom: 5px;"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                            (click)="deleteSale(sale)" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm" style="margin-left: 7px; margin-bottom: 5px;" title="E-Way Bill PDF"
                                                            (click)="getEWayBillDetailServer(sale)">
                                                            <i class="fas fa-file-invoice"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm" style="margin-left: 7px; margin-bottom: 5px;" title="Whatsapp Message"
                                                            (click)="whatsappSale(sale)">
                                                            <i class="fab fa-whatsapp"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allsale < 1 ">
                                            <td colspan="11" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                        <!-- <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item active" aria-current="page">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Sale Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="getAllsaleByDate();clearGridData();hideShowHistoryModel();"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row d-flex align-items-baseline">
                                    <div class="col-lg-12 col-md-12 mb-4">
                                        <div class="portlet">
                                            <div class="portlet-header">Sale</div>
                                            <form class="needs-validation" novalidate="">
                                                <div class="portlet-content">
                                                    <div class="row d-flex align-items-baseline g-3">
            
                                                        <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                                            <label for="last" class="form-label">Item Name<span style="color: red;" class="me-2">&nbsp;*</span>
                                                            </label>
                                                            <!-- <input type="text" [value]="search1" ngxTypeahead
                                                                (focusout)="getItemById()" [taList]="searchItemName"
                                                                [taItemTpl]="myTemp" (taSelected)="selectedStatic($event)"
                                                                id="ItemName" [(ngModel)]="txtItemName" name="txtItemName"
                                                                placeholder="Enter Item Name"
                                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                                autofocus="autofocus" />
                                                            <ng-template #myTemp let-item>
                                                                <div class="item-name">
                                                                    {{item.result.itemName}}
                                                                </div>
                                                            </ng-template> -->
            
                                                            <input type="text" class="form-control" id="ItemName"
                                                                placeholder="Enter Item Name" required="required"
                                                                [(ngModel)]="txtItemName" name="txtItemName" disabled />
                                                            <div class="invalid-feedback">
                                                                Please enter item name.
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-2">
                                                            <label for="first" class="form-label">Category Name</label>
                                                            <input type="text" class="form-control" id="itemName"
                                                                placeholder="Enter Category Name" required="required"
                                                                [(ngModel)]="txtCategoryName" name="txtCategoryName" disabled />
                                                            <div class="invalid-feedback">
                                                                Please enter txtCategory Name.
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <label for="country" class="form-label">SubCategory Name</label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Enter SubCategory Name" required="required"
                                                                [(ngModel)]="txtSubCategoryName" name="txtSubCategoryName"
                                                                disabled />
                                                            <div class="invalid-feedback">
                                                                Please enter a SubCategory Name.
                                                            </div>
                                                        </div> -->
                                                        <div class="col-lg-3 col-xl-3 col-6">
                                                            <label for="country" class="form-label">Brand Name</label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Enter Brand Name" required="required"
                                                                [(ngModel)]="txtBrandName" name="txtBrandName" disabled />
                                                            <div class="invalid-feedback">
                                                                Please enter a SubCategory Name.
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-xl-1 col-6">
                                                            <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Quantity" required="required"
                                                                [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                                            <div class="invalid-feedback">
                                                                Please enter a quantity.
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-2 col-xl-2 col-6">
                                                            <label for="country" class="form-label">Rate<span style="color: red;">&nbsp;*</span></label>
                                                            <input type="text" class="form-control" id="last"
                                                                placeholder="Rate" required="required"
                                                                [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                                            <div class="invalid-feedback">
                                                                Please enter a rate.
                                                            </div>
                                                        </div>
                                                        <div class="col-auto ms-auto btn-container-end" *ngIf="!isClick">
                                                            <button type="button" class="btn btn-primary d-block me-2"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                                (click)="getBatchNumber()" data-bs-toggle="modal"
                                                                data-bs-target="#stockCheckModal">
                                                                STOCK
                                                            </button>
                                                            <button class="btn btn-secondary me-2" type="button" (click)="clearGridData();">
                                                                Cancel
                                                            </button>
                                                            <button class="btn btn-primary d-block" type="submit"
                                                                (click)="addUpdateSaleDetail()">{{btnName}}
                                                            </button>
                                                        </div>
                                                        <div class="col-auto ms-auto btn-container-end" *ngIf="isClick">
                                                            <button class="btn btn-primary" type="button" disabled>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                Please wait...
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <!-- <div class="row d-flex justify-content-end">
                                            <div class="col-md-6">
                                                <div class="input-group search-box">
                                                    <input class="form-control border-0" type="search"
                                                        placeholder="Search" name="search" [(ngModel)]="search"
                                                        id="example-search-input" />
                                                    <span class="input-group-append">
                                                        <button class="btn btn-outline-secondary border-0"
                                                            type="button">
                                                            <i class="fa fa-search"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                              <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Action</th>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">SubCategory</th>
                                                        <th scope="col">Brand</th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Rate</th>
                                                        <!-- <th scope="col">Manufacture Date</th>
                                                    <th scope="col">Expiry Date</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let saledtl of allSaleDetail">
                                                        <td>
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                            (click)="updateSale(saledtl)">
                                                            <i class="fas fa-pencil-alt"></i></button>
                                                        </td>
                                                        <td>{{saledtl.item.itemName}}</td>
                                                        <td>{{saledtl.item.category.categoryName}}</td>
                                                        <td>{{saledtl.item.subCategory.subCategoryName}}</td>
                                                        <td>{{saledtl.item.brand.name}}</td>
                                                        <td>{{saledtl.quantity}}</td>
                                                        <td class="text-end">{{saledtl.rate | appendIND}}</td>
                                                        <!-- <td>{{saledtl.party.partyName}}</td>
                                                    <td>{{saledtl.party.partyName}}</td> -->
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allsale < 1 ">
                                                        <td colspan="6" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal" (click)="getAllsaleByDate();clearGridData();hideShowHistoryModel();">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="pdfModal" tabindex="-1" aria-labelledby="pdfModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Download PDF</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- Do you want to download PDF ? -->
                <!-- <div class="row col-md-12 d-flex text-center align-items-center mb-2" *ngFor="let comp of allPDFCategory">
                    <a role="button">{{comp.companyName}}</a>
                    <div class="row col-md-4 d-flex text-center align-items-center mb-2" *ngFor="let inv of comp.invoiceDetail">
                        <a role="button" (click)="downloadNewPDF(inv,comp.invoiceDetail.length)">{{inv.invoicename}}</a>
                    </div>
                </div> -->
                
                <ul *ngFor="let comp of allPDFCategory">
                    <li ><b>{{comp.companyName}}</b>
                      <ul *ngFor="let inv of comp.invoiceDetail">
                        <li role="button" (click)="downloadNewPDF(inv)">{{inv.invoicename}}</li>
                      </ul>
                    </li>
                    <!-- <li>Mix all the ingredients
                      <ul>
                        <li>Dump in bowl</li>
                        <li>Stir with whisk</li>
                      </ul>
                    </li> -->
                </ul>
                <div class="text-center" *ngIf="allPDFCategory < 1 || allPDFCategory == null">
                    Invoice detail not found.
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="downloadPDF()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-6 col-xl-6 col-6">
                    <label for="country" class="form-label">Email Address</label>
                    <input type="text" class="form-control" id="last"
                        placeholder="Enter Email Address" required="required"
                        [(ngModel)]="txtEmail" name="txtEmail" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="sendMailUsingSendgrid()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- E-Way Bill Model -->
<div class="modal fade" id="eWayBillModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">E-Way Bill Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="clearValue();" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <form class="needs-validation" novalidate="">
                            <!-- <div class="portlet-header">Purchase</div> -->
                            <div class="portlet-content">
                                <div class="row d-flex g-3">
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="gstin" class="form-label">To GstIn <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="gstin"
                                            placeholder="Enter To GstIn" required="required"
                                            [(ngModel)]="txtToGstIn" name="txtToGstIn" />
                                        <div class="invalid-feedback">
                                            Please enter a To GstIn.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="trdName" class="form-label">To Trade Name <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="trdName"
                                            placeholder="Enter To TradeName" required="required"
                                            [(ngModel)]="txtToTradeName" name="txtToTradeName" />
                                        <div class="invalid-feedback">
                                            Please enter a To Trade Name.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="add1" class="form-label">To Address 1 <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="add1"
                                            placeholder="Enter To Address1" required="required"
                                            [(ngModel)]="txtToAddress1" name="txtToAddress1" />
                                        <div class="invalid-feedback">
                                            Please enter a To Address1.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="add2" class="form-label">To Address 2 </label>
                                        <input type="text" class="form-control" id="add2"
                                            placeholder="Enter To Address2" required="required"
                                            [(ngModel)]="txtToAddress2" name="txtToAddress2" />
                                        <div class="invalid-feedback">
                                            Please enter a To Address 2.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="place" class="form-label">To Place <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="place"
                                            placeholder="Enter To Place" required="required"
                                            [(ngModel)]="txtToPlace" name="txtToPlace" />
                                        <div class="invalid-feedback">
                                            Please enter a To Place.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="pin" class="form-label">To Pincode <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="pin"
                                            placeholder="Enter To Pincode" required="required"
                                            [(ngModel)]="txtToPincode" name="txtToPincode" />
                                        <div class="invalid-feedback">
                                            Please enter a To Pincode.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="otherVal" class="form-label">Other Value </label>
                                        <input type="text" class="form-control" id="otherVal"
                                            placeholder="Enter Other Value" required="required"
                                            [(ngModel)]="txtOtherValue" name="txtOtherValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a Other Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="otherVal" class="form-label">Total Value </label>
                                        <input type="text" class="form-control" id="otherVal"
                                            placeholder="Enter Total Value" required="required"
                                            [(ngModel)]="txtTotalValue" name="txtTotalValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a Total Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="cgstVal" class="form-label">Cgst Value </label>
                                        <input type="text" class="form-control" id="cgstVal"
                                            placeholder="Enter Cgst Value" required="required"
                                            [(ngModel)]="txtCgstValue" name="txtCgstValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a Cgst Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="sgstVal" class="form-label">Sgst Value </label>
                                        <input type="text" class="form-control" id="sgstVal"
                                            placeholder="Enter Sgst Value" required="required"
                                            [(ngModel)]="txtSgstValue" name="txtSgstValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a Sgst Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="igstVal" class="form-label">Igst Value </label>
                                        <input type="text" class="form-control" id="igstVal"
                                            placeholder="Enter Igst Value" required="required"
                                            [(ngModel)]="txtIgstValue" name="txtIgstValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a Igst Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="cessVal" class="form-label">Cess Value </label>
                                        <input type="text" class="form-control" id="cessVal"
                                            placeholder="Enter Cess Value" required="required"
                                            [(ngModel)]="txtCessValue" name="txtCessValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a Cess Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="cessNonAdvolVal" class="form-label">CessNonAdvol Value </label>
                                        <input type="text" class="form-control" id="cessNonAdvolVal"
                                            placeholder="Enter CessNonAdvol Value" required="required"
                                            [(ngModel)]="txtCessNonAdvolValue" name="txtCessNonAdvolValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a CessNonAdvol Value.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="totInvVal" class="form-label">totInv Value </label>
                                        <input type="text" class="form-control" id="totInvVal"
                                            placeholder="Enter totInv Value" required="required"
                                            [(ngModel)]="txttotInvValue" name="txttotInvValue" disabled />
                                        <div class="invalid-feedback">
                                            Please enter a totInv Value.
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex g-3">
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="transporterNm" class="form-label">Transporter Name <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="transporterNm"
                                            placeholder="Enter Transporter Name" required="required"
                                            [(ngModel)]="txtTransporterName" name="txtTransporterName" />
                                        <div class="invalid-feedback">
                                            Please enter a transporter name.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="transDoc" class="form-label">Transporter DocNo <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="transDoc"
                                            placeholder="Enter Transporter DocNo" required="required"
                                            [(ngModel)]="txtTransDocNo" name="txtTransDocNo" />
                                        <div class="invalid-feedback">
                                            Please enter a Transporter DocNo.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="transMod" class="form-label">Transporter Mode <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="transMod"
                                            placeholder="Enter Transporter Mode" required="required"
                                            [(ngModel)]="txtTransMode" name="txtTransMode" />
                                        <div class="invalid-feedback">
                                            Please enter a Transporter Mode.
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="transDis" class="form-label">Transporter Distance <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="transDis"
                                            placeholder="Enter Transporter Distance" required="required"
                                            [(ngModel)]="txtTransDistance" name="txtTransDistance" />
                                        <div class="invalid-feedback">
                                            Please enter a Transporter Distance.
                                        </div>
                                    </div> -->
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="transDocDt" class="form-label">Transporter DocDate<span style="color: red;">&nbsp;*</span></label>
                                        <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                            class="form-control" [(ngModel)]="txtTransDocDate" name="txtTransDocDate"
                                            placeholder="Enter Transporter DocDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker3 ></mat-datepicker>
                                        <div class="invalid-feedback">
                                            Please enter a Transporter DocDate.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="vehicleNo" class="form-label">Vehicle No <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="vehicleNo"
                                            placeholder="Enter Vehicle No" required="required"
                                            [(ngModel)]="txtVehicleNo" name="txtVehicleNo" (focusout)="toUpperCaseVehicleNo()"/>
                                        <div class="invalid-feedback">
                                            Please enter a Vehicle No.
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-xl-3 col-6">
                                        <label for="vehicleTp" class="form-label">Vehicle Type <span
                                            style="color: red;">&nbsp;*</span></label>
                                        <input type="text" class="form-control" id="vehicleTp"
                                            placeholder="Enter Vehicle Type" required="required"
                                            [(ngModel)]="txtVehicleType" name="txtVehicleType" />
                                        <div class="invalid-feedback">
                                            Please enter a Vehicle Type.
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                        <button class="btn btn-primary d-block" type="submit"
                                            (click)="createEwayBill()">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="clearValue();" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Model Close -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="imageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="historyLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="historyLabel">View Image</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <img [src]="largeviewImage" style="height: 100%;width: 50%;" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Stock Check Model -->
<div class="modal fade" id="stockCheckModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" 
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Batch wise Stock Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="hideShowBarcodeModel()"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="mt-3 table-responsive">
                            <table class="table table-hover table-striped">
                                <thead class="tblheadercolor">
                                    <tr>
                                        <th scope="col">Batch Number</th>
                                        <th scope="col">Available Qty</th>
                                        <th scope="col">Expiry Date</th>
                                        <th class="text-center" scope="col">Alloted Qty</th>
                                        <!-- <th class="text-center" scope="col">CREDIT (₹)</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let batch of batchwiseresponse;">
                                        <td>{{batch.batchNumber}}</td>
                                        <td>{{batch.quantity}}</td>
                                        <td>{{batch.expiryDate | date:'dd-MMM-yyyy'}}</td>
                                        <td>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Quantity" required="required"
                                                [(ngModel)]="batch.allotedquantity" name="txtrate" (keypress)="numberOnly($event)"/>
                                        </td>
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="hideShowBarcodeModel()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>