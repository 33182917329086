import { Component, OnInit } from '@angular/core';
import { receiptVoucherDto } from '../service/receipt_voucher/receipt_voucher';
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { SaleService } from "../service/sale/sale.service";
// import { sale } from '../service/sale/sale';
import { searchSale } from '../service/sale/searchSale';
// import { sale } from '../service/sale/sale';
import { LiabilityVoucherService } from '../service/liability-voucher/liability-voucher.service';
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';
declare var $: any;

@Component({
  selector: 'app-liability-voucher',
  templateUrl: './liability-voucher.component.html',
  styleUrls: ['./liability-voucher.component.css']
})
export class LiabilityVoucherComponent implements OnInit {
  txtLiabilityId;
  txtVoucherDate;
  txtselected;
  txtpartyName;
  txtsaleCode;
  txtpandingAmount;
  txtnetAmount;
  txtcredit;
  txtcompany;
  optradio;
  allCompany;
  txtRadio;
  isDisabled: boolean = true;

  // allreceiptvoucher;
  btnName = 'Add';
  allLiability;

  public searchParty = '';
  searchPartyName = [];
  public searchSale = '';
  searchsalecode = [];
  totalCredit = 0;
  txtNarration;

  ReceiptData: Array<receiptVoucherDto> = [];
  ReceiptIdLast: number = 0;

  datePipe = new DatePipe("en-US");
  txtChequeNo;
  txtLibLedger;
  allAssLibLedger;
  ledgerDisabled = false;

  constructor(
    private liabilityVoucherService: LiabilityVoucherService,
    private toastr: ToastrService,
    private partyService: PartyService,
    private saleservice: SaleService,
    private assetsVoucherService: AssetsVoucherService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.getAllParty();
    // this.getAllsale();
    // this.getAllPartyAccount();
    this.getAllCompany();
    // this.getAllAssetsLedger();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party",this.allCompany);
    })
  }

  getAllAssetsLedger() {
    this.assetsVoucherService.getAllAssLibLedger("11").subscribe(res => {
      this.allAssLibLedger = res;
      // console.log(this.allAssLibLedger);
    })
  }
  allBank;
  getAllBank() {
    this.partyService.getAllBank(this.txtcompany.partyId).subscribe(res => {
      this.allBank = res;
      // console.log("all Bank", this.allBank);
    })
  }

  checkBank(){
    if(this.txtRadio == "Bank"){
      if(this.txtcompany == null || this.txtcompany == "-:Select Company Name:-"){
        this.allBank = null;
      }else{
        this.getAllBank();
      }
    }
  }

  onItemChange(value) {
    // console.log(value);
    this.txtRadio = value;
    if (value == "Bank") {
      // console.log(value);
      this.isDisabled = false;
      if(this.txtcompany == null){
        this.toastr.error("Please select company");
      }else{
        this.getAllBank();
      }
      
    } else {
      this.isDisabled = true;
    }
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    // this.tempPartyopeningBalance = party.openingBalance;
    this.tempPartyId = party.partyId;
    // this.getClosingBalance();
    // this.getLedgerByPartyId(party.partyId);
    this.getAllLedgerByPartyId(party.partyId);
    this.selectLedger(party.type);
  }

  allparty;
  tempPartyopeningBalance;
  tempPartyId;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        // if(item.openingBalance == null || item.openingBalance == undefined || item.openingBalance == ''){
        //   temp.openingBalance = 0;
        // }
        // temp.openingBalance = item.openingBalance;
        temp.address = item.address;
        temp.type = item.type;
        this.searchPartyName.push(temp)
      }
      // console.log(this.allparty);
    })
  }

  partyLedger;
  getLedgerByPartyId(partyId) {
    this.partyService.getLedgerByPartyId(partyId).subscribe(res => {
      this.partyLedger = res.ledgerName;
    })
  }
  allPartyLedgerName;
  getAllLedgerByPartyId(partyId){
    this.partyService.getAllLedgerByPartyId(partyId).subscribe(res => {
      this.allPartyLedgerName = res;
    })
  }
  selectLedger(type){
    if(type == "COMPANY"){
      // this.txtLedger = "SUNDRY CREDITORS";
      // this.txtLedger = 21;
      this.txtLibLedger = 28;
      this.ledgerDisabled = true;
    } else if(type == "FARMER"){
      // this.txtLedger = "SUNDRY DEBITORS";
      // this.txtLedger = 20;
      this.txtLibLedger = 23;
      this.ledgerDisabled = true;
    } else {
      this.ledgerDisabled = false;
      this.txtLibLedger = null;
    }
  }

  allPartyCash;
  getAllPartyAccount() {
    this.partyService.getAllPartyAccount().subscribe(res => {
      this.allPartyCash = res;
    })
  }

  tempSale;
  selectedSaleCode(sale) {
    this.tempSale = sale;
    // console.log(this.tempSale);
    this.searchSale = sale.saleCode;
    // this.getsaleById();
  }

  allsale;
  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
      for (let item of this.allsale) {
        var temp = new searchSale();
        temp.saleCode = item.saleCode;
        temp.saleid = item.saleid;
        this.searchsalecode.push(temp)
      }
      // console.log(this.allsale);
    })
  }

  clearValue() {
    this.txtpartyName = "";
    this.txtcredit = "";
    this.searchParty = "";
    this.tempParty = "";
    this.txtNarration="";
    this.btnName = "Add";
    this.txtLiabilityId = null;
    this.txtrefId = null;
    this.txtparticulars = null;
    this.txtcompany = "";
    this.ReceiptData = null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txtChequeNo = "";
    this.txtLibLedger = "";
    this.partyLedger = null;
  }

  addLiabilityVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      return this.toastr.error("Company name must be required")
    } else if (this.txtRadio == "Bank" && (this.txtselected == "" || this.txtselected == null || this.txtselected == undefined || this.txtselected == "-:Select Bank Name:-")) {
      return this.toastr.error("Bank name must be required")
    } else if (this.txtRadio == "Bank" && (this.txtChequeNo == "" || this.txtChequeNo == null || this.txtChequeNo == undefined)) {
      return this.toastr.error("Cheque number must be required")
    } else if (this.tempParty == undefined || this.tempParty == null || this.tempParty == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.txtLibLedger == undefined || this.txtLibLedger == null || this.txtLibLedger == "" || this.txtLibLedger == "-:Select Ledger Name:-") {
      return this.toastr.error('Ledger must be required');
    } else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
      return this.toastr.error('Amount must be required');
    } else {
      if (this.txtLiabilityId == null) {
        var receiptDetail = new receiptVoucherDto();
        receiptDetail.id1 = this.ReceiptIdLast + 1;
        this.ReceiptIdLast = receiptDetail.id1;
        // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
        receiptDetail.voucherDate = this.txtVoucherDate;
        // receiptDetail.numbers = this.tempSale.saleCode;
        receiptDetail.partyId = this.tempParty.partyId;
        receiptDetail.partyName = this.tempParty.partyName;
        // receiptDetail.party = this.tempParty;
        receiptDetail.credit = this.txtcredit;
        // receiptDetail.billDetail = this.tempSale;
        // receiptDetail.receivedAmount = this.txtreceiveAmount;
        // receiptDetail.netAmount = this.txtnetAmount;
        receiptDetail.amount = this.txtcredit;
        this.totalCredit = Number(this.totalCredit) + Number(receiptDetail.credit);
        receiptDetail.ledgerId = this.txtLibLedger;
        this.ReceiptData.push(receiptDetail);
        // console.log(this.ReceiptData);
        // this.clearValue();
      } else {
        for (let Sale of this.ReceiptData) {
          if (Sale.id1 == this.txtLiabilityId) {
            // Sale.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd hh-mm-ss');
            // Sale.numbers = this.tempSale.saleCode;
            Sale.voucherDate = this.txtVoucherDate;
            Sale.partyId = this.tempParty.partyId;
            Sale.partyName = this.tempParty.partyName;
            // Sale.party = this.tempParty;
            Sale.credit = this.txtcredit;
            // Sale.billDetail = this.tempSale;
            // receiptDetail.receivedAmount = this.txtreceiveAmount;
            // Sale.netAmount = this.txtnetAmount;
            Sale.amount = this.txtcredit;
            this.getAmount();
            this.clearValue();
          }
        }
      }
    }
    this.createLiabilityVoucher();
    console.log(this.ReceiptData);
  }
  getAmount() {
    this.totalCredit = 0;
    for (let item of this.ReceiptData) {
      var sum = 0;
      sum = sum + Number(item.credit);
      this.totalCredit = this.totalCredit + sum;
    }
  }


  txtparticulars;
  txtrefId;
  receiptVoucherres;
  createLiabilityVoucher() {
    for (let receiptDetail of this.ReceiptData) {
      receiptDetail.refId = this.txtcompany.partyId;
      receiptDetail.particulars = this.txtcompany.partyName;
      receiptDetail.ledgerId = this.txtLibLedger;
      // receiptDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd hh-mm-ss');
      // receiptDetail.numbers = receiptDetail.numbers;
      receiptDetail.amount = receiptDetail.credit;
      if (this.txtrefId == null || this.txtrefId == "") {
        this.txtrefId = receiptDetail.partyId;
        this.txtparticulars = receiptDetail.partyName;
      } else {
        this.txtrefId = this.txtrefId + ',' + receiptDetail.partyId;
        this.txtparticulars = this.txtparticulars + ',' + receiptDetail.partyName;
      }
    }

    let receiptDetails = new receiptVoucherDto();
    receiptDetails.partyId = this.txtcompany.partyId;
    receiptDetails.partyName = this.txtcompany.partyName;
    receiptDetails.refId = this.txtrefId;
    receiptDetails.particulars = this.txtparticulars;
    // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    receiptDetails.voucherDate = this.txtVoucherDate;
    // receiptDetails.narration = this.txtNarration;
    receiptDetails.ledgerId = this.txtLibLedger;

    if(this.txtRadio == "Bank"){
      receiptDetails.bankId = this.txtselected.bankid;
      receiptDetails.ledgerId = this.txtLibLedger;
    }
    this.ReceiptData.push(receiptDetails);
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.liabilityVoucherService.createLiabilityVoucher(this.txtVoucherDate, this.ReceiptData, this.txtNarration, this.txtcompany.partyId,this.txtChequeNo)
      .subscribe(res => {
        this.receiptVoucherres = res;
        // console.log(this.receiptVoucherres);
        this.clearValue();
        this.ReceiptData = [];
        this.totalCredit = 0;
        this.toastr.success('Liability voucher save successfully');
      }, error => {
        // this.toastr.error('liability voucher not saved');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
  }

  getAllLiability() {
    this.liabilityVoucherService.getAllLiability().subscribe(res => {
      this.allLiability = res;
    })
  }

}
