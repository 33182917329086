<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Vender Detail</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <!-- <div class="col-md-6">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Excel</button>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Vender Name</th>
                                            <th scope="col">Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let iwstock of allVenderList | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{iwstock.clientName}}</td>
                                            <td>{{iwstock.mobileNumber}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allVenderList < 1 ">
                                            <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>