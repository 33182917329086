import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GSTService {

  constructor(
    private http: Http,
  ) { }

  createGst(txtGst: string, txtCGst: number, txtSGst: number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      gstName : txtGst,
      cgst : txtCGst,
      sgst : txtSGst,
    });
    return this.http.post('/api/create/gst', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateGst(txtGstId: string, txtGst: string, txtCGst: string, txtSGst: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      gstName : txtGst,
      id : txtGstId,
      cgst : txtCGst,
      sgst : txtSGst,
    });
    return this.http.put('/api/update/gst', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllGst(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/gst', { headers })
      .pipe(map(res => res.json()));

  }
  deleteGst(unitid:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id : unitid
    });
    return this.http.put('/api/delete/gst', body, { headers })
      .pipe(map(res => res.json()));
  }

  importExcelGSTINIT(resGST){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/gst', resGST, { headers })
      .pipe(map(res => res));
  }
}
