import { Component, OnInit } from '@angular/core';
import { PartyService } from "../service/party/party.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CompanyService } from '../service/company/company.service';
import { ExcelService } from '../service/excel/excel.service';
import * as XLSX from 'xlsx';
import { TypeService } from '../service/type/type.service';
import { GroupMasterPartyService } from '../service/group-master-party/group-master-party.service';
import { PurchasePlanService } from '../service/purchase_plan/purchase-plan.service';
import { LedgerService } from '../service/ledger/ledger.service';
declare var $: any;

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.css']
})
export class PartyComponent implements OnInit {
  resParty;
  resUpParty;
  txtParty;
  txtPartyCode;
  txtAddress;
  txtCity;
  txtState;
  txtPincode;
  txtCellNumber;
  txtMailId;
  txtPartyId;
  allParty;
  btnName = 'Save';
  search;
  page: number = 1;
  txtGstNumber;
  txtType;
  txtAccountGroup;
  txtopeningbalance:number=0;
  allCompany;
  txtChequeNo;
  txtbankName;
  allType;
  txtGroupName;
  allGroup;
  allState;
  txtLedger;
  allLedger;
  ledgerDisabled = false;
  disabledOpeningBalance: any = false;

  constructor(
    private router: Router,
    private PartyService: PartyService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
    private typeService: TypeService,
    private groupMasterPartyService: GroupMasterPartyService,
    private purchasePlanService: PurchasePlanService,
    private ledgerService: LedgerService,
  ) { }

  ngOnInit(): void {
    this.getAllParty();
    this.getAllCompany();
    this.getAllType();
    this.getAllGroup();
    this.getAllState();
    this.getAllLedger();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // Allow only Numbers and minus(-)
  numberandMinusOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 45) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  getAllParty() {
    this.PartyService.getAllPartyByAllType().subscribe(res => {
      this.allParty = res;
    })
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }
  getAllType() {
    this.typeService.getAllType().subscribe(res => {
      this.allType = res;
    })
  }
  getAllGroup() {
    this.groupMasterPartyService.getAllGroup().subscribe(res => {
      this.allGroup = res;
    })
  }
  getAllState() {
    this.PartyService.getAllState().subscribe(res => {
      this.allState = res;
      for(let state of res){
        this.partyStateArray.push({"State":state.stateName});
      }
    })
  }
  txtStateCode;
  stateCode(){
    if(this.txtState == null || this.txtState == "" || this.txtState == undefined){
      this.txtState = null;
    } else {
      this.txtStateCode = this.txtState.stateCode;
    }
  }
  ledgerArray = [];
  getAllLedger() {
    this.ledgerService.getAllLedger().subscribe(res => {
      this.allLedger = res;
      for(let leg of res){
        this.ledgerArray.push({"Ledger":leg.ledgerName});
      }
    })
  }
  selectLedger(){
    if(this.txtType == "COMPANY"){
      // this.txtLedger = "SUNDRY CREDITORS";
      // this.txtLedger = 21;
      this.txtLedger = 28;
      this.ledgerDisabled = true;
    } else if(this.txtType == "FARMER"){
      // this.txtLedger = "SUNDRY DEBITORS";
      // this.txtLedger = 20;
      this.txtLedger = 23;
      this.ledgerDisabled = true;
    } else {
      this.ledgerDisabled = false;
      this.txtLedger = null;
    }
  }

  clearData() {
    this.ledgerDisabled = false;
    this.btnName = 'Save';
    this.txtParty = '';
    this.txtPartyCode = '';
    this.txtAddress = '';
    this.txtCity = '';
    this.txtState = '';
    this.txtPincode = '';
    this.txtCellNumber = '';
    this.txtMailId = '';
    this.txtPartyId = null;
    this.txtType = '';
    this.txtGstNumber = null;
    this.txtAccountGroup = '';
    this.txtopeningbalance = 0;
    this.txtChequeNo = "";
    this.txtbankName = "";
    this.txtGroupName = null;
    this.txtStateCode = null;
    this.txtLedger = null;
    this.disabledOpeningBalance = false;
  }

  Address;
  City;
  State;
  StateCode;
  PinCode;
  Email;
  getPartyDetail(party){
    this.Address = party.address;
    this.City = party.city;
    this.State = party.state;
    this.StateCode = party.stateCode;
    this.PinCode = party.pincode;
    this.Email = party.mailId;
  }

  companyData = [];
  getcompanyDetail() {
    this.companyData = [];
    for (let cmp of this.allCompany) {
    this.companyData.push({"companyId": cmp.companyId, "companyName": cmp.companyName, "rate": cmp.openingbalance });
    }
    $('#CompanyModal').modal('hide');
    console.log(this.companyData);
  }

  createParty() {
    if (this.txtParty == null || this.txtParty == '' || this.txtParty == undefined) {
      this.toastr.error("party is required");
    } else if (this.txtState == null || this.txtState == '' || this.txtState == undefined || this.txtState == "-:Select State:-") {
      this.toastr.error("State is required");
    } else if (this.txtStateCode == null || this.txtStateCode == '' || this.txtStateCode == undefined) {
      this.toastr.error("State code is required");
    } else if (this.txtCellNumber == null || this.txtCellNumber == '' || this.txtCellNumber == undefined) {
      this.toastr.error("Mobile number is required");
    } else if (this.txtCellNumber.length > 10 || this.txtCellNumber.length < 10) {
      this.toastr.error("Mobile number must be 10 digit");
    } else if (this.txtType == null || this.txtType == '' || this.txtType == undefined || this.txtType == "-:Select Type:-") {
      this.toastr.error("Type is required");
    } else if (this.txtGroupName == "-:Select Group:-") {
      this.txtGroupName = null;
    // } else if (this.txtChequeNo == null || this.txtChequeNo == '' || this.txtChequeNo == undefined) {
    //   this.toastr.error("cheque no is required");
    // } else if (this.txtbankName == null || this.txtbankName == '' || this.txtbankName == undefined) {
    //   this.toastr.error("bank name is required");
    } else if ((this.txtPartyId == null) && (this.txtLedger == null || this.txtLedger == '' || this.txtLedger == undefined || this.txtLedger == "-:Select Ledger:-")) {
      this.toastr.error("Ledger is required");
    } else {
      if (this.txtGstNumber == null || this.txtGstNumber == '' || this.txtGstNumber == undefined) {
        this.txtGstNumber = null;
      }
      if(this.txtState == null || this.txtState == undefined || this.txtState == ""){
        this.txtState = null;
      } else {
        this.txtState = this.txtState.stateName;
      }
        if (this.txtPartyId == null) {
          if(this.companyData.length <= 0 ){
            console.log(true);
            for (let cmp of this.allCompany) {
              this.companyData.push({"companyId": cmp.companyId, "companyName": cmp.companyName, "rate": 0 });
              }
          }
          this.PartyService.createParty(this.txtParty, this.txtPartyCode, this.txtAddress, this.txtCity, this.txtState, this.txtPincode, this.txtCellNumber, this.txtMailId, this.txtGstNumber, this.txtType, this.txtAccountGroup, this.companyData, this.txtChequeNo, this.txtbankName,this.txtGroupName,this.txtStateCode,this.txtLedger)
            .subscribe(res => {
              this.resParty = res;
              // console.log(this.resParty);
              this.clearData();
              this.companyData = [];
              // console.log("company Data",this.companyData);
              this.getAllParty();
              this.getAllCompany();
              this.toastr.success('Party save successfully');
            }, error => {
              let message = JSON.parse(error._body).message;
              this.toastr.error(JSON.stringify(message));
            })
        } else {
          this.PartyService.updateParty(this.txtPartyId, this.txtParty, this.txtPartyCode, this.txtAddress, this.txtCity, this.txtState, this.txtPincode, this.txtCellNumber, this.txtMailId, this.txtGstNumber, this.txtType, this.txtAccountGroup,this.txtopeningbalance, this.txtChequeNo, this.txtbankName, this.companyData,this.txtGroupName,this.txtStateCode,this.txtLedger).subscribe(res => {
            this.resUpParty = res;
            this.clearData();
            this.getAllParty();
            this.toastr.success('Party updated successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
        }
    }
  }

  updateParty(party) {
    this.ledgerDisabled = true;
    var booleanValue = this.allState.filter(function (stat){
      return stat.stateName == party.state
    })
    this.btnName = 'Update';
    this.txtPartyId = party.partyId;
    this.txtParty = party.partyName;
    this.txtPartyCode = party.partyCode;
    this.txtAddress = party.address;
    this.txtCity = party.city;
    // this.txtState = party.state;
    this.txtPincode = party.pincode;
    this.txtCellNumber = party.cellNumber;
    this.txtMailId = party.mailId;
    this.txtGstNumber = party.gstNumber;
    this.txtType = party.type;
    this.txtAccountGroup = party.accountGroup;
    this.txtopeningbalance = party.openingBalance;
    this.txtChequeNo = party.cheqNo;
    this.txtbankName = party.bankName;
    if (party.group != null) {
      this.txtGroupName = party.group.groupName;
    }
    this.txtStateCode = booleanValue[0].stateCode;
    this.txtState = booleanValue[0];
    this.txtLedger = party.ledgerId;
    // this.getOpeningBalanceUpdate(party.partyId);
    this.disabledOpeningBalance = true;
  }

  deleteid;
  deleteParty(party) {
    this.deleteid = party.partyId;
  }

  toUpperParty(){
    const temp = this.txtParty;
    this.txtParty = temp.toUpperCase();
    return this.txtParty;
  }
  toUpperState(){
    const temp = this.txtState;
    this.txtState = temp.toUpperCase();
    return this.txtState;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.PartyService.deleteParty(this.deleteid).subscribe(res => {
        this.resUpParty = res;
        this.clearData();
        this.getAllParty();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Party deleted successfully');
      }, error => {
        this.toastr.error('Party not deleted');
      })
    }
  }

  // Party Excel
  txtExcelParty;
  ExcelDataParty = [];
  fileNameParty;
  selectedExcelParty;
  partyData;
  partyArray = ["COMPANY","FARMER"];
  partyStateArray = [];
  // partyStateArray = [{"state":"GUJARAT"},{"state":"ANDHRA PRADESH"},{"state":"ARUNACHAL PRADESH"},
  // {"state":"ASSAM"},{"state":"BIHAR"},{"state":"CHHATTISGARH"},{"state":"GOA"},
  // {"state":"HARYANA"},{"state":"HIMACHAL PRADESH"},{"state":"JHARKHAND"},{"state":"KARNATAKA"},
  // {"state":"KERALA"},{"state":"MADHYA PRADESH"},{"state":"MAHARASHTRA"},{"state":"MANIPUR"},
  // {"state":"MEGHALAYA"},{"state":"MIZORAM"},{"state":"NAGALAND"},{"state":"ODISHA"},{"state":"PUNJAB"},
  // {"state":"RAJASTHAN"},{"state":"SIKKIM"},{"state":"TAMIL NADU"},{"state":"TELANGANA"},
  // {"state":"TRIPURA"},{"state":"UTTAR PRADESH"},{"state":"UTTARAKHAND"},{"state":"WEST BENGAL"}];
  partyStateArray1 = [];
  exportExcelParty(): void {
    this.ExcelDataParty = [];
    this.fileNameParty = "Party";
    this.excelservice.exportExcelParty(this.fileNameParty,this.ExcelDataParty,this.partyArray,this.partyStateArray,this.ledgerArray);
  }

  onExcelUploadParty(event){
    this.selectedExcelParty = <File>event.target.files[0];
  }
  importandConvertExcelParty(){
    if (this.selectedExcelParty) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelParty);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        var party = workbook.SheetNames[0]
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[party]);
        this.partyData = rowObject;
        var resParty = [];
        for(let data of this.partyData){
          resParty.push({"partyName":data["Party Name"],"address":data["Address"],"state":data["State"],"city":data["City"],
          "pincode":data["Pincode"],"cellNumber":data["Mobile Number"],"mailId":data["Email"],"gstNumber":data["GST Number"],
          "type":"FARMER","cheqNo":data["Cheque No"],"bankName":data["Bank Name"],"ledgerName":data["Ledger"]});
        }
        
        //save excel data inot server
        this.PartyService.importExcelParty(resParty).subscribe(res => {
          this.toastr.success("Party save successfully");
          this.selectedExcelParty = '';
          this.txtExcelParty = '';
          this.getAllParty();
        }, error => {
          this.txtExcelParty = '';
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("Please select file");
    }
  }

  resUpPartyOpning;
  partyOpeningId;
  getOpeningBalance(party){
    this.partyOpeningId = party.partyId;
    this.PartyService.getPartyByOpeningBlance(this.partyOpeningId).subscribe(res => {
      this.resUpPartyOpning = res;
    })
  }
  // resUpPartyOpningUpdate;
  // getOpeningBalanceUpdate(party){
  //   // this.partyOpeningId = party.partyId;
  //   this.PartyService.getPartyByOpeningBlance(party).subscribe(res => {
  //     this.resUpPartyOpningUpdate = res;
  //     for (let cmp of this.resUpPartyOpningUpdate) {
  //       this.companyData.push({"companyId": cmp.companyId, "companyName": cmp.companyName, "rate": cmp.amount });
  //       }
  //   })
  // }

  companyOpeningData = [];
  getcompanyOpeningDetail() {
    this.companyOpeningData = [];
    for (let cmp of this.resUpPartyOpning) {
    this.companyOpeningData.push({"companyId": cmp.companyId, "companyName": cmp.companyName, "rate": cmp.amount });
    }
    this.PartyService.updateOpeningParty(this.partyOpeningId, this.companyOpeningData).subscribe(res => {
      this.resUpParty = res;
      this.clearData();
      this.companyOpeningData = [];
      this.getAllParty();
      this.toastr.success('party Balance save successfully');
      $('#CompanyOpeningModal').modal('hide');
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  resGSTNumber;
  getGSTNumber() {
    if(this.txtGstNumber.length == 15 && this.txtGstNumber != null){
      this.purchasePlanService.getGSTNumber(this.txtGstNumber).subscribe(res => {
        this.resGSTNumber = res;
        // this.txtOrganizationName = res.organizationName;
        this.txtParty = res.ownerName;
      })
    } else {
      // this.toastr.error('Please enter proper GST number.');
      // this.txtOrganizationName = null;
      // this.txtOwnerName = null;
    }
  }

  partyLedgerId;
  AddPartyLedger(party){
    this.partyLedgerId = party.partyId;
    this.getAllLedgerByPartyId();
  }
  ClearPartyLedger(){
    this.partyLedgerId = null;
    this.txtAssLibLedger = null;
  }
  allPartyLedgerName;
  getAllLedgerByPartyId(){
    this.PartyService.getAllLedgerByPartyId(this.partyLedgerId).subscribe(res => {
      this.allPartyLedgerName = res;
    })
  }
  txtAssLibLedger;
  allPartyLedger;
  createPartyLedger() {
    if (this.txtAssLibLedger == null || this.txtAssLibLedger == '' || this.txtAssLibLedger == undefined || this.txtAssLibLedger == "-:Select Ledger:-") {
      this.toastr.error("Ledger is required");
    } else {
      this.PartyService.createPartyLedger(this.partyLedgerId,this.txtAssLibLedger).subscribe(res => {
        this.allPartyLedger = res;
        this.getAllLedgerByPartyId();
        this.ClearPartyLedger();
        this.toastr.success("Ledger save successfully");
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      });
    }
  }
}
