import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DebitnoteService {

  constructor(private http: Http,private httpClient: HttpClient) { }

  createDebitNote(txtVoucherDate: Date, ReceiptData: Array<any>,txtNarration:string,companyId:Number, txttype:string, txtgst:string, txttotalAmount:number,amount:number,CreditDebitNoteData: Array<any>) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      receiptVoucherDetailDto: ReceiptData,
      narration: txtNarration,
      companyId: companyId,
      type: txttype,
      gst: txtgst,
      totalamount: txttotalAmount,
      amount:amount,
      creditDebitNoteDetailDTOs : CreditDebitNoteData
    });
    return this.http.post('/api/create/debitnote', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllDebitNote(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/debitnote', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Credit note By Id
  getAllDebitNoteById(txtId:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/debitnote/'+txtId,{headers:headers})
    .pipe(map(res => res.json()));

  }

  public downloadDebitNote(vouchermasterid: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/debit/note/` + vouchermasterid, { headers: header, responseType: 'blob' });
  }

  sendDebitEmail(id: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/server/debit/note/'+ id +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  debitNoteMailWithSendGrid(vouchermstId: string,email: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      vouchermasterid: vouchermstId,
      email: email
    });
    return this.http.post('/api/send/debitnote', body, { headers })
      .pipe(map(res => res));
  }

  //get All Debit note detail excel
  getAllDebitNoteExcel(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/excel/debitnote/detail',{headers:headers})
    .pipe(map(res => res.json()));
  }
}
