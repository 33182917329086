import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GeneralInformationService } from '../service/general-information/general-information.service';
declare var $: any;

@Component({
  selector: 'app-advertisement-msg',
  templateUrl: './advertisement-msg.component.html',
  styleUrls: ['./advertisement-msg.component.css']
})
export class AdvertisementMsgComponent implements OnInit {
  btnName ='save';
  search;
  page: number = 1;
  isAdvertisementData = false;

  txtAdvertisement;
  txtAdvertisementId;
  allAdvertisement;
  resAdvertisement;
  public loading: boolean = false;

  constructor(
    private toastr : ToastrService,
    private generalinformationService : GeneralInformationService,
  ) { }
  ngOnInit(): void {
    this.getAllAdvertisement();
  }

  getAllAdvertisement() {
    this.generalinformationService.getAllAdvertisement().subscribe(res => {
      this.allAdvertisement = res;
      if(res == null || res == [] || res.length == 0) {
        this.isAdvertisementData = false;
      } else {
        this.isAdvertisementData = true;
      }
    },error =>{
      this.isAdvertisementData = false;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.isAdvertisementData = true;
    this.txtAdvertisement = null;
    this.txtAdvertisementId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
  }

  createAdvertisement() {
    if (this.txtAdvertisement == null || this.txtAdvertisement == '' || this.txtAdvertisement == undefined || this.txtAdvertisement.trim() =='') {
      this.toastr.error("Text is required");
    } else if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.toastr.error("Image is required");
    } else {
      if (this.txtAdvertisementId == null) {
        this.generalinformationService.createAdvertisement(this.txtAdvertisement, this.txtImage)
          .subscribe(res => {
            this.resAdvertisement = res;
            console.log(this.resAdvertisement);
            this.uploadImage();
            // this.clearData();
            this.getAllAdvertisement();
            this.toastr.success('Detail save successfully');
          }, error => {
            this.toastr.error('Detail not saved');

          })
      } else {
        this.generalinformationService.updateAdvertisement(this.txtAdvertisementId, this.txtAdvertisement, this.txtImage).subscribe(res => {
          this.resAdvertisement = res;
          this.uploadImage();
          // this.clearData();
          this.getAllAdvertisement();
          this.toastr.success('Detail updated successfully');
        }, error => {
          this.toastr.error('Detail not update');
        })
      }
    }
  }

  updateAdvertisement(general) {
    this.btnName = 'Update';
    this.isAdvertisementData = false;
    this.txtAdvertisementId = general.advertisementid;
    this.txtAdvertisement = general.text;
    this.txtImage = general.image;
    this.imagePreview = general.image;
  }
  
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.imagePreview = "";
      this.getAllAdvertisement();
      this.clearData();
      // this.uploadQR();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      // data1.append('com', this.txtAdvertisementId);
      this.generalinformationService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllAdvertisement();
        this.clearData();
        // this.uploadQR();
        this.imagePreview = "";
      }, error => {
        this.imagePreview = "";
        this.clearData();
        this.getAllAdvertisement();
        // this.uploadQR();
      })
    }
  }

  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }

  whatsappAdv;
  sendMSG;
  whatsappAdvertisement(adv){
    this.generalinformationService.whatsappAdvertisement(adv.advertisementid).subscribe(res => {
      this.whatsappAdv= res;
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      for(let data of res){
        setTimeout(() => {
          this.generalinformationService.sendAdvertisementMsgAndMedia(data.mobileNumber,data.vrfmassage,data.vrfmedia).subscribe(res1 => {
            this.sendMSG = res1;
            if (res1) {
              this.hideloaderOrder();
              $(".overlay").css({"display": "none"});
              // this.toastr.success("Email send successfully");
            }
          },error=>{
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
            this.loading = false;
          })
        }, 200);
      }
    })
  }
}
