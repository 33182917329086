import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserSuccessStoryService } from '../service/user-success-story/user-success-story.service';
declare var $: any;
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-user-success-story',
  templateUrl: './user-success-story.component.html',
  styleUrls: ['./user-success-story.component.css']
})
export class UserSuccessStoryComponent implements OnInit {
  txtUserId;
  txtUserName;
  txtUserDes;
  allUserStory;
  resUserStory;
  resUserStoryUp;
  resUserStoryDel;
  btnName = 'Save';
  search;
  page: number = 1;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  color: ThemePalette = 'warn';
  isActive: boolean;

  constructor(
    private userSuccessStoryService: UserSuccessStoryService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllUserSuccesStoryWithDeactive();
  }

  getAllUserSuccesStoryWithDeactive() {
    this.userSuccessStoryService.getAllUserSuccesStoryWithDeactive().subscribe(res => {
      this.allUserStory = res;
    })
  }

  toUpperCaseUserName() {
    const temp = this.txtUserName;
    this.txtUserName = temp.toUpperCase();
    return this.txtUserName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtUserName = '';
    this.txtUserDes = '';
    this.txtUserId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
  }

  createUserStory() {
    if (this.txtUserName == null || this.txtUserName == '' || this.txtUserName == undefined) {
      this.toastr.error("User name is required");
    }else if (this.txtUserDes == null || this.txtUserDes == '' || this.txtUserDes == undefined) {
      this.toastr.error("User description is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("category image is required");
    } else {
      if (this.txtUserId == null) {
        this.userSuccessStoryService.createUserStory(this.txtUserName, this.txtUserDes)
          .subscribe(res => {
            this.resUserStory = res;
            console.log(this.resUserStory);
            this.uploadImage();
            this.clearData();
            this.getAllUserSuccesStoryWithDeactive();
            this.toastr.success('User success story save successfully');
          }, error => {
            this.toastr.error('User success story not saved');
          // let message = JSON.parse(error._body).message;
          // this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.userSuccessStoryService.updateUserStory(this.txtUserId, this.txtUserName, this.txtUserDes, this.txtImage, this.isActive).subscribe(res => {
          this.resUserStoryUp = res;
          this.uploadImage();
          this.clearData();
          this.getAllUserSuccesStoryWithDeactive();
          this.toastr.success('User success story update successfully');
        }, error => {
          this.toastr.error('User success story not updated');
          // let message = JSON.parse(error._body).message;
          // this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateUserStory(user) {
    this.btnName = 'Update';
    this.txtUserId = user.userstoryid;
    this.txtUserName = user.userName;
    this.txtUserDes = user.userDescription;
    this.txtImage = user.image;
    this.imagePreview = user.image;
    this.isActive = user.isactive;
  }

  // isChecked = true;
  // deleteAppId;
  // deleteUserStory(user) {
  //   this.deleteAppId = user.userstoryid;
  // }

  // confirmDelete() {
  //   if (this.deleteAppId != null) {
  //     this.userSuccessStoryService.deleteUserStory(this.deleteAppId)
  //       .subscribe(res => {
  //         this.resUserStoryDel = res;
  //         console.log(this.resUserStoryDel);
  //         this.clearData();
  //         this.getAllUserSuccesStory();
  //         $('#deleteModal').modal('hide');
  //         // $('.modal-backdrop').remove();
  //         this.toastr.success('user success story delete successfully');
  //       }, error => {
  //         this.toastr.error('user success story not delete');
  //       })
  //   }
  // }

  checkDisableUserStory(user) {
    if (user.isactive == false) {
      this.userSuccessStoryService.deleteUserStory(user.userstoryid).subscribe(res => {
        // this.allUserStory = res;
        // console.log(this.resDeleteOffer);
        this.getAllUserSuccesStoryWithDeactive();
        this.clearData();
        this.toastr.success('User success story deactive successfully');
      }, error => {
        this.toastr.error('User success story not deactive');
      });
    } else {
      this.userSuccessStoryService.activeUserStory(user.userstoryid).subscribe(res => {
        this.resUserStoryDel = res;
        // console.log(this.resDeleteOffer);
        this.getAllUserSuccesStoryWithDeactive();
        this.clearData();
        this.toastr.success('User success story active successfully');
      }, error => {
        this.toastr.error('User success story not active');
        // let message = JSON.parse(error._body).message;
        // this.toastr.error(JSON.stringify(message));
      });
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      // this.imagePreview = "";
      // this.getAllCategoryApp();
      // this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('name', this.txtUserName);
      this.userSuccessStoryService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllUserSuccesStoryWithDeactive();
        this.clearData();
        this.imagePreview = "";
      }, error => {
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllUserSuccesStory();
      })
    }
  }

}
