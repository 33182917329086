import { Component, OnInit } from '@angular/core';
import { ReceiptVoucherService } from "../service/receipt_voucher/receipt-voucher.service";
import { receiptVoucherDto } from '../service/receipt_voucher/receipt_voucher';
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { SaleService } from "../service/sale/sale.service";
import { searchSale } from '../service/sale/searchSale';
import { DebitnoteService } from '../service/debit/debitnote.service';
import { sale } from '../service/sale/sale';
import { Router } from '@angular/router';
import { Purchase } from '../service/purchase/purchase';
import { ItemsDetailService } from '../service/items-detail/items-detail.service';
declare var $: any;

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.css']
})
export class DebitNoteComponent implements OnInit {

  txtreceiptid;
  txtVoucherDate;
  txtselected;
  txtpartyName;
  txtsaleCode;
  txtpandingAmount;
  txtnetAmount;
  txtcredit;
  txtcompany;
  optradio;
  allCompany;
  txtRadio;
  isDisabled: boolean = true;

  allreceiptvoucher;
  btnName = 'Add';
  allReceipt;

  public searchParty = '';
  searchPartyName = [];
  public searchSale = '';
  searchsalecode = [];
  totalCredit = 0;
  txtNarration;
  txttype;
  txtgst;
  txttotalAmount = 0;

  ReceiptData: Array<receiptVoucherDto> = [];
  ReceiptIdLast: number = 0;

  datePipe = new DatePipe("en-US");
  txtquantity;
  txtrate;
  isClick: any = false;

  constructor(
    private receiptvoucherservice: ReceiptVoucherService,
    private toastr: ToastrService,
    private partyService: PartyService,
    private saleservice: SaleService,
    private debitnoteService: DebitnoteService,
    private router: Router,
    private itemsdetailservice: ItemsDetailService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy hh-mm-ss");
    this.txtVoucherDate = new Date();
    this.getAllParty();
    // this.getAllsale();
    // this.getAllPartyAccount();
    this.getAllCompany();
    this.getAllItem();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party",this.allCompany);
    })
  }

  allBank;
  getAllBank() {
    this.partyService.getAllBank(this.txtcompany.partyId).subscribe(res => {
      this.allBank = res;
      // console.log("all Bank", this.allBank);
    })
  }

  checkBank(){
    if(this.txtRadio == "Bank"){
      this.getAllBank();
    }
  }

  onItemChange(value) {
    // console.log(value);
    this.txtRadio = value;
    if (value == "Bank") {
      // console.log(value);
      this.isDisabled = false;
      if(this.txtcompany == null || this.txtcompany == "-:Select Company Name:-"){
        this.toastr.error("Please select company");
      }else{
        this.getAllBank();
      }
      
    } else {
      this.isDisabled = true;
    }
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  tempParty;
  tempCreditDebitNotePartyId;
  selectedParty(party) {
    this.tempParty = party;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    this.tempCreditDebitNotePartyId = party.partyId;
    this.getLedgerByPartyId(party.partyId);
  }

  allparty;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        temp.address = item.address;
        this.searchPartyName.push(temp)
      }
      // console.log(this.allparty);
    })
  }

  partyLedger;
  getLedgerByPartyId(partyId) {
    this.partyService.getLedgerByPartyId(partyId).subscribe(res => {
      this.partyLedger = res.ledgerName;
    })
  }

  allPartyCash;
  getAllPartyAccount() {
    this.partyService.getAllPartyAccount().subscribe(res => {
      this.allPartyCash = res;
    })
  }

  tempSale;
  selectedSaleCode(sale) {
    this.tempSale = sale;
    // console.log(this.tempSale);
    this.searchSale = sale.saleCode;
    // this.getsaleById();
  }

  allsale;
  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
      for (let item of this.allsale) {
        var temp = new searchSale();
        temp.saleCode = item.saleCode;
        temp.saleid = item.saleid;
        this.searchsalecode.push(temp)
      }
      // console.log(this.allsale);
    })
  }

  clearValue() {
    this.txtpartyName = "";
    this.txtcredit = "";
    this.searchParty = "";
    this.tempParty = "";
    this.txtNarration="";
    // this.txtVoucherDate = new Date();
    // this.txtcompany="";
    // this.optradio="";
    // this.txtselected="";
    this.btnName = "Add";
    this.txtreceiptid = null;
    $("#cashnote").prop("checked", true)
    
    this.txtrefId=null;
    this.txtparticulars=null;
    this.txtcompany="";
    this.ReceiptData=null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txttype ="";
     this.txtgst ="";
     this.txttotalAmount= 0;
     this.partyLedger = null;
     this.tempCreditDebitNotePartyId = null;
     this.txtCreditDebitNoteType = "DEBITNOTE";
     this.finalTotalAmount = 0;
    this.CreditDebitNoteData = [];
    this.isClick = false;
  }

  addDebitNote() {
    this.isClick = true;
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      this.isClick = false;
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == undefined || this.txtcompany == null || this.txtcompany == '' || this.txtcompany == "-:Select Company Name:-") {
      this.isClick = false;
      return this.toastr.error('Company must be required');
    } else if (this.txttype == undefined || this.txttype == null || this.txttype == '' || this.txttype == "-:Select Type:-") {
      this.isClick = false;
      return this.toastr.error('Type must be required');
    } else if (this.tempParty == undefined || this.tempParty == null || this.tempParty == '') {
      this.isClick = false;
      return this.toastr.error('Party Name must be required');
    } else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
      this.isClick = false;
      return this.toastr.error('Amount must be required');
    } else if (this.txtgst == undefined || this.txtgst == null || this.txtgst == '' || this.txtgst == "-:Select GST:-") {
      this.isClick = false;
      return this.toastr.error('GST must be required');
    // } else if (this.CreditDebitNoteData == null || this.CreditDebitNoteData.length == 0) {
    //   this.toastr.error("Credit Debit Note detail is required")
    // } else if(this.finalTotalAmount > this.txttotalAmount) {
    //   this.toastr.error("Total amount is more than Debit amount");
    } else {
      if (this.txtreceiptid == null) {
        var receiptDetail = new receiptVoucherDto();
        receiptDetail.id1 = this.ReceiptIdLast + 1;
        this.ReceiptIdLast = receiptDetail.id1;
        // receiptDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd hh-mm-ss');
        receiptDetail.voucherDate = this.txtVoucherDate;
        // receiptDetail.numbers = this.tempSale.saleCode;
        receiptDetail.partyId = this.tempParty.partyId;
        receiptDetail.partyName = this.tempParty.partyName;
        // receiptDetail.party = this.tempParty;
        receiptDetail.credit = this.txttotalAmount;
        // receiptDetail.billDetail = this.tempSale;
        // receiptDetail.receivedAmount = this.txtreceiveAmount;
        // receiptDetail.netAmount = this.txtnetAmount;
        receiptDetail.amount = this.txttotalAmount;
        this.totalCredit = Number(this.totalCredit) + Number(receiptDetail.credit);
        receiptDetail.ledgerId = 11;
        this.ReceiptData.push(receiptDetail);
        // console.log(this.ReceiptData);
        // this.clearValue();
      } else {
        for (let Sale of this.ReceiptData) {
          if (Sale.id1 == this.txtreceiptid) {
            // Sale.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd hh-mm-ss');
            // Sale.numbers = this.tempSale.saleCode;
            Sale.voucherDate = this.txtVoucherDate;
            Sale.partyId = this.tempParty.partyId;
            Sale.partyName = this.tempParty.partyName;
            // Sale.party = this.tempParty;
            Sale.credit = this.txtcredit;
            // Sale.billDetail = this.tempSale;
            // receiptDetail.receivedAmount = this.txtreceiveAmount;
            // Sale.netAmount = this.txtnetAmount;
            Sale.amount = this.txtcredit;
            this.getAmount();
            this.clearValue();
          }
        }
      }
    }
    this.createDebitNote();
    console.log(this.ReceiptData);
  }
  getAmount() {
    this.totalCredit = 0;
    for (let item of this.ReceiptData) {
      var sum = 0;
      sum = sum + Number(item.credit);
      this.totalCredit = this.totalCredit + sum;
    }
  }


  txtparticulars;
  txtrefId;
  receiptVoucherres;
  createDebitNote() {
    if(this.finalTotalAmount > this.txttotalAmount) {
      this.isClick = false;
      this.toastr.error("Total amount is more than debit amount");
      this.ReceiptData = [];
    // } else if (this.CreditDebitNoteData == null || this.CreditDebitNoteData.length == 0) {
    //   this.toastr.error("Debit note detail is required");
    //   this.ReceiptData = [];
    } else {
    for (let receiptDetail of this.ReceiptData) {
      receiptDetail.refId = this.txtcompany.partyId;
      receiptDetail.particulars = this.txtcompany.partyName;
      receiptDetail.ledgerId = 3;
      // receiptDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd hh-mm-ss');
      // receiptDetail.numbers = receiptDetail.numbers;
      receiptDetail.amount = receiptDetail.credit;
      if (this.txtrefId == null || this.txtrefId == "") {
        this.txtrefId = receiptDetail.partyId;
        this.txtparticulars = receiptDetail.partyName;
      } else {
        this.txtrefId = this.txtrefId + ',' + receiptDetail.partyId;
        this.txtparticulars = this.txtparticulars + ',' + receiptDetail.partyName;
      }
    }

    let receiptDetails = new receiptVoucherDto();
    receiptDetails.partyId = this.txtcompany.partyId;
    receiptDetails.partyName = this.txtcompany.partyName;
    receiptDetails.refId = this.txtrefId;
    receiptDetails.particulars = this.txtparticulars;
    // receiptDetails.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd Thh:mm:ssZ');
    receiptDetails.voucherDate = this.txtVoucherDate;
    receiptDetails.ledgerId = 43;
    if(this.txtRadio == "Bank"){
      receiptDetails.bankId = this.txtselected.bankid;
      receiptDetails.ledgerId = 7;
    }
    this.ReceiptData.push(receiptDetails);
    // console.log(this.ReceiptData);
    this.txttotalAmount = parseFloat(Number(this.txttotalAmount).toFixed(2));
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.debitnoteService.createDebitNote(this.txtVoucherDate, this.ReceiptData, this.txtNarration, this.txtcompany.partyId, this.txttype, this.txtgst, this.txttotalAmount,this.txtcredit,this.CreditDebitNoteData)
      .subscribe(res => {
        this.receiptVoucherres = res;
        // console.log(this.receiptVoucherres);
        this.clearValue();
        this.ReceiptData = [];
        this.totalCredit = 0;
        this.toastr.success('Debit note save successfully');
      }, error => {
        // this.toastr.error('Debit Note  not saved');
        if(error.status == 403){
          localStorage.removeItem('Token');
          this.router.navigate(['']);
          this.toastr.error('Please login again');
        } else {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        }
        this.ReceiptData = [];
        this.isClick = false;
      })
    }
  }

  getAllCreditNote() {
    this.receiptvoucherservice.getAllreceipt().subscribe(res => {
      this.allReceipt = res;
    })
  }

  updateDebitNote(rcp) {
    this.btnName = "Update";
    this.txtreceiptid = rcp.id1;
    this.txtVoucherDate = rcp.voucherDate;
    this.txtpartyName = rcp.partyName;
    var abc = new SearchParty();
    abc.partyId = rcp.partyId;
    abc.partyName = rcp.partyName;
    this.tempParty = abc;
    // this.tempParty.partyName = rcp.partyName;
    // this.txtpartyName = rcp.partyId;
    // this.search1 = rcp.itemName;
    this.txtcredit = rcp.credit;
  }

  i;
  deleteReceptData;
  deleteDebitNote(rcp) {
    this.deleteReceptData = rcp;
  }

  confirmDelete() {
    for (let ReceiptData of this.ReceiptData) {
      if (ReceiptData.id1 == this.deleteReceptData.id1) {
        this.i = this.deleteReceptData.id;
        let index = this.ReceiptData.indexOf(this.deleteReceptData);
        if (ReceiptData.id1 !== -1) {
          this.ReceiptData.splice(index, 1);
          this.getAmount();
        }
      }
      $('#deleteModal').modal('hide');
      // $('.modal-backdrop').remove();
    }
  }

  // resSaleById;
  // getsaleById() {
  //   this.saleservice.getsaleById(this.tempSale.saleid).subscribe(res => {
  //     this.resSaleById = res;
  //     this.txtnetAmount = this.resSaleById.netAmount;
  //   })
  // }
  temp = 0;
  calculateTotalAmount() {
    this.temp = 0 ;
    if(this.txtgst == "-:Select GST:-"){
      this.txttotalAmount = 0;
    } else {
      this.temp = parseFloat(Number(this.txtcredit * Number(this.txtgst) / 100).toFixed(2));
      this.txttotalAmount = Number(this.txtcredit) + this.temp;
    }
  }

  btnArrayName = 'Add';
  allItem;
  searchItemName = [];
  public searchItem = '';
  txtCGST;
  txtSGST;
  txtItemName;
  itemId;
  txtAmount = 0;
  txtItemGST;
  txtTotalItemAmount = 0;
  
  CreditDebitNoteData: Array<Purchase> = [];
  CreditDebitNoteIdLast: number = 0;
  txtCreditDebitNoteId;
  txtCreditDebitNoteType = "DEBITNOTE";
  finalTotalAmount = 0;
  addItemDetail() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.itemId == undefined || this.itemId == null || this.itemId == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate must be required');
    } else if (this.txtAmount == undefined || this.txtAmount == null || this.txtAmount == 0) {
      return this.toastr.error('Amount must be required');
    } else if (this.txtItemGST == undefined || this.txtItemGST == null || this.txtItemGST == "-:Select GST:-") {
      return this.toastr.error('GST Rate must be required');
    // } else if (this.txtTotalItemAmount == undefined || this.txtTotalItemAmount == null || this.txtTotalItemAmount == 0) {
    //   return this.toastr.error('Total amount must be required');
    } else {
      if (this.txtCreditDebitNoteId == null) {
        var purchaseDetail = new Purchase();
        if (this.CreditDebitNoteData.length == 0) {
          purchaseDetail.id1 = this.CreditDebitNoteIdLast;
        } else if (this.CreditDebitNoteData.length != 0) {
          purchaseDetail.id1 = this.CreditDebitNoteIdLast + 1;
        }
        this.CreditDebitNoteIdLast = purchaseDetail.id1;
        purchaseDetail.itemName = this.searchItem; //this for selected itemName
        purchaseDetail.itemId = this.itemId;
        purchaseDetail.partyId = this.tempCreditDebitNotePartyId;
        purchaseDetail.type = this.txtCreditDebitNoteType;
        purchaseDetail.quantity = this.txtquantity;
        purchaseDetail.rate = this.txtrate;
        // purchaseDetail.cgst = this.txtCGST;
        // purchaseDetail.sgst = this.txtSGST;
        purchaseDetail.cgst = parseFloat(Number(this.txtItemGST / 2).toFixed(2));
        purchaseDetail.sgst = parseFloat(Number(this.txtItemGST / 2).toFixed(2));
        purchaseDetail.totalAmount = this.txtrate * this.txtquantity;
        // purchaseDetail.totalAmount = this.txtAmount;
        //base price calculation
        // var totalpercentage = 100 + this.txtCGST + this.txtSGST;
        var baseprice = 0;
        // baseprice = parseFloat(Number(purchaseDetail.totalAmount * 100 / totalpercentage).toFixed(2));
        baseprice = parseFloat(Number(purchaseDetail.totalAmount).toFixed(2));
        var totalgstamt = 0;
        totalgstamt = parseFloat(Number(baseprice * (purchaseDetail.cgst + purchaseDetail.sgst) / 100).toFixed(2));
        // purchaseDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
        // purchaseDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
        purchaseDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        purchaseDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        // purchaseDetail.netAmount = baseprice;
        purchaseDetail.netAmount = parseFloat(Number(baseprice + totalgstamt).toFixed(2));

        var booleanValue = (this.CreditDebitNoteData).some((item) => item.itemId === this.itemId )
        if (booleanValue == true) {
          this.toastr.error("Item already exists.");
        } else {
          this.CreditDebitNoteData.push(purchaseDetail);
          this.calculateItemTotalAmount();
          this.getCreditDebitNoteAmount();
          this.clearItemGridData();
        }

      } else {
        for (let Purchase of this.CreditDebitNoteData) {
          if (Purchase.id1 == this.txtCreditDebitNoteId) {
            var booleanValue = (this.CreditDebitNoteData).some((item) => item.itemId === this.itemId && item.id1 != this.txtCreditDebitNoteId)
            if (booleanValue == true) {
              this.toastr.error("Item already exists.");
            } else {
              Purchase.itemName = this.searchItem;
              Purchase.itemId = this.itemId;
              Purchase.partyId = this.tempCreditDebitNotePartyId;
              Purchase.type = this.txtCreditDebitNoteType;
              Purchase.quantity = this.txtquantity;
              Purchase.rate = this.txtrate;
              // Purchase.cgst = this.txtCGST;
              // Purchase.sgst = this.txtSGST;
              Purchase.cgst = parseFloat(Number(this.txtItemGST / 2).toFixed(2));
              Purchase.sgst = parseFloat(Number(this.txtItemGST / 2).toFixed(2));
              // this.txtItemGST = Purchase.cgst + Purchase.sgst;
              Purchase.totalAmount = this.txtrate * this.txtquantity;
              // Purchase.totalAmount = this.txtAmount;
              //base price calculation
              // var totalpercentage = 100 + this.txtCGST + this.txtSGST;
              var baseprice = 0;
              // baseprice = parseFloat(Number(Purchase.totalAmount * 100 / totalpercentage).toFixed(2));
              baseprice = parseFloat(Number(Purchase.totalAmount).toFixed(2));
              var totalgstamt = 0;
              totalgstamt = parseFloat(Number(baseprice * (Purchase.cgst + Purchase.sgst) / 100).toFixed(2));
              // Purchase.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
              // Purchase.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
              Purchase.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
              Purchase.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
              // Purchase.netAmount = baseprice;
              Purchase.netAmount = parseFloat(Number(baseprice + totalgstamt).toFixed(2));

              this.calculateItemTotalAmount();
              this.getCreditDebitNoteAmount();
              this.clearItemGridData();
            }
          }
        }
      }
    }
    console.log(this.CreditDebitNoteData);
  }
  getCreditDebitNoteAmount() {
    this.finalTotalAmount = 0;
    for (let item of this.CreditDebitNoteData) {
      this.finalTotalAmount = this.finalTotalAmount + item.netAmount;
    }
  }

  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of res) {
        this.searchItemName.push({"itemName": item.itemName,"itemId": item.itemid,"cgst": item.gst.cgst,"sgst": item.gst.sgst})
      }
    })
  }
  selectedItem(result) {
    this.itemId = result.itemId;
    this.searchItem = result.itemName;
    this.txtCGST = result.cgst;
    this.txtSGST = result.sgst;
    this.txtItemGST = result.cgst + result.sgst;
    this.calculateItemTotalAmount();
  }

  tempGstAmount = 0;
  calculateItemTotalAmount(){
    this.tempGstAmount = 0;
    if ((this.txtAmount != null || this.txtAmount != 0) && (this.txtItemGST != null || this.txtItemGST != "") && (this.txtTotalItemAmount == null || this.txtTotalItemAmount == 0 || this.txtTotalItemAmount == undefined)) {
      // this.txtTotalItemAmount = parseFloat((this.txtAmount * this.txtItemGST).toFixed(2));
      this.tempGstAmount = parseFloat(Number(this.txtAmount * Number(this.txtItemGST) / 100).toFixed(2));
      this.txtTotalItemAmount = Number(this.txtAmount) + this.tempGstAmount;
    // } else if ((this.txtNetAmount != null && this.txtNetAmount != "") && (this.txtPrice != null && this.txtPrice != "") && (this.txtQuantity == null || this.txtQuantity == "" || this.txtQuantity == undefined)) {
    //   this.txtQuantity = parseFloat((this.txtNetAmount / this.txtPrice).toFixed(2));
    // } else if ((this.txtNetAmount != null && this.txtNetAmount != "") && (this.txtQuantity != null && this.txtQuantity != "") && (this.txtPrice == null || this.txtPrice == "" || this.txtPrice == undefined)) {
    //   this.txtPrice = parseFloat((this.txtNetAmount / this.txtQuantity).toFixed(2));
    } else {
      this.tempGstAmount = parseFloat(Number(this.txtAmount * Number(this.txtItemGST) / 100).toFixed(2));
      this.txtTotalItemAmount = Number(this.txtAmount) + this.tempGstAmount;
    }
  }

  calculateItemQtyRate(){
    if ((this.txtquantity != null || this.txtquantity != undefined) && (this.txtrate != null || this.txtrate != undefined)) {
      this.txtAmount = parseFloat((this.txtquantity * this.txtrate).toFixed(2));
      this.calculateItemTotalAmount();
    } else {
      // this.txtAmount = parseFloat((this.txtquantity * this.txtrate).toFixed(2));
    }
  }

  clearItemGridData(){
    this.btnArrayName = 'Add';
    this.txtItemName = null;
    this.itemId = null;
    this.txtAmount = 0;
    this.txtItemGST = null;
    this.txtTotalItemAmount = 0;
    this.txtCGST = null;
    this.txtSGST = null;
    this.searchItem = null;
    this.txtCreditDebitNoteId = null;
    this.txtquantity = null;
    this.txtrate = null;
  }

  updateCreditNoteItem(cdn){
    this.btnArrayName = 'Update';
    this.txtCreditDebitNoteId = cdn.id1;
    this.txtItemName = cdn.itemName;
    this.itemId = cdn.itemId;
    this.txtAmount = cdn.totalAmount;
    this.txtItemGST = cdn.cgst + cdn.sgst;
    this.txtTotalItemAmount = cdn.netAmount;
    this.txtCGST = cdn.cgst;
    this.txtSGST = cdn.sgst;
    this.txtquantity = cdn.quantity;
    this.txtrate = cdn.rate;
    this.searchItem = cdn.itemName;
  }

  ii;
  deleteCreditDebitNoteData;
  isClickDelete: any = false;
  deleteCreditNoteItem(cdn: any) {
    this.deleteCreditDebitNoteData = cdn;
  }

  confirmItemDelete() {
    this.isClickDelete = true;
    for (let PurchaseData of this.CreditDebitNoteData) {
      if (PurchaseData.id1 == this.deleteCreditDebitNoteData.id1) {
        this.ii = this.deleteCreditDebitNoteData.purchaseId;
        let index = this.CreditDebitNoteData.indexOf(this.deleteCreditDebitNoteData);
        if (PurchaseData.id1 !== -1) {
          this.CreditDebitNoteData.splice(index, 1);
          // this.clearDetail();
          this.calculateItemTotalAmount();
          this.getCreditDebitNoteAmount();
          $('#deleteItemModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.isClickDelete = false;
        }
      }
    }
  }

}
