import { Component, OnInit } from '@angular/core';
import { TransferService } from "../service/transfer/transfer.service";
import { DatePipe } from '@angular/common';
import { SaleService } from '../service/sale/sale.service';
import { GeneralInformationService } from '../service/general-information/general-information.service';

@Component({
  selector: 'app-transfer-record',
  templateUrl: './transfer-record.component.html',
  styleUrls: ['./transfer-record.component.css']
})
export class TransferRecordComponent implements OnInit {
  allTransfer;
  allTransferDetail;
  search;
  page: number = 1;
  txtFormDate;
  txtToDate;
  datePipe = new DatePipe("en-US");
  
  constructor(
    private TransferService: TransferService,
    private saleService: SaleService,
    private generalinformationService : GeneralInformationService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.getAlltransfer();
    this.getAlltransferByDate();
  }

  getAlltransfer() {
    this.TransferService.getAlltransfer()
      .subscribe(res => {
      this.allTransfer = res;
      // console.log(this.allTransfer);
    })
  }

  getAlltransferByDate() {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.TransferService.getAlltransferByDate(this.txtFormDate,this.txtToDate).subscribe(res => {
      this.allTransfer = res;
    })
  }

  getTransferDetail(transfer){
    this.allTransferDetail = transfer.transferDetails;
    // console.log(this.allTransferDetail);
  }

  whatsappsale;
  whatsappSale(res) {
    this.TransferService.whatsappSale(res.transferid).subscribe(res => {
      this.whatsappsale = res;
      this.sendRevisedBillMSG(res);
      this.sendMedia(res);
    })
  }
  
  sendMSG;
  sendRevisedBillMSG(res) {
    this.saleService.sendRevisedBillMSG(res.mobileNumber,res.vrfmassage).subscribe(res => {
      this.sendMSG = res;
    })
  }

  sendMediaLedger;
  sendMedia(res){
    this.generalinformationService.sendAdvertisementMsgAndMedia(res.mobileNumber,"",res.vrfmedia).subscribe(res2 => {
      this.sendMediaLedger = res2;
    })
  }

  resBillPdfTransfer;
  openPdfResponseTransfer;
  getTransferPDF(transfer) {
    this.TransferService.downloadTransferBill(transfer.transferid).subscribe((data) => {
      this.resBillPdfTransfer = data;

      var newBlob1 = new Blob([this.resBillPdfTransfer], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_Bij_Bhandar.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseTransfer.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfTransfer = '';
      this.openPdfResponseTransfer = '';
    })
  }

  allPDFInvoice;
  transferNumber;
  getcompnayOrganizationPDF(sale) {
    this.transferNumber = sale.transferNumber;
    this.TransferService.getTransferPDFCategoryNoOfInvoiceData(sale.transferid).subscribe(res => {
      // console.log(res);
      // this.allPDFInvoice = res;
      this.allPDFInvoice = JSON.parse(res);
    },error => {
      this.allPDFInvoice = null;
    })
  }
  downloadTransferCategoryWiseBill(company) {
    this.TransferService.downloadTransferCategoryWiseBill(company.invoiceid,this.transferNumber,company.companyName).subscribe((data) => {
      this.resBillPdfTransfer = data;

      var newBlob1 = new Blob([this.resBillPdfTransfer], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "transfer_categoryPDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseTransfer.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfTransfer = '';
      this.openPdfResponseTransfer = '';
    })
  }

}
