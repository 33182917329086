import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../service/excel/excel.service';
import { GroupMasterPartyService } from '../service/group-master-party/group-master-party.service';
import { StockService } from '../service/stock/stock.service';
import { CompanyService } from '../service/company/company.service';
declare var $: any;

@Component({
  selector: 'app-party-closing-report',
  templateUrl: './party-closing-report.component.html',
  styleUrls: ['./party-closing-report.component.css']
})
export class PartyClosingReportComponent implements OnInit {
  allPartyClosingData;
  search;
  page: number = 1;
  datePipe = new DatePipe("en-US");

  address;
  public loading: boolean = false;

  constructor(
    private toastr: ToastrService,
    private stockservice: StockService,
    private excelservice: ExcelService,
    private groupMasterPartyService: GroupMasterPartyService,
    private companyService: CompanyService,
  ) { }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // txtStartDate = "2021-04-01";
  txtStartDate;
  txtEndDate;
  ngOnInit(): void {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtStartDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtEndDate = new Date();
    this.getAllCompany();
    this.getAllPartyClosingReport();
    this.getAllGroup();
  }

  txtCompany = "All";
  allCompany;
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  partyOpeningBalance = 0;
  txtCredit;
  txtDebit;
  txtClosingBalance = 0;
  tempClosingBalance;
  partyReportData = [];
  getAllPartyClosingReport() {
    this.loading = true;
    $(".overlay").css({"display": "block", "opacity": "0.7"});
    $(".loader").css({"display": "block"});
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    this.stockservice.getAllPartyClosingReportByCompany(this.txtStartDate, this.txtEndDate,this.txtCompany).subscribe(res => {
      this.allPartyClosingData = res;
      console.log(this.allPartyClosingData);
      // if (this.allPartyClosingData.openingBalance == null) {
      //   this.partyOpeningBalance = 0;
      //   this.allPartyClosingData.openingBalance = 0;
      // } else {
      //   this.partyOpeningBalance = this.allPartyClosingData.openingBalance;
      //   console.log(this.partyOpeningBalance);
      // } 
      // this.tempClosingBalance = this.txtCredit - this.txtDebit;
      // if(this.tempPartyopeningBalance < 0 ){
      // this.txtClosingBalance = this.tempClosingBalance + this.partyOpeningBalance;
      // }
      this.partyReportData = [];
      for (let ii = 0; ii < this.allPartyClosingData.length; ii++) {
        this.txtCredit = this.allPartyClosingData[ii].credit;
        this.txtDebit = this.allPartyClosingData[ii].debit;
        this.txtClosingBalance = this.allPartyClosingData[ii].credit - this.allPartyClosingData[ii].debit + this.allPartyClosingData[ii].openingBalance;

        // this.address = this.allPartyClosingData[ii].address;

        // console.log("particular" + ii);
        this.partyReportData.push({
          "partyName": this.allPartyClosingData[ii].partyName, "address": this.allPartyClosingData[ii].address, "debit": this.allPartyClosingData[ii].debit
          , "credit": this.allPartyClosingData[ii].credit, "openingBalance": this.allPartyClosingData[ii].openingBalance, "closingBalance": this.txtClosingBalance,
        })
        console.log(this.partyReportData);
      }
      if (res) {
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
      }
    }, error => {
      // this.toastr.error('Purchase not save');
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
      this.hideloaderOrder();
      $(".overlay").css({"display": "none"});
      this.loading = false;
    })
  }

  ExcelData = [];
  exportAsXLSX(): void {
    this.ExcelData = [];
    if (this.search == "" || this.search == null || this.search == undefined) {
      for (let excel of this.partyReportData) {
        this.ExcelData.push({
          "partyName": excel.partyName, "address": excel.address, "openingBalance": excel.openingBalance, "debit": excel.debit
          , "credit": excel.credit, "closingBalance": excel.closingBalance,
        })
      }
    } else {
      for (let excel of this.searchArray) {
        this.ExcelData.push({
          "partyName": excel.partyName, "address": excel.address, "openingBalance": excel.openingBalance, "debit": excel.debit
          , "credit": excel.credit, "closingBalance": excel.closingBalance,
        })
      }
    }
    this.excelservice.exportExcelPartyClosingData("party_Closing_Report", this.ExcelData);
  }

  searchArray = [];
  calculateNetAmount() {
    this.searchArray = [];
    if (this.search == "" || this.search == null || this.search == undefined) {
      this.partyReportData = [];
      for (let ii = 0; ii < this.allPartyClosingData.length; ii++) {
        this.txtCredit = this.allPartyClosingData[ii].credit;
        this.txtDebit = this.allPartyClosingData[ii].debit;
        this.txtClosingBalance = this.allPartyClosingData[ii].credit - this.allPartyClosingData[ii].debit + this.allPartyClosingData[ii].openingBalance;

        this.partyReportData.push({
          "partyName": this.allPartyClosingData[ii].partyName, "address": this.allPartyClosingData[ii].address, "debit": this.allPartyClosingData[ii].debit
          , "credit": this.allPartyClosingData[ii].credit, "openingBalance": this.allPartyClosingData[ii].openingBalance, "closingBalance": this.txtClosingBalance,
        })
      }
    } else {
      for (let ii = 0; ii < this.allPartyClosingData.length; ii++) {
        if (this.allPartyClosingData[ii].address == null || this.allPartyClosingData[ii].address == "") {
          this.address = "";
        } else {
          this.address = this.allPartyClosingData[ii].address;
        }
        if (this.search.toLowerCase() === this.address.toLowerCase()) {
          this.txtCredit = this.allPartyClosingData[ii].credit;
          this.txtDebit = this.allPartyClosingData[ii].debit;
          this.txtClosingBalance = this.allPartyClosingData[ii].credit - this.allPartyClosingData[ii].debit + this.allPartyClosingData[ii].openingBalance;

          this.searchArray.push({
            "partyName": this.allPartyClosingData[ii].partyName, "address": this.allPartyClosingData[ii].address, "debit": this.allPartyClosingData[ii].debit
            , "credit": this.allPartyClosingData[ii].credit, "openingBalance": this.allPartyClosingData[ii].openingBalance, "closingBalance": this.txtClosingBalance,
          })
        }
      }
    }
  }
  
  txtGroupName;
  allGroup;
  getAllGroup() {
    this.groupMasterPartyService.getAllGroup().subscribe(res => {
      this.allGroup = res;
    })
  }

  resClosingBalance;
  txtFormDate;
  txtToDate;
  getPartyWhatsaapMessage() {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtFormDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
    this.txtToDate = this.datePipe.transform(new Date() , 'yyyy-MM-dd');
    this.groupMasterPartyService.getPartyWhatsaapMessage(this.txtGroupName,this.txtFormDate,this.txtToDate).subscribe(res => {
      this.resClosingBalance = res;
    });
  }
}
