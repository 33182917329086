import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SmsIntegrationService } from '../service/sms_integration/sms-integration.service';
declare var $: any;

@Component({
  selector: 'app-sms-integration',
  templateUrl: './sms-integration.component.html',
  styleUrls: ['./sms-integration.component.css']
})
export class SmsIntegrationComponent implements OnInit {
  resSms;
  resUpSms;
  resDelSms;
  txtSmsId;
  txtSmsType;
  txtTemplateId;
  txtMessage;
  txtOrganizationName;
  allSMS;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private toastr: ToastrService, 
    private smsIntegrationService: SmsIntegrationService,
  ) { }

  ngOnInit(): void {
    this.getAllSMS();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllSMS() {
    this.smsIntegrationService.getAllSMS().subscribe(res => {
      this.allSMS = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtSmsType = null;
    this.txtTemplateId = null;
    this.txtMessage = null;
    this.txtSmsId = null;
    this.txtOrganizationName = null;
  }
  createSMS() {
    var templateId = this.txtTemplateId;
    var message = this.txtMessage;
    var organizationName = this.txtOrganizationName;
    if (this.txtSmsType == null || this.txtSmsType == '' || this.txtSmsType == undefined) {
      this.toastr.error("SMS Type is required");
    } else if (this.txtTemplateId == null || this.txtTemplateId == '' || this.txtTemplateId == undefined || (this.txtTemplateId = templateId.trim()) == "") {
      this.toastr.error("Template Id is required");
    } else if (this.txtMessage == null || this.txtMessage == '' || this.txtMessage == undefined || (this.txtMessage = message.trim()) == "") {
      this.toastr.error("Message is required");
    } else if (this.txtOrganizationName == null || this.txtOrganizationName == '' || this.txtOrganizationName == undefined || (this.txtOrganizationName = organizationName.trim()) == "") {
      this.toastr.error("organization name is required");
    } else {
      if (this.txtSmsId == null) {
        this.smsIntegrationService.createSMS(this.txtSmsType, this.txtTemplateId, this.txtMessage, this.txtOrganizationName)
          .subscribe(res => {
            this.resSms = res;
            this.clearData();
            this.getAllSMS();
            this.toastr.success('SMS save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.smsIntegrationService.updateSMS(this.txtSmsId, this.txtSmsType, this.txtTemplateId, this.txtMessage, this.txtOrganizationName).subscribe(res => {
          this.resUpSms = res;
          this.clearData();
          this.getAllSMS();
          this.toastr.success('SMS updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateSMS(sms) {
    this.btnName = 'Update';
    this.txtSmsId = sms.msgId;
    this.txtSmsType = sms.type;
    this.txtTemplateId = sms.templateId;
    this.txtMessage = sms.message;
    this.txtOrganizationName = sms.organizationName;
  }

  deleteid;
  deleteSMS(sms) {
    this.deleteid = sms.planuserId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.smsIntegrationService.deleteSMS(this.deleteid)
        .subscribe(res => {
          this.resDelSms = res;
          this.clearData();
          this.getAllSMS();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('SMS deleted successfully');
        }, error => {
          this.toastr.error('SMS not deleted');
        })
    }
  }
}
