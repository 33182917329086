import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: Http,
  ) { }

  createUser(txtfirstName: string, txtlastName: string, txtEmail: string, txtpassword: string, txtLocation: number, txtState: number, txtStreet: string, txtCity: string, txtArea: string, txtPincode: string, txtuserName: string, txtContactNo: string, txtRole: Array<any>) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      firstname: txtfirstName,
      lastname: txtlastName,
      email: txtEmail,
      password: txtpassword,
      location: txtLocation,
      state: txtState,
      street: txtStreet,
      city: txtCity,
      area: txtArea,
      pincode: txtPincode,
      username: txtuserName,
      contactno: txtContactNo,
      roles: txtRole,
    });
    return this.http.post('/api/user/add', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  updateUser(txtUserId: string, txtfirstName: string, txtlastName: string, txtEmail: string, txtLocation: string, txtState: string, txtStreet: string, txtCity: string, txtArea: string, txtPincode: string, txtuserName: string, txtContactNo: string, txtRole: Array<any>) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userid: txtUserId,
      firstname: txtfirstName,
      lastname: txtlastName,
      email: txtEmail,
      // password: txtpassword,
      location: txtLocation,
      state: txtState,
      street: txtStreet,
      city: txtCity,
      area: txtArea,
      pincode: txtPincode,
      username: txtuserName,
      contactno: txtContactNo,
      roles: txtRole,
    });
    return this.http.put('/api/user/update', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllUser() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/user/get/all', { headers })
      .pipe(map(res => res.json()));
  }

  getLoginUser() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/user/get/loginuser', { headers })
      .pipe(map(res => res.json()));
  }

  changePassword(txtPassword: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      password: txtPassword,
    });

    return this.http.put('/api/changepassword', body, { headers })
      .pipe(map(res => res));
  }

  deleteUser(txtUserId: string, txtRole: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userid: txtUserId,
    });
    return this.http.delete('/api/user/delete/' + txtUserId + '/' + txtRole, { headers })
      .pipe(map(res => res.json()));
  }

  getAllUserInquiry() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/inquiry', { headers })
      .pipe(map(res => res.json()));
  }
}
