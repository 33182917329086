import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BankBookService {

  constructor(
    private http: Http,
  ) { }

  getBankBookDetail(ledgerid: string, companuyorgid: string, bankid: string, startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/bankbook/ledger/' + ledgerid + '/company/' + companuyorgid + '/bank/' + bankid + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  getBankOpeningBalance(bankid:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/bank/' + bankid , { headers })
      .pipe(map(res => res.json()));
  }

  getBankOpeningBalanceByMonth(startdate,enddate,bankid:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/bankbook/date/' + startdate + '/' + enddate + '/bank/' + bankid , { headers })
      .pipe(map(res => res.json()));
  }

}
