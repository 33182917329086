import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: Http,
  ) { }

  createRole(txtroleName: string, txtDescription: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name : txtroleName,
      description : txtDescription,
    });
    return this.http.post('/api/role/add', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateRole(txtroleId: string, txtroleName: string, txtDescription: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      roleid : txtroleId,
      name : txtroleName,
      description : txtDescription,
    });
    return this.http.put('/api/role/update', body, { headers })
      .pipe(map(res => res.json()));
  }

  deleteRole(txtroleId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   roleid: txtroleId,
    // });
    return this.http.delete('/api/role/delete/' + txtroleId, { headers : headers})
      .pipe(map(res => res.json()));
  }

  getAllRole(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/role/get/all', { headers })
      .pipe(map(res => res.json()));

  }
}
