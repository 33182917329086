import { Party } from "../party/party";
import { billDto } from "./billDto";

export class receiptVoucherDto {
    id1: number;
    voucherDate: Date;
    partyId: number;
    credit: number;
    status: string;
    partyName: string;
    // party:Party;
    refId: string;
    particulars: string;
    receivedAmount: number;
    netAmount: number;
    billDetail: billDto;
    numbers: string;
    amount: number;
    ledgerId:number;
    bankId: number;

}