import { Component, OnInit } from '@angular/core';
import { PurchaseService } from "../service/purchase/purchase.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
import { Purchase } from "../service/purchase/purchase";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { StockAdjustmentService } from '../service/stock_adjustment/stock-adjustment.service';
import { ExcelService } from '../service/excel/excel.service';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-stock-adjustment',
  templateUrl: './stock-adjustment.component.html',
  styleUrls: ['./stock-adjustment.component.css']
})
export class StockAdjustmentComponent implements OnInit {
  itemId;
  txtStockAdjustmentId;
  txtManufactureDate;
  txtItemName;
  txtItemNameSearch;
  txtBatchNumber;
  txtquantity;
  txtrate;
  txtExpiryDate;
  resUpStockAdjust;
  resStockAdjust;
  resDelStockadjust;
  resitemValue;

  allStockAdjustment;
  btnName = 'save';
  allItem;
  searchItemName = [];
  public search1 = '';

  searchBatchNo = [];
  public search2 = '';

  datePipe = new DatePipe("en-US");
  search;
  page: number = 1;
  constructor(
    private itemsdetailservice: ItemsDetailService,
    private stockadjustmentservice: StockAdjustmentService,
    private toastr: ToastrService,
    private excelservice: ExcelService,

  ) { }

  ngOnInit(): void {
    this.txtExpiryDate = new Date();
    this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllStockAdjustment();
  }

  clearValue() {
    this.itemId = "";
    this.txtItemName = "";
    this.txtManufactureDate = new Date();
    this.txtBatchNumber = "";
    this.txtquantity = "";
    this.txtrate = "";
    this.txtExpiryDate = new Date();
    this.search1 = "";
    this.search2 = "";
    this.btnName = "save";
    this.txtStockAdjustmentId = null;
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
    // Allow only Numbers and minus(-)
    numberandMinusOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keycode;
      if (charCode == 45) {
        return true;
      } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      } else {
        return true;
      }
    }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  selectedStatic(result) {
    this.itemId = result.itemid;
    this.search1 = result.itemName;
    this.getStockAdjustmentByItemId();
  }

  selectedStaticItem(result){
    this.txtItemNameSearch = result.itemName;
  }

  itemArray = [];
  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      // for (let item of this.allItem) {
      //   var temp = new SearchItemDtos();
      //   temp.itemName = item.itemName;
      //   temp.itemId = item.itemid;
      //   this.searchItemName.push(temp)
      // }
      for (let item of this.allItem) {
        // this.itemArray.push(item.itemName);
        this.itemArray.push({"Item":item.itemName});
      }
    })
  }

  searchByItemName(){
    this.stockadjustmentservice.getStockAdjustMentDataByItemName(this.txtItemNameSearch).subscribe(res => {
      this.allStockAdjustment = res;
    })
  }

  searchBoxClear(){
    this.txtItemNameSearch= '';
    this.getAllStockAdjustment();
  }

  resStockAdjustmentBatchNo;
  getStockAdjustmentByItemId() {
    // console.log("item Name",this.search1);
    // console.log("item Name",this.itemId);
    this.stockadjustmentservice.getStockAdjustmentByItemId(this.itemId)
      .subscribe(res => {
        this.resStockAdjustmentBatchNo = res;
        // console.log(this.resStockAdjustmentBatchNo);
      })
  }

  selectedBatchNo(result) {
    this.txtBatchNumber = result.batchnumber;
    this.txtBatchNumber = this.txtBatchNumber.toUpperCase();
    this.setExpiryDateAndManuDate();
    this.itemAndBatchNumberValue();
    return this.txtBatchNumber;
  }
  toUpperCaseBatchNo(){
    var temp = this.txtBatchNumber;
    this.txtBatchNumber = temp.toUpperCase();
    return this.txtBatchNumber;
  }

  itemAndBatchNumberValue() {
    this.stockadjustmentservice.itemAndBatchNumberValue(this.itemId, this.txtBatchNumber)
      .subscribe(res => {
        this.resitemValue = res;
        this.txtrate = this.resitemValue.rate;
        // console.log(this.resitemValue);
      })
  }

  setExpiryDateAndManuDate() {
    for (let batchno of this.resStockAdjustmentBatchNo) {
      if (this.txtBatchNumber == batchno.batchnumber) {
        this.txtExpiryDate = batchno.expirydate;
        this.txtManufactureDate = batchno.manufacturedate;
      }
    }
  }

  createStockAdjustment() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error(' Item Name is required');
    } else if (this.txtBatchNumber == undefined || this.txtBatchNumber == null || this.txtBatchNumber == 0) {
      return this.toastr.error('Batch number is required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity is required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate is required');
    } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      return this.toastr.error('Manufacture Date is required');
    } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      return this.toastr.error('Expiry Date is required');
    } else {
      if (this.txtStockAdjustmentId == null) {
        if (this.search2 == "" || this.search2 == null || this.search2 == undefined) {
          this.search2 = this.txtBatchNumber;
        }
        this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "yyyy-MM-dd");
        this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "yyyy-MM-dd");
        this.stockadjustmentservice.createStockAdjustment(this.search1, this.search2, this.txtquantity, this.txtrate, this.txtManufactureDate, this.txtExpiryDate)
          .subscribe(res => {
            this.resStockAdjust = res;
            // console.log(this.resStockAdjust);
            this.clearValue();
            // this.getAllItem();
            this.getAllStockAdjustment();
            this.toastr.success('Stock adjustment save successfully');
          }, error => {
            this.toastr.error('Stock adjustment not save');
          })
      }
    }
  }

  getAllStockAdjustment() {
    this.stockadjustmentservice.getAllStockAdjustment().subscribe(res => {
      this.allStockAdjustment = res;
    })
  }

  // Stock-Adjustment Excel
  txtExcelStockAdjustment;
  ExcelDataStockAdjustment = [];
  fileNameStockAdjustment;
  selectedExcelStockAdjustment;
  stockAdjustmentData;
  exportExcelStockAdjustment(): void {
    this.ExcelDataStockAdjustment = [];
    this.fileNameStockAdjustment = "stockAdjustment";
    this.excelservice.exportExcelStockAdjustment(this.fileNameStockAdjustment,this.ExcelDataStockAdjustment,this.itemArray);
  }

  onExcelUploadStockAdjustment(event){
    this.selectedExcelStockAdjustment = <File>event.target.files[0];
  }
  importandConvertExcelStockAdjustment(){
    if (this.selectedExcelStockAdjustment) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelStockAdjustment);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var stockAdjustment = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[stockAdjustment]);
        this.stockAdjustmentData = rowObject;
        var resStockAdjustment = [];
        for(let data of this.stockAdjustmentData){
          var temexprdate = (data["Expiry Date"] - 25569) * 24 * 60 * 60 * 1000;
          var cvrtExpiry = this.datePipe.transform(temexprdate,"yyyy-MM-dd")
          var temmanufactDate = (data["Manufacture Date"] - 25569) * 24 * 60 * 60 * 1000;
          var cnvrtmanufctDate = this.datePipe.transform(temmanufactDate,"yyyy-MM-dd")
          resStockAdjustment.push({"itemName":data["Item Name"],"batchNumber":data["Batch Number"],"quantity":data["Quantity"],
          "rate":data["Rate"],"manufactureDate":cnvrtmanufctDate,"expiryDate":cvrtExpiry});
        }
        
        //save excel data inot server
        this.stockadjustmentservice.importExcelStockAdjustment(resStockAdjustment).subscribe(res => {
          this.toastr.success("Stock adjustment save successfully");
          this.selectedExcelStockAdjustment = '';
          this.txtExcelStockAdjustment = '';
          this.getAllStockAdjustment();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.txtExcelStockAdjustment = '';
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }
}