export class saleReturn{
    id1: number;
    manufactureDate: string;
    itemId: string;
    itemName: string;
    categoryName: string;
    subCategoryName: string;
    brandName: string;
    quantity: number;
    rate: number;
    expiryDate: string;
    cgst:number;
    cgstAmount:number;
    sgst:number;
    sgstAmount:number;
    saleid: string;
    batchNumber: string;
    totalAmount: number;
    netAmount: number;
    salenumber: string;
}