<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Purchase Record</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-lg-2 col-xl-2 col-6 bottom_margin">
                                    <!-- <label for="country" class="form-label">From <span
                                            style="color: red;">&nbsp;*</span></label> -->
                                    <input (click)="pickerFrom.open()" matInput [matDatepicker]="pickerFrom"
                                        class="form-control" [(ngModel)]="txtFormDate" name="txtFormDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerFrom" style="display: none;">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerFrom></mat-datepicker>
                                    <div class="invalid-feedback">
                                        Please enter a Form Date.
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xl-2 col-6 bottom_margin">
                                    <!-- <label for="country" class="form-label">To <span
                                            style="color: red;">&nbsp;*</span></label> -->
                                    <input (click)="pickerTO.open()" matInput [matDatepicker]="pickerTO" [min]="txtFormDate"
                                        class="form-control" [(ngModel)]="txtToDate" name="txtToDate">
                                    <mat-datepicker-toggle matSuffix [for]="pickerTO" style="display: none;">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerTO></mat-datepicker>
                                    <div class="invalid-feedback">
                                        Please enter a To Date.
                                    </div>
                                </div>
                                <div class="col-lg-1 col-xl-2 col-4 btn-container-end bottom_margin">
                                    <button class="btn btn-primary d-block" type="submit" (click)="getAllPurchaseByDate()">Search</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Purchase Date</th>
                                            <th scope="col">Purchase Number</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col">Party Invoice No.</th>
                                            <th scope="col" class="text-center">Amount</th>
                                            <th scope="col" class="text-center">GST Amount</th>
                                            <th scope="col" class="text-center">Discount</th>
                                            <th scope="col" class="text-center">Net Amount</th>
                                            <th scope="col" class="text-center">Type</th>
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let purchase of allPurchase | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>{{purchase.purchaseDate | date :'dd-MMM-yyyy'}}</td>
                                            <td>{{purchase.purchaseNumber}}</td>
                                            <td>{{purchase.party.partyName}}</td>
                                            <td>{{purchase.partyinvoicenumber}}</td>
                                            <td class="text-end">{{purchase.amount | appendIND}}</td>
                                            <td class="text-end">{{purchase.gstAmount | appendIND}}</td>
                                            <td class="text-end">{{purchase.discount | appendIND}}</td>
                                            <!-- <td class="text-end">{{purchase.netAmount | appendIND}}</td> -->
                                            <td class="text-end">{{purchase.finalAmount | appendIND}}</td>
                                            <td class="text-end">{{purchase.type}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm me-2"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                    (click)="getPurchaseDetail(purchase)" data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal">
                                                    <i class="fas fa-history"></i>
                                                </button>
                                                <button *ngIf="userRole" type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deletePurchase(purchase)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allPurchase < 1 ">
                                            <td colspan="10" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                        <!-- <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item active" aria-current="page">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Purchase Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                              <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">SubCategory</th>
                                                        <th scope="col" >Quantity</th>
                                                        <th scope="col" class="text-center">Rate</th>
                                                        <!-- <th scope="col">Manufacture Date</th>
                                                    <th scope="col">Expiry Date</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let purchasedtl of allPurchaseDetail">
                                                        <td>{{purchasedtl.item.itemName}}</td>
                                                        <td>{{purchasedtl.item.category.categoryName}}</td>
                                                        <td>{{purchasedtl.item.subCategory.subCategoryName}}</td>
                                                        <td>{{purchasedtl.quantity}}</td>
                                                        <td class="text-end">{{purchasedtl.rate | appendIND}}</td>
                                                        <!-- <td>{{saledtl.party.partyName}}</td>
                                                    <td>{{saledtl.party.partyName}}</td> -->
                                                        <!-- <td>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                            (click)="getSaleDetail(sale)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            <i class="fas fa-history"></i>
                                                        </button>
                                                    </td> -->
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allPurchase < 1 ">
                                                        <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>