import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CashToBankService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }
  createCashToBankVoucher(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number,txtchequeNo: number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId,
      chequeNo: txtchequeNo,
    });
    return this.http.post('/api/create/contravoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllCashToBankVoucher(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/contravoucher', { headers })
      .pipe(map(res => res.json()));
  }

  public downloadPaymentVoucher(vouchermasterid: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/payment/voucher/` + vouchermasterid, { headers: header, responseType: 'blob' });
  }

  getAllCashToBankById(contravoucherid:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/contravoucher/'+contravoucherid,{headers:headers})
    .pipe(map(res => res.json()));

  }

  createBankToBankVoucher(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number,txtchequeNo: number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId,
      chequeNo: txtchequeNo,
    });
    return this.http.post('/api/create/contrabankvoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllBankToBankVoucher(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/contrabankvoucher', { headers })
      .pipe(map(res => res.json()));
  }

}
