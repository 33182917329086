import { Component, OnInit } from '@angular/core';
import { GroupService } from "../service/group/group.service";
import { BalancesheetService } from "../service/balancesheet/balancesheet.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {

  resBalance = [];
  txtAccountName;
  txtBalance;
  txtgroupid;
  allBalance;
  allgroup;
  resGroup;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(private groupService: GroupService,
    private balancesheetService: BalancesheetService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getAllBalance();
    this.getAllGroup();
  }

  getAllBalance() {
    this.balancesheetService.getAllBalance().subscribe(res => {
      this.allBalance = res;
    })
  }

  getAllGroup() {
    this.groupService.getAllGroup().subscribe(res => {
      this.allgroup = res;
    })
  }

  toUpperCaseGroup() {
    const temp = this.txtAccountName;
    this.txtAccountName = temp.toUpperCase();
    return this.txtAccountName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtAccountName = '';
    this.txtgroupid = null;
    this.txtBalance = '';
  }

  createGroup() {
    if (this.txtBalance == null || this.txtBalance == '' || this.txtBalance == undefined|| this.txtBalance == "-:Select Balance Sheet Name:-") {
      return this.toastr.error("Balance sheet is required");
    } else if (this.txtAccountName == undefined || this.txtAccountName == null || this.txtAccountName == '') {
      return this.toastr.error('Account name is required');
    } else {
      if (this.txtgroupid == null) {
        this.groupService.createGroup(this.txtBalance, this.txtAccountName)
          .subscribe(res => {
            this.resGroup = res;
            this.clearData();
            this.getAllGroup();
            this.toastr.success('Group save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.groupService.updateGroup(this.txtgroupid, this.txtAccountName, this.txtBalance).subscribe(res => {
          this.resGroup = res;
          this.clearData();
          this.getAllGroup();
          this.toastr.success('Group updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatesubcategory(group) {
    this.btnName = 'Update';
    this.txtgroupid = group.glaccountId;
    this.txtBalance = group.balanceSheetType.balancesheetName;
    this.txtAccountName = group.glaccountName;

  }

  deleteid;
  deleteData(group) {
    this.deleteid = group.glaccountId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.groupService.deletGroup(this.deleteid).subscribe(res => {
        this.resGroup = res;
        this.clearData();
        this.getAllGroup();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Group deleted successfully');
      }, error => {
        this.toastr.error('Group not deleted');
      })
    }
  }
}
