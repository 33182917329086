<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Trial Balance Report</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-2 col-xl-2 col-md-3 col-6 bottom_margin">
                                            <label for="country" class="form-label">Start Date<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="pickerFrom.open()" matInput [matDatepicker]="pickerFrom"
                                                class="form-control" [(ngModel)]="txtStartDate" name="txtStartDate">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFrom" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pickerFrom></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-md-3 col-6 bottom_margin">
                                            <label for="country" class="form-label">End Date <span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="pickerTO.open()" matInput [matDatepicker]="pickerTO"
                                                class="form-control" [(ngModel)]="txtEndDate" name="txtEndDate">
                                            <mat-datepicker-toggle matSuffix [for]="pickerTO" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pickerTO></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="company" class="form-label">Company Name</label>
                                            <select class="form-select" id="company" [(ngModel)]="txtCompany" name="txtCompany">
                                                <!-- <option>All</option> -->
                                                <option *ngFor="let company of allCompany" [value]="company.companyId">{{company.companyName}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="getAllTrialBalance()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Trial Balance Report</div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <!-- <div class="col-md-6">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Excel</button>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Group Name</th>
                                            <th scope="col">Debit</th>
                                            <th scope="col">Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let trial of alltrialbalance | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td>
                                                <a href="javascript:void(0)" title="Details" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    (click)="getTrialBalanceDetail(trial)" data-bs-toggle="modal" style="color: blue;"
                                                    data-bs-target="#exampleModal">{{trial.groupName}}
                                                </a>
                                            </td>
                                            <td class="text-end">{{trial.credit | appendIND}}</td>
                                            <td class="text-end">{{trial.debit | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td><b>Total</b></td>
                                            <td class="text-end"><b>{{totalCreditAmount | appendIND}}</b></td>
                                            <td class="text-end"><b>{{totalDebitAmount | appendIND}}</b></td>
                                        </tr>
                                        <!-- <tr *ngIf="allItemStock < 1 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr> -->
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event"
                                                (pageBoundsCorrection)="page=($event)" responsive="true">
                                            </pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">{{groupName}}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Ledger Name</th>
                                                        <th scope="col">Party Name</th>
                                                        <th scope="col">Credit</th>
                                                        <th scope="col">Debit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let detail of allDetailData  | paginate: { itemsPerPage: 5, currentPage: page1, id : 'dtlHistory'}">
                                                        <td>{{detail.ledgername}}</td>
                                                        <td>{{detail.partyName}}</td>
                                                        <td>{{detail.credit | appendIND}}</td>
                                                        <td>{{detail.debit | appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr *ngIf="allDetailData < 1 ">
                                                        <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-end">
                                            <nav aria-label="Table pagination">
                                                <ul class="pagination">
                                                    <li>
                                                        <pagination-controls (pageChange)="page1 = $event"
                                                            (pageBoundsCorrection)="page1=($event)" responsive="true" id = "dtlHistory">
                                                        </pagination-controls>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>