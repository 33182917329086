import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(
    private http: Http,
  ) { }

  addPurchaseDetail(txtPurchaseDate: string, txtpurchaseCode: string, txtpartyName: string, txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, Purchase: Array<any>, txtDueDays: String, txtDueDate: String, txtPartyInvoiceNo: string, txtNarration: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      purchaseDate: txtPurchaseDate,
      purchaseNumber: txtpurchaseCode,
      partyId: txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      purchaseDetailDTOs: Purchase,
      dueDays: txtDueDays,
      dueDate: txtDueDate,
      type: "CREDIT",
      partyinvoicenumber: txtPartyInvoiceNo,
      narration: txtNarration
    });
    return this.http.post('/api/create/purchase', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  addCashPurchaseDetail(txtPurchaseDate: string, txtpurchaseCode: string, txtpartyName: string, txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, Purchase: Array<any>, txtDueDays: String, txtDueDate: String, txtPartyInvoiceNo: string, txtNarration: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      purchaseDate: txtPurchaseDate,
      purchaseNumber: txtpurchaseCode,
      partyId: txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      purchaseDetailDTOs: Purchase,
      dueDays: txtDueDays,
      dueDate: txtDueDate,
      type: "CASH",
      partyinvoicenumber: txtPartyInvoiceNo,
      narration: txtNarration
    });
    return this.http.post('/api/create/purchase', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllPurchase() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/purchase', { headers })
      .pipe(map(res => res.json()));
  }

  getPurchaseNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchasenumber', { headers })
      .pipe(map(res => res.json()));
  }

  getAllPurchaseByLocationAndDate(startDate:string, endDate:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/purchase/date/'+startDate+'/'+endDate, { headers })
      .pipe(map(res => res.json()));
  }
  
  searchGstrSaleNigamDetail(companyid: string, selectMonth: string, selectyear: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/sale/nigam/' + companyid + '/month/' + selectMonth + '/year/' + selectyear, { headers })
      .pipe(map(res => res.json()));
  }

  searchGstrSaleBhandarDetail(companyid: string, selectMonth: string, selectyear: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/sale/bhandar/' + companyid + '/month/' + selectMonth + '/year/' + selectyear, { headers })
      .pipe(map(res => res.json()));
  }

  searchB2CSDetail(companyid: string, month: string, year: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/b2cs/sale/' + companyid + '/month/' + month + '/year/' + year, { headers })
      .pipe(map(res => res.json()));
  }

  searchHSNDetail(companyid: string, month: string, year: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/hsncode/' + companyid + '/month/' + month + '/year/' + year, { headers })
      .pipe(map(res => res.json()));
  }

  searchB2CSMasterDetail(companyid: string, month: string, year: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/gstrate/' + companyid + '/month/' + month + '/year/' + year, { headers })
      .pipe(map(res => res.json()));
  }
  searchDocsDetail(companyid: string, month: string, year: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/invoice/' + companyid + '/month/' + month + '/year/' + year, { headers })
      .pipe(map(res => res.json()));
  }

  searchCDNRDetail(companyid: string, month: string, year: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/excel/GSTR1/crdr/' + companyid + '/month/' + month + '/year/' + year, { headers })
      .pipe(map(res => res.json()));
  }

  deletePurchase(purchaseDeleteId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      purchaseid: purchaseDeleteId,
    });
    return this.http.put('/api/delete/purchase', body, { headers })
      .pipe(map(res => res.json()));
  }

  addDemoPurchaseDetail(txtPurchaseDate: string, txtpurchaseCode: string, txtpartyName: string, txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, Purchase: Array<any>, txtDueDays: String, txtDueDate: String, txtPartyInvoiceNo: string, txtNarration: string,txtType: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      purchaseDate: txtPurchaseDate,
      purchaseNumber: txtpurchaseCode,
      partyId: txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      purchaseDetailDTOs: Purchase,
      dueDays: txtDueDays,
      dueDate: txtDueDate,
      type: txtType,
      partyinvoicenumber: txtPartyInvoiceNo,
      narration: txtNarration
    });
    return this.http.post('/api/create/purchase/demo', body, { headers: headers })
      .pipe(map(res => res.json()));
  }
}
