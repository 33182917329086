import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserSuccessStoryService {

  constructor(
    private http: Http,
  ) { }

  createUserStory(txtUserName: string,txtUserDes: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userName: txtUserName,
      userDescription: txtUserDes
    });
    return this.http.post('/api/app/create/story', body, { headers })
      .pipe(map(res => res));
  }
  
  updateUserStory(txtUserId: string, txtUserName: string,txtUserDes: string,txtImage: string, isActive: boolean) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userstoryid: txtUserId,
      userName: txtUserName,
      userDescription: txtUserDes,
      image: txtImage,
      isactive: isActive,
    });
    return this.http.put('/api/app/update/story', body, { headers })
      .pipe(map(res => res));
  }

  getAllUserSuccesStory() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/story', { headers })
      .pipe(map(res => res.json()));
  }

  getAllUserSuccesStoryWithDeactive() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/story/withdeactive', { headers })
      .pipe(map(res => res.json()));
  }

  deleteUserStory(txtUserId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userstoryid: txtUserId,
    });
    return this.http.put('/api/app/delete/story', body, { headers })
      .pipe(map(res => res.json()));
  }

  activeUserStory(txtUserId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      userstoryid: txtUserId,
    });
    return this.http.put('/api/app/active/story', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/story/image', data, { headers: headers })
      .pipe(map(res => res));
  }
}
