<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Farmer Details</h2>
                <!-- <div class="row d-flex"> -->
                    <div *ngIf="this.userOrgName == 'IAgro Solution' && this.userRoleName =='SUPER ADMIN'" class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <!-- <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Type <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtType" name="txtType">
                                                <option>-:Select Type:-</option>
                                                <option>Purchase</option>
                                                <option>Purchase Return</option>
                                                <option>Sale</option>
                                                <option>Sale Return</option>
                                            </select>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="country" class="form-label">Entity <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtOrganization" name="txtOrganization">
                                                <option>-:Select Entity:-</option>
                                                <option value="ALL">ALL</option>
                                                <option *ngFor="let org of allOrganization" [value]="org.id">{{org.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit" (click)="getAllFarmerListByTypeAndOrgId()">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                <!-- </div> -->
                <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <!-- <div class="portlet-header">Farmer Details
                            <span style="float: right;"><b>Total : {{totalNetAmount | appendIND}}</b></span>
                        </div> -->
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-xl-8 col-lg-6 col-md-6 col-12">
                                    <button *ngIf="allFarmerList.length != 0" class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col">#</th> -->
                                            <th scope="col">Entity Name</th>
                                            <th scope="col">Party Name</th>
                                            <th scope="col">Mobile No.</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">GST Number</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">State</th>
                                            <th scope="col">State Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let srch of allFarmerList | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{srch.orgname}}</td>
                                            <td>{{srch.partyname}}</td>
                                            <td>{{srch.cellnumber}}</td>
                                            <td>{{srch.address}}</td>
                                            <td>{{srch.gstnumber}}</td>
                                            <td>{{srch.location}}</td>
                                            <td>{{srch.state}}</td>
                                            <td>{{srch.statecode}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allFarmerList.length == 0 ">
                                            <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="portlet-footer">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button class="btn btn-secondary me-md-2" type="button">
                            Cancel
                        </button>
                        <button class="btn btn-primary" type="submit" (click)="createPurchaseDetail()">
                            Save
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
