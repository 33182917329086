import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BalancesheetService {

  constructor(private http: Http) { }

  createBalance(txtbalancesheetName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      balancesheetName : txtbalancesheetName,
    });
    return this.http.post('/api/create/balancesheet', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateBalance(txbalanceId: string, txtbalancesheetName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      balancesheetName : txtbalancesheetName,
      balancesheetId : txbalanceId,
    });
    return this.http.put('/api/update/balancesheet', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllBalance(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/balancesheet', { headers })
      .pipe(map(res => res.json()));

  }

  deleteBalance(txbalanceId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      balancesheetId : txbalanceId,
    });
    return this.http.put('/api/delete/balancesheet', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllTrialBalance(startdate: string,enddate: string,company : string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/trialbalance/report/' + company+'/date/'+startdate+'/'+enddate , { headers })
      .pipe(map(res => res.json()));
  }

  getTrialBalanceDetail(startdate: string,enddate: string,glaAccountId : string,company : string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/trialbalance/report/detail/glaaccount/' + glaAccountId + '/company/' + company+'/date/'+startdate+'/'+enddate , { headers })
      .pipe(map(res => res.json()));
  }

  getAllBalanceSheetAssetsReport(company : string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/balancesheet/report/assets/' + company, { headers })
      .pipe(map(res => res.json()));
  }

  getAllBalanceSheetLiabilityReport(company : string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/balancesheet/report/liability/' + company, { headers })
      .pipe(map(res => res.json()));
  }

  getBalanceSheetDetail(name : string,company : string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/balancesheet/report/detail/' + name + '/' + company, { headers })
      .pipe(map(res => res.json()));
  }

  getBalanceSheetDetailPartyWise(name : string,company : string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/balancesheet/report/partywise/detail/' + name + '/' + company, { headers })
      .pipe(map(res => res.json()));
  }
}
