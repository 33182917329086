import { Component, OnInit } from '@angular/core';
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
// import { DetailAnalysisService } from "../service/detail-analysis/detail-analysis.service";
import { StockService } from "../service/stock/stock.service";
import { DatePipe } from '@angular/common';
import { SearchParty } from '../service/party/searchparty';
import { ExcelService } from "../service/excel/excel.service";
import { LedgerSearchStockDTO } from '../service/excel/LedgerSearchStockDTO';
import { CompanyService } from '../service/company/company.service';
import { JvService } from '../service/jv/jv.service';
import { SaleService } from '../service/sale/sale.service';
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';
import { LiabilityVoucherService } from '../service/liability-voucher/liability-voucher.service';
import { GeneralInformationService } from '../service/general-information/general-information.service';
declare var $: any;
@Component({
  selector: 'app-ledger-detail',
  templateUrl: './ledger-detail.component.html',
  styleUrls: ['./ledger-detail.component.css']
})
export class LedgerDetailComponent implements OnInit {
  // txtFormDate = '2021-04-01';
  txtFormDate;
  txtToDate;
  // txtLedger;
  allParty;
  // partyId;
  reSearchData;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  search;
  page = 1;
  txtTotalCredit;
  txtTotalDebit;
  txtTotalBalance;

  txtpartyName;
  public searchParty = '';
  searchPartyName = [];
  receiptData = [];
  txtCreditOpeningBalance = 0;
  txtDebitOpeningBalance = 0;
  txtCreditClosingBalance = 0;
  txtDebitClosingBalance = 0;
  txtTotalDifferent = 0;

  txtcompany;
  allCompany;
  public loading: boolean = false;

  constructor(
    private partyservice: PartyService,
    private toastr: ToastrService,
    // private detailAnalysisService: DetailAnalysisService,
    private stockservice: StockService,
    private excelservice: ExcelService,
    private companyService: CompanyService,
    private JvService: JvService,
    private saleservice: SaleService,
    private assetsservice : AssetsVoucherService,
    private liabilityservice : LiabilityVoucherService,
    private generalinformationService : GeneralInformationService,
  ) { }

  ngOnInit(): void {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtFormDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtToDate = new Date();
    this.getAllParty();
    // this.searchStock();
    this.getAllCompany();
  }

  txtCompanyId;
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      console.log(this.allCompany);
      this.txtCompanyId = res.companyId;
    })
  }

  // getAllParty() {
  //   this.partyservice.getAllParty().subscribe(res => {
  //     this.allParty = res;
  //     // this.txtLedger=this.allParty.partyId;

  //   })
  // }
  tempParty;
  partyCellNumber;
  ExcelpartyName;
  ExcelpartyAddress;
  selectedParty(party) {
    this.tempParty = party.partyId;
    console.log(party);
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    this.partyCellNumber = party.cellNumber;
    this.ExcelpartyName = party.partyName;
    this.ExcelpartyAddress = party.address;
    this.txtEmail = party.email;
    this.txtEmailSale = party.email;
    // for (let balance of this.allparty) {
    //   if (party.cellNumber == balance.cellNumber) {
    //     if (balance.openingBalance > 0) {
    //       this.txtCreditOpeningBalance = balance.openingBalance;
    //       this.txtDebitOpeningBalance = 0;
    //     } else {
    //       this.txtCreditOpeningBalance = 0;
    //       this.txtDebitOpeningBalance = Math.abs(balance.openingBalance);
    //     }
    //   }
    // }
  }

  downloadPdfWhatsUpp(){
    if(this.txtcompany == "All"){
      this.whatsappLedger()
    }else{
      this.whatsappLedgerCompanyWise();
    }
  }

  whatsappsale;
  whatsappLedger() {
    var PartyClosingBalanceWP = 0;
    if(this.txtDebitClosingBalance != 0){
      PartyClosingBalanceWP = this.txtDebitClosingBalance;
    } else if(this.txtCreditClosingBalance != 0){
      PartyClosingBalanceWP = this.txtCreditClosingBalance;
    } else {
      // PartyClosingBalance = 0;
    }
    this.saleservice.whatsappLedger(this.tempParty,this.txtFormDate, this.txtToDate, PartyClosingBalanceWP).subscribe(res => {
      this.whatsappsale = res;
      this.sendRevisedBillMSG(res);
      this.sendMedia(res);
    })
  }

  whatsappsaleCompanyWise;
  whatsappLedgerCompanyWise() {
    var PartyClosingBalanceWP = 0;
    if(this.txtDebitClosingBalance != 0){
      PartyClosingBalanceWP = this.txtDebitClosingBalance;
    } else if(this.txtCreditClosingBalance != 0){
      PartyClosingBalanceWP = this.txtCreditClosingBalance;
    } else {
      // PartyClosingBalance = 0;
    }
    this.saleservice.whatsappLedgerCompanyWise(this.tempParty,this.txtFormDate, this.txtToDate, PartyClosingBalanceWP, this.txtcompany).subscribe(res => {
      this.whatsappsaleCompanyWise = res;
      this.sendRevisedBillMSG(res);
      this.sendMedia(res);
    })
  }
  
  sendMSG;
  sendRevisedBillMSG(res) {
    this.saleservice.sendRevisedBillMSG(res.mobileNumber,res.vrfmassage).subscribe(res1 => {
      this.sendMSG = res1;
    })
  }

  sendMediaLedger;
  sendMedia(res){
    this.generalinformationService.sendAdvertisementMsgAndMedia(res.mobileNumber,"",res.vrfmedia).subscribe(res2 => {
      this.sendMediaLedger = res2;
    })
  }

  // ExalData=[];
  allparty;
  getAllParty() {
    this.partyservice.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        // this.searchPartyName.push({ "partyName":item.partyName, "partyId": item.partyId })
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber = item.cellNumber;
        temp.address = item.address;
        temp.email = item.mailId;
        this.searchPartyName.push(temp)
      }
      console.log(this.allparty);
    })
  }

  searchStock() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
      // } else if (this.txtLedger == "" || this.txtLedger == null || this.txtLedger == undefined) {
      //   this.toastr.error("ledger is required");
      } else if (this.tempParty == "" || this.tempParty == null || this.tempParty == undefined) {
        this.toastr.error("Party name is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name is required");
    } else {
      // this.txtLedger=this.allParty.partyId;
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      if(this.txtcompany == "All"){
      this.stockservice.getLedgerDetail(this.tempParty, this.txtFormDate, this.txtToDate).subscribe(res => {
        this.reSearchData = res;
        // console.log(res);
        
        // this.calclateLedgerDetail();
        this.getAllPartyOpeningBalanceByMonth();
        // console.log(this.receiptData);
        // console.log(this.reSearchData);
      }, error => {
        this.toastr.error("something went wrong");
      });
    }else{
      // this.getLedgerDetailPartyAndCompanyId();
      this.getPartyOpeningBalanceByMonth();
    }
    }
  }
  ExcelData = [];
  //   exportAsXLSX():void {
  //     // this.ExcelData.push({
  //     //   "voucherDate": this.reSearchData.voucherDate, "detail": this.reSearchData.detail, "debit": this.reSearchData.debit
  //     //   , "credit": this.reSearchData.credit, "txtTotalBalance": this.txtTotalBalance
  //     // })
  //     this.ExcelData=[];
  //     for(let excel of this.reSearchData){
  //       let data = new LedgerSearchStockDTO();;
  //       data.date=this.datePipe.transform(excel.voucherDate, 'dd-MM-yyyy');
  //       data.detail= excel.detail;
  //       data.debit=excel.debit;
  //       data.credit=excel.credit;
  //       data.totalamount=excel.txtTotalBalance;
  //       this.ExcelData.push(data);
  //     }  
  //     this.excelservice.exportAsExcelFile(this.ExcelData, 'LedgerSearchStock');
  //  }

  TotalAmount = 0;
  TotalTaxableValue = 0;
  TotalCess = 0;
  exportAsXLSX(): void {
    this.ExcelData = [];
    this.TotalCess = 0;
    for (let excel of this.receiptData) {
      let data = new LedgerSearchStockDTO();
      data.date = this.datePipe.transform(excel.voucherDate, 'dd-MM-yyyy');
      data.particulars = excel.detail;
      data.debit = excel.debit;
      data.credit = excel.credit;
      data.balance = excel.txtTotalBalance;
      this.ExcelData.push(data);
    }
    this.excelservice.exportExcelLedger("Ledger_Detail", this.ExcelData, this.txtDebitOpeningBalance, this.txtCreditOpeningBalance, this.txtTotalDebit, this.txtTotalCredit, this.txtDebitClosingBalance, this.txtCreditClosingBalance, this.ExcelpartyName, this.partyCellNumber, this.ExcelpartyAddress);
  }

  // reSearchSubLedgerData;
  // ledgersaleDetails;
  ledgerSubDetail = [];
  partyinvoicenumber;
  getsubLedgerDetail(srch) {
    console.log(srch);
    this.ledgerSubDetail = [];
    if (srch.detail == "PURCHASE") {
      this.stockservice.getPurchaseLedgerDetail(srch.detailid).subscribe(res => {
        // this.reSearchSubLedgerData = res;
        // this.ledgersaleDetails = this.reSearchSubLedgerData.purchaseDetails;
        this.partyinvoicenumber = res.partyinvoicenumber;
        for(let ledger of res.purchaseDetails){
          this.ledgerSubDetail.push({"itemName": ledger.item.itemName, "categoryName": ledger.item.category.categoryName, 
          "subCategoryName": ledger.item.subCategory.subCategoryName, "brandName": ledger.item.brand.name, 
          "quantity": ledger.quantity, "rate": ledger.rate, "totalAmount": ledger.totalAmount, "narration": res.narration, "partyinvoicenumber": this.partyinvoicenumber})
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (srch.detail == "PURCHASE RETURN") {
      this.stockservice.getPurchaseReturnLedgerDetail(srch.detailid).subscribe(res => {
        // this.reSearchSubLedgerData = res;
        // this.ledgersaleDetails = this.reSearchSubLedgerData.purchaseReturnsDetails;
        for(let ledger of res.purchaseReturnDetails){
          this.ledgerSubDetail.push({"itemName": ledger.item.itemName, "categoryName": ledger.item.category.categoryName, 
          "subCategoryName": ledger.item.subCategory.subCategoryName, "brandName": ledger.item.brand.name, 
          "quantity": ledger.quantity, "rate": ledger.rate, "totalAmount": ledger.totalAmount, "narration": res.narration})
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (srch.detail == "SALE") {
      this.stockservice.getSaleLedgerDetail(srch.detailid).subscribe(res => {
        // this.reSearchSubLedgerData = res;
        // this.ledgersaleDetails = this.reSearchSubLedgerData.saleDetails;
        for(let ledger of res.saleDetails){
          this.ledgerSubDetail.push({"itemName": ledger.item.itemName, "categoryName": ledger.item.category.categoryName, 
          "subCategoryName": ledger.item.subCategory.subCategoryName, "brandName": ledger.item.brand.name, 
          "quantity": ledger.quantity, "rate": ledger.rate, "totalAmount": ledger.totalAmount, "narration": res.narration})
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (srch.detail == "SALE RETURN") {
      this.stockservice.getSaleReturnLedgerDetail(srch.detailid).subscribe(res => {
        // this.reSearchSubLedgerData = res;
        // this.ledgersaleDetails = this.reSearchSubLedgerData.saleReturnDetails;
        for(let ledger of res.saleReturnDetails){
          this.ledgerSubDetail.push({"itemName": ledger.item.itemName, "categoryName": ledger.item.category.categoryName, 
          "subCategoryName": ledger.item.subCategory.subCategoryName, "brandName": ledger.item.brand.name, 
          "quantity": ledger.quantity, "rate": ledger.rate, "totalAmount": ledger.totalAmount, "narration": res.narration})
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (srch.detail == "SALE CHALLAN") {
      this.stockservice.getSaleChallanLedgerDetail(srch.detailid).subscribe(res => {
        // this.reSearchSubLedgerData = res;
        // this.ledgersaleDetails = this.reSearchSubLedgerData.saleDetails;
        for(let ledger of res.salechallanDetails){
          this.ledgerSubDetail.push({"itemName": ledger.item.itemName, "categoryName": ledger.item.category.categoryName, 
          "subCategoryName": ledger.item.subCategory.subCategoryName, "brandName": ledger.item.brand.name, 
          "quantity": ledger.quantity, "rate": ledger.rate, "totalAmount": ledger.totalAmount, "narration": res.narration})
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }
  }

  allPaymentById;
  resAllVoucherLedgerData;
  txtTotalVoucherCredit;
  txtTotalVoucherDebit;
  getVoucherLedgerDetailById(srch) {
    if (srch.detail == "PAYMENT") {
      this.stockservice.getPaymentVoucherLedgerDetail(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let voucherData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + voucherData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + voucherData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if(srch.detail == "RECEIPT"){
      this.stockservice.getReceiptVoucherLedgerDetail(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let voucherData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + voucherData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + voucherData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }else if(srch.detail == "CREDITNOTE"){
      this.stockservice.getCreditVoucherLedgerDetail(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let voucherData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + voucherData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + voucherData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }else if(srch.detail == "DEBITNOTE"){
      this.stockservice.getDebitVoucherLedgerDetail(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let voucherData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + voucherData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + voucherData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }else if(srch.detail == "JOURNAL VOUCHER"){
      this.JvService.getAllJVById(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let receiptData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + receiptData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + receiptData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }else if(srch.detail == "Assets"){
      this.assetsservice.getAllAssetsById(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let receiptData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + receiptData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + receiptData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }else if(srch.detail == "LIABILITY"){
      this.liabilityservice.getAllLiabilityById(srch.voucherMasterId).subscribe(res => {
        this.resAllVoucherLedgerData = res;
        console.log(this.resAllVoucherLedgerData);
        this.txtTotalVoucherCredit = 0;
        this.txtTotalVoucherDebit = 0;
        for (let receiptData of this.resAllVoucherLedgerData) {
          this.txtTotalVoucherDebit = this.txtTotalVoucherDebit + receiptData.debit;
          this.txtTotalVoucherCredit = this.txtTotalVoucherCredit + receiptData.credit;
        }
      }, error => {
        this.toastr.error("something went wrong");
      });
    }
  }

  openModal(srch){
    if(srch.detail == "PAYMENT" || srch.detail == "RECEIPT" || srch.detail == "CREDITNOTE" || srch.detail == "DEBITNOTE" || srch.detail == "JOURNAL VOUCHER" || srch.detail == "Assets" || srch.detail == "LIABILITY"){
      this.getVoucherLedgerDetailById(srch);
      $('#VoucherLedgerModal').modal('show')
    }else {
      this.getsubLedgerDetail(srch);
      $('#LedgerdetailModal').modal('show')
    }
  }

  allLedgerDetailPartyAndCompanyId;
  PartyAndCompanyIdOpeningBalance = 0;
  getLedgerDetailPartyAndCompanyId(){
    this.stockservice.getLedgerDetailPartyAndCompanyId(this.tempParty , this.txtcompany).subscribe(res => {
      this.allLedgerDetailPartyAndCompanyId = res;
      console.log(this.allLedgerDetailPartyAndCompanyId);
      if(this.allLedgerDetailPartyAndCompanyId.amount  == null){
        this.PartyAndCompanyIdOpeningBalance = 0;
        // this.allLedgerDetailPartyAndCompanyId.amount = 0;
      }else{
        this.PartyAndCompanyIdOpeningBalance = this.allLedgerDetailPartyAndCompanyId.amount;
      }
      // this.txtCreditOpeningBalance = this.allLedgerDetailPartyAndCompanyId.amount;
      // this.txtDebitOpeningBalance = this.allLedgerDetailPartyAndCompanyId.amount;
      // for (let balance of this.allLedgerDetailPartyAndCompanyId) {
        
      // }
      this.getLedgerDetailCompany();
    })
  }
  partyOpeningBlanceByMonth;
  previousdayDate;
  txtStartDate;
  getPartyOpeningBalanceByMonth(){
    this.txtStartDate = "2021-04-01";
    this.previousdayDate = new Date(this.txtFormDate);
    this.previousdayDate.setDate(this.previousdayDate.getDate() - 1);
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, 'yyyy-MM-dd');
    this.previousdayDate = this.datePipe.transform(this.previousdayDate, 'yyyy-MM-dd');
    this.partyservice.getPartyOpeningBalanceByMonth(this.txtStartDate,this.previousdayDate,this.tempParty, this.txtcompany).subscribe(res => {
      this.partyOpeningBlanceByMonth = res;
      if(this.partyOpeningBlanceByMonth.closingBalance  == null){
        this.PartyAndCompanyIdOpeningBalance = 0;
      }else{
        this.PartyAndCompanyIdOpeningBalance = this.partyOpeningBlanceByMonth.closingBalance;
        // this.txtDebitOpeningBalance = this.partyOpeningBlanceByMonth.closingBalance;
      }
      this.getLedgerDetailCompany();
    })
  }
  AllPartyOpeningBalance = 0;
  getAllPartyOpeningBalanceByMonth(){
    this.txtStartDate = "2021-04-01";
      this.previousdayDate = new Date(this.txtFormDate);
      this.previousdayDate.setDate(this.previousdayDate.getDate() - 1);
      this.txtStartDate = this.datePipe.transform(this.txtStartDate, 'yyyy-MM-dd');
      this.previousdayDate = this.datePipe.transform(this.previousdayDate, 'yyyy-MM-dd');
      this.partyservice.getAllPartyOpeningBalanceByMonth(this.txtStartDate,this.previousdayDate,this.tempParty).subscribe(res => {
        this.allPartyOpeningBlanceByMonth = res;
        if(res.closingBalance  == null){
          this.AllPartyOpeningBalance = 0;
        }else{
          this.AllPartyOpeningBalance = res.closingBalance;
        }
        this.calclateLedgerDetail();
        // if (res.closingBalance > 0) {
        //   this.txtCreditOpeningBalance = res.closingBalance;
        //   this.txtDebitOpeningBalance = 0;
        // } else {
        //   this.txtCreditOpeningBalance = 0;
        //   this.txtDebitOpeningBalance = Math.abs(res.closingBalance);
        // }
      });
  }

  getLedgerDetailCompany(){
    this.stockservice.getLedgerDetailCompany(this.tempParty, this.txtFormDate, this.txtToDate,this.txtcompany).subscribe(res => {
      this.reSearchData = res;
      // console.log(this.reSearchData);
      this.calclateLedgerDetail();
      
    }, error => {
      this.toastr.error("something went wrong");
    });
  }

  allPartyOpeningBlanceByMonth;
  calclateLedgerDetail(){
    if(this.txtcompany == "All"){
      // for (let balance of this.allparty) {
      //   if (this.partyCellNumber == balance.cellNumber) {  
      //     if(balance.openingBalance == null || balance.openingBalance == undefined || balance.openingBalance == ''){
      //      this.txtCreditOpeningBalance = 0;
      //      this.txtDebitOpeningBalance = 0;
      //     }else{
      //       if (balance.openingBalance > 0) {
      //         this.txtCreditOpeningBalance = balance.openingBalance;
      //         this.txtDebitOpeningBalance = 0;
      //       } else {
      //         this.txtCreditOpeningBalance = 0;
      //         this.txtDebitOpeningBalance = Math.abs(balance.openingBalance);
      //       }
      //     }
      //   }
      // }
        this.txtTotalBalance = 0;
        if (this.AllPartyOpeningBalance > 0) {
          this.txtCreditOpeningBalance = this.AllPartyOpeningBalance;
          this.txtDebitOpeningBalance = 0;
          this.txtTotalBalance = this.AllPartyOpeningBalance;
        } else {
          this.txtCreditOpeningBalance = 0;
          this.txtDebitOpeningBalance = Math.abs(this.AllPartyOpeningBalance);
          this.txtTotalBalance = -(this.AllPartyOpeningBalance);
        }
    }else{
      if (this.PartyAndCompanyIdOpeningBalance > 0) {
        this.txtCreditOpeningBalance = this.PartyAndCompanyIdOpeningBalance;
        this.txtDebitOpeningBalance = 0;
        this.txtTotalBalance = this.PartyAndCompanyIdOpeningBalance;
      } else {
        this.txtCreditOpeningBalance = 0;
        this.txtDebitOpeningBalance = Math.abs(this.PartyAndCompanyIdOpeningBalance);
        this.txtTotalBalance = -(this.PartyAndCompanyIdOpeningBalance);
      }
    }
    this.isEnable = true;
    this.totalNetAmount = 0;
    this.txtTotalCredit = 0;
    this.txtTotalDebit = 0;
    this.txtCreditClosingBalance = 0;
    this.txtDebitClosingBalance = 0;
    this.txtTotalDifferent = 0;
    if (this.reSearchData.length == 0) {
      this.receiptData = [];
      this.txtCreditClosingBalance = this.txtCreditOpeningBalance;
      this.txtDebitClosingBalance = this.txtDebitOpeningBalance;
      this.reSearchData.push("totalbalance", "0")
    } else {

      // for (let receiptData of this.reSearchData) {
      //   this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
      //   this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      //   if(receiptData.debit!=null || receiptData.debit!=0 || receiptData.debit!=undefined){
      //     this.txtTotalBalance= this.txtTotalBalance + receiptData.debit;
      //     this.reSearchData.push({"totalbalance" : this.txtTotalBalance})
      //   }
      //   else{
      //     this.reSearchData.push({"totalbalance" : "0"})
      //   }
      // this.txtTotalBalance = receiptData.amount - receiptData.credit;
      this.receiptData = [];
      for (let ii = 0; ii < this.reSearchData.length; ii++) {
        this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
        this.txtTotalCredit = this.txtTotalCredit + this.reSearchData[ii].credit;
        if (this.reSearchData[ii].debit != 0 && this.reSearchData[ii].credit == 0) {
          this.txtTotalBalance = this.txtTotalBalance + this.reSearchData[ii].debit;
          console.log("particular" + ii);
          this.receiptData.push({
            "voucherDate": this.reSearchData[ii].voucherdate, "detail": this.reSearchData[ii].detail, "debit": this.reSearchData[ii].debit
            , "credit": this.reSearchData[ii].credit, "txtTotalBalance": this.txtTotalBalance, "invoiceId": this.reSearchData[ii].invoiceid, "voucherMasterId": this.reSearchData[ii].vouchermasterid,
            "detailid": this.reSearchData[ii].detailid
          })

        } else if (this.reSearchData[ii].credit != 0 && this.reSearchData[ii].debit == 0) {
          this.txtTotalBalance = this.txtTotalBalance - this.reSearchData[ii].credit;
          this.receiptData.push({
            "voucherDate": this.reSearchData[ii].voucherdate, "detail": this.reSearchData[ii].detail, "debit": this.reSearchData[ii].debit
            , "credit": this.reSearchData[ii].credit, "txtTotalBalance": this.txtTotalBalance, "invoiceId": this.reSearchData[ii].invoiceid, "voucherMasterId": this.reSearchData[ii].vouchermasterid,
            "detailid": this.reSearchData[ii].detailid
          })
        }
      }
      this.txtTotalDifferent = Math.abs(this.txtTotalDebit - this.txtTotalCredit);
      // console.log("diff",this.txtTotalDifferent);
      // console.log("total debit",this.txtTotalDebit);
      // console.log("total credit",this.txtTotalCredit);
      if (this.txtTotalDebit > this.txtTotalCredit) {
        if (this.txtTotalDifferent < this.txtCreditOpeningBalance) {
          this.txtCreditClosingBalance = this.txtCreditOpeningBalance - this.txtTotalDifferent - this.txtDebitOpeningBalance;
        } else {
          this.txtDebitClosingBalance = this.txtTotalDifferent + this.txtDebitOpeningBalance - this.txtCreditOpeningBalance;
        }
      } else {
        if (this.txtTotalDifferent < this.txtDebitOpeningBalance) {
          this.txtDebitClosingBalance = this.txtDebitOpeningBalance - this.txtTotalDifferent - this.txtCreditOpeningBalance;
        } else {
          this.txtCreditClosingBalance = this.txtTotalDifferent + this.txtCreditOpeningBalance - this.txtDebitOpeningBalance;
        }
      }
    }
  }

  resLedgerDetailPdf;
  openPdfResponseLedger;
  downloadLedgerDetailPDF() {
    this.stockservice.downloadLedgerDetailBill(this.tempParty, this.txtFormDate, this.txtToDate).subscribe((data) => {
      this.resLedgerDetailPdf = data;

      var newBlob = new Blob([this.resLedgerDetailPdf], { type: "application/pdf" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const ledgerdata = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = ledgerdata;
      link.download = "LedgerBalanceReport.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseLedger.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(ledgerdata);
        link.remove();
      }, 100);
      this.resLedgerDetailPdf = '';
      this.openPdfResponseLedger = '';
    })
  }

  resLedgerCompanyDetailPdf;
  openPdfResponseLedgerCompany;
  downloadLedgerCompanyDetailPDF() {
    this.stockservice.downloadLedgerCompanyDetailBill(this.tempParty, this.txtFormDate, this.txtToDate, this.txtcompany).subscribe((data) => {
      this.resLedgerCompanyDetailPdf = data;

      var newBlob = new Blob([this.resLedgerCompanyDetailPdf], { type: "application/pdf" });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const ledgerCompanydata = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = ledgerCompanydata;
      link.download = "CompanyLedgerBalanceReport.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseLedger.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(ledgerCompanydata);
        link.remove();
      }, 100);
      this.resLedgerCompanyDetailPdf = '';
      this.openPdfResponseLedgerCompany = '';
    })
  }

  downloadPdf(){
    if(this.txtcompany == "All"){
      this.downloadLedgerDetailPDF();
    }else{
      this.downloadLedgerCompanyDetailPDF();
    }
  }

  txtEmail;
  sendMail() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("email address is required."); 
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      if(this.txtcompany == "All"){
        this.emailAllLedgerData();
      }else{
        this.emailCompanyLedgerData();
      }
    }
  }

  emailAllLedgerData() {
    this.stockservice.sendMailAllLedgerData(this.tempParty, this.txtFormDate, this.txtToDate, this.txtEmail).subscribe(res => {
      console.log(res);
      $("#emailModal").modal('hide');
      this.toastr.success("Email sent successfully");
    },error => {
      this.toastr.error("Email not sent");
    })
  }

  emailCompanyLedgerData() {
    this.stockservice.sendMailCompanyLedgerData(this.tempParty,this.txtcompany, this.txtFormDate, this.txtToDate, this.txtEmail).subscribe(res => {
      console.log(res);
      $("#emailModal").modal('hide');
      this.toastr.success("Email sent successfully");
    },error => {
      this.toastr.error("Email not sent");
    })
  }

  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }
  sendLedgerMailUsingSendgrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      if(this.txtcompany == "All"){
        this.loading = true;
        $(".overlay").css({"display": "block", "opacity": "0.7"});
        $(".loader").css({"display": "block"});
        this.stockservice.allLedgerMailWithSendGrid(this.tempParty, this.txtFormDate, this.txtToDate, this.txtEmail).subscribe(res=>{
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
            $("#emailModal").modal('hide');
            this.toastr.success("Email send successfully");
          }
        },error=>{
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }else{
        this.loading = true;
        $(".overlay").css({"display": "block", "opacity": "0.7"});
        $(".loader").css({"display": "block"});
        this.stockservice.companyLedgerMailWithSendGrid(this.tempParty,this.txtcompany, this.txtFormDate, this.txtToDate, this.txtEmail).subscribe(res=>{
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
            $("#emailModal").modal('hide');
            this.toastr.success("Email send successfully");
          }
        },error=>{
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
      
    }
  }

  txtEmailSale;
  emailsaleno;
  getsendEmailData(srch) {
    console.log(srch);
    this.emailsaleno = srch.invoiceId;
    // if (srch.party.mailId != null) {
    //   this.txtEmailSale = srch.party.mailId;
    // }
  }
  saleMailUsingSendgrid() {
    var email = this.txtEmailSale;
    if (this.txtEmailSale == '' || this.txtEmailSale == null || this.txtEmailSale == undefined || (this.txtEmailSale = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmailSale)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.saleservice.sendMailWithSendGrid(this.emailsaleno,this.txtEmailSale).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#saleEmailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  resMessage;
  sendLedgerMessage(){
    var PartyClosingBalance = 0;
    if(this.txtDebitClosingBalance != 0){
      PartyClosingBalance = this.txtDebitClosingBalance;
    } else if(this.txtCreditClosingBalance != 0){
      PartyClosingBalance = this.txtCreditClosingBalance;
    } else {
      // PartyClosingBalance = 0;
    }
    this.stockservice.sendLedgerMessage(this.tempParty, PartyClosingBalance)
      .subscribe(res => {
        this.resMessage = res;
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#messageModal").modal('hide');
          this.toastr.success("Meaasge send successfully");
        }
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
  }
}