import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BrandAppService } from '../service/brand-app/brand-app.service';
declare var $: any;

@Component({
  selector: 'app-brand-app',
  templateUrl: './brand-app.component.html',
  styleUrls: ['./brand-app.component.css']
})
export class BrandAppComponent implements OnInit {
  txtBrandAppId;
  txtBrandAppName;
  txtBrandGuj;
  allBrandApp;
  btnName = 'Save';
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  search;
  page: number = 1;
  resBrandAppUp;
  resBrandApp;
  resBrandAppDel;

  constructor(
    private brandAppService: BrandAppService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllBrandApp();
  }

  getAllBrandApp() {
    this.brandAppService.getAllBrandApp().subscribe(res => {
      this.allBrandApp = res;  
      console.log(this.allBrandApp);
    })
  }

  toUpperCaseBrandApp() {
    const temp = this.txtBrandAppName;
    this.txtBrandAppName = temp.toUpperCase();
    return this.txtBrandAppName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtBrandAppId = null;
    this.txtBrandAppName = "";
    this.txtBrandGuj = "";
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
  }

  createBrandApp() {
    var brd = this.txtBrandAppName;
    var brdguj = this.txtBrandGuj;
    if (this.txtBrandAppName == null || this.txtBrandAppName == '' || this.txtBrandAppName == undefined || (this.txtBrandAppName = brd.trim()) == "" ) {
      this.toastr.error("Brand name is required");
    } else if (this.txtBrandGuj == null || this.txtBrandGuj == '' || this.txtBrandGuj == undefined || (this.txtBrandGuj = brdguj.trim()) == "" ) {
      this.toastr.error("Brand name gujarat is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("brand image is required");
    } else {
      if (this.txtBrandAppId == null) {
        this.brandAppService.createBrandApp(this.txtBrandAppName, this.txtBrandGuj)
          .subscribe(res => {
            this.resBrandApp = res;
            console.log(this.resBrandApp);
            this.uploadImage();
            this.clearData();
            this.getAllBrandApp();
            this.toastr.success('Brand save successfully');
          }, error => {
            // this.toastr.error('Brand not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.brandAppService.updateBrandApp(this.txtBrandAppId, this.txtBrandAppName, this.txtBrandGuj, this.txtImage).subscribe(res => {
          this.resBrandAppUp = res;
          this.uploadImage();
          this.clearData();
          this.getAllBrandApp();
          this.toastr.success('Brand updated successfully');
        }, error => {
          // this.toastr.error('Brand not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateBrandApp(brd) {
    this.btnName = 'Update';
    this.txtBrandAppId = brd.brandappid;
    this.txtBrandAppName = brd.name;
    this.txtBrandGuj = brd.brandNameGuj;
    this.txtImage = brd.image;
    this.imagePreview = brd.image;
  }

  deleteAppId;
  deleteBrandApp(brd) {
    this.deleteAppId = brd.brandappid;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.brandAppService.deleteBrandApp(this.deleteAppId)
        .subscribe(res => {
          this.resBrandAppDel = res;
          console.log(this.resBrandAppDel);
          this.clearData();
          this.getAllBrandApp();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Brand deleted successfully');
        }, error => {
          this.toastr.error('Brand not deleted');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      // this.imagePreview = "";
      // this.getAllProductApp();
      // this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('cat', this.txtBrandAppName);
      this.brandAppService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllBrandApp();
        // this.clearData();
        this.imagePreview = "";
      }, error => {
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllProductApp();
      })
    }
  }

}
