import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CropsIssueService } from '../service/crops-issue/crops-issue.service';
import { CropsService } from '../service/crops/crops.service';
declare var $: any;

@Component({
  selector: 'app-crops-issue',
  templateUrl: './crops-issue.component.html',
  styleUrls: ['./crops-issue.component.css']
})
export class CropsIssueComponent implements OnInit {
  txtCrops;
  allCrops;
  txtCropsIssueId;
  txtCropsIssueName;
  txtCropsIssueGuj;
  txtCropsIssueDes;
  txtCropsIssueDesGuj;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  resCropsIssue;
  resCropsIssueUp;
  resCropsIssueDel;
  btnName = 'Save';
  search;
  page: number = 1;
  allCropsIssue;

  constructor(
    private cropsService: CropsService,
    private cropsIssueService: CropsIssueService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllCrops();
    this.getAllCropsIssue();
  }

  getAllCrops() {
    this.cropsService.getAllCrops().subscribe(res => {
      this.allCrops = res;
      // console.log(this.allCrops);
    })
  }

  getAllCropsIssue() {
    this.cropsIssueService.getAllCropsIssue().subscribe(res => {
      this.allCropsIssue = res;
      // console.log(this.allCropsIssue);
    })
  }

  toUpperCaseCropsIssue() {
    const temp = this.txtCropsIssueName;
    this.txtCropsIssueName = temp.toUpperCase();
    return this.txtCropsIssueName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCrops = '';
    this.txtCropsIssueName = '';
    this.txtCropsIssueGuj = '';
    this.txtCropsIssueDes = '';
    this.txtCropsIssueDesGuj = '';
    this.txtCropsIssueId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
    this.myfiles = [];
    this.selectedFile = [];
  }

  createCropsIssue() {
    var cro = this.txtCrops;
    var crpiss = this.txtCropsIssueName;
    var crpissguj = this.txtCropsIssueGuj;
    var crpissdes = this.txtCropsIssueDes;
    var crpissdesguj = this.txtCropsIssueDesGuj;
    if (this.txtCrops == null || this.txtCrops == '' || this.txtCrops == undefined || this.txtCrops == "-:Select Crops:-" || (this.txtCrops = cro.trim()) == "") {
      this.toastr.error("Application crops is required");
    }else if (this.txtCropsIssueName == null || this.txtCropsIssueName == '' || this.txtCropsIssueName == undefined || (this.txtCropsIssueName = crpiss.trim()) == "") {
      this.toastr.error("Crops issue name is required");
    }else if (this.txtCropsIssueGuj == null || this.txtCropsIssueGuj == '' || this.txtCropsIssueGuj == undefined || (this.txtCropsIssueGuj = crpissguj.trim()) == "") {
      this.toastr.error("Gujarati crops issue name is required");
    }else if (this.txtCropsIssueDes == null || this.txtCropsIssueDes == '' || this.txtCropsIssueDes == undefined || (this.txtCropsIssueDes = crpissdes.trim()) == "") {
      this.toastr.error("Crops issue description is required");
    }else if (this.txtCropsIssueDesGuj == null || this.txtCropsIssueDesGuj == '' || this.txtCropsIssueDesGuj == undefined || (this.txtCropsIssueDesGuj = crpissdesguj.trim()) == "") {
      this.toastr.error("Gujarati crops issue description is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("crops issue image is required");
    } else {
      if (this.txtCropsIssueId == null) {
        this.cropsIssueService.createCropsIssue(this.txtCrops, this.txtCropsIssueName, this.txtCropsIssueGuj, this.txtCropsIssueDes, this.txtCropsIssueDesGuj)
          .subscribe(res => {
            this.resCropsIssue = res;
            // console.log(this.resCropsIssue);
            this.uploadImage();
            this.clearData();
            this.getAllCropsIssue();
            this.toastr.success('Crops issue save successfully');
          }, error => {
            // this.toastr.error('Crops issue not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.cropsIssueService.updateCropsIssue(this.txtCropsIssueId, this.txtCrops, this.txtCropsIssueName, this.txtCropsIssueGuj, this.txtCropsIssueDes, this.txtCropsIssueDesGuj, this.txtImage).subscribe(res => {
          this.resCropsIssueUp = res;
          this.uploadImage();
          this.clearData();
          this.getAllCropsIssue();
          this.toastr.success('Crops issue updated successfully');
        }, error => {
          // this.toastr.error('Crops issue not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateCropsIssue(crpisu) {
    this.btnName = 'Update';
    this.txtCropsIssueId= crpisu.id;
    this.txtCrops = crpisu.crops.cropsName;
    this.txtCropsIssueName = crpisu.cropsIssueName;
    this.txtCropsIssueGuj = crpisu.cropsIssueNameGuj;
    this.txtCropsIssueDes = crpisu.cropsIssueDescription;
    this.txtCropsIssueDesGuj = crpisu.cropsIssueDescriptionGuj;
    this.txtImage = crpisu.image;
    this.imagePreview = crpisu.image;
  }

  deleteAppId;
  deleteDataCropsIssue(crpisu) {
    this.deleteAppId = crpisu.id;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.cropsIssueService.deleteCropsIssue(this.deleteAppId)
        .subscribe(res => {
          this.resCropsIssueDel = res;
          // console.log(this.resCropsIssueDel);
          this.clearData();
          this.getAllCropsIssue();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Crops issue deleted successfully');
        }, error => {
          this.toastr.error('Crops issue not deleted');
        })
    }
  }

  // onImageUpload(event) {
  //   this.selectedImage = <File>event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.imagePreview = reader.result;
  //   };
  //   reader.readAsDataURL(this.selectedImage);
  // }
  
  // uploadImage() {
  //   if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
  //     // this.imagePreview = "";
  //     // this.getAllProductApp();
  //     // this.clearData();
  //     return
  //   } else {
  //     const data1 = new FormData();
  //     data1.append('file', this.selectedImage, this.selectedImage.name);
  //     data1.append('cat', this.txtCropsIssueName);
  //     this.cropsIssueService.uploadImage(data1).subscribe(res => {
  //       this.resImage = res;
  //       this.getAllCropsIssue();
  //       // this.clearData();
  //       this.imagePreview = "";
  //     }, error => {
  //       // this.imagePreview = "";
  //       // this.clearData();
  //       // this.getAllProductApp();
  //     })
  //   }
  // }

  myfiles= [];
  selectedFile = [];
  onImageUpload(event) {
    this.myfiles = [];
    this.selectedFile = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.myfiles.push(event.target.files[i])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // console.log(event.target.result);
        this.selectedFile.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
    }
    // console.log(this.myfiles)
  }
  removeSelectedFile(index) {
    this.selectedFile.splice(index, 1);
    this.myfiles.splice(index, 1);
    if ((this.selectedFile.length == 0 && this.myfiles.length == 0) || (this.selectedFile == [] && this.myfiles == [])) {
      // this.clearData();
      this.txtImage = '';
    }
  }
  uploadImage() {
    if (this.myfiles == [] || this.myfiles == undefined || this.myfiles == null || this.myfiles.length == 0) {
      this.toastr.error("please select images");
    } else {
      const data1 = new FormData();
      for (let i = 0; i < this.myfiles.length; i++) {
        data1.append('file', this.myfiles[i], this.myfiles[i].name);
        // data1.append('subcat', this.txtImgSubCategory);
        data1.append('cat', this.txtCropsIssueName);
        // data1.append('name', this.txtImgProductName);
      }
      this.cropsIssueService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        // this.viewImages(this.viewimg);
        this.toastr.success("successfully images uploaded.")
        this.getAllCropsIssue();
        this.clearData();
        // this.clearimagedata();
      }, error => {
        this.toastr.error("images not uploaded.")
        // this.clearimagedata();
        // this.clearData();
      })
    }
  }

  cropsIssueMasterId;
  cropsIssueType;
  cropsIssueMediyaType;
  allCropsIssueImages;
  getCropsIssueImages(crpisu){
    this.cropsIssueMasterId = crpisu.id;
    this.cropsIssueType = "Crops Issue";
    this.cropsIssueMediyaType = "Image";
    this.cropsIssueService.getCropsIssueImages(crpisu.id,"Crops Issue","Image").subscribe(res => {
      this.allCropsIssueImages = res;
    })
  }

  deleteCropsImagesId;
  resCropsKrishiGyaanDel;
  deleteImagesAndVideos(ckg) {
    this.deleteCropsImagesId = ckg.docId;
    $('#imageModal').modal('hide');
    $('#deleteImageModal').modal('show');
  }

  confirmImageDelete() {
    if (this.deleteCropsImagesId != null) {
      this.cropsIssueService.deleteCropsKrishiGyaanImages(this.deleteCropsImagesId)
        .subscribe(res => {
          this.resCropsKrishiGyaanDel = res;
          this.clearData();
          this.getAllCropsIssue();
          $('#deleteImageModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Document deleted successfully');
        }, error => {
          this.toastr.error('Document not deleted');
        })
    }
  }
}
