<div class="body-container">
    <div class="content-holder">
      <app-navbar></app-navbar>
      <div class="content">
        <app-top-header></app-top-header>
        <div class="main-content">
          <div class="row">
            <div class="col-lg-12 col-md-12 mb-4">
              <div class="portlet">
                <form class="needs-validation" novalidate="">
                  <div class="portlet-header">Plan</div>
                  <div class="portlet-content">
                    <div class="row g-3">
                      <div class="col-lg-4 col-xl-3 col-12">
                        <label for="first" class="form-label">Plan Name<span style="color: red;">&nbsp;*</span></label>
                        <input type="hidden" class="form-control" [(ngModel)]="txtPlanId" name="txtPlanId">
                        <input type="text" class="form-control" id="planName" placeholder="Enter Plan name"
                          required="required" [(ngModel)]="txtPlanName" name="txtPlanName" (focusout)="toUpperPlan()"/>
                        <div class="invalid-feedback">
                          Please enter plan name.
                        </div>
                      </div>
                      <div class="col-lg-4 col-xl-3 col-6">
                        <label for="NoOfUser" class="form-label">Number Of User<span style="color: red;">&nbsp;*</span></label>
                        <input type="text" class="form-control" id="last" placeholder="Enter Number Of User"
                          required="required" [(ngModel)]="txtNoOfUser" name="txtNoOfUser" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter number of user.
                        </div>
                      </div>
                      <div class="col-lg-4 col-xl-3 col-6">
                        <label for="country" class="form-label">Number Of Location<span style="color: red;">&nbsp;*</span></label>
                        <input type="text" class="form-control" id="itemName" placeholder="Enter Number Of Location"
                          required="required" [(ngModel)]="txtNoOfLocation" name="txtNoOfLocation" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter number of location.
                        </div>
                      </div>
                      <div class="col-lg-3 col-xl-3 col-6">
                        <label for="country" class="form-label">Number Of Company<span style="color: red;">&nbsp;*</span></label>
                        <input type="text" class="form-control" id="itemName" placeholder="Enter Number Of Company"
                          required="required" [(ngModel)]="txtNoOfOrganization" name="txtNoOfOrganization" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter number of Company.
                        </div>
                      </div>
                      <div class="col-lg-3 col-xl-3 col-6">
                        <label for="NoOfUser" class="form-label">Extra User<span style="color: red;">&nbsp;</span></label>
                        <input type="text" class="form-control" id="last" placeholder="Enter Number Of Extra User"
                          required="required" [(ngModel)]="txtNoOfUserLimit" name="txtNoOfUserLimit" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter number of extra user.
                        </div>
                      </div>
                      <div class="col-lg-3 col-xl-3 col-6">
                        <label for="country" class="form-label">Extra Location<span style="color: red;">&nbsp;</span></label>
                        <input type="text" class="form-control" id="itemName" placeholder="Enter Number Of Extra Location"
                          required="required" [(ngModel)]="txtNoOfLocationLimit" name="txtNoOfLocationLimit" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter number of Extra Location.
                        </div>
                      </div>
                      <!-- <div class="col-lg-3 col-xl-3 col-6">
                        <label for="country" class="form-label">Extra Company<span style="color: red;">&nbsp;</span></label>
                        <input type="text" class="form-control" id="itemName" placeholder="Enter Number Of Extra Company"
                          required="required" [(ngModel)]="txtNoOfOrganizationLimit" name="txtNoOfOrganizationLimit" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter number of Extra Company.
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-3 col-xl-3 col-6">
                        <label for="country" class="form-label">Period Time<span style="color: red;">&nbsp;*</span></label>
                        <input type="text" class="form-control" id="itemName" placeholder="Enter Period Time"
                          required="required" [(ngModel)]="txtPeriodTime" name="txtPeriodTime" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter period time.
                        </div>
                      </div>
                      <div class="col-lg-3 col-xl-3 col-6">
                        <label for="country" class="form-label">Plan Price<span style="color: red;">&nbsp;*</span></label>
                        <input type="text" class="form-control" id="itemName" placeholder="Enter Plan Price"
                          required="required" [(ngModel)]="txtPlanPrice" name="txtPlanPrice" (keypress)="numberOnly($event)"/>
                        <div class="invalid-feedback">
                          Please enter plan price.
                        </div>
                      </div> -->
                      <div class="col-auto col-lg-1 btn-container-end">
                        <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                          Cancel
                        </button>
                        <button class="btn btn-primary" type="submit" (click)="createPlan()">
                          {{btnName}}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 mb-4">
              <div class="portlet">
                <div class="portlet-header">Plan Details</div>
                <div class="portlet-content">
                  <div class="row d-flex justify-content-end">
                    <div class="col-md-6">
                      <div class="input-group search-box">
                        <input class="form-control border-0" placeholder="Search" name="search" [(ngModel)]="search"
                          id="example-search-input" />
                        <span class="input-group-append">
                          <button class="btn btn-outline-secondary border-0" type="button">
                            <i class="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
  
                  <div class="mt-3 table-responsive">
                    <table class="table table-hover table-striped table-bordered">
                      <thead class="tblheadercolor">
                        <tr>
                          <th scope="col">Plan Name</th>
                          <th scope="col">Number Of User</th>
                          <th scope="col">Number Of Location</th>
                          <th scope="col">Number Of Company</th>
                          <th scope="col">Extra User</th>
                          <th scope="col">Extra Location</th>
                          <!-- <th scope="col">Extra Company</th> -->
                          <!-- <th scope="col">Period Time</th>
                          <th scope="col">Plan Price</th> -->
                          <th scope="col" style="min-width: 100px;">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let plan of allPlan | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                          <td>{{plan.planName}}</td>
                          <td>{{plan.numberOfUser}}</td>
                          <td>{{plan.numberOfLocation}}</td>
                          <td>{{plan.numberOfOrganization}}</td>
                          <td>{{plan.userLimit}}</td>
                          <td>{{plan.locationLimit}}</td>
                          <!-- <td>{{plan.organizationLimit}}</td> -->
                          <!-- <td>{{plan.periodTime}}</td>
                          <td>{{plan.price | appendIND}}</td> -->
                          <td>
                            <button type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Edit" (click)="updatePlan(plan)">
                              <i class="fas fa-pencil-alt"></i>
                            </button>
                            <button type="button" class="btn btn-secondary btn-sm me-2" data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Delete" (click)="deletePlan(plan)" data-bs-toggle="modal"
                              data-bs-target="#deleteModal">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr *ngIf="allPlan < 1">
                          <td colspan="8" class="text-center"><b>No Record Found</b></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="mt-3 d-flex justify-content-end">
                    <nav aria-label="Table pagination">
                      <ul class="pagination">
                        <li>
                          <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)">
                          </pagination-controls>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure want to delete this record ?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>