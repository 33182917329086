<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Transfer Details</h2> -->
                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Transfer</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Transfer Date<span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3" class="form-control"
                                                [(ngModel)]="txttransferDate" name="txttransferDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3"
                                                style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>

                                            <div class="invalid-feedback">
                                                Please enter a transfer date.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Transfer Code</label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txttransferid"
                                                name="txttransferid">
                                            <input type="text" class="form-control" id="itemName"
                                                placeholder="Enter transfer code" required="required"
                                                [(ngModel)]="txttransferCode" name="txttransferCode" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter transfer code.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-6 col-xl-4 col-6 position-relative">
                                            <label for="last" class="form-label">Location<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchLocation" ngxTypeahead
                                                [taList]="searchLocationName" [taItemTpl]="myParty"
                                                (taSelected)="selectedLocation($event)" id="Location"
                                                [(ngModel)]="txtlocation" name="txtlocation"
                                                placeholder="Enter Location" class="ui-autocomplete-input form-control"
                                                autocomplete="off" autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.locationName}}
                                                </div>
                                            </ng-template>

                                            <div class="invalid-feedback">
                                                Please enter location.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <form class="needs-validation" novalidate="">
                                    <div class="portlet-content">
                                        <div class="row d-flex align-items-baseline g-3">

                                            <div class="col-lg-3 col-xl-4 col-6 position-relative">
                                                <label for="last" class="form-label">Item Name<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" [value]="search1" ngxTypeahead
                                                    (focusout)="getItemById()" [taList]="searchItemName"
                                                    [taItemTpl]="myTemp" (taSelected)="selectedStatic($event)"
                                                    id="ItemName" [(ngModel)]="txtItemName" name="txtItemName"
                                                    placeholder="Enter Item Name"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp let-item>
                                                    <div class="item-name">
                                                        {{item.result.itemName}}
                                                    </div>
                                                </ng-template>

                                                <div class="invalid-feedback">
                                                    Please enter item name.
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-3 col-6">
                                                <label for="country" class="form-label">Brand Name</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Brand Name" required="required"
                                                    [(ngModel)]="txtBrandName" name="txtBrandName"
                                                    disabled />
                                                <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-1 col-5">
                                                <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Quantity" required="required"
                                                    [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a quantity.
                                                </div>
                                            </div>
                                            <div class="col-6 col-lg-2 btn-container-end">
                                                <label></label>
                                                <button type="button" class="btn btn-primary d-block me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                        (click)="getBatchNumber()" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal">STOCK
                                                        <!-- <i class="fas fa-history">STOCK</i> -->
                                                </button>
                                                <button class="btn btn-primary d-block" type="submit"
                                                    (click)="addSaleDetailStockCheck()">
                                                    {{btnName}}
                                                </button>
                                            </div>
                                            <!-- <div class="col-auto col-md-1 btn-container-end">
                                                <button class="btn btn-primary d-block" type="submit"
                                                    (click)="addSaleDetailStockCheck()">
                                                    {{btnName}}
                                                </button>
                                            </div> -->
                                        </div>
                                    </div>
                                </form>

                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Transfer Details</div>
                        <div class="portlet-content">


                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col">#</th> -->
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Category Name</th>
                                            <th scope="col">SubCategory Name</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                            <!-- <th scope="col">BatchWise</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let transfer of transferData">
                                            <!-- <th scope="row">1</th>  -->
                                            <td>{{transfer.itemName}}</td>
                                            <td>{{transfer.categoryName}}</td>
                                            <td>{{transfer.subCategoryName}}</td>
                                            <td>{{transfer.quantity}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                style="margin-right:5px ;" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="updateTransfer(transfer)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteTransfer(transfer)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="transferData.length == 0 ">
                                            <td colspan="8" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearGridData()" (click)="clearValue()">
                                Cancel
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="createTransferDetail()">
                                Save
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Batch wise Stock Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="mt-3 table-responsive">
                            <table class="table table-hover table-striped">
                                <thead class="tblheadercolor">
                                    <tr>
                                        <th scope="col">Batch Number</th>
                                        <th scope="col">Available Qty</th>
                                        <th scope="col">Expiry Date</th>
                                        <th class="text-center" scope="col">Alloted Qty</th>
                                        <!-- <th class="text-center" scope="col">CREDIT (₹)</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let batch of batchwiseresponse;">
                                        <td>{{batch.batchNumber}}</td>
                                        <td>{{batch.quantity}}</td>
                                        <td>{{batch.expiryDate}}</td>
                                        <td>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Quantity" required="required"
                                                [(ngModel)]="batch.allotedquantity" name="txtrate" (keypress)="numberOnly($event)"/>
                                        </td>
                                        <!-- <td class="text-end">{{receiptById.credit}}</td> -->
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>