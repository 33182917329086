import { Component, OnInit } from '@angular/core';
// import { SaleService } from "../service/sale/sale.service";
import { SaleChallanService } from "../service/sale-challan/sale-challan.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
//import { Purchase } from "../service/purchase/purchase";
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
// import { sale } from '../service/sale/sale';
import { salechallan } from "../service/sale-challan/sale-challan";
declare var $: any;

@Component({
  selector: 'app-sale-challan',
  templateUrl: './sale-challan.component.html',
  styleUrls: ['./sale-challan.component.css']
})
export class SaleChallanComponent implements OnInit {
  itemId;
  txtsaleDate;
  saleNumber;
  txtsaleid;
  txtsaleCode;
  txtpartyName;
  txtpartyId;
  txtgstAmount = 0;
  txtamount = 0;
  txtDiscount = 0;
  txtnetAmount = 0;
  txtMobileNumber;
  stockCheck;
  batchwiseresponse;

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity = 0;
  txtrate = 0;
  txtExpiryDate;

  // txtItemName;
  allsale;
  getParty;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];

  saleData: Array<salechallan> = [];
  saleIdLast: number = 0;
  txtCGST: number;
  txtSGST: number;
  txtTotalGST: number;
  exitGST=0;
  datePipe = new DatePipe("en-US");

  stockQuantity: number = 0;
  public loading: boolean = false;

  constructor(
    // private saleservice: SaleService,
    private salechallanservice: SaleChallanService,
    private itemsdetailservice: ItemsDetailService,
    private toastr: ToastrService,
    private partyService: PartyService,
  ) { }

  ngOnInit(): void {
    this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, "dd/MM/yyyy");
    this.txtsaleDate = new Date();
    this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.txtExpiryDate = new Date();
    this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllParty();
    // this.getSaleNumber();
  }
  
  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //Sale Number
  getSaleNumber() {
    this.salechallanservice.getSaleChallanNumber().subscribe(res => {
      this.saleNumber = res;
      this.txtsaleCode = this.saleNumber;
    })
  }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
    this.getItemById();
  }
  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    this.txtMobileNumber= party.cellNumber;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
  }

  allparty;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        temp.address = item.address;
        this.searchPartyName.push(temp)
      }
      console.log(this.allparty);
    })
  }

  getPartyByCellNumber() {
    this.partyService.getPartyByCellNumber(this.txtMobileNumber).subscribe(res => {
      this.getParty = res;
      this.txtpartyName = this.getParty.partyName;
      this.txtpartyId = this.getParty.partyId;
    })
  }


  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.allItem);

    })
  }

  clearGridData() {
    this.txtItemName = "";
    this.txtManufactureDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = "";
    this.txtSubCategoryName = "";
    this.txtBrandName = "";
    this.txtquantity = 0;
    this.txtrate = 0;
    this.txtExpiryDate = new Date();
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = "";
    this.btnName = "Add";
    this.txtsaleid = null;
    this.batchwiseresponse = null;
    this.txtCGST = 0;
    this.txtSGST = 0;
  }

  clearValue() {
    this.txtsaleDate = new Date();
    // this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, "dd/MM/yyyy");
    this.txtsaleCode = '';
    this.txtnetAmount = 0;
    this.txtamount = 0;
    this.txtDiscount = 0;
    this.txtgstAmount = 0;
    this.txtpartyName = '';
    this.searchParty = '';
    this.saleData = [];
    this.txtMobileNumber = '';
    this.tempamount = 0;
    this.exitGST=0;
    this.getSaleNumber();
  }

  resItemById;
  getItemById() {
    this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
      this.resItemById = res;
      console.log(this.resItemById);
      this.txtCategoryName = this.resItemById.category.categoryName;
      this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
      this.txtBrandName = this.resItemById.brand.name;
      this.txtCGST = this.resItemById.gst.cgst;
      this.txtSGST = this.resItemById.gst.sgst;
    });
  }

  addSaleDetailStockCheck() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    }else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      this.salechallanservice.addSaleDetailStockCheck(this.itemId, this.txtquantity)
        .subscribe(res => {
          this.stockCheck = res;
          console.log(this.stockCheck);
          // this.clearValue();
          this.toastr.success('Stock available');
          this.addSaleDetail();
        }, error => {
          this.toastr.error('Stock not available for item');
        })
    }
  }

  addSaleDetail() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
      } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error(' Rate must be required');
      // } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      // return this.toastr.error(' Manufacture Date must be required');
      // } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      // return this.toastr.error(' Expiry Date must be required');
    }else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      if (this.txtsaleid == null) {
        // this.txtItemName = this.tempitem;
        var saleDetail = new salechallan();
        if (this.saleData.length == 0) {
          saleDetail.id1 = this.saleIdLast;
        } else if (this.saleData.length != 0) {
          saleDetail.id1 = this.saleIdLast + 1;
        }
        this.saleIdLast = saleDetail.id1;
        saleDetail.itemName = this.search1; //this for selected itemName
        saleDetail.itemId = this.itemId;
        saleDetail.categoryName = this.txtCategoryName;
        saleDetail.subCategoryName = this.txtSubCategoryName;
        saleDetail.brandName = this.txtBrandName;
        saleDetail.quantity = this.txtquantity;
        saleDetail.rate = this.txtrate;
        saleDetail.cgst = this.txtCGST;
        saleDetail.sgst = this.txtSGST;
        saleDetail.totalAmount = this.txtrate * this.txtquantity;
        //base price calculation
        var totalpercentage = 100 + this.txtCGST + this.txtSGST
        var baseprice = 0;
        baseprice = parseFloat(Number(saleDetail.totalAmount * 100 / totalpercentage).toFixed(2));
        var totalgstamt = 0;
        totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
        // saleDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
        // saleDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
        saleDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.netAmount = baseprice;
        this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
        saleDetail.manufactureDate = this.txtManufactureDate;
        this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
        saleDetail.expiryDate = this.txtExpiryDate;
        saleDetail.expiryDate = this.txtExpiryDate;
        if (this.batchwiseresponse == null) {
          this.getBatchNumber();
          saleDetail.stockDTOs = this.batchwiseresponse;
        } else {
          saleDetail.stockDTOs = this.batchwiseresponse;
        }
        this.stockQuantity = 0;
        for (let stockQuantityValue of this.batchwiseresponse) {
          this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
        }
        console.log("quantity",this.stockQuantity);
        if (this.stockQuantity > this.txtquantity) {
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is more than quantity');
        }else if(this.stockQuantity < this.txtquantity){
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is less than quantity');
        }
        this.saleData.push(saleDetail);

        //code for calculate netamount and gstamount;
        // var temp = 0;
        // var gstsum = 0;
        // temp = this.txtquantity * this.txtrate;
        // this.tempamount = this.tempamount + temp;

        // this.tempGST = temp * (this.txtCGST + this.txtSGST) / 100;
        // gstsum = gstsum + this.tempGST;
        // this.exitGST=this.exitGST+gstsum;
        // this.txtgstAmount = this.exitGST;

        // this.txtamount = this.tempamount - this.txtgstAmount;
        // this.txtnetAmount = this.tempamount;
        this.getAmount();
        this.calculateNetAmount()
        this.clearGridData();

      } else {
        for (let sale of this.saleData) {
          if (sale.id1 == this.txtsaleid) {
            this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
            sale.manufactureDate = this.txtManufactureDate;
            sale.itemName = this.search1;
            sale.itemId = this.itemId;
            sale.categoryName = this.txtCategoryName;
            sale.subCategoryName = this.txtSubCategoryName;
            sale.brandName = this.txtBrandName;
            sale.quantity = this.txtquantity;
            sale.rate = this.txtrate;
            sale.cgst = this.txtCGST;
            sale.sgst = this.txtSGST;
            sale.totalAmount = this.txtrate * this.txtquantity;
            //base price calculation
            var totalpercentage = 100 + this.txtCGST + this.txtSGST
            var baseprice = 0;
            baseprice = parseFloat(Number(sale.totalAmount * 100 / totalpercentage).toFixed(2));
            var totalgstamt = 0;
            totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
            // sale.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
            // sale.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
            sale.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            sale.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            sale.netAmount = baseprice;

            this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
            sale.expiryDate = this.txtExpiryDate;
            if (this.batchwiseresponse == null) {
              this.getBatchNumber();
              sale.stockDTOs = this.batchwiseresponse;
            } else {
              sale.stockDTOs = this.batchwiseresponse;
            }
            this.stockQuantity = 0;
            for (let stockQuantityValue of this.batchwiseresponse) {
              this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
            }
            console.log("quantity",this.stockQuantity);
            if (this.stockQuantity > this.txtquantity) {
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is more than quantity');
            }else if(this.stockQuantity < this.txtquantity){
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is less than quantity');
            }
            this.getAmount();
            this.calculateNetAmount();
            this.clearGridData();
          }
        }
      }
    }
    console.log(this.saleData);
  }

  calculateNetAmount() {
    if (this.txtDiscount == 0 || this.txtDiscount == undefined || this.txtDiscount == null) {
      this.txtDiscount = 0;
      this.txtnetAmount = this.txtnetAmount;
    } else {
      this.txtnetAmount = this.txtnetAmount - this.txtDiscount;
    }
  }

  tempamount = 0;
  tempGST = 0;
  getAmount() {
    this.txtamount = 0;
    this.txtgstAmount = 0;
    this.tempamount = 0;
    this.tempGST = 0;
    var temp = 0;
    var gstsum = 0;
    this.txtnetAmount = 0;
    this.exitGST=0;

    for (let item of this.saleData) {
      // temp = item.quantity * item.rate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = temp * (item.cgst + item.sgst) / 100;
      // // gstsum = gstsum + this.tempGST;
      // this.exitGST=this.exitGST+this.tempGST;
      // this.txtgstAmount = this.exitGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;
      this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
      this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
      this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
    }
  }

  saleDetailres;
  createSaleDetail() {
    if (this.txtsaleDate == undefined || this.txtsaleDate == null || this.txtsaleDate == '') {
      return this.toastr.error('Sale date must be required');
    // } else if (this.txtsaleCode == undefined || this.txtsaleCode == null || this.txtsaleCode == '') {
    //   return this.toastr.error('challan number must be required');
    // } else if (this.txtMobileNumber == null || this.txtMobileNumber == '' || this.txtMobileNumber == undefined) {
    //   this.toastr.error("Mobile Number is required");
    // } else if (this.txtMobileNumber.length > 10 || this.txtMobileNumber.length < 10) {
    //   this.toastr.error("Mobile Number must be 10 digit");
    } else if (this.txtpartyName == undefined || this.txtpartyName == null || this.txtpartyName == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.saleData.length == 0 || this.saleData == []) {
      return this.toastr.error('Sale detail must be required');
    } else {
      // this.txtpartyName = this.tempParty;
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, 'yyyy-MM-dd');
      this.salechallanservice.addSaleDetailChallan(this.txtsaleDate, this.txtsaleCode, this.txtMobileNumber, this.txtpartyName, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.saleData)
        .subscribe(res => {
          this.saleDetailres = res;
          // console.log(this.saleDetailres);
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
          }
          this.clearValue();
          this.getSaleNumber();
          this.toastr.success('Sale challan save successfully');
        }, error => {
          // this.toastr.error('Sale challan not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
        })
    }
  }

  getAllsale() {
    this.salechallanservice.getAllsale().subscribe(res => {
      this.allsale = res;
    })
  }

  updateSale(sale) {
    this.btnName = "Update";
    this.txtsaleid = sale.id1;
    this.txtItemName = sale.itemName;
    this.itemId = sale.itemId;
    this.search1 = sale.itemName;
    this.txtCategoryName = sale.categoryName;
    this.txtSubCategoryName = sale.subCategoryName;
    this.txtBrandName = sale.brandName;
    this.txtquantity = sale.quantity;
    this.txtrate = sale.rate;
    this.txtCGST = sale.cgst;
    this.txtSGST = sale.sgst;
    this.txtManufactureDate = sale.manufactureDate;
    this.txtExpiryDate = sale.expiryDate;

  }

  i;
  deleteSaleData;
  deleteSale(sale) {
    this.deleteSaleData = sale;
  }

  confirmDelete(){
    for (let saleData of this.saleData) {
      if (saleData.id1 == this.deleteSaleData.id1) {
        this.i = this.deleteSaleData.saleId;
        let index = this.saleData.indexOf(this.deleteSaleData);
        if (saleData.id1 !== -1) {
          this.saleData.splice(index, 1);
          this.getAmount();
          this.calculateNetAmount();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
        }
      }
    }
  }

  getBatchNumber() {
    this.salechallanservice.getStockByItemPerBatch(this.itemId, this.txtquantity)
      .subscribe(res => {
        this.batchwiseresponse = res;
        if (this.batchwiseresponse.length <= 0) {
          return this.toastr.error('Stock Not Found.');
        }
        console.log(this.batchwiseresponse);
        for (let batchWiseStock of this.batchwiseresponse) {
          if (batchWiseStock.allotedquantity == null) {
            batchWiseStock.allotedquantity = 0
          }
        }
        console.log("batchWise Stock", this.batchwiseresponse);
        this.toastr.success('Stock available');
        // this.addSaleDetail();
      }, error => {
        this.toastr.error('Stock not available for item');
      })
  }
}
