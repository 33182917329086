import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CropsIssueService } from '../service/crops-issue/crops-issue.service';
import { CropsKrishiGyaanService } from '../service/crops-krishi-gyaan/crops-krishi-gyaan.service';
import { CropsService } from '../service/crops/crops.service';
declare var $: any;

@Component({
  selector: 'app-crops-krishi-gyaan',
  templateUrl: './crops-krishi-gyaan.component.html',
  styleUrls: ['./crops-krishi-gyaan.component.css']
})
export class CropsKrishiGyaanComponent implements OnInit {
  txtCrops;
  allCrops;
  txtCropsKrishiGyaanId;
  txtCropsKrishiGyaanName;
  txtCropsKrishiGyaanGuj;
  txtCropsKrishiGyaanDes;
  txtCropsKrishiGyaanDesGuj;
  txtImage;
  imagePreview = [];
  selectedImage = [];
  resImage;
  resCropsKrishiGyaan;
  resCropsKrishiGyaanUp;
  resCropsKrishiGyaanDel;
  btnName = 'Save';
  search;
  page: number = 1;
  allCropsKrishiGyaan;

  txtVideo = null;
  videoPreview = [];
  selectedVideo = [];
  printMessage :boolean = true;
  // txtVideoURL = '';

  constructor(
    private cropsService: CropsService,
    private cropsKrishiGyaanService: CropsKrishiGyaanService,
    private toastr: ToastrService,
    private cropsIssueService: CropsIssueService,
  ) { }

  ngOnInit(): void {
    this.getAllCrops();
    this.getAllCropsKrishiGyaan();
  }

  getAllCrops() {
    this.cropsService.getAllCrops().subscribe(res => {
      this.allCrops = res;
      console.log(this.allCrops);
    })
  }

  getAllCropsKrishiGyaan() {
    this.cropsKrishiGyaanService.getAllCropsKrishiGyaan().subscribe(res => {
      this.allCropsKrishiGyaan = res;
      console.log(this.allCropsKrishiGyaan);
    })
  }

  toUpperCaseCropsKrishiGyaan() {
    const temp = this.txtCropsKrishiGyaanName;
    this.txtCropsKrishiGyaanName = temp.toUpperCase();
    return this.txtCropsKrishiGyaanName;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCrops = '';
    this.txtCropsKrishiGyaanName = '';
    this.txtCropsKrishiGyaanGuj = '';
    this.txtCropsKrishiGyaanDes = '';
    this.txtCropsKrishiGyaanDesGuj = '';
    this.txtCropsKrishiGyaanId = null;
    this.txtImage = "";
    this.imagePreview = [];
    this.selectedImage = [];
    this.txtVideo = null;
    this.videoPreview = [];
    this.selectedVideo = [];
    this.printMessage = true;
  }

  createCropsKrishiGyaan() {
    var cro = this.txtCrops;
    var crpkg = this.txtCropsKrishiGyaanName;
    var crpkgguj = this.txtCropsKrishiGyaanGuj;
    var crpkgdes = this.txtCropsKrishiGyaanDes;
    var crpkgdesguj = this.txtCropsKrishiGyaanDesGuj;
    if (this.txtCrops == null || this.txtCrops == '' || this.txtCrops == undefined || this.txtCrops == "-:Select Crops:-" || (this.txtCrops = cro.trim()) == "") {
      this.toastr.error("Application crops is required");
    }else if (this.txtCropsKrishiGyaanName == null || this.txtCropsKrishiGyaanName == '' || this.txtCropsKrishiGyaanName == undefined || (this.txtCropsKrishiGyaanName = crpkg.trim()) == "") {
      this.toastr.error("Crops krishi gyaan name is required");
    }else if (this.txtCropsKrishiGyaanGuj == null || this.txtCropsKrishiGyaanGuj == '' || this.txtCropsKrishiGyaanGuj == undefined || (this.txtCropsKrishiGyaanGuj = crpkgguj.trim()) == "") {
      this.toastr.error("Gujarati crops krishi gyaan name is required");
    }else if (this.txtCropsKrishiGyaanDes == null || this.txtCropsKrishiGyaanDes == '' || this.txtCropsKrishiGyaanDes == undefined || (this.txtCropsKrishiGyaanDes = crpkgdes.trim()) == "") {
      this.toastr.error("Crops krishi gyaan description is required");
    }else if (this.txtCropsKrishiGyaanDesGuj == null || this.txtCropsKrishiGyaanDesGuj == '' || this.txtCropsKrishiGyaanDesGuj == undefined || (this.txtCropsKrishiGyaanDesGuj = crpkgdesguj.trim()) == "") {
      this.toastr.error("Gujarati crops krishi gyaan description is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("Crops krishi gyaan image is required");
    // } else if (this.txtVideo == null || this.txtVideo == ''){
    //   this.toastr.error("Please select only one video option.");
    } else {
      if (this.txtCropsKrishiGyaanId == null) {
        this.cropsKrishiGyaanService.createCropsKrishiGyaan(this.txtCrops, this.txtCropsKrishiGyaanName, this.txtCropsKrishiGyaanGuj, this.txtCropsKrishiGyaanDes, this.txtCropsKrishiGyaanDesGuj)
          .subscribe(res => {
            this.resCropsKrishiGyaan = res;
            console.log(this.resCropsKrishiGyaan);
            this.uploadImage();
            // this.uploadVideo();
            // this.clearData();
            // this.getAllCropsKrishiGyaan();
            // this.toastr.success('crops Krishi Gyaan save successfully');
          }, error => {
            // this.toastr.error('crops Krishi Gyaan not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.cropsKrishiGyaanService.updateCropsKrishiGyaan(this.txtCropsKrishiGyaanId,this.txtCrops, this.txtCropsKrishiGyaanName, this.txtCropsKrishiGyaanGuj, this.txtCropsKrishiGyaanDes, this.txtCropsKrishiGyaanDesGuj, this.txtImage, this.txtVideo).subscribe(res => {
          this.resCropsKrishiGyaanUp = res;
          this.uploadImage();
          // this.uploadVideo();
          // this.clearData();
          // this.getAllCropsKrishiGyaan();
          // this.toastr.success('crops update successfully');
        }, error => {
          // this.toastr.error('crops not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateCropsKrishiGyaan(ckg) {
    this.btnName = 'Update';
    this.txtCropsKrishiGyaanId = ckg.id;
    this.txtCrops = ckg.crops.cropsName;
    this.txtCropsKrishiGyaanName = ckg.cropsKrishiGyaanName;
    this.txtCropsKrishiGyaanGuj = ckg.cropsKrishiGyaanNameGuj;
    this.txtCropsKrishiGyaanDes = ckg.cropsKrishiGyaanDescription;
    this.txtCropsKrishiGyaanDesGuj = ckg.cropsKrishiGyaanDescriptionGuj;
    this.txtImage = ckg.image;
    this.imagePreview = ckg.image;
    this.txtVideo = ckg.video;
    this.videoPreview = ckg.video;
    this.printMessage = false;
  }

  deleteAppId;
  deleteDataCropsKrishiGyaan(ckg) {
    this.deleteAppId = ckg.id;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.cropsKrishiGyaanService.deleteCropsKrishiGyaan(this.deleteAppId)
        .subscribe(res => {
          this.resCropsKrishiGyaanDel = res;
          console.log(this.resCropsKrishiGyaanDel);
          this.clearData();
          this.getAllCropsKrishiGyaan();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Crops krishi gyaan deleted successfully');
        }, error => {
          this.toastr.error('Crops krishi gyaan not deleted');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = [];
    this.imagePreview = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.selectedImage.push(event.target.files[i])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // console.log(event.target.result);
        this.imagePreview.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
    }
  }
  removeSelectedFile(index) {
    this.imagePreview.splice(index, 1);
    this.selectedImage.splice(index, 1);
    if ((this.imagePreview.length == 0 && this.selectedImage.length == 0) || (this.imagePreview == [] && this.selectedImage == [])) {
      // this.clearData();
      this.txtImage = '';
    }
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == [] || this.selectedImage == undefined || this.selectedImage.length == 0) {
      // this.imagePreview = "";
      // this.getAllProductApp();
      // this.clearData();
      this.uploadVideo();
      return
    } else {
      const data1 = new FormData();
      for (let i = 0; i < this.selectedImage.length; i++) {
        data1.append('file', this.selectedImage[i], this.selectedImage[i].name);
        data1.append('cat', this.txtCropsKrishiGyaanName);
      }
      this.cropsKrishiGyaanService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.uploadVideo();
        // this.getAllCropsKrishiGyaan();
        // this.clearData();
        this.imagePreview = [];
      }, error => {
        this.uploadVideo();
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllProductApp();
      })
    }
  }

  
  //Display video on save time
  resvideo;
  onVideoUpload(event) {
    this.selectedVideo = [];
    this.videoPreview = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.selectedVideo.push(event.target.files[i])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.videoPreview.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
    }
  }
  removeSelectedVideo(index) {
    this.videoPreview.splice(index, 1);
    this.selectedVideo.splice(index, 1);
    if ((this.videoPreview.length == 0 && this.selectedVideo.length == 0) || (this.videoPreview == [] && this.selectedVideo == [])) {
      // this.clearData();
      this.txtVideo = '';
    }
  }

  uploadVideo() {
    if (this.selectedVideo == null || this.selectedVideo.length == 0 || this.selectedVideo == undefined) {
      this.selectedVideo = [];
      if(this.printMessage == true){
        this.clearData();
        this.toastr.success('Crops krishi gyaan save successfully');
      }else{
        this.clearData();
        this.toastr.success('Crops krishi gyaan updated successfully');
      }
      this.getAllCropsKrishiGyaan();
      return
    } else {
      const data1 = new FormData();
      for (let i = 0; i < this.selectedVideo.length; i++) {
        data1.append('file', this.selectedVideo[i], this.selectedVideo[i].name);
        data1.append('cat', this.txtCropsKrishiGyaanName);
      }
      // data1.append('size', this.txtSize);
      // data1.append('frame', this.txtframetype);
      // data1.append('color', this.txtcolor);
      this.cropsKrishiGyaanService.uploadVideo(data1).subscribe(res => {
        this.resvideo = res;
        if(this.printMessage == true){
          this.clearData();
          this.toastr.success('Crops krishi gyaan save successfully');
        }else{
          this.clearData();
          this.toastr.success('Crops krishi gyaan updated successfully');
        }
        this.getAllCropsKrishiGyaan();
        this.videoPreview = null;
        this.selectedVideo = null;
      }, error => {
        this.clearData();
        this.getAllCropsKrishiGyaan();
        this.toastr.error('Crops krishi gyaan not save');
        this.videoPreview = null;
        this.selectedVideo = null;
      })
    }
  }

  // removeSelectedVideo() { 
  //   this.txtVideo = null;
  //   this.videoPreview = null;
  //   this.selectedVideo = null;
  // }

  cropsIssueMasterId;
  // cropsIssueType;
  // cropsIssueMediyaType;
  allCropsKrishiGyaanImages;
  getCropsKrishiGyaanImages(ckg,Image){
    this.cropsIssueMasterId = ckg.id;
    // this.cropsIssueType = "Crops Issue";
    // this.cropsIssueMediyaType = "Image";
    this.cropsIssueService.getCropsIssueImages(ckg.id,"Crops Krishi Gyaan",Image).subscribe(res => {
      this.allCropsKrishiGyaanImages = res;
    })
  }

  allCropsKrishiGyaanVideo;
  getCropsKrishiGyaanVideo(ckg,Video){
    this.cropsIssueMasterId = ckg.id;
    this.cropsIssueService.getCropsIssueImages(ckg.id,"Crops Krishi Gyaan",Video).subscribe(res => {
      this.allCropsKrishiGyaanVideo = res;
    })
  }

  deleteCropsImagesId;
  deleteImagesAndVideos(ckg) {
    this.deleteCropsImagesId = ckg.docId;
    $('#imageModal').modal('hide');
    $('#videoModal').modal('hide');
    $('#deleteImageVideoModal').modal('show');
  }

  confirmImageDelete() {
    if (this.deleteCropsImagesId != null) {
      this.cropsIssueService.deleteCropsKrishiGyaanImages(this.deleteCropsImagesId)
        .subscribe(res => {
          this.resCropsKrishiGyaanDel = res;
          this.clearData();
          this.getAllCropsKrishiGyaan();
          $('#deleteImageVideoModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Document deleted successfully');
        }, error => {
          this.toastr.error('Document not deleted');
        })
    }
  }
}
