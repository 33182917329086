import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { CompanyService } from "../service/company/company.service";
declare var $: any;


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  resCompany;
  resUpCompany;
  txtCompany;
  txtGSTNumber;
  txtCompanyId;
  allCompany;
  btnName = 'Save';
  search;
  page: number = 1;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  isEnable = false;
  txtQRImage;
  QRPreview;
  selectedQR;
  resQR;

  constructor(private companyService: CompanyService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getAllCompany();
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  toUpperCaseCompany() {
    const temp = this.txtCompany;
    this.txtCompany = temp.toUpperCase();
    return this.txtCompany;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCompany = '';
    this.txtGSTNumber = null;
    this.txtCompanyId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
    this.isEnable = false;
    this.txtQRImage = "";
    this.QRPreview = "";
    this.selectedQR = "";
  }

  createCompany() {
    if (this.txtCompany == null || this.txtCompany == '' || this.txtCompany == undefined) {
      this.toastr.error("Company is required");
    } else {
      if (this.txtGSTNumber == undefined || this.txtGSTNumber == null || this.txtGSTNumber == '') {
        this.txtGSTNumber = null;
      }
      if (this.txtCompanyId == null) {
        this.companyService.createCompany(this.txtCompany,this.txtGSTNumber,this.txtQRImage)
          .subscribe(res => {
            this.resCompany = res;
            this.uploadQR();
            // this.clearData();
            this.getAllCompany();
            this.toastr.success('Company save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.companyService.updateCompany(this.txtCompanyId, this.txtCompany,this.txtGSTNumber, this.txtImage, this.txtQRImage).subscribe(res => {
          this.resUpCompany = res;
          this.uploadImage();
          // this.uploadQR();
          // this.clearData();
          this.getAllCompany();
          this.toastr.success('Company updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateCompany(company) {
    this.isEnable = true;
    this.btnName = 'Update';
    this.txtCompanyId = company.companyId;
    this.txtCompany = company.companyName;
    this.txtGSTNumber = company.gstNumber;
    this.txtImage = company.image;
    this.imagePreview = company.image;
    this.txtQRImage = company.qr;
    this.QRPreview = company.qr;
  }

  deleteid;
  deleteCompany(company) {
    this.deleteid = company.companyId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.companyService.deleteCompany(this.deleteid).subscribe(res => {
        this.resUpCompany = res;
        this.clearData();
        this.getAllCompany();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Company deleted successfully');
      }, error => {
        this.toastr.error('Company not deleted');
      })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.imagePreview = "";
      // this.getAllCompany();
      // this.clearData();
      this.uploadQR();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('com', this.txtCompany);
      this.companyService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        // this.getAllCompany();
        // this.clearData();
        this.uploadQR();
        this.imagePreview = "";
      }, error => {
        this.imagePreview = "";
        // this.clearData();
        // this.getAllCompany();
        this.uploadQR();
      })
    }
  }

  onQRUpload(event) {
    this.selectedQR = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.QRPreview = reader.result;
    };
    reader.readAsDataURL(this.selectedQR);
  }

  uploadQR() {
    if (this.selectedQR == null || this.selectedQR == '' || this.selectedQR == undefined) {
      // if (this.selectedImage != null || this.selectedImage != '' || this.selectedImage != undefined) {
      //   // this.uploadImage();
      //   // this.getAllBanner();
      // }
        this.QRPreview = "";
        this.getAllCompany();
        this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedQR, this.selectedQR.name);
      data1.append('com', this.txtCompany);
      this.companyService.uploadQRCode(data1).subscribe(res => {
        this.resQR = res;
        // this.uploadImage();
        this.getAllCompany();
        this.clearData();
        this.QRPreview = "";
      }, error => {
        // this.uploadImage();
        // this.iconPreview = "";
        // this.getAllBanner();
        this.QRPreview = "";
        this.clearData();
        this.getAllCompany();
      })
    }
  }
}
