import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { PlanExpiryDetailService } from '../service/plan-expiry-detail/plan-expiry-detail.service';

@Component({
  selector: 'app-plan-expiry-detail',
  templateUrl: './plan-expiry-detail.component.html',
  styleUrls: ['./plan-expiry-detail.component.css']
})
export class PlanExpiryDetailComponent implements OnInit {
  page10: number = 1;
  page20: number = 1;
  page30: number = 1;
  page45: number = 1;
  searchten;
  searchtwenty;
  searchthirty;
  searchfourtyfive;
  datePipe = new DatePipe("en-US");
  txtDueDateConvert;
  txtEndDate;
  txtStartDate;
  constructor(
    private planExpiryDetailService: PlanExpiryDetailService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getDueDate(10);
  }

  allPlanExpiryDate;
  getDueDate(days) {
    this.txtStartDate = new Date();
    var ltime = this.txtStartDate.getTime() + days * 24 * 60 * 60 * 1000;
    this.txtEndDate = new Date(ltime);
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    // console.log("this due date after", this.txtEndDate);
    this.planExpiryDetailService.getPlanExpiryDate(this.txtStartDate, this.txtEndDate).subscribe(res => {
      this.allPlanExpiryDate = res;
      // console.log(this.allPlanExpiryDate);
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  contactPerson;
  contactPersonMobile;
  address;
  email;
  chequeNo;
  bankName;
  extraLocationAmount;
  extraplanLocationLimit;
  extraUserAmount;
  extraplanUserLimit;
  planDays;
  planPrice;
  planAmount;
  planNumberOfLocation;
  planNumberOfUser;
  totalLocation;
  totalUser;
  narration;
  subExpiryDateDetail(plan){
    if (plan.planAmount != null){
      this.planAmount = plan.planAmount;
    } else {
      this.planAmount = 0;
    }
    if (plan.extraLocation != null || plan.extraLocation != undefined){
      this.extraLocationAmount = plan.extraLocation.amount;
      this.totalLocation = parseFloat(Number(Number(plan.plan.numberOfLocation) + Number(plan.extraLocation.planLocationLimit)).toFixed(2));
    } else {
      this.extraLocationAmount = 0;
      this.totalLocation = parseFloat(Number(plan.plan.numberOfLocation).toFixed(2));
    }
    if (plan.extraUser != null || plan.extraUser != undefined){
      this.extraUserAmount = plan.extraUser.amount;
      this.totalUser = parseFloat(Number(Number(plan.plan.numberOfUser) + Number(plan.extraUser.planUserLimit)).toFixed(2));
    } else {
      this.extraUserAmount = 0;
      this.totalUser = parseFloat(Number(plan.plan.numberOfUser).toFixed(2));
    }
    if(plan.extraLocation != null) {
      this.extraLocationAmount = plan.extraLocation.amount;
      this.extraplanLocationLimit = plan.extraLocation.planLocationLimit;
    }
    if(plan.extraUser != null) {
      this.extraUserAmount = plan.extraUser.amount;
      this.extraplanUserLimit = plan.extraUser.planUserLimit;
    }
    this.planDays = plan.planpriceId.planDays;
    this.planPrice = plan.planpriceId.planPrice;
    this.contactPerson = plan.contactPerson;
    this.contactPersonMobile = plan.contactPersonMobile;
    this.address = plan.address;
    this.email = plan.email;
    this.chequeNo = plan.chequeNo;
    this.bankName = plan.bankName;
    this.narration = plan.narration;
  }

}
