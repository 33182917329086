import { Component, OnInit } from '@angular/core';
import { StockService } from '../service/stock/stock.service';
import { ExcelService } from "../service/excel/excel.service";
import { DatePipe } from '@angular/common';
import { CompanyService } from '../service/company/company.service';
import { CategoryService } from '../service/category/category.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-item-wise-stock',
  templateUrl: './item-wise-stock.component.html',
  styleUrls: ['./item-wise-stock.component.css']
})
export class ItemWiseStockComponent implements OnInit {
  
  allItemStock;
  search;
  page: number = 1;
  allItemStock2: any = [];
  datePipe = new DatePipe("en-US");

  constructor(
    private stockservice: StockService,
    private excelservice: ExcelService,
    private companyService: CompanyService,
    private categoryservice: CategoryService,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this.getAllsale();
    this.getAllCompany();
    // this.getAllCategory();
  }

  allReceipt;
  getAllsale() {
    this.stockservice.getAllItemStockLocationWise().subscribe(res => {
      this.allItemStock = res;
    })
  }

  ExalData=[];
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.allItemStock){
      this.ExalData.push({ "itemname":exal.itemname, "quantity": exal.quantity })
    }
    this.excelservice.exportAsExcelFile(this.ExalData, 'ItemWiseStock');
 }
 
 txtCompany;
 allCompany;
 getAllCompany() {
   this.companyService.getAllCompany().subscribe(res => {
     this.allCompany = res;
   })
 }

 txtCategory;
 allCategory;
 getAllCategory() {
  this.categoryservice.getAllCategoryByCompany(this.txtCompany).subscribe(res => {
    this.allCategory = res;
  })
}

 ExalData2=[];
 companyIds=[];
 categoryIds=[];
 isClick = false;
 exportAsXLSX2():void {
  this.isClick = true;
  if (this.txtCompany == "" || this.txtCompany == null || this.txtCompany == undefined || this.txtCompany == "-:Select Company:-") {
    this.toastr.error("Company is required");
    this.isClick = false;
  } else if (this.txtCategory == "" || this.txtCategory == null || this.txtCategory == undefined || this.txtCompany == "-:Select Category:-") {
    this.toastr.error("Category is required");
    this.isClick = false;
  } else {
    this.ExalData2=[];
    this.companyIds=[];
    this.categoryIds=[];
    if(this.txtCompany == "All"){
      for(let cm of this.allCompany){
        this.companyIds.push(cm.companyId);
      }
    } else {
      this.companyIds.push(this.txtCompany);
    }
    if(this.txtCategory == "All"){
      for(let ct of this.allCategory){
        this.categoryIds.push(ct.categoryId);
      }
    } else {
      this.categoryIds.push(this.txtCategory);
    }
    this.stockservice.getAllItemStockExcel(this.companyIds,this.categoryIds).subscribe(res => {
      this.allItemStock2 = res;
      for(let excel of res){
        var expirydate = this.datePipe.transform(excel.expirydate, 'dd-MMM-yyyy');
        this.ExalData2.push({"Company Name": excel.companyname,"Category Name": excel.categoryName,"Subcategory Name":excel.subcategoryName, "Item Name": excel.itemname, "Batch Number": excel.batchnumber, "Quantity": excel.quantity, "Value":excel.stkValue,"Expiry Date": expirydate});
      }
      this.excelservice.exportExcelItemWiseStock('ItemWiseStock',this.ExalData2);
      this.isClick= false;
    }, error => {
      this.isClick = false;
    })
  }
}

  resBatchWiseQty
  getQtyBatchWiseByItemId(item) {
    this.stockservice.getQtyBatchWiseByItemId(item.itemid).subscribe(res => {
      this.resBatchWiseQty = res;
    })
  }
}
