<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">

                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header d-flex align-items-center">Credit Note Record
                            <button type="button" class="btn btn-secondary ms-auto" (click)="Movecreditnote()">Add Credit Note</button>
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="getAllCreditNoteExcel()">Excel</button>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col" style="min-width: 100px;">Action</th> -->
                                            <th scope="col" style="width: 60px;">Action</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Narration</th>
                                            <th scope="col">Amount (Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let payment of allPayment | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                            <td class="text-center">
                                                <div class="card-close">
                                                    <div class="dropdown">
                                                        <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false" class="dropdown-toggle">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                        </button>
                                                        <div aria-labelledby="closeCard1" style="min-width: 120px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Print"
                                                            (click)="creditNotePdf(payment)" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                                            (click)="getsendEmailData(payment)" data-bs-toggle="modal"
                                                            data-bs-target="#emailModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                            <i class="fas fa-envelope"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Account History"
                                                            (click)="paymentDetail(payment)" style="margin-left: 5px; margin-bottom: 5px;" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            <i class="fa fa-inr"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Item History"
                                                            (click)="creditDebitNoteDetail(payment)" style="margin-left: 5px; margin-bottom: 5px;" data-bs-toggle="modal" data-bs-target="#creditDebitNoteDetailModel">
                                                            <i class="fas fa-history"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-secondary btn-sm" style="margin-left:5px; margin-bottom: 5px;"
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                            (click)="deleteCredit(payment)" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{payment.voucherDate | date :'dd-MM-yyyy'}}</td>
                                            <td>{{payment.partyName}}</td>
                                            <td>{{payment.narration}}</td>
                                            <td class="text-end">{{payment.debit | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allPayment < 1 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                        <!-- <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item active" aria-current="page">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li> -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Payment Voucher Details</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="col-lg-12 col-md-12">
                                    <div class="portlet">
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                              <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Particulars</th>
                                                        <th class="text-center" scope="col">DEBIT (₹)</th>
                                                        <th class="text-center" scope="col">CREDIT (₹)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let paymentById of allPaymentById;">
                                                        <td>{{paymentById.partyName}}</td>
                                                        <td class="text-end">{{paymentById.debit | appendIND}}</td>
                                                        <td class="text-end">{{paymentById.credit | appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td><b>Total</b></td>
                                                        <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                                        <td class="text-end"><b>{{txtTotalCredit | appendIND}}</b></td>
                                                      </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-6 col-xl-6 col-6">
                        <label for="country" class="form-label">Email Address</label>
                        <input type="text" class="form-control" id="last"
                            placeholder="Enter Email Address" required="required"
                            [(ngModel)]="txtEmail" name="txtEmail" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="creditNoteMailWithSendGrid()">Send</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>    
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="creditDebitNoteDetailModel" tabindex="-1" aria-labelledby="exampleModalLabel"aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Credit Note Item Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12">
                        <div class="portlet">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th class="text-center" scope="col">Quantity</th>
                                            <th class="text-center" scope="col">Rate (₹)</th>
                                            <th class="text-center" scope="col">Amount (₹)</th>
                                            <th class="text-center" scope="col">GST</th>
                                            <th class="text-center" scope="col">GST Amount (₹)</th>
                                            <th class="text-center" scope="col">Total Amount (₹)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let dtl of creditDebitNoteDetails | paginate: { itemsPerPage: 5, currentPage: pageCreditNoteDtl, id: 'creditnotedtl'}">
                                            <td>{{dtl.itemName}}</td>
                                            <td class="text-center">{{dtl.quantity}}</td>
                                            <td class="text-end">{{dtl.rate | appendIND}}</td>
                                            <td class="text-end">{{dtl.totalAmount | appendIND}}</td>
                                            <td class="text-center">{{dtl.cgst + dtl.sgst}}</td>
                                            <td class="text-end">{{dtl.cgstAmount + dtl.sgstAmount | appendIND}}</td>
                                            <td class="text-end">{{dtl.netAmount | appendIND}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="creditDebitNoteDetails < 1 || creditDebitNoteDetails == 'null'">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="pageCreditNoteDtl = $event" (pageBoundsCorrection)="pageCreditNoteDtl=($event)"
                                            responsive="true" id="creditnotedtl"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>