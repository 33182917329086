import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CropsIssueService } from '../service/crops-issue/crops-issue.service';
import { VenderStoryService } from '../service/vender-story/vender-story.service';
declare var $: any;

@Component({
  selector: 'app-vender-story',
  templateUrl: './vender-story.component.html',
  styleUrls: ['./vender-story.component.css']
})
export class VenderStoryComponent implements OnInit {
  // txtCrops;
  // allCrops;
  txtVenderStoryId;
  txtTitleName;
  txtTitleNameGuj;
  txtTitleDes;
  txtTitleDesGuj;
  txtImage;
  imagePreview = [];
  selectedImage = [];
  resImage;
  resVenderStory;
  resVenderStoryUp;
  resVenderStoryDel;
  btnName = 'Save';
  search;
  page: number = 1;
  allVenderStory;

  txtVideo = null;
  videoPreview = [];
  selectedVideo = [];
  printMessage :boolean = true;
  // txtVideoURL = '';

  constructor(
    private venderStoryService: VenderStoryService,
    // private cropsKrishiGyaanService: CropsKrishiGyaanService,
    private toastr: ToastrService,
    private cropsIssueService: CropsIssueService,
  ) { }

  ngOnInit(): void {
    // this.getAllCrops();
    this.getAllVenderStory();
  }

  getAllVenderStory() {
    this.venderStoryService.getAllVenderStory().subscribe(res => {
      this.allVenderStory = res;
    })
  }

  toUpperCasetxtTitle() {
    const temp = this.txtTitleName;
    this.txtTitleName = temp.toUpperCase();
    return this.txtTitleName;
  }

  clearData() {
    this.btnName = 'Save';
    // this.txtCrops = '';
    this.txtTitleName = null;
    this.txtTitleNameGuj = null;
    this.txtTitleDes = null;
    this.txtTitleDesGuj = null;
    this.txtVenderStoryId = null;
    this.txtImage = null;
    this.imagePreview = [];
    this.selectedImage = [];
    this.txtVideo = null;
    this.videoPreview = [];
    this.selectedVideo = [];
    this.printMessage = true;
  }

  createVenderStory() {
    // var cro = this.txtCrops;
    var crpkg = this.txtTitleName;
    var crpkgguj = this.txtTitleNameGuj;
    var crpkgdes = this.txtTitleDes;
    var crpkgdesguj = this.txtTitleDesGuj;
    // if (this.txtCrops == null || this.txtCrops == '' || this.txtCrops == undefined || this.txtCrops == "-:Select Crops:-" || (this.txtCrops = cro.trim()) == "") {
    //   this.toastr.error("Application crops is required");
    // }else 
    if (this.txtTitleName == null || this.txtTitleName == '' || this.txtTitleName == undefined || (this.txtTitleName = crpkg.trim()) == "") {
      this.toastr.error("Title name is required");
    }else if (this.txtTitleNameGuj == null || this.txtTitleNameGuj == '' || this.txtTitleNameGuj == undefined || (this.txtTitleNameGuj = crpkgguj.trim()) == "") {
      this.toastr.error("Gujarati title name is required");
    }else if (this.txtTitleDes == null || this.txtTitleDes == '' || this.txtTitleDes == undefined || (this.txtTitleDes = crpkgdes.trim()) == "") {
      this.toastr.error("Title description is required");
    }else if (this.txtTitleDesGuj == null || this.txtTitleDesGuj == '' || this.txtTitleDesGuj == undefined || (this.txtTitleDesGuj = crpkgdesguj.trim()) == "") {
      this.toastr.error("Gujarati title description is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("Crops krishi gyaan image is required");
    // } else if (this.txtVideo == null || this.txtVideo == ''){
    //   this.toastr.error("Please select only one video option.");
    } else {
      if (this.txtVenderStoryId == null) {
        this.venderStoryService.createVenderStory(this.txtTitleName, this.txtTitleNameGuj, this.txtTitleDes, this.txtTitleDesGuj)
          .subscribe(res => {
            this.resVenderStory = res;
            this.uploadImage();
            // this.uploadVideo();
            // this.clearData();
            // this.getAllCropsKrishiGyaan();
            // this.toastr.success('crops Krishi Gyaan save successfully');
          }, error => {
            // this.toastr.error('crops Krishi Gyaan not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.venderStoryService.updateVenderStory(this.txtVenderStoryId, this.txtTitleName, this.txtTitleNameGuj, this.txtTitleDes, this.txtTitleDesGuj, this.txtImage, this.txtVideo).subscribe(res => {
          this.resVenderStoryUp = res;
          this.uploadImage();
          // this.uploadVideo();
          // this.clearData();
          // this.getAllCropsKrishiGyaan();
          // this.toastr.success('crops update successfully');
        }, error => {
          // this.toastr.error('crops not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateVenderStory(ckg) {
    this.btnName = 'Update';
    this.txtVenderStoryId = ckg.venderstoryId;
    // this.txtCrops = ckg.crops.cropsName;
    this.txtTitleName = ckg.titleName;
    this.txtTitleNameGuj = ckg.titleNameGuj;
    this.txtTitleDes = ckg.titleDescription;
    this.txtTitleDesGuj = ckg.titleDescriptionGuj;
    this.txtImage = ckg.image;
    this.imagePreview = ckg.image;
    this.txtVideo = ckg.video;
    this.videoPreview = ckg.video;
    this.printMessage = false;
  }

  deleteAppId;
  deleteDataVenderStory(ckg) {
    this.deleteAppId = ckg.venderstoryId;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.venderStoryService.deleteVenderStory(this.deleteAppId)
        .subscribe(res => {
          this.resVenderStoryDel = res;
          this.clearData();
          this.getAllVenderStory();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Vender Story deleted successfully');
        }, error => {
          this.toastr.error('Vender Story not deleted');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = [];
    this.imagePreview = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.selectedImage.push(event.target.files[i])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        // console.log(event.target.result);
        this.imagePreview.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
    }
  }
  removeSelectedFile(index) {
    this.imagePreview.splice(index, 1);
    this.selectedImage.splice(index, 1);
    if ((this.imagePreview.length == 0 && this.selectedImage.length == 0) || (this.imagePreview == [] && this.selectedImage == [])) {
      // this.clearData();
      this.txtImage = '';
    }
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == [] || this.selectedImage == undefined || this.selectedImage.length == 0) {
      // this.imagePreview = "";
      // this.getAllProductApp();
      // this.clearData();
      this.uploadVideo();
      return
    } else {
      const data1 = new FormData();
      for (let i = 0; i < this.selectedImage.length; i++) {
        data1.append('file', this.selectedImage[i], this.selectedImage[i].name);
        data1.append('title', this.txtTitleName);
      }
      this.venderStoryService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.uploadVideo();
        // this.getAllCropsKrishiGyaan();
        // this.clearData();
        this.imagePreview = [];
      }, error => {
        this.uploadVideo();
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllProductApp();
      })
    }
  }

  
  //Display video on save time
  resvideo;
  onVideoUpload(event) {
    this.selectedVideo = [];
    this.videoPreview = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.selectedVideo.push(event.target.files[i])
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.videoPreview.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
    }
  }
  removeSelectedVideo(index) {
    this.videoPreview.splice(index, 1);
    this.selectedVideo.splice(index, 1);
    if ((this.videoPreview.length == 0 && this.selectedVideo.length == 0) || (this.videoPreview == [] && this.selectedVideo == [])) {
      // this.clearData();
      this.txtVideo = '';
    }
  }

  uploadVideo() {
    if (this.selectedVideo == null || this.selectedVideo.length == 0 || this.selectedVideo == undefined) {
      this.selectedVideo = [];
      if(this.printMessage == true){
        this.clearData();
        this.toastr.success('Vender Story save successfully');
      }else{
        this.clearData();
        this.toastr.success('Vender Story updated successfully');
      }
      this.getAllVenderStory();
      return
    } else {
      const data1 = new FormData();
      for (let i = 0; i < this.selectedVideo.length; i++) {
        data1.append('file', this.selectedVideo[i], this.selectedVideo[i].name);
        data1.append('title', this.txtTitleName);
      }
      // data1.append('size', this.txtSize);
      // data1.append('frame', this.txtframetype);
      // data1.append('color', this.txtcolor);
      this.venderStoryService.uploadVideo(data1).subscribe(res => {
        this.resvideo = res;
        if(this.printMessage == true){
          this.clearData();
          this.toastr.success('Vender Story save successfully');
        }else{
          this.clearData();
          this.toastr.success('Vender Story updated successfully');
        }
        this.getAllVenderStory();
        this.videoPreview = null;
        this.selectedVideo = null;
      }, error => {
        this.clearData();
        this.getAllVenderStory();
        this.toastr.error('Vender Story not save');
        this.videoPreview = null;
        this.selectedVideo = null;
      })
    }
  }

  // removeSelectedVideo() { 
  //   this.txtVideo = null;
  //   this.videoPreview = null;
  //   this.selectedVideo = null;
  // }

  cropsIssueMasterId;
  // cropsIssueType;
  // cropsIssueMediyaType;
  allCropsKrishiGyaanImages;
  getVenderStoryImages(ckg,Image){
    this.cropsIssueMasterId = ckg.venderstoryId;
    // this.cropsIssueType = "Crops Issue";
    // this.cropsIssueMediyaType = "Image";
    this.cropsIssueService.getCropsIssueImages(ckg.venderstoryId,"VenderStory",Image).subscribe(res => {
      this.allCropsKrishiGyaanImages = res;
    })
  }

  allCropsKrishiGyaanVideo;
  getVenderStoryVideo(ckg,Video){
    this.cropsIssueMasterId = ckg.venderstoryId;
    this.cropsIssueService.getCropsIssueImages(ckg.venderstoryId,"VenderStory",Video).subscribe(res => {
      this.allCropsKrishiGyaanVideo = res;
    })
  }

  deleteCropsImagesId;
  deleteImagesAndVideos(ckg) {
    this.deleteCropsImagesId = ckg.docId;
    $('#imageModal').modal('hide');
    $('#videoModal').modal('hide');
    $('#deleteImageVideoModal').modal('show');
  }

  confirmImageDelete() {
    if (this.deleteCropsImagesId != null) {
      this.cropsIssueService.deleteCropsKrishiGyaanImages(this.deleteCropsImagesId)
        .subscribe(res => {
          this.resVenderStoryDel = res;
          this.clearData();
          this.getAllVenderStory();
          $('#deleteImageVideoModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Document deleted successfully');
        }, error => {
          this.toastr.error('Document not deleted');
        })
    }
  }

}
