import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { BalancesheetService } from "../service/balancesheet/balancesheet.service";
declare var $: any;

@Component({
  selector: 'app-balancesheet',
  templateUrl: './balancesheet.component.html',
  styleUrls: ['./balancesheet.component.css']
})
export class BalancesheetComponent implements OnInit {

  resBalance;
  resUpBalance;
  txtBalanceName;
  txtBalanceId;
  allBalance;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(private toastr: ToastrService, private balancesheetService: BalancesheetService) { }

  ngOnInit(): void {
    this.getAllBalance();
  }

  getAllBalance() {
    this.balancesheetService.getAllBalance().subscribe(res => {
      this.allBalance = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtBalanceName = '';
    this.txtBalanceId = null;
  }

  toUpperCaseBalanceSheet() {
    const temp = this.txtBalanceName;
    this.txtBalanceName = temp.toUpperCase();
    return this.txtBalanceName;
  }

  createCompany() {
    if (this.txtBalanceName == null || this.txtBalanceName == '' || this.txtBalanceName == undefined) {
      this.toastr.error("Balance sheet name is required");
    } else {
      if (this.txtBalanceId == null) {
        this.balancesheetService.createBalance(this.txtBalanceName)
          .subscribe(res => {
            this.resBalance = res;
            this.clearData();
            this.getAllBalance();
            this.toastr.success('BalanceSheet save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.balancesheetService.updateBalance(this.txtBalanceId, this.txtBalanceName).subscribe(res => {
          this.resUpBalance = res;
          this.clearData();
          this.getAllBalance();
          this.toastr.success('BalanceSheet updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateCompany(balance) {
    this.btnName = 'Update';
    this.txtBalanceId = balance.balancesheetId;
    this.txtBalanceName = balance.balancesheetName;
  }

  deleteid;
  deleteCompany(balance) {
    this.deleteid = balance.balancesheetId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.balancesheetService.deleteBalance(this.deleteid).subscribe(res => {
        this.resUpBalance = res;
        this.clearData();
        this.getAllBalance();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('BalanceSheet deleted successfully');
      }, error => {
        this.toastr.error('BalanceSheet not deleted');
      })
    }
  }

}
