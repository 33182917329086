<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Media Marketing</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="first" class="form-label">Description<span style="color: red;">&nbsp;</span></label>
                                            <textarea type="text" class="form-control" id="descr" placeholder="Enter Description" name="txtDescription"
                                                [(ngModel)]="txtDescription" required="required" rows="2"> </textarea>
                                            <div class="invalid-feedback">
                                                Please enter description.
                                            </div>
                                        </div>
                                        <div class="col-12" *ngIf="!isUpdate">
                                            <label for="country" class="form-label">Media Type<span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtMediaType" [(ngModel)]="txtMediaType" required="">
                                                <!-- <option>-:Select Media Type:-</option> -->
                                                <option value="Image" selected>Image</option>
                                                <option value="Video">Video</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid media type.
                                            </div>
                                        </div>
                                        <div class="row g-3" *ngIf="!isUpdate">
                                            <div class="col-md-12 col-sm-8 col-12">
                                                <div class="form-group">
                                                    <!-- <label style="opacity:0;">Image</label> -->
                                                    <label for="formFile" class="form-label">Media</label>
                                                    <input class="form-control" type="file" id="formFile" name="txtImage" multiple
                                                    [(ngModel)]="txtImage" (change)="onImageUpload($event)" [accept]="txtMediaType == 'Image'? 'image/*' : 'video/*'">
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;" *ngFor='let url of selectedFile;let index = index'>
                                                <img *ngIf="txtMediaType == 'Image'" [src]="url" height="30px" width="30px">
                                                <video *ngIf="txtMediaType == 'Video'" loop height="50px" width="50px" [src]="url" [muted]="'muted'"></video>
                                                <a class="text-danger" style="margin-left: 10px; margin-right: 10px;" title="Delete" (click)="removeSelectedFile(index)"><i class="fas fa-trash-alt" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <div class="row">
                                        <div class="col-auto ms-auto">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createMediaMarketing()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Media Marketing</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let dtl of allMediaDetail | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{dtl.description}}</td>
                                                <td class="text-end">
                                                    <div class="card-close">
                                                        <div class="dropdown">
                                                            <mat-slide-toggle class="me-2" [(ngModel)]="dtl.isview" (change)="checkEnableDisableMarketing(dtl)" [color]="color"></mat-slide-toggle>
                                                          <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false" class="dropdown-toggle">
                                                            <i class="fas fa-ellipsis-v"></i>
                                                          </button>
                                                          <div aria-labelledby="closeCard1" style="min-width: 120px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                                (click)="updateMediaMaster(dtl)" style="margin-left: 5px; margin-bottom: 5px;">
                                                                <i class="fas fa-pencil-alt"></i>
                                                            </button>
                                                            <button type="button" class="btn btn-secondary btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                                (click)="deleteData(dtl)" data-bs-toggle="modal"
                                                                data-bs-target="#deleteModal" style="margin-left: 5px; margin-bottom: 5px;">
                                                                <i class="fas fa-trash-alt"></i>
                                                            </button>
                                                            <button type="button" class="btn btn-secondary btn-sm" style="margin-left: 5px; margin-bottom: 5px;"
                                                                data-bs-toggle="tooltip" data-bs-placement="top" title="View"
                                                                (click)="viewMediaDetail(dtl)"data-bs-toggle="modal"
                                                                data-bs-target="#viewMediaDetail">
                                                                <i class="fas fa-eye"></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allMediaDetail.length < 1">
                                                <td colspan="2" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                                responsive="true"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="viewMediaDetail" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Media Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="row d-flex g-3 mt-2">
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-3" *ngFor="let ckg of allSubDetail"
                            style="margin: 10px;">
                                <img *ngIf="ckg.mediaType == 'Image'" [src]="ckg.mediaUrl" height="80px" width="80px">
                                <video *ngIf="ckg.mediaType == 'Video'" loop height="80px" width="80px" [src]="ckg.mediaUrl" [muted]="'muted'"></video>
                                <!-- <a class="text-danger d-flex align-items-center ms-2 mt-2" role="button" *ngIf="ckg.mediaUrl != null" title="Delete" (click)="deleteImagesAndVideos(ckg)"
                                data-bs-toggle="tooltip" data-bs-placement="top" data-bs-toggle="modal" data-bs-target="#deleteImageVideoModal">
                                    <i class="fas fa-trash-alt" aria-hidden="true"></i>
                                </a> -->
                            </div>
                            <div class="text-center" *ngIf="allSubDetail.length < 1" style="margin-bottom: 10px;">
                                <div class="text-center"><b>No Media Found</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteImageVideoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete document</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this document ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmImageDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>