import { Component, OnInit } from '@angular/core';
import { ReceiptVoucherService } from "../service/receipt_voucher/receipt-voucher.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { SaleService } from '../service/sale/sale.service';
import { GeneralInformationService } from '../service/general-information/general-information.service';
declare var $: any;

@Component({
  selector: 'app-receipt-v-record',
  templateUrl: './receipt-v-record.component.html',
  styleUrls: ['./receipt-v-record.component.css']
})
export class ReceiptVRecordComponent implements OnInit {
  allReceipt;
  allReceiptDetail;
  allReceiptById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;
  public loading: boolean = false;
  datePipe = new DatePipe("en-US");
  txtFormDate;
  txtToDate;

  constructor(
    private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService,
    private saleService: SaleService,
    private generalinformationService : GeneralInformationService,
  ) { }

  ngOnInit(): void {var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.getAllreceipt();
    this.getAllReceiptByDate();
  }

  MoveReceipt() {
    this.router.navigate(['receiptvoucher']);
  }

  // getAllreceipt() {
  //   this.receiptvoucherservice.getAllreceipt().subscribe(res => {
  //     this.allReceipt = res;
  //     // console.log(this.allReceipt);
  //   })
  // }
  userRole;
  getAllReceiptByDate() {
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    this.receiptvoucherservice.getAllReceiptByDate(this.txtFormDate,this.txtToDate).subscribe(res => {
      // this.allReceipt = res;
      this.allReceipt = res.receiptvoucher;
      this.userRole = res.userrole;
    })
  }

  whatsappsale;
  whatsappSale(res) {
    this.receiptvoucherservice.whatsappSale(res.voucherMasterId).subscribe(res => {
      this.whatsappsale = res;
      this.sendRevisedBillMSG(res);
      this.sendMedia(res);
    })
  }
  
  sendMSG;
  sendRevisedBillMSG(res) {
    this.saleService.sendRevisedBillMSG(res.mobileNumber,res.vrfmassage).subscribe(res => {
      this.sendMSG = res;
    })
  }

  sendMediaLedger;
  sendMedia(res){
    this.generalinformationService.sendAdvertisementMsgAndMedia(res.mobileNumber,"",res.vrfmedia).subscribe(res2 => {
      this.sendMediaLedger = res2;
    })
  }

  receiptDetail(receipt) {
    this.getReceiptVoucherById(receipt.voucherMasterId)
    // this.txtDate = receipt.voucherDate;
    // console.log("Receipt Date",receipt);
  }

  getReceiptVoucherById(txtId) {
    this.receiptvoucherservice.getAllReceiptById(txtId).subscribe(res => {
      this.allReceiptById = res;
      console.log(this.allReceiptById);
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allReceiptById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }

  resBillPdf
  openPdfResponse
  receiptVoucherPdf(receipt) {
    this.receiptvoucherservice.downloadReceiptVoucher(receipt.voucherMasterId).subscribe((data) => {
      this.resBillPdf = data;

      var newBlob = new Blob([this.resBillPdf], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Sale_Bij_Nigam.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponse.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
      this.resBillPdf = '';
      this.openPdfResponse = '';
    })
  }

  deleteReceiptId;
  deleteReceipt(receipt) {
    this.deleteReceiptId = receipt.voucherMasterId;
  }

  resReceiptData;
  confirmDelete() {
    if (this.deleteReceiptId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteReceiptId).subscribe(res => {
        this.resReceiptData = res;
        console.log(this.resReceiptData);
        // this.getAllreceipt();
        this.getAllReceiptByDate();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Receipt data deleted successfully');
      }, error => {
        this.toastr.error('Receipt data not deleted');
      })
    }
  }

  // delete() {
  //     this.receiptvoucherservice.deleteReceiptById(this.deleteReceiptId).subscribe(res => {
  //       this.resReceiptData = res;
  //       console.log(this.resReceiptData);
  //       this.getAllreceipt();
  //       $('#deleteModal').modal('hide');
  //       $('.modal-backdrop').remove();
  //       this.toastr.success('Receipt Data Delete Successfully');
  //     }, error => {
  //       this.toastr.error('Receipt Data Not Delete');
  //     })
  // }

  txtEmail;
  vouchermstId;
  getsendEmailData(receipt) {
    console.log(receipt);
    this.vouchermstId = receipt.voucherMasterId;
    // if (receipt.party.mailId != null) {
    //   this.txtEmail = receipt.party.mailId;
    // }
  }
  hideloaderOrder() { 
    $(".loader1").css({"display": "none"});
  }
  receiptMailWithSendGrid() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required.");
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.receiptvoucherservice.receiptMailWithSendGrid(this.vouchermstId,this.txtEmail).subscribe(res=>{
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          $("#emailModal").modal('hide');
          this.toastr.success("Email send successfully");
        }
      },error=>{
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
    }
  }

  sendMail() {
    var email = this.txtEmail;
    if (this.txtEmail == '' || this.txtEmail == null || this.txtEmail == undefined || (this.txtEmail = email.trim()) == "") {
      this.toastr.error("Email address is required."); 
    } else if (! /(.+)@(.+){2,}\.(.+){2,}/.test(this.txtEmail)) {
      this.toastr.error("Please enter valid email address");
    } else {
      this.receiptvoucherservice.sendReceiptEmail(this.vouchermstId, this.txtEmail).subscribe(res => {
        console.log(res);
        $("#emailModal").modal('hide');
        this.toastr.success("Email send successfully");
      })
    }
  }
}
