import { Component, OnInit } from '@angular/core';
import { UnitService } from "../service/unit/unit.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {
  resUnit;
  resUpUnit;
  txtUnit;
  txtUnitId;
  allUnit;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private router: Router,
    private UnitService: UnitService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllUnit();
  }

  getAllUnit() {
    this.UnitService.getAllUnit().subscribe(res => {
      this.allUnit = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtUnit = '';
    this.txtUnitId = null;
  }

  toUpperCaseUnit() {
    const temp = this.txtUnit;
    this.txtUnit = temp.toUpperCase();
    return this.txtUnit;
  }

  createUnit() {
    if (this.txtUnit == null || this.txtUnit == '' || this.txtUnit == undefined) {
      this.toastr.error("Unit is required");
    } else {
      if (this.txtUnitId == null) {
        this.UnitService.createUnit(this.txtUnit)
          .subscribe(res => {
            this.resUnit = res;
            this.clearData();
            this.getAllUnit();
            this.toastr.success('Unit save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.UnitService.updateUnit(this.txtUnitId, this.txtUnit).subscribe(res => {
          this.resUpUnit = res;
          this.clearData();
          this.getAllUnit();
          this.toastr.success('Unit updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateUnit(unit) {
    this.btnName = 'Update';
    this.txtUnitId = unit.unitId;
    this.txtUnit = unit.unitName;
  }

  deleteid;
  deleteUnit(unit) {
    this.deleteid = unit.unitId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.UnitService.deleteUnit(this.deleteid).subscribe(res => {
        this.resUpUnit = res;
        this.clearData();
        this.getAllUnit();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Unit deleted successfully');
      }, error => {
        this.toastr.error('Unit not deleted');
      })
    }
  }
}
