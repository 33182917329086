import { Component, OnInit } from '@angular/core';
import { BrandService } from "../service/brand/brand.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {
  resBrand;
  resUpBrand;
  txtBrand;
  txtBrandId;
  allBrand;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private router: Router,
    private BrandService: BrandService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllBrand();
  }

  getAllBrand() {
    this.BrandService.getAllBrand().subscribe(res => {
      this.allBrand = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtBrand = '';
    this.txtBrandId = null;
  }

  toUpperCaseBrand() {
    const temp = this.txtBrand;
    this.txtBrand = temp.toUpperCase();
    return this.txtBrand;
  }

  createBrand() {
    if (this.txtBrand == null || this.txtBrand == '' || this.txtBrand == undefined) {
      this.toastr.error("Brand is required");
    } else {
      if (this.txtBrandId == null) {
        this.BrandService.createBrand(this.txtBrand)
          .subscribe(res => {
            this.resBrand = res;
            this.clearData();
            this.getAllBrand();
            this.toastr.success('Brand save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.BrandService.updateBrand(this.txtBrandId, this.txtBrand).subscribe(res => {
          this.resUpBrand = res;
          this.clearData();
          this.getAllBrand();
          this.toastr.success('Brand updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateBrand(brand) {
    this.btnName = 'Update';
    this.txtBrandId = brand.id;
    this.txtBrand = brand.name;
  }

  deleteid;
  deleteBrand(brand) {
    this.deleteid = brand.id;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.BrandService.deleteBrand(this.deleteid).subscribe(res => {
        this.resUpBrand = res;
        this.clearData();
        this.getAllBrand();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Brand deleted successfully');
      }, error => {
        this.toastr.error('Brand not deleted');
      })
    }
  }
}
