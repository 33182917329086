<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Category Master</h2> -->

                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Product App Master</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="country" class="form-label">Category<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtCategoryApp"
                                                [(ngModel)]="txtCategoryApp" required="">
                                                <option>-:Select Category App:-</option>
                                                <option *ngFor="let category of allCategoryApp"
                                                    [value]="category.categoryName">{{category.categoryName}}</option>

                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid Category App.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="country" class="form-label">Brand<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtBrandApp"
                                                [(ngModel)]="txtBrandApp" required="">
                                                <option>-:Select Brand App:-</option>
                                                <option *ngFor="let brand of allBrandApp"
                                                    [value]="brand.name">{{brand.name}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please select a valid brand App.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Product<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtProductAppId"
                                                name="txtProductAppId">
                                            <input type="text" class="form-control" id="ProductApp"
                                                placeholder="Enter Product" name="txtProductApp"
                                                [(ngModel)]="txtProductApp" required="required" (focusout)="toUpperCaseProductApp()" />
                                            <div class="invalid-feedback">
                                                Please enter ProductApp name.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Product Gujarati<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="ProductGuj"
                                                placeholder="Enter Product Gujarati" name="txtProductGuj" [(ngModel)]="txtProductGuj"
                                                required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Product gujarati name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-12">
                                            <label for="first" class="form-label">Rate<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="rate"
                                                placeholder="Enter Product Rate" name="txtProductRate"
                                                [(ngModel)]="txtProductRate" required="required" (keypress)="numberandDotOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter ProductApp rate.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Product Description<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="ProductAppDes"
                                                placeholder="Enter Product Description" name="txtProductDes"
                                                [(ngModel)]="txtProductDes" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter ProductApp description.
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Product Description Gujarati<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="ProductDesGuj"
                                                placeholder="Enter Product Description Gujarati" name="txtProductDesGuj" 
                                                [(ngModel)]="txtProductDesGuj" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Product description gujarati.
                                            </div>
                                        </div> -->
                                        <div class="row g-3">
                                            <div class="col-md-10 col-sm-8 col-8">
                                                <div class="form-group">
                                                    <!-- <label style="opacity:0;">Image</label> -->
                                                    <label for="formFile" class="form-label">Image</label>
                                                    <input class="form-control" type="file" id="formFile" name="txtImage"
                                                    [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                                                <img [src]="imagePreview" height="50px" width="50px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createProductApp()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Product</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Category</th>
                                                <th scope="col">Brand</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">Product Gujarati</th>
                                                <!-- <th scope="col">Rate(Rs.)</th>
                                                <th scope="col">Product Description</th>
                                                <th scope="col">Product Description Gujarati</th> -->
                                                <th class="text-center">Image</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let pdt of allProductApp | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{pdt.category.categoryName}}</td>
                                                <td>{{pdt.brandApp.name}}</td>
                                                <td>{{pdt.productName}}</td>
                                                <td>{{pdt.productNameGuj}}</td>
                                                <!-- <td>{{pdt.price}}</td>
                                                <td>{{pdt.productDescription}}</td>
                                                <td>{{pdt.productDescriptionGuj}}</td> -->
                                                <td class="text-center"><img [src]="pdt.image" height="50px" width="50px">
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateProductApp(pdt)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteDataProduct(pdt)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allProductApp < 1">
                                                <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>

                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure want to delete this record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>