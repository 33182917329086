import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlanExpiryDetailService {

  constructor(
    private http: Http,
  ) { }

  getPlanExpiryDate(txtFormDate :string,txtToDate:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/planpurchase/date/' + txtFormDate + '/' + txtToDate , { headers })
      .pipe(map(res => res.json()));
  }

}
