import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CashToBankService } from '../service/cash-to-bank/cash-to-bank.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
declare var $: any;

@Component({
  selector: 'app-bank-to-bank-record',
  templateUrl: './bank-to-bank-record.component.html',
  styleUrls: ['./bank-to-bank-record.component.css']
})
export class BankToBankRecordComponent implements OnInit {
  allBankToBank;
  allBankToBankById;
  txtTotalCredit = 0;
  txtTotalDebit = 0;
  txtTotalAmount = 0;
  search;
  page: number = 1;

  constructor(
    private cashtobankservice: CashToBankService,
    private router: Router,
    private toastr: ToastrService,
    private receiptvoucherservice: ReceiptVoucherService,
  ) { }

  ngOnInit(): void {
    this.getAllBankToBankVoucher();
  }

  MoveBankToBank() {
    this.router.navigate(['banktobank']);
  }
  getAllBankToBankVoucher() {
    this.cashtobankservice.getAllBankToBankVoucher().subscribe(res => {
      this.allBankToBank = res;
      this.txtTotalAmount = 0;
      for (let bankToBankData of this.allBankToBank) {
        this.txtTotalAmount = this.txtTotalAmount + bankToBankData.debit;
      }
    })
  }

  bankToBankDetail(banktobank) {
    this.getAllBankToBankById(banktobank.voucherMasterId)
  }

  getAllBankToBankById(txtId) {
    this.cashtobankservice.getAllCashToBankById(txtId).subscribe(res => {
      this.allBankToBankById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let bankToBankData of this.allBankToBankById) {
        this.txtTotalDebit = this.txtTotalDebit + bankToBankData.debit;
        this.txtTotalCredit = this.txtTotalCredit + bankToBankData.credit;
      }
    })
  }

  deleteBankToBankId;
  deleteBankToBank(banktobank){
    this.deleteBankToBankId = banktobank.voucherMasterId;
  }

  resBankToBankData;
  confirmDelete() {
    if (this.deleteBankToBankId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteBankToBankId)
        .subscribe(res => {
          this.resBankToBankData = res;
          this.getAllBankToBankVoucher();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('BankToBank data deleted successfully');
        }, error => {
          this.toastr.error('BankToBank data not deleted');
        })
    }
  }
}
