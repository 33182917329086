<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>SubCategory Master</h2> -->
                <div class="row">
                    <div class="col-lg-5 col-md-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Plan Price </div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-12">
                                            <label for="country" class="form-label">Plan Name<span style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtPriceid" name="txtPriceid">
                                            <select class="form-select" id="country" name="txtPlan" [(ngModel)]="txtPlan" required="">
                                                <option >-:Select Plan Name:-</option>
                                                <option *ngFor="let plan of allPlan" [value]="plan.planName">{{plan.planName}}</option>
                                            </select>
                                            <div class="invalid-feedback">Please select a valid Plan name.</div>
                                        </div>
                                        <div class="col-12">
                                            <label for="country" class="form-label">Plan Duration<span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" name="txtPlanDuration" [(ngModel)]="txtPlanDuration" required="">
                                                <option >-:Select Plan Duration:-</option>
                                                <option value="1">1 Year</option>
                                                <option value="2">2 Year</option>
                                                <option value="3">3 Year</option>
                                                <option value="4">4 Year</option>
                                                <option value="5">5 Year</option>
                                                <!-- <option value="30">30 Days</option>
                                                <option value="60">60 Days</option>
                                                <option value="90">90 Days</option>
                                                <option value="120">120 Days</option>
                                                <option value="150">150 Days</option>
                                                <option value="180">180 Days</option>
                                                <option value="210">210 Days</option>
                                                <option value="240">240 Days</option>
                                                <option value="270">270 Days</option>
                                                <option value="300">300 Days</option>
                                                <option value="330">330 Days</option>
                                                <option value="365">365 Days</option> -->
                                            </select>
                                            <div class="invalid-feedback">Please select a valid Plan deration.</div>
                                        </div>
                                        <div class="col-12">
                                            <label for="first" class="form-label">Price<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="Category" placeholder="Enter Price" name="txtPrice"
                                                [(ngModel)]="txtPrice" required="required" (keypress)="numberOnly($event)"  />
                                            <div class="invalid-feedback">Please enter price.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-footer">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-secondary me-md-2" type="button" (click)="clearData()">
                                            Cancel
                                        </button>
                                        <button class="btn btn-primary" type="submit" (click)="createPlanPriceDetail()">
                                            {{btnName}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-8">
                        <div class="portlet">
                            <div class="portlet-header">Plan Price Detail</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-6">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" type="search" placeholder="Search"
                                                name="search" [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Plan Name</th>
                                                <th scope="col">Plan Duration (Year)</th>
                                                <th scope="col">Price</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pp of allPriceData | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{pp.plan.planName}}</td>
                                                <td>{{pp.planDays}}</td>
                                                <td>{{pp.planPrice}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm" style="margin-right:5px ;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit" (click)="updatePlanPrice(pp)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deleteData(pp)" data-bs-toggle="modal" data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allPriceData < 1">
                                                <td colspan="4" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are You Sure Want To Delete This Record ?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>