import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const XLS_EXCEL_EXTENSION = '.xls';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  datePipe = new DatePipe("en-US");
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportExcel(filename, excelData, TotalpartyLength, invoiceLength, TotalAmount, TotalTaxableValue, TotalCess,
    b2csExcelData,b2cspartyLength,b2csinvoiceLength,b2csTotalAmount,b2csTotalTaxableValue,b2csTotalCess
    ,ExcelDataHSN,TotalValue,TotalTaxableValue11,TotalIntegratedTax,TotalCentralTax,TotalStateUTTax,NoofHSN,TotalCesshsn,
    ExcelDataMaster,NatureofDocumentData,TotalTaxableValue2,TotalCessmaster,
    ExcelDataDocs,TotalNumber,TotalCancelldedocs,
    ExcelDataCdnr,TotalReceiverLength,TotalInvoiceAdvanceReceiptNumber,TotalVoucherLength,TotalVoucherValue,TotalTaxableValueCdnr,TotalCessCdnr) {

    //Title, Data
    const title = filename;
    const data = excelData;
    const datahsn = ExcelDataHSN;
    const datamaster = ExcelDataMaster;
    const datadocs = ExcelDataDocs;
    const datacdnr = ExcelDataCdnr;

    //Create a workbook with a worksheet  ---"no.2=b2cs=>b2ba" "n0.4=master=>b2cs"---
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('b2b');
    let worksheetb2cs = workbook.addWorksheet('b2ba');
    worksheetb2cs.state = "hidden";
    let worksheethsn = workbook.addWorksheet('hsn');
    let worksheetmaster = workbook.addWorksheet('b2cs');
    let worksheetdocs = workbook.addWorksheet('docs');
    let worksheetcdnr = workbook.addWorksheet('cdnr');

    //Add a Row  ---b2b---
    let headerRow0 = worksheet.addRow(["Summary For B2B(4)"]);
    headerRow0.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow0.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    let headerRow1 = worksheet.addRow(["No.of Recipients", "", "No. of Invoices", "", "Total Invoice Value", "", "", "", "", "", "", "Total Taxable Value", "Total Cess"]);
    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    // .fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: '0070C0' },  
    // };
    // .font = {
    //   bold: true,
    //   color: { argb: 'FFFFFF' },
    //   size: 11
    // };

    worksheet.addRow([]);

    let header = ["GSTIN/UIN of Recipient", "Receiver Name", "Invoice Number", "Invoice date", "Invoice Value", "Place Of Supply", "Reverse Charge", "Applicable % of Tax Rate", "Invoice Type", "E-Commerce GSTIN", "Rate", "Taxable Value", "Cess Amount"]
    let headerRow = worksheet.addRow(header)
    // .fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     // fgColor: { argb: '0070C0' }
    //     fgColor: { argb: 'E6B8B7' }
    //   };
    // headerRow.values=header;
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }


    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 22;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 10;
    worksheet.getColumn(12).width = 20;
    worksheet.getColumn(13).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let TotalpartyLength1 = TotalpartyLength;
    let TotalpartyLengthCell = worksheet.getCell('A3');
    TotalpartyLengthCell.value = TotalpartyLength1;
    TotalpartyLengthCell.alignment = { vertical: 'middle', horizontal: 'center' }

    let invoiceLength1 = invoiceLength;
    let invoiceLengthCell = worksheet.getCell('C3');
    invoiceLengthCell.value = invoiceLength1;
    invoiceLengthCell.alignment = { vertical: 'middle', horizontal: 'center' }

    let TotalAmount1 = TotalAmount;
    let TotalAmountCell = worksheet.getCell('E3');
    TotalAmountCell.value = TotalAmount1;

    let TotalTaxableValue1 = TotalTaxableValue;
    let TotalTaxableValueCell = worksheet.getCell('L3');
    TotalTaxableValueCell.value = TotalTaxableValue1;

    let TotalCess1 = TotalCess;
    let TotalCessCell = worksheet.getCell('M3');
    TotalCessCell.value = TotalCess1;

    //file data b2cs worksheet ---b2ba---
        //Add a Row
        let headerRow0b2cs = worksheetb2cs.addRow(["Summary For B2CS"]);
        headerRow0b2cs.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0070C0' },
            bgColor: { argb: '' },
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 11
          }
          headerRow0b2cs.alignment = { vertical: 'middle', horizontal: 'center' }
        })
    
        let headerRow1b2cs = worksheetb2cs.addRow(["No.of Recipients", "", "No. of Invoices", "", "Total Invoice Value", "", "", "", "", "", "", "Total Taxable Value", "Total Cess"]);
        headerRow1b2cs.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0070C0' },
            bgColor: { argb: '' },
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 11
          }
          headerRow1b2cs.alignment = { vertical: 'middle', horizontal: 'center' }
        })
    
        worksheetb2cs.addRow([]);
    
        let headerb2cs = ["GSTIN/UIN of Recipient", "Receiver Name", "Invoice Number", "Invoice date", "Invoice Value", "Place Of Supply", "Reverse Charge", "Applicable % of Tax Rate", "Invoice Type", "E-Commerce GSTIN", "Rate", "Taxable Value", "Cess Amount"]
        let headerRowb2cs = worksheetb2cs.addRow(headerb2cs)
        
        headerRowb2cs.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E6B8B7' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: '000000' },
            size: 11
          }
        })
        headerRowb2cs.alignment = { vertical: 'middle', horizontal: 'center' }
    
    
        worksheetb2cs.getColumn(1).width = 20;
        worksheetb2cs.getColumn(2).width = 15;
        worksheetb2cs.getColumn(3).width = 15;
        worksheetb2cs.getColumn(4).width = 15;
        worksheetb2cs.getColumn(5).width = 20;
        worksheetb2cs.getColumn(6).width = 15;
        worksheetb2cs.getColumn(7).width = 15;
        worksheetb2cs.getColumn(8).width = 22;
        worksheetb2cs.getColumn(9).width = 15;
        worksheetb2cs.getColumn(10).width = 20;
        worksheetb2cs.getColumn(11).width = 10;
        worksheetb2cs.getColumn(12).width = 20;
        worksheetb2cs.getColumn(13).width = 15;
    
        for (let x1 of b2csExcelData) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheetb2cs.addRow(temp)
        }
    
        let TotalpartyLength1b2cs = b2cspartyLength;
        let TotalpartyLengthCellb2cs = worksheetb2cs.getCell('A3');
        TotalpartyLengthCellb2cs.value = TotalpartyLength1b2cs;
        TotalpartyLengthCellb2cs.alignment = { vertical: 'middle', horizontal: 'center' }
    
        let invoiceLength1b2cs = b2csinvoiceLength;
        let invoiceLengthCellb2cs = worksheetb2cs.getCell('C3');
        invoiceLengthCellb2cs.value = invoiceLength1b2cs;
        invoiceLengthCellb2cs.alignment = { vertical: 'middle', horizontal: 'center' }
    
        let TotalAmount1b2cs = b2csTotalAmount;
        let TotalAmountCellb2cs = worksheetb2cs.getCell('E3');
        TotalAmountCellb2cs.value = TotalAmount1b2cs;
    
        let TotalTaxableValue1b2cs = b2csTotalTaxableValue;
        let TotalTaxableValueCellb2cs = worksheetb2cs.getCell('L3');
        TotalTaxableValueCellb2cs.value = TotalTaxableValue1b2cs;
    
        let TotalCess1b2cs = b2csTotalCess;
        let TotalCessCellb2cs = worksheetb2cs.getCell('M3');
        TotalCessCellb2cs.value = TotalCess1b2cs;
    
    //hsn data
    // let worksheethsn = workbookhsn.addWorksheet('hsn');

    //Add a Row
    let headerRow0hsn = worksheethsn.addRow(["Summary For HSN(12)"]);
    headerRow0hsn.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow0hsn.alignment = { vertical: 'middle', horizontal: 'center' }
    })
  
    let headerRow1hsn = worksheethsn.addRow(["No. of HSN", "", "", "", "Total Value", "Total Taxable Value", "Total Integrated Tax", "Total Central Tax", "Total State/UT Tax", "Total Cess","Rate"]);
    headerRow1hsn.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1hsn.alignment = { vertical: 'middle', horizontal: 'center' }
    })
  
    worksheethsn.addRow([]);
  
    let headerhsn = ["HSN", "Description", "UQC", "Total Quantity", "Total Value", "Taxable Value", "Integrated Tax Amount", "Central Tax Amount", "State/UT Tax Amount", "Cess Amount","Rate"]
    let headerRowhsn = worksheethsn.addRow(headerhsn)
    headerRowhsn.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRowhsn.alignment = { vertical: 'middle', horizontal: 'center' }
  
    worksheethsn.getColumn(1).width = 20;
    worksheethsn.getColumn(2).width = 15;
    worksheethsn.getColumn(3).width = 15;
    worksheethsn.getColumn(4).width = 15;
    worksheethsn.getColumn(5).width = 20;
    worksheethsn.getColumn(6).width = 20;
    worksheethsn.getColumn(7).width = 22;
    worksheethsn.getColumn(8).width = 22;
    worksheethsn.getColumn(9).width = 20;
    worksheethsn.getColumn(10).width = 20;
  
    for (let x11 of datahsn) {
      let x22 = Object.keys(x11);
      let temphsn = []
      for (let y1 of x22) {
        temphsn.push(x11[y1])
      }
      worksheethsn.addRow(temphsn)
    }
  
    let TotalHSN = NoofHSN;
    let TotalHSNCell = worksheethsn.getCell('A3');
    TotalHSNCell.value = TotalHSN;
    TotalHSNCell.alignment = { vertical: 'middle', horizontal: 'center' }
  
    let TotalIntegratedtax = TotalIntegratedTax;
    let TotalIntegratedTaxCell = worksheethsn.getCell('G3');
    TotalIntegratedTaxCell.value = TotalIntegratedtax;
    // TotalIntegratedTaxCell.alignment = { vertical: 'middle', horizontal: 'center' }
  
    let totalvalue = TotalValue;
    let totalvalueCell = worksheethsn.getCell('E3');
    totalvalueCell.value = totalvalue;
  
    let TotalTaxableValuehsn = TotalTaxableValue11;
    let TotalTaxableValuehsnCell = worksheethsn.getCell('F3');
    TotalTaxableValuehsnCell.value = TotalTaxableValuehsn;
  
    let TotalCesshsn1 = TotalCesshsn;
    let TotalCesshsnCell = worksheethsn.getCell('J3');
    TotalCesshsnCell.value = TotalCesshsn1;
  
    let TotalCentralTaxhsn = TotalCentralTax;
    let TotalCentralTaxCell = worksheethsn.getCell('H3');
    TotalCentralTaxCell.value = TotalCentralTaxhsn;
  
    let TotalStateUTTaxhsn = TotalStateUTTax;
    let TotalStateUTTaxCell = worksheethsn.getCell('I3');
    TotalStateUTTaxCell.value = TotalStateUTTaxhsn;


    //masterB2CS data
    //Add a Row
    let headerRow0master = worksheetmaster.addRow(["Summary For B2CS(7)"]);
    headerRow0master.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow0master.alignment = { vertical: 'middle', horizontal: 'center' }
    })
  
    let headerRow1master = worksheetmaster.addRow(["", "", "", "", "Total Taxable Value", "Total Cess", ""]);
    headerRow1master.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1master.alignment = { vertical: 'middle', horizontal: 'center' }
    })
  
    worksheetmaster.addRow([]);
  
    let headermaster = ["Type", "Place Of Supply", "Applicable % Of Tax Rate", "Rate", "Taxable Value", "Cess Amount", "E-Commerce GSTIN"]
    let headerRowmaster = worksheetmaster.addRow(headermaster)
    headerRowmaster.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRowmaster.alignment = { vertical: 'middle', horizontal: 'center' }
  
    worksheetmaster.getColumn(1).width = 20;
    worksheetmaster.getColumn(2).width = 15;
    worksheetmaster.getColumn(3).width = 25;
    worksheetmaster.getColumn(4).width = 15;
    worksheetmaster.getColumn(5).width = 20;
    worksheetmaster.getColumn(6).width = 15;
    worksheetmaster.getColumn(7).width = 20;
  
    for (let x11mst of datamaster) {
      let x22mst = Object.keys(x11mst);
      let tempmaster = []
      for (let y1mst of x22mst) {
        tempmaster.push(x11mst[y1mst])
      }
      worksheetmaster.addRow(tempmaster)
    }
  
    let TotalTaxableValuemaster = TotalTaxableValue2;
    let TotalTaxableValuemasterCell = worksheetmaster.getCell('E3');
    TotalTaxableValuemasterCell.value = TotalTaxableValuemaster;
  
    let TotalCessmaster1 = TotalCessmaster;
    let TotalCessmasterCell = worksheetmaster.getCell('F3');
    TotalCessmasterCell.value = TotalCessmaster1;


    //docs data
    //Add a Row
    let headerRow0docs = worksheetdocs.addRow(["Summary of documents issued during the tax period(13)"]);
    headerRow0docs.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow0docs.alignment = { vertical: 'middle', horizontal: 'center' }
      worksheetdocs.getCell('A1').alignment = { wrapText: true };
    })
  
    let headerRow1docs = worksheetdocs.addRow(["", "", "", "Total Number", "Total Cancelled"]);
    headerRow1docs.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1docs.alignment = { vertical: 'middle', horizontal: 'center' }
    })
  
    worksheetdocs.addRow([]);
  
    let headerdocs = ["Nature of Document", "Sr. No. From", "Sr. No. To", "Total Number", "Cancelled"]
    let headerRowdocs = worksheetdocs.addRow(headerdocs)
    headerRowdocs.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRowdocs.alignment = { vertical: 'middle', horizontal: 'center' }
  
    worksheetdocs.getColumn(1).width = 30;
    worksheetdocs.getColumn(2).width = 15;
    worksheetdocs.getColumn(3).width = 15;
    worksheetdocs.getColumn(4).width = 15;
    worksheetdocs.getColumn(5).width = 15;
  
    for (let x11docs of datadocs) {
      let x22docs = Object.keys(x11docs);
      let tempdocs = []
      for (let y1docs of x22docs) {
        tempdocs.push(x11docs[y1docs])
      }
      worksheetdocs.addRow(tempdocs)
    }
  
    let totalNumber = TotalNumber;
    let totalNumberCell = worksheetdocs.getCell('D3');
    totalNumberCell.value = totalNumber;
  
    let TotalCancelldedoc = TotalCancelldedocs;
    let TotalCancelldedocCell = worksheetdocs.getCell('E3');
    TotalCancelldedocCell.value = TotalCancelldedoc;

    let NatureofDocumentData0 = NatureofDocumentData[0];
    let NatureofDocumentData0Cell = worksheetdocs.getCell('A5');
    NatureofDocumentData0Cell.value = NatureofDocumentData0;

    let NatureofDocumentData1 = NatureofDocumentData[1];
    let NatureofDocumentData1Cell = worksheetdocs.getCell('A6');
    NatureofDocumentData1Cell.value = NatureofDocumentData1;

    let NatureofDocumentData2 = NatureofDocumentData[2];
    let NatureofDocumentData2Cell = worksheetdocs.getCell('A7');
    NatureofDocumentData2Cell.value = NatureofDocumentData2;

    let NatureofDocumentData3 = NatureofDocumentData[3];
    let NatureofDocumentData3Cell = worksheetdocs.getCell('A8');
    NatureofDocumentData3Cell.value = NatureofDocumentData3;

    let NatureofDocumentData4 = NatureofDocumentData[4];
    let NatureofDocumentData4Cell = worksheetdocs.getCell('A9');
    NatureofDocumentData4Cell.value = NatureofDocumentData4;
  
    //Add a Row  ---cdnr---
    let headerRow0cdnr = worksheetcdnr.addRow(["Summary For CDNR(9B)"]);
    headerRow0cdnr.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow0cdnr.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    let headerRow1cdnr = worksheetcdnr.addRow(["No.of Recipients", "", "No. of Notes", "", "", "", "", "", "Total Note Value", "", "", "Total Taxable Value", "Total Cess"]);
    headerRow1cdnr.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1cdnr.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    worksheetcdnr.addRow([]);

    let headercdnr = ["GSTIN/UIN of Recipient", "Receiver Name", "Note Number", "Note Date", "Note Type", "Place Of Supply", "Reverse Charge", "Note Supply Type", "Note Value", "Applicable % of Tax Rate", "Rate", "Taxable Value", "Cess Amount"]
    let headerRowcdnr = worksheetcdnr.addRow(headercdnr)
    headerRowcdnr.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRowcdnr.alignment = { vertical: 'middle', horizontal: 'center' }


    worksheetcdnr.getColumn(1).width = 22;
    worksheetcdnr.getColumn(2).width = 20;
    worksheetcdnr.getColumn(3).width = 20;
    worksheetcdnr.getColumn(4).width = 15;
    worksheetcdnr.getColumn(5).width = 10;
    worksheetcdnr.getColumn(6).width = 15;
    worksheetcdnr.getColumn(7).width = 15;
    worksheetcdnr.getColumn(8).width = 20;
    worksheetcdnr.getColumn(9).width = 17;
    worksheetcdnr.getColumn(10).width = 25;
    worksheetcdnr.getColumn(11).width = 10;
    worksheetcdnr.getColumn(12).width = 20;
    worksheetcdnr.getColumn(13).width = 15;

    for (let x1cdnr of datacdnr) {
      let x2cdnr = Object.keys(x1cdnr);
      let tempcdnr = []
      for (let ycdnr of x2cdnr) {
        tempcdnr.push(x1cdnr[ycdnr])
      }
      worksheetcdnr.addRow(tempcdnr)
    }

    let TotalReceiverLength1 = TotalReceiverLength;
    let TotalReceiverLengthCell = worksheetcdnr.getCell('A3');
    TotalReceiverLengthCell.value = TotalReceiverLength1;
    TotalReceiverLengthCell.alignment = { vertical: 'middle', horizontal: 'center' }

    let TotalVoucherLength1 = TotalVoucherLength;
    let TotalVoucherLengthCell = worksheetcdnr.getCell('C3');
    TotalVoucherLengthCell.value = TotalVoucherLength1;
    TotalVoucherLengthCell.alignment = { vertical: 'middle', horizontal: 'center' }
    
    // let TotalInvoiceAdvanceReceiptNumber1 = TotalInvoiceAdvanceReceiptNumber;
    // let TotalInvoiceAdvanceReceiptNumberCell = worksheetcdnr.getCell('C3');
    // TotalInvoiceAdvanceReceiptNumberCell.value = TotalInvoiceAdvanceReceiptNumber1;

    let TotalVoucherValue1 = TotalVoucherValue;
    let TotalVoucherValueCell = worksheetcdnr.getCell('I3');
    TotalVoucherValueCell.value = TotalVoucherValue1;

    let TotalTaxableValueCdnr1 = TotalTaxableValueCdnr;
    let TotalTaxableValueCdnrCell = worksheetcdnr.getCell('L3');
    TotalTaxableValueCdnrCell.value = TotalTaxableValueCdnr1;

    let TotalCessCdnr1 = TotalCessCdnr;
    let TotalCessCdnrCell = worksheetcdnr.getCell('M3');
    TotalCessCdnrCell.value = TotalCessCdnr1;


    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportExcelLedger(filename, excelData, txtDebitOpeningBalance, txtCreditOpeningBalance,txtTotalDebit, txtTotalCredit,txtDebitClosingBalance, txtCreditClosingBalance, ExcelpartyName: string, partyCellNumber, ExcelpartyAddress) {

    //Title, Data
    const title = filename;
    const data = excelData;
    const openingdebit = txtDebitOpeningBalance;
    const openingcredit = txtCreditOpeningBalance;
    const totalDebit = txtTotalDebit;
    const totalCredit = txtTotalCredit;
    const closeingdebit = txtDebitClosingBalance;
    const closeingcredit = txtCreditClosingBalance;
    const partyname = ExcelpartyName;
    const partycellnumber = partyCellNumber;
    const partyaddress = ExcelpartyAddress;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Ledger');

    let partyNameRow = worksheet.addRow(["Party Name", "", partyname, "", ""]);
    partyNameRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      partyNameRow.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    let partyCellNumberRow = worksheet.addRow(["Party CellNumber", "", partycellnumber, "", ""]);
    partyCellNumberRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      partyCellNumberRow.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    let partyAddressRow = worksheet.addRow(["Party Address", "", partyaddress, "", ""]);
    partyAddressRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      partyAddressRow.alignment = { vertical: 'middle', horizontal: 'center' }
    })

    let header = ["Date", "Particulars", "Debit(Rs.)", "Credit(Rs.)", "Balance(Rs.)"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    let headerRow1 = worksheet.addRow(["Opening Balance", "", openingdebit, openingcredit, ""]);
    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "","Current Total", totalDebit, totalCredit, ""]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    let headerRow3 = worksheet.addRow([ "","Closing Balance", closeingdebit, closeingcredit, ""]);
    headerRow3.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow3.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportExcelGSTR3B(selectMonth: string,gstr3bfileName: string,saleNetAmount: number,saleCGst: number,saleSGst: number,saleIGst: number,purchaseCGst: number,purchaseSGst: number,purchaseIGst: number,txtGstNumber : string,txtOwnerName : string) {
    //Title
    const title = gstr3bfileName;
    var currentDate= this.datePipe.transform(new Date(), "dd/MM/yyyy");

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('b2b1');

    // // add a table to a sheet
    // worksheet.addTable({
    //   name: 'MyTable',
    //   ref: 'A1',
    //   // headerRow: true,
    //   // totalsRow: true,
    //   style: {
    //     // theme: 'TableStyleDark3',
    //     theme: 'TableStyleMedium9',
    //     // showRowStripes: true,
    //   },
    //   columns: [
    //     { name: 'Date' },
    //     { name: 'Amount' },
    //   ],
    //   rows: [
    //     [new Date('2019-07-20'), 70.10],
    //     [new Date('2019-07-21'), 70.60],
    //     [new Date('2019-07-22'), 70.10],
    //   ],
    // });
    // worksheet.getColumn(1).width = 20;
    // worksheet.getColumn(2).width = 20;

    //  add a table 1 to a sheet 
    worksheet.addTable({
      name: 'MyTable1',
      ref: 'A1',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Financial year' },
        { name: 'Tax period' },
      ],
      rows: [
        ["2022-23"],
        [selectMonth],
      ],
    });

    // worksheet.mergeCells('B4:C4');
    // worksheet.mergeCells('A5:A9');
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;

    //  add a table 2 to a sheet 
    worksheet.addTable({
      name: 'MyTable2',
      ref: 'A4',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: '1' },
        { name: 'GSTIN' },
        { name: ' ' },
        // { name: '24ABRPP2920J1ZF' },
        { name: txtGstNumber },
      ],
      rows: [
        // ["1","","GSTIN","24ABRPP2920J1ZF"],
        // ["2", "(a)", "Legal name of the registered person", "CHAMPAKBHAI MANCHHUBHAI PATEL"],
        ["2", "(a)", "Legal name of the registered person", txtOwnerName],
        ["", "(b)", "Trade name, if any", gstr3bfileName],
        ["", "(c)", "GSTR-1 fling date", currentDate],
        ["", "(d)", "GSTR-2B generation date", currentDate],
        ["", "(e)", "GSTR-3B Summary Generation date", currentDate],
      ],
    });

    // worksheet.mergeCells('B4:C4');
    // worksheet.mergeCells('A5:A9');
    worksheet.addRow(["Section I: Auto-populated details of Table 3.1,3.2 and 4 of FORM GSTR-3B Taxpayers may use this data to fle their FORM GSTR-3B"]);
    worksheet.addRow([]);
    worksheet.addRow(["A. Table 3.1 of FORM GSTR-3B : Details of Outward supplies and inward supplies liable to reverse charge"]);
    worksheet.mergeCells('A10:F10');
    worksheet.mergeCells('A12:F12');

    //  add a table 3 to a sheet 
    worksheet.addTable({
      name: 'MyTable3',
      ref: 'A14',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Nature of supplies' },
        { name: 'Total taxable value' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["(a)Outward taxable supplies (other than zero rated, nil rated and exempted)", saleNetAmount, saleIGst, saleCGst, saleSGst, "0.00"],
        ["(b)Outward taxable supplies (zero rated)", "0.00", "0.00", "", "", "0.00"],
        ["(c)Other outward supplies (Nil rated,exempted)", "0.00", "", "", "", ""],
        ["(d)Inward supplies(liable to reverse charge)", "0.00", "0.00", "0.00", "0.00", "0.00"],
        ["(e)Non-GST outward supplies", "0.00", "", "", "", ""],
      ],
    });
    worksheet.addRow(["B. Table 3.2 of FORM GSTR-3B : Of the supplies shown in 3.1(a) above, details of inter-state supplies made to unregistered persons,composition taxable persons and UIN holders"]);
    worksheet.addRow([]);
    worksheet.mergeCells('A20:F20');
    // worksheet.getRow(16).getCell(1).alignment={vertical:'middle',wrapText:true};
    // worksheet.getCell("r:A15, c:A19").alignment = { WrapText: true };
    worksheet.getCell('A15').alignment = { wrapText: true };
    worksheet.getCell('A16').alignment = { wrapText: true };
    worksheet.getCell('A17').alignment = { wrapText: true };
    worksheet.getCell('A18').alignment = { wrapText: true };
    worksheet.getCell('A19').alignment = { wrapText: true };
    // worksheet.getColumn(1).alignment = { vertical: 'middle', wrapText: true };

    //  add a table 4 to a sheet 
    worksheet.addTable({
      name: 'MyTable4',
      ref: 'A22',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Nature of supplies' },
        { name: 'Place of supply(State/UT)' },
        { name: 'Total taxable value' },
        { name: 'Amount of Integrated tax' },
      ],
      rows: [
        ["Supplies made to Unregistered persons", "", "0.00", "0.00"],
        ["Supplies made to Composition taxable persons", "", "0.00", "0.00"],
        ["Supplies made to UIN holders", "", "0.00", "0.00"],
      ],
    });
    worksheet.getCell('A23').alignment = { wrapText: true };
    worksheet.getCell('A24').alignment = { wrapText: true };
    worksheet.getCell('A25').alignment = { wrapText: true };
    worksheet.addRow(["C. Table 4 of FORM GSTR-3B : Eligible ITC"]);
    worksheet.addRow([]);
    worksheet.mergeCells('A26:F26');

    //  add a table 5 to a sheet 
    worksheet.addTable({
      name: 'MyTable5',
      ref: 'A28',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Details' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["(A) ITC Available (whether in full or part)", "", "", "", ""],
        ["(1) Import of goods", "0.00", "", "", "0.00"],
        ["(2) Import of services", "", "", "", ""],
        ["(3) Inward supplies liable to reverse charge (other than 1 & 2 above)", "0.00", "0.00", "0.00", "0.00"],
        ["(4) Inward supplies from ISD", "0.00", "0.00", "0.00", "0.00"],
        ["(5) All other ITC", purchaseIGst, purchaseCGst, purchaseSGst, "0.00"],
        ["(B) ITC Reversed", "", "", "", ""],
        ["(1) As per rules 42 & 43 of CGST Rules", "", "", "", ""],
        ["(2) Others", "0.00", "0.00", "0.00", "0.00"],
        ["(C) Net ITC Available (A)-(B)", "0.00", "0.00", "0.00", "0.00"],
        ["(D) Ineligible ITC", "", "", "", ""],
        ["(1) As per section 17(5)", "", "", "", ""],
        ["(2) Others", "", "", "", ""],
      ],
    });
    worksheet.getCell('A29').alignment = { wrapText: true };
    worksheet.getCell('A30').alignment = { wrapText: true };
    worksheet.getCell('A31').alignment = { wrapText: true };
    worksheet.getCell('A32').alignment = { wrapText: true };
    worksheet.getCell('A33').alignment = { wrapText: true };
    worksheet.getCell('A34').alignment = { wrapText: true };
    worksheet.getCell('A35').alignment = { wrapText: true };
    worksheet.getCell('A36').alignment = { wrapText: true };
    worksheet.getCell('A37').alignment = { wrapText: true };
    worksheet.getCell('A38').alignment = { wrapText: true };
    worksheet.getCell('A39').alignment = { wrapText: true };
    worksheet.getCell('A40').alignment = { wrapText: true };
    worksheet.getCell('A41').alignment = { wrapText: true };

    worksheet.addRow(["Section II : Detailed breakup of values in Table 3.1 and 3.2 of FORM GSTR-3B Taxpayers may use this data for reconciliation of details auto-populated in Section I above and values fled by them in their FORM GSTR-1 or refected in FORM GSTR-2B"]);
    worksheet.addRow([]);
    worksheet.addRow(["A. Break up of Table 3.1 (a) of GSTR-3B"]);
    worksheet.mergeCells('A42:F42');
    worksheet.mergeCells('A44:F44');

    //  add a table 6 to a sheet 
    worksheet.addTable({
      name: 'MyTable6',
      ref: 'A46',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Total taxable value' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["4A", "Taxable outward supplies made to registered persons(other than zero rated supplies)", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["4B", "Outward supplies made to registered persons attracting tax on reverse charge", "0.00", "", "", "",""],
        ["4C", "Taxable outward supplies made through e-commerce operator attracting TCS", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["5A", "Taxable outward inter-state supplies made to unregistered persons (where invoice value is more than Rs.2.5 lakhs)", "0.00", "0.00", "", "","0.00"],
        ["5B", "Taxable outward inter-state supplies made through ecommerce operator attracting TCS (where invoice value is more than Rs. 2.5 lakhs)", "0.00", "0.00", "", "","0.00"],
        ["6C", "Deemed exports", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["7A_1", "Taxable outward intra-state supplies made to unregistered persons", "0.00", "", "0.00", "0.00","0.00"],
        ["7B_1", "Taxable outward inter-state supplies made to unregistered persons (where invoice value is upto Rs. 2.5 lakhs)", "0.00", "0.00", "", "","0.00"],
        ["9A", "Amendments made to invoices declared in previous tax periods in Tables-4, 5 and 6C", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["9B", "Credit/Debit Notes pertaining to invoices declared in Tables-4, 5 and 6C", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["9C", "Amendments to Credit/Debit Notes pertaining to invoices declared in Tables-4, 5 and 6C", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["10A", "Amendment to taxable outward intra-state supplies made to unregistered persons declared in Table-7 in previous tax periods", "0.00", "", "0.00", "0.00","0.00"],
        ["10B", "Amendment to taxable outward intra-state supplies made to unregistered persons declared in Table-7 in previous tax periods", "0.00", "0.00", "", "","0.00"],
        ["11_I_A1", "Advance amount received in the tax period - Intra-state", "0.00", "", "0.00", "0.00","0.00"],
        ["11_I_A2", "Advance amount received in the tax period - Inter-state", "0.00", "0.00", "", "","0.00"],
        ["11_I_B1", "Advance amount received in earlier tax period and adjusted against supplies being shown in this tax period - Intra-state", "0.00", "", "0.00", "0.00","0.00"],
        ["11_I_B2", "Advance amount received in earlier tax period and adjusted against supplies being shown in this tax period - Inter-state", "0.00", "0.00", "", "","0.00"],
        ["11_II", "Amendments to information regarding advances in previous tax periods", "0.00", "0.00", "0.00", "0.00","0.00"],
        [" ", "Total", "0.00", "0.00", "0.00", "0.00","0.00"],
      ],
    });
    // worksheet.mergeCells('A46:B46');
    worksheet.getCell('B47').alignment = { wrapText: true };
    worksheet.getCell('B48').alignment = { wrapText: true };
    worksheet.getCell('B49').alignment = { wrapText: true };
    worksheet.getCell('B50').alignment = { wrapText: true };
    worksheet.getCell('B51').alignment = { wrapText: true };
    worksheet.getCell('B52').alignment = { wrapText: true };
    worksheet.getCell('B53').alignment = { wrapText: true };
    worksheet.getCell('B54').alignment = { wrapText: true };
    worksheet.getCell('B55').alignment = { wrapText: true };
    worksheet.getCell('B56').alignment = { wrapText: true };
    worksheet.getCell('B57').alignment = { wrapText: true };
    worksheet.getCell('B58').alignment = { wrapText: true };
    worksheet.getCell('B59').alignment = { wrapText: true };
    worksheet.getCell('B60').alignment = { wrapText: true };
    worksheet.getCell('B61').alignment = { wrapText: true };
    worksheet.getCell('B62').alignment = { wrapText: true };
    worksheet.getCell('B63').alignment = { wrapText: true };
    worksheet.getCell('B64').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["B. Break up of Table 3.1 (b) of GSTR-3B"]);
    worksheet.mergeCells('A67:F67');

    //  add a table 7 to a sheet 
    worksheet.addTable({
      name: 'MyTable7',
      ref: 'A68',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Total taxable value' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["6A", "Exports", "0.00", "0.00", "", "","0.00"],
        ["6B", "Supplies made to SEZ unit or SEZ developer", "0.00", "0.00", "", "","0.00"],
        ["9A", "Amendments made to invoices declared in previous tax periods in Table-6A, 6B", "0.00", "0.00", "", "","0.00"],
        ["9B", "Credit/Debit Notes pertaining to invoices declared in Table-6A,6B", "0.00", "0.00", "", "","0.00"],
        ["9C", "Amendments to Credit/Debit Notes pertaining to invoices declared in Table-6A,6B", "0.00", "0.00", "", "","0.00"],
        [" ", "Total", "0.00", "0.00", "", "","0.00"],
      ],
    });
    // worksheet.mergeCells('A68:B68');
    worksheet.getCell('B69').alignment = { wrapText: true };
    worksheet.getCell('B70').alignment = { wrapText: true };
    worksheet.getCell('B71').alignment = { wrapText: true };
    worksheet.getCell('B72').alignment = { wrapText: true };
    worksheet.getCell('B73').alignment = { wrapText: true };
    worksheet.getCell('B74').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["C. Break up of Table 3.1 (c) of GSTR-3B"]);
    worksheet.mergeCells('A76:F76');

     //  add a table 8 to a sheet 
     worksheet.addTable({
      name: 'MyTable8',
      ref: 'A77',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Total taxable value' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["8", "Nil rated and exempted supplies (columns 2 and 3)", "0.00", "", "", "",""],
      ],
    });
    // worksheet.mergeCells('A77:B77');
    worksheet.getCell('B78').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["D. Break up of Table 3.1 (d) of GSTR-3B"]);
    worksheet.mergeCells('A80:F80');
    
     //  add a table 9 to a sheet 
     worksheet.addTable({
      name: 'MyTable9',
      ref: 'A81',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-2B' },
        { name: ' ' },
        { name: 'Total taxable value' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["3_Part A_ III", "ITC Available - Inward Supplies liable for reverse charge", "0.00", "0.00", "0.00", "0.00","0.00"],
        ["4_Part A_III", "ITC not available - Inward Supplies liable for reverse charge", "0.00", "0.00", "0.00", "0.00","0.00"],
      ],
    });
    // worksheet.mergeCells('A81:B81');
    worksheet.getCell('B82').alignment = { wrapText: true };
    worksheet.getCell('B83').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["E. Break up of Table 3.1 (e) of GSTR-3B"]);
    worksheet.mergeCells('A85:F85');

    //  add a table 10 to a sheet 
    worksheet.addTable({
      name: 'MyTable10',
      ref: 'A86',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Total taxable value' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["8", "Non-GST outward supplies (column 4)", "0.00", "", "", "",""],
      ],
    });
    // worksheet.mergeCells('A86:B86');
    worksheet.getCell('B87').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["F. Break up of Table 3.2 (Supplies made to Unregistered persons)"]);
    worksheet.mergeCells('A89:F89');
    worksheet.addRow([]);

    //  add a table 11 to a sheet 
    worksheet.addTable({
      name: 'MyTable11',
      ref: 'A91',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Place of supply (State/UT)' },
        { name: 'Total taxable value' },
        { name: 'Amount Of Integrated tax' },
      ],
      rows: [
        ["5A", "Taxable outward inter-state supplies made to un-registered persons (where invoice value is more than Rs. 2.5 lakhs)", "", "0.00", "0.00"],
        ["7B_1", "Taxable outward inter-state supplies made to un-registered persons (where invoice value is upto Rs. 2.5 lakhs)", "", "0.00", "0.00"],
        ["9A", "Amendments made to invoices declared in previous tax periods in Table-5A", "", "0.00", "0.00"],
        ["9B", "Credit/Debit Notes pertaining to invoices declared in Table-5A", "", "0.00", "0.00"],
        ["9C", "Amendments to Credit/Debit Notes pertaining to Credit/Debit notes declared in Table-9B", "", "0.00", "0.00"],
        ["10B", "Amendment to taxable outward inter-state supplies made to un-registered persons declared in Table-7 in previous tax periods", "", "0.00", "0.00"],
        ["11_I_A2", "Advance amount received in the tax period - Inter-state", "", "0.00", "0.00"],
        ["11_I_B2", "Advance amount received in earlier tax period and adjusted against supplies being shown in this tax period - Inter-state", "", "0.00", "0.00"],
        ["11_II", "Amendments to information regarding advances in previous tax periods and amendments to information regarding adjustment against supplies", "", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A91:B91');
    worksheet.getCell('B92').alignment = { wrapText: true };
    worksheet.getCell('B93').alignment = { wrapText: true };
    worksheet.getCell('B94').alignment = { wrapText: true };
    worksheet.getCell('B95').alignment = { wrapText: true };
    worksheet.getCell('B96').alignment = { wrapText: true };
    worksheet.getCell('B97').alignment = { wrapText: true };
    worksheet.getCell('B98').alignment = { wrapText: true };
    worksheet.getCell('B99').alignment = { wrapText: true };
    worksheet.getCell('B100').alignment = { wrapText: true };
    
    worksheet.addRow([]);
    worksheet.addRow(["G. Break up of Table 3.2 (Supplies made to Composition taxable persons)"]);
    worksheet.mergeCells('A102:F102');
    worksheet.addRow([]);

    
    //  add a table 12 to a sheet 
    worksheet.addTable({
      name: 'MyTable12',
      ref: 'A104',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Place of supply (State/UT)' },
        { name: 'Total taxable value' },
        { name: 'Amount Of Integrated tax' },
      ],
      rows: [
        ["4A", "Taxable outward supplies made to registered persons (other than zero rated supplies)", "", "0.00", "0.00"],
        ["4C", "Taxable outward supplies made through e-commerce operator attracting TCS", "", "0.00", "0.00"],
        ["9A", "Amendments made to invoices declared in previous tax periods in Table-4A, 4C", "", "0.00", "0.00"],
        ["9B", "Credit/Debit Notes pertaining to invoices declared in Table-4A, 4C", "", "0.00", "0.00"],
        ["9C", "Amendments to Credit/Debit Notes pertaining to invoices declared in Table-9B", "", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A104:B104');
    worksheet.getCell('B105').alignment = { wrapText: true };
    worksheet.getCell('B106').alignment = { wrapText: true };
    worksheet.getCell('B107').alignment = { wrapText: true };
    worksheet.getCell('B108').alignment = { wrapText: true };
    worksheet.getCell('B109').alignment = { wrapText: true };
    
    worksheet.addRow([]);
    worksheet.addRow(["H. Break up of Table 3.2 (Supplies made to UIN holders)"]);
    worksheet.mergeCells('A111:F111');
    worksheet.addRow([]);
    
    //  add a table 13 to a sheet 
    worksheet.addTable({
      name: 'MyTable13',
      ref: 'A113',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-1' },
        { name: ' ' },
        { name: 'Place of supply (State/UT)' },
        { name: 'Total taxable value' },
        { name: 'Amount Of Integrated tax' },
      ],
      rows: [
        ["4A", "Taxable outward supplies made to registered persons (other than zero rated supplies)", "", "0.00", "0.00"],
        ["4C", "Taxable outward supplies made through e-commerce operator attracting TCS", "", "0.00", "0.00"],
        ["9A", "Amendments made to invoices declared in previous tax periods in Table-4A, 4C", "", "0.00", "0.00"],
        ["9B", "Credit/Debit Notes pertaining to invoices declared in Table-4A, 4C", "", "0.00", "0.00"],
        ["9C", "Amendments to Credit/Debit Notes pertaining to invoices declared in Table-9B", "", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A113:B113');
    worksheet.getCell('B114').alignment = { wrapText: true };
    worksheet.getCell('B115').alignment = { wrapText: true };
    worksheet.getCell('B116').alignment = { wrapText: true };
    worksheet.getCell('B117').alignment = { wrapText: true };
    worksheet.getCell('B118').alignment = { wrapText: true };
    
    worksheet.addRow([]);
    worksheet.addRow(["Section III : Detailed breakup of values in Table 4 of FORM GSTR-3B Taxpayers may use this data for reconciliation of details auto-populated in Section I above and values refected in their FORM GSTR-2B"]);
    worksheet.mergeCells('A120:F120');
    worksheet.addRow([]);
    worksheet.addRow(["A. Break up of Table 4A(1) (ITC Available - Import of goods)"]);
    worksheet.mergeCells('A122:F122');
    worksheet.addRow([]);

    //  add a table 14 to a sheet 
    worksheet.addTable({
      name: 'MyTable14',
      ref: 'A124',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-2B' },
        { name: ' ' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["3_Part A_IV", "ITC Available - Import of Goods", "0.00", "", "", "0.00"],
      ],
    });
    // worksheet.mergeCells('A124:B124');
    worksheet.getCell('B125').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["B. Break up of Table 4A(3) (ITC Available - Inward supplies liable to reverse charge (other than 1 & 2 above))"]);
    worksheet.mergeCells('A127:F127');
    worksheet.addRow([]);
    
    
    //  add a table 16 to a sheet 
    worksheet.addTable({
      name: 'MyTable16',
      ref: 'A129',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-2B' },
        { name: ' ' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["3_Part A_III", "ITC Available - Inward Supplies liable for reverse charge", "0.00", "0.00", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A129:B129');
    worksheet.getCell('B130').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["C. Break up of Table 4A(4) (ITC Available - Inward Supplies from ISD)"]);
    worksheet.mergeCells('A132:F132');
    worksheet.addRow([]);
    
    //  add a table 17 to a sheet 
    worksheet.addTable({
      name: 'MyTable17',
      ref: 'A134',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-2B' },
        { name: ' ' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["3_Part A_II", "ITC Available - Inward Supplies from ISD", "0.00", "0.00", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A134:B134');
    worksheet.getCell('B135').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["D. Break up of Table 4A(5) (ITC Available - All other ITC - Supplies from registered persons other than reverse charge)"]);
    worksheet.mergeCells('A137:F137');
    worksheet.addRow([]);
    
    //  add a table 18 to a sheet 
    worksheet.addTable({
      name: 'MyTable18',
      ref: 'A139',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-2B' },
        { name: ' ' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["3_Part A_I", "ITC Available - All other ITC - Supplies from registered persons other than reverse charge", "0.00", "0.00", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A139:B139');
    worksheet.getCell('B140').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow(["E. Break up of Table 4B(2) (ITC Reversed - Others )"]);
    worksheet.mergeCells('A142:F142');
    worksheet.addRow([]);
    
    //  add a table 19 to a sheet 
    worksheet.addTable({
      name: 'MyTable19',
      ref: 'A144',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'Table in GSTR-2B' },
        { name: ' ' },
        { name: 'Integrated tax' },
        { name: 'Central Tax' },
        { name: 'State/UT Tax' },
        { name: 'Cess' },
      ],
      rows: [
        ["3_Part B_I", "ITC Available - Others", "0.00", "0.00", "0.00", "0.00"],
        ["4_Part B_I", "ITC not available - Others", "0.00", "0.00", "0.00", "0.00"],
        ["3_Part A", "ITC reversal due to net negative values in 3_Part A_I,II,III,IV (A,B,C and D above)", "0.00", "0.00", "0.00", "0.00"],
      ],
    });
    // worksheet.mergeCells('A144:B144');
    worksheet.getCell('B145').alignment = { wrapText: true };
    worksheet.getCell('B146').alignment = { wrapText: true };
    worksheet.getCell('B147').alignment = { wrapText: true };

    worksheet.addRow([]);
    worksheet.addRow([]);

    worksheet.addRow(["Instructions:"]);
    worksheet.addRow(["   1. Terms Used :"]);
    worksheet.addRow(["     a. ITC – Input Tax Credit"]);
    worksheet.addRow(["     b. SEZ – Special Economic Zone"]);
    worksheet.addRow(["     c. ISD – Input Service Distributor"]);
    worksheet.addRow([]);
    worksheet.addRow(["   2. System generated summary is a summary of FORM GSTR-3B which will be generated for every registered person based on the following:"]);
    worksheet.addRow(["     a. Details of outward supplies as fled by you in FORM GSTR-1 for the period"]);
    worksheet.addRow(["     b. Details of inward supplies liable to reverse charge and input tax credit as per your FORM GSTR-2B generated for the period"]);
    worksheet.addRow([]);
    worksheet.addRow(["   3. The summary is generated in the following manner:"]);
    
    //  add a table 20 to a sheet 
    worksheet.addTable({
      name: 'MyTable20',
      ref: 'A161',
      // headerRow: true,
      // totalsRow: true,
      style: {
        // theme: 'TableStyleDark3',
        theme: 'TableStyleMedium9',
        // showRowStripes: true,
      },
      columns: [
        { name: 'GSTR-3B table' },
        { name: 'GSTR-2B table' },
        { name: ' Reference' },
      ],
      rows: [
        ["3.1(a, b, c, e) and 3.2 Details of Outward supplies", "NA", "This is auto-populated from FORM GSTR-1. Positive values shall be reported in respective tables. Negative values, if any, shall not be considered in the table and system will provide the value as zero.Negative values may arise in these tables due to credit notes and downward amendment of outward supplies. Detail break-up in Section II above may be referred in this respect."],
        ["3.1(d) Inward supplies liable to reverse charge", "Table 3 Part A Section III Table 4 Part A Section III", "This is auto-populated from FORM GSTR-2B. Positive values shall be reported in respective table. Negative values, if any, shall not be considered in the table and system will provide the value as zero.Negative values may arise in this table due to downward amendment of outward supplies. Detail break-up in Section II above may be referred in this respect."],
        ["4A (1, 3, 4, 5) ITC Available", "Table 3 Part A Section I, II, III, IV", "This is auto-populated from FORM GSTR-2B. Positive ITC values shall be reported in respective tables. Negative values, if any, shall not be reported and value shall be reported as zero.However, such negative values shall be considered as ITC reversal and would be reported in table 4B(2). Detail break-up in Section III above may be referred in this respect."],
        ["4B(2) ITC reversed – (2) Others", "Table 3 Part B Section I Table 4 Part B Section I", "This is auto-populated from FORM GSTR-2B. This covers the following: a. Positive values of all credit notes, on which ITC is available. If this is negative, then credit may be reclaimed subject to reversal of the same on an earlier instance. b. Positive values of all credit notes, on which ITC is not available. c. In case there are net negative values in ITC available table 4A(1,3,4,5), then such negative values shall be considered in this table.Detail break-up in Section III above may be referred in this respect."],
      ],
    });
    worksheet.getCell('A162').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    worksheet.getCell('A163').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    worksheet.getCell('A164').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    worksheet.getCell('A165').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    
    worksheet.getCell('B162').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    worksheet.getCell('B163').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    worksheet.getCell('B164').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};
    worksheet.getCell('B165').alignment = { wrapText: true ,vertical: 'middle',horizontal: 'center'};

    worksheet.getCell('C162').alignment = { wrapText: true };
    worksheet.getCell('C163').alignment = { wrapText: true };
    worksheet.getCell('C164').alignment = { wrapText: true };
    worksheet.getCell('C165').alignment = { wrapText: true };
    
    worksheet.addRow([]);
    worksheet.addRow(["4. If you have issued invoice and credit note in same month, then both shall be reported separately"]);
    worksheet.addRow(["5. In case you have not fled FORM GSTR-1 for the period, system generated summary will display the respective values as ‘Not Filed’."]);
    worksheet.addRow(["6. Similarly, if FORM GSTR-2B is not generated for the period, system generated summary will display the respective values as ‘Not Generated’"]);
    worksheet.addRow(["7. In case net ITC available in table 4C is in negative, then such negative value shall be considered as liability."]);
    worksheet.addRow(["8. Table 5 and 5.1 of FORM GSTR-3B is not part of this PDF."]);

    // worksheet.columns.forEach(column => {
    //   column.border = {
    //     top: { style: "thick" },
    //     left: { style: "thick" },
    //     bottom: { style: "thick" },
    //     right: { style: "thick" }
    //   };
    // });

    // worksheet.getRow(5).eachCell(cell => Object.assign(cell, {
    //   border: {
    //     top: { style: 'thin' },
    //     left: { style: 'thin' },
    //     bottom: { style: 'thin' },
    //     right: { style: 'thin' }
    //   }
    // }
    // ));
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelSubcategory(fileName,ExcelDatasubcat) {
    //Title, Data
    const title = fileName;
    const subcategorydata = ExcelDatasubcat;
    //Create a workbook with a worksheet
    let subcatworkbook = new Workbook();
    let subcatworksheet = subcatworkbook.addWorksheet('subcategory');
    //Add a Row
    let header = ["Category", "SubCategory"]
    let headerRow = subcatworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    subcatworksheet.getColumn(1).width = 20;
    subcatworksheet.getColumn(2).width = 30;

    for (let sub of subcategorydata) {
      let x2 = Object.keys(sub);
      let tempsub = []
      for (let y of x2) {
        tempsub.push(sub[y])
      }
      subcatworksheet.addRow(tempsub)
    }
    //Generate & Save Excel File
    subcatworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelPartyClosingData(filename, excelData) {

    //Title, Data
    const title = filename;
    const data = excelData;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('partyClosingData');

    let header = ["PartyName", "Address", "OpeningBalance(Rs.)", "Debit(Rs.)", "Credit(Rs.)", "ClosingBalance(Rs.)"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 25;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportExcelCashBankBook(filename, excelData, txtDebitOpeningBalance, txtCreditOpeningBalance,txtTotalDebit, txtTotalCredit,txtDebitClosingBalance, txtCreditClosingBalance) {

    //Title, Data
    const title = filename;
    const data = excelData;
    const openingdebit = txtDebitOpeningBalance;
    const openingcredit = txtCreditOpeningBalance;
    const totalDebit = txtTotalDebit;
    const totalCredit = txtTotalCredit;
    const closeingdebit = txtDebitClosingBalance;
    const closeingcredit = txtCreditClosingBalance;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["Date", "Particulars","Party Name", "Debit(Rs.)", "Credit(Rs.)", "Balance(Rs.)"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    let headerRow1 = worksheet.addRow(["Opening Balance", "","", openingdebit, openingcredit, ""]);
    headerRow1.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow1.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "", "","Current Total", totalDebit, totalCredit, ""]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    let headerRow3 = worksheet.addRow([ "", "","Closing Balance", closeingdebit, closeingcredit, ""]);
    headerRow3.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow3.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportExcelSubcategoryINIT(fileName,ExcelDatasubcat,allCategory) {
    //Title, Data
    const title = fileName;
    const subcategorydata = ExcelDatasubcat;
    //Create a workbook with a worksheet
    let subcatworkbook = new Workbook();
    let subcatworksheet = subcatworkbook.addWorksheet('subcategory');
    //Add a Row
    let header = ["Category", "SubCategory"]
    let headerRow = subcatworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    subcatworksheet.getColumn(1).width = 20;
    subcatworksheet.getColumn(2).width = 30;

    for (let sub of subcategorydata) {
      let x2 = Object.keys(sub);
      let tempsub = []
      for (let y of x2) {
        tempsub.push(sub[y])
      }
      subcatworksheet.addRow(tempsub)
    }
    let joincatlist = "\""+allCategory.join(',')+"\"";    
    for(let i=2; i < 1000; i++){
      subcatworksheet.getCell('A'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Category error',
        formulae: [joincatlist]//'"One,Two,Three,Four"'
      };
      // subcatworksheet.getCell('A'+i).value=allCategory[0];
    }
    //Generate & Save Excel File
    subcatworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelCategoryINIT(fileNameCategory,ExcelDataCategory,allCompany) {
    //Title, Data
    const title = fileNameCategory;
    const categorydata = ExcelDataCategory;
    //Create a workbook with a worksheet
    let categoryworkbook = new Workbook();
    let categoryworksheet = categoryworkbook.addWorksheet('category');
    //Add a Row
    let header = ["categoryName", "hsnCode", "companyName"]
    let headerRow = categoryworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    categoryworksheet.getColumn(1).width = 30;
    categoryworksheet.getColumn(2).width = 30;
    categoryworksheet.getColumn(3).width = 30;

    for (let com of categorydata) {
      let x2 = Object.keys(com);
      let tempcom = []
      for (let y of x2) {
        tempcom.push(com[y])
      }
      categoryworksheet.addRow(tempcom)
    }
    let joincatlist = "\""+allCompany.join(',')+"\"";    
    for(let i=2; i < 1000; i++){
      categoryworksheet.getCell('C'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Company error',
        formulae: [joincatlist]//'"One,Two,Three,Four"'
      };
      // subcatworksheet.getCell('A'+i).value=allCompany[0];
    }
    //Generate & Save Excel File
    categoryworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelBrandINIT(fileNameBrand,ExcelDataBrand) {
    //Title, Data
    const title = fileNameBrand;
    const branddata = ExcelDataBrand;
    //Create a workbook with a worksheet
    let brandworkbook = new Workbook();
    let brandworksheet = brandworkbook.addWorksheet('brand');
    //Add a Row
    let header = ["brandName"]
    let headerRow = brandworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    brandworksheet.getColumn(1).width = 30;

    for (let brd of branddata) {
      let x2 = Object.keys(brd);
      let tempbrd = []
      for (let y of x2) {
        tempbrd.push(brd[y])
      }
      brandworksheet.addRow(tempbrd)
    }
    // let joincatlist = "\""+allCompany.join(',')+"\"";    
    // for(let i=2; i < 1000; i++){
    //   categoryworksheet.getCell('C'+i).dataValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     showErrorMessage:true,
    //     operator:"notEqual",
    //     error:"given value does not exist",
    //     errorTitle: 'Company error',
    //     formulae: [joincatlist]//'"One,Two,Three,Four"'
    //   };
    //   // subcatworksheet.getCell('A'+i).value=allCompany[0];
    // }
    //Generate & Save Excel File
    brandworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelUnitINIT(fileNameUnit,ExcelDataUnit) {
    //Title, Data
    const title = fileNameUnit;
    const unitdata = ExcelDataUnit;
    //Create a workbook with a worksheet
    let unitworkbook = new Workbook();
    let unitworksheet = unitworkbook.addWorksheet('unit');
    //Add a Row
    let header = ["unitName"]
    let headerRow = unitworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    unitworksheet.getColumn(1).width = 30;

    for (let unit of unitdata) {
      let x2 = Object.keys(unit);
      let tempunit = []
      for (let y of x2) {
        tempunit.push(unit[y])
      }
      unitworksheet.addRow(tempunit)
    }
    // let joincatlist = "\""+allCompany.join(',')+"\"";    
    // for(let i=2; i < 1000; i++){
    //   categoryworksheet.getCell('C'+i).dataValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     showErrorMessage:true,
    //     operator:"notEqual",
    //     error:"given value does not exist",
    //     errorTitle: 'Company error',
    //     formulae: [joincatlist]//'"One,Two,Three,Four"'
    //   };
    //   // subcatworksheet.getCell('A'+i).value=allCompany[0];
    // }
    //Generate & Save Excel File
    unitworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelGSTINIT(fileNameGST,ExcelDataGST) {
    //Title, Data
    const title = fileNameGST;
    const gstdata = ExcelDataGST;
    //Create a workbook with a worksheet
    let gstworkbook = new Workbook();
    let gstworksheet = gstworkbook.addWorksheet('GST');
    //Add a Row
    let header = ["gstName","cgst","sgst"]
    let headerRow = gstworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    gstworksheet.getColumn(1).width = 30;
    gstworksheet.getColumn(2).width = 20;
    gstworksheet.getColumn(3).width = 20;

    for (let gst of gstdata) {
      let x2 = Object.keys(gst);
      let tempgst = []
      for (let y of x2) {
        tempgst.push(gst[y])
      }
      gstworksheet.addRow(tempgst)
    }
    // let joincatlist = "\""+allCompany.join(',')+"\"";    
    // for(let i=2; i < 1000; i++){
    //   categoryworksheet.getCell('C'+i).dataValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     showErrorMessage:true,
    //     operator:"notEqual",
    //     error:"given value does not exist",
    //     errorTitle: 'Company error',
    //     formulae: [joincatlist]//'"One,Two,Three,Four"'
    //   };
    //   // subcatworksheet.getCell('A'+i).value=allCompany[0];
    // }
    //Generate & Save Excel File
    gstworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelItemINIT(fileNameItem,ExcelDataItem,catArray,subCatArray,brandArray,unitArray,gstArray) {
    //Title, Data
    const title = fileNameItem;
    const Itemdata = ExcelDataItem;
    //Create a workbook with a worksheet
    let itemworkbook = new Workbook();
    let itemworksheet = itemworkbook.addWorksheet('Item');

    let CategorySheet = itemworkbook.addWorksheet('Category');
    CategorySheet.state = "hidden";
    let headerCategory = ["Category"]
    let headerRowCategory = CategorySheet.addRow(headerCategory)

    let SubCategorySheet = itemworkbook.addWorksheet('SubCategory');
    SubCategorySheet.state = "hidden";
    let headerSubCategory = ["SubCategory"]
    let headerRowSubCategory = SubCategorySheet.addRow(headerSubCategory)

    let BrandSheet = itemworkbook.addWorksheet('Brand');
    BrandSheet.state = "hidden";
    let headerBrand = ["Brand"]
    let headerRowBrand = BrandSheet.addRow(headerBrand)

    let UnitSheet = itemworkbook.addWorksheet('Unit');
    UnitSheet.state = "hidden";
    let headerUnit = ["Unit"]
    let headerRowUnit = UnitSheet.addRow(headerUnit)

    let GSTSheet = itemworkbook.addWorksheet('GST');
    GSTSheet.state = "hidden";
    let headerGST = ["GST"]
    let headerRowGST = GSTSheet.addRow(headerGST)

    //Add a Row
    let header = ["Item Name","Category Name","SubCategory Name","Brand Name","Unit Name","GST Name","HSN Code"]
    let headerRow = itemworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    itemworksheet.getColumn(1).width = 30;
    itemworksheet.getColumn(2).width = 20;
    itemworksheet.getColumn(3).width = 20;
    itemworksheet.getColumn(4).width = 20;
    itemworksheet.getColumn(5).width = 20;
    itemworksheet.getColumn(6).width = 20;
    itemworksheet.getColumn(7).width = 20;

    for (let itm of Itemdata) {
      let x2 = Object.keys(itm);
      let tempitm = []
      for (let y of x2) {
        tempitm.push(itm[y])
      }
      itemworksheet.addRow(tempitm)
    }

    for (let cate of catArray) {
      let c2 = Object.keys(cate);
      let tempcate = []
      for (let c of c2) {
        tempcate.push(cate[c])
      }
      CategorySheet.addRow(tempcate)
    }
    for (let subcat of subCatArray) {
      let sc2 = Object.keys(subcat);
      let tempsubcat = []
      for (let sc of sc2) {
        tempsubcat.push(subcat[sc])
      }
      SubCategorySheet.addRow(tempsubcat)
    }
    for (let brand of brandArray) {
      let b2 = Object.keys(brand);
      let tempbrand = []
      for (let b of b2) {
        tempbrand.push(brand[b])
      }
      BrandSheet.addRow(tempbrand)
    }
    for (let unit of unitArray) {
      let u2 = Object.keys(unit);
      let tempunit = []
      for (let u of u2) {
        tempunit.push(unit[u])
      }
      UnitSheet.addRow(tempunit)
    }
    for (let GST of gstArray) {
      let g2 = Object.keys(GST);
      let tempGST = []
      for (let g of g2) {
        tempGST.push(GST[g])
      }
      GSTSheet.addRow(tempGST)
    }
    // let joincatlist = "\""+catArray.join(',')+"\"";
    for(let i=2; i < 1000; i++){
      itemworksheet.getCell('B'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Category error',
        formulae: ['Category!$A$2:$A$500'],
        // formulae: ["\""+catArray.join(',')+"\""]//'"One,Two,Three,Four"'
      };
      // subcatworksheet.getCell('A'+i).value=allCompany[0];
      itemworksheet.getCell('C'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'SubCategory error',
        formulae: ['SubCategory!$A$2:$A$500']
        // formulae: ["\""+subCatArray.join(',')+"\""]
      };
      itemworksheet.getCell('D'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Brand error',
        formulae: ['Brand!$A$2:$A$500']
        // formulae: ["\""+brandArray.join(',')+"\""]
      };
      itemworksheet.getCell('E'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Unit error',
        formulae: ['Unit!$A$2:$A$500']
        // formulae: ["\""+unitArray.join(',')+"\""]
      };
      itemworksheet.getCell('F'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'GST error',
        formulae: ['GST!$A$2:$A$500']
        // formulae: ["\""+gstArray.join(',')+"\""]
      };
    }
    //Generate & Save Excel File
    itemworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelStockAdjustment(fileNameStockAdjustment,ExcelDataStockAdjustment,itemArray) {
    //Title, Data
    const title = fileNameStockAdjustment;
    const stockAdjustmentdata = ExcelDataStockAdjustment;
    //Create a workbook with a worksheet
    let stockAdjustmentworkbook = new Workbook();
    let stockAdjustmentworksheet = stockAdjustmentworkbook.addWorksheet('StockAdjustment');
    //Add a Row
    let header = ["Item Name","Batch Number","Quantity","Rate","Manufacture Date","Expiry Date"]
    let headerRow = stockAdjustmentworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    let ItemSheet = stockAdjustmentworkbook.addWorksheet('Item');
    ItemSheet.state = "hidden";
    let headerItem = ["Item"]
    let headerRowItem = ItemSheet.addRow(headerItem)

    stockAdjustmentworksheet.getColumn(1).width = 30;
    stockAdjustmentworksheet.getColumn(2).width = 30;
    stockAdjustmentworksheet.getColumn(3).width = 30;
    stockAdjustmentworksheet.getColumn(4).width = 30;
    stockAdjustmentworksheet.getColumn(5).width = 30;
    stockAdjustmentworksheet.getColumn(6).width = 30;

    for (let stk of stockAdjustmentdata) {
      let x2 = Object.keys(stk);
      let tempstk = []
      for (let y of x2) {
        tempstk.push(stk[y])
      }
      stockAdjustmentworksheet.addRow(tempstk)
    }
    for (let Item of itemArray) {
      let i2 = Object.keys(Item);
      let tempItem = []
      for (let g of i2) {
        tempItem.push(Item[g])
      }
      ItemSheet.addRow(tempItem)
    }
    let joincatlist = "\""+itemArray.join(',')+"\"";    
    for(let i=2; i < 1000; i++){
      stockAdjustmentworksheet.getCell('A'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Item error',
        // formulae: [joincatlist]//'"One,Two,Three,Four"'
        formulae: ['Item!$A$2:$A$500']//'"One,Two,Three,Four"'
      };
      // subcatworksheet.getCell('A'+i).value=allCompany[0];
    }
    //Generate & Save Excel File
    stockAdjustmentworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelParty(fileNameParty,ExcelDataParty,partyArray,partyStateArray,ledgerArray) {
    //Title, Data
    const title = fileNameParty;
    const Partydata = ExcelDataParty;
    //Create a workbook with a worksheet
    let partyworkbook = new Workbook();
    let partyworksheet = partyworkbook.addWorksheet('Party');

    let PartyStateSheet = partyworkbook.addWorksheet('PartyState');
    PartyStateSheet.state = "hidden";
    let headerState = ["State"]
    let headerRowState = PartyStateSheet.addRow(headerState)

    let PartyLedgerSheet = partyworkbook.addWorksheet('PartyLedger');
    PartyLedgerSheet.state = "hidden";
    let headerLedger = ["Ledger"]
    let headerRowLedger = PartyLedgerSheet.addRow(headerLedger)
    //Add a Row
    let header = ["Party Name","Address","State","City","Pincode","Mobile Number","Email","GST Number","Cheque No","Bank Name","Ledger"]
    let headerRow = partyworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    partyworksheet.getColumn(1).width = 30;
    partyworksheet.getColumn(2).width = 30;
    partyworksheet.getColumn(3).width = 20;
    partyworksheet.getColumn(4).width = 30;
    partyworksheet.getColumn(5).width = 20;
    partyworksheet.getColumn(6).width = 20;
    partyworksheet.getColumn(7).width = 20;
    partyworksheet.getColumn(8).width = 20;
    partyworksheet.getColumn(9).width = 20;
    partyworksheet.getColumn(10).width = 20;
    partyworksheet.getColumn(11).width = 20;

    for (let stk of Partydata) {
      let x2 = Object.keys(stk);
      let tempstk = []
      for (let y of x2) {
        tempstk.push(stk[y])
      }
      partyworksheet.addRow(tempstk)
    }
    for (let par of partyStateArray) {
      let p2 = Object.keys(par);
      let temppart = []
      for (let c of p2) {
        temppart.push(par[c])
      }
      PartyStateSheet.addRow(temppart)
    }
    for (let led of ledgerArray) {
      let p2 = Object.keys(led);
      let templedger = []
      for (let c of p2) {
        templedger.push(led[c])
      }
      PartyLedgerSheet.addRow(templedger)
    }
    // let joincatlist = "\""+partyArray.join(',')+"\"";    
    // for(let i=2; i < 1000; i++){
    //   partyworksheet.getCell('I'+i).dataValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     showErrorMessage:true,
    //     operator:"notEqual",
    //     error:"given value does not exist",
    //     errorTitle: 'Party error',
    //     formulae: [joincatlist]
    //   };
    // }
    // let joinStatelist = "\""+partyStateArray.join(',')+"\"";    
    for(let i=2; i < 1000; i++){
      partyworksheet.getCell('C'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'State error',
        formulae: ['PartyState!$A$2:$A$500']
      };
    }
    for(let i=2; i < 1000; i++){
      partyworksheet.getCell('K'+i).dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage:true,
        operator:"notEqual",
        error:"given value does not exist",
        errorTitle: 'Ledger error',
        formulae: ['PartyLedger!$A$2:$A$500']
      };
    }
    //Generate & Save Excel File
    partyworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportSaleReportExcelFile(filename, excelData,totalSaleAmount,
    totalExemptedNetAmount,
    totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
    totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
    totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
    totalTwentyEightNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
    totalGstAmount,totalNetAmount,totalAddCharge,totalDiscount) {

    //Title, Data
    const title = filename;
    const data = excelData;
    const saleamount = totalSaleAmount;
    // const fivegstAmount = fivegstmount;
    // const twelevgstamou = twelevgstamout;
    // const eighgstamoun = eighgstamount;
    // const twentyfourgstamoun  = twentyeightgstamount;
    const gstamount = totalGstAmount;
    const netamount = totalNetAmount;
    const addcharge = totalAddCharge;
    const discount = totalDiscount;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["Sale Date", "Name","GST Number", "Sale Number", "Amount",
      "GST 0% Amt",
      "GST 5% Amt","CGST 2.5%","SGST 2.5%","IGST 5%",
      "GST 12% Amt","CGST 6%","SGST 6%","IGST 12%",
      "GST 18% Amt","CGST 9%","SGST 9%","IGST 18%",
      "GST 28% Amt","CGST 14%","SGST 14%","IGST 28%",
      "GST Amount","Discount","Addtional Charge","Net Amount"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(20).width = 15;
    worksheet.getColumn(21).width = 15;
    worksheet.getColumn(22).width = 15;
    worksheet.getColumn(23).width = 15;
    worksheet.getColumn(24).width = 15;
    worksheet.getColumn(25).width = 15;
    worksheet.getColumn(26).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "","","", "Current Total", saleamount,
      totalExemptedNetAmount,
      totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
      totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
      totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
      totalTwentyEightNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
      gstamount,discount,addcharge,netamount]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportSaleReturnReportExcelFile(filename, excelData,totalSaleAmount,
    totalExemptedNetAmount,
    totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
    totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
    totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
    totalTwentyEightNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
    totalGstAmount,totalNetAmount,totalDiscount){

    //Title, Data
    const title = filename;
    const data = excelData;
    const saleamount = totalSaleAmount;
    // const fivegstAmount = fivegstmount;
    // const twelevgstamou = twelevgstamout;
    // const eighgstamoun = eighgstamount;
    // const twentyfourgstamoun  = twentyeightgstamount;
    const gstamount = totalGstAmount;
    const netamount = totalNetAmount;
    const discount = totalDiscount;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["SaleReturn Date", "Name","GST Number", "SaleReturn Number", "Amount",
      "GST 0% Amt",
      "GST 5% Amt","CGST 2.5%","SGST 2.5%","IGST 5%",
      "GST 12% Amt","CGST 6%","SGST 6%","IGST 12%",
      "GST 18% Amt","CGST 9%","SGST 9%","IGST 18%",
      "GST 28% Amt","CGST 14%","SGST 14%","IGST 28%",
      "GST Amount","Discount","Net Amount"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(20).width = 15;
    worksheet.getColumn(21).width = 15;
    worksheet.getColumn(22).width = 15;
    worksheet.getColumn(23).width = 15;
    worksheet.getColumn(24).width = 15;
    worksheet.getColumn(25).width = 15;
    worksheet.getColumn(26).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "","","", "Current Total", saleamount,
      totalExemptedNetAmount,
      totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
      totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
      totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
      totalTwentyEightNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount, 
      gstamount,discount,netamount]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportPurchaseReportExcelFile(filename, excelData,totalPurchaseAmount,
    totalExemptedNetAmount,
    totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
    totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
    totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
    totalTwentyFourNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
    totalGstAmount,totalnetamount,totalDiscount){

    //Title, Data
    const title = filename;
    const data = excelData;
    const saleamount = totalPurchaseAmount;
    // const fivegstAmount = fivegstmount;
    // const twelevgstamou = twelevgstamout;
    // const eighgstamoun = eighgstamount;
    // const twentyfourgstamoun  = twentyfourgstamount;
    const gstamount = totalGstAmount;
    const netamount = totalnetamount;
    const discount = totalDiscount;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["Purchase Date", "Name","GST Number", "Purchase Number","Amount",
      "GST 0% Amt",
      "GST 5% Amt","CGST 2.5%","SGST 2.5%","IGST 5%",
      "GST 12% Amt","CGST 6%","SGST 6%","IGST 12%",
      "GST 18% Amt","CGST 9%","SGST 9%","IGST 18%",
      "GST 28% Amt","CGST 14%","SGST 14%","IGST 28%",
      "GST Amount","Discount","Net Amount"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 17;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(20).width = 15;
    worksheet.getColumn(21).width = 15;
    worksheet.getColumn(22).width = 15;
    worksheet.getColumn(23).width = 15;
    worksheet.getColumn(24).width = 15;
    worksheet.getColumn(25).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "","","", "Current Total", saleamount,
      totalExemptedNetAmount,
      totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
      totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
      totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
      totalTwentyFourNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
      gstamount,discount,netamount]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportPurchaseReturnReportExcelFile(filename, excelData,totalPurchaseAmount,
    totalExemptedNetAmount,
    totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
    totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
    totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
    totalTwentyFourNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
    totalGstAmount,totalnetamount,totalDiscount){

    //Title, Data
    const title = filename;
    const data = excelData;
    const saleamount = totalPurchaseAmount;
    // const fivegstAmount = fivegstmount;
    // const twelevgstamou = twelevgstamout;
    // const eighgstamoun = eighgstamount;
    // const twentyfourgstamoun  = twentyfourgstamount;
    const gstamount = totalGstAmount;
    const netamount = totalnetamount;
    const discount = totalDiscount;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["PurchaseReturn Date", "Name","GST Number", "PurchaseReturn Number", "Amount",
      "GST 0% Amt",
      "GST 5% Amt","CGST 2.5%","SGST 2.5%","IGST 5%",
      "GST 12% Amt","CGST 6%","SGST 6%","IGST 12%",
      "GST 18% Amt","CGST 9%","SGST 9%","IGST 18%",
      "GST 28% Amt","CGST 14%","SGST 14%","IGST 28%",
      "Gst Amount","Discount","Net Amount"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
    worksheet.getColumn(12).width = 15;
    worksheet.getColumn(13).width = 15;
    worksheet.getColumn(14).width = 15;
    worksheet.getColumn(15).width = 15;
    worksheet.getColumn(16).width = 15;
    worksheet.getColumn(17).width = 15;
    worksheet.getColumn(18).width = 15;
    worksheet.getColumn(19).width = 15;
    worksheet.getColumn(20).width = 15;
    worksheet.getColumn(21).width = 15;
    worksheet.getColumn(22).width = 15;
    worksheet.getColumn(23).width = 15;
    worksheet.getColumn(24).width = 15;
    worksheet.getColumn(25).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "","","", "Current Total", saleamount,
      totalExemptedNetAmount,
      totalFiveNetAmount,fiveCgstAmount,fiveSgstAmount,fiveIgstAmount,
      totalTwelveNetAmount,twelveCgstAmount,twelveSgstAmount,twelveIgstAmount,
      totalEightgNetAmount,eightCgstAmount,eightSgstAmount,eightIgstAmount,
      totalTwentyFourNetAmount,tewntyeightCgstAmount,tewntyeightSgstAmount,tewntyeightIgstAmount,
      gstamount,discount,netamount]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportAsExcelStockvalution(filename, excelData,totalstockquantity, totalstockTotalAmount) {

    //Title, Data
    const title = filename;
    const data = excelData;
    const Stockquantity = totalstockquantity;
    totalstockTotalAmount = parseFloat(Number(totalstockTotalAmount).toFixed(2));
    const StockTotalAmount = totalstockTotalAmount;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["Item Name", "Company Name", "Stock Quantity", "Stock Total Amount(Rs.)"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 25;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let headerRow2 = worksheet.addRow([ "","Current Total", Stockquantity, StockTotalAmount]);
    headerRow2.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0070C0' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 11
      }
      headerRow2.alignment = { vertical: 'middle', horizontal: 'right' }
    })

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  
  exportExcelProfitLoss(filename, txtIncome,txtSale,txtPurchaseReturn,txtStock,totalA,txtExpense,txtPurchase,txtSaleReturn,totalB,txtProfitLoss) {

    //Title, Data
    const title = filename;
    // const Income = parseFloat(Number(txtIncome).toFixed(2));
    // const Sale = parseFloat(Number(txtSale).toFixed(2));
    // const Stock = parseFloat(Number(txtStock).toFixed(2));
    // const TotalA = parseFloat(Number(totalA).toFixed(2));
    // const Expense = parseFloat(Number(txtExpense).toFixed(2));
    // const Purchase = parseFloat(Number(txtPurchase).toFixed(2));
    // const TotalB = parseFloat(Number(totalB).toFixed(2));
    // const ProfitLoss = parseFloat(Number(txtProfitLoss).toFixed(2));

    const Income = Math.round(txtIncome);
    const Sale = Math.round(txtSale);
    const purchaseReturn = Math.round(txtPurchaseReturn);
    const Stock = Math.round(txtStock);
    const TotalA = Math.round(totalA);
    const Expense = Math.round(txtExpense);
    const Purchase = Math.round(txtPurchase);
    const saleReturn = Math.round(txtSaleReturn);
    const TotalB = Math.round(totalB);
    const ProfitLoss = Math.round(txtProfitLoss);
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);
    
    let header = ["Particulars", "Amount(Rs.)"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.addRow(["(A)Income", ""]);
    worksheet.addRow(["Income",Income]);
    worksheet.addRow(["Sale",Sale]);
    worksheet.addRow(["Purchase Return",purchaseReturn]);
    worksheet.addRow(["Stock",Stock]);
    worksheet.addRow(["Total(A)",TotalA]);

    worksheet.addRow(["(B)Expenditure", ""]);
    worksheet.addRow(["Expense",Expense]);
    worksheet.addRow(["Purchase",Purchase]);
    worksheet.addRow(["Sale Return",saleReturn]);
    worksheet.addRow(["Total(B)",TotalB]);
    worksheet.addRow(["ProfitLoss",ProfitLoss]);

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  exportExcelSaleINIT(fileNameBrand,ExcelDataBrand) {
    //Title, Data
    const title = fileNameBrand;
    const branddata = ExcelDataBrand;
    //Create a workbook with a worksheet
    let brandworkbook = new Workbook();
    let brandworksheet = brandworkbook.addWorksheet('salemaster');
    //Add a Row
    let header = ["saleNumber","partyName","cellNumber","amount","additionalcharges","netAmount","discount","gstAmount","narration","saleDate","type"]
    let headerRow = brandworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    brandworksheet.getColumn(1).width = 30;
    brandworksheet.getColumn(2).width = 30;
    brandworksheet.getColumn(3).width = 30;
    brandworksheet.getColumn(4).width = 30;
    brandworksheet.getColumn(5).width = 30;
    brandworksheet.getColumn(6).width = 30;
    brandworksheet.getColumn(7).width = 30;
    brandworksheet.getColumn(8).width = 30;
    brandworksheet.getColumn(9).width = 30;
    brandworksheet.getColumn(10).width = 30;
    brandworksheet.getColumn(11).width = 30;

    for (let brd of branddata) {
      let x2 = Object.keys(brd);
      let tempbrd = []
      for (let y of x2) {
        tempbrd.push(brd[y])
      }
      brandworksheet.addRow(tempbrd)
    }
    // let joincatlist = "\""+allCompany.join(',')+"\"";    
    // for(let i=2; i < 1000; i++){
    //   categoryworksheet.getCell('C'+i).dataValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     showErrorMessage:true,
    //     operator:"notEqual",
    //     error:"given value does not exist",
    //     errorTitle: 'Company error',
    //     formulae: [joincatlist]//'"One,Two,Three,Four"'
    //   };
    //   // subcatworksheet.getCell('A'+i).value=allCompany[0];
    // }
    //Generate & Save Excel File
    brandworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelSaleDetailINIT(fileNameUnit,ExcelDataUnit) {
    //Title, Data
    const title = fileNameUnit;
    const unitdata = ExcelDataUnit;
    //Create a workbook with a worksheet
    let unitworkbook = new Workbook();
    let unitworksheet = unitworkbook.addWorksheet('SaleDetail');
    //Add a Row
    let header = ["saleNumber","itemName","companyId","sgstAmount","netAmount","quantity","rate","cgstAmount","totalAmount","type"]
    let headerRow = unitworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    unitworksheet.getColumn(1).width = 30;
    unitworksheet.getColumn(2).width = 30;
    unitworksheet.getColumn(3).width = 30;
    unitworksheet.getColumn(4).width = 30;
    unitworksheet.getColumn(5).width = 30;
    unitworksheet.getColumn(6).width = 30;
    unitworksheet.getColumn(7).width = 30;
    unitworksheet.getColumn(8).width = 30;
    unitworksheet.getColumn(9).width = 30;
    unitworksheet.getColumn(10).width = 30;

    for (let unit of unitdata) {
      let x2 = Object.keys(unit);
      let tempunit = []
      for (let y of x2) {
        tempunit.push(unit[y])
      }
      unitworksheet.addRow(tempunit)
    }
    // let joincatlist = "\""+allCompany.join(',')+"\"";    
    // for(let i=2; i < 1000; i++){
    //   categoryworksheet.getCell('C'+i).dataValidation = {
    //     type: 'list',
    //     allowBlank: false,
    //     showErrorMessage:true,
    //     operator:"notEqual",
    //     error:"given value does not exist",
    //     errorTitle: 'Company error',
    //     formulae: [joincatlist]//'"One,Two,Three,Four"'
    //   };
    //   // subcatworksheet.getCell('A'+i).value=allCompany[0];
    // }
    //Generate & Save Excel File
    unitworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  public exportAsDemoStock(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileDemoStock(excelBuffer, excelFileName);
  }
  private saveAsExcelFileDemoStock(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportExcelVMDetail(fileNameVM,ExcelDataVM) {
    //Title, Data
    const title = fileNameVM;
    const vmdata = ExcelDataVM;
    //Create a workbook with a worksheet
    let unitworkbook = new Workbook();
    let unitworksheet = unitworkbook.addWorksheet(fileNameVM);
    //Add a Row
    let header = ["voucherMasterId","amount","bankId","companyId","creditAmount","debitAmount","detail","detailId","invoiceId","partyName","voucherDate","mobileNumber"]
    let headerRow = unitworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    unitworksheet.getColumn(1).width = 30;
    unitworksheet.getColumn(2).width = 30;
    unitworksheet.getColumn(3).width = 30;
    unitworksheet.getColumn(4).width = 30;
    unitworksheet.getColumn(5).width = 30;
    unitworksheet.getColumn(6).width = 30;
    unitworksheet.getColumn(7).width = 30;
    unitworksheet.getColumn(8).width = 30;
    unitworksheet.getColumn(9).width = 30;
    unitworksheet.getColumn(10).width = 30;
    unitworksheet.getColumn(11).width = 30;
    unitworksheet.getColumn(12).width = 30;

    for (let vm of vmdata) {
      let x2 = Object.keys(vm);
      let tempvm = []
      for (let y of x2) {
        tempvm.push(vm[y])
      }
      unitworksheet.addRow(tempvm)
    }
    //Generate & Save Excel File
    unitworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelSaleReturnINIT(fileNameSR,ExcelDataSR) {
    //Title, Data
    const title = fileNameSR;
    const branddata = ExcelDataSR;
    //Create a workbook with a worksheet
    let brandworkbook = new Workbook();
    let brandworksheet = brandworkbook.addWorksheet('salemaster');
    //Add a Row
    let header = ["salereturnNumber","partyName","cellNumber","amount","netAmount","discount","gstAmount","narration","saleReturnDate","type","location"]
    let headerRow = brandworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    brandworksheet.getColumn(1).width = 30;
    brandworksheet.getColumn(2).width = 30;
    brandworksheet.getColumn(3).width = 30;
    brandworksheet.getColumn(4).width = 30;
    brandworksheet.getColumn(5).width = 30;
    brandworksheet.getColumn(6).width = 30;
    brandworksheet.getColumn(7).width = 30;
    brandworksheet.getColumn(8).width = 30;
    brandworksheet.getColumn(9).width = 30;
    brandworksheet.getColumn(10).width = 30;
    brandworksheet.getColumn(11).width = 30;

    for (let brd of branddata) {
      let x2 = Object.keys(brd);
      let tempbrd = []
      for (let y of x2) {
        tempbrd.push(brd[y])
      }
      brandworksheet.addRow(tempbrd)
    }
    //Generate & Save Excel File
    brandworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  exportExcelSaleReturnDetailINIT(fileNameSR,ExcelDataSR) {
    //Title, Data
    const title = fileNameSR;
    const branddata = ExcelDataSR;
    //Create a workbook with a worksheet
    let brandworkbook = new Workbook();
    let brandworksheet = brandworkbook.addWorksheet('salemaster');
    //Add a Row
    let header = ["salereturnNumber","itemName","companyId","sgstAmount","netAmount","quantity","rate","cgstAmount","totalAmount","salenumber","batchNumber","manufactureDate","expiryDate","location"]
    let headerRow = brandworksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    brandworksheet.getColumn(1).width = 30;
    brandworksheet.getColumn(2).width = 30;
    brandworksheet.getColumn(3).width = 30;
    brandworksheet.getColumn(4).width = 30;
    brandworksheet.getColumn(5).width = 30;
    brandworksheet.getColumn(6).width = 30;
    brandworksheet.getColumn(7).width = 30;
    brandworksheet.getColumn(8).width = 30;
    brandworksheet.getColumn(9).width = 30;
    brandworksheet.getColumn(10).width = 30;
    brandworksheet.getColumn(11).width = 30;

    for (let brd of branddata) {
      let x2 = Object.keys(brd);
      let tempbrd = []
      for (let y of x2) {
        tempbrd.push(brd[y])
      }
      brandworksheet.addRow(tempbrd)
    }
    //Generate & Save Excel File
    brandworkbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })
  }

  public exportAsDemoBalance(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFileDemoBalance(excelBuffer, excelFileName);
  }
  private saveAsExcelFileDemoBalance(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  exportExcelPartyTypeFarmerData(filename, excelData) {

    //Title, Data
    const title = filename;
    const data = excelData;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["Entity Name","Party Name", "Mobile No.", "Address", "GST Number", "Location", "State", "State Code"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

  
  exportAsExcelProductPurchaseReport(filename, excelData,txtFormDate,txtToDate) {
    //Title, Data
    const title = filename;
    const data = excelData;
    const formDate = txtFormDate;
    const toDate = txtToDate;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);
  
    let reportNameRow = worksheet.addRow([filename]);
    reportNameRow.worksheet.mergeCells(0,1,0,10)
    reportNameRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 14
      }
      reportNameRow.alignment = { vertical: 'middle', horizontal: 'center' }
    })
  
    let startToEndDateRow = worksheet.addRow(["Date :-",formDate+' To '+toDate]);
    startToEndDateRow.worksheet.mergeCells('B2:J2')
    startToEndDateRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12
      }
      startToEndDateRow.alignment = { vertical: 'middle', horizontal: 'left' }
      startToEndDateRow.worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center'};
    })
  
    let header = ["Item Name","Subcategory Name","Total Purchase Quantity","Total Purchase Avg Price(₹)","Total Credit Quantity","Total Credit Avg Price(₹)","Total Sale Quantity","Total Sale Avg Price(₹)","Total Debit Quantity","Total Debit Avg Price(₹)"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }
  
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 25;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 25;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.getColumn(8).width = 25;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 25;
  
    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
  
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      var month = new Date().getMonth() + 1;
      fs.saveAs(blob, title +'-'+new Date().getDate()+'-'+month+'-'+new Date().getFullYear()+' '+new Date().getHours()+'.'+new Date().getMinutes()+'.'+new Date().getSeconds()+ EXCEL_EXTENSION);
    })
  }

  exportExcelItemWiseStock(filename, excelData) {

    //Title, Data
    const title = filename;
    const data = excelData;
    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(filename);

    let header = ["Company Name","Category Name", "Subcategory Name", "Item Name", "Batch Number", "Quantity", "Value", "Expiry Date"]
    let headerRow = worksheet.addRow(header)
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E6B8B7' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: '000000' },
        size: 11
      }
    })
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 10;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;

    for (let x1 of data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    })

  }

}
