import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from '../service/plan/plan.service';
import { PlanUserService } from '../service/plan_user/plan-user.service';
declare var $: any;

@Component({
  selector: 'app-plan-user',
  templateUrl: './plan-user.component.html',
  styleUrls: ['./plan-user.component.css']
})
export class PlanUserComponent implements OnInit {
  resExtraUser;
  resUpExtraUser;
  resDelExtraUser;
  txtExtraUserId;
  txtPlanName;
  txtExtraUser;
  txtAmount;
  allExtraUser;
  allPlan;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private toastr: ToastrService, 
    private planUserService: PlanUserService,
    private planService: PlanService,
  ) { }

  ngOnInit(): void {
    this.getAllPlan();
    this.getAllExtraUser();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllPlan() {
    this.planService.gatAllPlan().subscribe(res => {
      this.allPlan = res;
    })
  }

  getAllExtraUser() {
    this.planUserService.getAllExtraUser().subscribe(res => {
      this.allExtraUser = res;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtPlanName = null;
    this.txtExtraUserId = null;
    this.txtExtraUser = null;
    this.txtAmount = null;
  }
  createExtraUser() {
    if (this.txtPlanName == null || this.txtPlanName == '' || this.txtPlanName == undefined || this.txtPlanName == "-:Select A Plan:-") {
      this.toastr.error("Plan name is required");
    } else if (this.txtExtraUser == null || this.txtExtraUser == '' || this.txtExtraUser == undefined || this.txtExtraUser == 0) {
      this.toastr.error("Extra user is required");
    } else if (this.txtAmount == null || this.txtAmount == '' || this.txtAmount == undefined || this.txtAmount == 0) {
      this.toastr.error("Amount is required");
    } else {
      if (this.txtExtraUserId == null) {
        this.planUserService.createExtraUser(this.txtPlanName, this.txtExtraUser, this.txtAmount)
          .subscribe(res => {
            this.resExtraUser = res;
            this.clearData();
            this.getAllExtraUser();
            this.toastr.success('Extra user save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.planUserService.updateExtraUser(this.txtExtraUserId, this.txtPlanName, this.txtExtraUser, this.txtAmount).subscribe(res => {
          this.resUpExtraUser = res;
          this.clearData();
          this.getAllExtraUser();
          this.toastr.success('Extra user updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateExtraUser(exuser) {
    this.btnName = 'Update';
    this.txtExtraUserId = exuser.planuserId;
    this.txtPlanName = exuser.plan.planName;
    this.txtExtraUser = exuser.planUserLimit;
    this.txtAmount = exuser.amount;
  }

  deleteid;
  deleteExtraUser(exuser) {
    this.deleteid = exuser.planuserId;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.planUserService.deleteExtraUser(this.deleteid)
        .subscribe(res => {
          this.resDelExtraUser = res;
          this.clearData();
          this.getAllExtraUser();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Extra user deleted successfully');
        }, error => {
          this.toastr.error('Extra user not deleted');
        })
    }
  }

}
