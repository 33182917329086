import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GstewaybillLoginService {

  constructor(
    private http: Http,
  ) { }

  createGSTEWayBillLogin(txtGSTEWayBillUserName: string,txtGSTEWayBillPassword: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      gstEWayBillUserName: txtGSTEWayBillUserName,
      gstEWayBillPassword: txtGSTEWayBillPassword,
    });
    return this.http.post('/api/create/login/gstewaybill', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateGSTEWayBillLogin(GSTEWayBillLoginId: string, txtGSTEWayBillUserName: string,txtGSTEWayBillPassword: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      gstEWayBillUserName: txtGSTEWayBillUserName,
      gstEWayBillLoginId: GSTEWayBillLoginId,
      gstEWayBillPassword: txtGSTEWayBillPassword,
    });
    return this.http.put('/api/update/login/gstewaybill', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllGSTEWayBillLogin() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/login/gstewaybill', { headers })
      .pipe(map(res => res.json()));

  }

  deleteGSTEWayBillLogin(GSTEWayBillLoginId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      gstEWayBillLoginId: GSTEWayBillLoginId,
    });
    return this.http.put('/api/delete/login/gstewaybill', body, { headers })
      .pipe(map(res => res.json()));
  }
}
