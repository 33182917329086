<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Ledger Details</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">From <span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtFormDate" name="txtFormDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">To <span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                class="form-control" [(ngModel)]="txtToDate" name="txtToDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-2 col-xl-2 col-2">
                                            <label for="country" class="form-label">Ledger <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="country" [(ngModel)]="txtLedger" name="txtLedger">
                                                <option *ngFor="let ledger of allParty" [ngValue]="ledger">{{ledger.partyName}}</option>
                                            </select>
                                        </div> -->
                                        <div class="col-lg-2 col-xl-4 col-6 position-relative">
                                            <label for="last" class="form-label">Party Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Enter party Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}-{{item.result.cellNumber}}{{item.result.address !='' && item.result.address !=null ? '-'+item.result.address:''}}
                                                </div>
                                            </ng-template>
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-3 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtcompany"
                                                [(ngModel)]="txtcompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option>All</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company.companyId">
                                                    {{company.companyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="searchStock()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <div class="portlet-header">Search Details
                            <!-- <span style="float: right;">Total : {{totalNetAmount | appendIND}}</span> -->
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <!-- <div class="col-md-8 d-flex" style="flex-wrap: wrap;">
                                    <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                        (click)="exportAsXLSX()" style="margin-bottom: 10px;">Export To Excel</button>
                                    <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                        (click)="downloadPdf()" style="margin-bottom: 10px;">Download PDF</button>
                                    <button type="button" class="btn btn-primary d-block me-2 bottom_margin"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                        data-bs-toggle="modal" data-bs-target="#emailModal"
                                        style="margin-bottom: 10px;"> Send Email </button>
                                    <button type="button" class="btn btn-primary d-block bottom_margin"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Send Message"
                                        data-bs-toggle="modal" data-bs-target="#messageModal"
                                        style="margin-bottom: 10px;"> Send Message </button>
                                </div> -->
                                
                                <div class="col-md-8 d-flex">
                                    <div class="card-close">
                                        <div class="dropdown">
                                        <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" class="dropdown-toggle" class="btn btn-primary btn-sm me-2 bottom_margin">Actions
                                            <!-- <i class="fas fa-ellipsis-v"></i> -->
                                        </button>
                                        <div aria-labelledby="closeCard1" style="min-width: 200px; padding: 5px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                            <!-- <a href="#" class="dropdown-item remove">
                                            <i class="fas fa-times"></i>Close
                                            </a>
                                            <a href="#" class="dropdown-item edit">
                                            <i class="fas fa-cog"></i>Edit
                                            </a> -->
                                            <button class="btn btn-primary btn-sm me-2 bottom_margin" type="submit" title="Export To Excel"
                                            (click)="exportAsXLSX()">
                                            <i class="fa fa-file-excel" aria-hidden="true"></i></button>
                                            <button class="btn btn-primary btn-sm me-2 bottom_margin" type="submit" title="Download PDF"
                                                (click)="downloadPdf()">
                                                <i class="fa fa-file-pdf"></i></button>
                                            <button type="button" class="btn btn-primary btn-sm me-2 bottom_margin" 
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                                data-bs-toggle="modal" data-bs-target="#emailModal">
                                                <i class="fa fa-envelope" aria-hidden="true"></i></button>
                                            <button type="button" class="btn btn-primary btn-sm me-2 bottom_margin"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Send Message"
                                                data-bs-toggle="modal" data-bs-target="#messageModal">
                                                <i class="fa fa-comment" aria-hidden="true"></i></button>
                                            <button type="button" class="btn btn-primary btn-sm bottom_margin"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Send Message" (click)="downloadPdfWhatsUpp()">
                                                <i class="fab fa-whatsapp"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-md-6 d-flex">
                                    <button type="button" class="btn btn-primary d-block bottom_margin"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                        data-bs-toggle="modal" data-bs-target="#emailModal"> Send Email </button>
                                    <button type="button" class="btn btn-primary d-block bottom_margin"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Send Message"
                                        data-bs-toggle="modal" data-bs-target="#messageModal"> Send Message </button>
                                </div> -->
                                <div class="col-md-4">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Particulars</th>
                                            <!-- <th scope="col">Type&No.</th> -->
                                            <th scope="col">Debit(Rs.)</th>
                                            <th scope="col">Credit(Rs.)</th>
                                            <th scope="col">Balance(Rs.)</th>
                                            <!-- <th scope="col">Detail</th> -->
                                            <th scope="col" style="min-width: 100px;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="2"><b>Opening Balance</b></td>
                                            <td class="text-end"><b>{{txtDebitOpeningBalance | appendIND}}</b></td>
                                            <td class="text-end"><b>{{txtCreditOpeningBalance | appendIND}}</b></td>
                                            <td class="text-end"></td>
                                            <td class="text-end"></td>
                                        </tr>
                                        <tr *ngFor="let srch of receiptData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{srch.voucherDate | date : 'dd-MM-yyyy'}}</td>
                                            <td>{{srch.detail}} - {{srch.invoiceId != 0 ?
                                                srch.invoiceId:srch.voucherMasterId}}</td>
                                            <!-- <td>{{srch.qty}}</td> -->
                                            <td class="text-end">{{srch.debit | appendIND}}</td>
                                            <td class="text-end">{{srch.credit | appendIND}}</td>
                                            <td class="text-end">{{srch.txtTotalBalance | appendIND}}</td>
                                            <td class="text-start">
                                                <button type="button" class="btn btn-primary btn-sm me-2"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                    (click)="openModal(srch)">
                                                    <i class="fas fa-history"></i>
                                                </button>
                                                <button *ngIf="srch.detail == 'SALE'" type="button" class="btn btn-primary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Send Email"
                                                    (click)="getsendEmailData(srch)" data-bs-toggle="modal"
                                                    data-bs-target="#saleEmailModal">
                                                    <i class="fas fa-envelope"></i>
                                                </button>
                                                <!-- <button type="button" class="btn btn-primary btn-sm" style="margin-left: 5px;"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                                                    (click)="getVoucherLedgerDetailById(srch)" data-bs-toggle="modal" data-bs-target="#VoucherLedgerModal">
                                                    <i class="fas fa-history"></i>
                                                </button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="2"><b>Current Total</b></td>
                                            <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                            <td class="text-end"><b>{{txtTotalCredit | appendIND}}</b></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2"><b>Closing Balance</b></td>
                                            <td class="text-end"><b>{{txtDebitClosingBalance | appendIND}}</b></td>
                                            <td class="text-end"><b>{{txtCreditClosingBalance | appendIND}}</b></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                    <tfoot>
                                        <tr *ngIf="reSearchData.length == 0 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"
                                            responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="portlet-footer">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button class="btn btn-secondary me-md-2" type="button">
                            Cancel
                        </button>
                        <button class="btn btn-primary" type="submit" (click)="createPurchaseDetail()">
                            Save
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="modal fade" id="LedgerdetailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                        <div class="portlet">
                            <div class="portlet-content">
                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Sub Category</th>
                                                <th scope="col">Brand</th>
                                                <th scope="col">Party Invoice Number</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col" class="text-center">Rate</th>
                                                <th scope="col" class="text-center">Total Amount</th>
                                                <th scope="col">Narration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let srchdetail of ledgerSubDetail">
                                                <td>{{srchdetail.itemName}}</td>
                                                <td>{{srchdetail.categoryName}}</td>
                                                <td>{{srchdetail.subCategoryName}}</td>
                                                <td>{{srchdetail.brandName}}</td>
                                                <td>{{srchdetail.partyinvoicenumber}}</td>
                                                <td>{{srchdetail.quantity}}</td>
                                                <td class="text-end">{{srchdetail.rate | appendIND}}</td>
                                                <td class="text-end">{{srchdetail.totalAmount | appendIND}}</td>
                                                <td>{{srchdetail.narration}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="ledgerSubDetail.length == 0 ">
                                            <!-- <tr *ngIf="ledgerSubDetail < 1 "> -->
                                                <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="VoucherLedgerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Voucher Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12">
                        <div class="portlet">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Particulars</th>
                                            <th class="text-center" scope="col">DEBIT (₹)</th>
                                            <th class="text-center" scope="col">CREDIT (₹)</th>
                                            <th scope="col">Narration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let voucherById of resAllVoucherLedgerData;">
                                            <td>{{voucherById.partyName}}</td>
                                            <td class="text-end">{{voucherById.debit | appendIND}}</td>
                                            <td class="text-end">{{voucherById.credit | appendIND}}</td>
                                            <td>{{voucherById.narration}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td><b>Total</b></td>
                                            <td class="text-end"><b>{{txtTotalVoucherDebit | appendIND}}</b></td>
                                            <td class="text-end"><b>{{txtTotalVoucherCredit | appendIND}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-6 col-xl-6 col-6">
                        <label for="country" class="form-label">Email Address</label>
                        <input type="text" class="form-control" id="last"
                            placeholder="Enter Email Address" required="required"
                            [(ngModel)]="txtEmail" name="txtEmail" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="sendLedgerMailUsingSendgrid()">Yes</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="saleEmailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-6 col-xl-6 col-6">
                    <label for="country" class="form-label">Email Address</label>
                    <input type="text" class="form-control" id="last"
                        placeholder="Enter Email Address" required="required"
                        [(ngModel)]="txtEmailSale" name="txtEmailSale" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="saleMailUsingSendgrid()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="messageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="messageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-center">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Send Message</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to send this message ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="sendLedgerMessage()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>