import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
import { ToastrService } from "ngx-toastr";
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';
declare var $: any;

@Component({
  selector: 'app-assets-v-record',
  templateUrl: './assets-v-record.component.html',
  styleUrls: ['./assets-v-record.component.css']
})
export class AssetsVRecordComponent implements OnInit {
  allAssets;
  allAssetsById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;

  constructor(
    private assetsVoucherService: AssetsVoucherService,
    private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllAssets();
  }

  MoveAssets() {
    this.router.navigate(['assetsvoucher']);
  }

  getAllAssets() {
    this.assetsVoucherService.getAllAssets().subscribe(res => {
      this.allAssets = res;
    }, error => {
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  assetsDetail(assets) {
    this.getAllAssetsById(assets.voucherMasterId)
    // this.txtDate = receipt.voucherDate;
  }

  getAllAssetsById(txtId) {
    this.assetsVoucherService.getAllAssetsById(txtId).subscribe(res => {
      this.allAssetsById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allAssetsById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }

  deleteAssetsId;
  deleteAssets(assets){
    this.deleteAssetsId = assets.voucherMasterId;
  }

  resAssetsData;
  confirmDelete() {
    if (this.deleteAssetsId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteAssetsId)
        .subscribe(res => {
          this.resAssetsData = res;
          console.log(this.resAssetsData);
          this.getAllAssets();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Assets data deleted successfully');
        }, error => {
          this.toastr.error('Assets data not deleted');
        })
    }
  }

}
