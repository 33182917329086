import { Component, OnInit } from '@angular/core';
import { SaleService } from "../service/sale/sale.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
//import { Purchase } from "../service/purchase/purchase";
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { sale } from '../service/sale/sale';
import { CompanyService } from '../service/company/company.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../service/excel/excel.service';
import { StockService } from '../service/stock/stock.service';
import { SaleReturnService } from '../service/sale-return/sale-return.service';
declare var $: any;

@Component({
  selector: 'app-sale-demo',
  templateUrl: './sale-demo.component.html',
  styleUrls: ['./sale-demo.component.css']
})
export class SaleDemoComponent implements OnInit {

  itemId;
  txtsaleDate;
  saleNumber;
  txtsaleid;
  txtsaleCode;
  txtpartyName;
  txtpartyId;
  txtgstAmount = 0;
  txtamount = 0;
  txtDiscount:number =0;
  txtAdd:number = 0;
  txtnetAmount = 0;
  txtMobileNumber;
  stockCheck;
  batchwiseresponse;

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity;
  txtrate;
  txtExpiryDate;

  allsale;
  getParty;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];

  saleData: Array<sale> = [];
  saleIdLast: number = 0;
  txtCGST: number;
  txtSGST: number;
  txtTotalGST: number;
  exitGST=0;
  datePipe = new DatePipe("en-US");
  openPdfResponseNigam;
  resBillPdfNigama;
  openPdfResponseBhandar;
  resBillPdfBhandar;
  txtNarration;

  stockQuantity: number = 0;
  public loading: boolean = false;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;

  constructor(
    private saleservice: SaleService,
    private salereturnservice: SaleReturnService,
    private itemsdetailservice: ItemsDetailService,
    private toastr: ToastrService,
    private partyService: PartyService,
    private companyService: CompanyService,
    private excelservice: ExcelService,
    private stockservice: StockService,
  ) { }

  ngOnInit(): void {
    this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, "dd/MM/yyyy");
    this.txtsaleDate = new Date();
    this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.txtExpiryDate = new Date();
    this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllParty();
    // this.getSaleNumber();
    // this.getAllsale();
    this.getAllCompany();
  }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //Sale Number
  getSaleNumber() {
    this.saleservice.getSaleNumber().subscribe(res => {
      this.saleNumber = res;
      this.txtsaleCode = this.saleNumber;
    })
  }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
    this.getItemById();
    this.getLastPrice();
  }
  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    this.txtMobileNumber= party.cellNumber;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    this.tempPartyopeningBalance = party.openingBalance;
    this.tempPartyId = party.partyId;
    this.getClosingBalance();
    this.getPartyCreditScore();
    this.getIagroCreditScore();
  }

  partycreditScore = 0;
  getPartyCreditScore() {
    var sdate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    var edate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.saleservice.getPartyCreditScore(this.tempParty.partyId,sdate,edate).subscribe(res=>{
      if(res.totalscore != null) {
        this.partycreditScore = res.totalscore;
      } else {
        this.partycreditScore = 0;
      }
      $(document).ready(function()
        {
          $("span.diffcolorparty" ).each(function() {
            if(res.totalscore >= 90) {
              $(this).css("color", "green");
            } else if(res.totalscore > 80 && res.totalscore < 90) {
              $(this).css("color", "skyblue");
            } else if(res.totalscore > 55 && res.totalscore <= 80) {
              $(this).css("color", "yellow");
            } else if(res.totalscore < 55) {
              $(this).css("color", "red");
            }
          });
        });
    })
  }
  iagroCreditScore = 0;
  getIagroCreditScore() {
    var sdate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    var edate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.saleservice.getIagroCreditScore(this.tempParty.partyId,sdate,edate).subscribe(res=>{
      if(res.totalscore != null) {
        this.iagroCreditScore = res.totalscore;
      } else {
        this.iagroCreditScore = 0;
      }
      $(document).ready(function()
        {
          $("span.diffcolor" ).each(function() {
            if(res.totalscore >= 90) {
              $(this).css("color", "green");
            } else if(res.totalscore > 80 && res.totalscore < 90) {
              $(this).css("color", "skyblue");
            } else if(res.totalscore > 55 && res.totalscore <= 80) {
              $(this).css("color", "yellow");
            } else if(res.totalscore < 55) {
              $(this).css("color", "red");
            }
          });
        });
    })
  }
  allparty;
  tempPartyopeningBalance;
  tempPartyId;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        if(item.openingBalance == null || item.openingBalance == undefined || item.openingBalance == ''){
          temp.openingBalance = 0;
        }
        temp.openingBalance = item.openingBalance;
        temp.address = item.address;
        this.searchPartyName.push(temp)
      }
      console.log(this.allparty);
    })
  }

  getPartyByCellNumber() {
    this.partyService.getPartyByCellNumber(this.txtMobileNumber).subscribe(res => {
      this.getParty = res;
      this.txtpartyName = this.getParty.partyName;
      this.txtpartyId = this.getParty.partyId;
    })
  }


  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.allItem);

    })
  }

  clearGridData() {
    this.txtItemName = "";
    this.txtManufactureDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = "";
    this.txtSubCategoryName = "";
    this.txtBrandName = "";
    this.txtquantity = "";
    this.txtrate = "";
    this.txtExpiryDate = new Date();
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = "";
    this.btnName = "Add";
    this.txtsaleid = null;
    this.batchwiseresponse = null;
    this.txtCGST = 0;
    this.txtSGST = 0;
  }

  clearValue() {
    this.txtsaleDate = new Date();
    // this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, "dd/MM/yyyy");
    this.txtsaleCode = '';
    this.txtnetAmount = 0;
    this.txtamount = 0;
    this.txtDiscount = 0;
    this.txtAdd =0;
    this.txtgstAmount = 0;
    this.txtpartyName = '';
    this.searchParty = '';
    this.saleData = [];
    this.txtMobileNumber = '';
    this.tempamount = 0;
    this.exitGST=0;
    this.txtNarration = '';
    this.getSaleNumber();
    this.txtClosingBalance = 0;
    this.txtLastPrice = 0;
    this.partycreditScore = 0;
    $(document).ready(function()
    {
      $("span.diffcolorparty" ).each(function() {
        $(this).css("color", "black");
      });
    });
    this.iagroCreditScore = 0;
    $(document).ready(function()
    {
      $("span.diffcolor" ).each(function() {
        $(this).css("color", "black");
      });
    });
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
  }

  resItemById;
  getItemById() {
    this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
      this.resItemById = res;
      console.log(this.resItemById);
      this.txtCategoryName = this.resItemById.category.categoryName;
      this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
      this.txtBrandName = this.resItemById.brand.name;
      this.txtCGST = this.resItemById.gst.cgst;
      this.txtSGST = this.resItemById.gst.sgst;
    });
  }

  addSaleDetailStockCheck() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate must be required');
      // } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      // return this.toastr.error(' Manufacture Date must be required');
      // } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      // return this.toastr.error(' Expiry Date must be required');
    }else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      this.saleservice.addSaleDetailStockCheck(this.itemId, this.txtquantity)
        .subscribe(res => {
          this.stockCheck = res;
          console.log(this.stockCheck);
          // this.clearValue();
          this.toastr.success('Stock available');
          this.addSaleDetail();
        }, error => {
          this.toastr.error('Stock not available for item');

        })
    }
  }

  addSaleDetail() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate must be required');
      // } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      // return this.toastr.error(' Manufacture Date must be required');
      // } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      // return this.toastr.error(' Expiry Date must be required');
    }else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      if (this.txtsaleid == null) {
        // this.txtItemName = this.tempitem;
        var saleDetail = new sale();
        if (this.saleData.length == 0) {
          saleDetail.id1 = this.saleIdLast;
        } else if (this.saleData.length != 0) {
          saleDetail.id1 = this.saleIdLast + 1;
        }
        this.saleIdLast = saleDetail.id1;
        saleDetail.itemName = this.search1; //this for selected itemName
        saleDetail.itemId = this.itemId;
        saleDetail.categoryName = this.txtCategoryName;
        saleDetail.subCategoryName = this.txtSubCategoryName;
        saleDetail.brandName = this.txtBrandName;
        saleDetail.quantity = this.txtquantity;
        saleDetail.rate = this.txtrate;
        saleDetail.cgst = this.txtCGST;
        saleDetail.sgst = this.txtSGST;
        saleDetail.totalAmount = this.txtrate * this.txtquantity;
        //base price calculation
        var totalpercentage = 100 + this.txtCGST + this.txtSGST
        var baseprice = 0;
        baseprice = parseFloat(Number(saleDetail.totalAmount * 100 / totalpercentage).toFixed(2));
        var totalgstamt = 0;
        totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
        // saleDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
        // saleDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
        saleDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.netAmount = baseprice;

        this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
        saleDetail.manufactureDate = this.txtManufactureDate;
        this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
        saleDetail.expiryDate = this.txtExpiryDate;
        if (this.batchwiseresponse == null) {
          this.getBatchNumber();
          saleDetail.stockDTOs = this.batchwiseresponse;
        } else {
          saleDetail.stockDTOs = this.batchwiseresponse;
        }
        this.stockQuantity = 0;
        for (let stockQuantityValue of this.batchwiseresponse) {
          this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
        }
        console.log("quantity",this.stockQuantity);
        if (this.stockQuantity > this.txtquantity) {
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is more than quantity.');
        }else if(this.stockQuantity < this.txtquantity){
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is less than quantity.');
        }
        this.saleData.push(saleDetail);

        //code for calculate netamount and gstamount;
        // var temp = 0;
        // var gstsum = 0;
        // temp = this.txtquantity * this.txtrate;
        // this.tempamount = this.tempamount + temp;

        // this.tempGST = temp * (this.txtCGST + this.txtSGST) / 100;
        // gstsum = gstsum + this.tempGST;
        // this.exitGST=this.exitGST+gstsum;
        // this.txtgstAmount = this.exitGST;

        // this.txtamount = this.tempamount - this.txtgstAmount;
        // this.txtnetAmount = this.tempamount;
        this.getAmount();
        this.calculateNetAmount();
        this.calculateAdd();
        this.clearGridData();

      } else {
        for (let sale of this.saleData) {
          if (sale.id1 == this.txtsaleid) {
            this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
            sale.manufactureDate = this.txtManufactureDate;
            sale.itemName = this.search1;
            sale.itemId = this.itemId;
            sale.categoryName = this.txtCategoryName;
            sale.subCategoryName = this.txtSubCategoryName;
            sale.brandName = this.txtBrandName;
            sale.quantity = this.txtquantity;
            sale.rate = this.txtrate;
            sale.cgst = this.txtCGST;
            sale.sgst = this.txtSGST;
            sale.totalAmount = this.txtrate * this.txtquantity;
            //base price calculation
            var totalpercentage = 100 + this.txtCGST + this.txtSGST
            var baseprice = 0;
            baseprice = parseFloat(Number(sale.totalAmount * 100 / totalpercentage).toFixed(2));
            var totalgstamt = 0;
            totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
            // sale.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
            // sale.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
            sale.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            sale.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            sale.netAmount = baseprice;

            this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
            sale.expiryDate = this.txtExpiryDate;
            if (this.batchwiseresponse == null) {
              this.getBatchNumber();
              sale.stockDTOs = this.batchwiseresponse;
            } else {
              sale.stockDTOs = this.batchwiseresponse;
            }
            this.stockQuantity = 0;
            for (let stockQuantityValue of this.batchwiseresponse) {
              this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
            }
            console.log("quantity",this.stockQuantity);
            if (this.stockQuantity > this.txtquantity) {
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is more than quantity.');
            }else if(this.stockQuantity < this.txtquantity){
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is less than quantity.');
            }
            this.getAmount();
            this.calculateNetAmount();
            this.calculateAdd();
            this.clearGridData();
          }
        }
      }
    }
    console.log(this.saleData);
  }

  calculateNetAmount() {
    if (this.txtDiscount == 0 ||  this.txtDiscount == undefined || this.txtDiscount == null) {
      this.txtDiscount = 0;
      if(this.txtAdd == 0){
        this.txtnetAmount = this.tempNetAmount;
      }else{
        this.txtnetAmount = this.tempNetAmount + Number(this.txtAdd);
      }
    } else {
        this.txtnetAmount = this.tempNetAmount;
        this.txtnetAmount = parseFloat(Number(this.txtnetAmount + Number(this.txtAdd - this.txtDiscount)).toFixed(2));
    }
  }

  calculateAdd(){
    if (this.txtAdd == 0 ||  this.txtAdd == undefined || this.txtAdd == null) {
      this.txtAdd = 0;
      if(this.txtDiscount == 0){
        this.txtnetAmount = this.tempNetAmount;
      }else{
        this.txtnetAmount = this.tempNetAmount - Number(this.txtDiscount);
      }
    } else {
        this.txtnetAmount = this.tempNetAmount;
        this.txtnetAmount = parseFloat(Number(this.txtnetAmount + Number(this.txtAdd - this.txtDiscount)).toFixed(2));
    }
  }

  tempamount = 0;
  tempGST = 0;
  tempNetAmount = 0;
  getAmount() {
    this.txtamount = 0;
    this.txtgstAmount = 0;
    this.tempamount = 0;
    this.tempGST = 0;
    var temp = 0;
    var gstsum = 0;
    this.txtnetAmount = 0;
    this.exitGST=0;

    for (let item of this.saleData) {
      // temp = item.quantity * item.rate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = temp * (item.cgst + item.sgst) / 100;
      // // gstsum = gstsum + this.tempGST;
      // this.exitGST=this.exitGST+this.tempGST;
      // this.txtgstAmount = this.exitGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;
      this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
      this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
      this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
      this.tempNetAmount = this.txtnetAmount;
    }
  }

  saleDetailres;
  createSaleDetail() {
    if (this.txtsaleDate == undefined || this.txtsaleDate == null || this.txtsaleDate == '') {
      return this.toastr.error('Sale date must be required');
      // } else if (this.txtsaleCode == undefined || this.txtsaleCode == null || this.txtsaleCode == '') {
      //   return this.toastr.error(' sale number must be required');
    // } else if (this.txtMobileNumber == null || this.txtMobileNumber == '' || this.txtMobileNumber == undefined) {
    //   this.toastr.error("Mobile Number is required");
    // } else if (this.txtMobileNumber.length > 10 || this.txtMobileNumber.length < 10) {
    //   this.toastr.error("Mobile Number must be 10 digit");
    } else if (this.txtpartyName == undefined || this.txtpartyName == null || this.txtpartyName == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.tempPartyId == undefined || this.tempPartyId == null || this.tempPartyId == '') {
      return this.toastr.error('Please select a party name');
    } else if (this.saleData.length == 0 || this.saleData == []) {
      return this.toastr.error('Sale detail must be required');
    } else {
      // this.txtpartyName = this.tempParty;
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, 'yyyy-MM-dd');
      this.saleservice.addSaleDetailSave(this.txtsaleDate, this.txtsaleCode, this.txtMobileNumber, this.tempPartyId, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.saleData,this.txtAdd, this.txtNarration)
        .subscribe(res => {
          this.saleDetailres = res;
          console.log(this.saleDetailres);
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
          }
          this.uploadImage(res.saleid);
          this.clearValue();
          this.getSaleNumber();
          this.toastr.success('Sale save successfully');
          this.getcompnayOrganizationPDF();
          $('#pdfModal').modal('show');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
        })
    }
  }

  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
    })
  }

  updateSale(sale) {
    this.btnName = "Update";
    this.txtsaleid = sale.id1;
    this.txtItemName = sale.itemName;
    this.itemId = sale.itemId;
    this.search1 = sale.itemName;
    this.txtCategoryName = sale.categoryName;
    this.txtSubCategoryName = sale.subCategoryName;
    this.txtBrandName = sale.brandName;
    this.txtquantity = sale.quantity;
    this.txtrate = sale.rate;
    this.txtCGST = sale.cgst;
    this.txtSGST = sale.sgst;
    this.txtManufactureDate = sale.manufactureDate;
    this.txtExpiryDate = sale.expiryDate;

  }

  i;
  deleteSaleData;
  deleteSale(sale) {
    this.deleteSaleData = sale;
  }

  confirmDelete(){
    for (let saleData of this.saleData) {
      if (saleData.id1 == this.deleteSaleData.id1) {
        this.i = this.deleteSaleData.saleId;
        let index = this.saleData.indexOf(this.deleteSaleData);
        if (saleData.id1 !== -1) {
          this.saleData.splice(index, 1);
          this.getAmount();
          this.calculateNetAmount();
          this.calculateAdd();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
        }
      }
    }
  }

  totalStockQuantity = 0;
  getBatchNumber() {
    this.saleservice.getStockByItemPerBatch(this.itemId, this.txtquantity)
      .subscribe(res => {
        this.batchwiseresponse = res;
        if (this.batchwiseresponse.length <= 0) {
          return this.toastr.error('Stock Not Found.');
        }
        console.log(this.batchwiseresponse);
        this.totalStockQuantity = 0;
        for (let batchWiseStock of this.batchwiseresponse) {
          if (batchWiseStock.allotedquantity == null) {
            batchWiseStock.allotedquantity = 0
          }
          var sum = 0;
          sum = sum + Number(batchWiseStock.quantity);
          this.totalStockQuantity = this.totalStockQuantity + sum;
        }
        console.log("batchWise Stock", this.batchwiseresponse);
        if(this.txtquantity > this.totalStockQuantity){
          this.toastr.error('Stock not available for item');
        } else {
          this.toastr.success('Stock available');
        }
        // this.addSaleDetail();
      }, error => {
        this.toastr.error('Stock not available for item');
      })

  }

  allCompany = [];
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  bijNigamId
  bijBhandarId
  tempPdfCompany;
  NoOfCompanyLength;
  allPDFCompany;
  getcompnayOrganizationPDF() {
    this.saleservice.getCompanyOganizationPDFData(this.saleDetailres.saleid).subscribe(res=>{
      console.log(res);
      this.allPDFCompany = res;
      this.NoOfCompanyLength = res.length;
      // for (let data of res) {
        // if (data.companyName == "RADHASWAMI BIJ NIGAM") {
        //   this.bijNigamId = data.companyId
        // } else if (data.companyName == "RADHASWAMI BIJ BHANDAR") {
        //   this.bijBhandarId = data.companyId
        // } else {
        //   this.toastr.error("something went wrong");
        // }
        // for(let comp of this.allCompany) {
        //   if (data.companyName == comp.companyName) {
        //     this.tempPdfCompany = data.companyName;
        //     this.saleBhandarPDF();
        //     break;
        //   } else {
        //     this.tempPdfCompany = null;
        //   }
        // }
      // }
    }, error => {
      this.NoOfCompanyLength = null;
      this.allPDFCompany = null;
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  downloadPDF() {
    if ((this.bijNigamId != undefined || this.bijNigamId != null) && (this.bijBhandarId != undefined || this.bijBhandarId != null)) {
      this.saleNigamPDF();
      this.saleBhandarPDF();
    } else if (this.bijNigamId != undefined || this.bijNigamId != null) {
      this.saleNigamPDF();
    } else if (this.bijBhandarId != null || this.bijBhandarId != undefined) {
      this.saleBhandarPDF();
    } else {
      this.toastr.error("something went wrong.");
    }
    this.bijBhandarId = null;
    this.bijNigamId = null;
  }

  saleNigamPDF() {
    this.saleservice.downloadSaleNigamBill(this.saleDetailres.saleNumber).subscribe((data) => {
      this.resBillPdfNigama = data;

      var newBlob = new Blob([this.resBillPdfNigama], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob);
        return;
      }

      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Sale_Bij_Nigam.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseNigam.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
      this.resBillPdfNigama = '';
      this.openPdfResponseNigam = '';
    })
  }

  saleBhandarPDF() {
    this.saleservice.downloadSaleBhandarBill(this.saleDetailres.saleNumber,this.tempPdfCompany,this.NoOfCompanyLength).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_Bij_Bhandar.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  downloadPdfByCompany(company,noofCompany) {
    this.saleservice.downloadSaleBhandarBill(this.saleDetailres.saleNumber,company.companyName,noofCompany).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  resClosingBalance;
  txtCredit;
  txtDebit;
  txtClosingBalance =0;
  tempClosingBalance;
  txtFormDate;
  txtToDate;
  getClosingBalance() {
    var startDate = new Date();
    var curr_year = startDate.getFullYear();
    var curr_month = startDate.getMonth() + 1;
    if (curr_month < 4) {
      curr_year = startDate.getFullYear()-1;
    } else {
      curr_year = startDate.getFullYear();
    }
    this.txtFormDate = (curr_year + "-" + '04' + "-" + '01');
    this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
    this.txtToDate = this.datePipe.transform(new Date() , 'yyyy-MM-dd');
    this.saleservice.getOpeningBalance(this.tempPartyId,this.txtFormDate,this.txtToDate).subscribe(res => {
      this.resClosingBalance = res;
      console.log(this.resClosingBalance);
      this.txtCredit = this.resClosingBalance.credit;
      this.txtDebit = this.resClosingBalance.debit;
      this.tempClosingBalance = this.txtCredit - this.txtDebit;
      // if(this.tempPartyopeningBalance < 0 ){
        this.txtClosingBalance = this.tempClosingBalance + this.tempPartyopeningBalance;
      // }
    });
  }

  resLastPrice;
  txtLastPrice = 0;
  getLastPrice() {
    this.saleservice.getLastPrice(this.tempPartyId,this.itemId).subscribe(res => {
      this.resLastPrice = res;
      console.log(this.resLastPrice);
      this.txtLastPrice = this.resLastPrice.rate;
    }, error => {
      this.txtLastPrice = 0;
      // this.toastr.error("sale Detail not found");
    });
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage(saleid) {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.imagePreview = "";
      // this.getAllCompany();
      this.clearValue();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('sale', saleid);
      this.saleservice.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        // this.getAllCompany();
        this.clearValue();
        this.imagePreview = "";
      }, error => {
        this.imagePreview = "";
        this.clearValue();
        // this.getAllCompany();
      })
    }
  }


  //Sale master Excel entry
  txtExcelBrand;
  ExcelDataBrand = [];
  fileNameBrand;
  selectedExcelBrand;
  brandData;
  exportExcelSaleINIT(): void {
    this.ExcelDataBrand = [];
    this.fileNameBrand = "Sale";
    this.excelservice.exportExcelSaleINIT(this.fileNameBrand,this.ExcelDataBrand);
  }
  onExcelUploadSale(event){
    this.selectedExcelBrand = <File>event.target.files[0];
  }
  importandConvertExcelSale(){
    if (this.selectedExcelBrand) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelBrand);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var brand = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[brand]);
        this.brandData = rowObject;
        var resBrand = [];
        for(let data of this.brandData){
          var temexprdate = (data["saleDate"] - 25569) * 24 * 60 * 60 * 1000;
          var cvrtExpiry = this.datePipe.transform(temexprdate,"yyyy-MM-dd")
          resBrand.push({"saleNumber":data.saleNumber,"partyName":data.partyName,"cellNumber":data.cellNumber,"amount":data.amount,"additionalcharges":data.additionalcharges
          ,"netAmount":data.netAmount,"discount":data.discount,"gstAmount":data.gstAmount,"narration":data.narration,"saleDate":cvrtExpiry,"type":data.type});
        }
        //save excel data inot server
        this.saleservice.importExcelSaleINIT(resBrand).subscribe(res => {
        // console.log(res);
          this.toastr.success("Sale master save successfully");
          this.selectedExcelBrand = '';
          this.txtExcelBrand = '';
          // this.txtfilename = '';
          // this.getAllBrand();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }


  //Sale Detail excel entry
  txtExcelUnit;
  ExcelDataUnit = [];
  fileNameUnit;
  selectedExcelUnit;
  unitData;
  exportExcelSaleDetailINIT(): void {
    this.ExcelDataUnit = [];
    this.fileNameUnit = "SaleDetail";
    this.excelservice.exportExcelSaleDetailINIT(this.fileNameUnit,this.ExcelDataUnit);
  }
  onExcelUploadSaleDetail(event){
    this.selectedExcelUnit = <File>event.target.files[0];
  }
  importandConvertExcelSaleDetail(){
    if (this.selectedExcelUnit) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelUnit);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var unit = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[unit]);
        this.unitData = rowObject;
        var resUnit = [];
        for(let data of this.unitData){
          resUnit.push({"saleNumber":data.saleNumber,"itemName":data.itemName,"companyId":data.companyId,"sgstAmount":data.sgstAmount,
          "netAmount":data.netAmount,"quantity":data.quantity,"rate":data.rate,"cgstAmount":data.cgstAmount,"totalAmount":data.totalAmount,"type":data.type});
        }
        //save excel data inot server
        this.saleservice.importExcelSaleDetailINIT(resUnit).subscribe(res => {
        // console.log(res);
          this.toastr.success("Sale detail save successfully");
          this.selectedExcelUnit = '';
          this.txtExcelUnit = '';
          // this.txtfilename = '';
          // this.getAllUnit();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }
  
  ExalData=[];
  allItemStock;
  exportAsXLSX():void {
    this.ExalData=[];
    this.stockservice.getAllItemStockDemo().subscribe(res => {
      this.allItemStock = res;
      this.excelservice.exportAsDemoStock(this.allItemStock, 'Get Stock');
    })
    // for(let exal of this.allItemStock){
    //   this.ExalData.push({ "itemName":exal.itemName, "batchNumber": exal.batchNumber, "quantity":exal.quantity, "rate":exal.rate, 
    //   "manufactureDate":exal.manufactureDate,"expiryDate":exal.expiryDate})
    // }
 }

 //voucher master Detail excel entry
 txtVMExcel;
 ExcelDataVM = [];
 fileNameVM;
 selectedExcelVM;
 VMData;
 exportExcelVMDetailINIT(): void {
   this.ExcelDataVM = [];
   this.fileNameVM = "VMDetail";
   this.excelservice.exportExcelVMDetail(this.fileNameVM,this.ExcelDataVM);
 }
 onExcelUploadVMDetail(event){
   this.selectedExcelVM = <File>event.target.files[0];
 }
 importandConvertExcelVMDetail(){
   if (this.selectedExcelVM) {
     let fileReader = new FileReader();
     fileReader.readAsBinaryString(this.selectedExcelVM);
     fileReader.onload = (event) => {
       let data = event.target.result;
       let workbook = XLSX.read(data, { type: "binary" });
       // console.log(workbook.SheetNames[0]);
       var unit1 = workbook.SheetNames[0]
       //  workbook.SheetNames.forEach(sheet => {
       let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[unit1]);
       this.VMData = rowObject;
       var resVM = [];
       for(let data of this.VMData){
        var txtvdate = (data["voucherDate"] - 25569) * 24 * 60 * 60 * 1000;
        var txtvddate = this.datePipe.transform(txtvdate,"yyyy-MM-dd")
        resVM.push({"voucherMasterId":data.voucherMasterId,"Amount": '0',"bankId":data.bankId,"companyId":data.companyId,
         "creditAmount":data.creditAmount,"debitAmount": data.debitAmount,"detail":data.detail,"detailId":data.detailId,"invoiceId":data.invoiceId,"partyName":data.partyName,"voucherDate":txtvddate,"mobileNumber":data.mobileNumber});
       }
       //save excel data inot server
       this.saleservice.importExcelVMDetail(resVM).subscribe(res => {
       // console.log(res);
         this.toastr.success("Voucher detail save successfully");
         this.selectedExcelVM = '';
         this.txtVMExcel = '';
         // this.txtfilename = '';
         // this.getAllUnit();
       }, error => {
         let message = JSON.parse(error._body).message;
         this.toastr.error(JSON.stringify(message));
       });
     }
   } else {
     this.toastr.error("please select file");
   }
 }

 //sale return master
  txtExcelSR;
  ExcelDataSR = [];
  fileNameSR;
  selectedExcelSR;
  sRData;
  exportExcelSRINIT(): void {
    this.ExcelDataSR = [];
    this.fileNameSR = "SaleReturn";
    this.excelservice.exportExcelSaleReturnINIT(this.fileNameSR,this.ExcelDataSR);
  }
  onExcelUploadSR(event){
    this.selectedExcelSR = <File>event.target.files[0];
  }
  importandConvertExcelSR(){
    if (this.selectedExcelSR) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelSR);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var brand = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[brand]);
        this.sRData = rowObject;
        var resSR = [];
        for(let data of this.sRData){
          var temexprdate = (data["saleReturnDate"] - 25569) * 24 * 60 * 60 * 1000;
          var cvrtExpiry = this.datePipe.transform(temexprdate,"yyyy-MM-dd")
          resSR.push({"salereturnNumber":data.salereturnNumber,"partyName":data.partyName,"cellNumber":data.cellNumber,"amount":data.amount
          ,"netAmount":data.netAmount,"discount":data.discount,"gstAmount":data.gstAmount,"narration":data.narration,"saleReturnDate":cvrtExpiry,"type":data.type,"location":data.location});
        }
        //save excel data inot server
        this.salereturnservice.importExcelSaleReturnINIT(resSR).subscribe(res => {
        // console.log(res);
          this.toastr.success("Sale return master save successfully");
          this.selectedExcelSR = '';
          this.txtExcelSR = '';
          // this.txtfilename = '';
          // this.getAllBrand();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  //sale return detail
  txtExcelSRD;
  ExcelDataSRD = [];
  fileNameSRD;
  selectedExcelSRD;
  sRDData;
  exportExcelSRDINIT(): void {
    this.ExcelDataSRD = [];
    this.fileNameSRD = "SaleReturnDetail";
    this.excelservice.exportExcelSaleReturnDetailINIT(this.fileNameSRD,this.ExcelDataSRD);
  }
  onExcelUploadSRD(event){
    this.selectedExcelSRD = <File>event.target.files[0];
  }
  importandConvertExcelSRD(){
    if (this.selectedExcelSRD) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedExcelSRD);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        // console.log(workbook.SheetNames[0]);
        var SRD = workbook.SheetNames[0]
        //  workbook.SheetNames.forEach(sheet => {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[SRD]);
        this.sRDData = rowObject;
        var resSRD = [];
        for(let data of this.sRDData){
          var temexprdate = (data["manufactureDate"] - 25569) * 24 * 60 * 60 * 1000;
          var cvrtmanu1 = this.datePipe.transform(temexprdate,"yyyy-MM-dd")
          var temexprdate1 = (data["expiryDate"] - 25569) * 24 * 60 * 60 * 1000;
          var cvrtExpiry1 = this.datePipe.transform(temexprdate1,"yyyy-MM-dd")
          resSRD.push({"salereturnNumber":data.salereturnNumber,"itemName":data.itemName,"companyId":data.companyId,"sgstAmount":data.sgstAmount,"netAmount":data.netAmount,
          "quantity":data.quantity,"rate":data.rate,"cgstAmount":data.cgstAmount,"totalAmount":data.totalAmount,"salenumber":data.salenumber,"batchNumber":data.batchNumber,
          "manufactureDate":cvrtmanu1,"expiryDate":cvrtExpiry1,"location":data.location});
        }
        //save excel data inot server
        this.salereturnservice.importExcelSaleReturnDetailINIT(resSRD).subscribe(res => {
        // console.log(res);
          this.toastr.success("Sale return Detail save successfully");
          this.selectedExcelSRD = '';
          this.txtExcelSRD = '';
          // this.txtfilename = '';
          // this.getAllBrand();
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        });
      }
    } else {
      this.toastr.error("please select file");
    }
  }

  OpeningData=[];
  AllData;
  exportAsOpening():void {
    this.ExalData=[];
    this.stockservice.getAllItemStockBalance().subscribe(res => {
      this.AllData = res;
      this.excelservice.exportAsDemoBalance(this.AllData, 'Get OpeningBalance');
    })
    // for(let exal of this.allItemStock){
    //   this.ExalData.push({ "itemName":exal.itemName, "batchNumber": exal.batchNumber, "quantity":exal.quantity, "rate":exal.rate, 
    //   "manufactureDate":exal.manufactureDate,"expiryDate":exal.expiryDate})
    // }
 }

 txtExcelopp;
 ExcelDataopp = [];
 fileNameopp;
 selectedExcelopp;
 oppData;
 onExcelUploadOpening(event){
  this.selectedExcelopp = <File>event.target.files[0];
}
importandConvertExcelOPP(){
  if (this.selectedExcelopp) {
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(this.selectedExcelopp);
    fileReader.onload = (event) => {
      let data = event.target.result;
      let workbook = XLSX.read(data, { type: "binary" });
      // console.log(workbook.SheetNames[0]);
      var SRD = workbook.SheetNames[0]
      //  workbook.SheetNames.forEach(sheet => {
      let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[SRD]);
      this.oppData = rowObject;
      var resOPPS = [];
      for(let data of this.oppData){
        resOPPS.push({"allclosingbalance":data.allclosingbalance,"rsbnclosingbalance":data.rsbnclosingbalance,
        "rsbbclosingbalance":data.rsbbclosingbalance,"mobilenumber":data.mobilenumber});
      }
      //save excel data inot server
      this.salereturnservice.importExceloppINIT(resOPPS).subscribe(res => {
      // console.log(res);
        this.toastr.success("Opening Detail save successfully");
        this.selectedExcelSRD = '';
        this.txtExcelSRD = '';
        // this.txtfilename = '';
        // this.getAllBrand();
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      });
    }
  } else {
    this.toastr.error("please select file");
  }
}

}
