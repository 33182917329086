import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WhatsappMessageService {

  constructor(
    private http: Http,
  ) { }

  createWhatsAppMsg(txtWhatsAppMsg: string,txtImage: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      whatsappMsg: txtWhatsAppMsg,
      image: txtImage
    });
    return this.http.post('/api/create/whatsappmsg', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateWhatsAppMsg(txtWhatsAppMsgId: string, txtWhatsAppMsg: string, txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      whatsappMsg: txtWhatsAppMsg,
      whatsappmsgId: txtWhatsAppMsgId,
      image: txtImage
    });
    return this.http.put('/api/update/whatsappmsg', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllWhatsAppMsg() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/whatsappmsg', { headers })
      .pipe(map(res => res.json()));
  }

  deleteWhatsAppMsg(txtWhatsAppMsgId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      whatsappmsgId: txtWhatsAppMsgId,
    });
    return this.http.put('/api/delete/whatsappmsg', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/whatsappmsg/image', data, { headers: headers })
      .pipe(map(res => res));
  }
}
