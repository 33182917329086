export class hsnDto{
    HSN: string;
    Description: string;
    UQC: string;
    TotalQuantity: string;
    TotalValue: number;
    TaxableValue: number;
    IntegratedTaxAmount: number;
    CentralTaxAmount: number;
    StateUTTaxAmount: number;
    CessAmount: number;
    rate:number;
    // Rate: number;
    // TaxableValue: number;
    // CessAmount: number;
    // TotalInvoiceValue: number;
    // TotalTaxableValue: number;
}