import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { AgroGlobalConst } from '../agro-global';

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  addSaleDetailSave(txtsaleDate: string, txtsaleCode: string, txtCellNUmber: string, txtpartyName:string,txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, sale: Array<any>,txtAdd:number, txtNarration: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleDate: txtsaleDate,
      saleNumber: txtsaleCode,
      cellNumber: txtCellNUmber,
      partyName:txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      saleDetailDTOs: sale,
      type:"CREDIT",
      additionalcharges:txtAdd,
      narration:txtNarration
    });
    return this.http.post('/api/create/sale', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  addSaleCashDetail(txtsaleDate: string, txtsaleCode: string, txtCellNUmber: string, txtpartyName:string,txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, sale: Array<any>,txtAdd:number, txtNarration: string, optRadio: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleDate: txtsaleDate,
      saleNumber: txtsaleCode,
      cellNumber: txtCellNUmber,
      partyName:txtpartyName,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      saleDetailDTOs: sale,
      type:"CASH",
      additionalcharges:txtAdd,
      narration: txtNarration,
      paymentMode: optRadio
    });
    return this.http.post('/api/create/sale', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllsale(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/sale', { headers })
      .pipe(map(res => res.json()));
  }

  getAllsaleByDate(stratdate:string,endDate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sale/date/'+stratdate +'/'+endDate, { headers })
      .pipe(map(res => res.json()));
  }
  
  getSaleNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salenumber', { headers })
      .pipe(map(res => res.json()));
  }

  addSaleDetailStockCheck(itemid:string,quantity:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      // itemName: itemname,
      itemid: itemid,
      quantity: quantity
    });

    return this.http.put('/api/get/stock/check', body,{ headers })
      .pipe(map(res => res.json()));
  }

  getStockByItemPerBatch(itemid:string,quantity:number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      // itemName: itemname,
      itemid: itemid,
      quantity: quantity
    });

    return this.http.put('/api/get/stock/check/byitem', body,{ headers })
      .pipe(map(res => res.json()));
  }

  public downloadSaleNigamBill(billnumber: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/sale/nigam/bill/` + billnumber, { headers: header, responseType: 'blob' });
  }

  public downloadSaleBhandarBill(billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/sale/bhandar/bill/` + billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

  getCompanyOganizationPDFData(saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/company/organization/detail/' + saleid,{ headers })
      .pipe(map(res => res.json()));
  }
  getPDFCategoryNoOfInvoiceData(saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/pdf/category/noofinvoice/detail/' + saleid,{ headers })
      .pipe(map(res => res.text()));
  }
  // new sale PDF by category
  public downloadSalePesticideBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/sale/pesticide/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadSaleFertiliserBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/sale/fertiliser/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadSaleSeedsBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/sale/seeds/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

  sendNigamEmail(saleno: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/sale/nigam/'+ saleno +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  sendBhandarEmail(saleno: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/sale/bhandar/'+ saleno +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  sendMailWithSendGrid(saleno: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleNumber: saleno,
      email: email
    });
    return this.http.post('/api/sendgrid', body, { headers })
      .pipe(map(res => res));
  }

  getOpeningBalance(tempPartyId: string,txtFormDate :string,txtToDate:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledger/closing/'+tempPartyId+'/date/'+txtFormDate+'/'+txtToDate , { headers })
      .pipe(map(res => res.json()));
  }
  getLastPrice(tempPartyid: string,itemid :string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/' + tempPartyid + '/item/' + itemid , { headers })
      .pipe(map(res => res.json()));
  }

  createEwayBill(txtToGstIn: string, txtToTradeName: string, txtToAddress1: string, txtToAddress2: string,
    txtToPlace: string, txtToPincode: string, txtOtherValue: number, txtTotalValue: number, txtCgstValue: number, txtSgstValue: number,
    txtIgstValue: number, txtCessValue: number, txtCessNonAdvolValue: number, txttotInvValue: number, txtTransporterName: string,
    txtTransDocNo: string, txtTransMode: string, txtTransDocDate: string, txtVehicleNo: string, txtVehicleType: string, txtSaleId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      toGstin: txtToGstIn,
      toTrdName: txtToTradeName,
      toAddr1: txtToAddress1,
      toAddr2:txtToAddress2,
      toPlace: txtToPlace,
      toPincode: txtToPincode,
      otherValue: txtOtherValue,
      totalValue: txtTotalValue,
      cgstValue: txtCgstValue,
      sgstValue: txtSgstValue,
      igstValue: txtIgstValue,
      cessValue: txtCessValue,
      cessNonAdvolValue: txtCessNonAdvolValue,
      totInvValue: txttotInvValue,
      transporterName: txtTransporterName,
      transDocNo: txtTransDocNo,
      transMode: txtTransMode,
      // transDistance: txtTransDistance,
      transDocDate: txtTransDocDate,
      vehicleNo: txtVehicleNo,
      vehicleType: txtVehicleType,
      saleid: txtSaleId,
    });
    return this.http.post('/api/create/gstewaybill', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  deleteSale(saleDeleteId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleid: saleDeleteId,
    });
    return this.http.put('/api/delete/sale', body, { headers })
      .pipe(map(res => res.json()));
  }

  getPartyCreditScore(partyid:string, startdate:string, enddate:string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/closing/location/date/'+startdate+'/'+ enddate +'/'+partyid, { headers })
      .pipe(map(res => res.json()));
  }
  getIagroCreditScore(partyid:string, startdate:string, enddate:string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/party/overall/credit/score/date/'+startdate+'/'+ enddate +'/'+partyid, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/upload/credit/sale/image', data, { headers: headers })
      .pipe(map(res => res));
  }

  public getEWayBillDetailServer(saleid): Observable<Blob>{
    var token = localStorage.getItem('Token');
    let headers = new HttpHeaders().set('Authorization', token);

    return this.httpClient.get('/api/get/gstewaybill/'+saleid,{ headers: headers , responseType: 'blob'});
  }

  importExcelSaleINIT(resBrand){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   name : resCategory,
    // });
    return this.http.post('/api/create/list/salemaster', resBrand, { headers })
      .pipe(map(res => res));
  }

  importExcelSaleDetailINIT(resUnit){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/salemasterdetail', resUnit, { headers })
      .pipe(map(res => res));
  }
  
  importExcelVMDetail(resUnit){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/vouchermaster/demo', resUnit, { headers })
      .pipe(map(res => res));
  }

  whatsappSale(saleid:string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/sale/whatsup/'+saleid , { headers })
      .pipe(map(res => res.json()));
  }

  whatsappThanks(salenumber:number, companyname: string, numofcompany: number){
    return this.httpClient.get(`/api/generate/sale/bhandar/bill/`+salenumber + `/` + companyname + `/`+ numofcompany, { responseType: 'blob' });
  }

  whatsappLedger(tempParty: string,fromDate : string,txtToDate :string, PartyClosingBalance: number){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledgerdetail/whatsup/'+tempParty+ '/date/'+ fromDate +'/'+ txtToDate +'/'+ PartyClosingBalance , { headers })
      .pipe(map(res => res.json()));
  }

  whatsappLedgerCompanyWise(tempParty: string,fromDate : string,txtToDate :string, PartyClosingBalance: number,company : string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/ledgerdetail/whatsup/'+tempParty+ '/date/'+ fromDate +'/'+ txtToDate +'/'+ PartyClosingBalance +'/company/'+ company, { headers })
      .pipe(map(res => res.json()));
  }
  
  sendRevisedBillMSG(mobileNumber: string,verifymsg: string) {
    return this.http.get('https://chhoteraja.com/api/send-text.php?number=91'+mobileNumber+'&msg='+verifymsg+'&apikey='+AgroGlobalConst.apikey+'&instance='+AgroGlobalConst.instantkey)
    .pipe(map(res => res));    
  }

  deleteMultiMedia(listOfData: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.post('/api/get/delete/pdf/s3',listOfData , { headers })
      .pipe(map(res => res));
  }

  getSaleDetailBySaleId(saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/saledetail/from/sale/' + saleid,{ headers })
      .pipe(map(res => res.json()));
  }

  createSaleDetail(txtItemName: string,itemId: string,txtCategoryName: string,txtSubCategoryName: string,txtBrandName: string,txtquantity: string,txtrate: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token", token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemName: txtItemName,
      itemId: itemId,
      categoryName: txtCategoryName,
      subCategoryName: txtSubCategoryName,
      brandName: txtBrandName,
      quantity: txtquantity,
      rate: txtrate
    });
    return this.http.post('/api/create/saledetail', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateSaleDetail(txtsaledetailid: string,txtItemName: string,itemId: string,txtCategoryName: string,txtSubCategoryName: string,txtBrandName: string,txtquantity: string,txtrate: string,txtStockDTOs: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saledetailid: txtsaledetailid,
      itemName: txtItemName,
      itemId: itemId,
      categoryName: txtCategoryName,
      subCategoryName: txtSubCategoryName,
      brandName: txtBrandName,
      quantity: txtquantity,
      rate: txtrate,
      stockDTOs: txtStockDTOs
    });
    return this.http.put('/api/update/saledetail', body, { headers })
      .pipe(map(res => res.json()));
  }

}