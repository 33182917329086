import { Component, OnInit } from '@angular/core';
import { CashBookService } from '../service/cash-book/cash-book.service';
import { PartyService } from '../service/party/party.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LedgerSearchStockDTO } from '../service/excel/LedgerSearchStockDTO';
import { ExcelService } from '../service/excel/excel.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
import { CompanyService } from '../service/company/company.service';

@Component({
  selector: 'app-cash-book',
  templateUrl: './cash-book.component.html',
  styleUrls: ['./cash-book.component.css']
})
export class CashBookComponent implements OnInit {

  allCompany;
  txtFormDate;
  txtToDate;
  // txtLedger;
  allParty;
  // partyId;
  reSearchData;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  search;
  page = 1;
  txtTotalCredit = 0;
  txtTotalDebit = 0;
  txtTotalBalance;

  receiptData = [];
  txtCreditOpeningBalance = 0;
  txtDebitOpeningBalance = 0;
  txtCreditClosingBalance = 0;
  txtDebitClosingBalance = 0;
  txtTotalDifferent = 0;
  cashLedgerId = "6";
  txtcompany;
  allReceiptById;
  cashOpeningBlance;
  constructor(
    private companyService: CompanyService,
    private cashBookService: CashBookService,
    private toastr: ToastrService,
    private excelservice: ExcelService,
    private receiptvoucherservice: ReceiptVoucherService,
  ) { }

  ngOnInit(): void {
    this.txtFormDate = new Date();
    this.txtToDate = new Date();
    this.getAllCompany();
    // this.cashOpeningBalance();
  }

  // cashOpeningBalance(){
  //   this.companyService.getCashOpeningBalance(this.txtcompany).subscribe(res => {
  //     this.cashOpeningBlance = res;
  //     this.txtDebitOpeningBalance = res.openingBalance;
  //   })
  // }
  cashOpeningBlanceByMonth;
  previousdayDate;
  txtStartDate;
  getCashOpeningBalanceByMonth(){
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name is required");
    } else {
      this.txtStartDate = "2021-04-01";
      // var startDate = new Date();
      // var curr_year = startDate.getFullYear();
      // var curr_month = startDate.getMonth() + 1;
      // if (curr_month < 4) {
      //   curr_year = startDate.getFullYear()-1;
      // } else {
      //   curr_year = startDate.getFullYear();
      // }
      // this.txtStartDate = (curr_year + "-" + '04' + "-" + '01');
      this.previousdayDate = new Date(this.txtFormDate);
      this.previousdayDate.setDate(this.previousdayDate.getDate() - 1);
      // this.txtStartDate = this.datePipe.transform(this.txtStartDate, 'yyyy-MM-dd');
      this.previousdayDate = this.datePipe.transform(this.previousdayDate, 'yyyy-MM-dd');
      this.cashBookService.getCashOpeningBalanceByMonth(this.txtStartDate,this.previousdayDate,this.txtcompany).subscribe(res => {
        this.cashOpeningBlanceByMonth = res;
        this.txtDebitOpeningBalance = res.closingBalance;
        this.searchCashBookDetailByStartEndDate();
      })
    }
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  txtVoucherTotalCredit = 0;
  txtVoucherTotalDebit = 0;
  getvoucherDetail(receipt) {
    this.getVoucherById(receipt.vouchermasterid)
    // this.txtDate = receipt.voucherDate;
    // console.log("Receipt Date",receipt);
  }
  getVoucherById(txtId) {
    this.receiptvoucherservice.getAllReceiptById(txtId).subscribe(res => {
      this.allReceiptById = res;
      // console.log(this.allReceiptById);
      this.txtVoucherTotalCredit = 0;
      this.txtVoucherTotalDebit = 0;
      for (let receiptData of this.allReceiptById) {
        this.txtVoucherTotalDebit = this.txtVoucherTotalDebit + receiptData.debit;
        this.txtVoucherTotalCredit = this.txtVoucherTotalCredit + receiptData.credit;
      }
    })
  }

  reSearchDataLength;
  searchCashBookDetailByStartEndDate() {
    this.totalNetAmount = 0;
    this.txtTotalCredit = 0;
    this.txtTotalDebit = 0;
    this.txtTotalBalance = 0;
    this.txtCreditClosingBalance = 0;
    this.txtDebitClosingBalance = 0;
    this.txtTotalDifferent = 0;
    this.receiptData = [];
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      this.toastr.error("Company name is required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      // this.getCashOpeningBalanceByMonth();
      this.cashBookService.getCashBookDetail(this.cashLedgerId, this.txtcompany, this.txtFormDate, this.txtToDate).subscribe(res => {
        this.reSearchData = res;
        this.reSearchDataLength = res.length;
        // console.log(res);

        // this.isEnable = true;
        if (this.reSearchData.length == 0) {
          this.receiptData = [];
          this.txtCreditClosingBalance = this.txtCreditOpeningBalance;
          this.txtDebitClosingBalance = this.txtDebitOpeningBalance;
          this.reSearchData.push("totalbalance", "0")
        } else {
          this.receiptData = [];
          this.txtTotalBalance = this.txtDebitOpeningBalance;
          for (let ii = 0; ii < this.reSearchData.length; ii++) {
            this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
            this.txtTotalCredit = this.txtTotalCredit + this.reSearchData[ii].credit;
            if (this.reSearchData[ii].debit != 0 && this.reSearchData[ii].credit == 0) {
              this.txtTotalBalance = this.txtTotalBalance + this.reSearchData[ii].debit;
              // console.log("particular" + ii);
              this.receiptData.push({
                "voucherDate": this.reSearchData[ii].voucherdate, "detail": this.reSearchData[ii].detail, "debit": this.reSearchData[ii].debit
                , "credit": this.reSearchData[ii].credit, "txtTotalBalance": this.txtTotalBalance,"vouchermasterid":this.reSearchData[ii].vouchermasterid,"customername": this.reSearchData[ii].customername
              })

            } else if (this.reSearchData[ii].credit != 0 && this.reSearchData[ii].debit == 0) {
              this.txtTotalBalance = this.txtTotalBalance - this.reSearchData[ii].credit;
              this.receiptData.push({
                "voucherDate": this.reSearchData[ii].voucherdate, "detail": this.reSearchData[ii].detail, "debit": this.reSearchData[ii].debit
                , "credit": this.reSearchData[ii].credit, "txtTotalBalance": this.txtTotalBalance,"vouchermasterid":this.reSearchData[ii].vouchermasterid,"customername": this.reSearchData[ii].customername
              })
            }
          }
          this.txtTotalDifferent = Math.abs(this.txtTotalDebit - this.txtTotalCredit);
          // console.log("diff",this.txtTotalDifferent);
          // console.log("total debit",this.txtTotalDebit);
          // console.log("total credit",this.txtTotalCredit);
          if (this.txtTotalDebit > this.txtTotalCredit) {
            if (this.txtTotalDifferent < this.txtCreditOpeningBalance) {
              this.txtCreditClosingBalance = this.txtCreditOpeningBalance - this.txtTotalDifferent - this.txtDebitOpeningBalance;
            } else {
              this.txtDebitClosingBalance = this.txtTotalDifferent + this.txtDebitOpeningBalance - this.txtCreditOpeningBalance;
            }
          } else {
            if (this.txtTotalDifferent < this.txtDebitOpeningBalance) {
              this.txtDebitClosingBalance = this.txtDebitOpeningBalance - this.txtTotalDifferent - this.txtCreditOpeningBalance;
            } else {
              this.txtCreditClosingBalance = this.txtTotalDifferent + this.txtCreditOpeningBalance - this.txtDebitOpeningBalance;
            }
          }
        }
        if(this.receiptData.length == 0 || this.receiptData == null || this.receiptData == undefined){
          this.toastr.error("No data found.");
          this.isEnable = false;
        } else {
          this.isEnable = true;
        }
        // console.log(this.receiptData);
        // console.log(this.reSearchData);
      }, error => {
        this.isEnable = true;
        this.txtCreditClosingBalance = this.txtCreditOpeningBalance;
        this.txtDebitClosingBalance = this.txtDebitOpeningBalance;
        // let message = JSON.parse(error._body).message;
        // this.toastr.error(JSON.stringify(message));
      });
    }
  }

  ExcelData = [];
  TotalAmount = 0;
  TotalTaxableValue = 0;
  TotalCess = 0;
  exportAsXLSX(): void {
    this.ExcelData = [];
    this.TotalCess = 0;
    for (let excel of this.receiptData) {
      let data = new LedgerSearchStockDTO();
      data.date = this.datePipe.transform(excel.voucherDate, 'dd-MM-yyyy');
      data.particulars = excel.detail;
      data.partyName = excel.customername;
      data.debit = excel.debit;
      data.credit = excel.credit;
      data.balance = excel.txtTotalBalance;
      this.ExcelData.push(data);
    }
    this.excelservice.exportExcelCashBankBook("CashBook", this.ExcelData, this.txtDebitOpeningBalance, this.txtCreditOpeningBalance, this.txtTotalDebit, this.txtTotalCredit, this.txtDebitClosingBalance, this.txtCreditClosingBalance);
  }
}
