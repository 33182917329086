<div class="body-container">
  <div class="content-holder">
    <app-navbar></app-navbar>
    <div class="content">
      <app-top-header></app-top-header>
      <div class="main-content">
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-4">
            <div class="portlet">
              <form class="needs-validation" novalidate="">
                <div class="portlet-header">Purchase Plan</div>
                <div class="portlet-content">
                  <div class="row g-3">
                    <div class="col-lg-4 col-xl-3 col-6">
                      <label for="country" class="form-label">Plan Name<span style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtPlanName" [(ngModel)]="txtPlanName" required=""
                        (change)="getAllAdditionalPlan()">
                        <option selected>-:Select A Plan:-</option>
                        <option *ngFor="let plan of allPlan" [ngValue]="plan">{{plan.planName}}</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid plan.
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 col-6">
                      <label for="country" class="form-label">Plan Duration<span style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtPlanDays" [(ngModel)]="txtPlanDays" required=""
                         (change)="getAmount()">
                        <option selected>-:Select A Plan Duration:-</option>
                        <option *ngFor="let days of allPriceList" [ngValue]="days">{{days.planDays}} Year - Rs.{{days.planPrice}}</option>
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid plan.
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 col-6">
                      <label for="country" class="form-label">Extra Location<span
                          style="color: red;">&nbsp;</span></label>
                      <select class="form-select" id="country" name="txtExtraLocation" [(ngModel)]="txtExtraLocation"
                        required="" (change)="changeExtraLocation()">
                        <option>-:Select A Location:-</option>
                        <option *ngFor="let loc of allLocationData" [ngValue]="loc">
                          {{loc.planLocationLimit}} Location - Rs.{{loc.amount}}</option>
                        <!-- <option>{{extraLocation}}- Rs.{{extraLocationAmount}}</option> -->
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid plan.
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 col-6">
                      <label for="country" class="form-label">Extra User<span style="color: red;">&nbsp;</span></label>
                      <select class="form-select" id="country" name="txtExtraUser" [(ngModel)]="txtExtraUser"
                        required="" (change)="changeExtraUser()">
                        <option selected>-:Select A User:-</option>
                        <option *ngFor="let usr of allUserData" [ngValue]="usr">{{usr.planUserLimit}} User -
                          Rs.{{usr.amount}}</option>
                        <!-- <option>{{extraUser}}- Rs.{{extraUserAmount}}</option> -->
                      </select>
                      <div class="invalid-feedback">
                        Please select a valid plan.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">GST Number</label>
                      <input type="text" class="form-control" id="Pincode" placeholder="Enter GST Number"
                        name="txtGstNumber" [(ngModel)]="txtGstNumber" required="required" (focusout)="getGSTNumber()" maxlength="15"/>
                      <div class="invalid-feedback">
                        Please enter GST Number.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Company Name<span style="color: red;"> *</span></label>
                      <input type="text" class="form-control" id="MailId" placeholder="Enter Company Name"
                        name="txtOrganizationName" [(ngModel)]="txtOrganizationName" required="required"
                        (focusout)="toUpperCompanyName()" />
                      <div class="invalid-feedback">
                        Please enter Email Id.
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 col-6">
                      <label for="NoOfUser" class="form-label">Owner Name<span
                          style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" id="last" placeholder="Enter Owner Name"
                        required="required" [(ngModel)]="txtOwnerName" name="txtOwnerName"
                        (focusout)="toUpperOwnerName()" />
                      <input type="hidden" class="form-control" [(ngModel)]="txtPurchasePlanId"
                        name="txtPurchasePlanId">
                      <div class="invalid-feedback">
                        Please enter number of user.
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-3 col-6">
                      <label for="country" class="form-label">Owner Mobile Number<span
                          style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" id="itemName" placeholder="Enter Owner Mobile Number"
                        required="required" [(ngModel)]="txtOwnerMobileNumber" name="txtOwnerMobileNumber"
                        maxlength="10" (keypress)="numberOnly($event)" />
                      <div class="invalid-feedback">
                        Please enter number of location.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="country" class="form-label">Contact Person<span
                          style="color: red;">&nbsp;</span></label>
                      <input type="text" class="form-control" id="itemName" placeholder="Enter Contact Person"
                        required="required" [(ngModel)]="txtContactPerson" name="txtContactPerson"
                        (focusout)="toUpperContactPerson()" />
                      <div class="invalid-feedback">
                        Please enter number of organization.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="NoOfUser" class="form-label">Contact Number<span
                          style="color: red;">&nbsp;</span></label>
                      <input type="text" class="form-control" id="last" placeholder="Enter Contact Person Mobile Number"
                        required="required" [(ngModel)]="txtContactPersonMobileNumber"
                        name="txtContactPersonMobileNumber" maxlength="10" (keypress)="numberOnly($event)" />
                      <div class="invalid-feedback">
                        Please enter number of user limit.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Address</label>
                      <!-- <input type="text" class="form-control" id="Address" placeholder="Enter Address" name="txtAddress"
                        [(ngModel)]="txtAddress" required="required" /> -->
                        <textarea class="form-control" rows="1" name="txtAddress"
                        [(ngModel)]="txtAddress"></textarea>
                      <div class="invalid-feedback">
                        Please enter Address.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Email<span></span></label>
                      <input type="text" class="form-control" id="MailId" placeholder="Enter Email" name="txtMailId"
                        [(ngModel)]="txtMailId" required="required" />
                      <div class="invalid-feedback">
                        Please enter Email Id.
                      </div>
                    </div>
                    <!-- <div class="col-lg-3 col-xl-1 col-6">
                      <label for="first" class="form-check-label"></label>
                      <br>
                      <div class="form-check form-check-inline">
                        <label><input class="form-check-input" type="radio" name="optradio" value="Cash" id="cashnote"
                            (change)="onItemChange($event.target.value)" checked>&nbsp;Cash</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <label><input class="form-check-input" type="radio" name="optradio" value="Bank"
                            (change)="onItemChange($event.target.value)">&nbsp;Bank</label>
                      </div>
                    </div>
                    <div *ngIf="isDisabled == false" class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Bank Name<span style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="txtBankName" name="txtBankName"
                        placeholder="Enter Bank Name">
                      <div class="invalid-feedback">
                        Please enter Bank Name.
                      </div>
                    </div>
                    <div *ngIf="isDisabled == false" class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Cheque No.<span style="color: red;">&nbsp;*</span></label>
                      <input type="text" class="form-control" [(ngModel)]="txtChequeNo" name="txtChequeNo">
                      <div class="invalid-feedback">
                        Please enter Cheque Number.
                      </div>
                    </div> -->
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">State<span
                        style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtState"
                          [(ngModel)]="txtState" required="" (focusout)="stateCode()">
                          <option>-:Select State:-</option>
                          <option *ngFor="let state of allState" [ngValue]="state">
                              {{state.stateName}}</option>
                      </select>
                      <div class="invalid-feedback">
                          Please enter State Name.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="first" class="form-label">State Code</label>
                      <input type="text" class="form-control" id="State"
                          placeholder="Enter State Code" name="txtStateCode" [(ngModel)]="txtStateCode"
                          required="required" disabled/>
                      <div class="invalid-feedback">
                          Please enter State Code.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="NoOfUser" class="form-label">Plan Amount<span
                          style="color: red;">&nbsp;</span></label>
                      <input type="text" class="form-control" id="last" placeholder="Enter Plan Amount"
                        required="required" [(ngModel)]="txtPlanAmount" name="txtPlanAmount"
                        (keypress)="numberOnly($event)" disabled />
                      <div class="invalid-feedback">
                        Please enter number of user limit.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-2 col-6">
                      <label for="NoOfUser" class="form-label">Final Amount<span
                          style="color: red;">&nbsp;</span></label>
                      <input type="text" class="form-control" id="last" placeholder="Enter Final Plan Amount"
                        required="required" [(ngModel)]="txtFinalAmount" name="txtFinalAmount"
                        (keypress)="numberOnly($event)" disabled />
                      <div class="invalid-feedback">
                        Please enter number of user limit.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Ledger View<span
                        style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtLedgerView"
                          [(ngModel)]="txtLedgerView" required="">
                          <option>-:Select Ledger:-</option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                      </select>
                      <div class="invalid-feedback">
                          Please enter Ledger.
                      </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-6">
                      <label for="first" class="form-label">Marketing View<span
                        style="color: red;">&nbsp;*</span></label>
                      <select class="form-select" id="country" name="txtMarketingView"
                          [(ngModel)]="txtMarketingView" required="">
                          <option>-:Select Marketing:-</option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                      </select>
                      <div class="invalid-feedback">
                          Please enter Marketing.
                      </div>
                    </div>
                    <!-- <div class="row g-3"> -->
                      <div class="col-lg-4 col-xl-3 col-md-10 col-sm-8 col-8">
                          <div class="form-group">
                              <!-- <label style="opacity:0;">Image</label> -->
                              <label for="formFile" class="form-label">Profile Picture</label>
                              <input class="form-control" type="file" id="formFile" name="txtImage"
                              [(ngModel)]="txtImage" (change)="onImageUpload($event)" accept="image/*">
                          </div>
                      </div>
                      <div class="col-lg-2 col-xl-1 col-md-2 col-sm-3 col-3" style="margin-top: 35px;">
                          <img [src]="imagePreview" height="50px" width="50px">
                      </div>
                    <!-- </div> -->
                    <div class="col-auto col-lg-1 btn-container-end">
                      <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                        Cancel
                      </button>
                      <button class="btn btn-primary" type="submit" (click)="createPurchasePlan()">
                        {{btnName}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-4">
            <div class="portlet">
              <div class="portlet-header">Purchase Plan Details</div>
              <div class="portlet-content">
                <div class="row d-flex justify-content-end">
                  <div class="col-md-6">
                    <div class="input-group search-box">
                      <input class="form-control border-0" placeholder="Search" name="search" [(ngModel)]="search"
                        id="example-search-input" />
                      <span class="input-group-append">
                        <button class="btn btn-outline-secondary border-0" type="button">
                          <i class="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="mt-3 table-responsive">
                  <table class="table table-hover table-striped table-bordered">
                    <thead class="tblheadercolor">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Owner</th>
                        <th scope="col">Owner Mobile</th>
                        <th scope="col">Compnay Name</th> 
                        <th scope="col">Plan Duration(Year)</th> 
                        <!-- <th scope="col">Extra User</th>
                        <th scope="col">Extra Location</th> -->
                        <!-- <th scope="col">Bank Name</th>
                        <th scope="col">Cheque No.</th> -->
                        <th scope="col">Final Amount</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let plan of allPurchasePlan | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                        <td class="text-success me-2" data-bs-toggle="modal" role="button" title="History"
                          (click)="getPurchaseDetail(plan)" data-bs-target="#exampleModal" aria-hidden="true">
                          {{plan.planPurchaseNumber}}</td>
                        <td>{{plan.plan.planName}}</td>
                        <td>{{plan.ownerName}}</td>
                        <td>{{plan.ownerMobileNumber}}</td>
                        <td>{{plan.organizationName}}</td>
                        <!-- <td>{{plan.planpriceId != null ? (plan.planpriceId.planDays +' Days - Rs.'+ plan.planpriceId.planPrice): ''}}</td> -->
                        <td class="text-center">{{plan.planpriceId != null ? plan.planpriceId.planDays: ''}}</td>
                        <!-- <td>{{plan.extraUser != null ? (plan.extraUser.planUserLimit +' User - Rs.'+ plan.extraUser.amount): ''}}</td>
                        <td>{{plan.extraLocation != null ? (plan.extraLocation.planLocationLimit +' User - Rs.'+ plan.extraLocation.amount) : ''}}</td> -->
                        <!-- <td>{{extraPlanUserLimit}} User - Rs.{{extraPlanUserAmount}}</td> -->
                        <!-- <td>{{extraPlanLocationLimit}} Location - Rs.{{extraPlanLocationAmount}}</td> -->
                        <!-- <td>{{plan.bankName}}</td>
                        <td>{{plan.chequeNo}}</td> -->
                        <td class="text-end">{{plan.finalAmount | appendIND}}</td>
                        <td>
                          <button type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Edit" (click)="updatePurchasePlan(plan)">
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                          <!-- <button type="button" class="btn btn-secondary btn-sm me-2" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Delete" (click)="deletePurchasePlan(plan)"
                            data-bs-toggle="modal" data-bs-target="#deleteModal">
                            <i class="fas fa-trash-alt"></i>
                          </button> -->
                          <!-- <button type="button" class="btn btn-primary btn-sm"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="History"
                              (click)="getPurchaseDetail(plan)" data-bs-toggle="modal"
                              data-bs-target="#exampleModal">
                              <i class="fas fa-history"></i>
                          </button> -->
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr *ngIf="allPurchasePlan < 1">
                        <td colspan="9" class="text-center"><b>No Record Found</b></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="mt-3 d-flex justify-content-end">
                  <nav aria-label="Table pagination">
                    <ul class="pagination">
                      <li>
                        <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)">
                        </pagination-controls>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure want to delete this record ?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Purchase Plan Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-lg-12 col-md-12">
            <div class="portlet">
              <div class="row mt-2 mb-1 ms-1">
                <div class="col-lg-4 col-sm-4 col-4">
                    <a>Plan Amount : <span class="text-right">{{planAmount | appendIND}}</span></a>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    <a>Extra Location Amount : <span>{{extraLocationAmountData | appendIND}}</span></a>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    <a>Extra User Amount : <span>{{extraUserAmountData | appendIND}}</span></a>
                </div>
                <!-- <div class="col-lg-6 col-sm-6 col-6">
                    <a>Amount : <span class="text-right">{{totalAmount}}</span></a><br/>
                    <a>GST : <span>{{gstAmount}}</span></a><br/>
                    <a>Discount : <span>{{discount}}</span></a><br/>
                    <a>Net Amount : <span>{{netAmount}}</span></a>
                </div> -->
              </div>
              <div class="mt-3 table-responsive">
                <table class="table table-hover table-striped table-bordered">
                  <thead class="tblheadercolor">
                    <tr>
                      <th scope="col">Location</th>
                      <th scope="col">User</th>
                      <th scope="col">Contact Person</th>
                      <th scope="col">Contact Person Mobile</th>
                      <th scope="col">Address</th>
                      <th scope="col">State</th>
                      <th scope="col">StateCode</th>
                      <th scope="col">Email</th>
                      <th scope="col">GST Number</th>
                      <th scope="col">Ledger View</th>
                      <th scope="col">Marketing View</th>
                      <th scope="col">Profile Picture</th>
                      <!-- <th scope="col">Company Name</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">{{totalLocation}}</td>
                      <td class="text-center">{{totalUser}}</td>
                      <td>{{contactPerson}}</td>
                      <td>{{contactPersonMobile}}</td>
                      <td>{{address}}</td>
                      <td>{{state}}</td>
                      <td>{{statecode}}</td>
                      <td>{{email}}</td>
                      <td>{{gstNumber}}</td>
                      <td>{{txtLedgerDisplay}}</td>
                      <td>{{txtMarketinfDisplay}}</td>
                      <td class="text-center"><img [src]="txtImageDisplay" height="50px" width="50px">
                      <!-- <td>{{organizationName}}</td> -->
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr *ngIf="allPurchasePlan < 1 ">
                      <td colspan="12" class="text-center"><b>No Record Found</b></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>