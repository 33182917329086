import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TermConditionService {

  constructor(
    private http: Http,
  ) { }

  createTermsCondition(txtTermsCondition: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      termconditionName : txtTermsCondition,
    });
    return this.http.post('/api/create/termcondition', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateTermsCondition(txtTermsConditionId: string, txtTermsCondition: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      termconditionName : txtTermsCondition,
      termconditionId : txtTermsConditionId,
    });
    return this.http.put('/api/update/termcondition', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllTermsCondition(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/termcondition', { headers })
      .pipe(map(res => res.json()));

  }

  deleteTermsCondition(txtTermsConditionId:string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      termconditionId : txtTermsConditionId,
    });
    return this.http.put('/api/delete/termcondition', body, { headers })
      .pipe(map(res => res.json()));  
  }
}
