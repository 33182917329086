<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Receipt Payment Details
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col">Date</th> -->
                                            <th scope="col">From Party</th>
                                            <th scope="col">To Party</th>
                                            <th scope="col">From GST</th>
                                            <th scope="col">To GST</th>
                                            <th scope="col">Amount (Rs.)</th>
                                            <th scope="col">Type</th>
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let srch of reSearchData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <!-- <td>{{srch.ledgerDate | date : 'dd-MM-yyyy'}}</td> -->
                                            <td>{{srch.fromParty}}</td>
                                            <td>{{srch.toParty}}</td>
                                            <td>{{srch.fromGst}}</td>
                                            <td>{{srch.toGst}}</td>
                                            <td class="text-end">{{srch.balance | appendIND}}</td>
                                            <td>{{srch.type}}</td>
                                            <td>
                                                <a type="button" class="btn btn-success btn-sm"
                                                    data-bs-placement="top" title="Accept" (click)="getAccept(srch);"
                                                    data-bs-toggle="modal" style="margin-left: 5px; margin-bottom: 5px;"
                                                    data-bs-target="#acceptModal">
                                                    <i class="fas fa-check-circle"></i>
                                                </a>
                                                <a type="button" class="btn btn-danger btn-sm"
                                                    data-bs-placement="top" title="Reject" (click)="getReject(srch);"
                                                    data-bs-toggle="modal" style="margin-left: 5px; margin-bottom: 5px;"
                                                    data-bs-target="#rejectModal">
                                                    <i class="fas fa-times-circle"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!-- <tfoot>
                                        <tr>
                                            <td></td>
                                            <td class="text-end"><b>Current Total</b></td>
                                            <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                        </tr>
                                    </tfoot> -->
                                    <tfoot>
                                        <tr *ngIf="reSearchData < 1 || reSearchData == null">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event"
                                                (pageBoundsCorrection)="page=($event)" responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="acceptModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="acceptModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Accept Payment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="clearPaymentData()" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-3 col-xl-2 col-3">
                        <label for="first" class="form-check-label"></label>
                        <!-- <br> -->
                        <div class="form-check form-check-inline">
                            <label><input class="form-check-input" type="radio" name="txtRadio" [(ngModel)]="txtRadio" value="Cash" id="cashnote"
                                    (change)="onItemChange($event.target.value)" checked>&nbsp;Cash</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label><input class="form-check-input" type="radio" [(ngModel)]="txtRadio" name="txtRadio" value="Bank"
                                    (change)="onItemChange($event.target.value)">&nbsp;Bank</label>
                        </div>
                    </div>
                    <div *ngIf="isDisabled == false" class="col-xl-5 col-lg-3 col-5">
                        <label for="first" class="form-label">Bank<span
                                style="color: red;">&nbsp;*</span></label>
                        <select class="form-select" id="country" name="txtselected"
                            [(ngModel)]="txtselected" required="">
                            <option>-:Select Bank:-</option>
                            <option *ngFor="let bank of allBank" [ngValue]="bank.bankid">
                                {{bank.bankName}}</option>
                        </select>
                        <div class="invalid-feedback">
                            Please enter Cash.
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmAccept()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearPaymentData()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="rejectModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Reject Payment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to reject this payment ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmReject()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>