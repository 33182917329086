import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { ExpenseLedgerService } from '../service/expense-ledger/expense-ledger.service';
import { SearchExpenseLedger } from '../service/expense-ledger/searcExpenseLedger';
import { SubCategoryService } from '../sub-category/sub-category.service';
declare var $: any;

@Component({
  selector: 'app-subcategory-report',
  templateUrl: './subcategory-report.component.html',
  styleUrls: ['./subcategory-report.component.css']
})
export class SubcategoryReportComponent implements OnInit {
  reSearchData;
  search;
  page = 1;
  txtTotalDebit = 0;
  datePipe = new DatePipe("en-US");
  txtFormDate;
  txtToDate;
  dropdownList = [];
  selectedRoles = [];
  dropdownSettings = {};

  constructor(
    private toastr: ToastrService,
    private expenseLedgerService: ExpenseLedgerService,
    private SubCategoryService: SubCategoryService,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.txtFormDate = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.txtToDate = new Date();
    this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
    // this.searchSubCategoryData();
    this.getAllSubCategory();
  }

  txtSubCategory;
  txtItem;
  allSubCategory;
  getAllSubCategory() {
    // this.SubCategoryService.getAllSubCategory().subscribe(res => {
    //   this.allSubCategory = res;
    // })
    this.SubCategoryService.getAllSubCategory().subscribe((data: any[]) => {
      this.dropdownList = data;
      this.dropdownSettings = {
        singleSelection: true,
        idField: 'subcategoryid',
        textField: 'subCategoryName',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true
      };
    }), (error => {
      this.toastr.error('Something went wrong');
      // console.log(error);
    });
  }

  allItemBySubCategory;
  getAllItemBySubCategory(txtSubCategory) {
    this.allItemBySubCategory = null;
    if(txtSubCategory == null || txtSubCategory == "" || txtSubCategory == undefined || txtSubCategory == "-:Select SubCategory:-"){
      this.toastr.error("Please select a subcategory");
    } else {
      this.SubCategoryService.getAllItemBySubCategory(txtSubCategory.subcategoryid).subscribe(res => {
        this.allItemBySubCategory = res;
      })
    }
  }
  searchSubCategoryData() {
    this.expenseLedgerService.searchSubCategoryData().subscribe(res => {
      this.reSearchData = res;
      this.txtTotalDebit = 0;
      for (let ii = 0; ii < this.reSearchData.length; ii++) {
        this.txtTotalDebit = this.txtTotalDebit + this.reSearchData[ii].debit;
      }
    }, error => {
      this.toastr.error("something went wrong");
    });
  }

  resBillPdfSubCategory;
  openPdfResponseSubCategory;
  getSubCategoryPDF(location){
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if(this.txtItem == null || this.txtItem == "" || this.txtItem == undefined || this.txtItem == "-:Select Item:-"){
      this.toastr.error("Please select a item");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, "yyyy-MM-dd");
      this.txtToDate = this.datePipe.transform(this.txtToDate, "yyyy-MM-dd");
      this.expenseLedgerService.downloadSubCategoryPDF(this.txtItem,this.txtFormDate,this.txtToDate,location).subscribe((data) => {
        this.resBillPdfSubCategory = data;

        var newBlob1 = new Blob([this.resBillPdfSubCategory], { type: "application/pdf" });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(newBlob1);
          return;
        }

        const data2 = window.URL.createObjectURL(newBlob1);
        var link2 = document.createElement('a');
        link2.href = data2;
        link2.download = "Sale_Bij_Bhandar.pdf";
        // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        window.open(link2.href, "", "width=800,height=600");
        this.openPdfResponseSubCategory.saveAs(link2.href);

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data2);
          link2.remove();
        }, 100);
        this.resBillPdfSubCategory = '';
        this.openPdfResponseSubCategory = '';
      })
    }
  }

}
