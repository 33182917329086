import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CropsIssueService {

  constructor(
    private http: Http,
  ) { }

  createCropsIssue(txtCrops: string, txtCropsIssueName: string, txtCropsIssueGuj: string, txtCropsIssueDes: string, txtCropsIssueDesGuj: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      cropsName: txtCrops,
      cropsIssueName: txtCropsIssueName,
      cropsIssueNameGuj: txtCropsIssueGuj,
      cropsIssueDescription: txtCropsIssueDes,
      cropsIssueDescriptionGuj: txtCropsIssueDesGuj,
    });
    return this.http.post('/api/app/create/cropsissue', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateCropsIssue(txtCropsIssueId: string, txtCrops: string, txtCropsIssueName: string, txtCropsIssueGuj: string, txtCropsIssueDes: string, txtCropsIssueDesGuj: string, txtImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: txtCropsIssueId,
      cropsName: txtCrops,
      cropsIssueName: txtCropsIssueName,
      cropsIssueNameGuj: txtCropsIssueGuj,
      cropsIssueDescription: txtCropsIssueDes,
      cropsIssueDescriptionGuj: txtCropsIssueDesGuj,
      image: txtImage
    });
    return this.http.put('/api/app/update/cropsissue', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllCropsIssue() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/cropsissue', { headers })
      .pipe(map(res => res.json()));
  }

  deleteCropsIssue(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    console.log("token",token);
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      id: deleteAppId,
    });
    return this.http.put('/api/app/delete/cropsissue', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/cropsissue/image', data, { headers: headers })
      .pipe(map(res => res));
  }

  getCropsIssueImages(masterid,type,mediatype) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/get/crops/otherdocument/frontend/' +masterid+'/'+type+'/'+mediatype, { headers })
      .pipe(map(res => res.json()));
  }
  
  deleteCropsKrishiGyaanImages(deleteCropsImagesId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.delete('/api/app/remove/cropsissue/image/' + deleteCropsImagesId, { headers })
      .pipe(map(res => res));
  }
}
