import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WhatsappMessageService } from '../service/whatsapp-message/whatsapp-message.service';
declare var $: any;

@Component({
  selector: 'app-whatsapp-message',
  templateUrl: './whatsapp-message.component.html',
  styleUrls: ['./whatsapp-message.component.css']
})
export class WhatsappMessageComponent implements OnInit {
  txtWhatsAppMsgId;
  txtWhatsAppMsg;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;

  btnName ='save';
  allWhatsAppMsg;
  resWhatsAppMsg;
  resWhatsAppMsgUp;
  resWhatsAppMsgDel;
  search;
  page: number = 1;
  isWhatsAppMsg = false;
  constructor(
    private toastr : ToastrService,
    private whatsappMessageService : WhatsappMessageService,) { }

    ngOnInit(): void {
      this.getAllWhatsAppMsg();
    }
  
    getAllWhatsAppMsg() {
      this.whatsappMessageService.getAllWhatsAppMsg().subscribe(res => {
        this.allWhatsAppMsg = res;
        if(res == null || res == [] || res.length == 0) {
          this.isWhatsAppMsg = false;
        } else {
          this.isWhatsAppMsg = true;
        }
      },error =>{
        this.isWhatsAppMsg = false;
      })
    }
  
    clearData() {
      this.btnName = 'Save';
      this.isWhatsAppMsg = true;
      this.txtWhatsAppMsg = null;
      this.txtImage = null;
      this.imagePreview = null;
      this.txtWhatsAppMsgId = null;
    }
  
    resImageWhatsAppId;
    createWhatsAppMsg() {
      if (this.txtWhatsAppMsg == null || this.txtWhatsAppMsg == '' || this.txtWhatsAppMsg == undefined || this.txtWhatsAppMsg.trim() =='') {
        this.toastr.error("WhatsApp Msg is required");
      } else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined || this.txtImage.trim() =='') {
        this.toastr.error("Image is required");
      } else {
        if (this.txtWhatsAppMsgId == null) {
          this.whatsappMessageService.createWhatsAppMsg(this.txtWhatsAppMsg, this.txtImage)
            .subscribe(res => {
              this.resWhatsAppMsg = res;
              this.resImageWhatsAppId = res.whatsappmsgId;
              this.uploadImage();
              this.clearData();
              this.getAllWhatsAppMsg();
              this.toastr.success('WhatsApp message save successfully');
            }, error => {
              // this.toastr.error('WhatsApp message not save');
              let message = JSON.parse(error._body).message;
              this.toastr.error(JSON.stringify(message));
  
            })
        } else {
          this.whatsappMessageService.updateWhatsAppMsg(this.txtWhatsAppMsgId, this.txtWhatsAppMsg, this.txtImage).subscribe(res => {
            this.resWhatsAppMsgUp = res;
            this.uploadImage();
            this.clearData();
            this.getAllWhatsAppMsg();
            this.toastr.success('WhatsApp message updated successfully');
          }, error => {
            // this.toastr.error('WhatsApp message not updated');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
        }
      }
    }
  
    updateWhatsAppMsg(whtsmsg) {
      this.btnName = 'Update';
      this.isWhatsAppMsg = false;
      this.txtWhatsAppMsgId = whtsmsg.whatsappmsgId;
      this.txtWhatsAppMsg = whtsmsg.whatsappMsg;
      this.txtImage = whtsmsg.image;
      this.imagePreview = whtsmsg.image;
    }
    
    deleteAppId;
    deleteWhatsAppMsg(whtsmsg) {
      this.deleteAppId = whtsmsg.whatsappmsgId;
    }
  
    confirmDelete() {
      if (this.deleteAppId != null) {
        this.whatsappMessageService.deleteWhatsAppMsg(this.deleteAppId)
          .subscribe(res => {
            this.resWhatsAppMsgDel = res;
            this.clearData();
            this.getAllWhatsAppMsg();
            $('#deleteModal').modal('hide');
            this.toastr.success('WhatsApp message deleted successfully');
          }, error => {
            // this.toastr.error('WhatsApp message not deleted');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      }
    }
  
    onImageUpload(event) {
      this.selectedImage = <File>event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  
    uploadImage() {
      if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
        this.imagePreview = "";
        this.getAllWhatsAppMsg();
        this.clearData();
        return
      } else {
        const data1 = new FormData();
        data1.append('file', this.selectedImage, this.selectedImage.name);
        data1.append('whatsapp', this.resImageWhatsAppId);
        this.whatsappMessageService.uploadImage(data1).subscribe(res => {
          this.resImage = res;
          this.getAllWhatsAppMsg();
          this.clearData();
          this.imagePreview = "";
        }, error => {
          this.imagePreview = "";
          this.clearData();
          this.getAllWhatsAppMsg();
        })
      }
    }
}
