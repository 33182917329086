import { Component, OnInit } from '@angular/core';
import { ReceiptVoucherService } from "../service/receipt_voucher/receipt-voucher.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { JvService } from '../service/jv/jv.service';
declare var $: any;

@Component({
  selector: 'app-jvrecord',
  templateUrl: './jvrecord.component.html',
  styleUrls: ['./jvrecord.component.css']
})
export class JvrecordComponent implements OnInit {
  allJV;
  allJVById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;

  constructor(
    private receiptvoucherservice: ReceiptVoucherService,
    private JvService: JvService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllJV();
  }

  MoveJV() {
    this.router.navigate(['jv']);
  }

  getAllJV() {
    this.JvService.getAllJV().subscribe(res => {
      this.allJV = res;
      console.log(this.allJV);
    })
  }

  JVDetail(jv) {
    this.getJVById(jv.voucherMasterId)
  }

  getJVById(txtId) {
    this.JvService.getAllJVById(txtId).subscribe(res => {
      this.allJVById = res;
      console.log(this.allJVById);
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allJVById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }

  deleteJVId;
  deleteJV(jv) {
    this.deleteJVId = jv.voucherMasterId;
  }

  resJVData;
  confirmDelete() {
    if (this.deleteJVId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteJVId).subscribe(res => {
        this.resJVData = res;
        console.log(this.resJVData);
        this.getAllJV();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('JV Data Delete Successfully');
      }, error => {
        this.toastr.error('JV Data Not Delete');
      })
    }
  }

}
