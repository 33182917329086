import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
// import { Observable } from 'rxjs';
// import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetsVoucherService {

  constructor(
    private http: Http,
    // private httpClient: HttpClient
  ) { }

  createAssetsVoucher(txtVoucherDate: string, paymentData: Array<any>,txtNarration:string,companyId:Number,txtchequeNo: number){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      voucherDate: txtVoucherDate,
      paymentVoucherDetailDto: paymentData,
      narration: txtNarration,
      companyId: companyId,
      chequeNo: txtchequeNo,
    });
    return this.http.post('/api/create/assetsvoucher', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllAssets(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/assetsvoucher', { headers })
      .pipe(map(res => res.json()));
  }

  //get All Assets By Id
  getAllAssetsById(txtId: number) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/assetsvoucher/' + txtId, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllAssLibLedger(groupid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/ledger/assets/' + groupid , { headers })
      .pipe(map(res => res.json()));
  }

  getAssetsSearch(groupid){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/assetsdata/' + groupid , { headers })
      .pipe(map(res => res.json()));
  }

}
