<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Category Master</h2> -->

                <!-- <div class="row">
                    <div id="map"></div>
                    <div id="legend">
                        <h3>Legend</h3>
                    </div>
                </div> -->
                <!-- <div id="map"></div>
                <div id="legend">
                    <h3>Legend</h3>
                </div> -->

                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs justify-content-center" role="tablist">
                            <li class="nav-item" role="presentation" *ngFor="let company of companyName; let i = index;">
                              <button class="nav-link" [ngClass]="selectedwallet== i ? 'active' : ''" (click)="selectedChange(company,i)" aria-controls="one" 
                              role="tab" data-toggle="tab">{{company.companyName}}</button>
                            </li>
                        </ul>
                          
                        <div class="tab-content">
                        <div role="tabpanel" class="tab-pane fade show active" id="one">
                            <div class="portlet h-100">
                                <div class="portlet-content dashboard-card">
                                    <div class="row dashboard-content justify-content-center">
                                        <div class="col-10">
                                            <div class="dashboard-label">{{companyReportName}} Daily Report</div>
                                            <div class="mt-3 table-responsive">
                                                <table class="table table-hover table-striped table-bordered">
                                                    <thead class="tblheadercolor">
                                                        <tr>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Cash Sale</th>
                                                            <th scope="col">Credit Sale</th>
                                                            <th scope="col">Collection</th>
                                                            <th scope="col">Expense</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let BDRstock of allBhandarDailyReport">
                                                            <td>{{BDRstock.location}}</td>
                                                            <td class="text-end"
                                                                (click)="getBhandarDailySummaryData(BDRstock,'CASH')"
                                                                data-bs-toggle="modal" data-bs-target="#cashSaleModal">
                                                                {{BDRstock.cashSale | appendIND}}</td>
                                                            <td class="text-end"
                                                                (click)="getBhandarDailySummaryData(BDRstock,'CREDIT')"
                                                                data-bs-toggle="modal" data-bs-target="#creditSaleModal">
                                                                {{BDRstock.creditSale | appendIND}}</td>
                                                            <td class="text-end" (click)="getCollectionBhandarData(BDRstock)" data-bs-toggle="modal"
                                                                data-bs-target="#collectionModal">{{BDRstock.collection |
                                                                appendIND}}</td>
                                                            <td class="text-end" (click)="getExpenseBhandarData(BDRstock)" data-bs-toggle="modal"
                                                                data-bs-target="#expenseModal">{{BDRstock.expense |
                                                                appendIND}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- static Report Data Table -->
                <!-- <div class="row">
                    <div class="col-md-6">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label">Nigam Daily Report</div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">CashSale</th>
                                                        <th scope="col">CreditSale</th>
                                                        <th scope="col">Collection</th>
                                                        <th scope="col">Expense</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let NDRstock of allNigamDailyReport">
                                                        <td>{{NDRstock.location}}</td>
                                                        <td class="text-end"
                                                            (click)="getNigamDailySummaryData(NDRstock,'CASH')"
                                                            data-bs-toggle="modal" data-bs-target="#cashSaleModal">
                                                            {{NDRstock.cashSale | appendIND}}</td>
                                                        <td class="text-end"
                                                            (click)="getNigamDailySummaryData(NDRstock,'CREDIT')"
                                                            data-bs-toggle="modal" data-bs-target="#creditSaleModal">
                                                            {{NDRstock.creditSale | appendIND}}</td>
                                                        <td class="text-end" (click)="getCollectionData(NDRstock)" data-bs-toggle="modal"
                                                            data-bs-target="#collectionModal">{{NDRstock.collection |
                                                            appendIND}}</td>
                                                        <td class="text-end" (click)="getExpenseData(NDRstock)" data-bs-toggle="modal"
                                                            data-bs-target="#expenseModal">{{NDRstock.expense |
                                                            appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label">Bhandar Daily Report</div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered">
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">CashSale</th>
                                                        <th scope="col">CreditSale</th>
                                                        <th scope="col">Collection</th>
                                                        <th scope="col">Expense</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let BDRstock of allBhandarDailyReport">
                                                        <td>{{BDRstock.location}}</td>
                                                        <td class="text-end"
                                                            (click)="getBhandarDailySummaryData(BDRstock,'CASH')"
                                                            data-bs-toggle="modal" data-bs-target="#cashSaleModal">
                                                            {{BDRstock.cashSale | appendIND}}</td>
                                                        <td class="text-end"
                                                            (click)="getBhandarDailySummaryData(BDRstock,'CREDIT')"
                                                            data-bs-toggle="modal" data-bs-target="#creditSaleModal">
                                                            {{BDRstock.creditSale | appendIND}}</td>
                                                        <td class="text-end" (click)="getCollectionBhandarData(BDRstock)" data-bs-toggle="modal"
                                                            data-bs-target="#collectionModal">{{BDRstock.collection |
                                                            appendIND}}</td>
                                                        <td class="text-end" (click)="getExpenseBhandarData(BDRstock)" data-bs-toggle="modal"
                                                            data-bs-target="#expenseModal">{{BDRstock.expense |
                                                            appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="height: 200px; padding: 0.5rem;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label d-flex">Total&nbsp;Purchase 
                                            <!-- <b style="color: #000;">{{currentFinancialYear}}</b> -->
                                            <select class="dashboard-select form-select" name="txtPurchaseFinancialYear"
                                                [(ngModel)]="txtPurchaseFinancialYear" (change)="getAllPurchaseReportCompanyWise()" required="">
                                                <option>-:Select Year:-</option>
                                                <option value="2021-2022">2021-2022</option>
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                <option value="2026-2027">2026-2027</option>
                                                <option value="2027-2028">2027-2028</option>
                                                <option value="2028-2029">2028-2029</option>
                                                <option value="2029-2030">2029-2030</option>
                                                <option value="2030-2031">2030-2031</option>
                                                <option value="2031-2032">2031-2032</option>
                                            </select>
                                        </div>
                                        <div class="title" *ngFor="let p of allPurchaseData">
                                            <span style="font-size: 13px;">{{p.companyname}}-</span>
                                            <!-- <div class="progress">
                                              <div role="progressbar" style="width: 25%; height: 4px" aria-valuenow="25" aria-valuemin="0"
                                                aria-valuemax="100" class="progress-bar bg-violet"></div>
                                            </div> -->
                                            <div class="number"><strong>{{p.netamount | number : '1.2-2'}}</strong></div>
                                        </div>
                                        <div *ngIf="allPurchaseData < 1" style="text-align: center;">
                                            <h6>No data found in {{txtPurchaseFinancialYear}} year</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="height: 200px; padding: 0.5rem;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label d-flex">Total&nbsp;Sale 
                                            <!-- <b style="color: #000;">{{currentFinancialYear}}</b> -->
                                            <select class="dashboard-select form-select" name="txtSaleFinancialYear"
                                                [(ngModel)]="txtSaleFinancialYear" (change)="getAllSaleReportCompanyWise()" required="">
                                                <option>-:Select Year:-</option>
                                                <option value="2021-2022">2021-2022</option>
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                <option value="2026-2027">2026-2027</option>
                                                <option value="2027-2028">2027-2028</option>
                                                <option value="2028-2029">2028-2029</option>
                                                <option value="2029-2030">2029-2030</option>
                                                <option value="2030-2031">2030-2031</option>
                                                <option value="2031-2032">2031-2032</option>
                                            </select>
                                        </div>
                                        <div class="title" *ngFor="let s of allSaleData">
                                            <span style="font-size: 13px;">{{s.companyname}}-</span>
                                            <div class="number"><strong>{{s.netamount | number : '1.2-2'}}</strong></div>
                                        </div>
                                        <div *ngIf="allSaleData < 1" style="text-align: center;">
                                            <h6>No data found in {{txtSaleFinancialYear}} year</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="height: 200px; padding: 0.5rem;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <!-- <div class="dashboard-label d-flex">Total&nbsp;Credit  -->
                                        <div class="dashboard-label d-flex">You Will Pay 
                                            <!-- <b style="color: #000;">{{currentFinancialYear}}</b> -->
                                            <select class="dashboard-select form-select" name="txtPaymentCreditFinancialYear"
                                                [(ngModel)]="txtPaymentCreditFinancialYear" (change)="getAllPaymentCreditReportCompanyWise()" required="">
                                                <option>-:Select Year:-</option>
                                                <option value="2021-2022">2021-2022</option>
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                <option value="2026-2027">2026-2027</option>
                                                <option value="2027-2028">2027-2028</option>
                                                <option value="2028-2029">2028-2029</option>
                                                <option value="2029-2030">2029-2030</option>
                                                <option value="2030-2031">2030-2031</option>
                                                <option value="2031-2032">2031-2032</option>
                                            </select>
                                        </div>
                                        <div class="title" *ngFor="let pc of allPaymentCreditData">
                                            <span style="font-size: 13px;">{{pc.companyname}}-</span>
                                            <div class="number"><strong>{{pc.netamount | number : '1.2-2'}}</strong></div>
                                        </div>
                                        <div *ngIf="allPaymentCreditData < 1" style="text-align: center;">
                                            <h6>No data found in {{txtPaymentCreditFinancialYear}} year</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="height: 200px; padding: 0.5rem;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <!-- <div class="dashboard-label d-flex">Total&nbsp;Debit  -->
                                        <div class="dashboard-label d-flex">You Will Get 
                                            <!-- <b style="color: #000;">{{currentFinancialYear}}</b> -->
                                            <select class="dashboard-select form-select" name="txtReceiptDebitFinancialYear"
                                                [(ngModel)]="txtReceiptDebitFinancialYear" (change)="getAllReceiptDebitReportCompanyWise()" required="">
                                                <option>-:Select Year:-</option>
                                                <option value="2021-2022">2021-2022</option>
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                <option value="2026-2027">2026-2027</option>
                                                <option value="2027-2028">2027-2028</option>
                                                <option value="2028-2029">2028-2029</option>
                                                <option value="2029-2030">2029-2030</option>
                                                <option value="2030-2031">2030-2031</option>
                                                <option value="2031-2032">2031-2032</option>
                                            </select>
                                        </div>
                                        <div class="title" *ngFor="let rd of allReceiptDebitData">
                                            <span style="font-size: 13px;">{{rd.companyname}}-</span>
                                            <div class="number"><strong>{{rd.netamount | number : '1.2-2'}}</strong></div>
                                        </div>
                                        <div *ngIf="allReceiptDebitData < 1" style="text-align: center;">
                                            <h6>No data found in {{txtReceiptDebitFinancialYear}} year</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="height: 200px; padding: 0.5rem;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label d-flex">You Will Get 
                                            <select class="dashboard-select form-select" name="youwillGetFinancialyear"
                                                [(ngModel)]="youwillGetFinancialyear" (change)="getAllYouWillGetDetail()" required="">
                                                <option value="2021-2022">2021-2022</option>
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                <option value="2026-2027">2026-2027</option>
                                                <option value="2027-2028">2027-2028</option>
                                                <option value="2028-2029">2028-2029</option>
                                                <option value="2029-2030">2029-2030</option>
                                                <option value="2030-2031">2030-2031</option>
                                                <option value="2031-2032">2031-2032</option>
                                            </select>
                                        </div>
                                        <div class="title" *ngFor="let rd of allYouWillGet">
                                            <span style="font-size: 13px;">{{rd.companyname}}-</span>
                                            <div class="number"><strong>{{rd.credit | number : '1.2-2'}}</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="height: 200px; padding: 0.5rem;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label d-flex">You Will Pay 
                                            <select class="dashboard-select form-select" name="youwillPayFinancialyear"
                                                [(ngModel)]="youwillPayFinancialyear" (change)="getAllYouWillPayDetail()" required="">
                                                <option value="2021-2022">2021-2022</option>
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                <option value="2026-2027">2026-2027</option>
                                                <option value="2027-2028">2027-2028</option>
                                                <option value="2028-2029">2028-2029</option>
                                                <option value="2029-2030">2029-2030</option>
                                                <option value="2030-2031">2030-2031</option>
                                                <option value="2031-2032">2031-2032</option>
                                            </select>
                                        </div>
                                        <div class="title" *ngFor="let rd of allYouWillPay">
                                            <span style="font-size: 13px;">{{rd.companyname}}-</span>
                                            <div class="number"><strong>{{rd.debit | number : '1.2-2'}}</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="card mt-3 mb-3">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-6 col-6">
                                        <h6 class="header-title m-0">Purchase Status</h6>
                                    </div>
                                    <div class="col-md-6 col-6">
                                        <select class="form-select" id="year" name="txtPurchaseYear"
                                            [(ngModel)]="txtPurchaseYear" (change)="purchaseBarChart()" required="">
                                            <option>-:Select Year:-</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="barchart" *ngIf="purchaseBarChartEnable" style="width:100% ;height:370px"></div>
                                <div *ngIf="!purchaseBarChartEnable" style="width:100% ;height:370px;text-align: center;"><h4>No Data Found In {{txtPurchaseYear}} Year</h4></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mt-3 mb-3">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-4 col-12">
                                        <h6 class="header-title m-0">Sale Status</h6>
                                    </div>
                                    <div class="col-md-4 col-6">
                                        <select class="form-select" id="year" name="txtYear"
                                            [(ngModel)]="txtYear" (change)="getLocation()" required="">
                                            <option>-:Select Year:-</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 col-6">
                                        <select class="form-select" id="location" name="txtlocation"
                                            [(ngModel)]="txtlocation" (change)="getLocation()" required="">
                                            <option>All</option>
                                            <option *ngFor="let location of allLocation"
                                                [ngValue]="location.locationName">
                                                {{location.locationName}}</option>

                                        </select>
                                    </div>
                                </div>
                                <!-- <select class="form-control" [(ngModel)]="txtlocation" name="txtlocation" (focusout)="getSubCategoryByCategoryId()">
                                    <option>-:Select Category:-</option>
                                    <option *ngFor="let location of allLocation" [value]="location.locationName">{{location.locationName}}</option>
                                </select> -->
                            </div>
                            <div class="card-body">
                                <div id="topsalechart" *ngIf="topSaleItemName" style="width:100% ;height:370px"></div>
                                <div *ngIf="!topSaleItemName" style="width:100% ;height:370px;text-align: center;"><h4>No Data Found In {{txtYear}} Year</h4></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mt-3 mb-3">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-4 col-12">
                                        <h6 class="header-title m-0">CREDIT / CASH</h6>
                                    </div>
                                    <div class="col-md-4 col-6">
                                        <select class="form-select" id="year" name="txtCreditCashYear"
                                            [(ngModel)]="txtCreditCashYear" (change)="getSaleByType()" required="">
                                            <option>-:Select Year:-</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 col-6">
                                        <select class="form-select" id="txtSaleBytype" name="txtSaleBytype"
                                            [(ngModel)]="txtSaleBytype" (change)="getSaleByType()" required="">
                                            <option>All</option>
                                            <option *ngFor="let location of allLocation"
                                                [ngValue]="location.locationName">
                                                {{location.locationName}}</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="piechart" *ngIf="getCreditCashEnable" style="width:100% ;height:370px"></div>
                                <div *ngIf="!getCreditCashEnable" style="width:100% ;height:370px;text-align: center;"><h4>No Data Found In {{txtCreditCashYear}} Year</h4></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="card mt-3 mb-3">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="header-title m-0">Detail</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <label></label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="topaccount" style="width:100% ;height:370px"></div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-6">
                        <div class="portlet h-100">
                            <div class="portlet-content dashboard-card" style="overflow-x: auto; height: 470px;">
                                <div class="row dashboard-content">
                                    <div class="col-12">
                                        <div class="dashboard-label">Bank Book Detail</div>
                                        <div class="mt-3 table-responsive">
                                            <table class="table table-hover table-striped table-bordered" >
                                                <thead class="tblheadercolor">
                                                    <tr>
                                                        <th scope="col">Bank Name</th>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Opening Balance</th>
                                                        <th scope="col">Credit</th>
                                                        <th scope="col">Debit</th>
                                                        <th scope="col">Closing Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let bank of allBankBookData">
                                                        <td>{{bank.bankName}}</td>
                                                        <td>{{bank.companyName}}</td>
                                                        <td class="text-end">{{bank.openingBalance | appendIND}}</td>
                                                        <td class="text-end" >{{bank.credit | appendIND}}</td>
                                                        <td class="text-end" >{{bank.debit | appendIND}}</td>
                                                        <td class="text-end" >{{bank.closingBalance | appendIND}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="cashSaleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Cash Sale Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-lg-12 col-md-12">
                                <div class="portlet">
                                    <div class="mt-3 table-responsive">
                                        <table class="table table-hover table-striped table-bordered">
                                            <thead class="tblheadercolor">
                                                <tr>
                                                    <th scope="col">Party Name</th>
                                                    <th scope="col">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cashSaleData of allNigamDailySummaryData">
                                                    <td>{{cashSaleData.partyName}}</td>
                                                    <td>{{cashSaleData.totalamount}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="creditSaleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Credit Sale Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-lg-12 col-md-12">
                                <div class="portlet">
                                    <div class="mt-3 table-responsive">
                                        <table class="table table-hover table-striped table-bordered">
                                            <thead class="tblheadercolor">
                                                <tr>
                                                    <th scope="col">Party Name</th>
                                                    <th scope="col">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let creditSaleData of allNigamDailySummaryData">
                                                    <td>{{creditSaleData.partyName}}</td>
                                                    <td>{{creditSaleData.totalamount}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <!-- <tr *ngIf="allSaleDetail < 1 ">
                                                    <td colspan="6" class="text-center"><b>No Record Found</b></td>
                                                </tr> -->
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="collectionModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Collection Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-lg-12 col-md-12">
                                <div class="portlet">
                                    <div class="mt-3 table-responsive">
                                        <table class="table table-hover table-striped table-bordered">
                                            <thead class="tblheadercolor">
                                                <tr>
                                                    <th scope="col">Party Name</th>
                                                    <th scope="col">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let collectionData of allCollectionData">
                                                    <td>{{collectionData.partyName}}</td>
                                                    <td>{{collectionData.totalamount}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <!-- <tr *ngIf="allSaleDetail < 1 ">
                                                    <td colspan="6" class="text-center"><b>No Record Found</b></td>
                                                </tr> -->
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="expenseModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Expense Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-lg-12 col-md-12">
                                <div class="portlet">
                                    <div class="mt-3 table-responsive">
                                        <h5>Expense</h5>
                                        <table class="table table-hover table-striped table-bordered">
                                            <thead class="tblheadercolor">
                                                <tr>
                                                    <th scope="col">Party Name</th>
                                                    <th scope="col">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let expense of expenseSummary">
                                                    <td>{{expense.partyName}}</td>
                                                    <td>{{expense.totalamount}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <!-- <tr *ngIf="allSaleDetail < 1 ">
                                                    <td colspan="6" class="text-center"><b>No Record Found</b></td>
                                                </tr> -->
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div class="portlet">
                                    <div class="mt-3 table-responsive">
                                        <h5>Payment</h5>
                                        <table class="table table-hover table-striped table-bordered">
                                            <thead class="tblheadercolor">
                                                <tr>
                                                    <th scope="col">Party Name</th>
                                                    <th scope="col">Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let payment of paymentSummary">
                                                    <td>{{payment.partyName}}</td>
                                                    <td>{{payment.totalamount}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <!-- <tr *ngIf="allSaleDetail < 1 ">
                                                    <td colspan="6" class="text-center"><b>No Record Found</b></td>
                                                </tr> -->
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>