import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ItemsDetailService {

  constructor(
    private http: Http,
  ) { }

  createItemsDetail(txtitemName: string, txtitemCode: string, txtbrand: string, txtcategory: string, txtsubCategory: string, txtunit: string, txtgst: string,  txtHSNCode: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemName: txtitemName,
      itemCode: txtitemCode,
      brandName: txtbrand,
      categoryName: txtcategory,
      subCategoryName: txtsubCategory,
      unitName: txtunit,
      gstName: txtgst,
      hsnCode: txtHSNCode,
    });
    return this.http.post('/api/create/item', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateitem(txtitemNameid: string, txtitemName: string, txtitemCode: string, txtbrand: string, txtcategory: string, txtsubCategory: string, txtunit: string, txtgst: string,txtHSNCode: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemid: txtitemNameid,
      itemName: txtitemName,
      itemCode: txtitemCode,
      brandName: txtbrand,
      categoryName: txtcategory,
      subCategoryName: txtsubCategory,
      unitName: txtunit,
      gstName: txtgst,
      hsnCode: txtHSNCode,
    });
    return this.http.put('/api/update/item', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllItem() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');


    return this.http.get('/api/getall/item', { headers })
      .pipe(map(res => res.json()));

  }

  getItemById(id: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/item/'+ id, { headers })
      .pipe(map(res => res.json()));

  }

  deleteItem(txtitemNameid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemid: txtitemNameid,
    });
    return this.http.put('/api/delete/item', body, { headers })
      .pipe(map(res => res.json()));
  }

  getSaleDataByPartyAndItemId(itemid: string, partyid: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemId: itemid,
      partyId: partyid,
    });
    return this.http.put('/api/get/sale/return/user', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllSaleItemNameSaleId(itemname: string, saleId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      itemName: itemname,
      saleid: saleId,
    });
    return this.http.put('/api/get/stock/return/item', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getSaleDatesByBatchNo(batchNo: string, saleId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      batchNumber: batchNo,
      saleid: saleId,
    });
    return this.http.put('/api/get/stock/return/batch', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  importExcelItemINIT(resItem){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.post('/api/create/list/item', resItem, { headers })
      .pipe(map(res => res));
  }
}
