import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SaleReturnService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  addSaleReturnDetail(txtsaleReturnDate: string, txtsaleReturnCode: string, txtCellNUmber: string, txtpartyId: string, txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, saleReturn: Array<any>, txtNarration: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleReturnDate: txtsaleReturnDate,
      salereturnNumber: txtsaleReturnCode,
      cellNumber: txtCellNUmber,
      partyId: txtpartyId,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      saleReturnDetailDTOs: saleReturn,
      type: "CREDIT",
      narration: txtNarration
    });
    return this.http.post('/api/create/salereturn', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  addCashSaleReturnDetail(txtsaleReturnDate: string, txtsaleReturnCode: string, txtCellNUmber: string, txtgstAmount: number, txtamount: number, txtDiscount: number, txtnetAmount: number, saleReturn: Array<any>, txtNarration: string, txtpartyId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      saleReturnDate: txtsaleReturnDate,
      salereturnNumber: txtsaleReturnCode,
      cellNumber: txtCellNUmber,
      partyId: txtpartyId,
      gstAmount: txtgstAmount,
      amount: txtamount,
      discount: txtDiscount,
      netAmount: txtnetAmount,
      saleReturnDetailDTOs: saleReturn,
      type: "CASH",
      narration: txtNarration
    });
    return this.http.post('/api/create/salereturn', body, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getAllsaleReturn(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/getall/salereturn', { headers })
      .pipe(map(res => res.json()));
  }

  getAllsaleReturnByDate(startdate: string,enddate: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturn/date/'+startdate+'/'+enddate, { headers })
      .pipe(map(res => res.json()));
  }
  
  getSaleReturnNumber() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturnnumber', { headers })
      .pipe(map(res => res.json()));
  }
  
  getSaleItemPriceByItemIdAndSaleId(itemid: string, saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturn/price/item/'+ itemid +'/sale/'+ saleid, { headers })
      .pipe(map(res => res.json()));
  }

  getBatchNumberByPartyANdItem(partyid: string, itemid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/find/batchno/party/' + partyid + '/item/' + itemid , { headers })
      .pipe(map(res => res.json()));
  }

  getSaleNoByPartyItemAndBatchNo(partyid: string, itemid: string,batchno:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/saleno/party/' + partyid + '/item/' + itemid + '/batchnumber/' + batchno, { headers })
      .pipe(map(res => res.json()));
  }

  getQTYNRateBySaleNoAndItem(saleno: string,itemid:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/qtyrate/saleno/'+ saleno +'/item/' + itemid, { headers })
      .pipe(map(res => res.json()));
  }

  getQTYBySaleNoAndItem(salenumber: string,itemid:string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/salereturn/chk/stock/' + salenumber + '/' + itemid , { headers })
      .pipe(map(res => res.json()));
  }

  public downloadSaleReturnNigamBill(billnumber: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/return/nigam/bill/` + billnumber, { headers: header, responseType: 'blob' });
  }

  public downloadSaleReturnBhandarBill(billnumber: string, companyname: string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    // return this.httpClient.get(`/api/generate/server/return/bhandar/bill/` + billnumber + `/` + companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
    return this.httpClient.get(`/api/generate/return/bhandar/bill/` + billnumber + `/` + companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

  getCompanyOganizationPDFData(salereturnid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/return/company/organization/detail/' + salereturnid,{ headers })
      .pipe(map(res => res.json()));
  }

  // new sale return PDF by category
  getSaleReturnPDFCategoryNoOfInvoiceData(saleid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturn/pdf/category/noofinvoice/detail/' + saleid,{ headers })
      .pipe(map(res => res.text()));
  }
  
  public downloadSaleReturnPesticideBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/salereturn/pesticide/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadSaleReturnFertiliserBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/salereturn/fertiliser/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }
  public downloadSaleReturnSeedsBill(invoiceid:string,billnumber: string,companyname:string,NoOfCompany: number): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get(`/api/generate/server/salereturn/seeds/invoice/`+invoiceid+`/`+ billnumber +`/`+companyname +`/`+NoOfCompany, { headers: header, responseType: 'blob' });
  }

  sendReturnNigamEmail(saleReturnno: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/return/nigam/'+ saleReturnno +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  sendReturnBhandarEmail(saleReturnno: string,email: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/sendemail/return/bhandar/'+ saleReturnno +'/email/' + email,{ headers })
      .pipe(map(res => res));
  }

  deleteSaleReturn(saleReturnDeleteId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      salereturnid: saleReturnDeleteId,
    });
    return this.http.put('/api/delete/salereturn', body, { headers })
      .pipe(map(res => res.json()));
  }

  saleReturnMailWithSendGrid(emailsaleReturnno: string,email: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      salereturnnumber: emailsaleReturnno,
      email: email,
    });
    return this.http.post('/api/send/salereturn', body, { headers })
      .pipe(map(res => res));
  }

  importExcelSaleReturnINIT(resUnit){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/salereturnmaster', resUnit, { headers })
      .pipe(map(res => res));
  }

  importExcelSaleReturnDetailINIT(resUnit){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/salereturndetail', resUnit, { headers })
      .pipe(map(res => res));
  }

  importExceloppINIT(resUnit){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    // const body = JSON.stringify({
    //   unitName : resCategory,
    // });
    return this.http.post('/api/create/list/openingbalance', resUnit, { headers })
      .pipe(map(res => res));
  }
  
  whatsappSaleReturn(salereturnid:string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/get/salereturn/whatsup/' + salereturnid , { headers })
      .pipe(map(res => res.json()));
  }
}
