import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BannerDetailsService {

  constructor(
    private http: Http,
  ) { }

  createBanner(txtBannerName: string) {
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      name: txtBannerName,
      // image: txtImage,
      // productNameGuj: txtIconImage,
    });
    return this.http.post('/api/app/create/banner', body, { headers })
      .pipe(map(res => res.json()));
  }
  
  updateBanner(txtBannerId: string, txtBannerName: string, txtImage: string, txtIconImage: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      bannerimgId: txtBannerId,
      name: txtBannerName,
      image: txtImage,
      appimage: txtIconImage,
    });
    return this.http.put('/api/app/update/banner', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllBanner() {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    return this.http.get('/api/app/getall/banner', { headers })
      .pipe(map(res => res.json()));
  }

  deleteDataBanner(deleteAppId: string){
    const headers = new Headers
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      bannerimgId: deleteAppId,
    });
    return this.http.put('/api/app/delete/banner', body, { headers })
      .pipe(map(res => res.json()));
  }

  uploadImage(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/banner/image', data, { headers: headers })
      .pipe(map(res => res));
  }
  uploadIcon(data) {
    var headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    return this.http.post('/api/app/upload/appbanner/image', data, { headers: headers })
      .pipe(map(res => res));
  }
}
