<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>GSTR1</h2>
                <div class="row ">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-6 col-lg-2 col-md-6">
                                            <label for="country" class="form-label">Month <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" [(ngModel)]="selectMonth" name="selectMonth">
                                                <option value="0">JAN</option>
                                                <option value="1">FEB</option>
                                                <option value="2">MAR</option>
                                                <option value="3">APR</option>
                                                <option value="4">MAY</option>
                                                <option value="5">JUN</option>
                                                <option value="6">JUL</option>
                                                <option value="7">AUG</option>
                                                <option value="8">SEP</option>
                                                <option value="9">OCT</option>
                                                <option value="10">NOV</option>
                                                <option value="11">DEC</option>
                                                </select>
                                        </div>
                                        <div class="col-6 col-lg-2 col-md-6">
                                            <label for="country" class="form-label">Year <span style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" [(ngModel)]="selectYear" name="selectYear">
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                </select>
                                        </div>
                                        <div class="col-lg-3 col-xl-4 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtCompany"
                                                [(ngModel)]="txtCompany" required="" (change)="getGSTNumber(txtCompany)">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company">
                                                    {{company.companyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Type<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="type" name="txtType"
                                                [(ngModel)]="txtType" required="">
                                                <option>-:Select Type:-</option>
                                                <option value="GSTR1">GSTR1</option>
                                                <option value="GSTR3B">GSTR3B</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter type.
                                            </div>
                                        </div>

                                        <div class="col-6 col-lg-2 col-md-3 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="getSave()">Save
                                            </button>
                                        </div>
                                        <!-- <div class="col-6 col-lg-2 col-md-3 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="excelNigamSaleDetail()">
                                                GSTR1-NIGAM
                                            </button>
                                        </div>
                                        <div class="col-6 col-lg-2 col-md-3 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="excelBhandarSaleDetail()">
                                                GSTR1-BHANDAR
                                            </button>
                                        </div>
                                        <div class="col-6 col-lg-2 col-md-3 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="getGstr3BNigam()">
                                                GSTR3B-NIGAM
                                            </button>
                                        </div>
                                        <div class="col-6 col-lg-2 col-md-3 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="getGstr3BBhandar()">
                                                GSTR3B-BHANDAR
                                            </button>
                                        </div> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 mb-4" *ngIf="isEnable">
                    <div class="portlet">
                        <div class="portlet-header">Search Details
                            <span style="float: right;">Total : {{totalNetAmount | appendIND}}</span>
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                 <div class="col-md-6">
                                    <button class="btn btn-primary d-block" type="submit" (click)="exportAsXLSX()">Excel</button>
                                </div> 
                                <div class="col-md-6">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search" name="search" [(ngModel)]="search"
                                            id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Debit(Rs.)</th>
                                            <th scope="col">Credit(Rs.)</th>
                                            <th scope="col">Balance(Rs.)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let srch of receiptData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{srch.voucherDate | date : 'dd-MM-yyyy'}}</td>
                                            <td>{{srch.detail}}</td>
                                            <td class="text-end">{{srch.debit | appendIND}}</td>
                                            <td class="text-end">{{srch.credit | appendIND}}</td>
                                            <td class="text-end">{{srch.txtTotalBalance | appendIND}}</td>
                                            
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td><b>Total</b></td>
                                            <td class="text-end"><b>{{txtTotalDebit | appendIND}}</b></td>
                                            <td class="text-end"><b>{{txtTotalCredit | appendIND}}</b></td>
                                        </tr>
                                    </tfoot>
                                    <tfoot>
                                        <tr *ngIf="reSearchData.length == 0 ">
                                            <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event" (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>