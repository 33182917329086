import { Component, OnInit } from '@angular/core';
import { TransferService } from "../service/transfer/transfer.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
import { LocationService } from "../service/location/location.service";
import { ToastrService } from "ngx-toastr";
import { SearchLocation } from '../service/location/searchLocation';
import { DatePipe } from '@angular/common';
import { transfer } from '../service/transfer/transfer';
import { SaleService } from '../service/sale/sale.service';
import { stockDTO } from '../service/transfer/stockDTO';
declare var $: any;

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {
  itemId;
  txttransferDate;
  txttransferid;
  txttransferCode;
  txtlocation;
  transferNumber;
  batchwiseresponse;

  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity = 0;
  stockCheck;


  alltransfer;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchLocation = '';
  searchLocationName = [];

  transferData: Array<transfer> = [];
  transferIdLast: number = 0;
  datePipe = new DatePipe("en-US");

  stockQuantity: number = 0;
  public loading: boolean = false;

  constructor(
    private TransferService: TransferService,
    private itemsdetailservice: ItemsDetailService,
    private toastr: ToastrService,
    private LocationService: LocationService,
    private saleservice: SaleService
  ) { }

  ngOnInit(): void {
    this.txttransferDate = this.datePipe.transform(this.txttransferDate, "dd/MM/yyyy");
    this.txttransferDate = new Date();
    this.getAllItem();
    this.getAllWithoutCurrentLocation();
    // this.getTransferNumber();
  }
  
  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //transfer Number
  getTransferNumber() {
    this.TransferService.getTransferNumber().subscribe(res => {
      this.transferNumber = res;
      this.txttransferCode = this.transferNumber;
    })
  }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
    this.getItemById();
    // this.getBatchNumber(result);
  }
  templocation;
  selectedLocation(location) {
    this.templocation = location;

    this.searchLocation = location.locationName;
  }


  allLocation;
  getAllWithoutCurrentLocation() {
    this.LocationService.getAllWithoutCurrentLocation().subscribe(res => {
      this.allLocation = res;
      for (let item of this.allLocation) {
        var temp = new SearchLocation();
        temp.locationName = item.locationName;
        temp.id = item.id;
        this.searchLocationName.push(temp)
      }
      console.log(this.allLocation);
    })
  }

  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.allItem);

    })
  }

  clearGridData() {
    this.txtItemName = "";

    this.txtCategoryName = "";
    this.txtSubCategoryName = "";
    this.txtBrandName = "";
    this.txtquantity = 0;

    this.search1 = "";
    this.btnName = "Add";
    this.txttransferid = null;
    this.batchwiseresponse = null;
  }

  clearValue() {
    this.txttransferDate = new Date();
    // this.txttransferDate = this.datePipe.transform(this.txttransferDate, "dd/MM/yyyy");
    this.txttransferCode = '';
    this.txtlocation = '';
    this.searchLocation = '';
    this.transferData = [];
  }

  resItemById;
  getItemById() {
    this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
      this.resItemById = res;
      console.log(this.resItemById);
      this.txtCategoryName = this.resItemById.category.categoryName;
      this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
      this.txtBrandName = this.resItemById.brand.name;
    });
  }

  addTransferDetail() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      if (this.txttransferid == null) {
        var transferDetail = new transfer();
        if (this.transferData.length == 0) {
          transferDetail.id1 = this.transferIdLast;
        } else if (this.transferData.length != 0) {
          transferDetail.id1 = this.transferIdLast + 1;
        }
        this.transferIdLast = transferDetail.id1;
        transferDetail.itemName = this.search1; //this for selected itemName
        transferDetail.itemId = this.itemId;
        transferDetail.categoryName = this.txtCategoryName;
        transferDetail.subCategoryName = this.txtSubCategoryName;
        transferDetail.brandName = this.txtBrandName;
        transferDetail.quantity = this.txtquantity;
        if (this.batchwiseresponse == null) {
          this.getBatchNumber();
          transferDetail.stockDTOs = this.batchwiseresponse;
        } else {
          transferDetail.stockDTOs = this.batchwiseresponse;
        }
        this.stockQuantity = 0;
        for (let stockQuantityValue of this.batchwiseresponse) {
          this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
        }
        console.log("quantity",this.stockQuantity);
        if (this.stockQuantity > this.txtquantity) {
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is more than quantity');
        }else if(this.stockQuantity < this.txtquantity){
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is less than quantity');
        }
        this.transferData.push(transferDetail);
        console.log("transfer array", this.transferData);
        this.clearGridData();
      } else {
        for (let transfer of this.transferData) {
          if (transfer.id1 == this.txttransferid) {
            transfer.itemName = this.search1;
            transfer.itemId = this.itemId;
            transfer.categoryName = this.txtCategoryName;
            transfer.subCategoryName = this.txtSubCategoryName;
            transfer.brandName = this.txtBrandName;
            transfer.quantity = this.txtquantity;
            transfer.stockDTOs = this.batchwiseresponse;
            this.stockQuantity = 0;
            for (let stockQuantityValue of this.batchwiseresponse) {
              this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
            }
            console.log("quantity",this.stockQuantity);
            if (this.stockQuantity > this.txtquantity) {
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is more than quantity');
            }else if(this.stockQuantity < this.txtquantity){
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is less than quantity');
            }
            this.clearGridData();
          }
        }
      }
    }
    console.log(this.transferData);
  }

  transferDetailres;
  createTransferDetail() {
    if (this.txttransferDate == undefined || this.txttransferDate == null || this.txttransferDate == '') {
      return this.toastr.error('Transfer date must be required');
      // } else if (this.txttransferCode == undefined || this.txttransferCode == null || this.txttransferCode == '') {
      // return this.toastr.error(' transfer Code must be required');
    } else if (this.txtlocation == undefined || this.txtlocation == null || this.txtlocation == '') {
      return this.toastr.error('Location must be required');
    } else if (this.transferData.length == 0 || this.transferData == []) {
      return this.toastr.error('Transfer detail must be required');
    } else {
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      this.txtlocation = this.templocation;
      this.txttransferDate = this.datePipe.transform(this.txttransferDate, 'yyyy-MM-dd');
      this.TransferService.addTransferDetailSave(this.txttransferDate, this.txttransferCode, this.txtlocation.locationName, this.transferData)
        .subscribe(res => {
          this.transferDetailres = res;
          console.log(this.transferDetailres);
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
          }
          this.clearValue();
          this.getTransferNumber();
          this.toastr.success('Transfer detail save successfully');
        }, error => {
          // this.toastr.error('Transfer detail not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
        })
    }
  }

  getAlltransfer() {
    this.TransferService.getAlltransfer()
      .subscribe(res => {
        this.alltransfer = res;
      })
  }

  updateTransfer(transfer) {
    this.btnName = "Update";
    this.txttransferid = transfer.id1;
    this.txtItemName = transfer.itemName;
    this.itemId = transfer.itemId;
    this.search1 = transfer.itemName;
    this.txtCategoryName = transfer.categoryName;
    this.txtSubCategoryName = transfer.subCategoryName;
    this.txtBrandName = transfer.name;
    this.txtquantity = transfer.quantity;
  }

  i;
  deleteTransferdata;
  deleteTransfer(transfer) {
    this.deleteTransferdata = transfer;
  }

  confirmDelete() {
    for (let saleData of this.transferData) {
      if (saleData.id1 == this.deleteTransferdata.id1) {
        this.i = this.deleteTransferdata.saleId;
        let index = this.transferData.indexOf(this.deleteTransferdata);
        if (saleData.id1 !== -1) {
          this.transferData.splice(index, 1);
        }
      }
      $('#deleteModal').modal('hide');
      // $('.modal-backdrop').remove();
    }
  }

  addSaleDetailStockCheck() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      this.saleservice.addSaleDetailStockCheck(this.itemId, this.txtquantity)
        .subscribe(res => {
          this.stockCheck = res;
          console.log(this.stockCheck);
          // this.clearValue();
          this.toastr.success('Stock available');
          this.addTransferDetail();
        }, error => {
          this.toastr.error('Stock not available for item');

        })
    }
  }

  getBatchNumber() {
    this.saleservice.getStockByItemPerBatch(this.itemId, this.txtquantity)
      .subscribe(res => {
        this.batchwiseresponse = res;
        if (this.batchwiseresponse.length <= 0) {
          return this.toastr.error('Stock not found');
        }
        console.log(this.batchwiseresponse);
        for (let batchWiseStock of this.batchwiseresponse) {
          if (batchWiseStock.allotedquantity == null) {
            batchWiseStock.allotedquantity = 0
          }
        }
        console.log("batchWise Stock", this.batchwiseresponse);
        this.toastr.success('Stock available');
        // this.addSaleDetail();
      }, error => {
        this.toastr.error('Stock not available for item');
      })
  }
}
