import { Component, OnInit } from '@angular/core';
import { LocationService } from "../service/location/location.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  resLocation;
  resUpLocation;
  txtLocation;
  txtLocationId;
  allLocation;
  btnName = 'Save';
  search;
  page: number = 1;
  txtAddress;
  txtPESLICNumber;
  txtFERLICNumber;
  txtSEEDLICNumber;
  disabled = false;

  constructor(
    private router: Router,
    private LocationService: LocationService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllLocation();
  }

  getAllLocation() {
    this.LocationService.getAllLocation().subscribe(res => {
      this.allLocation = res;
    })
  }

  toUpperCaseLocation() {
    const temp = this.txtLocation;
    this.txtLocation = temp.toUpperCase();
    return this.txtLocation;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtLocation = '';
    this.txtLocationId = null;
    this.txtAddress = '';
    this.txtFERLICNumber = '';
    this.txtPESLICNumber = '';
    this.txtSEEDLICNumber = '';
    this.disabled = false;
  }

  createLocation() {
    if (this.txtLocation == null || this.txtLocation == '' || this.txtLocation == undefined) {
      this.toastr.error("Location is required");
    } else {
      if (this.txtLocationId == null) {
        this.LocationService.createLocation(this.txtLocation,this.txtAddress,this.txtPESLICNumber,this.txtFERLICNumber,this.txtSEEDLICNumber)
          .subscribe(res => {
            this.resLocation = res;
            this.clearData();
            this.getAllLocation();
            this.toastr.success('Location save successfully');
          }, error => {
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.LocationService.updateLocation(this.txtLocationId, this.txtLocation,this.txtAddress,this.txtPESLICNumber,this.txtFERLICNumber,this.txtSEEDLICNumber).subscribe(res => {
          this.resUpLocation = res;
          this.clearData();
          this.getAllLocation();
          this.toastr.success('Location updated successfully');
        }, error => {
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateLocation(location) {
    this.btnName = 'Update';
    this.disabled = true;
    this.txtLocationId = location.id;
    this.txtLocation = location.locationName;
    this.txtAddress = location.address;
    this.txtPESLICNumber = location.peslicNumber;
    this.txtFERLICNumber = location.ferlicNumber;
    this.txtSEEDLICNumber = location.seedlicNumber;
  }

  deleteid;
  deleteLocation(location) {
    this.deleteid = location.id;
  }

  confirmDelete() {
    if (this.deleteid != null) {
      this.LocationService.deleteLocation(this.deleteid).subscribe(res => {
        this.resUpLocation = res;
        this.clearData();
        this.getAllLocation();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
        this.toastr.success('Location deleted successfully');
      }, error => {
        this.toastr.error('Location not deleted');
      })
    }
  }
}
