<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Party Master</h2> -->

                <div class="row">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Party Master</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-3 col-12">
                                            <label for="first" class="form-label">GST Number</label>
                                            <input type="text" class="form-control" id="Pincode"
                                                placeholder="Enter GST Number" name="txtGstNumber"
                                                [(ngModel)]="txtGstNumber"  required="required" (focusout)="getGSTNumber();"/>
                                            <div class="invalid-feedback">
                                                Please enter GST Number.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-5 col-12">
                                            <label for="first" class="form-label">Party Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtPartyId"
                                                name="txtPartyId">
                                            <input type="text" class="form-control" id="Party"
                                                placeholder="Enter Party Name" name="txtParty" [(ngModel)]="txtParty"
                                                required="required" (focusout)="toUpperParty();" />
                                            <div class="invalid-feedback">
                                                Please enter Party Name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Party Code<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="Partycode"
                                                placeholder="Enter Party Code" name="txtPartyCode"
                                                [(ngModel)]="txtPartyCode" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Party Code.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-4 col-12">
                                            <label for="first" class="form-label">Address</label>
                                            <textarea class="form-control" placeholder="Enter Address" rows="1" name="txtAddress"
                                            [(ngModel)]="txtAddress"></textarea>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-12">
                                            <label for="first" class="form-label">State</label><span
                                            style="color: red;">&nbsp;*</span>
                                            <select class="form-select" id="country" name="txtState"
                                                [(ngModel)]="txtState" required="" (focusout)="stateCode()">
                                                <option>-:Select State:-</option>
                                                <option *ngFor="let state of allState" [ngValue]="state">
                                                    {{state.stateName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter State Name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">State Code</label><span
                                            style="color: red;">&nbsp;*</span>
                                            <input type="text" class="form-control" id="State"
                                                placeholder="Enter State Code" name="txtStateCode" [(ngModel)]="txtStateCode"
                                                required="required" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter State Code.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">City</label>
                                            <input type="text" class="form-control" id="City"
                                                placeholder="Enter City Name" name="txtCity" [(ngModel)]="txtCity"
                                                required="required" />
                                            <div class="invalid-feedback">
                                                Please enter City Name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Pincode</label>
                                            <input type="text" class="form-control" id="Pincode"
                                                placeholder="Enter Pincode" name="txtPincode" [(ngModel)]="txtPincode"
                                                maxlength="6" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Pincode.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-12">
                                            <label for="first" class="form-label">Mobile Number<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="CellNumber"
                                                placeholder="Enter Mobile Number" name="txtCellNumber"
                                                [(ngModel)]="txtCellNumber" maxlength="10" required="required"
                                                (keypress)="numberOnly($event)" />
                                            <div class="invalid-feedback">
                                                Please enter Mobile Number.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-12">
                                            <label for="first" class="form-label">Email<span></span></label>
                                            <input type="text" class="form-control" id="MailId"
                                                placeholder="Enter Email Id" name="txtMailId" [(ngModel)]="txtMailId"
                                                required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Email Id.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Type</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <select class="form-select" id="country" name="txtType"
                                                [(ngModel)]="txtType" required="" (focusout)="selectLedger();">
                                                <option>-:Select Type:-</option>
                                                <option *ngFor="let type of allType" [ngValue]="type.typeName">
                                                    {{type.typeName}}</option>
                                                <!-- <option>COMPANY</option>
                                                <option>FARMER</option> -->
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter Mobile Number.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Group</label>
                                            <select class="form-select" id="country" name="txtGroupName"
                                                [(ngModel)]="txtGroupName" required="">
                                                <option>-:Select Group:-</option>
                                                <option *ngFor="let group of allGroup" [ngValue]="group.groupName">
                                                    {{group.groupName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter Group Name.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="first" class="form-label">Cheque No</label>
                                            <input type="text" class="form-control" id="cheqNo"
                                                placeholder="Enter Cheque No" name="txtChequeNo"
                                                [(ngModel)]="txtChequeNo"  required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Cheque No.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Bank Name</label>
                                            <input type="text" class="form-control" id="bankName"
                                                placeholder="Enter Bank Name" name="txtbankName"
                                                [(ngModel)]="txtbankName"  required="required" />
                                            <div class="invalid-feedback">
                                                Please enter Bank Name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Ledger</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <select class="form-select" id="country" name="txtLedger"
                                                [(ngModel)]="txtLedger" required="" [disabled]="ledgerDisabled">
                                                <option>-:Select Ledger:-</option>
                                                <option *ngFor="let ledger of allLedger" [ngValue]="ledger.ledgerId">
                                                    {{ledger.ledgerName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter ledger Name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Opening Balance</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <input type="text" class="form-control" id="Pincode"
                                                placeholder="Enter GST Number" name="txtopeningbalance"
                                                [(ngModel)]="txtopeningbalance" required="required" />
                                            <div class="invalid-feedback">
                                                Please enter GST Number.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-2 col-6 btn-container-end">
                                            <button class="btn btn-primary" type="button" data-bs-toggle="modal"
                                            data-bs-target="#CompanyModal" [disabled]="disabledOpeningBalance">
                                                Opening&nbsp;Balance
                                            </button>
                                        </div>
                                        <div class="col-md-1 btn-container-end">
                                            <button class="btn btn-secondary me-2" type="button"
                                                (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createParty()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <div class="portlet-header">Party</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-8 d-flex">
                                        <div class="row">
                                        <div class="col-12 col-xl-6 bottom_margin">
                                        <input type="file" name="txtExcelParty" [(ngModel)]="txtExcelParty" 
                                         (change)="onExcelUploadParty($event)" accept=".xls,.xlsx"/>
                                        </div>
                                        <div class="col-5 col-xl-3">
                                        <button class="btn btn-primary d-block me-2 bottom_margin" type="submit"
                                            (click)="exportExcelParty()">Export&nbsp;To&nbsp;Excel</button>
                                        </div>
                                        <div class="col-5 col-xl-3">
                                        <button class="btn btn-primary d-block bottom_margin" type="submit"
                                            (click)="importandConvertExcelParty()">Import&nbsp;Excel</button>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" placeholder="Search" name="search"
                                                [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Party Name</th>
                                                <!-- <th scope="col">Address</th>
                                                <th scope="col">City</th>
                                                <th scope="col">State</th>
                                                <th scope="col">PinCode</th> -->
                                                <th scope="col">Mobile Number</th>
                                                <!-- <th scope="col">Email</th> -->
                                                <th scope="col">GST Number</th>
                                                <th scope="col">Type</th>
                                                <!-- <th scope="col">Group</th> -->
                                                <th scope="col">Cheque No</th>
                                                <th scope="col">Bank Name</th>
                                                <th scope="col">Opening Balance</th>
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let party of allParty | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{party.partyName}}</td>
                                                <!-- <td>{{party.address}}</td>
                                                <td>{{party.city}}</td>
                                                <td>{{party.state}}</td>
                                                <td>{{party.pincode}}</td> -->
                                                <td>{{party.cellNumber}}</td>
                                                <!-- <td>{{party.mailId}}</td> -->
                                                <td>{{party.gstNumber}}</td>
                                                <td>{{party.type}}</td>
                                                <!-- <td>{{party.group!= null ? party.group.groupName : ''}}</td> -->
                                                <td>{{party.cheqNo}}</td>
                                                <td>{{party.bankName}}</td>
                                                <td class="text-end">{{party.openingBalance}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px; margin-bottom: 5px;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="History"
                                                        data-bs-toggle="modal" (click) = "getPartyDetail(party)" data-bs-target="#HistoryModal">
                                                        <i class="fas fa-history"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px; margin-bottom: 5px;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="OpeningBalance"
                                                        data-bs-toggle="modal" (click) = "getOpeningBalance(party)" data-bs-target="#CompanyOpeningModal">
                                                        <i class="fas fa-rupee-sign"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px; margin-bottom: 5px;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Edit"
                                                        (click)="updateParty(party)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <button *ngIf="party.type != 'COMPANY' && party.type != 'FARMER'" type="button" class="btn btn-primary btn-sm"
                                                        style="margin-right:5px; margin-bottom: 5px;" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Ledger"
                                                        (click)="AddPartyLedger(party)" data-bs-toggle="modal"
                                                        data-bs-target="#ledgerModal">
                                                        <i class="fa fa-clipboard"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        style="margin-right:5px; margin-bottom: 5px;" data-bs-toggle="tooltip" 
                                                        data-bs-placement="top" title="Delete"
                                                        (click)="deleteParty(party)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allParty < 1">
                                                <td colspan="13" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>

                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event" responsive="true"
                                                    (pageBoundsCorrection)="page=($event)"></pagination-controls>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Are you sure want to delete this record ?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="CompanyModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">OpeningBalance Detail</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-md-12">
                            <div class="portlet">
                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">companyId</th>
                                                <th scope="col">company Name</th>
                                                <th scope="col">Opening Balance<span style="color: red;">&nbsp;</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let company of allCompany">
                                                <td>{{company.companyId}}</td>
                                                <td>{{company.companyName}}</td>
                                                <td>
                                                    <div class="col">                                                           
                                                        <input type="text" class="form-control" id="last"
                                                            placeholder="Enter openingbalance" required="required"
                                                            [(ngModel)]="company.openingbalance" name="txtopeningbalance" (keypress)="numberandMinusOnly($event)"/>
                                                        <div class="invalid-feedback">
                                                            Please enter a openingbalance.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allCompany < 1 ">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" type="submit" (click)="getcompanyDetail()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="CompanyOpeningModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">OpeningBalance Detail</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-md-12">
                            <div class="portlet">
                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">companyId</th>
                                                <th scope="col">company Name</th>
                                                <th scope="col">Opening Balance<span style="color: red;">&nbsp;</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let company of resUpPartyOpning">
                                                <td>{{company.companyId}}</td>
                                                <td>{{company.companyName}}</td>
                                                <td>
                                                    <div class="col">                                                           
                                                        <input type="text" class="form-control" id="last"
                                                            placeholder="Enter openingbalance" required="required"
                                                            [(ngModel)]="company.amount" name="txtopeningbalance" (keypress)="numberandMinusOnly($event)"/>
                                                        <div class="invalid-feedback">
                                                            Please enter a openingbalance.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allCompany < 1 ">
                                                <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" type="submit" (click)="getcompanyOpeningDetail()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="HistoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Party Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="mt-3 table-responsive">
                            <table class="table table-hover table-striped table-bordered">
                                <thead class="tblheadercolor">
                                    <tr>
                                        <th scope="col">Email</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">State</th>
                                        <th scope="col">StateCode</th>
                                        <th scope="col">City</th>
                                        <th scope="col">PinCode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{Email}}</td>
                                        <td>{{Address}}</td>
                                        <td>{{State}}</td>
                                        <td>{{StateCode}}</td>
                                        <td>{{City}}</td>
                                        <td>{{PinCode}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr *ngIf="allParty < 1 ">
                                        <td colspan="5" class="text-center"><b>No Record Found</b></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button class="btn btn-primary" type="submit" (click)="getcompanyDetail()">Save</button> -->
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="ledgerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ledger Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"
                   (click)="ClearPartyLedger();" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Party Master</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-6 col-xl-6 col-12">
                                            <label for="first" class="form-label">Ledger</label><span
                                                style="color: red;">&nbsp;*</span>
                                            <select class="form-select" id="country" name="txtAssLibLedger"
                                                [(ngModel)]="txtAssLibLedger" required="" [disabled]="ledgerDisabled">
                                                <option>-:Select Ledger:-</option>
                                                <option *ngFor="let ledger of allLedger" [ngValue]="ledger.ledgerId">
                                                    {{ledger.ledgerName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter ledger Name.
                                            </div>
                                        </div>
                                        <div class="col-md-1 btn-container-end">
                                            <button class="btn btn-primary" type="submit" (click)="createPartyLedger()">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="portlet">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Ledger Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ledger of allPartyLedgerName">
                                            <td>{{ledger.partyledgerName}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="allPartyLedgerName < 1 ">
                                            <td colspan="3" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="ClearPartyLedger()">Cancel</button>
            </div>
        </div>
    </div>
</div>