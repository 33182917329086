import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(
    private http: Http,
  ) { }

  createType(txtType: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      typeName : txtType,
    });
    return this.http.post('/api/create/type', body, { headers })
      .pipe(map(res => res.json()));
  }
  updateType(txtTypeId: string, txtType: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      typeName : txtType,
      typeId : txtTypeId,
    });
    return this.http.put('/api/update/type', body, { headers })
      .pipe(map(res => res.json()));
  }
  getAllType(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/type', { headers })
      .pipe(map(res => res.json()));
  }
}
