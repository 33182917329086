import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CashBookService {

  constructor(
    private http: Http,
  ) { }

  getCashBookDetail(ledgerid: string, companuyorgid: string, startdate: string, enddate: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/cashbook/ledger/' + ledgerid + '/company/' + companuyorgid + '/date/' + startdate + '/' + enddate, { headers })
      .pipe(map(res => res.json()));
  }

  getCashOpeningBalanceByMonth(startdate: string, enddate: string, compenyid: string) {
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    return this.http.get('/api/get/cashbook/date/' + startdate + '/' + enddate + '/company/' + compenyid , { headers })
      .pipe(map(res => res.json()));
  }
}
