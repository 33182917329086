import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { DatePipe } from '@angular/common';
import { PartyService } from '../service/party/party.service';
import { SearchParty } from '../service/party/searchparty';
import { paymentVoucherDTO } from '../service/payment_voucher/paymentVoucherDTO';
import { CashToBankService } from '../service/cash-to-bank/cash-to-bank.service';
declare var $: any;

@Component({
  selector: 'app-cash-to-bank',
  templateUrl: './cash-to-bank.component.html',
  styleUrls: ['./cash-to-bank.component.css']
})
export class CashToBankComponent implements OnInit {
  txtpaymentid;
  txtVoucherDate;
  txtpandingAmount;
  txtnetAmount;
  txtcredit;
  txtcompany;
  // isDisabled: boolean = true;

  allreceiptvoucher;
  btnName = 'Add';
  allCashToBank;
  txtNarration;

  txtpartyName;
  public searchParty = '';
  searchPartyName = [];
  totalCredit = 0;
  allCompany;

  paymentData: Array<paymentVoucherDTO> = [];
  paymentIdLast: number = 0;
  datePipe = new DatePipe("en-US");

  txtselected;
  // optradio;
  txtRadio;
  txtChequeNo;

  constructor(
    private toastr: ToastrService,
    private partyService: PartyService,
    private cashtobankservice: CashToBankService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    // this.getAllParty();
    // this.getAllPartyAccount();
    // this.getAllBank();
    this.getAllCompany();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      // console.log("all Party", this.allCompany);
    })
  }

  allBank;
  getAllBank() {
    this.partyService.getAllBank(this.txtcompany.partyId).subscribe(res => {
      this.allBank = res;
      // console.log("all Bank", this.allBank);
    })
  }

  checkBank() {
    // if(this.txtRadio == "Bank"){
    this.getAllBank();
    // }
  }

  
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    this.searchParty = party.partyName + "-" + party.cellNumber;
  }
  allparty;
  getAllParty() {
    this.partyService.getAllParty().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber = item.cellNumber;
        this.searchPartyName.push(temp)
      }
      // console.log(this.allparty);
    })
  }

  allPartyCash;
  getAllPartyAccount() {
    this.partyService.getAllPartyAccount().subscribe(res => {
      this.allPartyCash = res;
    })
  }

  clearValue() {
    this.txtpartyName = "";
    this.txtcredit = "";
    this.searchParty = "";
    this.tempParty = "";
    this.txtNarration = "";
    this.btnName = "Add";
    this.txtpaymentid = null;
    this.txtrefId = null;
    this.txtparticulars = null;
    this.txtcompany = "";
    this.paymentData = null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txtChequeNo = '';
    this.txtselected = null;
  }

  addCashToBankVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error(' Voucher Date must be required');
    } else if (this.txtcompany == undefined || this.txtcompany == null || this.txtcompany == "" || this.txtcompany == "-:Select Company Name:-" ) {
      return this.toastr.error('Company must be required');
    }else if(this.txtselected == null || this.txtselected == "" || this.txtselected == undefined || this.txtselected == "-:Select Bank Name:-" ){
      return this.toastr.error('Bank must be required');
    }else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
      return this.toastr.error('Amount must be required');
    // }else if(this.paymentData.length <= 0){
    //   return this.toastr.error('Please add bank for voucher.');
    } else {
      if (this.txtpaymentid == null) {
        var paymentDetail = new paymentVoucherDTO();
        paymentDetail.id1 = this.paymentIdLast + 1;
        this.paymentIdLast = paymentDetail.id1;
        // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
        paymentDetail.partyId = this.txtcompany.partyId;
        paymentDetail.partyName = this.txtcompany.partyName;
        paymentDetail.debit = this.txtcredit;
        paymentDetail.amount = this.txtcredit;
        paymentDetail.ledgerId = 7;
        paymentDetail.bankId = this.txtselected.bankid;
        // paymentDetail.ledgerId = 6
        this.totalCredit = Number(this.totalCredit) + Number(paymentDetail.debit);
        this.paymentData.push(paymentDetail);
        console.log("Payment data",this.paymentData);
        // this.clearValue();
      }
    }
    this.createCashToBankVoucher();
  }

  getAmount() {
    this.totalCredit = 0;
    for (let item of this.paymentData) {
      var sum = 0;
      sum = sum + Number(item.debit);
      this.totalCredit = this.totalCredit + sum;
    }
  }

  txtparticulars;
  txtrefId;
  paymentVoucherres;
  createCashToBankVoucher() {
    for (let paymentDetail of this.paymentData) {
      paymentDetail.refId = this.txtcompany.partyId;
      paymentDetail.particulars = this.txtcompany.partyName;
      // paymentDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
      // receiptDetail.numbers = receiptDetail.numbers;
      paymentDetail.amount = paymentDetail.debit;
      paymentDetail.ledgerId = 7;
      if (this.txtrefId == null || this.txtrefId == "") {
        this.txtrefId = paymentDetail.partyId;
        this.txtparticulars = paymentDetail.partyName;
      } else {
        this.txtrefId = this.txtrefId + ',' + paymentDetail.partyId;
        this.txtparticulars = this.txtparticulars + ',' + paymentDetail.partyName;
      }
    }

    let paymentDetails = new paymentVoucherDTO();
    paymentDetails.partyId = this.txtcompany.partyId;
    paymentDetails.partyName = this.txtcompany.partyName;
    paymentDetails.refId = this.txtrefId;
    paymentDetails.particulars = this.txtparticulars;
    paymentDetails.voucherDate = this.txtVoucherDate;
    paymentDetails.ledgerId = 6;
    // if(this.txtRadio == "Bank"){
    // paymentDetails.ledgerId = 7;
    // paymentDetails.bankId = this.txtselected.bankid;
    // }  
    // paymentDetails.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.paymentData.push(paymentDetails);
    console.log("Save Before Array",this.paymentData )
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.cashtobankservice.createCashToBankVoucher(this.txtVoucherDate, this.paymentData, this.txtNarration, this.txtcompany.partyId, this.txtChequeNo)
      .subscribe(res => {
        this.paymentVoucherres = res;
        console.log(this.paymentVoucherres);
        this.clearValue();
        this.getAllBank();
        this.paymentData = [];
        this.totalCredit = 0;
        this.toastr.success('CashToBank voucher save successfully');
      }, error => {
        // this.toastr.error('CashToBank voucher not saved');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
  }

  getAllCashToBankVoucher() {
    this.cashtobankservice.getAllCashToBankVoucher().subscribe(res => {
      this.allCashToBank = res;
    })
  }

}