import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BannerDetailsService } from '../service/banner-details/banner-details.service';
// import { CategoryAppService } from '../service/category-app/category-app.service';
declare var $: any;

@Component({
  selector: 'app-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.css']
})
export class BannerDetailsComponent implements OnInit {
  txtBannerId;
  txtBannerName;
  allBanner;
  btnName = 'Add';
  txtIconImage;
  iconPreview;  
  selectedIcon;
  txtImage;
  imagePreview;
  selectedImage;
  resIcon;
  resImage;
  search;
  page: number = 1;
  resBannerUp;
  resBanner;
  resBannerDel;

  constructor(
    private bannerDetailsService: BannerDetailsService,
    // private categoryAppService: CategoryAppService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // this.getAllCategoryApp();
    this.getAllBanner();
  }

  getAllBanner() {
    this.bannerDetailsService.getAllBanner().subscribe(res => {
      this.allBanner = res;
    })
  }

  toUpperCaseBanner() {
    const temp = this.txtBannerName;
    this.txtBannerName = temp.toUpperCase();
    return this.txtBannerName;
  }

  clearData() {
    this.txtBannerId = null;
    this.txtBannerName = "";
    this.txtIconImage = "";
    this.txtImage = "";
    this.iconPreview = "";
    this.imagePreview = "";
    this.selectedIcon = "";
    this.selectedImage = "";
    this.btnName = 'Save';
  }

  createBanner() {
    if (this.txtBannerName == null || this.txtBannerName == '' || this.txtBannerName == undefined ) {
      this.toastr.error("Banner name is required");
    }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
      this.toastr.error("Banner image is required");
    // }else if (this.txtIconImage == null || this.txtIconImage == '' || this.txtIconImage == undefined) {
    //   this.toastr.error("app banner image is required");
    } else {
      if (this.txtBannerId == null) {
        this.bannerDetailsService.createBanner(this.txtBannerName)
          .subscribe(res => {
            this.resBanner = res;
            this.uploadImage();
            // this.uploadIcon();
            this.clearData();
            this.getAllBanner();
            this.toastr.success('Banner save successfully');
          }, error => {
            // this.toastr.error('banner not saved');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.bannerDetailsService.updateBanner(this.txtBannerId, this.txtBannerName, this.txtImage, this.txtIconImage).subscribe(res => {
          this.resBannerUp = res;
          this.uploadImage();
          // this.uploadIcon();
          this.clearData();
          this.getAllBanner();
          this.toastr.success('Banner updated successfully');
        }, error => {
          // this.toastr.error('banner not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateBanner(pdt) {
    this.btnName = 'Update';
    this.txtBannerId = pdt.bannerimgId;
    this.txtBannerName = pdt.name;
    this.txtImage = pdt.image;
    this.imagePreview = pdt.image;
    // this.txtIconImage = pdt.appimage;
    // this.iconPreview = pdt.appimage;
  }

  deleteAppId;
  deleteDataBanner(banner) {
    this.deleteAppId = banner.bannerimgId;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.bannerDetailsService.deleteDataBanner(this.deleteAppId)
        .subscribe(res => {
          this.resBannerDel = res;
          this.clearData();
          this.getAllBanner();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Banner deleted successfully');
        }, error => {
          this.toastr.error('Banner not deleted');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      // this.imagePreview = "";
      // this.getAllProductApp();
      // this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('name', this.txtBannerName);
      this.bannerDetailsService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllBanner();
        // this.clearData();
        this.imagePreview = "";
      }, error => {
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllProductApp();
      })
    }
  }

  onIconUpload(event) {
    this.selectedIcon = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.iconPreview = reader.result;
    };
    reader.readAsDataURL(this.selectedIcon);
  }

  uploadIcon() {
    if (this.selectedIcon == null || this.selectedIcon == '' || this.selectedIcon == undefined) {
      // if (this.selectedImage != null || this.selectedImage != '' || this.selectedImage != undefined) {
      //   // this.uploadImage();
      //   // this.getAllBanner();
      // }
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedIcon, this.selectedIcon.name);
      data1.append('name', this.txtBannerName);
      this.bannerDetailsService.uploadIcon(data1).subscribe(res => {
        this.resIcon = res;
        // this.uploadImage();
        this.getAllBanner();
        this.iconPreview = "";
      }, error => {
        // this.uploadImage();
        // this.iconPreview = "";
        // this.getAllBanner();
      })
    }
  }

}
