import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SaleService } from '../service/sale/sale.service';

@Component({
  selector: 'app-thanks-detail',
  templateUrl: './thanks-detail.component.html',
  styleUrls: ['./thanks-detail.component.css']
})
export class ThanksDetailComponent implements OnInit {

  isPdfCode = false;

  constructor(
    private router: Router,
    private saleService: SaleService,
  ) { }

  ngOnInit(): void {
    var href = this.router.url;
    if (this.router.url.includes('?')) {
      // console.log("if condition");
      var routtype = href.split("?");
      var routval = routtype[1];
      var id = routval.split("=");
      var ord = id[1].split("_")
      var company = ord;
      // var cmp = company[1].split("-").join(" ");
      // console.log(ord[1]);
      // console.log(company);
      // console.log(company[0]);
      // console.log(company[1].split("%20").join(" "));
      // console.log(company[2]);
      this.downloadFinalBill(company[0],company[1],company[2]);
    } else {
      // console.log("else condition");
    }
  }

  resFinalBillPdf;
  openPdfResponse;
  downloadFinalBill(saleNumber,companyname,numofcompany) {
    this.isPdfCode = true;
    this.saleService.whatsappThanks(saleNumber,companyname,numofcompany).subscribe((data) => {
      this.resFinalBillPdf = data;
      var newBlob1 = new Blob([this.resFinalBillPdf], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data11 = window.URL.createObjectURL(newBlob1);
      var link1 = document.createElement('a');
      link1.href = data11;
      link1.download = "FinalBill.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link1.href, "", "width=800,height=600");
      this.openPdfResponse.saveAs(link1.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data11);
        link1.remove();
      }, 100);
    })
  }
}
