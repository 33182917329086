<div class="overlay" [ngClass]="loading == true ?'active':''">
    <div class="loader"></div>
</div>
<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Sale Return Details</h2> -->
                <div class="row d-flex align-items-baseline">
                    <div class="col-lg-12 col-md-12">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">Sale Return</div>
                                <div class="portlet-content">
                                    <div class="row d-flex align-items-baseline g-3">
                                        <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="country" class="form-label">Sale Return Date<span style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtsaleReturnDate"
                                                name="txtsaleReturnDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <!-- <input type="text" class="form-control" id="PurchaseDate"
                                                placeholder="Enter purchase Date" required="required"
                                                [(ngModel)]="txtPurchaseDate" name="txtPurchaseDate" /> -->
                                            <div class="invalid-feedback">
                                                Please enter a saleReturn Date.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="first" class="form-label">Sale Return Code</label>
                                            <input type="hidden" class="form-control" [(ngModel)]="txtsaleReturnid"
                                                name="txtsaleReturnid">
                                            <input type="text" class="form-control" id="itemName"
                                                placeholder="Enter sale Return code" required="required"
                                                [(ngModel)]="txtsaleReturnCode" name="txtsaleReturnCode" disabled />
                                            <div class="invalid-feedback">
                                                Please enter sale Return code.
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Mobile Number<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" class="form-control" id="itemName"
                                                placeholder="Enter Mobile Number" required="required"
                                                [(ngModel)]="txtMobileNumber" name="txtMobileNumber" maxlength="10"
                                                (focusout)="getPartyByCellNumber()" (keypress)="numberOnly($event)"/>
                                            <div class="invalid-feedback">
                                                Please enter Mobile Number.
                                            </div>
                                        </div> -->
                                        <div class="col-lg-3 col-xl-5 col-8 position-relative">
                                            <label for="last" class="form-label">Party Name<span style="color: red;">&nbsp;*</span></label>
                                            <input type="text" [value]="searchParty" ngxTypeahead
                                                [taList]="searchPartyName" [taItemTpl]="myParty"
                                                (taSelected)="selectedParty($event)" id="PartyName"
                                                [(ngModel)]="txtpartyName" name="txtpartyName"
                                                placeholder="Enter party Name"
                                                class="ui-autocomplete-input form-control" autocomplete="off"
                                                autofocus="autofocus" />
                                            <ng-template #myParty let-item>
                                                <div class="item-name">
                                                    {{item.result.partyName}}
                                                </div>
                                            </ng-template>
                                            <!-- <input type="text" class="form-control" id="last"
                                                placeholder="Enter Party Name" required="required"
                                                [(ngModel)]="txtpartyName" name="txtpartyName" disabled /> -->
                                            <div class="invalid-feedback">
                                                Please enter party name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-3">
                                            <label for="country" class="form-label">Gst Amount</label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Enter Gst Amount" required="required"
                                                [(ngModel)]="txtgstAmount" name="txtgstAmount" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a gst amount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Amount</label>
                                            <input type="text" class="form-control" id="last" placeholder="Enter Amount"
                                                required="required" [(ngModel)]="txtamount" name="txtamount" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a amount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Discount</label>
                                            <input type="text" class="form-control" id="last" (focusout)="calculateNetAmount()"
                                                placeholder="Enter Discount" required="required"
                                                [(ngModel)]="txtDiscount" name="txtDiscount" />
                                            <div class="invalid-feedback">
                                                Please enter a Discount.
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <label for="country" class="form-label">Net Amount</label>
                                            <input type="text" class="form-control" id="last"
                                                placeholder="Enter Net Amount" required="required"
                                                [(ngModel)]="txtnetAmount" name="txtnetAmount" disabled/>
                                            <div class="invalid-feedback">
                                                Please enter a net Amount.
                                            </div> 
                                        </div>-->
                                    </div>
                                </div>
                                <hr>
                                <form class="needs-validation" novalidate="">
                                    <div class="portlet-content">
                                        <div class="row d-flex align-items-baseline g-3">
                                            <div class="col-lg-3 col-xl-3 col-6 position-relative">
                                                <label for="last" class="form-label">Item Name<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" [value]="search1" ngxTypeahead
                                                     [taList]="searchItemName" [taItemTpl]="myTemp" (taSelected)="selectedStatic($event)"
                                                    id="ItemName" [(ngModel)]="txtItemName" name="txtItemName"
                                                    placeholder="Enter Item Name"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp let-item>
                                                    <div class="item-name">
                                                        {{item.result.itemName}}
                                                    </div>
                                                </ng-template>

                                                <div class="invalid-feedback">
                                                    Please enter item name.
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-2 col-6">
                                                <label for="first" class="form-label">Batch Number<span style="color: red;">&nbsp;*</span></label>
                                                <select class="form-select" id="country" name="txtBatchNumber"
                                                    [(ngModel)]="txtBatchNumber" (focusout)="getSaleNoByPartyItemAndBatchNo()"
                                                    required="">
                                                    <option>-:Select Batch Number:-</option>
                                                    <option *ngFor="let item of resReturnBatchNos" [ngValue]="item.batchNumber">
                                                        {{item.batchNumber}}</option>
                                                </select>
                                                <div class="invalid-feedback">
                                                    Please select id.
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-xl-2 col-6 position-relative">
                                                <label for="first" class="form-label">Sale Number<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" [value]="searchSalenumber" ngxTypeahead
                                                    [taList]="resReturnSaleNos" [taItemTpl]="myTemp1" (taSelected)="selectedStaticSaleNumber($event)"
                                                    id="salenumber" [(ngModel)]="txtsaleid" name="txtsaleid"
                                                    placeholder="Enter Sale Number"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #myTemp1 let-item>
                                                    <div class="item-name">
                                                        {{item.result.salenumber}}
                                                    </div>
                                                </ng-template>
                                                <!-- <select class="form-select" id="country" name="txtsaleid"
                                                    [(ngModel)]="txtsaleid" (focusout)="getQTYNRateBySaleNoAndItem()"
                                                    required="">
                                                    <option></option>
                                                    <option *ngFor="let item of resReturnSaleNos" [ngValue]="item.salenumber">
                                                        {{item.salenumber}}</option>
                                                </select> -->
                                                <div class="invalid-feedback">
                                                    Please select sale number.
                                                </div>
                                            </div>

                                            <!-- <div class="col-2">
                                                <label for="country" class="form-label">Manufacture Date</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Manufacture Date" required="required"
                                                    [(ngModel)]="txtManufactureDate" name="txtManufactureDate" disabled/>
                                                <div class="invalid-feedback" >
                                                    Please enter Manufacture Date.
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <label for="country" class="form-label">Expiry Date</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Expiry Date" required="required"
                                                    [(ngModel)]="txtExpiryDate" name="txtExpiryDate" disabled/>
                                                <div class="invalid-feedback">
                                                    Please enter Expiry Date.
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-2 position-relative">
                                                <label for="last" class="form-label">Batch Number</label>
                                                <input type="text" [value]="searchBatch" ngxTypeahead
                                                    [taList]="searchBatchNumber" [taItemTpl]="batch"
                                                    (taSelected)="selectedBatch($event)" id="BatchNumber"
                                                    [(ngModel)]="txtBatchNumber" name="txtBatchNumber"
                                                    placeholder="Enter Batch Number"
                                                    class="ui-autocomplete-input form-control" autocomplete="off"
                                                    autofocus="autofocus" />
                                                <ng-template #batch let-item>
                                                    <div class="item-name">
                                                        {{item.batch.batchNumber}}
                                                    </div>
                                                </ng-template>
                                                <div class="invalid-feedback">
                                                    Please enter item name.
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-2">
                                                <label for="first" class="form-label">Category Name</label>
                                                <input type="text" class="form-control" id="itemName"
                                                    placeholder="Enter Category Name" required="required"
                                                    [(ngModel)]="txtCategoryName" name="txtCategoryName" disabled />
                                                <div class="invalid-feedback">
                                                    Please enter txtCategory Name.
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-2">
                                                <label for="country" class="form-label">SubCategory Name</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="SubCategory Name" required="required"
                                                    [(ngModel)]="txtSubCategoryName" name="txtSubCategoryName"
                                                    disabled />
                                                <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div> -->
                                            <div class="col-lg-3 col-xl-2 col-6">
                                                <label for="country" class="form-label">Brand Name</label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Enter Brand Name" required="required"
                                                    [(ngModel)]="txtBrandName" name="txtBrandName" disabled />
                                                <div class="invalid-feedback">
                                                    Please enter a SubCategory Name.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-1 col-6">
                                                <label for="country" class="form-label">Quantity<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Quantity" required="required"
                                                    [(ngModel)]="txtquantity" name="txtquantity" (keypress)="numberOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a quantity.
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-xl-1 col-6">
                                                <label for="country" class="form-label">Rate<span style="color: red;">&nbsp;*</span></label>
                                                <input type="text" class="form-control" id="last"
                                                    placeholder="Rate" required="required"
                                                    [(ngModel)]="txtrate" name="txtrate" (keypress)="numberandDotOnly($event)"/>
                                                <div class="invalid-feedback">
                                                    Please enter a rate.
                                                </div>
                                            </div>
                                            <!-- <div class="col-2">
                                                <label for="country" class="form-label">Manufacture Date</label>
                                                <input matInput [matDatepicker]="picker2" class="form-control"
                                                    [(ngModel)]="txtManufactureDate " name="txtManufactureDate">
                                                <mat-datepicker-toggle matSuffix [for]="picker2"
                                                    style="float: right;position: absolute;left: 1240px;bottom:25%;">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                                <div class="invalid-feedback">
                                                    Please enter a manufacture date.
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <label for="country" class="form-label">Expiry Date</label>
                                                <input matInput [matDatepicker]="picker1" class="form-control"
                                                    [(ngModel)]="txtExpiryDate " name="txtExpiryDate">
                                                <mat-datepicker-toggle matSuffix [for]="picker1"
                                                    style="float: right;position: absolute;left: 415px;bottom:11.5%;">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                                <div class="invalid-feedback">
                                                    Please enter a expiryDate.
                                                </div>
                                            </div> -->
                                            <div class="col-auto ms-auto btn-container-end">
                                                <div class="row">
                                                    <label *ngIf="qtyLabel">Quantity: {{availableSaleNoQty}}</label>
                                                    <button class="btn btn-primary d-block" type="submit"
                                                        (click)="addSaleReturnDetail()">
                                                        {{btnName}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="portlet">
                        <div class="portlet-header">Sale Return Details</div>
                        <div class="portlet-content">
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                  <thead class="tblheadercolor">
                                        <tr>
                                            <!-- <th scope="col">#</th> -->
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Sale Number</th>
                                            <th scope="col">Batch Number</th>
                                            <!-- <th scope="col">category Name</th>
                                            <th scope="col">SubCategory Name</th> -->
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Manufacture Date</th>
                                            <th scope="col">Expiry Date</th>
                                            <th scope="col">Brand Name</th>
                                            <th scope="col" style="min-width: 100px;">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let salereturn of saleReturnData">
                                            <!-- <th scope="row">1</th> -->
                                            <td>{{salereturn.itemName}}</td>
                                            <td>{{salereturn.saleid}}</td>
                                            <td>{{salereturn.batchNumber}}</td>
                                            <!-- <td>{{salereturn.categoryName}}</td>
                                            <td>{{salereturn.subCategoryName}}</td> -->
                                            <td>{{salereturn.quantity}}</td>
                                            <td class="text-end">{{salereturn.rate | appendIND}}</td>
                                            <td>{{salereturn.manufactureDate | date:'dd/MM/yyyy'}}</td>
                                            <td>{{salereturn.expiryDate | date:'dd/MM/yyyy'}}</td>
                                            <td>{{salereturn.brandName}}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                style="margin-right:5px ;" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                    (click)="updateSaleReturn(salereturn)">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                    (click)="deleteSaleReturn(salereturn)" data-bs-toggle="modal"
                                                    data-bs-target="#deleteModal">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="saleReturnData.length == 0 ">
                                            <td colspan="9" class="text-center"><b>No Record Found</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-xl-8 col-md-6 col-6">
                                        <div class="col-lg-4 col-xl-4 col-md-8 col-sm-4 col-12 margin_top">
                                            <label for="country" class="form-label">Narration</label>
                                            <textarea class="form-control" rows="2" name="txtNarration"
                                                [(ngModel)]="txtNarration"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-6">
                                        <div class="justify-content-end">
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">Amount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Enter Amount" required="required"
                                                        [(ngModel)]="txtamount" name="txtamount" disabled />
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-end">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">GST Amount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Enter Gst Amount" required="required"
                                                        [(ngModel)]="txtgstAmount" name="txtgstAmount" disabled />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">Discount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        (focusout)="calculateNetAmount()" placeholder="Discount"
                                                        required="required" [(ngModel)]="txtDiscount"
                                                        name="txtDiscount" (keypress)="numberandDotOnly($event)"/>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="inputPassword" class="col-md-5"
                                                    style="padding: 8px;">Net Amount:</label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control text-end" id="last"
                                                        placeholder="Net Amount" required="required"
                                                        [(ngModel)]="txtnetAmount" name="txtnetAmount" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="portlet-footer">
                    <!-- <div class="d-grid gap-2 d-md-flex justify-content-md-end"> -->
                    <div class="row">
                        <div class="col-auto ms-auto">
                            <button class="btn btn-secondary me-2" type="button" (click)="clearGridData();clearValue()">
                                Cancel
                            </button>
                            <button class="btn btn-primary" type="submit" (click)="createSaleReturnDetail()">
                                Save
                            </button>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure want to delete this record ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>