import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { CashService } from '../service/cash/cash.service';
import { CompanyService } from '../service/company/company.service';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.css']
})
export class CashComponent implements OnInit {
  txtcashid;
  txtCash = "Cash";
  txtCompany;
  txtopeningBalance;
  allCompany;
  allCash;
  resCash;
  btnName = 'Save';
  search;
  page: number = 1;

  constructor(
    private toastr: ToastrService,
    private cashservice: CashService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.getAllCompany();
    this.getAllCash();
  }

  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      console.log(this.allCompany);
      this.txtCompany = this.allCompany.companyId;
      this.txtCompany = this.allCompany.companyName;
    })
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCash = 'Cash';
    this.txtCompany = '';
    this.txtopeningBalance = '';
    this.txtcashid = null;
  }

  createCash() {
    if (this.txtCompany == null || this.txtCompany == '' || this.txtCompany == undefined || this.txtCompany == "-:Select Company Name:-") {
      this.toastr.error("company name is required");
    } else if (this.txtopeningBalance == null || this.txtopeningBalance == '' || this.txtopeningBalance == undefined) {
      this.toastr.error("opening balance is required");
    } else {
      this.cashservice.createCash(this.txtCash, this.txtCompany.companyId, this.txtCompany.companyName, this.txtopeningBalance)
        .subscribe(res => {
          this.resCash = res;
          console.log(this.resCash);
          this.clearData();
          this.getAllCash();
          this.toastr.success('Cash save successfully');
        }, error => {
          this.toastr.error('Cash not saved');
        })
    }
  }

  getAllCash() {
    this.cashservice.getAllCash().subscribe(res => {
      this.allCash = res;
      console.log(this.allCash);
    })
  }
}
