import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExpenseVoucherService } from '../service/expense_voucher/expense-voucher.service';
import { PaymentVoucherService } from "../service/payment_voucher/payment-voucher.service";
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
declare var $: any;

@Component({
  selector: 'app-expense-v-record',
  templateUrl: './expense-v-record.component.html',
  styleUrls: ['./expense-v-record.component.css']
})
export class ExpenseVRecordComponent implements OnInit {
  allExpense;
  allPaymentDetail;
  allPaymentById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;

  constructor(
    private expensevoucherservice: ExpenseVoucherService,
    private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllExpense();
  }

  MovePayment() {
    this.router.navigate(['expensevoucher']);
  }

  getAllExpense() {
    this.expensevoucherservice.getAllExpense().subscribe(res => {
      this.allExpense = res;
    })
  }

  paymentDetail(payment) {
    this.getReceiptVoucherById(payment.voucherMasterId)
    // this.txtDate = receipt.voucherDate;
  }

  getReceiptVoucherById(txtId) {
    this.receiptvoucherservice.getAllReceiptById(txtId).subscribe(res => {
      this.allPaymentById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let receiptData of this.allPaymentById) {
        this.txtTotalDebit = this.txtTotalDebit + receiptData.debit;
        this.txtTotalCredit = this.txtTotalCredit + receiptData.credit;
      }
    })
  }

  deleteExpenseId;
  deleteExpense(payment){
    this.deleteExpenseId = payment.voucherMasterId;
  }

  resExpenseData;
  confirmDelete() {
    if (this.deleteExpenseId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteExpenseId)
        .subscribe(res => {
          this.resExpenseData = res;
          this.getAllExpense();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Expense data deleted successfully');
        }, error => {
          this.toastr.error('Expense data not deleted');
        })
    }
  }
}
