import { Component, OnInit } from '@angular/core';
import { LocationService } from "../service/location/location.service";
import { ToastrService } from "ngx-toastr";
import { DetailAnalysisService } from "../service/detail-analysis/detail-analysis.service";
import { DatePipe } from '@angular/common';
import { ExcelService } from "../service/excel/excel.service";
import { searchdto } from "../service/excel/searchdto";

@Component({
  selector: 'app-detail-analysis',
  templateUrl: './detail-analysis.component.html',
  styleUrls: ['./detail-analysis.component.css']
})
export class DetailAnalysisComponent implements OnInit {

  txtFormDate;
  txtToDate;
  txtType;
  txtLocation;
  allLocation;
  reSearchData;
  resSelectedSubDetail;
  isEnable = false;
  datePipe = new DatePipe("en-US");
  totalNetAmount = 0;
  page = 1;
  search;

  ExalData: Array<searchdto> = [];

  constructor(
    private locationService: LocationService,
    private toastr: ToastrService,
    private detailAnalysisService: DetailAnalysisService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.txtFormDate = new Date();
    this.txtToDate = new Date();
    this.getAllLocation();
  }

  getAllLocation() {
    this.locationService.getAllLocation().subscribe(res => {
      this.allLocation = res;
    })
  }

  searchAnalysis() {
    if (this.txtFormDate == "" || this.txtFormDate == null || this.txtFormDate == undefined) {
      this.toastr.error("Form date is required");
    } else if (this.txtToDate == "" || this.txtToDate == null || this.txtToDate == undefined) {
      this.toastr.error("To date is required");
    } else if (this.txtType == "" || this.txtType == null || this.txtType == undefined || this.txtType == "-:Select Type:-") {
      this.toastr.error("Type is required");
    } else if (this.txtLocation == "" || this.txtLocation == null || this.txtLocation == undefined || this.txtLocation == "-:Select Location:-") {
      this.toastr.error("Location is required");
    } else {
      this.txtFormDate = this.datePipe.transform(this.txtFormDate, 'yyyy-MM-dd');
      this.txtToDate = this.datePipe.transform(this.txtToDate, 'yyyy-MM-dd');
      if (this.txtType == "Purchase") {
        this.detailAnalysisService.getPurchaseAnalysis(this.txtFormDate, this.txtToDate, this.txtLocation).subscribe(res => {
          this.reSearchData = res;
            this.isEnable = true;
          this.totalNetAmount = 0;
          for (let amount of this.reSearchData) {
              var sum = 0;
              sum = sum + Number(amount.netamount);
              this.totalNetAmount = this.totalNetAmount + sum;
          }
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else if (this.txtType == "Purchase Return") {
        this.detailAnalysisService.getPurchaseReturnAnalysis(this.txtFormDate, this.txtToDate, this.txtLocation).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          for (let amount of this.reSearchData) {
              var sum = 0;
              sum = sum + Number(amount.netamount);
              this.totalNetAmount = this.totalNetAmount + sum;
          }
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else if (this.txtType == "Sale") {
        this.detailAnalysisService.getSaleAnalysis(this.txtFormDate, this.txtToDate, this.txtLocation).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          for (let amount of this.reSearchData) {
              var sum = 0;
              sum = sum + Number(amount.netamount);
              this.totalNetAmount = this.totalNetAmount + sum;
          }
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else if (this.txtType == "Sale Return") {
        this.detailAnalysisService.getSaleReturnAnalysis(this.txtFormDate, this.txtToDate, this.txtLocation).subscribe(res => {
          this.reSearchData = res;
          this.isEnable = true;
          this.totalNetAmount = 0;
          for (let amount of this.reSearchData) {
              var sum = 0;
              sum = sum + Number(amount.netamount);
              this.totalNetAmount = this.totalNetAmount + sum;
          }
        }, error => {
          this.toastr.error("something went wrong");
        });
      }
    }
  }

  getSearchSubDetail(srch) {
    if (this.txtType == "Purchase") {
      this.detailAnalysisService.getPurchaseAnalysisDetail(srch.location, srch.id).subscribe(res => {
        this.resSelectedSubDetail = res;
        console.log(this.resSelectedSubDetail);
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (this.txtType == "Purchase Return") {
      this.detailAnalysisService.getPurchaseReturnAnalysisDetail(srch.location, srch.id).subscribe(res => {
        this.resSelectedSubDetail = res;
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (this.txtType == "Sale") {
      this.detailAnalysisService.getSaleAnalysisDetail(srch.location, srch.id).subscribe(res => {
        this.resSelectedSubDetail = res;
      }, error => {
        this.toastr.error("something went wrong");
      });
    } else if (this.txtType == "Sale Return") {
      this.detailAnalysisService.getSaleReturnAnalysisDetail(srch.location, srch.id).subscribe(res => {
        this.resSelectedSubDetail = res;
      }, error => {
        this.toastr.error("something went wrong");
      });
    }
  }

  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.reSearchData){
      let data = new searchdto();
      data.date=this.datePipe.transform(exal.date, 'dd-MM-yyyy');
      data.number= exal.number;
      data.partyname=exal.partyname;
      data.location=exal.location;
      data.netamount=exal.netamount;
      this.ExalData.push(data);
    }
    this.excelservice.exportAsExcelFile(this.ExalData, 'Search');
 }

}
