<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <h2>Sale Report</h2>
                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <!-- <div class="portlet-header">Purchase</div> -->
                                <div class="portlet-content">
                                    <div class="row d-flex g-3">
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">From <span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                                                class="form-control" [(ngModel)]="txtFormDate" name="txtFormDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker3" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a Form Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-2 col-6">
                                            <label for="country" class="form-label">To <span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input (click)="picker2.open()" matInput [matDatepicker]="picker2"
                                                class="form-control" [(ngModel)]="txtToDate" name="txtToDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2" style="display: none;">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                            <div class="invalid-feedback">
                                                Please enter a To Date.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-3 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtcompany"
                                                [(ngModel)]="txtcompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <!-- <option>All</option> -->
                                                <option *ngFor="let company of allCompany" [ngValue]="company.companyId">
                                                    {{company.companyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-1 col-xl-1 col-1 btn-container-end">
                                            <button class="btn btn-primary d-block" type="submit"
                                                (click)="searchSaleReport()">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mb-4">
                    <div class="portlet">
                        <div class="portlet-header">Search Details
                        </div>
                        <div class="portlet-content">
                            <div class="row d-flex justify-content-end">
                                <!-- <div class="col-md-6">
                                    <button class="btn btn-primary d-block bottom_margin" type="submit" (click)="exportAsXLSX()">Export To Excel</button>
                                </div> -->

                                <div class="col-md-8 d-flex">
                                    <div class="card-close">
                                        <div class="dropdown">
                                        <button type="button" id="closeCard1" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" class="dropdown-toggle" class="btn btn-primary btn-sm me-2 bottom_margin">Actions
                                            <!-- <i class="fas fa-ellipsis-v"></i> -->
                                        </button>
                                        <div aria-labelledby="closeCard1" style="min-width: 85px; padding: 5px;" class="dropdown-menu dropdown-menu-right has-shadow">
                                            <!-- <a href="#" class="dropdown-item remove">
                                            <i class="fas fa-times"></i>Close
                                            </a>
                                            <a href="#" class="dropdown-item edit">
                                            <i class="fas fa-cog"></i>Edit
                                            </a> -->
                                            <button class="btn btn-primary btn-sm me-2 bottom_margin" type="submit" title="Export To Excel"
                                            (click)="exportAsXLSX()">
                                            <i class="fa fa-file-excel" aria-hidden="true"></i></button>
                                            <button class="btn btn-primary btn-sm me-2 bottom_margin" type="submit" title="Download PDF"
                                                (click)="downloadPDF()"><i class="fa fa-file-pdf"></i>
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="input-group search-box">
                                        <input class="form-control border-0" type="search" placeholder="Search"
                                            name="search" [(ngModel)]="search" id="example-search-input" />
                                        <span class="input-group-append">
                                            <button class="btn btn-outline-secondary border-0" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 table-responsive">
                                <table class="table table-hover table-striped table-bordered">
                                    <thead class="tblheadercolor">
                                        <tr>
                                            <th scope="col">Sale Date</th>
                                            <th scope="col">Name </th>
                                            <th scope="col">GST Number</th>
                                            <th scope="col">Sale Number</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">0% Amt</th>
                                            
                                            <th scope="col">GST 5% Amt</th>
                                            <th scope="col">CGST 2.5%</th>
                                            <th scope="col">SGST 2.5%</th>
                                            <th scope="col">IGST 5%</th>

                                            <th scope="col">GST 12% Amt</th>
                                            <th scope="col">CGST 6%</th>
                                            <th scope="col">SGST 6%</th>
                                            <th scope="col">IGST 12%</th>

                                            <th scope="col">GST 18% Amt</th>
                                            <th scope="col">CGST 9%</th>
                                            <th scope="col">SGST 9%</th>
                                            <th scope="col">IGST 18%</th>

                                            <th scope="col">GST 28% Amt</th>
                                            <th scope="col">CGST 14%</th>
                                            <th scope="col">SGST 14%</th>
                                            <th scope="col">IGST 28%</th>

                                            <th scope="col">GST Amount</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Add. Charge</th>
                                            <th scope="col">Net Amount</th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sale of reSearchSaleData | filter:search | paginate: { itemsPerPage: 10, currentPage: page }">
                                            <td>{{sale.date | date : 'dd-MM-yyyy'}}</td>
                                            <td>{{sale.name }}</td>
                                            <td>{{sale.gstnumber}}</td>
                                            <td>{{sale.purchasenumber}}</td>
                                            <td class="text-end">{{sale.purchaseamount | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.exemptedNetAmount ? sale.exemptedNetAmount : 0) | number : '1.2-2'}}</td>

                                            <td class="text-end">{{(null != sale.fiveNetAmount ? sale.fiveNetAmount : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.fiveCgst ? sale.fiveCgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.fiveSgst ? sale.fiveSgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.fiveIgst ? sale.fiveIgst : 0) | number : '1.2-2'}}</td>
                                            
                                            <td class="text-end">{{(null != sale.twelveNetAmount ? sale.twelveNetAmount : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.twelveCgst ? sale.twelveCgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.twelveSgst ? sale.twelveSgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.twelveIgst ? sale.twelveIgst : 0) | number : '1.2-2'}}</td>
                                            
                                            <td class="text-end">{{(null != sale.eighteenNetAmount ? sale.eighteenNetAmount : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.eighteenCgst ? sale.eighteenCgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.eighteenSgst ? sale.eighteenSgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.eighteenIgst ? sale.eighteenIgst : 0) | number : '1.2-2'}}</td>
                                            
                                            <td class="text-end">{{(null != sale.tewntyeightNetAmount ? sale.tewntyeightNetAmount : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.tewntyeightCgst ? sale.tewntyeightCgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.tewntyeightSgst ? sale.tewntyeightSgst : 0) | number : '1.2-2'}}</td>
                                            <td class="text-end">{{(null != sale.tewntyeightIgst ? sale.tewntyeightIgst : 0) | number : '1.2-2'}}</td>
                                            
                                            <td class="text-end">{{sale.totalcgst + sale.totalsgst + sale.totaligst | number : '1.2-2'}}</td>
                                            <td class="text-end">{{sale.salediscount | number : '1.2-2'}}</td>
                                            <td class="text-end">{{sale.additionalCharge | number : '1.2-2'}}</td>
                                            <td class="text-end">{{sale.netamount | number : '1.2-2'}}</td>
                                            <!-- <td>{{enableNigam == false ? sale.credit - sale.gstamount : sale.credit | number : '1.2-2'}}</td>
                                            <td>{{enableNigam == false ? sale.gstamount : 0 | number : '1.2-2'}}</td>
                                            <td>{{sale.credit | number : '1.2-2'}}</td> -->
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="text-end"><b>{{totalSaleAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{totalExemptedNetAmount | number : '1.2-2'}}</b></td>
                                            
                                            <td class="text-end"><b>{{totalFiveNetAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{fiveCgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{fiveSgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{fiveIgstAmount | number : '1.2-2'}}</b></td>
                                            
                                            <td class="text-end"><b>{{totalTwelveNetAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{twelveCgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{twelveSgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{twelveIgstAmount | number : '1.2-2'}}</b></td>
                                            
                                            <td class="text-end"><b>{{totalEightgNetAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{eightCgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{eightSgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{eightIgstAmount | number : '1.2-2'}}</b></td>
                                            
                                            <td class="text-end"><b>{{totalTwentyEightNetAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{tewntyeightCgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{tewntyeightSgstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{tewntyeightIgstAmount | number : '1.2-2'}}</b></td>
                                            
                                            <td class="text-end"><b>{{totalGstAmount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{totalDiscount | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{totalAddCharge | number : '1.2-2'}}</b></td>
                                            <td class="text-end"><b>{{totalNetAmount | number : '1.2-2'}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="mt-3 d-flex justify-content-end">
                                <nav aria-label="Table pagination">
                                    <ul class="pagination">
                                        <li>
                                            <pagination-controls (pageChange)="page = $event"
                                                (pageBoundsCorrection)="page=($event)" responsive="true"></pagination-controls>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>