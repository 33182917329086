import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExpenseVoucherService } from '../service/expense_voucher/expense-voucher.service';
import { ReceiptVoucherService } from '../service/receipt_voucher/receipt-voucher.service';
declare var $: any;

@Component({
  selector: 'app-gstr-voucher-record',
  templateUrl: './gstr-voucher-record.component.html',
  styleUrls: ['./gstr-voucher-record.component.css']
})
export class GstrVoucherRecordComponent implements OnInit {
  allGstr;
  allGstrById;
  txtTotalCredit;
  txtTotalDebit;
  search;
  page: number = 1;

  constructor(
    private expensevoucherservice: ExpenseVoucherService,
    private receiptvoucherservice: ReceiptVoucherService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllGstr();
  }

  MoveGstr() {
    this.router.navigate(['gstrvoucher']);
  }

  getAllGstr() {
    this.expensevoucherservice.getAllGstr().subscribe(res => {
      this.allGstr = res;
    })
  }

  gstrDetail(gstr) {
    this.getGstrVoucherById(gstr.voucherMasterId);
  }

  getGstrVoucherById(txtId) {
    this.expensevoucherservice.getAllGstrVoucherById(txtId).subscribe(res => {
      this.allGstrById = res;
      this.txtTotalCredit = 0;
      this.txtTotalDebit = 0;
      for (let gstrData of this.allGstrById) {
        this.txtTotalDebit = this.txtTotalDebit + gstrData.debit;
        this.txtTotalCredit = this.txtTotalCredit + gstrData.credit;
      }
    })
  }

  deleteGstrId;
  deleteGstr(gstr) {
    this.deleteGstrId = gstr.voucherMasterId;
  }

  resGstrData;
  confirmDelete() {
    if (this.deleteGstrId != null) {
      this.receiptvoucherservice.deleteVoucherRecord(this.deleteGstrId)
        .subscribe(res => {
          this.resGstrData = res;
          this.getAllGstr();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Gstr data deleted successfully');
        }, error => {
          this.toastr.error('Gstr data not deleted');
        })
    }
  }

}
