import { Component, OnInit } from '@angular/core';
import { SaleService } from "../service/sale/sale.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
//import { Purchase } from "../service/purchase/purchase";
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
import { sale } from '../service/sale/sale';
import { CompanyService } from '../service/company/company.service';
declare var $: any;

@Component({
  selector: 'app-cash-sale',
  templateUrl: './cash-sale.component.html',
  styleUrls: ['./cash-sale.component.css']
})
export class CashSaleComponent implements OnInit {
  itemId;
  txtsaleDate;
  saleNumber;
  txtsaleid;
  txtsaleCode;
  txtpartyName;
  txtpartyId;
  txtgstAmount = 0;
  txtamount = 0;
  txtDiscount:number =0;
  txtAdd:number = 0;
  txtnetAmount = 0;
  txtMobileNumber;
  stockCheck;
  batchwiseresponse;

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBrandName;
  txtquantity;
  txtrate;
  txtExpiryDate;

  // txtItemName;
  allsale;
  getParty;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];

  saleData: Array<sale> = [];
  saleIdLast: number = 0;
  txtCGST: number;
  txtSGST: number;
  txtTotalGST: number;
  exitGST=0;
  datePipe = new DatePipe("en-US");
  txtNarration;

  stockQuantity: number = 0;
  public loading: boolean = false;
  
  constructor(
    private saleservice: SaleService,
    private itemsdetailservice: ItemsDetailService,
    private toastr: ToastrService,
    private partyService: PartyService,
    private companyService: CompanyService,) { }

  ngOnInit(): void {
    this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, "dd/MM/yyyy");
    this.txtsaleDate = new Date();
    this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.txtExpiryDate = new Date();
    this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllParty();
    // this.getSaleNumber();
    // this.getAllsale();
    this.getAllCompany();
  }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  // Allow only Numbers
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //Sale Number
  getSaleNumber() {
    this.saleservice.getSaleNumber().subscribe(res => {
      this.saleNumber = res;
      this.txtsaleCode = this.saleNumber;
    })
  }

  selectedStatic(result) {
    this.itemId = result.itemId;
    this.search1 = result.itemName;
    this.getItemById();
  }
  tempParty;
  selectedParty(party) {
    this.tempParty = party;

    this.searchParty = party.partyName;
  }

  allparty;
  getAllParty() {
    this.partyService.getAllParty().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        this.searchPartyName.push(temp)
      }
      console.log(this.allparty);
    })
  }

  getPartyByCellNumber() {
    this.partyService.getPartyByCellNumber(this.txtMobileNumber).subscribe(res => {
      this.getParty = res;
      this.txtpartyName = this.getParty.partyName;
      this.txtpartyId = this.getParty.partyId;
    })
  }


  getAllItem() {
    this.itemsdetailservice.getAllItem().subscribe(res => {
      this.allItem = res;
      for (let item of this.allItem) {
        var temp = new SearchItemDtos();
        temp.itemName = item.itemName;
        temp.itemId = item.itemid;
        this.searchItemName.push(temp)
      }
      console.log(this.allItem);

    })
  }

  clearGridData() {
    this.txtItemName = "";
    this.txtManufactureDate = new Date();
    // this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtCategoryName = "";
    this.txtSubCategoryName = "";
    this.txtBrandName = "";
    this.txtquantity = "";
    this.txtrate = "";
    this.txtExpiryDate = new Date();
    // this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.search1 = "";
    this.btnName = "Add";
    this.txtsaleid = null;
    this.batchwiseresponse = null;
    this.txtCGST = 0;
    this.txtSGST = 0;
  }

  clearValue() {
    this.txtsaleDate = new Date();
    // this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, "dd/MM/yyyy");
    this.txtsaleCode = '';
    this.txtMobileNumber = '';
    this.txtnetAmount = 0;
    this.txtamount = 0;
    this.txtDiscount = 0;
    this.txtAdd = 0;
    this.txtgstAmount = 0;
    this.txtpartyName = '';
    this.searchParty = '';
    this.saleData = [];
    this.txtMobileNumber = '';
    this.tempamount = 0;
    this.exitGST=0;
    this.txtNarration= '';
    this.getSaleNumber();
    this.optRadio = "Cash";
  }

  resItemById;
  txtCompanyName;
  getItemById() {
    this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
      this.resItemById = res;
      console.log(this.resItemById);
      this.txtCompanyName =  this.resItemById.category.company.companyName;
      this.txtCategoryName = this.resItemById.category.categoryName;
      this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
      this.txtBrandName = this.resItemById.brand.name;
      this.txtCGST = this.resItemById.gst.cgst;
      this.txtSGST = this.resItemById.gst.sgst;
    });
  }

  addSaleDetailStockCheck() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate must be required');
      // } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      // return this.toastr.error(' Manufacture Date must be required');
      // } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      // return this.toastr.error(' Expiry Date must be required');
    }else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      this.saleservice.addSaleDetailStockCheck(this.itemId, this.txtquantity)
        .subscribe(res => {
          this.stockCheck = res;
          console.log(this.stockCheck);
          // this.clearValue();
          this.toastr.success('Stock available');
          this.addSaleDetail();
        }, error => {
          this.toastr.error('Stock not available for item');

        })
    }
  }

  addSaleDetail() {
    if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
      return this.toastr.error('Item name must be required');
    } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
      return this.toastr.error('Quantity must be required');
    } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
      return this.toastr.error('Rate must be required');
      // } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
      // return this.toastr.error(' Manufacture Date must be required');
      // } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
      // return this.toastr.error(' Expiry Date must be required');
    }else if (this.batchwiseresponse == null) {
      return this.toastr.error('Please verify stock');
    } else {
      if (this.txtsaleid == null) {
        // this.txtItemName = this.tempitem;
        var saleDetail = new sale();
        if (this.saleData.length == 0) {
          saleDetail.id1 = this.saleIdLast;
        } else if (this.saleData.length != 0) {
          saleDetail.id1 = this.saleIdLast + 1;
        }
        this.saleIdLast = saleDetail.id1;
        saleDetail.itemName = this.search1; //this for selected itemName
        saleDetail.itemId = this.itemId;
        saleDetail.categoryName = this.txtCategoryName;
        saleDetail.subCategoryName = this.txtSubCategoryName;
        saleDetail.companyName = this.txtCompanyName;
        saleDetail.brandName = this.txtBrandName;
        saleDetail.quantity = this.txtquantity;
        saleDetail.rate = this.txtrate;
        saleDetail.cgst = this.txtCGST;
        saleDetail.sgst = this.txtSGST;
        saleDetail.totalAmount = this.txtrate * this.txtquantity;
        //base price calculation
        var totalpercentage = 100 + this.txtCGST + this.txtSGST
        var baseprice = 0;
        baseprice = parseFloat(Number(saleDetail.totalAmount * 100 / totalpercentage).toFixed(2));
        var totalgstamt = 0;
        totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
        // saleDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
        // saleDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
        saleDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
        saleDetail.netAmount = baseprice;

        this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
        saleDetail.manufactureDate = this.txtManufactureDate;
        this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
        saleDetail.expiryDate = this.txtExpiryDate;
        if (this.batchwiseresponse == null) {
          this.getBatchNumber();
          saleDetail.stockDTOs = this.batchwiseresponse;
        } else {
          saleDetail.stockDTOs = this.batchwiseresponse;
        }
        this.stockQuantity = 0;
        for (let stockQuantityValue of this.batchwiseresponse) {
          this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
        }
        console.log("quantity",this.stockQuantity);
        if (this.stockQuantity > this.txtquantity) {
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is more than quantity');
        }else if(this.stockQuantity < this.txtquantity){
          console.log("Inisde If",this.stockQuantity);
          return this.toastr.error('Stock assigned is less than quantity');
        }
        this.saleData.push(saleDetail);

        //code for calculate netamount and gstamount;
        // var temp = 0;
        // var gstsum = 0;
        // temp = this.txtquantity * this.txtrate;
        // this.tempamount = this.tempamount + temp;

        // this.tempGST = temp * (this.txtCGST + this.txtSGST) / 100;
        // gstsum = gstsum + this.tempGST;
        // this.exitGST=this.exitGST+gstsum;
        // this.txtgstAmount = this.exitGST;

        // this.txtamount = this.tempamount - this.txtgstAmount;
        // this.txtnetAmount = this.tempamount;
        this.getAmount();
        this.calculateNetAmount();
        this.calculateAdd();
        this.clearGridData();

      } else {
        for (let sale of this.saleData) {
          if (sale.id1 == this.txtsaleid) {
            this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
            sale.manufactureDate = this.txtManufactureDate;
            sale.itemName = this.search1;
            sale.itemId = this.itemId;
            sale.categoryName = this.txtCategoryName;
            sale.subCategoryName = this.txtSubCategoryName;
            sale.companyName = this.txtCompanyName;
            sale.brandName = this.txtBrandName;
            sale.quantity = this.txtquantity;
            sale.rate = this.txtrate;
            sale.cgst = this.txtCGST;
            sale.sgst = this.txtSGST;
            sale.totalAmount = this.txtrate * this.txtquantity;
            //base price calculation
            var totalpercentage = 100 + this.txtCGST + this.txtSGST
            var baseprice = 0;
            baseprice = parseFloat(Number(sale.totalAmount * 100 / totalpercentage).toFixed(2));
            var totalgstamt = 0;
            totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
            // sale.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
            // sale.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
            sale.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            sale.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            sale.netAmount = baseprice;
            this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
            sale.expiryDate = this.txtExpiryDate;
            if (this.batchwiseresponse == null) {
              this.getBatchNumber();
              sale.stockDTOs = this.batchwiseresponse;
            } else {
              sale.stockDTOs = this.batchwiseresponse;
            }
            this.stockQuantity = 0;
            for (let stockQuantityValue of this.batchwiseresponse) {
              this.stockQuantity = Number(this.stockQuantity) + Number(stockQuantityValue.allotedquantity);
            }
            console.log("quantity",this.stockQuantity);
            if (this.stockQuantity > this.txtquantity) {
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is more than quantity');
            }else if(this.stockQuantity < this.txtquantity){
              console.log("Inisde If",this.stockQuantity);
              return this.toastr.error('Stock assigned is less than quantity');
            }
            this.getAmount();
            this.calculateNetAmount();
            this.calculateAdd();
            this.clearGridData();
          }
        }
      }
    }
    console.log(this.saleData);
  }

  calculateNetAmount() {
    if (this.txtDiscount == 0 || this.txtDiscount == undefined || this.txtDiscount == null) {
      this.txtDiscount = 0;
      if(this.txtAdd == 0){
        this.txtnetAmount = this.tempNetAmount;
      }else{
        this.txtnetAmount = this.tempNetAmount + Number(this.txtAdd);
      }
    } else {
        this.txtnetAmount = this.tempNetAmount;
        this.txtnetAmount = parseFloat(Number(this.txtnetAmount + Number(this.txtAdd - this.txtDiscount)).toFixed(2));
    }
  }

  calculateAdd(){
    if (this.txtAdd == 0 ||  this.txtAdd == undefined || this.txtAdd == null) {
      this.txtAdd = 0;
      if(this.txtDiscount == 0){
        this.txtnetAmount = this.tempNetAmount;
      }else{
        this.txtnetAmount = this.tempNetAmount - Number(this.txtDiscount);
      }
    } else {
        this.txtnetAmount = this.tempNetAmount;
        this.txtnetAmount = parseFloat(Number(this.txtnetAmount + Number(this.txtAdd - this.txtDiscount)).toFixed(2));
    }
  }

  tempamount = 0;
  tempGST = 0;
  tempNetAmount = 0;
  getAmount() {
    this.txtamount = 0;
    this.txtgstAmount = 0;
    this.tempamount = 0;
    this.tempGST = 0;
    var temp = 0;
    var gstsum = 0;
    this.txtnetAmount = 0;
    this.exitGST=0;

    for (let item of this.saleData) {
      // temp = item.quantity * item.rate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = this.tempamount * (item.cgst + item.sgst) / 100;
      // // gstsum = gstsum + this.tempGST;
      // this.exitGST=this.exitGST+this.tempGST;
      // this.txtgstAmount = this.exitGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;
      this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
      this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
      this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
      this.tempNetAmount = this.txtnetAmount;
    }
  }

  saleDetailres;
  createSaleDetail() {
    if (this.txtsaleDate == undefined || this.txtsaleDate == null || this.txtsaleDate == '') {
      return this.toastr.error('Sale date must be required');
      // } else if (this.txtsaleCode == undefined || this.txtsaleCode == null || this.txtsaleCode == '') {
      // return this.toastr.error(' sale number must be required');
    } else if (this.txtMobileNumber == null || this.txtMobileNumber == '' || this.txtMobileNumber == undefined) {
      this.toastr.error("Mobile number is required");
    } else if (this.txtMobileNumber.length > 10 || this.txtMobileNumber.length < 10) {
      this.toastr.error("Mobile number must be 10 digit");
    } else if (this.txtpartyName == undefined || this.txtpartyName == null || this.txtpartyName == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.saleData.length == 0 || this.saleData == []) {
      return this.toastr.error('Sale detail must be required');
    } else {
      // if(this.optRadio == "Bank"){
      //   for(let saleBank of this.saleData){
      //     console.log("Company Name",saleBank.companyName);
      //     if(saleBank.categoryName == "SEED"){
      //       console.log("this bank",saleBank.companyName);
      //       return this.toastr.error('Radhaswami Bij Nigam item not sale in Bank');
      //     }
      //   }
      // }
      // this.txtpartyName = this.tempParty;
      this.loading = true;
      $(".overlay").css({"display": "block", "opacity": "0.7"});
      $(".loader").css({"display": "block"});
      // console.log("paymentMode",this.optRadio);
      this.txtsaleDate = this.datePipe.transform(this.txtsaleDate, 'yyyy-MM-dd');
      this.saleservice.addSaleCashDetail(this.txtsaleDate, this.txtsaleCode, this.txtMobileNumber, this.txtpartyName, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.saleData,this.txtAdd, this.txtNarration, this.optRadio)
        .subscribe(res => {
          this.saleDetailres = res;
          // console.log(this.saleDetailres);
          if (res) {
            this.hideloaderOrder();
            $(".overlay").css({"display": "none"});
          }
          this.clearValue();
          // this.checkedValue();
          this.getSaleNumber();
          this.toastr.success('Sale save successfully');
          this.getPDFCategoryNoOfInvoiceData();
          $('#pdfModal').modal('show');
        }, error => {
          // this.toastr.error('Sale not saved');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
          this.loading = false;
        })
    }
  }

  getAllsale() {
    this.saleservice.getAllsale().subscribe(res => {
      this.allsale = res;
    })
  }

  updateSale(sale) {
    this.btnName = "Update";
    this.txtsaleid = sale.id1;
    this.txtItemName = sale.itemName;
    this.itemId = sale.itemId;
    this.search1 = sale.itemName;
    this.txtCategoryName = sale.categoryName;
    this.txtSubCategoryName = sale.subCategoryName;
    this.txtBrandName = sale.brandName;
    this.txtquantity = sale.quantity;
    this.txtrate = sale.rate;
    this.txtCGST = sale.cgst;
    this.txtSGST = sale.sgst;
    this.txtManufactureDate = sale.manufactureDate;
    this.txtExpiryDate = sale.expiryDate;

  }

  i;
  deleteSaleData;
  deleteSale(sale) {
    this.deleteSaleData = sale;
  }

  confirmDelete(){
    for (let saleData of this.saleData) {
      if (saleData.id1 == this.deleteSaleData.id1) {
        this.i = this.deleteSaleData.saleId;
        let index = this.saleData.indexOf(this.deleteSaleData);
        if (saleData.id1 !== -1) {
          this.saleData.splice(index, 1);
          this.getAmount();
          this.calculateNetAmount();
          this.calculateAdd();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
        }
      }
    }
  }

  getBatchNumber() {
    this.saleservice.getStockByItemPerBatch(this.itemId, this.txtquantity)
      .subscribe(res => {
        this.batchwiseresponse = res;
        if (this.batchwiseresponse.length <= 0) {
          return this.toastr.error('Stock not found');
        }
        console.log(this.batchwiseresponse);
        for (let batchWiseStock of this.batchwiseresponse) {
          if (batchWiseStock.allotedquantity == null) {
            batchWiseStock.allotedquantity = 0
          }
        }
        console.log("batchWise Stock", this.batchwiseresponse);
        this.toastr.success('Stock available');
        // this.addSaleDetail();
      }, error => {
        this.toastr.error('Stock not available for item');
      })

  }

  allCompany = [];
  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  bijNigamId;
  bijBhandarId;
  resBillPdfNigama;
  openPdfResponseNigam 
  resBillPdfBhandar
  openPdfResponseBhandar
  tempPdfCompany;
  NoOfCompanyLength;
  allPDFCompany;
  getcompnayOrganizationPDF() {
    // this.saleservice.getCompanyOganizationPDFData(this.saleDetailres.saleid).subscribe(res=>{
      this.saleservice.getPDFCategoryNoOfInvoiceData(this.saleDetailres.saleid).subscribe(res => {
      console.log(res);
      this.allPDFCompany = res;
      this.NoOfCompanyLength = res.length;
      // for (let data of res) {
        // if (data.companyName == "RADHASWAMI BIJ NIGAM") {
        //   this.bijNigamId = data.companyId
        // } else if (data.companyName == "RADHASWAMI BIJ BHANDAR") {
        //   this.bijBhandarId = data.companyId
        // } else {
        //   this.toastr.error("something went wrong");
        // }
        // for(let comp of this.allCompany) {
        //   if (data.companyName == comp.companyName) {
        //     this.tempPdfCompany = data.companyName;
        //     this.saleBhandarPDF();
        //     break;
        //   } else {
        //     this.tempPdfCompany = null;
        //   }
        // }
      // }
    }, error => {
      this.allPDFCompany = null;
      this.NoOfCompanyLength = null;
      let message = JSON.parse(error._body).message;
      this.toastr.error(JSON.stringify(message));
    })
  }

  allPDFCategory;
  totalNoOfInvoice = 0;
  getPDFCategoryNoOfInvoiceData() {
    this.saleservice.getPDFCategoryNoOfInvoiceData(this.saleDetailres.saleid).subscribe(res => {
      // console.log(res);
      this.allPDFCategory = JSON.parse(res);
      // console.log(JSON.parse(res));
      // this.allPDFCategory = res;
      this.totalNoOfInvoice = 0;
      for (let comp of this.allPDFCategory) {
        this.totalNoOfInvoice = this.totalNoOfInvoice + comp.invoiceDetail.length;
      }
      // console.log("Total NoOfInvoice For Discount: "+this.totalNoOfInvoice);
    },error => {
      this.allPDFCategory = null;
    })
  }

  downloadPDF() {
    if ((this.bijNigamId != undefined || this.bijNigamId != null) && (this.bijBhandarId != undefined || this.bijBhandarId != null)) {
      this.saleNigamPDF();
      this.saleBhandarPDF();
    } else if (this.bijNigamId != undefined || this.bijNigamId != null) {
      this.saleNigamPDF();
    } else if (this.bijBhandarId != null || this.bijBhandarId != undefined) {
      this.saleBhandarPDF();
    } else {
      this.toastr.error("something went wrong");
    }
    this.bijBhandarId = null;
    this.bijNigamId = null;
  }

  saleNigamPDF() {
    this.saleservice.downloadSaleNigamBill(this.saleDetailres.saleNumber).subscribe((data) => {
      this.resBillPdfNigama = data;

      var newBlob = new Blob([this.resBillPdfNigama], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).sSaveOrOpenBlob(newBlob);
        return;
      }

      const data1 = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data1;
      link.download = "Sale_Bij_Nigam.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link.href, "", "width=800,height=600");
      this.openPdfResponseNigam.saveAs(link.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data1);
        link.remove();
      }, 100);
      this.resBillPdfNigama = '';
      this.openPdfResponseNigam = '';
    })
  }

  saleBhandarPDF() {
    this.saleservice.downloadSaleBhandarBill(this.saleDetailres.saleNumber,this.tempPdfCompany,this.NoOfCompanyLength).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_Bij_Bhandar.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  downloadPdfByCompany(company,noofCompany) {
    this.saleservice.downloadSaleBhandarBill(this.saleDetailres.saleNumber,company.companyName,noofCompany).subscribe((data) => {
      this.resBillPdfBhandar = data;

      var newBlob1 = new Blob([this.resBillPdfBhandar], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseBhandar.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfBhandar = '';
      this.openPdfResponseBhandar = '';
    })
  }

  checkedValue(){
    // document.getElementById('cashnote').innerHTML = "Cash";
    var ele = document.getElementById("cashnote");
   var element = ele as HTMLInputElement;
   element.checked = true;
  }

  optRadio="Cash";
  onItemChange(value) {
    // console.log(value);
    this.optRadio = value;
    if (value == "Bank") {
      this.optRadio = "Bank";
      console.log("Bank Value",this.optRadio);
    }else{
      this.optRadio = "Cash";
      console.log("Cash Value",this.optRadio)
    }
  }
  
  // new sale report by category
  downloadNewPDF(company) {
    if("PESTICIDE" == company.staticinvoicename){
      this.downloadSalePesticideBill(company);
    } else if("FERTILISER" == company.staticinvoicename){
      this.downloadSaleFertiliserBill(company);
    } else if("SEEDS" == company.staticinvoicename){
      this.downloadSaleSeedsBill(company);
    }
  }
  openPdfResponsePesticide;
  resBillPdfPesticide;
  openPdfResponseFertiliser;
  resBillPdfFertiliser;
  openPdfResponseSeeds;
  resBillPdfSeeds;
  downloadSalePesticideBill(company) {
    this.saleservice.downloadSalePesticideBill(company.invoiceid,this.saleDetailres.saleNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfPesticide = data;

      var newBlob1 = new Blob([this.resBillPdfPesticide], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponsePesticide.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfPesticide = '';
      this.openPdfResponsePesticide = '';
    })
  }
  downloadSaleFertiliserBill(company) {
    this.saleservice.downloadSaleFertiliserBill(company.invoiceid,this.saleDetailres.saleNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfFertiliser = data;

      var newBlob1 = new Blob([this.resBillPdfFertiliser], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseFertiliser.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfFertiliser = '';
      this.openPdfResponseFertiliser = '';
    })
  }
  downloadSaleSeedsBill(company) {
    this.saleservice.downloadSaleSeedsBill(company.invoiceid,this.saleDetailres.saleNumber,company.companyName,this.totalNoOfInvoice).subscribe((data) => {
      this.resBillPdfSeeds = data;

      var newBlob1 = new Blob([this.resBillPdfSeeds], { type: "application/pdf" });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(newBlob1);
        return;
      }

      const data2 = window.URL.createObjectURL(newBlob1);
      var link2 = document.createElement('a');
      link2.href = data2;
      link2.download = "Sale_PDF.pdf";
      // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      window.open(link2.href, "", "width=800,height=600");
      this.openPdfResponseSeeds.saveAs(link2.href);

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data2);
        link2.remove();
      }, 100);
      this.resBillPdfSeeds = '';
      this.openPdfResponseSeeds = '';
    })
  }
  
}