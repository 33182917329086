import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryAppService } from '../service/category-app/category-app.service';
declare var $: any;

@Component({
  selector: 'app-category-app',
  templateUrl: './category-app.component.html',
  styleUrls: ['./category-app.component.css']
})
export class CategoryAppComponent implements OnInit {
  txtCategoryAppId;
  txtCategoryApp;
  txtCategoryAppGuj;
  allCategoryApp;
  resCategoryApp;
  resCategoryAppUp;
  resCategoryDel;
  btnName = 'Save';
  search;
  page: number = 1;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;

  constructor(
    private categoryAppService: CategoryAppService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllCategoryApp();
  }

  getAllCategoryApp() {
    this.categoryAppService.gatAllCategoryApp().subscribe(res => {
      this.allCategoryApp = res;
    })
  }

  toUpperCaseCategoryApp() {
    const temp = this.txtCategoryApp;
    this.txtCategoryApp = temp.toUpperCase();
    return this.txtCategoryApp;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtCategoryApp = '';
    this.txtCategoryAppGuj = '';
    this.txtCategoryAppId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
  }

  createCategoryApp() {
    var cat = this.txtCategoryApp;
    var catguj = this.txtCategoryAppGuj;
    if (this.txtCategoryApp == null || this.txtCategoryApp == '' || this.txtCategoryApp == undefined || (this.txtCategoryApp = cat.trim()) == "") {
      this.toastr.error("Application category is required");
    }else if (this.txtCategoryAppGuj == null || this.txtCategoryAppGuj == '' || this.txtCategoryAppGuj == undefined || (this.txtCategoryAppGuj = catguj.trim()) == "") {
      this.toastr.error("Application category gujarati is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("Category image is required");
    } else {
      if (this.txtCategoryAppId == null) {
        this.categoryAppService.createCategoryApp(this.txtCategoryApp, this.txtCategoryAppGuj)
          .subscribe(res => {
            this.resCategoryApp = res;
            console.log(this.resCategoryApp);
            this.uploadImage();
            // this.clearData();
            this.getAllCategoryApp();
            this.toastr.success('Application category save successfully');
          }, error => {
            // this.toastr.error('Application category not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.categoryAppService.updateCategoryApp(this.txtCategoryAppId, this.txtCategoryApp, this.txtCategoryAppGuj, this.txtImage).subscribe(res => {
          this.resCategoryAppUp = res;
          this.uploadImage();
          // this.clearData();
          this.getAllCategoryApp();
          this.toastr.success('Application category updated successfully');
        }, error => {
          // this.toastr.error('Application category not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updatecategoryApp(cat) {
    this.btnName = 'Update';
    this.txtCategoryAppId = cat.categoryId;
    this.txtCategoryApp = cat.categoryName;
    this.txtCategoryAppGuj = cat.categoryNameGuj;
    this.txtImage = cat.image;
    this.imagePreview = cat.image;
  }

  deleteAppId;
  deleteDataApp(cat) {
    this.deleteAppId = cat.categoryId;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.categoryAppService.deleteCategoryApp(this.deleteAppId)
        .subscribe(res => {
          this.resCategoryDel = res;
          console.log(this.resCategoryDel);
          this.clearData();
          this.getAllCategoryApp();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Application category delete successfully');
        }, error => {
          this.toastr.error('Application category not delete');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      this.imagePreview = "";
      this.getAllCategoryApp();
      this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('cat', this.txtCategoryApp);
      this.categoryAppService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllCategoryApp();
        this.clearData();
        this.imagePreview = "";
      }, error => {
        this.imagePreview = "";
        this.clearData();
        this.getAllCategoryApp();
      })
    }
  }

}
