import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { StockService } from '../service/stock/stock.service';
import { CompanyService } from '../service/company/company.service';
import { ExcelService } from '../service/excel/excel.service';
declare var $: any;

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.css']
})
export class ProfitLossComponent implements OnInit {
  allProfitLossData;
  // search;
  // page: number = 1;
  datePipe = new DatePipe("en-US");
  // txtStartDate = "2021-04-01";
  txtStartDate;
  txtEndDate;
  allCompany;
  txtCompany;

  constructor(
    private toastr: ToastrService,
    private stockservice: StockService,
    private companyService: CompanyService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.txtEndDate = new Date();
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    this.txtStartDate = new Date();
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    // var startDate = new Date();
    // var curr_year = startDate.getFullYear();
    // var curr_month = startDate.getMonth() + 1;
    // if (curr_month < 4) {
    //   curr_year = startDate.getFullYear()-1;
    // } else {
    //   curr_year = startDate.getFullYear();
    // }
    // this.txtStartDate = (curr_year + "-" + '04' + "-" + '01');
    // console.log(this.txtStartDate);
    // this.getAllProfitAndLoss();
    // this.searchStockValution();
    this.getAllCompany();
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
      // console.log(this.allCompany);
    })
  }

  txtSale = 0;
  txtStock = 0;
  txtPurchase = 0;
  txtExpense = 0;
  txtSaleReturn = 0;
  txtPurchaseReturn = 0;
  // txtCreditNote = 0;
  // txtDebitNote = 0;
  txtIncome = 0;
  totalA = 0;
  totalB = 0;
  txtProfitLoss = 0;
  getAllProfitAndLoss() {
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    if (this.txtCompany == "All") {
      this.stockservice.getAllProfitAndLoss(this.txtStartDate, this.txtEndDate).subscribe(res => {
        this.allProfitLossData = res;
        console.log(this.allProfitLossData);
        this.txtSale = this.allProfitLossData.sale;
        this.txtPurchase = this.allProfitLossData.purchase;
        this.txtExpense = this.allProfitLossData.expense;
        this.txtIncome = this.allProfitLossData.income;
        this.txtSaleReturn = this.allProfitLossData.saleReturn;
        this.txtPurchaseReturn = this.allProfitLossData.purchaseReturn;
        // this.txtCreditNote = this.allProfitLossData.creditNote;
        // this.txtDebitNote = this.allProfitLossData.debitNote;
        // console.log(this.txtSale);
        // console.log(this.txtPurchase);
        // console.log(this.txtExpense);
        this.totalA = this.txtSale + this.txtStock + this.txtIncome + this.txtPurchaseReturn;
        this.totalB = this.txtExpense + this.txtPurchase + this.txtSaleReturn;
        this.txtProfitLoss = this.totalA - this.totalB;
      })
    } else {
      this.stockservice.getAllProfitAndLossCompany(this.txtCompany,this.txtStartDate, this.txtEndDate).subscribe(res => {
        this.allProfitLossData = res;
        console.log(this.allProfitLossData);
        this.txtSale = this.allProfitLossData.sale;
        this.txtPurchase = this.allProfitLossData.purchase;
        this.txtExpense = this.allProfitLossData.expense;
        this.txtIncome = this.allProfitLossData.income;
        this.txtSaleReturn = this.allProfitLossData.saleReturn;
        this.txtPurchaseReturn = this.allProfitLossData.purchaseReturn;
        // this.txtCreditNote = this.allProfitLossData.creditNote;
        // this.txtDebitNote = this.allProfitLossData.debitNote;
        // console.log(this.txtSale);
        // console.log(this.txtPurchase);
        // console.log(this.txtExpense);
        this.totalA = this.txtSale + this.txtStock + this.txtIncome + this.txtPurchaseReturn;
        this.totalB = this.txtExpense + this.txtPurchase + this.txtSaleReturn;
        this.txtProfitLoss = this.totalA - this.totalB;
      })
    }
  }

  reStockvalutionData;
  reStockvalutionCompanyData = [];
  searchStockValution() {
    // if (this.txtLocation == "" || this.txtLocation == null || this.txtLocation == undefined) {
    //   this.toastr.error("location is required");
    // } else {
    // this.stockservice.getsearchStockValutionAll().subscribe(res => {
    this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
    this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
    this.stockservice.getsearchStockValutionAllByDate(this.txtStartDate, this.txtEndDate).subscribe(res => {
      this.reStockvalutionData = res;
      this.reStockvalutionCompanyData = this.reStockvalutionData;
      // console.log(this.reStockvalutionData);

      if ( this.txtCompany == "All") {
        this.getTotal();
        this.getAllProfitAndLoss();
      } else {
        this.reStockvalutionData = [];
        for (let company of this.reStockvalutionCompanyData) {
          // console.log("company",company);
          // console.log("item id",company.itemID);
          // console.log("item Category",company.item.category);
          // console.log("category",company.item.category.company.companyId);
          if (this.txtCompany == company.item.category.company.companyId) {
            // console.log("company",company);
            this.reStockvalutionData.push(company);
          }
        }
          this.getTotal();
          this.getAllProfitAndLoss();
      }
      // this.getTotal();
    }, error => {
      this.toastr.error("something went wrong");
    });
    // }
  }
  totalstockTotalAmount;
  getTotal() {
    this.totalstockTotalAmount = 0;
    for (let stkval of this.reStockvalutionData) {
      var sum = 0;
      sum = sum + Number(stkval.stockTotalAmount);
      this.totalstockTotalAmount = this.totalstockTotalAmount + sum;
      this.txtStock = this.totalstockTotalAmount;
      // console.log(this.totalstockTotalAmount);
    }
  }

  ExalData = [];
  stockTotalAmount;
  excelExpiryStockData = [];
  exportAsXLSX():void {
    // this.ExalData=[];
      // this.stockTotalAmount = parseFloat(Number(exal.stockTotalAmount).toFixed(2));
      // this.ExalData.push({"Income": this.txtIncome,"Sale": this.txtSale,"Stock": this.txtStock,"Total(A)": this.totalA,
      // "Expense": this.txtExpense,"Purchase": this.txtPurchase,"Total(B)": this.totalB,"ProfitLoss": this.txtProfitLoss})
    
    this.excelservice.exportExcelProfitLoss('Profitloss',this.txtIncome,this.txtSale,this.txtPurchaseReturn,this.txtStock,this.totalA,this.txtExpense,this.txtPurchase,this.txtSaleReturn,this.totalB,this.txtProfitLoss);
 }

 resProfitLossPdf;
 openPdfResponseProfitLoss;
 downloadProfitLossPDF() {
  this.txtStartDate = this.datePipe.transform(this.txtStartDate, "yyyy-MM-dd");
  this.txtEndDate = this.datePipe.transform(this.txtEndDate, "yyyy-MM-dd");
   this.stockservice.downloadProfitLossPDF(this.txtCompany, this.txtStartDate, this.txtEndDate,this.txtStock).subscribe((data) => {
     this.resProfitLossPdf = data;

     var newBlob = new Blob([this.resProfitLossPdf], {type: "application/pdf"});
     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
       window.navigator.msSaveOrOpenBlob(newBlob);
       return;
     }

     const profitLossCompanydata = window.URL.createObjectURL(newBlob);
     var link = document.createElement('a');
     link.href = profitLossCompanydata;
     link.download = "ProfitLossReport.pdf";
     // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
     window.open(link.href, "", "width=800,height=600");
     this.openPdfResponseProfitLoss.saveAs(link.href);

     setTimeout(function () {
       // For Firefox it is necessary to delay revoking the ObjectURL
       window.URL.revokeObjectURL(profitLossCompanydata);
       link.remove();
     }, 100);
     this.resProfitLossPdf = '';
     this.openPdfResponseProfitLoss = '';
   })
 }

}
