import { Component, OnInit } from '@angular/core';
import { PurchaseReturnService } from "../service/purchase-return/purchase-return.service";
import { ItemsDetailService } from "../service/items-detail/items-detail.service";
import { SearchItemDtos } from '../service/items-detail/searchItemDTO';
import { PurchaseReturn } from "../service/purchase-return/purchase-return";
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
declare var $: any;

@Component({
  selector: 'app-purchase-return',
  templateUrl: './purchase-return.component.html',
  styleUrls: ['./purchase-return.component.css']
})
export class PurchaseReturnComponent implements OnInit {
  itemId;
  txtPurchaseReturnDate;
  txtpurchaseReturnid;
  txtpurchaseReturnCode;
  txtpartyName;
  txtDueDays;
  txtDueDate;
  txtgstAmount = 0;
  txtamount = 0;
  txtDiscount = 0;
  txtnetAmount = 0;

  txtManufactureDate;
  txtItemName;
  txtCategoryName;
  txtSubCategoryName;
  txtBatchNumber;
  txtquantity;
  txtrate;
  txtExpiryDate;
  purchaseNumber;

  // txtItemName;
  allPurchase;
  btnName = 'Add';
  allItem;
  searchItemName = [];
  public search1 = '';
  public searchParty = '';
  searchPartyName = [];
  resAllBatchNumber
  searchbatchno;
  searchBatchNumbers =[];
  resQty;
  avilableBatchNoQty;
  PurchaseReturnData: Array<PurchaseReturn> = [];
  PurchaseIdLast: number = 0;
  txtCGST: number;
  txtSGST: number;
  txtTotalGST: number;
  exitGST=0;
  datePipe = new DatePipe("en-US");
  purchasepartyid;
  txtNarration;
  public loading: boolean = false;

  constructor(
    private itemsdetailservice: ItemsDetailService,
    private purchasereturnservice: PurchaseReturnService,
    private toastr: ToastrService,
    private partyService: PartyService,
) { }

  ngOnInit(): void {
    this.txtPurchaseReturnDate = this.datePipe.transform(this.txtPurchaseReturnDate, "dd/MM/yyyy");
    this.txtPurchaseReturnDate = new Date();
    // this.txtPurchaseReturnDate = this.datePipe.transform(this.txtDueDate, "dd-MM-yyyy");
    this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
    this.txtExpiryDate = new Date();
    this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
    this.txtManufactureDate = new Date();
    this.getAllItem();
    this.getAllParty();
    this.getPurchaseReturnNumber();
  }

  hideloaderOrder() { 
    $(".loader").css({"display": "none"});
  }
 // Allow only Numbers and dot(.)
 numberandDotOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keycode;
  if (charCode == 46) {
    return true;
  } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  } else {
    return true;
  }
}
// Allow only Numbers
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keycode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

getDueDate() {
  var ltime = this.txtPurchaseReturnDate.getTime() + this.txtDueDays * 24 * 60 * 60 * 1000;
  this.txtDueDate = new Date(ltime);
  this.txtDueDate = this.datePipe.transform(this.txtDueDate, "MM-dd-yyyy");
}
//for search data


getPurchaseReturnNumber() {
  this.purchasereturnservice.getPurchaseReturnNumber().subscribe(res => {
    this.purchaseNumber = res;
    this.txtpurchaseReturnCode = this.purchaseNumber;
  })
}

selectedStatic(result) {
  this.itemId = result.itemId;
  this.search1 = result.itemName;
  this.getItemById();
  this.getBatchNumberByPartyANdItem();
}
tempParty;
selectedParty(party) {
  this.tempParty = party;
  this.purchasepartyid = party.partyId;
  this.searchParty = party.partyName;
}
allparty;
getAllParty() {
  this.partyService.getAllPartyStatus("COMPANY").subscribe(res => {
    this.allparty = res;
    for (let item of this.allparty) {
      var temp = new SearchParty();
      temp.partyName = item.partyName;
      temp.partyId = item.partyId;
      this.searchPartyName.push(temp)
    }
    console.log(this.allparty);
  })
}
getAllItem() {
  this.itemsdetailservice.getAllItem().subscribe(res => {
    this.allItem = res;
    for (let item of this.allItem) {
      var temp = new SearchItemDtos();
      temp.itemName = item.itemName;
      temp.itemId = item.itemid;
      this.searchItemName.push(temp)
    }
    console.log(this.allItem);

  })
}

clearGridData() {
  this.txtItemName = "";
  this.txtManufactureDate = new Date();
  // this.txtManufactureDate =this.datePipe.transform(this.txtManufactureDate, "dd/MM/yyyy");
  this.txtCategoryName = "";
  this.txtSubCategoryName = "";
  this.txtBatchNumber = "";
  this.txtquantity = "";
  this.txtrate = "";
  this.txtExpiryDate = new Date();
  // this.txtExpiryDate =this.datePipe.transform(this.txtExpiryDate, "dd/MM/yyyy");
  this.search1 = "";
  this.btnName = "Add";
  this.txtpurchaseReturnid = null;
  this.txtPurchaseNo = '';
  this.searchPurchasenumber= '';
  this.qtyLabel = false;
  this.txtCGST = 0;
  this.txtSGST = 0;
}

clearValue() {
  this.txtPurchaseReturnDate = new Date();
  // this.txtPurchaseReturnDate = this.datePipe.transform(this.txtPurchaseReturnDate, "dd/MM/yyyy");
  this.txtpurchaseReturnCode = '';
  this.txtDueDays = '';
  this.txtDueDate = '';
  this.txtnetAmount = 0;
  this.txtamount = 0;
  this.txtDiscount = 0;
  this.txtgstAmount = 0;
  this.txtpartyName = '';
  this.searchParty = '';
  this.PurchaseReturnData = [];
  this.tempamount = 0;
  this.exitGST=0;
  this.txtNarration  = '';
  this.getPurchaseReturnNumber();
}

resItemById;
getItemById() {
  this.itemsdetailservice.getItemById(this.itemId).subscribe(res => {
    this.resItemById = res;
    console.log(this.resItemById);
    this.txtCategoryName = this.resItemById.category.categoryName;
    this.txtSubCategoryName = this.resItemById.subCategory.subCategoryName;
    this.txtCGST = this.resItemById.gst.cgst;
    this.txtSGST = this.resItemById.gst.sgst;
  });
  this.getBatchNumberByItemId();
}

  selectedBatchno(batch) {
    this.txtBatchNumber = batch.batchnumber;
    this.searchbatchno = batch.batchnumber;
    this.getPurchaseNoByPartyItemAndBatchNo();
  }

  getBatchNumberByItemId() {
    this.purchasereturnservice.getBatchNumberByItemId(this.itemId).subscribe(res=>{
      this.resAllBatchNumber = res;
      console.log(this.resAllBatchNumber);
      this.searchBatchNumbers = [];
      for (let batch of this.resAllBatchNumber) {
          this.searchBatchNumbers.push({"batchnumber":batch.batchnumber});
      }
    })
  }

  getQtyByItemAndBatchNo() {
    this.purchasereturnservice.getQtyByBatchNoAndItemId(this.itemId,this.txtBatchNumber).subscribe(res=>{
      this.resQty = res;
      this.avilableBatchNoQty = this.resQty.totalqty;
      this.txtExpiryDate = this.resQty.expirydate;
      this.txtManufactureDate = this.resQty.manufacturedate;
      console.log(this.resQty);
      // console.log(this.avilableBatchNoQty);
    })
  }


addPurchaseReturnDetail() {
  if (this.txtItemName == undefined || this.txtItemName == null || this.txtItemName == '') {
    return this.toastr.error('Item name must be required');
  } else if (this.txtBatchNumber == undefined || this.txtBatchNumber == null || this.txtBatchNumber == 0) {
    return this.toastr.error('Batch number must be required');
  } else if (this.txtquantity == undefined || this.txtquantity == null || this.txtquantity == 0) {
    return this.toastr.error('Quantity must be required');
  } else if (this.txtrate == undefined || this.txtrate == null || this.txtrate == 0) {
    return this.toastr.error('Rate must be required');
  } else if (this.txtManufactureDate == undefined || this.txtManufactureDate == null || this.txtManufactureDate == '') {
    return this.toastr.error('Manufacture date must be required');
  } else if (this.txtExpiryDate == undefined || this.txtExpiryDate == null || this.txtExpiryDate == '') {
    return this.toastr.error('Expiry date must be required');
  } else if (Number(this.txtquantity) > Number(this.availablePurchaseNoQty)) {
    return this.toastr.error('Purchase Return quantity is greater then Purchase quantity');
  } else {
    if (this.txtpurchaseReturnid == null) {
      // this.txtItemName = this.tempitem;
      var purchaseDetail = new PurchaseReturn();
      if (this.PurchaseReturnData.length == 0) {
        purchaseDetail.id1 = this.PurchaseIdLast;
      } else if (this.PurchaseReturnData.length != 0) {
        purchaseDetail.id1 = this.PurchaseIdLast + 1;
      }
      this.PurchaseIdLast = purchaseDetail.id1;
      purchaseDetail.itemName = this.search1; //this for selected itemName
      purchaseDetail.itemId = this.itemId;
      purchaseDetail.purchasenumber = this.searchPurchasenumber;
      purchaseDetail.categoryName = this.txtCategoryName;
      purchaseDetail.subCategoryName = this.txtSubCategoryName;
      purchaseDetail.batchNumber = this.txtBatchNumber;
      purchaseDetail.quantity = this.txtquantity;
      purchaseDetail.rate = this.txtrate;
      purchaseDetail.cgst = this.txtCGST;
      purchaseDetail.sgst = this.txtSGST;
      purchaseDetail.totalAmount = this.txtrate * this.txtquantity;
      //base price calculation
      var totalpercentage = 100 + this.txtCGST + this.txtSGST
      var baseprice = 0;
      baseprice = parseFloat(Number(purchaseDetail.totalAmount * 100 / totalpercentage).toFixed(2));
      var totalgstamt = 0;
      totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
      // purchaseDetail.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
      // purchaseDetail.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
      purchaseDetail.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
      purchaseDetail.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
      purchaseDetail.netAmount = baseprice;

      this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
      purchaseDetail.manufactureDate = this.txtManufactureDate;
      this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
      purchaseDetail.expiryDate = this.txtExpiryDate;
      this.PurchaseReturnData.push(purchaseDetail);

      //code for calculate netamount and gstamount;
      // var temp = 0;
      // var gstsum = 0;
      // temp = this.txtquantity * this.txtrate;
      // this.tempamount = this.tempamount + temp;

      // this.tempGST = temp * (this.txtCGST + this.txtSGST) / 100;
      // gstsum = gstsum + this.tempGST;
      // this.exitGST=this.exitGST+gstsum;
      // this.txtgstAmount = this.exitGST;

      // this.txtamount = this.tempamount - this.txtgstAmount;
      // this.txtnetAmount = this.tempamount;
      this.qtyLabel = false;
      this.getAmount();
      this.calculateNetAmount()
      this.clearGridData();

    } else {
      // if (Number(this.txtquantity) > Number(this.PurchaseNoQty)) {
      //   this.toastr.error("Stock not available")
      // } else {
        for (let PurchaseReturn of this.PurchaseReturnData) {
          if (PurchaseReturn.id1 == this.txtpurchaseReturnid) {
            this.txtManufactureDate = this.datePipe.transform(this.txtManufactureDate, 'yyyy-MM-dd');
            PurchaseReturn.manufactureDate = this.txtManufactureDate;
            PurchaseReturn.itemName = this.search1;
            PurchaseReturn.itemId = this.itemId;
            PurchaseReturn.purchasenumber = this.searchPurchasenumber;
            PurchaseReturn.categoryName = this.txtCategoryName;
            PurchaseReturn.subCategoryName = this.txtSubCategoryName;
            PurchaseReturn.batchNumber = this.txtBatchNumber;
            PurchaseReturn.quantity = this.txtquantity;
            PurchaseReturn.rate = this.txtrate;
            PurchaseReturn.cgst = this.txtCGST;
            PurchaseReturn.sgst = this.txtSGST;
            PurchaseReturn.totalAmount = this.txtrate * this.txtquantity;
            //base price calculation
            var totalpercentage = 100 + this.txtCGST + this.txtSGST
            var baseprice = 0;
            baseprice = parseFloat(Number(PurchaseReturn.totalAmount * 100 / totalpercentage).toFixed(2));
            var totalgstamt = 0;
            totalgstamt = parseFloat(Number(baseprice * (this.txtCGST + this.txtSGST) / 100).toFixed(2));
            // Purchase.cgstAmount = (this.txtrate * this.txtquantity) * (this.txtCGST) / 100;
            // Purchase.sgstAmount = (this.txtrate * this.txtquantity) * (this.txtSGST) / 100;
            PurchaseReturn.cgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            PurchaseReturn.sgstAmount = parseFloat(Number(totalgstamt / 2).toFixed(2));
            PurchaseReturn.netAmount = baseprice;

            this.txtExpiryDate = this.datePipe.transform(this.txtExpiryDate, 'yyyy-MM-dd');
            PurchaseReturn.expiryDate = this.txtExpiryDate;
            this.qtyLabel = false
            this.getAmount();
            this.calculateNetAmount();
            this.clearGridData();
          }
        }
      // }
    }
  }
  console.log(this.PurchaseReturnData);
}

calculateNetAmount() {
  if (this.txtDiscount == 0 || this.txtDiscount == undefined || this.txtDiscount == null) {
    this.txtDiscount = 0;
    this.txtnetAmount = this.tempNetAmount;
  } else {
    this.txtnetAmount = this.tempNetAmount - this.txtDiscount;
  }
}

tempamount = 0;
tempGST = 0;
tempNetAmount = 0;
getAmount() {
  this.txtamount = 0;
  this.txtgstAmount = 0;
  this.tempamount = 0;
  this.tempGST = 0;
  var temp = 0;
  var gstsum = 0;
  this.txtnetAmount = 0;
  this.exitGST=0;

  for (let item of this.PurchaseReturnData) {
    // temp = item.quantity * item.rate;
    // this.tempamount = this.tempamount + temp;

    // this.tempGST = this.tempamount * (item.cgst + item.sgst) / 100;
    // // gstsum = gstsum + this.tempGST;
    //   this.exitGST=this.exitGST+this.tempGST;
    // this.txtgstAmount = this.exitGST;

    // this.txtamount = this.tempamount - this.txtgstAmount;
    // this.txtnetAmount = this.tempamount;
    this.txtamount = parseFloat(Number(this.txtamount + item.netAmount).toFixed(2));
    this.txtgstAmount = parseFloat(Number(this.txtgstAmount +(item.cgstAmount + item.sgstAmount)).toFixed(2));
    this.txtnetAmount = parseFloat(Number(this.txtnetAmount + item.totalAmount).toFixed(2));
    this.tempNetAmount = this.txtnetAmount;
  }

}

purchaseReturnDetailres;
createPurchaseReturnDetail() {
  if (this.txtPurchaseReturnDate == undefined || this.txtPurchaseReturnDate == null || this.txtPurchaseReturnDate == '') {
    return this.toastr.error('Purchase date must be required');
    // } else if (this.txtpurchaseReturnCode == undefined || this.txtpurchaseReturnCode == null || this.txtpurchaseReturnCode == '') {
    //   return this.toastr.error(' purchase number must be required');
  } else if (this.txtpartyName == undefined || this.txtpartyName == null || this.txtpartyName == '') {
    return this.toastr.error('Party name must be required');
  } else if (this.PurchaseReturnData.length == 0 || this.PurchaseReturnData == []) {
    return this.toastr.error('Purchase detail must be required');
  } else {
    // this.txtpartyName = this.tempParty;
    this.loading = true;
    $(".overlay").css({"display": "block", "opacity": "0.7"});
    $(".loader").css({"display": "block"});
    this.txtPurchaseReturnDate = this.datePipe.transform(this.txtPurchaseReturnDate, 'yyyy-MM-dd');
    this.txtDueDate = this.datePipe.transform(this.txtDueDate, 'yyyy-MM-dd');
    this.purchasereturnservice.addPurchaseReturnDetail(this.txtPurchaseReturnDate, this.txtpurchaseReturnCode, this.tempParty.partyId, this.txtgstAmount, this.txtamount, this.txtDiscount, this.txtnetAmount, this.PurchaseReturnData, this.txtDueDays, this.txtDueDate, this.txtNarration)
      .subscribe(res => {
        this.purchaseReturnDetailres = res;
        console.log(this.purchaseReturnDetailres);
        if (res) {
          this.hideloaderOrder();
          $(".overlay").css({"display": "none"});
        }
        this.clearValue();
        this.getPurchaseReturnNumber();
        this.toastr.success('Purchase return save successfully');
      }, error => {
        // this.toastr.error('item not saved');
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
        this.hideloaderOrder();
        $(".overlay").css({"display": "none"});
        this.loading = false;

      })
  }
}

getAllPurchaseReturn() {
  this.purchasereturnservice.getAllPurchaseReturn().subscribe(res => {
    this.allPurchase = res;
  })
}

updatePurchaseReturn(purchaseReturn) {
  this.btnName = "Update";
  this.txtpurchaseReturnid = purchaseReturn.id1;
  this.txtItemName = purchaseReturn.itemName;
  this.itemId = purchaseReturn.itemId;
  this.search1 = purchaseReturn.itemName;
  this.txtCategoryName = purchaseReturn.categoryName;
  this.txtSubCategoryName = purchaseReturn.subCategoryName;
  this.txtquantity = purchaseReturn.quantity;
  this.txtrate = purchaseReturn.rate;
  this.txtCGST = purchaseReturn.cgst;
  this.txtSGST = purchaseReturn.sgst;
  this.txtManufactureDate = purchaseReturn.manufactureDate;
  this.txtExpiryDate = purchaseReturn.expiryDate;
  this.txtBatchNumber = purchaseReturn.batchNumber;
  this.txtPurchaseNo = purchaseReturn.purchasenumber;
  this.searchPurchasenumber = purchaseReturn.purchasenumber;
  this.getQtyByItemAndBatchNo();
  this.getQTYNRateByPurchaseNoAndItem();
  this.getQTYByPurchaseNoAndItem();
}

i;
deletepurchasedata;
deletePurchaseReturn(purchaseReturn) {
  this.deletepurchasedata = purchaseReturn;
}

confirmDelete() {
  for (let PurchaseData of this.PurchaseReturnData) {
    if (PurchaseData.id1 == this.deletepurchasedata.id1) {
      this.i = this.deletepurchasedata.purchaseId;
      let index = this.PurchaseReturnData.indexOf(this.deletepurchasedata);
      if (PurchaseData.id1 !== -1) {
        this.PurchaseReturnData.splice(index, 1);
        this.getAmount();
        this.calculateNetAmount();
        $('#deleteModal').modal('hide');
        // $('.modal-backdrop').remove();
      }
    }
  }
}

  resReturnBatchNos;
  resReturnPurchaseNos =[];
  resReturnQtyRate;
  PurchaseNoQty;
  qtyLabel = false;
  txtPurchaseNo;
  searchPurchasenumber;
  getBatchNumberByPartyANdItem() {
    this.purchasereturnservice.getBatchNumberByPartyANdItem(this.purchasepartyid,this.itemId).subscribe(res=>{
      this.resReturnBatchNos = res;
      console.log(this.resReturnBatchNos);
      this.searchBatchNumbers = [];
      for (let batch of this.resReturnBatchNos) {
          this.searchBatchNumbers.push({"batchnumber":batch.batchNumber});
      }
    })
  }

  getPurchaseNoByPartyItemAndBatchNo() {
    this.purchasereturnservice.getPurchaseDetailByPartyItemAndBatchNo(this.purchasepartyid,this.itemId,this.txtBatchNumber).subscribe(res=>{
      this.resReturnPurchaseNos = res;
      console.log(this.resReturnPurchaseNos);
      this.setExpiryAndManuFactureDate();
    })
  }

  selectedStaticPurchaseNumber(result) {
    this.txtPurchaseNo = result.purchasenumber;
    this.searchPurchasenumber = result.purchasenumber;
    this.getQTYNRateByPurchaseNoAndItem();
    this.getQTYByPurchaseNoAndItem();
  }

  getQTYNRateByPurchaseNoAndItem() {
    this.purchasereturnservice.getQTYNRateByPurchaseNoAndItem(this.searchPurchasenumber,this.itemId).subscribe(res=>{
      this.resReturnQtyRate = res;
      console.log(this.resReturnQtyRate);
      this.qtyLabel = true;
      this.txtrate = parseFloat(Number(this.resReturnQtyRate.rate).toFixed(2));
      this.PurchaseNoQty = this.resReturnQtyRate.quantity;
    })
  }
  setExpiryAndManuFactureDate() {
    for (let batchno  of this.resReturnBatchNos){
      if (this.txtBatchNumber == batchno.batchNumber) {
        this.txtManufactureDate = batchno.manufactureDate;
        this.txtExpiryDate = batchno.expiryDate;
      }
    }
  }

  availablePurchaseNoQty;
  getQTYByPurchaseNoAndItem(){
    this.purchasereturnservice.getQTYByPurchaseNoAndItem(this.searchPurchasenumber,this.itemId).subscribe(res=>{
      this.availablePurchaseNoQty = parseFloat(Number(res).toFixed(0));
    })
  }
}
