<div class="body-container">
    <div class="content-holder">
        <app-navbar></app-navbar>
        <div class="content">
            <app-top-header></app-top-header>
            <div class="main-content">
                <!-- <h2>Item Master</h2> -->

                <div class="row">
                    <div class="col-lg-12 col-md-12 mb-4" *ngIf="!isGeneralData">
                        <div class="portlet">
                            <form class="needs-validation" novalidate="">
                                <div class="portlet-header">General Information</div>
                                <div class="portlet-content">
                                    <div class="row g-3">
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="first" class="form-label">Company Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <select class="form-select" id="company" name="txtCompany"
                                                [(ngModel)]="txtCompany" required="">
                                                <option>-:Select Company Name:-</option>
                                                <option *ngFor="let company of allCompany" [ngValue]="company.company.companyId">
                                                    {{company.company.companyName}}</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                Please enter company.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="Email" class="form-label">Email<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input class="form-control" id="Email" name="txtEmail"
                                                [(ngModel)]="txtEmail" required="">
                                            <div class="invalid-feedback">
                                                Please enter valid Email.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="Name" class="form-label">Bank Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input class="form-control" id="Name" name="txtBankName"
                                                [(ngModel)]="txtBankName" required="">
                                            <div class="invalid-feedback">
                                                Please enter valid bank name.
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-xl-3 col-6">
                                            <label for="number" class="form-label">Account Number<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input class="form-control" id="number" name="txtAccountNumber"
                                                [(ngModel)]="txtAccountNumber" required="">
                                            <div class="invalid-feedback">
                                                Please enter valid account number.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-3 col-6">
                                            <label for="code" class="form-label">IFSC Code<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input class="form-control" id="code" name="txtIfscCode"
                                                [(ngModel)]="txtIfscCode" required="">
                                            <div class="invalid-feedback">
                                                Please enter valid ifsc code.
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xl-3 col-6">
                                            <label for="branch" class="form-label">Branch Name<span
                                                    style="color: red;">&nbsp;*</span></label>
                                            <input class="form-control" id="branch" name="txtBranchName" 
                                                [(ngModel)]="txtBranchName" required="">
                                            <div class="invalid-feedback">
                                                Please enter valid branch name.
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-xl-2 col-6">
                                            <label for="condition" class="form-label">Terms & Condition<span
                                                    style="color: red;">&nbsp;</span></label>
                                            <input class="form-control" id="condition" name="txtTermsNcondition" 
                                                [(ngModel)]="txtTermsNcondition" required="">
                                            <div class="invalid-feedback">
                                                Please enter valid terms & condition.
                                            </div>
                                        </div> -->
                                        <div class="col-auto col-lg-1 btn-container-end">
                                            <button class="btn btn-secondary me-2" type="button" (click)="clearData()">
                                                Cancel
                                            </button>
                                            <button class="btn btn-primary" type="submit" (click)="createGeneralInformation()">
                                                {{btnName}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="portlet">
                            <div class="portlet-header">General Information Details</div>
                            <div class="portlet-content">
                                <div class="row d-flex justify-content-end">
                                    <div class="col-md-4">
                                        <div class="input-group search-box">
                                            <input class="form-control border-0" placeholder="Search" name="search"
                                                [(ngModel)]="search" id="example-search-input" />
                                            <span class="input-group-append">
                                                <button class="btn btn-outline-secondary border-0" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-3 table-responsive">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead class="tblheadercolor">
                                            <tr>
                                                <th scope="col">Company</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Bank Name</th>
                                                <th scope="col">Account Number</th>
                                                <th scope="col">IFSC Code</th>
                                                <th scope="col">Branch Name</th>
                                                <!-- <th scope="col">Terms & Condition</th> -->
                                                <th scope="col" style="min-width: 100px;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let general of allGeneral | filter:search | paginate: { itemsPerPage: 5, currentPage: page }">
                                                <td>{{general.companyName}}</td>
                                                <td>{{general.email}}</td>
                                                <td>{{general.bankName}}</td>
                                                <td>{{general.accountNumber}}</td>
                                                <td>{{general.ifscCode}}</td>
                                                <td>{{general.branch}}</td>
                                                <!-- <td>{{general.termsNcondition}}</td> -->
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-sm me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                                        (click)="updategeneral(general)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-secondary btn-sm me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                                        (click)="deletegeneral(general)" data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr *ngIf="allGeneral < 1">
                                                <td colspan="7" class="text-center"><b>No Record Found</b></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="mt-3 d-flex justify-content-end">
                                    <nav aria-label="Table pagination">
                                        <ul class="pagination">
                                            <li>
                                                <pagination-controls (pageChange)="page = $event"
                                                    (pageBoundsCorrection)="page=($event)" responsive="true">
                                                </pagination-controls>
                                            </li>
                                            <!-- <li class="page-item">
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li class="page-item active" aria-current="page">
                          <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete Record</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are You Sure Want To Delete This Record ?
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-primary" (click)="confirmDelete()">Yes</button> -->
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>