export class Gstr1PurchaseDto{
    header:any
    GSTINofRecipient: string;
    ReceiverName: string;
    InvoiceNumber: string;
    Invoicedate: string;
    InvoiceValue: number;
    PlaceOfSupply: string;
    ReverseCharge: string;
    ApplicableofTaxRate: string;
    InvoiceType: string;
    ECommerceGSTIN: string;
    Rate: number;
    TaxableValue: number;
    CessAmount: number;
    TotalInvoiceValue: number;
    TotalTaxableValue: number;
}