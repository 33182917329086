import { Component, OnInit } from '@angular/core';
import { receiptVoucherDto } from '../service/receipt_voucher/receipt_voucher';
import { PartyService } from "../service/party/party.service";
import { ToastrService } from "ngx-toastr";
import { SearchParty } from '../service/party/searchparty';
import { DatePipe } from '@angular/common';
import { LiabilityVoucherService } from '../service/liability-voucher/liability-voucher.service';
import { AssetsVoucherService } from '../service/assets-voucher/assets-voucher.service';

@Component({
  selector: 'app-opening-balance-liability',
  templateUrl: './opening-balance-liability.component.html',
  styleUrls: ['./opening-balance-liability.component.css']
})
export class OpeningBalanceLiabilityComponent implements OnInit {
  txtLiabilityId;
  txtVoucherDate;
  txtcompany;
  allCompany;
  txtpartyName;
  public searchParty = '';
  searchPartyName = [];
  txtLibLedger;
  allAssLibLedger;
  txtcredit;
  txtNarration;
  totalCredit = 0;
  ReceiptData: Array<receiptVoucherDto> = [];
  ReceiptIdLast: number = 0;
  datePipe = new DatePipe("en-US");
  txtChequeNo;

  constructor(
    private liabilityVoucherService: LiabilityVoucherService,
    private toastr: ToastrService,
    private partyService: PartyService,
    private assetsVoucherService: AssetsVoucherService,
  ) { }

  ngOnInit(): void {
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.getAllParty();
    this.getAllCompany();
    this.getAllAssetsLedger();
  }

  getAllCompany() {
    this.partyService.getAllPartyByCompanyOrg().subscribe(res => {
      this.allCompany = res;
      console.log("all Party",this.allCompany);
    })
  }

  getAllAssetsLedger() {
    this.assetsVoucherService.getAllAssLibLedger("11").subscribe(res => {
      this.allAssLibLedger = res;
      console.log(this.allAssLibLedger);
    })
  }

  // Allow only Numbers and dot(.)
  numberandDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keycode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }
  tempParty;
  selectedParty(party) {
    this.tempParty = party;
    if(party.address != null && party.address !=''){
      this.searchParty = party.partyName + "-" + party.cellNumber + "-" + party.address;
    }else{
      this.searchParty = party.partyName + "-" + party.cellNumber;
    }
    this.tempPartyId = party.partyId;
  }

  allparty;
  tempPartyId;
  getAllParty() {
    this.partyService.getAllPartyByStatus().subscribe(res => {
      this.allparty = res;
      for (let item of this.allparty) {
        var temp = new SearchParty();
        temp.partyName = item.partyName;
        temp.partyId = item.partyId;
        temp.cellNumber=item.cellNumber;
        temp.address = item.address;
        this.searchPartyName.push(temp)
      }
    })
  }

  clearValue() {
    this.txtpartyName = "";
    this.txtcredit = "";
    this.searchParty = "";
    this.tempParty = "";
    this.txtNarration="";
    this.txtLiabilityId = null;
    this.txtrefId = null;
    this.txtparticulars = null;
    this.txtcompany = "";
    this.ReceiptData = null;
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, "dd/MM/yyyy");
    this.txtVoucherDate = new Date();
    this.txtChequeNo = "";
    this.txtLibLedger = "";
  }

  addLiabilityVoucher() {
    if (this.txtVoucherDate == undefined || this.txtVoucherDate == null || this.txtVoucherDate == '') {
      return this.toastr.error('Voucher date must be required');
    } else if (this.txtcompany == "" || this.txtcompany == null || this.txtcompany == undefined || this.txtcompany == "-:Select Company Name:-") {
      return this.toastr.error("Company name must be required")
    } else if (this.tempParty == undefined || this.tempParty == null || this.tempParty == '') {
      return this.toastr.error('Party name must be required');
    } else if (this.txtLibLedger == undefined || this.txtLibLedger == null || this.txtLibLedger == "" || this.txtLibLedger == "-:Select Ledger Name:-") {
      return this.toastr.error('Ledger must be required');
    } else if (this.txtcredit == undefined || this.txtcredit == null || this.txtcredit == 0) {
      return this.toastr.error('Opening Balance must be required');
    } else {
      if (this.txtLiabilityId == null) {
        var receiptDetail = new receiptVoucherDto();
        receiptDetail.id1 = this.ReceiptIdLast + 1;
        this.ReceiptIdLast = receiptDetail.id1;
        // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
        receiptDetail.voucherDate = this.txtVoucherDate;
        receiptDetail.partyId = this.tempParty.partyId;
        receiptDetail.partyName = this.tempParty.partyName;
        // receiptDetail.party = this.tempParty;
        receiptDetail.credit = this.txtcredit;
        receiptDetail.amount = this.txtcredit;
        receiptDetail.ledgerId = this.txtLibLedger;
        this.ReceiptData.push(receiptDetail);
        // console.log(this.ReceiptData);
        // this.clearValue();
      }
    }
    this.createLiabilityVoucher();
    // console.log(this.ReceiptData);
  }

  txtparticulars;
  txtrefId;
  receiptVoucherres;
  createLiabilityVoucher() {
    for (let receiptDetail of this.ReceiptData) {
      receiptDetail.refId = this.txtcompany.partyId;
      receiptDetail.particulars = this.txtcompany.partyName;
      receiptDetail.ledgerId = this.txtLibLedger;
      // receiptDetail.voucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd hh-mm-ss');
      receiptDetail.amount = receiptDetail.credit;
      if (this.txtrefId == null || this.txtrefId == "") {
        this.txtrefId = receiptDetail.partyId;
        this.txtparticulars = receiptDetail.partyName;
      } else {
        this.txtrefId = this.txtrefId + ',' + receiptDetail.partyId;
        this.txtparticulars = this.txtparticulars + ',' + receiptDetail.partyName;
      }
    }

    let receiptDetails = new receiptVoucherDto();
    receiptDetails.partyId = this.txtcompany.partyId;
    receiptDetails.partyName = this.txtcompany.partyName;
    receiptDetails.refId = this.txtrefId;
    receiptDetails.particulars = this.txtparticulars;
    // this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    receiptDetails.voucherDate = this.txtVoucherDate;
    receiptDetails.ledgerId = this.txtLibLedger;

    this.ReceiptData.push(receiptDetails);
    this.txtVoucherDate = this.datePipe.transform(this.txtVoucherDate, 'yyyy-MM-dd');
    this.liabilityVoucherService.createLiabilityVoucher(this.txtVoucherDate, this.ReceiptData, this.txtNarration, this.txtcompany.partyId,this.txtChequeNo)
      .subscribe(res => {
        this.receiptVoucherres = res;
        // console.log(this.receiptVoucherres);
        this.clearValue();
        this.ReceiptData = [];
        this.toastr.success('Liability voucher save successfully');
      }, error => {
        let message = JSON.parse(error._body).message;
        this.toastr.error(JSON.stringify(message));
      })
  }

}
