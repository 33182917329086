import { Component, OnInit } from '@angular/core';
import { LocationService } from "../service/location/location.service";
import { ToastrService } from "ngx-toastr";
import { StockService } from '../service/stock/stock.service';
import { CompanyService } from '../service/company/company.service';
import { ExcelService } from '../service/excel/excel.service';

@Component({
  selector: 'app-stockvalution',
  templateUrl: './stockvalution.component.html',
  styleUrls: ['./stockvalution.component.css']
})
export class StockvalutionComponent implements OnInit {

  txtLocation;
  allLocation;
  reStockvalutionData;
  totalstockTotalAmount = 0;
  totalstockquantity = 0;
  txtCompany;
  allCompany;
  page = 1;
  search;

  reStockvalutionCompanyData=[];
  constructor(
    private locationService: LocationService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private stockservice: StockService,
    private excelservice: ExcelService,
  ) { }

  ngOnInit(): void {
    this.getAllLocation();
    this.getAllCompany();
  }
  getAllLocation() {
    this.locationService.getAllLocation().subscribe(res => {
      this.allLocation = res;
    })
  }

  getAllCompany() {
    this.companyService.getAllCompany().subscribe(res => {
      this.allCompany = res;
    })
  }

  searchStockValution() {
    if (this.txtLocation == "" || this.txtLocation == null || this.txtLocation == undefined || this.txtLocation == "-:Select Location:-") {
      this.toastr.error("Location is required");
    } else {
      if (this.txtLocation == "All") {
        this.stockservice.getsearchStockValutionAll().subscribe(res => {
          this.reStockvalutionData = res;
          this.reStockvalutionCompanyData = this.reStockvalutionData;
          // console.log(this.reStockvalutionData);

          if (this.txtCompany == "" || this.txtCompany == null || this.txtCompany == undefined) {
            this.getTotal();
          } else {
            this.reStockvalutionData=[];
            for(let company of this.reStockvalutionCompanyData){
              // console.log("company",company);
              // console.log("item id",company.itemID);
              // console.log("item Category",company.item.category);
              // console.log("category",company.item.category.company.companyName);
              if(this.txtCompany == company.item.category.company.companyName){
                // console.log("company",company);
                this.reStockvalutionData.push(company);
              }
              this.getTotal();
            }
          }
          // this.getTotal();
        }, error => {
          this.toastr.error("something went wrong");
        });
      } else {
        this.stockservice.getsearchStockValution(this.txtLocation).subscribe(res => {
          this.reStockvalutionData = res;
          this.reStockvalutionCompanyData = this.reStockvalutionData;
          // console.log(this.reStockvalutionData);

          if (this.txtCompany == "" || this.txtCompany == null || this.txtCompany == undefined) {
            this.getTotal();
          } else {
            this.reStockvalutionData=[];
            for(let company of this.reStockvalutionCompanyData){
              if(this.txtCompany == company.item.category.company.companyName){
                this.reStockvalutionData.push(company);
              }
              this.getTotal();
            }
          }
          // this.getTotal();
        }, error => {
          this.toastr.error("something went wrong");
        });
      }
    }
  }

  getTotal() {
    this.totalstockTotalAmount = 0;
    this.totalstockquantity = 0;
    for (let stkval of this.reStockvalutionData) {
      var sum = 0;
      sum = sum + Number(stkval.stockTotalAmount);
      this.totalstockTotalAmount = this.totalstockTotalAmount + sum;
      var sumqty = 0;
      sumqty = sumqty + Number(stkval.stockquantity);
      this.totalstockquantity = this.totalstockquantity + sumqty;
    }
  }

  ExalData = [];
  stockTotalAmount;
  exportAsXLSX():void {
    this.ExalData=[];
    for(let exal of this.reStockvalutionData){
      this.stockTotalAmount = parseFloat(Number(exal.stockTotalAmount).toFixed(2));
      this.ExalData.push({
        "Item Name": exal.item.itemName, "Company Name": exal.item.category.company.companyName, "Stock Quantity": exal.stockquantity
        , "Stock Total Amount (Rs.)": this.stockTotalAmount})
    }
    this.excelservice.exportAsExcelStockvalution('Stockvalution',this.ExalData,this.totalstockquantity, this.totalstockTotalAmount);
 }

}
