import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CropsService } from '../service/crops/crops.service';
import { ProductAppService } from '../service/product-app/product-app.service';
declare var $: any;

@Component({
  selector: 'app-crops',
  templateUrl: './crops.component.html',
  styleUrls: ['./crops.component.css']
})
export class CropsComponent implements OnInit {
  txtProductApp;
  allProductApp;
  txtCropsId;
  txtCrops;
  txtCropsGuj;
  txtCropsDes;
  txtCropsDesGuj;
  txtImage;
  imagePreview;
  selectedImage;
  resImage;
  resCrops;
  resCropsUp;
  resCropsDel;
  btnName = 'Save';
  search;
  page: number = 1;
  allCrops;

  constructor(
    private productAppService: ProductAppService,
    private cropsService: CropsService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getAllCrops();
    this.getAllProductApp();
  }

  getAllCrops() {
    this.cropsService.getAllCrops().subscribe(res => {
      this.allCrops = res;
    })
  }

  getAllProductApp() {
    this.productAppService.getAllProductApp().subscribe(res => {
      this.allProductApp = res;
    })
  }

  toUpperCaseCrops() {
    const temp = this.txtCrops;
    this.txtCrops = temp.toUpperCase();
    return this.txtCrops;
  }

  clearData() {
    this.btnName = 'Save';
    this.txtProductApp = '';
    this.txtCrops = '';
    this.txtCropsGuj = '';
    this.txtCropsDes = '';
    this.txtCropsDesGuj = '';
    this.txtCropsId = null;
    this.txtImage = "";
    this.imagePreview = "";
    this.selectedImage = "";
  }

  createCrops() {
    var pro = this.txtProductApp;
    var cro = this.txtCrops;
    var croguj = this.txtCropsGuj;
    var crodes = this.txtCropsDes;
    var crodesguj = this.txtCropsDesGuj;
    // if (this.txtProductApp == null || this.txtProductApp == '' || this.txtProductApp == undefined || this.txtProductApp == "-:Select Product:-" || (this.txtProductApp = pro.trim()) == "") {
    //   this.toastr.error("Application Product is required");
   if (this.txtCrops == null || this.txtCrops == '' || this.txtCrops == undefined || (this.txtCrops = cro.trim()) == "") {
      this.toastr.error("Crops name is required");
    }else if (this.txtCropsGuj == null || this.txtCropsGuj == '' || this.txtCropsGuj == undefined || (this.txtCropsGuj = croguj.trim()) == "") {
      this.toastr.error("Gujarati crops name is required");
    // }else if (this.txtCropsDes == null || this.txtCropsDes == '' || this.txtCropsDes == undefined || (this.txtCropsDes = crodes.trim()) == "") {
    //   this.toastr.error("Crops description is required");
    // }else if (this.txtCropsDesGuj == null || this.txtCropsDesGuj == '' || this.txtCropsDesGuj == undefined || (this.txtCropsDesGuj = crodesguj.trim()) == "") {
    //   this.toastr.error("Gujarati crops description is required");
    // }else if (this.txtImage == null || this.txtImage == '' || this.txtImage == undefined) {
    //   this.toastr.error("Crops image is required");
    } else {
      if (this.txtCropsId == null) {
        this.cropsService.createCrops(this.txtProductApp, this.txtCrops, this.txtCropsGuj, this.txtCropsDes, this.txtCropsDesGuj)
          .subscribe(res => {
            this.resCrops = res;
            this.uploadImage();
            this.clearData();
            this.getAllCrops();
            this.toastr.success('Crops save successfully');
          }, error => {
            // this.toastr.error('Crops not save');
            let message = JSON.parse(error._body).message;
            this.toastr.error(JSON.stringify(message));
          })
      } else {
        this.cropsService.updateCrops(this.txtCropsId,this.txtProductApp, this.txtCrops, this.txtCropsGuj, this.txtCropsDes, this.txtCropsDesGuj, this.txtImage).subscribe(res => {
          this.resCropsUp = res;
          this.uploadImage();
          this.clearData();
          this.getAllCrops();
          this.toastr.success('Crops updated successfully');
        }, error => {
          // this.toastr.error('Crops not updated');
          let message = JSON.parse(error._body).message;
          this.toastr.error(JSON.stringify(message));
        })
      }
    }
  }

  updateCrops(crp) {
    this.btnName = 'Update';
    this.txtCropsId = crp.id;
    this.txtCrops = crp.cropsName;
    this.txtCropsGuj = crp.cropsNameGuj;
    this.txtImage = crp.image;
    this.imagePreview = crp.image;
    // this.txtProductApp = crp.productApp.productName;
    // this.txtCropsDes = crp.cropsDescription;
    // this.txtCropsDesGuj = crp.cropsDescriptionGuj;
  }

  deleteAppId;
  deleteDataCrops(crp) {
    this.deleteAppId = crp.id;
  }

  confirmDelete() {
    if (this.deleteAppId != null) {
      this.cropsService.deleteCrops(this.deleteAppId)
        .subscribe(res => {
          this.resCropsDel = res;
          this.clearData();
          this.getAllCrops();
          $('#deleteModal').modal('hide');
          // $('.modal-backdrop').remove();
          this.toastr.success('Crops deleted successfully');
        }, error => {
          this.toastr.error('Crops not deleted');
        })
    }
  }

  onImageUpload(event) {
    this.selectedImage = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.selectedImage);
  }

  uploadImage() {
    if (this.selectedImage == null || this.selectedImage == '' || this.selectedImage == undefined) {
      // this.imagePreview = "";
      // this.getAllProductApp();
      // this.clearData();
      return
    } else {
      const data1 = new FormData();
      data1.append('file', this.selectedImage, this.selectedImage.name);
      data1.append('cat', this.txtCrops);
      this.cropsService.uploadImage(data1).subscribe(res => {
        this.resImage = res;
        this.getAllCrops();
        // this.clearData();
        this.imagePreview = "";
      }, error => {
        // this.imagePreview = "";
        // this.clearData();
        // this.getAllProductApp();
      })
    }
  }

}
