import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  createLedger(txtledgerName: string, txtglaccountName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      glAccountName : txtglaccountName,
      ledgerName:txtledgerName
    });
    return this.http.post('/api/create/ledger', body, { headers })
      .pipe(map(res => res.json()));
  }

  updateLedger(txtledgerId: string, txtledgerName: string,txtglAccountName: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      glAccountName : txtglAccountName,
      ledgerName : txtledgerName,
      ledgerId: txtledgerId
    });
    return this.http.put('/api/update/ledger', body, { headers })
      .pipe(map(res => res.json()));
  }

  getAllLedger(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/ledger', { headers })
      .pipe(map(res => res.json()));

  }

  getAllLedgerGST(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/ledger/gstr/group', { headers })
      .pipe(map(res => res.json()));

  }


  getAllLedgerGroup(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    
    return this.http.get('/api/getall/ledger/group', { headers })
      .pipe(map(res => res.json()));

  }

  deleteLedger(txtledgerId: string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');

    const body = JSON.stringify({
      ledgerId: txtledgerId
    });
    return this.http.put('/api/delete/ledger', body, { headers })
      .pipe(map(res => res.json()));
  }

  GetGroupWiseLedger(txtFormDate : string,txtToDate : string,txtGroup : string, txtcompany : string){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/group/ledger/' + txtFormDate + '/' + txtToDate + '/' + txtGroup + '/' + txtcompany , { headers : headers })
      .pipe(map(res => res.json()));
  }

  getAllLedgerExpense(){
    const headers = new Headers();
    var token = localStorage.getItem('Token');
    headers.append('Authorization', token);
    headers.append('Content-Type', 'Application/Json');
    
    return this.http.get('/api/getall/ledger/expence', { headers })
      .pipe(map(res => res.json()));
  }

  public downloadGroupWiseLedgerPDF(companyid: string): Observable<Blob> {
    var token = localStorage.getItem('Token');
    let header = new HttpHeaders().set("Authorization", token);
    return this.httpClient.get('/api/generate/server/balancesheet/report/' + companyid  , { headers: header, responseType: 'blob' });
  }
}
